export class ServiceProxy {
    headers = {
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json; charset=utf-8'        
    };

    headersone = {
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/json; charset=utf-8'
       // 'Authorization' : localStorage.getItem("accessToken")
    };

    getUrl(url : string, data : any){
        var queryParam = Object.keys(data)
            .map(k => k + '=' + data[k])
            .join('&');
        return url + '?' + queryParam;
    }

    get(url : string, data : any){
        if(data !== null)
        {
            url = this.getUrl(url, data)
        }
        const requestOptions = {
            method: 'GET',
            headers: this.headers
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }
    patch(url : string ,data : any)
    {
        const requestOptions = {
            method: 'PATCH',
            headers: this.headers,
            body: JSON.stringify(data)
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }
    post(url : string ,data : any)
    {
        const requestOptions = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(data)
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }
    postupload(url : string ,data : any){
        let requestOptions={
        }
        
        requestOptions = {
            method: 'POST',
            body: data
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }
    async asyncGet(url : string, data : any)
    {
        if(data !== null)
        {
            url = this.getUrl(url, data)
        }
        const requestOptions = {
            method: 'GET',
            headers: this.headers
        }
        let response = await fetch(url,requestOptions); 
        let jsonResult = await response.json(); 
        
        return jsonResult;
    }

    async asyncGetOne(url : string, data : any)
    {
        if(data !== null)
        {
            url = this.getUrl(url, data)
        }
        const requestOptions = {
            method: 'GET',
            headers: this.headers
        }
        let response = await fetch(url,requestOptions); 
        let jsonResult = await response.json(); 
        
        return jsonResult;
    }


    async asyncPost(url : string ,data : any)
    {
        const requestOptions = {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify(data)
        }
        let response = await fetch(url,requestOptions); 
        let jsonResult = await response.json(); 
        
        return jsonResult;
    }

    put(url : string ,data : any)
    {
        const requestOptions = {
            method: 'PUT',
            headers: this.headers,
            body: JSON.stringify(data)
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }

    delete(url : string ,data : any)
    {
        if(data !== null)
        {
            url = this.getUrl(url, data)
        }
        const requestOptions = {
            method: 'DELETE',
            headers: this.headers
        }
        return fetch(url,requestOptions)
        .then(response => {return response.json()})
        .catch(error => {return error});
    }
}

