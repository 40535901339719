import React, { Component } from "react";
import {Container,Card,Row,Col,Form,Alert,Button} from "../../../../node_modules/react-bootstrap";
import imgBGDisplay from '../../../assets/img/universal.png';
import { Redirect } from 'react-router-dom';
import '../../../assets/css/Style.css'
import {FormInputs} from '../../CustomComponents/FormInput'
import {Tabs, Tab} from 'react-bootstrap-tabs';
import '../../../assets/css/Style.css'
import '../../../assets/css/site.css'
import {LoginController} from "../../../Controllers/LoginController"
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { CommonController } from "../../../Controllers/CommonController";
import { SystemMaintenanceController } from "../../../Controllers/SystemMaintenanceController";

export class Home extends Component<IHome,Istate> 
{
  constructor(props){ 
    super(props)
    const userLogin = sessionStorage.getItem("uid")
    let loggedIn = true;
    if (userLogin === null || userLogin === undefined){
        loggedIn = false;
    }
    this.state = {
        loggedIn,
        sytemMessageGroups:[],
        modal: {modalShow: false, modalTitle: '', modalBody: false, modalAlertShow:false,modalAlertTitle:'',modalAlertErrorMsg:''}
    }
  } 

componentWillMount(){
  
  let systemMaintenanceController = new SystemMaintenanceController ();
        systemMaintenanceController.getActiveSysMessage().then(jsonGetSysMessage => {
            this.setState({ sytemMessageGroups: JSON.parse(JSON.stringify(jsonGetSysMessage))}); 
        }).catch(error => {
            console.error("There was an error!", error);
        })
}

    render() {
        //if (this.state.loggedIn === false){
        //  return <Redirect to="/" />
        //}
        const styleAlignBG={
            "max-width":"50rem",
            "width":"100%",
            "margin": "0 0 50% 0"
        }
        const divStyle = {
          width: '100%',
          height: '500px',
          backgroundImage: `url(${imgBGDisplay})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };
        const divStyle1 = {
          width: '100%',
          height: '400px',         
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };

        return (<div className="content">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: '100%' }}>
               <Card.Body>
                  {this.state.sytemMessageGroups.length > 0 ? 
                     <div style={divStyle1}>
                       <tbody>
                             {this.state.sytemMessageGroups.map((prop, key) => {
                              return (
                                  <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                      <td><p className="blinking">{prop["message"]||''}</p></td>
                                  </tr>
                                )
                            })}
                          </tbody>
                       </div>
                   : <div style={divStyle}></div>}
               </Card.Body>                                  
              </Card>
            </Col>
          </Row>
        </Container>
        </div>
        );
      }
    }

    interface Istate{
      loggedIn:boolean;
      modal:any;
      sytemMessageGroups?:any;
    }
    
    interface IHome{
      PasswordVerify?:number;
    }