import React, { Component } from "react";
import {Row,Col,Container, Card, Button, Form, Alert} from "react-bootstrap";
import {LoginNavBar} from '../../CustomComponents/NabvBar/LoginNavBar'
import {FormInputs} from '../../CustomComponents/FormInput'
import "../../../assets/css/LoginPage.css"
import { ILoginParameter, Istate } from "../../../Model/ILogin";
import {LoginController} from "../../../Controllers/LoginController"
import {CommonController} from "../../../Controllers/CommonController"
import {MenuController} from "../../../Controllers/MenuController"
import * as Utils from '../../../js/Utils.js';
import { Link, Switch, Redirect,Route } from "react-router-dom";
import "../../../assets/css/login.css"
import bgImg from "../../../assets/img/bg.jpg";
import logo from "../../../assets/img/umglogo.png";
import etalogo from "../../../assets/img/etalogo_login.png";
import { url } from "inspector";
import publicIP from 'react-native-public-ip';
import { UserAgentApplication } from 'msal';
import { config } from '../../../js/Config';
import { normalizeError, getUserProfile } from '../../../utils/MSUtils';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import GLOBAL from '../../../js/global';
import { logger, fileAsyncTransport } from "react-native-logs";
//import FS from "react-native-fs";
import {FileStreamLog} from "../../../Common/FileStreamLog";
import {alert,confirm} from 'react-bootstrap-confirmation';
import { domain } from "process";

export class Login extends Component<ILoginParameter,Istate> {
  constructor(props) {
    super(props);
   this.state = {
    isOpen: false,
    error: null,
    isAuthenticated: false,
    user: [],
    fields: { username:'', password:'' },
    errors: { username:'', password:'' },
    errMsg:"",
    loginFailed: 0,
    loading: false,
    modal: {modalSpinnerShow: false}
  }
    this.toggle = this.toggle.bind(this);
  }

  async componentWillMount(){
    
    var userAgentApplication
    userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: config.clientId,
        authority:config.authority,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
      }
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
   });
  }

  async login() { 
    let domainURL:string = Utils.getAbsoluteDomainUrl();

    //console.log("envronment:",process.env.NODE_ENV)   
    //console.log(domainURL) 
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({modal});
    //console.log("step:1");
    var userAgentApplication
    userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: config.clientId,
        authority:config.authority,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
      }
    });
    //console.log("step:2");

    try {
      await userAgentApplication.loginPopup(
      {
        scopes: config.scopes,
        prompt: "select_account"
      });
      //console.log("step:3");
      const user = await getUserProfile(userAgentApplication, config.scopes);
      //console.log("USER:", user)
      this.setState({
        isAuthenticated: true,
        user: {
          displayName: user.displayName,
          email: user.name ,
         // userPrincipalName:  user.userPrincipalName
          userPrincipalName:  user.mail
        },
        error: null
      });
      //console.log("step:4");
      //console.log( user.userPrincipalName)
      //set
      GLOBAL.userAgent = userAgentApplication;

      //check if SSO authenticated
        if(this.state.isAuthenticated){
          let fields = this.state.fields;
          sessionStorage.clear();
          localStorage.clear();
          //fields["username"] = "";
          fields["password"] = "";
          let loginController = new LoginController(this.state.fields.username,this.state.fields.password,this.state.loginFailed);
          loginController.getActiveUserLogin(this.state.user.userPrincipalName).then(response => {
            
            if(response.adUserId !== null){
              fields["username"] = response.adUserId;
              //console.log("AdUserId:",response.adUserId);
              loginController.userExistsInApp(this.state.fields.username).then(response => {
                if(response.userExists){
                    sessionStorage.setItem("adID",this.state.fields.username);
                    sessionStorage.setItem("shadow","");
                    loginController.afterSuccessfulLogin(this.state.fields.username,"","").then(response => {
                        if(response.multipleRole){
                          sessionStorage.setItem("multirole","1");
                          publicIP().then(ip => {
                            loginController.updateLogSuccessfulLogin(this.state.fields.username,ip).then(response => {
                              //console.log("LoginSuccessfulLogin response:",response);
                            });
                          }).catch(error => {
                              console.error("There was an error loading IP Address..., " + error)
                          });
                          this.props.history.push('/admin/selectrole');
                        }
                        else{
                          sessionStorage.setItem("multirole","0");
                            let commonController = new CommonController();
                            commonController.getUser(response.userId).then(response => {
                              let menuController = new MenuController();
                              menuController.getMenuVariable(response.userId,Utils.SysDateTime()).then(response => {
                                var result = (response.menuVariable=== null)?'':response.menuVariable.trim();
                                if (result != '')
                                {
                                  sessionStorage.setItem("menuVariable",result);
                                }
                                else
                                {
                                  sessionStorage.setItem("menuVariable",result);
                                } 
                                publicIP().then(ip => {
                                  loginController.updateLogSuccessfulLogin(this.state.fields.username,ip).then(response => {
                                    //console.log("LoginSuccessfulLogin response:",response); 
                                  });
                                }).catch(error => {
                                    console.error("There was an error loading IP Address..., " + error)
                                });
                                if(sessionStorage.getItem("user_type_id") === "R"){
                                  this.props.history.push('/admin/trackinggrid');
                                }
                                else if(sessionStorage.getItem("user_type_id") === "A"){
                                  this.props.history.push('/admin/approverinbox');
                                }
                                else if(sessionStorage.getItem("user_type_id") === "W" || sessionStorage.getItem("user_type_id") === "CT"){
                                  this.props.history.push('/admin/wtpincomingta');
                                }
                                else{
                                  this.props.history.push('/admin/home');
                                }
                              }).catch(error => {
                                console.error("There was an error getApprovalProcessValidate")
                              });   
                            }).catch(error => {
                                console.error("There was an error:", error);
                            })
                        }
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error:", error);
                    })
                }
                else{
                    modal["modalSpinnerShow"] = false;
                    this.setState({
                        errMsg : "Invalid Login",
                        loading: false,
                        modal
                    })
                }
              }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error:", error);
              })
            }
            else
            {
              modal["modalSpinnerShow"] = false;
              this.setState({
                errMsg : "Invalid Login",
                loading: false,
                modal
              })
              alert('You do not have access to eTA. Please reach out to Application Support (applicationsupport@umusic.com) to request access.')
            }
          }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error:", error);
          })
        }
    }
    catch (err) {
      this.setState({
        isAuthenticated: false,
        user: {},
        error: normalizeError(err)
      });
    }


    //console.log("step:5");
    //alert(this.state.user.displayName)    
    //alert(this.state.user.userPrincipalName)
    //alert(this.state.isAuthenticated)

    if(!this.state.isAuthenticated){
        modal["modalSpinnerShow"] = false;
        this.setState({modal});
    }   
  }

 eventLog(){

  let commonController = new CommonController();
  commonController.writeFile('Testing').then(response => {
     //console.log("File written successfully\n");
  }).catch(error => {
      console.error("There was an error loading IP Address..., " + error)
  });

  let today = new Date();
  let date = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

 // const fs = require('fs');
  
let data = "This is a file containing a collection"
           + " of programming languages.\n"
 + "1. C\n2. C++\n3. Python";
  
//fs.writeFileSync("programming.txt", data);


  //var obj = new FileStreamLog();
  //obj.createFile();
// obj.showFile();

  /*const fs = require("fs");

  let text = "Something you want to write in";
  
  fs.writeFileSync("document.txt", text, (err){
  if(err){
    return //console.log("error");
  }
  //console.log("File created!");
  })*/

  /*var RNFS = require('react-native-fs');

  const config = {
    severity: "debug",
    transport: fileAsyncTransport,
    transportOptions: {
      FS: RNFS,
      /* EXPO:
       * FS: FileSystem,
       
      fileName: `logs_${date}-${month}-${year}`, // Create a new file every day
    },
  };
  var log = logger.createLogger(config);

  log.info("Print this string to a file");*/
   
  //const EventLogger = require('eventlogger')
  //const log = new EventLogger('Hello World')
   
  //log.info('Basic information.')
  //log.warn('Watch out!')
  //log.error('Something went wrong.')
  
 }
 logout() {
    var userAgentApplication
    userAgentApplication.logout();
  }

  render() {
    
    const { loading } = this.state; 
    const alignCardCenter = {
      "margin": "10% 0 19% 0"
    };
    
    return (      
    
      <>      
      <div className="d-flex flex-column flex-root">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
          <div className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10" style={{"backgroundColor":"#487afa"}}>
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <a className="flex-column-auto mt-5 pb-lg-0 pb-10" href="#">
              <img alt="Logo"  className="max-h-80px" src={logo}/>
            </a>
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <img alt="Logo"  className="max-h-80px" src={etalogo}/>
              </div>
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">© Universal Music Group Application Support</div>
                <div className="d-flex">
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <div className="text-center mb-10 mb-lg-20"><h3 className="font-size-h1">eTA now supports Single Sign On (SSO) login, Please login with your email and password</h3>
                  
                  </div> 
                  {/*<form autoComplete='off' className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit = {this.onSubmit}>
                    {<div className="form-group fv-plugins-icon-container">
                      <FormInputs
                        properties={{id:"username",label:"User Name:",type:"text",name:"username",value:this.state.fields.username,className:"form-control input-sm",maxlength:16,onChange:this.handleChange}}
                        errorMsg={this.state.errors.username}
                        fieldRequired={true}
                      />
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <FormInputs
                        properties={{id:"password",label:"Password:",type:"password",name:"password",value:this.state.fields.password,className:"form-control input-sm",maxlength:50,onChange:this.handleChange}}
                        errorMsg={this.state.errors.password}
                        fieldRequired={true}
                      />
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">*/}
                    <div className="text-right mb-10 mb-lg-20">
                      <Button type="submit" size="sm" disabled={loading} style={{float:"right"}} variant="primary"  onClick={() => this.login()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px",color:"#6c757d" }}
                        />
                        )}
                        {loading && <span style={{color:"#6c757d"}}>wait...</span>}
                        {!loading && <span>Log In</span>}
                      </Button>
                      {/*<Button type="button" size="sm" style={{float:"right"}} variant="primary"  onClick={() => this.eventLog()}>WriteFile</Button>*/}
                    </div>
                  {/*</form>*/}
                </div>
              </div>
            </div>
          </div>
        </div>         
    </>      
    );
  }
}

