import React, { Component } from "react";
import { IBCDCodingParameter, Istate } from "../../../Model/IBCDCoding";
import {Container,Row,Col,Card} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { BCDWSController } from "../../../Controllers/BCDWSController";
import '../../../assets/css/Style.css';
import parse from 'html-react-parser';

export class AddBCDRemarks extends Component<IBCDCodingParameter,Istate> {
    constructor(props:IBCDCodingParameter){
        super(props)
        const userLogin = sessionStorage.getItem("uid")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {loadView:""},
            selectfields:{},
            errors:{},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            bcdCodingGroups:[],
            isListAvl:false,            
            isListAvlMsg:"No record(s) found",
            offset: 0,
            tableData: [],                  
            perPage: 10,
            currentPage: 0,
            pageCount:0                          
        }
        
    }

    componentWillMount(){
        let modal = this.state.modal;  
        let fields = this.state.fields; 
        modal['modalSpinnerShow']=true;                  
        let bcdwsController = new BCDWSController;
        var data = {
            authReqId: this.props.location.state.authReqId,
            isAddBCDRemarks: true
        }
        bcdwsController.addSortBCDRemarkToPNR(data).then(response => { 
            fields["loadView"] = response;//getVal.replace("undefined",this.props.location.state.authReqId); 
            modal['modalSpinnerShow']=false; 
            this.setState({modal,fields})                    
        })
    }


    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Add CTM Remarks</h4></div>
                            <Card.Body>
                                <div>
                                    {parse(this.state.fields.loadView)}
                                </div>                                                                 
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}