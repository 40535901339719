import {FormControl,FormLabel,FormGroup, Button, Col, Form, Row} from "../../../node_modules/react-bootstrap";
import React,{ Component } from 'react';
import {History, LocationState} from "History";
import DatePicker from 'react-datepicker';
import endOfDecadeWithOptions from "date-fns/esm/fp/endOfDecadeWithOptions/index.js";

export class SearchBoxCarVoucher extends Component<ISearchBoxParameter, Istate> {
    constructor(props: ISearchBoxParameter) {
        super(props)
        this.state = {
            fields: {searchString: "*"},
            selectfields: {searchDays: "14", searchScope: "voucher_id"},
            startDate: new Date(),
            endDate: new Date(),
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    }

    componentWillMount() {
        this.changeStartDate();
    }

    handleChange = (e: any) => {
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0) {
          fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else {
          fields[e.currentTarget.name] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }
    
    handleSelectChange = (e: any) => {
        let selectfields = this.state.selectfields;
        let currentTargetName = e.currentTarget.name;
        if (currentTargetName === "searchDays") {
          selectfields[e.currentTarget.name] = e.currentTarget.value;
          console.log(selectfields[e.currentTarget.name]);
          this.setState({ selectfields});
          this.changeStartDate();
        }
        else {
          selectfields[e.currentTarget.name] = e.currentTarget.value;
          this.setState({ selectfields});
        }
    }
    
    changeStartDate() {
        if (this.state.selectfields.searchDays === "all") {
          var date = new Date("01/01/2000");
        }
        else if (this.state.selectfields.searchDays === "other") {
          var date = new Date();
        }
        else {
          var date = new Date();
          date.setDate(date.getDate() - this.state.selectfields.searchDays);
        }
        this.setState({
          startDate: date, endDate: new Date()
        })
    }
    
    handleChangeStartDate = date => {
        this.setState({
          startDate: date
        });
    }
    
    handleChangeEndDate = date => {
        let selectfields = this.state.selectfields;
        selectfields['searchDays'] = "other";
        this.setState({
          endDate: date, selectfields
        })
    }
    
    handleOnSubmit = (e: any) => {
        this.props.parentCallback(this.state.selectfields.searchDays, this.state.startDate, this.state.endDate, this.state.fields.searchString, this.state.selectfields.searchScope);
        e.preventDefault();
    }

    render() { 
      const {loading} = this.state;

      return(<><Row>
        <Col sm={1}><div className="text-dark font-size-xs small">For</div></Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Control as="select" name="searchDays" id="searchDays" className="form-control-sm" value={this.state.selectfields.searchDays} onChange={this.handleSelectChange}>
              <option value="all">All</option>
              <option value="3">Last 3 days</option>
              <option value="7">Last 7 days</option>
              <option value="14">Last 14 days</option>
              <option value="30">Last 30 days</option>
              <option value="45">Last 45 days</option>
              <option value="60">Last 60 days</option>
              <option value="90">Last 90 days</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <DatePicker style={{ fontSize: "12px" }}
            Id="startDate"
            selected={this.state.startDate}
            onChange={this.handleChangeStartDate}
            isClearable
            showMonthDropdown
            showYearDropdown
            className="dateStyle" />
        </Col>
        <Col sm={1}><div className="text-dark font-size-xs small">and</div></Col>
        <Col sm={2}>
          <DatePicker style={{ fontSize: "12px" }}
            Id="endDate"
            selected={this.state.endDate}
            onChange={this.handleChangeEndDate}
            isClearable
            showMonthDropdown
            showYearDropdown
            className="dateStyle" />
        </Col>
        <Col sm={4}></Col>
      </Row>
      <Row>
        <Col sm={1}><div className="text-dark font-size-xs small">Find</div></Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Control type="text" name="searchString" id="searchString" className="form-control-sm" value={this.state.fields.searchString} onChange={this.handleChange}>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={1}><div className="text-dark font-size-xs small">In</div></Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Control as="select" name="searchScope" id="searchScope" className="form-control-sm" value={this.state.selectfields.searchScope} onChange={this.handleSelectChange}>
              <option value="voucher_id">Voucher Id</option>
              <option value="passenger_name">Passenger Name</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={4} style={{ textAlign: "center", alignSelf: "center" }} >
          <Button disabled={this.state.loading} name='searchHistory' style={{ marginBottom: "15px" }} onClick={(e) => this.handleOnSubmit(e)} title="Click to start search">
            {this.state.loading && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", color: "#487afa" }}
              />
            )}
            {this.state.loading && <span style={{ color: "#487afa" }}>wait...</span>}
            {!this.state.loading && <span>Search</span>}
          </Button>
        </Col>
      </Row></>)
    }
}

interface ISearchBoxParameter {   
    history?: History<LocationState>;
    parentCallback?:any
}
  
interface Istate {    
    fields: any;   
    selectfields: any; 
    startDate: any;
    endDate: any; 
    loading: boolean;
}