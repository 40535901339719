import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class RentalCarController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getBillingType(isCorporateCard:string){
        var url = this.variables.Url + this.variables.Controller.rentalCar + this.variables.rentalCarService.getBillingType;
        var data = {
            isCorporateCard : isCorporateCard
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonBillingType : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(billingType);
                function billingType(item: { rental_bill_id: string; Description: string}){
                    jsonBillingType.push({"id": item.rental_bill_id, "name": item.Description})
                }
                return JSON.parse(JSON.stringify(jsonBillingType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonBillingType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getRentalType(){
        
        var url = this.variables.Url + this.variables.Controller.rentalCar + this.variables.rentalCarService.getRentalType;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonRentalType : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(rentalType);
                function rentalType(item: { rental_type_id: string; description: string}){
                    jsonRentalType.push({"id": item.rental_type_id, "name": item.description})
                }
                
                return JSON.parse(JSON.stringify(jsonRentalType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonRentalType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getRentalCarBookingCarRentId(carRentId:string,sessionTA:string){
        var url = this.variables.Url + this.variables.Controller.rentalCar + this.variables.rentalCarService.getCarRentId;
        var data = {
            carRentId: carRentId,
            sessionTA: sessionTA
        }
        return this.serviceProxy.get(url,data).then(response => {
            var jsonCarRentId : Array<{"carRentId": string}> = [];
            if(response.success){
                response.recordset.forEach(carRentId);
                function carRentId(item: { car_rent_id: string}){
                    jsonCarRentId.push({"carRentId": item.car_rent_id})
                }
                return JSON.parse(JSON.stringify(jsonCarRentId).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCarRentId).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getBooking(authReqId, carRentId){
        var url = this.variables.Url + this.variables.Controller.rentalCar + this.variables.rentalCarService.getBooking;
        var data = {
            authreqId: authReqId,
            carRentId: carRentId
        }
        return this.serviceProxy.get(url,data).then((response : any) => {                                                                                                                                                                                  
            var jsonBooking : Array<{"carRentId": number; "rentalBillId": string; "rentalBillDesc": string;"rentalTypeId": number; "carCompany": string; "city": string; "rate": number; "tax": number; "otherCosts": number; "total": number; "pickupDate": string; "returnDate": string; "scAmount": number; "scDescription": string; "BCDGenerated": boolean; "BCDValidated": boolean; "RLN": string; "numberOfDays": number; "rateType":string}> = [];
            if(response.success){
                response.recordset.forEach(rentalCarBooking);
                function rentalCarBooking(item: { Car_Rent_ID: number; Rental_Bill_ID: string; Rental_Bill_Desc: string; Rental_Type_ID:number; Car_Company: string; City: string; Rate: number; Tax: number; Other_Costs: number; Total: number, Pick_Up_Date: string, Return_Date: string; SC_Amount: number; SC_Description: string; BCD_generated: boolean; BCD_validated: boolean; RLN: string; numberOfDays: number; RateType: string }, _index: number){
                    jsonBooking.push({"carRentId": item.Car_Rent_ID, "rentalBillId": item.Rental_Bill_ID, "rentalBillDesc": item.Rental_Bill_Desc, "rentalTypeId":item.Rental_Type_ID, "carCompany": item.Car_Company, "city": item.City, "rate": item.Rate, "tax": item.Tax, "otherCosts": item.Other_Costs, "total": item.Total, "pickupDate": item.Pick_Up_Date, "returnDate": item.Return_Date, "scAmount": item.SC_Amount, "scDescription": item.SC_Description, "BCDGenerated": item.BCD_generated, "BCDValidated": item.BCD_validated, "RLN": item.RLN, "numberOfDays": item.numberOfDays,"rateType":item.RateType})
                }
                
                return JSON.parse(JSON.stringify(jsonBooking).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonBooking).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    userProcessInsert (data){
        var url = this.variables.Url+ this.variables.Controller.rentalCar + this.variables.rentalCarService.userProcessInsert;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessCopy (data){
        var url = this.variables.Url+ this.variables.Controller.rentalCar + this.variables.rentalCarService.userProcessCopy;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessUpdate (data){
        var url = this.variables.Url+ this.variables.Controller.rentalCar + this.variables.rentalCarService.userProcessUpdate;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    bookingMap (data){
        var url = this.variables.Url+ this.variables.Controller.rentalCar + this.variables.rentalCarService.bookingMap;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateSpecialIns (data){
        var url = this.variables.Url+ this.variables.Controller.rentalCar + this.variables.rentalCarService.updateSpecialIns;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}