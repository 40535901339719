import React, { Component, CSSProperties } from "react";
import { IDirectBillReportGuestArrivalParamter, Istate } from "../../../Model/IDirectBillReportGuestArrival";
import { FormLabel, Container, Row, Col, Form, Alert, Button, Card, NavLink, FormControl, Table, InputGroup, FormGroup } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { DirectBillReportController } from "../../../Controllers/DirectBillReportController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import addSubtractDate from 'add-subtract-date';
import * as Utils from '../../../js/Utils.js';
import * as Constants from "../../../js/Constants";
import { SearchBoxDBReport } from "../../CustomComponents/SearchBoxDBReport";


export class DirectBillReportCheckin extends Component<IDirectBillReportGuestArrivalParamter, Istate> {
    constructor(props: IDirectBillReportGuestArrivalParamter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: { uid: "" },
            errors: {},
            errMsg: "",
            loggedIn,
            GuestArrivalSearch: [],
            GuestArrival: [],
            currentSort: 'down',
            currentColumn: 'authReqId',
            loading: false,
            modal: { modalSpinnerShow: false }
        }
    }

    componentWillMount() {
        let fields = this.state.fields;
        this.setState({ fields })
        this.pageLoad();
    }

    pageLoad() {
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.setState({ modal });

        var gadata = {
            uid: sessionStorage.getItem("uid")
        }
        let directbillreportcontroller = new DirectBillReportController();
        directbillreportcontroller.getDirectBillReportGuestArrival(gadata).then(jsoncallfoliolog => {
            this.setState({
                GuestArrival: JSON.parse(JSON.stringify(jsoncallfoliolog))
            });
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error...," + error)
        });
    }
    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        //debugger;
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = this.state.GuestArrival;
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            GuestArrival: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    render() {
        var prevAuthReqId = 0;
        const { loading } = this.state;
        const tdStylechk = {
            width: '3%',
            border: "2px solid #eee",
            padding: "2px"
        };
        const tdStyle = {
            width: '20%',
            border: "2px solid #eee",
            padding: "2px"
        };

        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%' }}>
                                <div className="header"><h2 className="title" style={{ textAlign: 'center' }}>eTA Travel Payment System</h2></div>
                                <div className="header"><h4 className="title">Guest Arrival <p style={{ textAlign: 'right', fontSize: '18px', marginTop: '-30px' }}>{Utils.FormatTodayDate()}</p></h4></div>                                
                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Table hover striped bordered >
                                                    <thead>
                                                        <tr>
                                                            <th style={tdStyle} onClick={() => this.onSortChange('authID')}>Auth Req <i className={this.setArrow('authID')}></i></th>
                                                            <th style={tdStyle} onClick={() => this.onSortChange('hotel')}>Hotel<i className={this.setArrow('hotel')}></i></th>
                                                            <th style={tdStyle} onClick={() => this.onSortChange('traveller')}>Traveller<i className={this.setArrow('traveller')}></i></th>
                                                            <th style={tdStyle} onClick={() => this.onSortChange('indate')}>Check-in Date<i className={this.setArrow('indate')}></i></th>
                                                            <th style={tdStyle} onClick={() => this.onSortChange('yesno')}>Centrally Billed<i className={this.setArrow('yesno')}></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.GuestArrival.map((prop, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{prop["authID"]}</td>
                                                                    <td>{prop["hotel"]}</td>
                                                                    <td>{prop["traveller"]}</td>
                                                                    <td>{Utils.FormatDate(prop["indate"])}</td>
                                                                    <td>{prop["yesno"]}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}