import React, { Component } from "react";
import { ICarVoucherPrintParameter, Istate } from "../../../Model/ICarVoucherPrint";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,FormControl,Table,InputGroup,FormGroup} from "../../../../node_modules/react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { CarVoucherController } from "../../../Controllers/CarVoucherController";
//import logo from "../../assets/img/UMG_Logo.png";
import queryString from 'query-string';
import {useLocation} from "react-router-dom";
import { alert, confirm } from 'react-bootstrap-confirmation';

export class CarVoucherConfirmation extends Component<ICarVoucherPrintParameter,Istate> {
    constructor(props:ICarVoucherPrintParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            lblFields: {account:'',passengerName:'',company:'',phone:'',empNo:'',costService:'',vipNo:'',
                        pickUpPoint:'',pickUpDtTm:'',dropOffPoint:'',name:'',output:'',description:''},            
            errors:{},
            errMsg:"",
            loading:false,           
            modal: {modalSpinnerShow: false} ,
            userId:"", 
            voucherId:""                          
        }
        this.onNavigate = this.onNavigate.bind(this);
    }

    componentWillMount(){       
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true; 
        let lblFields = this.state.lblFields; 
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let vId = params.get('voucherId');
        this.setState({voucherId:this.props.location.state.voucherId});
        /*const search = useLocation().search;
        const vId = new URLSearchParams(search).get('voucherId');*/          
       
        let carVoucherController = new CarVoucherController();
        
        var carVoucherDetails = {     
            voucherId : this.props.location.state.voucherId                   
        }
       
        carVoucherController.getCarVoucherdetails(carVoucherDetails).then(jsonVoucherDetails => {  
                lblFields["account"] = jsonVoucherDetails[0].account || "";
                lblFields["passengerName"] = jsonVoucherDetails[0].passengerName || "";
                lblFields["phone"] = jsonVoucherDetails[0].phone || "";
                lblFields["company"] = jsonVoucherDetails[0].company || "";
                lblFields["empNo"] = jsonVoucherDetails[0].empNo || "";
                lblFields["costService"] = jsonVoucherDetails[0].costService || "";
                lblFields["vipNo"] = jsonVoucherDetails[0].vipNo || "";
                lblFields["pickUpPoint"] = jsonVoucherDetails[0].pickUpPoint || "";
                lblFields["pickUpDtTm"] = jsonVoucherDetails[0].pickUpDtTm || "";
                lblFields["dropOffPoint"] = jsonVoucherDetails[0].dropOffPoint || "";
                lblFields["name"] = jsonVoucherDetails[0].name || "";
                lblFields["output"] = jsonVoucherDetails[0].output || ""; 
                lblFields["description"] = jsonVoucherDetails[0].description || "";        
                this.setState({lblFields});
                modal['modalSpinnerShow']=false;
                this.setState({modal})
        }).catch(error => {
            console.error("There was an error...," + error)
        });        
       
    }

    onNavigate = (e:any) => {
        e.preventDefault();  
        window.open('/carvoucherprint?voucherId='+ this.state.voucherId, '_blank', 'width=700,location=no,menubar=no,height=500,scrollbars=no,resizable=0,fullscreen=no');          
    }

    render() {
        const{ loading } = this.state;
        const bottomBorder = {
            borderBottom: "1px solid rgb(212, 212, 212)",
            width:"100%"
          };
          const para = {
            textAlign:"center",
            fontSize:"6",
            color:"#ffffff",
            fontFamily:"Lucida Sans Unicode"
          };  
          
          
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8}>
                        <h4>Your Personalized Car Voucher has been created!</h4><br/><br/>
                        <span>An e-mail has been sent to -</span><span style={{fontWeight:"bold"}}>{this.state.lblFields.description}</span><br/>
                        <span>To view and print your voucher, click here -</span><a  style={{color:"blue",textDecoration:"underline"}} onClick={(e) => this.onNavigate(e)}>VoucherID : {this.state.voucherId}</a><br/><br/>
                        <span style={{color:"red"}}>Remember to contact &nbsp;</span> <span style={{color:"navyblue"}}>{this.state.lblFields.description}</span><span style={{color:"red"}}>&nbsp; via telephone to confirm your reservation!</span>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}
        