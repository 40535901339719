import React, { Component, CSSProperties } from "react";
import { IDirectBillReportNotFolioLogParamter, Istate } from "../../../Model/IDirectBillReportNotFolioLog";
import { FormLabel, Container, Row, Col, Form, Alert, Button, Card, NavLink, FormControl, Table, InputGroup, FormGroup } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { DirectBillReportController } from "../../../Controllers/DirectBillReportController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import addSubtractDate from 'add-subtract-date';
import * as Utils from '../../../js/Utils.js';
import * as Constants from "../../../js/Constants";
import { SearchBoxDBReport } from "../../CustomComponents/SearchBoxDBReport";
import parse from 'html-react-parser';

export class DirectBillReportNotFolioLog extends Component<IDirectBillReportNotFolioLogParamter, Istate> {
    constructor(props: IDirectBillReportNotFolioLogParamter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: { searchDays: "14", startDate: "", endDate: new Date(), searchString: "*", searchScope: "ar.authreq_id" },
            errors: {},
            errMsg: "",
            loggedIn,
            callFolioLogSearch: [],
            callFolioLog: [],
            currentSort: 'down',
            currentColumn: 'authReqId',
            loading: false,
            modal: { modalSpinnerShow: false }
        }
    }

    componentWillMount() {
        let fields = this.state.fields;
        var date = new Date();
        date.setDate(date.getDate() - this.state.fields.searchDays);
        fields["startDate"] = date;
        this.setState({ fields })
        this.pageLoad();
    }
    pageLoad() {
        //debugger;
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.setState({ modal });
        sessionStorage.setItem("frompage", Constants.dbcallforlog);
        let directbillreportController = new DirectBillReportController();

        var searchString = "";
        if (this.state.fields.searchString.indexOf("*") != -1) {
            searchString = this.state.fields.searchString.replace("*", "");
        }
        else {
            searchString = this.state.fields.searchString;
        }
        let endDate: string = Utils.FormatDate(addSubtractDate.add(new Date(this.state.fields.endDate), 1, "day"));
        let startDate: string = Utils.FormatDate(this.state.fields.startDate);
        //console.log("startDate:", startDate);
        //console.log("endDate:", endDate);
        var dbcalllogData = {

            uid: sessionStorage.getItem("uid"),
            startDate: startDate,
            endDate: endDate,
            searchScope: this.state.fields.searchScope,
            searchStringLike: searchString
        }
        //console.log('dbcalllogData:', dbcalllogData);
        directbillreportController.getdirectBillReportNotFolioSearch(dbcalllogData).then(jsoncallfoliolog => {
            this.setState({
                callFolioLog: JSON.parse(JSON.stringify(jsoncallfoliolog))
            });
            //console.log('dbcalllogData:', this.state.callFolioLog);
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error...," + error)
        });
    }
    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        //debugger;
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = this.state.callFolioLog;
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            callFolioLog: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });

        //console.log("onSortChange - sort :", currentSort)
        //console.log("onSortChange - column :", currentColumn)
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }
    handleCallback = (searchDays, startDate, endDate, searchString, searchScope) => {
        let fields = this.state.fields;
        fields["searchDays"] = searchDays;
        fields["startDate"] = startDate;
        fields["endDate"] = endDate;
        fields["searchString"] = searchString;
        fields["searchScope"] = searchScope;
        this.setState({ fields });
        this.pageLoad();
    }
    onSaveUpdate = (e: any, item) => {
        //debugger;
        e.preventDefault();
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.setState({ errMsg: "" })
        let directbillreportController = new DirectBillReportController();

        var data = {
            foliocheck: item.foliocheck,
            calledcheck: item.calledcheck,
            authID: item.authID,
            usrid: sessionStorage.getItem("uid")
        }

        directbillreportController.updateCallNotfolioLog(data).then(response => {
            modal['modalSpinnerShow'] = false;
            this.pageLoad();
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error!", error)
            modal['modalSpinnerShow'] = false;
        })
    }

    checkboxchange(e: any, i, item) {
        // debugger;
        let fields = this.state.fields;
        let callFolioLog = [...this.state.callFolioLog];
        callFolioLog[e][i] = !callFolioLog[e][i]
        this.setState({ callFolioLog });
    }

    render() {
        var prevAuthReqId = 0;
        const { loading } = this.state;
        const tdStylechk = {
            width: '3%',
            border: "2px solid #eee",
            padding: "2px"
        };
        const tdStyle = {
            width: '20%',
            border: "2px solid #eee",
            padding: "2px"
        };
        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%', height:(this.state.callFolioLog.length <= 3)?"420px":"auto" }}>
                                <div className="header"><h2 className="title" style={{ textAlign: 'center' }}>eTA Travel Payment System</h2></div>
                                <div className="header"><h4 className="title">Folio Not Received <p style={{ textAlign: 'right', fontSize: '18px', marginTop: '-30px' }}>{Utils.FormatTodayDate()}</p></h4></div>
                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                    <SearchBoxDBReport parentCallback={this.handleCallback} from="Hotel" />
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{ width: '100%' }}>
                                                <Table hover striped bordered>
                                                    <thead style={{ backgroundColor: '#052a5e', fontSize: 'large' }}>
                                                        <tr>
                                                            <th onClick={() => this.onSortChange('company')}>Hotel Name <i className={this.setArrow('company')}></i></th>
                                                            <th onClick={() => this.onSortChange('fax')}>Fax<i className={this.setArrow('fax')}></i></th>
                                                            <th onClick={() => this.onSortChange('servicedate')}>Check-Out<i className={this.setArrow('servicedate')}></i></th>
                                                            <th onClick={() => this.onSortChange('traveller')}>Traveller<i className={this.setArrow('traveller')}></i></th>
                                                            <th onClick={() => this.onSortChange('authID')}>Auth req<i className={this.setArrow('authID')}></i></th>
                                                            <th>Got Folio<i></i></th>
                                                            <th>Called<i></i></th>
                                                            <th>Process<i></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.callFolioLog.map((prop, key) => {
                                                            if (prevAuthReqId != prop["authID"]) {
                                                                prevAuthReqId = prop["authID"]
                                                                var i = 0;
                                                                var concateTravelName = '';
                                                                var filterTravelName = this.state.callFolioLog.filter(x => x.authID == prop["authID"]).map(m => m.traveller)
                                                                for (let travelName of filterTravelName) {
                                                                    //console.log("travelName:" + i, travelName);
                                                                    if (i === 0) {
                                                                        concateTravelName = travelName;
                                                                    } else {
                                                                        concateTravelName = concateTravelName + "<br>" + travelName;
                                                                    }
                                                                    i = i + 1;
                                                                }
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{prop["company"]}</td>
                                                                        <td>{prop["fax"]}</td>
                                                                        <td>{Utils.FormatDate(prop["servicedate"])}</td>
                                                                        <td>{parse(concateTravelName)}</td>
                                                                        <td>{prop["authID"]}</td>
                                                                        <td>
                                                                            <input type="checkbox" name="foliocheck" checked={prop["foliocheck"]} onChange={this.checkboxchange.bind(this, key, "foliocheck")} />
                                                                        </td>
                                                                        <td>
                                                                            {prop["called"]}&nbsp;<input type="checkbox" name="calledcheck" checked={prop["calledcheck"]} onChange={this.checkboxchange.bind(this, key, "calledcheck")} />
                                                                        </td>
                                                                        <td>
                                                                            <Button id="Update" type="button" size="sm" disabled={loading} onClick={(e) => this.onSaveUpdate(e, prop)} >
                                                                                {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px", color: "#3472F7" }}
                                                                                    />
                                                                                )}
                                                                                {loading && <span style={{ color: "#3472F7" }} >wait...</span>}
                                                                                {!loading && <span>Update</span>}
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}