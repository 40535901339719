import React, { Component } from "react";
import { ICarVoucherHistoryParameter, Istate } from "../../../Model/ICarVoucherHistory";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,FormControl,Table,InputGroup,FormGroup} from "../../../../node_modules/react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import DatePicker from 'react-datepicker';
import validator from 'validator';
import dateFormat from 'dateformat'
import { CarVoucherController } from "../../../Controllers/CarVoucherController";
import '../../../assets/css/Style.css'
import { SubmitButton } from "../Common/SubmitButton";
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { SearchBoxCarVoucher } from "../../CustomComponents/SearchBoxCarVoucher";
import addSubtractDate from 'add-subtract-date';
import * as Utils from '../../../js/Utils.js';

export class CarVoucherHistory extends Component<ICarVoucherHistoryParameter,Istate> {
    constructor(props:ICarVoucherHistoryParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {searchDays: "14", startDate: "", endDate: new Date(), 
                    searchString: "*", searchScope: "voucher_id"},
            selectfields: {filterBy:''},
            errors:{},
            errMsg:"",
            loading:false,
            carVoucherHistory:[],
            modal: {modalSpinnerShow: false} ,
            userId:"", 
            startDate: "",   
            endDate:"",  
            currentSort: 'down',
            currentColumn: 'voucherId',      
        }
        
        this.handleCallback = this.handleCallback.bind(this);        
        //this.handleTimeFormat = this.handleTimeFormat.bind(this);
        //this.handleOptionChange = this.handleOptionChange.bind(this);
        //this.handleHFMSelectChange = this.handleHFMSelectChange.bind(this); 
    }

    componentWillMount(){
        let fields = this.state.fields;
        var date = new Date();
        date.setDate(date.getDate() - this.state.fields.searchDays);
        fields["startDate"] = date;
        this.setState({ fields })
        this.pageLoad();     
    }

    handleCallback = (searchDays, startDate, endDate, searchString, searchScope) => {
        
        let fields = this.state.fields;
        fields["searchDays"] = searchDays;
        fields["startDate"] = startDate;
        fields["endDate"] = endDate;
        fields["searchString"] = searchString;
        fields["searchScope"] =  searchScope;
        this.setState({ fields });
        this.pageLoad();
    }
    
    pageLoad(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({modal});
        let carVoucherController = new CarVoucherController();
        let addEndDate = new Date()
        addEndDate = addSubtractDate.add(this.state.fields.endDate,1,"day")
        var carVoucherHistory = {
            filterBy: this.state.fields.searchScope,            
            searchString:this.state.fields.searchString.replace("*",""),
            startDate: format(this.state.fields.startDate, 'MM/dd/yyyy') ,
            endDate: format(addEndDate, 'MM/dd/yyyy'),
            box:"",
            labelId: sessionStorage.getItem("label_id"),
            sysop:sessionStorage.getItem("sysop"),
            userId: sessionStorage.getItem("uid"),            
        }
        //console.log(carVoucherHistory);
        carVoucherController.getCarVoucherHistory(carVoucherHistory).then(jsoncarVoucherHistory => {
            this.setState({
                carVoucherHistory: JSON.parse(JSON.stringify(jsoncarVoucherHistory))
            });
            //console.log(this.state.carVoucherHistory);
            modal['modalSpinnerShow']=false;
            this.setState({modal});
        }).catch(error => {
            console.error("There was an error...," + error)
        });
    }
        
    onSubmit(e){
        
        e.preventDefault();
        
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = [...this.state.carVoucherHistory];
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            carVoucherHistory: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });

        //console.log("onSortChange - sort :", currentSort)
        //console.log("onSortChange - column :", currentColumn)
    }

    onSelect(voucherId){
        //this.props.history.push({pathname:'/admin/carvoucherprint',state:{voucherId:voucherId}});
        //window.open('/carvoucherprint?voucherId='+ voucherId, '_blank', 'location=yes,height=570,width=620,scrollbars=yes,status=yes');          
        window.open('/carvoucherprint?voucherId='+ voucherId, '_blank', 'height=570,width=620, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no');
    }

    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Car Voucher History</h4><p className="category"></p></div>
                            <Card.Body>
                            <Form>
                              <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                              <Row>
                                <Col sm={11}></Col>
                                <Col sm={1}>
                                    <Button id="submitVoucher" type="button" size="sm" disabled={loading} onClick={() => window.print()} >
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                            {!loading && <span>Print</span>}
                                        </Button>
                                </Col> 
                                </Row>
                              <SearchBoxCarVoucher parentCallback = {this.handleCallback} />
                              <Row>
                                    <Col sm={12}>
                                        <div style={{ width: '100%', height: "auto" }}>
                                            <Table hover striped bordered >
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => this.onSortChange('voucherId')}>Voucher Id<i className={this.setArrow('voucherId')}></i></th>
                                                        <th onClick={() => this.onSortChange('passengerName')}>Passenger Name<i className={this.setArrow('passengerName')}></i></th>
                                                        <th onClick={() => this.onSortChange('createdDate')}>Created Date<i className={this.setArrow('createdDate')}></i></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.carVoucherHistory.map((prop, key) => {
                                                        return (
                                                            <tr key={key} onClick={() => this.onSelect(prop["voucherId"])} style={{cursor:"pointer"}}>
                                                                <td>{prop["voucherId"]}</td>
                                                                <td>{prop["passengerName"]}</td>
                                                                <td>{Utils.FormatDate(prop["createdDate"])}</td>                                                                
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col>
                                        {this.state.carVoucherHistory.length > 3 ? "" :
                                            <table>
                                                <tr>
                                                    <td style={{height:"120px"}}>&nbsp;</td>
                                                </tr>
                                            </table>
                                        }
                                    </Col>
                                </Row>                     
                            </Form>                         
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}