import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class BCDWSController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    BCDSubmitTAValidation (data: any){        
        var url = this.variables.Url+ this.variables.Controller.bcdws + this.variables.bcdwsService.BCDSubmitTAValidation;
        return this.serviceProxy.get(url,data).then(response=>{
                return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    addSortRemarkToPNR (data: any){        
        var url = this.variables.Url+ this.variables.Controller.bcdws + this.variables.bcdwsService.addSortRemarkToPNR;
        return this.serviceProxy.patch(url,data).then(response=>{
                return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    addSortBCDRemarkToPNR (data: any){        
        var url = this.variables.Url+ this.variables.Controller.bcdws + this.variables.bcdwsService.addSortBCDRemarkToPNR;
        return this.serviceProxy.post(url,data).then(response=>{
                return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}
