import React, { Component, CSSProperties } from "react";
import { IRadioRoutingParameter, Istate } from "../../../Model/IRadioRouting";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { BCDcodeMaintenanceController } from "../../../Controllers/BCDcodeMaintenanceController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';

export class RadioRouting extends Component<IRadioRoutingParameter,Istate> {
    constructor(props:IRadioRoutingParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {email:'',id:''},
            selectfields:{cwEmail:'N',cwReport:'N',rpException:'N',rpTravelCount:'N'},
            errors:{},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            radioRoutingGroups:[],                             
        }  
        this.handleTextChange = this.handleTextChange.bind(this);   
        this.handleSelectChange = this.handleSelectChange.bind(this);   
        this.onEdit = this.onEdit.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onSubmit = this.onSubmit.bind(this); 
        this.onDelete = this.onDelete.bind(this); 
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true; 
        this.getRadioRoutingList(); 
    }

    onEdit = (item,e:any) => {
        e.preventDefault();
        let modal = this.state.modal;
        let fields = this.state.fields;  
        let selectfields = this.state.selectfields;       
        this.setState({errMsg:""})        
        
        fields["email"] = item["email"]
        fields["id"] = item["id"]
        selectfields["cwEmail"] = item["cwEmail"]
        selectfields["cwReport"] = item["cwReport"]   
        selectfields["rpException"] = item["rpException"]              
        selectfields["rpTravelCount"] = item["rpTravelCount"] 
        
        this.setState({modal,fields,selectfields});
          
    }

    onSubmit(e:any){
        e.preventDefault();
         let modal = this.state.modal;
         modal['modalSpinnerShow']=true;
         this.setState({errMsg:""})        
         let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
        if(this.validateEmail())
         {
            var data = {                
                id:this.state.fields.id,
                eMail:this.state.fields.email, 
                cwExEmail:this.state.selectfields.cwEmail,
                cwExRep:this.state.selectfields.cwReport,
                rpAppExEmail:this.state.selectfields.rpException,
                rpTravelCountExpEmail:this.state.selectfields.rpTravelCount,      
            }
            bcdcodeMaintenanceController.inserUpdateRadioRouting(data).then(response => { 
                this.clearText();
                this.getRadioRoutingList(); 
                if(this.state.fields.id != "")
                    alert('Compliance Officer Updated')
                else
                   alert('Compliance Officer Added')             
            }).catch(error => {
                console.error("There was an error!", error)
            })      
         }
        modal['modalSpinnerShow']=false;
        this.setState({modal}); 
          
    }

    validateEmail()
    {
        var isValid =true
        var emailregex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);         
        let errors = this.state.errors;        
       if(this.state.fields.email != "")
        {
            if(!emailregex.test(String(this.state.fields.email).toLowerCase()))
            {
                isValid = false;
                errors["email"] = 'Email ID Not Valid'                
            }            
        }
        else
        {
            isValid = false;
            errors["email"] = 'Please Enter Email ID'
        }
        this.setState({errors})
        return isValid;
    }

    async onDelete(item,e:any) { 
        var flag = await confirm("Are you sure want to Delete?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
            if(flag){
            var data = {                
                id:item["id"]                
            }
            let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
            bcdcodeMaintenanceController.deleteRadioRouting(data).then(response => { 
                this.clearText();
                this.getRadioRoutingList(); 
                alert('Deleted..')                
            }).catch(error => {
                console.error("There was an error!", error)
            }) 
        } 
    }

    onClear= (e:any) => {
        e.preventDefault();       
        this.clearText();
    }

    clearText()
    {
        let fields = this.state.fields;  
        let selectfields = this.state.selectfields; 
        let errors = this.state.errors;
        fields["email"] = "";
        fields["id"] = "";
        selectfields["cwEmail"] = "N";
        selectfields["cwReport"] = "N"; 
        selectfields["rpException"] = "N";             
        selectfields["rpTravelCount"] = "N"; 
        errors["email"] = "";
        this.setState({fields,selectfields,errors});
    }

    getRadioRoutingList =()=>{
        let modal = this.state.modal;         
        let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
        
        bcdcodeMaintenanceController.GetRadioRoutingList().then(jsonRadioRoutingList => {
            this.setState({ radioRoutingGroups: JSON.parse(JSON.stringify(jsonRadioRoutingList))});
            modal['modalSpinnerShow']=false;          
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })  
        modal['modalSpinnerShow']=false;      
        this.setState({modal})
    }

    handleTextChange(e:any){  
        let fields = this.state.fields;    
        let errors = this.state.errors;          
        fields[e.currentTarget.id] = e.currentTarget.value;  
        errors[e.currentTarget.id] =""  
        if(e.currentTarget.value === "")    
            fields["id"] = "";    
        this.setState({fields,errors});       
    }

    handleSelectChange = (e: any) => {
                
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ selectfields });
    }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'15%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'15%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Compliance Officer</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                   <Row>
                                       <Col sm={4} style={{marginTop:"2%"}}><Form.Label>Compliance Officer Email Address<text style={{color:"Red"}}>*</text></Form.Label></Col>
                                        <Col sm={4}>
                                            <FormInputs
                                                properties={{id:"email",label:"",type:"text",name:"email",value:this.state.fields.email,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.email}
                                                fieldRequired={false}
                                                disbled={true}
                                            />
                                        </Col>                                        
                                    </Row>
                                    <Row> 
                                        <Col sm={4}><Form.Label>Contest Winner Exception Email (Y/N)</Form.Label></Col>
                                        <Col sm={1}>
                                            <Form.Group>
                                                <div>                                                
                                                    <Form.Control as="select" name="cwEmail" id="cwEmail" className="form-control-sm" value={this.state.selectfields.cwEmail} onChange={this.handleSelectChange}>
                                                    <option value="Y">Y</option>
                                                    <option value="N">N</option>                                                    
                                                </Form.Control></div>
                                            </Form.Group>
                                        </Col>                                    
                                        <Col sm={4}><Form.Label>Contest Winner Exception Report (Y/N)</Form.Label></Col>
                                        <Col sm={1}>
                                            <Form.Group>
                                                <div>                                                
                                                    <Form.Control as="select" name="cwReport" id="cwReport" className="form-control-sm" value={this.state.selectfields.cwReport} onChange={this.handleSelectChange}>
                                                    <option value="Y">Y</option>
                                                    <option value="N">N</option>                                                    
                                                </Form.Control></div>
                                            </Form.Group>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <Col sm={4}><Form.Label>Radio Personnel Exception Email (Y/N)</Form.Label></Col>
                                        <Col sm={1}>
                                            <Form.Group>
                                                <div>                                                
                                                    <Form.Control as="select" name="rpException" id="rpException" className="form-control-sm" value={this.state.selectfields.rpException} onChange={this.handleSelectChange}>
                                                    <option value="Y">Y</option>
                                                    <option value="N">N</option>                                                    
                                                </Form.Control></div>
                                            </Form.Group>
                                        </Col>                                    
                                        <Col sm={4}> <Form.Label>Radio Personnel Travel Count Exception Email (Y/N)</Form.Label></Col>
                                        <Col sm={1}>
                                            <Form.Group>
                                                <div>                                               
                                                    <Form.Control as="select" name="rpTravelCount" id="rpTravelCount" className="form-control-sm" value={this.state.selectfields.rpTravelCount} onChange={this.handleSelectChange}>
                                                    <option value="Y">Y</option>
                                                    <option value="N">N</option>                                                    
                                                </Form.Control></div>
                                            </Form.Group>
                                        </Col>
                                    </Row>  
                                    <Row style={{marginBottom:"2%"}}>
                                    <Col sm={4}></Col>
                                        <Col sm={2} >
                                            <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSubmit(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Add/Update</span>}
                                            </Button>
                                        </Col>
                                        <Col sm={2}>
                                            <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onClear(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Clear</span>}
                                            </Button>
                                        </Col>
                                    </Row>                                    
                                    <Row>                                        
                                       <Col sm={12}>
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th style={thStyle}>Compliance Officer Email</th>
                                                                <th style={thStyle}>Contest Winner Exception Email (Y/N)</th>
                                                                <th style={thStyle}>Contest Winner Exception Report (Y/N)</th>
                                                                <th style={thStyle}>Radio Personnel Exception (Y/N)</th>  
                                                                <th style={thStyle}>Radio Personnel Travel Count Exception Email (Y/N)</th>                                                      
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.radioRoutingGroups.map((prop, key) => {
                                                                return (
                                                                    <tr style={{textAlign:"center"}}  key={key}> 
                                                                       <td style={tdStyle}>{prop["email"]}</td>                                                                                                                         
                                                                       <td style={tdStyle}>{prop["cwEmail"]}</td>
                                                                       <td style={tdStyle}>{prop["cwReport"]}</td>
                                                                       <td style={tdStyle}>{prop["rpException"]}</td>
                                                                       <td style={tdStyle}>{prop["rpTravelCount"]}</td>
                                                                       <td style={tdStyle}>
                                                                            <Button id="edit" type="button" size="sm" disabled={loading} style={{ marginRight: "5px" }} onClick={(e) => this.onEdit(prop,e)}>
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Edit</span>}
                                                                                </Button>
                                                                                <Button id="delete" type="button" size="sm" disabled={loading} variant="danger" onClick={(e) => this.onDelete(prop,e)}>
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Delete</span>}
                                                                                </Button>
                                                                       </td>
                                                                    </tr>
                                                                    
                                                                )
                                                            })}                                                            
                                                        </tbody>
                                                </Table>                                                        
                                            </div>                                       

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }

}