import React, { Component, CSSProperties } from "react";
import { NavLink,Link } from "react-router-dom";
import {FormLabel,Container,Row,Col,Form,Navbar, Nav, NavItem,Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../node_modules/react-bootstrap";
import {FormInputs} from '../CustomComponents/FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {AdminNavbarLinks} from "../CustomComponents/NabvBar/AdminNavbarLinks";
import logo from "../../assets/img/umglogo.png";
import {MultiFilter} from "../../Common/MultiFilter"
import { faAngleDown,faAngleUp} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { isNullishCoalesce } from "typescript";
import computerName from 'computer-name';
import * as GlobalVar from '../../js/GlobalVar.js';
import * as Utils from '../../js/Utils.js';
import { CommonController } from "../../Controllers/CommonController";
import { Variables } from '../../Variables/Variables';

export class Sidebar extends Component<ISidebar,Istate> {
  constructor(props) {
    super(props);
    this.state = {
      fields: {showMaintenance:false, showReport:false, showeTABCDCodes:false},
      width: window.innerWidth,
      Routes:[]
    };
  }
  ShowHideComponent = (e:any) => {
    e.persist(); 
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation(); 
    let fields = this.state.fields;
    let navLinkText:string = e.currentTarget.innerText.toLowerCase();
 
    if (e.currentTarget.innerText.toLowerCase().trim() === 'maintenance')
    {
      if (fields['showMaintenance'] === true) {
        fields['showMaintenance'] = false;
      } else {
        fields['showMaintenance'] = true;
      }
      this.setState({ fields });
    }
    else if(e.currentTarget.innerText.toLowerCase().trim() === 'report')
    {
      if (fields['showReport'] === true) {
        fields['showReport'] = false;
      } else {
        fields['showReport'] = true;
      }
      this.setState({ fields });
    }
    else
    {
      if (fields['showeTABCDCodes'] === true) {
        fields['showeTABCDCodes'] = false;
      } else {
        fields['showeTABCDCodes'] = true;
      }
      this.setState({ fields });
    }
  };
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentWillMount() {
    //debugger;
   
    toast.configure();
  
    this.setState({                           
      Routes : JSON.parse(JSON.stringify(this.props.routes),)
    });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  createRoute(prop:any,key:any){
    return (
      <li
        className={
          prop.upgrade
            ? "active active-pro"
            : this.activeRoute(prop.layout + prop.path)
        }
        key={key}
      >
        <NavLink
          to={{
            pathname:prop.layout + prop.path,
             state:{
              uid:sessionStorage.getItem("uid")
            }
          }}
          className="nav-link"
          activeClassName="active"
        >
          <p>{prop.name}</p>
        </NavLink>
      </li>
    );
  }
  //Filter the router list based on the role
  assignRoleRouter(prop:any){
    //sessionStorage.setItem("user_type_id","S");
    //sessionStorage.setItem("uid","sysAdmin");

    //debugger;
    let UserType_Id: string | null = sessionStorage.getItem("user_type_id");
    let blnUserHasAccessToTravellerInfoReport: boolean | false;
    if (sessionStorage.getItem("menuVariable") != null){
        blnUserHasAccessToTravellerInfoReport = sessionStorage.getItem("menuVariable")!.split('~')[0] === 'true';
    }else{
      blnUserHasAccessToTravellerInfoReport = false;
    }
    var mnu: Array<string> = []; 
    let reltiveUrl:string = Utils.getWebServerRelativeUrl();

    if (UserType_Id !== null && reltiveUrl.indexOf("selectrole") === -1){
      //if(UserType_Id === "S"){
        mnu.push('MNU_0'); 
      //}
      if(UserType_Id === "S" || sessionStorage.getItem("shadow") === "sysAdmin"){
        mnu.push('MNU_1'); 
      }
      if(UserType_Id === "FS"){
        mnu.push('MNU_2'); 
      }
      if(UserType_Id === "S"){
        mnu.push('MNU_3'); 
        mnu.push('MNU_31'); //Maintenance
        mnu.push('MNU_32'); //Reports
        mnu.push('MNU_33');
      }
      if(sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S"){
        mnu.push('MNU_4'); 
      }
      if(UserType_Id === "R" || sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S"){
        mnu.push('MNU_5'); 
      }
      if(UserType_Id === "A" || sessionStorage.getItem("is_dha") === "true" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_6'); 
      }
      if(UserType_Id === "A" || UserType_Id === "AU" || sessionStorage.getItem("is_dha") === "true" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_7'); 
      }
      if(blnUserHasAccessToTravellerInfoReport){
        mnu.push('MNU_8'); 
      }
      if(UserType_Id === "A" || sessionStorage.getItem("is_dha") === "true" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_9'); 
      }
      if(UserType_Id === "DB" || UserType_Id === "BB" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_A');         
      }
      if(UserType_Id === "DB" || UserType_Id === "BB" || UserType_Id === "A" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        //|| UserType_Id === "R"
        mnu.push('MNU_B');  
      }
      if(UserType_Id === "DB" || UserType_Id === "BB" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_B1');  
      }
      if(UserType_Id === "DB" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_C');         
      }
      if(sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S"){
        mnu.push('MNU_D');         
      }
      if(UserType_Id === "A" || UserType_Id === "R" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_E');  
      }
      if(sessionStorage.getItem("voucher_plus") === "true" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_F');  
      }
      if(UserType_Id === "A" || UserType_Id === "R" || sessionStorage.getItem("uid") === "sysAdmin" || UserType_Id === "S" || UserType_Id === "BB"){
        mnu.push('MNU_G');  
      }
      if(UserType_Id === "BB" || UserType_Id === "W" || UserType_Id === "CT" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        //|| sessionStorage.getItem("wtp_history") === "true"
        mnu.push('MNU_H');  
      }
      if(UserType_Id === "BB" || UserType_Id === "W" || UserType_Id === "CT" || UserType_Id === "DB" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        //|| sessionStorage.getItem("wtp_history") === "true"
        mnu.push('MNU_I');  
      }
      if(UserType_Id === "BB"){
        mnu.push('MNU_J');
      }
      if(sessionStorage.getItem("car_service_company_id") !== "null" || UserType_Id === "BB" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_K');  
      }
      if(sessionStorage.getItem("car_service_company_id") !== "null" || UserType_Id === "BB" || sessionStorage.getItem("uid") === "lcampion" || sessionStorage.getItem("uid") === "kumikosato" || sessionStorage.getItem("uid") === "tmccue" || sessionStorage.getItem("uid") === "lcothern" || sessionStorage.getItem("uid") === "aepstein" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_L');  
      }
      if(sessionStorage.getItem("tracking_grid") === "true" || UserType_Id === "S" || UserType_Id === "BB"){
        mnu.push('MNU_M');  
      }
      if(UserType_Id === "A" || UserType_Id === "R" || (sessionStorage.getItem("uid") === "sysAdmin" && UserType_Id === "S")){
        mnu.push('MNU_N');  
      }
      if(UserType_Id === "FS"){ //Removed UserType_Id === "S" || added in MNU_31
        mnu.push('MNU_O');  
      }
      //if(UserType_Id === "S"){
        //mnu.push('MNU_P');  
      //}
      mnu.push('MNU_Q');
      if (sessionStorage.getItem("shadow") !== ""){
        mnu.push('MNU_R');  
      }
    }
    else
    {
      mnu.push('MNUNULL'); 
    }

    let filters = {
      view : mnu
    };
    let multiFilter = new MultiFilter();
    return multiFilter.multiFilter(this.props.routes,filters);
  }
  setTarget(prop:any)
  {
    if(prop.name === "What's New" || prop.name === 'FAQ')
    {
      return "_blank"
    }
    else
    {
      return ""
    }
  }
  displayNavLink(prop:any) {
    let subMenuCount = prop.submenu.length;
    let propName = prop.name;
    if(subMenuCount > 0){
      return(
        <NavLink
          to={{
            state:{
              uid:sessionStorage.getItem("uid"),
              orderType:''
            }
          }}
          className="nav-link"
          activeClassName="active"
          onClick={this.ShowHideComponent}
        >
          <p>{prop.name}{this.displayToggleIcon(subMenuCount, prop.name)}</p>
        </NavLink>
      );
    }
    else{
      if(propName === "Create Travel Authorization"){
        return(
          <NavLink
            to={{
              pathname:prop.layout + prop.path,
              state:{
                uid:sessionStorage.getItem("uid"),
                authType:"Travel"
              }
            }}
            className="nav-link"
            activeClassName="active"
          >
            <p>{prop.name}</p>
          </NavLink>
        );
      }
      else if(propName === "Create Standalone Car Service"){
        return(
          <NavLink
            to={{
              pathname:prop.layout + prop.path,
              state:{
                uid:sessionStorage.getItem("uid"),
                authType:"Car Service"
              }
            }}
            className="nav-link"
            activeClassName="active"
          >
            <p>{prop.name}</p>
          </NavLink>
        );
      }
      else{
        return(
          <NavLink
            to={{
              pathname:prop.layout + prop.path,
              state:{
                uid:sessionStorage.getItem("uid"),
              }
            }}
            target={this.setTarget(prop)}
            className="nav-link"
            activeClassName="active"
            /*onClick={alert('selected')}*/
          >
            <p>{prop.name}</p>
          </NavLink>
        );
    }
  }
  }
  //info, success, warn, error, toast without properties
  //eg, toast('alert'), toast.info('alert'), toast.warn('alert')
  notifyFG = () => toast.warn('Approval process does not exist for ordering from this label: ' + sessionStorage.getItem("label_id"),{position:toast.POSITION.TOP_RIGHT});
  displayToggleIcon(cnt,name) {
    let fields = this.state.fields;
    if(cnt > 0){
      if (name.toLowerCase() === "maintenance")
      {
        if(fields['showMaintenance']=== true)
        {
          return <span style={{float:"right",paddingRight:"10px"}}><FontAwesomeIcon icon={faAngleUp} size="1x" /></span>
        }
        else
        {
          return <span style={{float:"right",paddingRight:"10px"}}><FontAwesomeIcon icon={faAngleDown} size="1x" /></span>
        }
      }
      else if (name.toLowerCase() === "report")
      {
        if(fields['showReport'] === true)
        {
          return <span style={{float:"right",paddingRight:"10px"}}><FontAwesomeIcon icon={faAngleUp} size="1x" /></span>
        }
        else
        {
          return <span style={{float:"right",paddingRight:"10px"}}><FontAwesomeIcon icon={faAngleDown} size="1x" /></span>
        }
      }
      else
      {
        if(fields['showeTABCDCodes'] === true)
        {
          return <span style={{float:"right",paddingRight:"10px"}}><FontAwesomeIcon icon={faAngleUp} size="1x" /></span>
        }
        else
        {
          return <span style={{float:"right",paddingRight:"10px"}}><FontAwesomeIcon icon={faAngleDown} size="1x" /></span>
        }
      }
    }
  }
  displayChildNavLink(prop){
  return <>{prop.submenu.map((p, i=30) => {
    return <li
    className={
      prop.upgrade
        ? "active active-pro"
        : this.activeRoute(p.layout + p.path)
    }
    key={i}
  >
    <NavLink
      to={{
        pathname:p.layout + p.path,
        state:{
          uid:sessionStorage.getItem("uid")
        }
      }}
      className="nav-link"
      activeClassName="active"
    >
    <p style={{paddingLeft:"30px"}}>{p.name}</p>
    </NavLink>
  </li>
  })}</>
}
getEnviroment=(domainURL)=>{
  if (sessionStorage.getItem("user_type_id") === "S"){
    if (domainURL.indexOf("eta2") > -1){
      return "Production" + ' (' + computerName() +') ';
    }
    else if(domainURL.indexOf("etaupgrade-test") > -1){
      return "Test" + ' (' + computerName() +') ';
    }
    else if(domainURL.indexOf("etauat2-test") > -1){
      return "Staging" + ' (' + computerName() +') ';
    }
    else if(domainURL.indexOf("8008") > -1){
      return "Port 8008" + ' (' + computerName() +') ';
    }
    else
    {
      return "Development" + ' (' + computerName() +') ';
    }
  }
  else
  {
    return '';
  }
}
getUserName(){
  //debugger;
  if(sessionStorage.getItem("user_type_id") === "S" || (sessionStorage.getItem("shadow") !== null && sessionStorage.getItem("shadow") !== "")){
    return ' - ' + sessionStorage.getItem("uid");
  }
  else{
    return '';
  }
  
}
render(){
  const routerList = this.assignRoleRouter(this.props.routes);
  const sidebarBackground = {
    backgroundImage: "url(" + this.props.image + ")"
  };
  const styles = {
    imageSize : {
      "width":"142px",
      "height":"45px",
      "padding-left":"30px"
    },
    imageBg : {
      "background-color": "#00B0F0",
      "height": "62px"
    },
    constStyle:{
      "fontSize": "14px",
      "paddingLeft": "15px",
      "paddingBottom": "15px",
      "height": "40px",
      "fontFamily": 'sofia-pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      "fontWeight": 500,
      "color": "#202121",
      "borderBottom": "1px solid #e6eaea",
      "width": "100%",
      "paddingTop":"6px"
    }
     //"#F7F7f9",
  } 
  var showTaggle:CSSProperties ={
    "display":"block"

  }
  var hideTaggle:CSSProperties ={
    "display":"none"
  }
  const showTagged=(prop)=>{
    let fields = this.state.fields;
    if (prop.submenu.length > 0){
      if(prop.name.toLowerCase().trim() === 'maintenance')
      {
        return <div style={fields['showMaintenance'] ? showTaggle : hideTaggle}>
          {this.displayChildNavLink(prop)}
        </div>
      }
      else if(prop.name.toLowerCase().trim() === 'report')
      {
        return <div style={fields['showReport'] ? showTaggle : hideTaggle}>
          {this.displayChildNavLink(prop)}
        </div>
      }
      else
      {
        return <div style={fields['showeTABCDCodes'] ? showTaggle : hideTaggle}>
          {this.displayChildNavLink(prop)}
        </div>
      }
    }
  }
return (
  <div
    id="sidebar"
    className="sidebar"
    data-color={this.props.color}
    data-image={this.props.image}
  >
      {this.props.hasImage ? (
        <div className="sidebar-background" />
      ) : (
        null
      )}
    <div id="logo" className="logo">
      <a href="#">
        <div className="logo-img" >
          <img src={logo} alt="logo_image" style={styles.imageSize}/>
        </div>
      </a>
    </div>
    <div className="sidebar-wrapper">
      <ul className="nav">
        {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
        {routerList.map((prop, key) => {
          let domainURL:string = Utils.getAbsoluteDomainUrl();
            if (!prop.redirect){
              //return this.createRoute(prop,key);
              if(prop.name === 'MachineName'){
                return <div id="divNav" key={key} 
                style={{"width":"100%","color": "#487afa ",fontSize:(sessionStorage.getItem("user_type_id") === 'R')?"28px":"16px","fontWeight": 700,"padding": "0px",
                "backgroundColor":"#F7F7FF", "textAlign":"center","paddingLeft":"0px", borderBottom:"1px solid #e6eaea",
                "fontFamily":"sofia-pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;"}}>
                  {' ' + this.getEnviroment(domainURL)}
                  Menu{this.getUserName()}
                </div>
              }
              /**commented merlin code */
              /*else if(prop.name === "Merlin" && !GlobalVar.production){
                return <div id="divNav" key={key} style={{"width":"100%"}}><li
                        className={
                          prop.upgrade
                            ? "active active-pro"
                            : this.activeRoute(prop.layout + prop.path)
                        }
                        key={key}
                      >
                        {this.displayNavLink(prop)}
                      </li>
                      {showTagged(prop)}
                    </div>
              }*/
              else if((prop.name === "Hotel Extended Stay" || prop.name === "Rental Car Extended Stay" || prop.name === "Direct Bill Incoming Rental Car TA's")){
                let variables = new Variables();
                if(!variables.production && domainURL.indexOf("usitg-wongm") > -1){
                  return <div id="divNav" key={key} style={{"width":"100%"}}><li
                        className={
                          prop.upgrade
                            ? "active active-pro"
                            : this.activeRoute(prop.layout + prop.path)
                        }
                        key={key}
                      >
                        {this.displayNavLink(prop)}
                      </li>
                      {showTagged(prop)}
                    </div>
                }
              }
              else if(prop.name === 'Users Logged In:'){
                return <div id="divNav" key={key} style={styles.constStyle}>
                  {prop.name + ' ' + sessionStorage.getItem("menuVariable")!.split('~')[1]}
                </div>
              }
              else if(prop.name === 'Active In Last 10 Min:'){
                return <div id="divNav" key={key} style={styles.constStyle}>
                  {prop.name + ' ' + sessionStorage.getItem("menuVariable")!.split('~')[2]}
                </div>
              }
              else if(prop.name === 'IIS Server Time:'){
                return <div id="divNav" key={key} style={styles.constStyle}>
                  {prop.name + ' ' + Utils.SysDateTime()}
                </div>
              }
              else if(prop.name === 'SQL Server Time:'){
                return <div id="divNav" key={key} style={styles.constStyle}>
                  {prop.name + ' ' + sessionStorage.getItem("menuVariable")!.split('~')[3]}
                </div>
              }  
              else{
                return <div id="divNav" key={key} style={{"width":"100%"}}><li
                        className={
                          prop.upgrade
                            ? "active active-pro"
                            : this.activeRoute(prop.layout + prop.path)
                        }
                        key={key}
                      >
                        {this.displayNavLink(prop)}
                      </li>
                      {showTagged(prop)}
                    </div>
              }                
              }
              return null;
            })}
      </ul>
    </div>
  </div>
  );
  }
}

interface ISidebar{
    location?:any;
    image?:string;
    color?:string;
    hasImage?:boolean;
    routes?:any;
}
interface Istate {
    fields:any;
    width:number;
    Routes:[];
}