import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import * as GlobalVar from '../js/GlobalVar.js';
import * as Utils from '../js/Utils.js';

export class UserDetailsController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getUser(userId : string){
        
        var data = {
            userId: userId,
        };
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getUser;
        return this.serviceProxy.get(url,data).then(response => {
            return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    getUserRoleType(userId:string, userTypeId:string){
        
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserRoleType;
        var data = {
            userId: userId,
            userTypeId: userTypeId
        };
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonRoleType : Array<{"id": string; "name": string, "carId":string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { User_Type_Id: string; description: string; car_id: string}, _index: number){
                    jsonRoleType.push({"id": item.User_Type_Id, "name": item.description, "carId": (item.car_id === null)?'':item.car_id })
                }
                return JSON.parse(JSON.stringify(jsonRoleType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonRoleType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getUserExpenseType(userId:string){
        
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserExpenseType;
        var data = {
            userId: userId
        };
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonExpenseType : Array<{"id": string; "name": string; "bit":boolean }> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { expense_type_id: string; description: string; bit: boolean}, _index: number){
                    jsonExpenseType.push({"id": item.expense_type_id, "name": item.description, "bit":item.bit })
                }
                return JSON.parse(JSON.stringify(jsonExpenseType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonExpenseType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getUserApproverTier(){
        
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserApproverTier;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonApproverTier : Array<{"id": string; "name": string; }> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { approver_tier_id: string; description: string; }, _index: number){
                    jsonApproverTier.push({"id": item.approver_tier_id, "name": item.description })
                }
                //console.log("ApproerTierController:", JSON.parse(JSON.stringify(jsonApproverTier).replace(/"\s+|\s+"/g, '"')))
                return JSON.parse(JSON.stringify(jsonApproverTier).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonApproverTier).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getUserLabels(userId:string, sysop:string){
        
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserLabels;
        var data = {
            userId: userId,
            sysop: sysop
        };
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string; "is_active": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { label_id: string; description: string;  is_active: string }, _index: number){
                    jsonLabel.push({"id": item.label_id, "name": item.description, "is_active": item.is_active ? "Yes" : "No"})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getUserCrossLabel(userId:string){
        
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserCrossLabel;
        var data = {
            userId: userId
        };
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string; "is_active": string, "bit": boolean}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { label_id: string; description: string;  is_active: string, bit: boolean }, _index: number){
                    jsonLabel.push({"id": item.label_id, "name": item.description, "is_active": item.is_active ? "Yes" : "No", "bit": item.bit})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getUserDetail(userId : string){
        
        var data = {
            userId: userId,
        };
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserDetail;
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
        }
    
    updateReEnableUserLogin (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.updateReEnableUserLogin;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateADUserDisabled (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.updateADUserDisabled;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateADUserServiceAccount (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.updateADUserServiceAccount;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    getUserActiveRoleCount(adUserId : string){
        
        var data = {
            in_user_id: adUserId,
        };
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserActiveRoleCount;
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
        }

    getUserSrExecApproval(data){
        
        var data1 = {
            userID: data.userID,
            sessionUserID: data.sessionUserID,
            sessionSysop: data.sessionSysop
        };
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserSrExecApproval;
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
        }
    getUserDepartment(data){
        
        var data1 = {
            userID: data.userID,
            sessionUserID: data.sessionUserID,
            sessionSysop: data.sessionSysop
        };
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserDepartment;
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
    }
    updateUserDetail (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.updateUserDetail;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    insertUserDetail (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.insertUserDetail;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    insertUserLabel (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.insertUserLabel;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }  
    insertUserDept (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.insertUserDept;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }  
    insertUserDH (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.insertUserDH;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }  
    insertUserExpType (data){
        var url = this.variables.Url+ this.variables.Controller.user + this.variables.userService.insertUserExpType;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    } 
    getUserSearch(data){
        
        var data1 = {
            searchValue: data.searchValue,
            type:data.type
        };
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getUserSearch;
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
        }
    getADUser(adUserId : string){
        
        var data = {
            adUserId: adUserId
        };
        var url = this.variables.Url + this.variables.Controller.user + this.variables.userService.getADUser;
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
        }
       
}