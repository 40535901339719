import React, { Component } from "react";
import { Container, Row, Col, Form, Alert, Button, Card, FormControl } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert,confirm} from 'react-bootstrap-confirmation';
import {History, LocationState} from "History";
import * as Utils from '../../../js/Utils.js';
import { AuthStatus } from "../Common/AuthStatus";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { TASummary } from "../Common/TASummary";
import { CommonController } from "../../../Controllers/CommonController";
import { ApproverController } from "../../../Controllers/ApproverController";
import { EmailController } from "../../../Controllers/EmailController";
import { SSL_OP_NO_SESSION_RESUMPTION_ON_RENEGOTIATION } from "constants";
import { SubmitButton } from "../Common/SubmitButton";
import * as Constants from "../../../js/Constants";

export class Decision extends Component<IProperties, Istate> {
    constructor(props:IProperties){
        super(props)
        this.state={
            fields:{statusId: '', taCodingOk: false, commentsToRequestor:'', commentsToApprover:''},
            optionfields:{reSubmit:false, decline:false, approve: false},
            errors:{},
            approvalHistory: [],
            modal: {modalSpinnerShow: false},
            errMsg:"",
            loading:false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal["modalSpinnerShow"]=true;
        this.setState({modal});
        sessionStorage.setItem("page","approver");
        let approverController = new ApproverController();
        var approvalHistoryData = {
            authReqId: sessionStorage.getItem("ta")
        }
        approverController.getApprovalHistory(approvalHistoryData).then(jsonApprovalHistory => {
            this.setState({
                approvalHistory: JSON.parse(JSON.stringify(jsonApprovalHistory))
            });
        }).catch(error => {
            console.error("There was an error...," + error)
        });
    }

    handleCallback = (taCodingOk) => {
        let fields = this.state.fields;
        fields["taCodingOk"] = taCodingOk;
        this.setState({fields})
        let modal = this.state.modal;
        modal["modalSpinnerShow"] = false;
        this.setState({modal});
    }

    handleChange(e){
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }

    handleOptionChange(e){
        let optionfields = this.state.optionfields;
        let fields = this.state.fields;
        let currentTargetId = e.currentTarget.id;
        fields["statusId"] = e.currentTarget.value;
        if (currentTargetId === "reSubmit") {
            optionfields['reSubmit'] = true;
            optionfields['decline'] = false;
            optionfields['approve'] = false;
        }
        else if (currentTargetId === "decline") {
            optionfields['reSubmit'] = false;
            optionfields['decline'] = true;
            optionfields['approve'] = false;
        }
        else if (currentTargetId === "approve") {
            optionfields['reSubmit'] = false;
            optionfields['decline'] = false;
            optionfields['approve'] = true;
        }
        this.setState({ optionfields, fields });
    }

    validateForm(){
        
    }

    onSubmit(e) {
      
        e.preventDefault();

        /* Check TA is locked or not for the current users */
        
        let comController = new CommonController();
        var datacheck = {
            authreqid: sessionStorage.getItem("ta"),
            userid: sessionStorage.getItem("uid"),
            shadowid: sessionStorage.getItem("shadow")
        }
        comController.getAuthlockedCount(datacheck).then(response => {
            
            var count = response[0].count || "";
            if (count > 0) {

                comController.getAuthlockedDetails(datacheck).then(response => {
                    
                    if (response[0].AuthReq_ID != "") {
                        alert("AuthReq# :" + response[0].AuthReq_ID + "\r\n" + "Is currently being edited by : " + response[0].Name + "\r\n" + response[0].Email + "\r\n" + response[0].Business_Phone + "\r\n")
                    }
                })
            }
            else {
                if (this.state.fields.statusId !== '') {


    const { commentsToRequestor} = this.state.fields;
    let modal = { ...this.state.modal };

    if (commentsToRequestor === '' && !this.state.optionfields.approve) {
        alert("Please enter the comment to Submitter");
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
    }  else  {
                    let commonController = new CommonController();
                    var taHistoryData = {
                        authReqId: sessionStorage.getItem("ta"),
                        userId: sessionStorage.getItem("uid"),
                        statusId: this.state.fields.statusId,
                        taIsDH: sessionStorage.getItem("ta_is_dh"),
                        taWeight: sessionStorage.getItem("ta_weight"),
                        isDHA: sessionStorage.getItem("is_dha"),
                        approverTierId: sessionStorage.getItem("approver_tier_id"),
                        commentsToRequestor: this.state.fields.commentsToRequestor,
                        commentsToApprover: this.state.fields.commentsToApprover,
                    }
                    commonController.UpdateTAHistory(taHistoryData).then(async response => {
                        var iWeight, statusId = this.state.fields.statusId, origStatusId;
                        if (sessionStorage.getItem("page") === "approver") {
                            if (this.state.fields.statusId === '5') {
                                if (sessionStorage.getItem("ta_weight") === "3" && sessionStorage.getItem("ta_max_approvers") === "1") {
                                    iWeight = parseInt(sessionStorage.getItem("ta_weight")!) - 2
                                }
                                else {
                                    if (parseInt(sessionStorage.getItem("ta_weight")!) > 0) {
                                        iWeight = parseInt(sessionStorage.getItem("ta_weight")!) - 1;
                                    }
                                }
                                if (parseInt(sessionStorage.getItem("ta_weight")!) <= 1) {
                                    if (sessionStorage.getItem("ta_is_dh") === "true" && sessionStorage.getItem("is_dha") === "false") {
                                        statusId = 8;
                                    }
                                }
                                else {
                                    statusId = 2;
                                }
                                if (iWeight === 1) {
                                    let approverController = new ApproverController();
                                    var labelApproversData = {
                                        labelId: sessionStorage.getItem("ta_label_id"),
                                        expenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
                                        approverTier: 1
                                    }
                                    await approverController.getLabelApprovers(labelApproversData).then(jsonLabelApprovers => {
                                        debugger;
                                        if (jsonLabelApprovers.length === 0) {
                                            iWeight = 0
                                            if (sessionStorage.getItem("ta_is_dh") === "true") {
                                                statusId = "8"
                                            }
                                            else {
                                                statusId = "5"
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        else {
                            iWeight = 0;
                        }
                        origStatusId = statusId;
                        if (iWeight === 0 && statusId === "5" && sessionStorage.getItem("ta_in_policy") === "true") {
                            if (sessionStorage.getItem("ta_car_service_only") === "true") {
                                statusId = "9";
                            }
                            else {
                                statusId = "6";
                            }
                        }

                        let commonController = new CommonController()
                        var taMoveData = {
                            userName: sessionStorage.getItem("uid"),
                            weight: iWeight,
                            statusId: statusId,
                            authreqId: sessionStorage.getItem("ta")
                        }
                        commonController.UpdateTaMove(taMoveData).then(response => {
                            //email submitter
                            var to = sessionStorage.getItem("ta_email")!;
                            var subject = "AuthReq #" + sessionStorage.getItem("ta") + " has been";
                            if (statusId === "3") {
                                subject = subject + " returned for changes and resubmission"
                            }
                            else if (statusId === "4") {
                                subject = subject + " rejected"
                            }
                            else if (statusId === "6") {
                                subject = subject + " completed by CTM"
                            }
                            else if (statusId === "7") {
                                subject = subject + " terminated per your request"
                            }
                            else if (statusId === "9") {
                                subject = subject + " completed by " + sessionStorage.getItem("ta_car_service_company")
                            }
                            else {
                                subject = subject + " approved"
                            }
                            let commonController = new CommonController();
                            var travellerData = {
                                id: '',
                                view: 'assigned',
                                bInner: 'false',
                                sessionTA: sessionStorage.getItem("ta")
                            }
                            commonController.getTATraveler(travellerData).then(jsonTravellers => {
                                var traveller = '';
                                jsonTravellers.map(prop => {
                                    if (traveller === '') {
                                        traveller = prop["name"];
                                    }
                                    else {
                                        traveller = traveller + ' ,' + prop["name"];
                                    }
                                })
                                subject = subject + " for travelers: " + traveller
                                var body = subject + ".<br>"
                                switch (statusId.toString()) {
                                    case "3":
                                    case "4":
                                        body = body + "<br> <br> Please check the Decision tab for details.<br> Explanation: " + this.state.fields.commentsToRequestor
                                }
                                body = body + "<br> <br> Logon to eTA at " + Utils.getSiteCollectionUrl() + " for more information. <br> <br>Thank you for using eTA!"
                                let emailController = new EmailController();
                                emailController.sendEmail('', to, subject, body);
                            }).catch(error => {
                                console.error("There was an error loading Traveler..., " + error)
                            });
                            //unlockTA
                            if (sessionStorage.getItem("page") === "approver" && iWeight === 0 && origStatusId === "5") {
                                //AddBCDRemarks taNumber
                            }

                            //if resubmit by and BCD tier approver, convert BCD generated reservations to manual
                            if (statusId === "3" && sessionStorage.getItem("approver_tier_id") === "0") {
                                //ConvertBCDResvToManual taNumber
                            }
                            if (this.props.location.state.from === "incoming") {
                                this.props.history.push("/admin/wtpincomingta");
                            } else {
                                this.props.history.push("/admin/approverinbox");
                            }
                        }).catch(error => {
                            console.error("There was an error!", error)
                        })
                    }).catch(error => {
                        console.error("There was an error...", error)
                    })


                }

                }
                else {
                    let modal = this.state.modal;
                    alert("Please select status");
                    modal['modalSpinnerShow'] = false;
                    this.setState({ modal });
                }
            }
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }

    applyDecision(loading){
        if(sessionStorage.getItem("is_dha") === "true" && sessionStorage.getItem("ta_is_dh") === "true" || sessionStorage.getItem("user_type_id") === "A"){
            if((sessionStorage.getItem("ta_weight") === sessionStorage.getItem("approver_weight") && parseInt(sessionStorage.getItem("ta_status_id")!) === 2) || (parseInt(sessionStorage.getItem("ta_weight")!) === 0 && (sessionStorage.getItem("is_dha") === "true" && parseInt(sessionStorage.getItem("ta_status_id")!) === 8))){
                return <Row>
                    <Col sm={4}></Col>
                    <Col sm={4}>
                        <Form onSubmit = {this.onSubmit}>
                            <table style={{border:"1", borderColor:"Silver"}}>
                                <tr>
                                    <td style={{backgroundColor:"silver", textAlign:"center"}} colSpan={2}>Current Decision</td>
                                </tr>
                                <tr>
                                    <td style={{width:"25%", backgroundColor:"silver", textAlign:"right"}}>Select Status:</td>
                                    {this.state.fields.taCodingOk ?
                                        <tr>
                                            <td style={{width:"75%"}}><Form.Check type="radio" label="Approve" name="statusId" id="approve" onChange={(e)=>this.handleOptionChange(e)} title="Approve" checked={this.state.optionfields.approve} value="5"/></td>
                                        </tr>
                                    : 
                                        <></>
                                    }
                                    <tr>
                                        <td style={{width:"75%"}}><Form.Check type="radio" label="ReSubmit (return to Submitter for changes)" name="statusId"  id="reSubmit" onChange={(e)=>this.handleOptionChange(e)} title="ReSubmit" checked={this.state.optionfields.reSubmit} value="3"/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"75%"}}><Form.Check type="radio" label="Decline (cancel/reject - no changes can be made)" name="statusId" id="decline" onChange={(e)=>this.handleOptionChange(e)} title="Decline" checked={this.state.optionfields.decline} value="4"/></td>
                                    </tr>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:"silver", textAlign:"right"}}>Comments to Submitter:</td>
                                    <td>
                                        <FormControl size="sm" id="commentsToRequestor" as="textarea" name="commentsToRequestor" className="form-control input-sm" value={this.state.fields.commentsToRequestor} onChange={(e) => this.handleChange(e)} ></FormControl>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor:"silver", textAlign:"right"}}>Comments to Approver:</td>
                                    <td>
                                        <FormControl size="sm" id="commentsToApprover" as="textarea" name="commentsToApprover" className="form-control input-sm" value={this.state.fields.commentsToApprover} onChange={(e) => this.handleChange(e)} ></FormControl>
                                    </td>
                                </tr>
                                <tr style={{height:"30px"}}>
                                    <td style={{backgroundColor:"silver", textAlign:"center"}} colSpan={2}>
                                        <Button id="applyDecision1" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#487afa" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#487afa"}} >wait...</span>}
                                            {!loading && <span>Apply Decision</span>}
                                        </Button>
                                    </td>
                                </tr>
                            </table>
                        </Form>
                    </Col>
                    <Col sm={4}></Col>
                </Row>
            }
        }
        else if((sessionStorage.getItem("user_type_id") === "CT" && parseInt(sessionStorage.getItem("approver_tier_id")!) === 0) || sessionStorage.getItem("user_type_id") === "CT" || sessionStorage.getItem("car_service_company_id") !== "null"){
            return <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                    <Form onSubmit = {this.onSubmit}>
                        <table style={{border:"1", borderColor:"Silver"}}>
                            <tr>
                                <td style={{backgroundColor:"silver", textAlign:"center"}} colSpan={2}>Submitter Request</td>
                            </tr>
                            <tr>
                                <td style={{width:"25%", backgroundColor:"silver", textAlign:"right"}} rowSpan={1}>Select Status:</td>
                                <tr>
                                    <td style={{width:"75%"}}><Form.Check type="radio" label="ReSubmit (return to Submitter for changes)" name="statusId"  id="reSubmit" onChange={(e)=>this.handleOptionChange(e)} title="ReSubmit" checked={this.state.optionfields.reSubmit} value="3"/></td>
                                </tr>
                                <tr>
                                    <td style={{width:"75%"}}><Form.Check type="radio" label="Terminate (cancel/reject - no changes can be made)" name="statusId" id="decline" onChange={(e)=>this.handleOptionChange(e)} title="Decline" checked={this.state.optionfields.decline} value="7"/></td>
                                </tr>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"silver", textAlign:"right"}}>Comments to Submitter:</td>
                                <td>
                                    <FormControl size="sm" id="commentsToRequestor" as="textarea" name="commentsToRequestor" className="form-control input-sm" value={this.state.fields.commentsToRequestor} onChange={(e) => this.handleChange(e)} ></FormControl>
                                </td>
                            </tr>
                            <tr style={{height:"30px"}}>
                                <td style={{backgroundColor:"silver", textAlign:"center"}} colSpan={2}>
                                    <Button id="applyDecision2" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" , color:"#487afa" }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                                        {!loading && <span>Apply Decision</span>}
                                    </Button>
                                </td>
                            </tr>
                        </table>
                    </Form>
                </Col>
                <Col sm={4}></Col>
            </Row>
        }
    }

    displayDecisionPage(){
        const{ loading } = this.state;
        return <div>
            <Row>
                <Col sm={12} style={{textAlign:"right"}}>
                    <Button id="submitVoucher" type="button" size="sm" onClick={() => window.print()} >
                        Print
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col sm={4}></Col>
                <Col sm={4}>
                    <table style={{border:"0px",width:"95%"}} cellPadding={2} >
                        {sessionStorage.getItem("ta_is_corporate_card") === "true" ?
                            <><tr><td style={{textAlign:"center"}}>EMPLOYEE CORPORATE CARD</td></tr>
                            {sessionStorage.getItem("ta_in_policy") === "false" ?
                                <tr><td>&nbsp;</td></tr>
                            : <></>}</>
                        : <></>}
                        {sessionStorage.getItem("ta_in_policy") === "true" ?
                            <><tr><td style={{textAlign:'center'}}>IN POLICY</td></tr>
                            <tr><td>&nbsp;</td></tr></>
                        : <></>}
                    </table>
                </Col>
                <Col sm={4}></Col>
            </Row>
            <TASummary parentCallback = {this.handleCallback} />
            {(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter") ?
                <Row>
                    <Col sm={12}>
                        <table style={{ border:"2", width:"100%", borderColor:"silver"}}>
                            <tr>
                                <td style={{backgroundColor:"silver", textAlign:"center"}} colSpan={5}>Approval History</td>
                            </tr>
                            <tr style={{textAlign:"center"}}>
                                <td style={{width:"25%"}}>Approver</td>
                                <td style={{width:"20%"}}>Approver Type</td>
                                <td style={{width:"30%"}}>Decision Date</td>
                                <td style={{width:"15%"}}>Decision</td>
                                <td style={{width:"20%"}}>Approver Phone</td>
                            </tr>
                            {this.state.approvalHistory.map((prop,key) => {
                                return <>
                                    <tr style={{textAlign:"center"}}>
                                        <td style={{width:"25%", backgroundColor:"#FFFF80"}}>{prop["name"]}</td>
                                        <td style={{width:"20%", backgroundColor:"#FFFF80"}}>{prop["approverTierDesc"]}</td>
                                        <td style={{width:"30%", backgroundColor:"#FFFF80"}}>{Utils.FormatDateTime(prop["updatedDate"])}</td>
                                        <td style={{width:"15%", backgroundColor:"#FFFF80"}}>{prop["statusTypeDesc"]}</td>
                                        <td style={{width:"20%", backgroundColor:"#FFFF80"}}>{prop["businessPhone"]}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:"silver", textAlign:"right"}}>Comments to Submitter:</td>
                                        <td style={{backgroundColor:"#FFFF80"}} colSpan={4}>{prop["commentsToRequestor"]}</td>
                                    </tr>
                                    {sessionStorage.getItem("user_type_id") === "A" ?
                                        <tr>
                                            <td style={{backgroundColor:"silver", textAlign:"right"}}>Comments to Approver:</td>
                                            <td style={{backgroundColor:"#FFFF80"}} colSpan={4}>{prop["commentsToApprover"]}</td>
                                        </tr>
                                    :
                                        <></>
                                    }
                                </>
                            })} 
                        </table>
                    </Col>
                </Row>
            :
                <></>
            }
            {this.applyDecision(loading)}
        </div>
    }

    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Traveler':
                this.props.history.push('/admin/traveller');
                break;
            case 'Air / Rail':
                this.props.history.push('/admin/airrail');
                break;
            case 'Hotel':
                this.props.history.push('/admin/hotel');
                break;
            case 'Rental Car':
                this.props.history.push('/admin/rentalcar');
                break;
            case 'Car Service':
                this.props.history.push('/admin/carservice');
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:this.props.location.state.from}});
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:this.props.location.state.from}});
                break;
            case 'Confirmation':
                this.props.history.push({pathname:'/admin/confirmation',state:{from:this.props.location.state.from}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/decision',state:{from:this.props.location.state.from}});
                break;
        }
    }
    codingTab(){
        debugger;
        if(sessionStorage.getItem("page") === "submitter" && parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 || sessionStorage.getItem("ta_is_corporate_card") === "true")){
            return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for summary page" label="Print"></Tab>
        }
        if(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter"){
            if(parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 || (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && parseInt(sessionStorage.getItem("ta_tier_code")!) === 0 || sessionStorage.getItem("ta_is_corporate_card") === "false")){
                return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
            }       
        }     
    }
    decisionTabTAHistoryResubmit(){
        if(sessionStorage.getItem("ta_status_id") === Constants.ReSubmit.toString()){
           return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision">{this.displayDecisionPage()}</Tab>
        }
        else
        {
            return <Tab headerClass="nav-link disabled" style={{color:"white"}} disabled></Tab>
        }
    }
    render() {
        const{ loading } = this.state;
        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%' }}>
                                <Card.Body>
                                    <Form>
                                        <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                        <AuthStatus/>
                                        <section style={{marginTop:"10px"}}>
                                            <div style={{textAlign:"right"}}>
                                            {(sessionStorage.getItem("ta_status_id") === Constants.ReSubmit.toString())?<SubmitButton 
                                                loading={this.state.loading} 
                                                redirect={this.props.history}
                                            />:<></>
                                            }
                                            </div>
                                            {(this.props.location.state.from === "incoming") ?                                                
                                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={1} mountOnExit>
                                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation"></Tab>
                                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision">{this.displayDecisionPage()}</Tab>
                                                    </Tabs>
                                            :
                                            (this.props.location.state.from === "tahistory" && (sessionStorage.getItem("ta_status_id") === Constants.Complete.toString() || sessionStorage.getItem("ta_status_id") === Constants.CarServiceComplete.toString()))?
                                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={2} mountOnExit>
                                                        {this.codingTab()}
                                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation"></Tab>
                                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision">{this.displayDecisionPage()}</Tab>
                                                    </Tabs>
                                                :
                                                ((this.props.location.state.from === "" || this.props.location.state.from === "tahistory") && sessionStorage.getItem("ta_status_id") === Constants.ReSubmit.toString())?
                                                        (sessionStorage.getItem("ta_car_service_only") === "true")?
                                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={3} mountOnExit>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                                            {this.codingTab()}
                                                            {this.decisionTabTAHistoryResubmit()}
                                                        </Tabs>
                                                        :
                                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={6} mountOnExit>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for air/rail page" label="Air / Rail"></Tab>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for hotel page" label="Hotel"></Tab>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for rental car page" label="Rental Car"></Tab>
                                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                                            {this.codingTab()}
                                                            {this.decisionTabTAHistoryResubmit()}
                                                        </Tabs>
                                                    :
                                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={1} mountOnExit>
                                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
                                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision">{this.displayDecisionPage()}</Tab>
                                                    </Tabs>
                                            }
                                        </section>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

interface IProperties{
    userId?: string;
    password?: string;
    history?: History<LocationState>;
    location?: any;
}

interface Istate{
    fields: any;
    optionfields: any;
    errors: any;
    approvalHistory: [];
    modal?: any;
    errMsg?: string;
    loading?: boolean;
}