import React,{ Component } from 'react';
import {Modal, Button, Row, Col} from "react-bootstrap";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import spinner from "../../assets/img/preloader.gif"

export class FormModalSpinner extends Component<IFormModalSpinner> {

  render() { 
    const {show}  = this.props;
    return(      
    <Modal id="mSpinner"
    {...this.props}
    centered>
      <div id="delayshow" className="loadingio-spinner-rolling-jr5mkz99o3">
          <div className="ldio-o39gvs4vhla">
            <div></div>
          </div>
      </div>
      <h3 id="delaydisplay">Please wait, fetching your data...</h3>
      </Modal>
    )

      /*return(<Modal id="mSpinner"
              {...this.props}
              centered
              ><img src={spinner}></img><p style={{color:"#ffffff"}}>Loading...</p>
          </Modal>)*/
      }
    }

interface IFormModalSpinner
  {
    show:boolean;
  }
  interface IProperties
  {
      
  }
  interface Istate
  {

  }