import React, { Component } from "react";
import { ISubmitterParameter, Istate } from "../../../Model/ISubmitter";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import * as Utils from '../../../js/Utils.js';
import { TravellerController } from "../../../Controllers/TravellerController";
import { SubmitterController } from "../../../Controllers/SubmitterController";
import addSubtractDate from 'add-subtract-date';
import * as Constants from "../../../js/Constants";

export class Submitter extends Component<ISubmitterParameter, Istate> {
    constructor(props: ISubmitterParameter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: {},
            errors: {},
            errMsg: "",
            loggedIn,
            unsubmittedTA: [],
            taHistory: [],
            currentSort: 'down',
            currentColumn: 'authReqId',
            loading: false,
            modal: { modalSpinnerShow: false }
        }
    }

    componentWillMount() {
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({modal});
        sessionStorage.setItem("frompage", Constants.unsubmitted);
        let submitterController = new SubmitterController();
        var submitterData = {
            labelId: sessionStorage.getItem("label_id"),
            sysop: sessionStorage.getItem("sysop"),
            createdBy: sessionStorage.getItem("uid"),
            createdDate: Utils.FormatDate(addSubtractDate.add(new Date(Date.now()),-14,"days"))
        }
        submitterController.getUnsubmittedTA(submitterData).then(jsonUnsubmittedTA => {
            this.setState({
                unsubmittedTA: JSON.parse(JSON.stringify(jsonUnsubmittedTA))
            });
            modal['modalSpinnerShow']=false;
            this.setState({modal});
        }).catch(error => {
            console.error("There was an error...," + error)
        });
        sessionStorage.setItem("frompage", "unsubmittedta");
    }

    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = [...this.state.unsubmittedTA];
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            unsubmittedTA: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    onSelect(authReqId){
        let travellerController = new TravellerController();
        travellerController.getTA(authReqId).then(response => {
            sessionStorage.setItem("page","submitter");
            this.props.history.push({pathname:'/admin/codingsummary',state:{from:"unsubmittedta"}});
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }

    render() {
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">TA Drafts</h4><p className="category"></p></div>
                            <div style={{ textAlign: "center" }}><h5 className="title">This is a temporary storage area. Only TAs created in the last 2 weeks will be visible here.</h5><p className="category"></p></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                <Row>
                                    <Col sm={12} style={{textAlign:"center", marginBottom:"15px"}}>
                                        <Button disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                            {this.state.loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", color: "#3472F7" }}
                                            />
                                            )}
                                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                            {!this.state.loading && <span>Refresh </span>}
                                        </Button>
                                        <Button disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                            {this.state.loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", color: "#3472F7" }}
                                            />
                                            )}
                                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                            {!this.state.loading && <span>Print </span>}
                                        </Button>
                                    </Col>
                                </Row>
                                {(sessionStorage.getItem("adID") !== null) ?
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Table hover striped bordered >
                                                    <thead>
                                                        <tr>
                                                            <th onClick={() => this.onSortChange('authReqId')}>Auth Req<i className={this.setArrow('authReqId')}></i></th>
                                                            <th onClick={() => this.onSortChange('createdBy')}>Submitter Name<i className={this.setArrow('createdBy')}></i></th>
                                                            <th onClick={() => this.onSortChange('createdDate')}>Date Created<i className={this.setArrow('createdDate')}></i></th>
                                                            <th onClick={() => this.onSortChange('artistProjectNumber')}>Project/ Employee Number<i className={this.setArrow('artistProjectNumber')}></i></th>
                                                            <th onClick={() => this.onSortChange('artistProjectName')}>Project Name <i className={this.setArrow('artistProjectName')}></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.unsubmittedTA.map((prop, key) => {
                                                            return (
                                                                <tr key={key} onClick={() => this.onSelect(prop["authReqId"])} style={{cursor:"pointer"}}>
                                                                    <td>{prop["carServiceOnly"] ? 'CA' + prop["authReqId"] :prop["authReqId"]}</td>
                                                                    <td>{prop["createdBy"]}</td>
                                                                    <td>{Utils.FormatDate(prop["createdDate"])}</td>
                                                                    <td>{prop["artistProjectNumber"]}</td>
                                                                    <td>{prop["artistProjectName"]}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col sm={12}>
                                            {"For security reason, this page is not displayed.<br>Please try to login again."}
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
}