import React, { Component } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'
import {CommonController} from "../../../Controllers/CommonController";
import {TravellerController} from "../../../Controllers/TravellerController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {History, LocationState} from "History";
import * as Utils from '../../../js/Utils.js';
import { alert, confirm } from 'react-bootstrap-confirmation';
import { AuthStatus } from "../Common/AuthStatus";
import { SubmitButton } from "../Common/SubmitButton";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import { TASummary } from "./TASummary";
import { TAInclude } from "../../../Common/TAInclude";

export class SubmitAuthReq extends Component<IProperties, Istate> {
    constructor(props:IProperties){
        super(props)
        this.state={
            fields:{taCoding:true,bAirOK:true,bAspOK:true,bHtlOK:true,bHspOK:true,bCarOK:true,bCspOK:true,bSvcOK:true,bSspOK:true,bAir:false,bHotel:false,bRentalCar:false,bCarService:false,bTravelerInfoFormRequired:false},
            status:"",
            modal: {modalSpinnerShow: false},
            assignedTATraveler:[],
            unassignedTATraveler:[],
            errMsg:"",
            loading:false
        }
    }
    async componentWillMount(){
        let fields = this.state.fields;
        let modal = this.state.modal;
        modal["modalSpinnerShow"]=true;
        this.setState({modal, status:""});
        //console.log("count:","1")
        let ta = await this.getTA();
        //console.log("count:","2")
        let taInclude = new TAInclude();
        fields['taCoding'] = await taInclude.taCoding("");
        //console.log("count:","3")
        fields['bAirOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","AIR","Air");
        //console.log("count:","4")
        fields['bAspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","ASP","Air");
        //console.log("count:","5")
        fields['bHtlOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","HTL","Hotel");
        //console.log("count:","6")
        fields['bHspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","HSP","Hotel");
        //console.log("count:","7")
        fields['bCarOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","CAR","Rental Car");
        //console.log("count:","8")
        fields['bCspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","CSP","Rental Car");
        //console.log("count:","9")
        fields['bSvcOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","SVC","Car Service");
        //console.log("count:","10")
        fields['bSspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","SSP","Car Service");
        //console.log("count:","11")
        fields['bTravelerInfoFormRequired'] = await this.travelerInfoFormRequired(sessionStorage.getItem('ta'));
        this.setState({fields});
        //console.log("count:","2.2.1")
        //console.log("fields", this.state.fields);
        //console.log("taCodingOk",this.taCodingOK());
        if (this.taCodingOK() && (sessionStorage.getItem("ta_has_air")==='true' || sessionStorage.getItem("ta_has_rental_car")==='true' || sessionStorage.getItem("ta_has_car_service")==='true' || sessionStorage.getItem("ta_has_hotel")==='true')
        && (sessionStorage.getItem("ta_has_air")==='true' || sessionStorage.getItem("ta_air_missing_traveler")==='false' || sessionStorage.getItem("ta_has_air")==='false')
        && (sessionStorage.getItem("ta_has_rental_car")==='true' || sessionStorage.getItem("ta_rental_car_missing_traveler")==='false' || sessionStorage.getItem("ta_has_rental_car")==='false')
        && (sessionStorage.getItem("ta_has_car_service")==='true' || sessionStorage.getItem("ta_car_service_missing_traveler")==='false' || sessionStorage.getItem("ta_has_rental_car")==='ta_has_car_service')
        && (sessionStorage.getItem("ta_has_hotel")==='true' || sessionStorage.getItem("ta_hotel_missing_traveler")==='false' || sessionStorage.getItem("ta_has_rental_car")==='ta_has_hotel')){
            //console.log("count:","2.2.2")
            
            //load ta Traveller information
            await this.tatraveler();
            
            if (sessionStorage.getItem("has_dha")==='true' && sessionStorage.getItem("ta_expense_type_id") === "100" && sessionStorage.getItem("ta_is_dh") === "true"){
                this.taMove(0, 8);
            }
            else{
                let maxWeight = await this.taMaxWeight(sessionStorage.getItem("ta_expense_type_id"));
                this.taMove(maxWeight, 2);
            }
            
            //use status for display Success html msg
            this.setState({status:"true"});
            console.log ("True")
        }
        else{
            //load ta Traveller information
            await this.tatraveler();

            if (!this.state.fields.taCoding){
                fields['bAir'] = true;
                fields['bHotel'] = true;
                fields['bRentalCar'] = true;
                fields['bCarService'] = true;
            }
            else
            {
                fields['bAir'] = (this.state.fields.bAirOK && this.state.fields.bAspOK) ? true : false;
                fields['bHotel'] = (this.state.fields.bHtlOK && this.state.fields.bHspOK) ? true : false;
                fields['bRentalCar'] = (this.state.fields.bCarOK && this.state.fields.bCspOK) ? true : false;
                fields['bCarService'] = (this.state.fields.bSvcOK && this.state.fields.bSspOK) ? true : false;
            }
            //use status for display failure html msg
            this.setState({fields, status:"false"});
            //console.log("count:","2.1")
            //console.log("False")
        }
        modal["modalSpinnerShow"]=false;
        
        this.setState({modal});
       
    }
    async tatraveler(){
        let commonController = new CommonController();
        var data={};
        data={
            id:'',
            view:'assigned',
            bInner:'false',
            sessionTA:sessionStorage.getItem("ta")
        }
        await commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                assignedTATraveler : JSON.parse(JSON.stringify(jsonTravellers)),
            });
            //console.log("assignedTATraveler, ",this.state.assignedTATraveler)
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
        data={
            id:'',
            view:'unassigned',
            bInner:'false',
            sessionTA:sessionStorage.getItem("ta")
        }
        await commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                unassignedTATraveler : JSON.parse(JSON.stringify(jsonTravellers))
            });
            //console.log("unassignedTATraveler, ",this.state.unassignedTATraveler)
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
    }
    async travelerInfoFormRequired(authReqId){
        let commonController = new CommonController();
        return await commonController.getTravelerInfoFormRequired(authReqId).then(response => {
            
            return response.Travel_Form;
        }).catch(error => {
            console.error("There was an error!", error)
        });
    }
    taMove(weight, status_id){
        //
        let commonController = new CommonController()
        var data={};
        data={
            userName: sessionStorage.getItem("uid"),
            weight: weight,
            statusId: status_id,
            authreqId: sessionStorage.getItem("ta")
        }
        //console.log("taMove", data);
        commonController.UpdateTaMove(data).then(response => {
            //console.log("UpdateTaMove", response)
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }
    async taMaxWeight(taExpenseTypeId){
        //
        if (sessionStorage.getItem("ta") !== ""){
            if (sessionStorage.getItem("department_id") === '143' && sessionStorage.getItem("ta_expense_type_id") === "10"){
                return 2; //exit function
            }
        }

        var data = {};
        data = {
            expenseTypeId: taExpenseTypeId,
            departmentId: sessionStorage.getItem("department_id")
        }
        let commonController = new CommonController()
        let appUserByExpType: boolean = false;
        await commonController.selectAppUserByExpType(data).then(response => {
            appUserByExpType = (response !== undefined) ? true : false
            //console.log("selectAppUserByExpType", appUserByExpType)
        }).catch(error => {
            console.error("There was an error!", error)
        });

        if (sessionStorage.getItem("has_dha")==='true' && sessionStorage.getItem("ta_expense_type_id") === "100" && sessionStorage.getItem("ta_is_dh") === 'true'){
            return sessionStorage.getItem("ta_max_approvers"); //exit function
        }	
		else if (appUserByExpType){
            return 3;
        }
        else{
            data={
                expenseTypeId:taExpenseTypeId,
                departmentId:sessionStorage.getItem("department_id"),
                labelId:sessionStorage.getItem("label_id")
            }
            return await commonController.selectMaxApprovers(data).then(response => {
                //console.log("selectMaxApprovers")
                return (response != undefined) ? response.max_approvers : sessionStorage.getItem("ta_weight"); //'user should not be moving the ta
            }).catch(error => {
                console.error("There was an error!", error)
            }) 
        }
    }
    async getTA(){
        //console.log("AuthReqId:",sessionStorage.getItem("ta"));
        //
        let travellerController = new TravellerController()
        return await travellerController.getTA(sessionStorage.getItem("ta")!).then(response => {
            //console.log("get TA")
            return response;
        }).catch(error => {
            console.error("There was an error!", error)
        }) 
    }

    taCodingOK(){
        if (sessionStorage.getItem("ta_is_corporate_card") === 'true' || !this.state.fields.taCoding){
            //console.log("taCodingOk true")
            return true;
        }
        else{
            if (this.state.fields.bAirOK && this.state.fields.bAspOK && this.state.fields.bHtlOK && this.state.fields.bHspOK && this.state.fields.bCarOK && this.state.fields.bCspOK && this.state.fields.bSvcOK && this.state.fields.bSspOK){
                return true;
            }
            else{
                return false;
            }
        }
    }

    msgSuccess=()=>{
        return <div style={{width:"100%"}}>
                    <Row>
                        <Col sm={12} style={{textAlign:"center"}}>
                            <div className="font-weight-bold">
                            {(sessionStorage.getItem("ta_car_service_only")==="true")?
                                <div style={{textAlign:"center"}}>The eTA you have submitted is for Car Service Only. 
                                <br></br>
                                Please make sure your have already confirmed your car service reservation with {sessionStorage.getItem("ta_car_service_company")} in order to properly complete this approval process. 
                                <br></br>
                                CTM Travel will only make car service reservations if it is included on a TA with air travel.
                                <br></br>
                                To see the progress of the TA, go to ETA tracking grid
                                <br></br>
                                <Button id="etaTrackingGrid" variant = "primary" type="button" size="sm" onClick={() => this.props.history.push('/admin/trackinggrid')}>
                                       Click
                                </Button></div>
                                :				
                                <div style={{textAlign:"center"}}>Your TA has been submitted. To see the progress of the TA, go to ETA tracking grid
                                <br></br>
                                <Button id="etaTrackingGrid" variant = "primary" type="button" size="sm" onClick={() => this.props.history.push('/admin/trackinggrid')}>
                                       Click
                                </Button></div>
                            }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} style={{textAlign:"center", marginTop:"15px"}}>
                            The {(sessionStorage.getItem("ta_car_service_only")==="true")?"CA":"TA"} you have submitted has already arrived in your first approvers inbox.<br></br>
                            When this {(sessionStorage.getItem("ta_car_service_only")==="true")?"CA":"TA"} request has received final approval, you will receive an email notification.<br></br>
                            <div style={{color:"red"}}>The approver will not receive an email at this time.</div><br></br>
                            Approvers receive an e-mail notification (including TA details) if there is a pending TA in their approver inbox.  
                            The reminders are e-mailed, 3 times a day, Monday – Friday at 11:00am, 1:00pm and 6:00pm CST (Central Time Zone) 
                        </Col>
                    </Row>
                    
                </div>
    }

    tdTitle(prop){  
        //2-Contest Winner ,4-Radio Personnel
        return (prop.type === 2)?
            "Reason For Travel:" + prop.travelPurpose + ' ' +
            "Addr: " + prop.address + ' ' +
            "City: " + prop.city + ' ' +
            "State/Zip: " + prop.stateProvinceId + ' ' + prop.zip + ' ' +
            ((prop.ssn === "")? "Federal ID:" + prop.fedId : "SSN:" + prop.fedId) + ' ' +
            "Phone: " + prop.phone + ' ' +
            "Call Letters: " + prop.callLetters + ' ' +
            "1099 Issuer: "+ ((prop.issuer !== null)?prop.issuer:'') + ' ' +
            "Contest Letter Received: " + prop.radioLetterReceived + ' ' +
            ((prop.radioLetterReceived === "N")? "Override Received: " + prop.overrideReceived : "")
            :
            (prop.type === 4) ?
           "Reason For Travel: " + prop.travelPurpose + ' ' +
             "Call Letters: " + prop.callLetters + ' ' +
             "Compliance Officer Approval: " + prop.preapprovalReceived : ""
    }

    travelerView(prop,key,tastatus){
        
            return <tr>
                        {(key === 0)?
                            <td style={{backgroundColor:"LightCyan",padding:"2px", alignSelf:"right"}}>{tastatus +':'}</td>
                        :
                            <td style={{backgroundColor:"LightCyan"}}>&nbsp;</td>
                        }
                        <td title={this.tdTitle(prop)}>
                            {prop.name}&nbsp;&nbsp;{prop.RLN}
                            {(prop.isDH)?<small>(flagged for Sr. Exec. Approval)</small>:<></>}
                        </td>
                        {(this.state.fields.bTravelerInfoFormRequired)? 
                            (prop.fromOnFile)? 
                            <td style={{padding:"2px",alignSelf:"center"}}>Y</td>
                                :
                            (!prop.fromOnFile)?
                                <td style={{color:"red",padding:"2px",alignSelf:"center"}}>N</td>
                                    :
                                <td style={{padding:"2px",alignSelf:"center"}}>&nbsp;</td>
                            :
                            <></>
                        }
                        {(this.state.fields.bTravelerInfoFormRequired)? 
                            (prop.travelRestrictions === 1)? 
                                <td style={{padding:"2px",alignSelf:"center"}}>Y</td>
                            :
                                (prop.travelRestrictions === 0)?
                                    <td style={{padding:"2px",alignSelf:"center"}}>N</td>
                                :
                                    (prop.travelRestrictions === 2)?
                                        <td style={{padding:"2px",alignSelf:"center"}}>N</td>
                                    :
                                        <td style={{padding:"2px",alignSelf:"center"}}>&nbsp;</td>
                        :
                            <></>
                        }
                    </tr>
    }
    
    msgFailure(){
        
        return (
            <><TASummary />
            <Row className="text-dark font-size-xs small">
                <Col sm={4} style={{alignSelf:"center"}}></Col>
                <Col sm={4} style={{alignSelf:"center"}}>
                    {!(sessionStorage.getItem("ta_has_air")==='true' || sessionStorage.getItem("ta_has_rental_car") === 'true' || sessionStorage.getItem("ta_has_car_service") === 'true' || sessionStorage.getItem("ta_has_hotel") === 'true')?
                        <div><div style={{marginTop:"20px"}}></div><big>No Itineraries</big></div>
                        :
                        <></>
                    }
                </Col>
                <Col sm={4} style={{alignSelf:"center"}}></Col>
            </Row></>
        )
    }
    
    formatCurrency(amount){
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Traveler':
                this.props.history.push('/admin/traveller');
                break;
            case 'Air / Rail':
                this.props.history.push('/admin/airrail');
                break;
            case 'Hotel':
                this.props.history.push('/admin/hotel');
                break;
            case 'Rental Car':
                this.props.history.push('/admin/rentalcar');
                break;
            case 'Car Service':
                this.props.history.push('/admin/carservice');
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
        }
    }

    codingTab(){
        if(sessionStorage.getItem("page") === "submitter" && parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 || sessionStorage.getItem("ta_is_corporate_card") === "true")){
            return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for summary page" label="Print"></Tab>
        }
        if(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter"){
            if(parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 || (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && parseInt(sessionStorage.getItem("ta_tier_code")!) === 0 || sessionStorage.getItem("ta_is_corporate_card") === "false")){
                return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
            }    
        }     
    }
    decisionTabTAHistoryResubmit(){
        
        if(sessionStorage.getItem("ta_status_desc") === "Re-Submit"){
           return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>           
        }
        else
        {
           return <Tab headerClass="nav-link disabled" style={{color:"white"}} disabled></Tab>
        }
    }
    render() {
        const{ loading } = this.state;
        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%' }}>
                                <Card.Body>
                                <Form>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <AuthStatus/>
                                    <section style={{marginTop:"10px"}}>
                                        <div style={{textAlign:"right"}}>
                                        {(this.state.status === "true")?
                                            <></>
                                        :
                                            <SubmitButton 
                                                loading={this.state.loading} 
                                                redirect={this.props.history}
                                            />
                                        }
                                        </div>
                                        {(this.state.status === "true")?
                                            <></>
                                            :
                                            (sessionStorage.getItem("ta_car_service_only") === "true")?
                                                <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={3} mountOnExit>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                                    {this.codingTab()}
                                                    {this.decisionTabTAHistoryResubmit()}
                                                </Tabs>
                                            :
                                                <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={6} mountOnExit>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler"></Tab>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for air/rail page" label="Air / Rail"></Tab>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for hotel page" label="Hotel"></Tab>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for rental car page" label="Rental Car"></Tab>
                                                    <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                                    {this.codingTab()}
                                                    {this.decisionTabTAHistoryResubmit()}
                                                </Tabs>
                                            }
                                        {(this.state.status === "") ? "" : (this.state.status === "true")?this.msgSuccess():this.msgFailure()}
                                    </section>
                                </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

interface IProperties
  {
    userId?:string;
    password?:string;
    history?:History<LocationState>;
    
  }
  interface Istate
  {
    fields:any;
    status?:string;
    modal?:any;
    assignedTATraveler?:[];
    unassignedTATraveler?:[];
    errMsg?:string;
    loading?:boolean;
  }