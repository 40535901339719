/*
 * Function to get Site Collection URL
 * Samples:
 *      "https://domain.sharepoint.com/sites/intranet"
 */
export function getSiteCollectionUrl() {
  if (
    window &&
    "location" in window &&
    "protocol" in window.location &&
    "pathname" in window.location &&
    "host" in window.location
  ) {
    var baseUrl;
 if ((window.location.host === "eta2.umusic.com")) {
      baseUrl = window.location.protocol + "//" + "eta2-prod-intra.umusic.com";
    } else {
      baseUrl = window.location.protocol + "//" + window.location.host;
    }
    const pathname = window.location.pathname;
    const siteCollectionDetector = "/sites/";
    if (pathname.indexOf(siteCollectionDetector) >= 0) {
      baseUrl += pathname.substring(
        0,
        pathname.indexOf("/", siteCollectionDetector.length)
      );
    }
    return baseUrl;
  }
  return "";
}

/*
 * Function to get Current Site Url
 * Samples:
 *      "https://domain.sharepoint.com/sites/intranet/subsite/Pages/Home.aspx"
 */
export function getCurrentAbsoluteSiteUrl() {
  if (
    window &&
    "location" in window &&
    "protocol" in window.location &&
    "pathname" in window.location &&
    "host" in window.location
  ) {
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname
    );
  }
  return "";
}

/*
 * Function to get Current Site Url
 * Samples:
 *      "/sites/intranet"
 */
export function getWebServerRelativeUrl() {
  if (window && "location" in window && "pathname" in window.location) {
    return window.location.pathname.replace(/\/$/, "");
  }
  return "";
}

/*
 * Function to get Layout Page Url
 * Replacement in SPFx for SP.Utilities.Utility.getLayoutsPageUrl('sp.js')
 * Samples:
 *      getLayoutsPageUrl('sp.js')
 *      "/sites/intranet/_layouts/15/sp.js"
 */
export function getLayoutsPageUrl(libraryName) {
  if (
    window &&
    "location" in window &&
    "pathname" in window.location &&
    libraryName !== ""
  ) {
    return (
      window.location.pathname.replace(/\/$/, "") +
      "/_layouts/15/" +
      libraryName
    );
  }
  return "";
}

/*
 * Function to get Current Domain Url
 * Samples:
 *      "https://domain.sharepoint.com"
 */
export function getAbsoluteDomainUrl() {
  //debugger;
  if (
    window &&
    "location" in window &&
    "protocol" in window.location &&
    "host" in window.location
  ) {
    return window.location.protocol + "//" + window.location.host;
  }
  return "";
}
// Display 12 hour format
export function SysDateTime() {
  var date = new Date();
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var secounds = date.getUTCSeconds();
  var getdate = date.getUTCDate();
  var getmon = date.getUTCMonth() + 1;
  var getyear = date.getUTCFullYear();

  // Check whether AM or PM
  var newformat = hours >= 12 ? "PM" : "AM";

  // Find current hour in AM-PM Format
  hours = hours % 12;

  // To display "0" as "12"
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return (
    getmon +
    "/" +
    getdate +
    "/" +
    getyear +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    secounds +
    " " +
    newformat
  );
}

// Display 12/11/2020 05:05:05 AM hour format
export function FormatDateTime(d) {
  //debugger;
  var date = new Date(d);
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var secounds = date.getUTCSeconds();
  var getdate = date.getUTCDate();
  var getmon = date.getUTCMonth() + 1;
  var getyear = date.getUTCFullYear();

  // Check whether AM or PM
  var newformat = hours >= 12 ? "PM" : "AM";

  // Find current hour in AM-PM Format
  hours = hours % 12;

  // To display "0" as "12"
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  secounds = secounds < 10 ? "0" + secounds : secounds;
  return (
    getmon +
    "/" +
    getdate +
    "/" +
    getyear +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    secounds +
    " " +
    newformat
  );
}

export function FormatDate(d) {
  //debugger;
  var date = new Date(d);
  var getdate = date.getUTCDate();
  var getmon = date.getUTCMonth() + 1;
  var getyear = date.getUTCFullYear();

  return getmon + "/" + getdate + "/" + getyear;
}

export function FormatTodayDate() {
  //debugger;
  var date = new Date();
  var getdate = date.getUTCDate();
  const getMonthname = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var getmon = getMonthname[date.getUTCMonth()];
  const getWday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var getday = getWday[date.getUTCDay()];
  var getyear = date.getUTCFullYear();

  return getday + ", " + getmon + " " + getdate + ", " + getyear;
}

export function DefaultFormatDate(d) {
  //debugger;
  var date = new Date(d);
  var getdate = date.getDate();
  var getmon = date.getMonth() + 1;
  var getyear = date.getFullYear();

  return getmon + "/" + getdate + "/" + getyear;
}

export function getAMPM(d) {
  var date = new Date(d);
  var hours = date.getUTCHours();
  var result = hours >= 12 ? "PM" : "AM";
  return result;
}

export function formatTime(d) {
  var date = new Date(d);
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();

  hours = hours % 12;

  // To display "0" as "12"
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes;
}

export function validTime(t) {
  var regex = /^(\d{1,2}):(\d{2})?$/;
  var time = t.match(regex);
  var bTime = true;
  if (regex.test(t)) {
    if (time[1] < 1 && time[1] > 12) {
      bTime = false;
    }
    if (time[2] > 59) {
      bTime = false;
    }
  } else {
    bTime = false;
  }
  return bTime;
}

export function validMoneyNotZero(money, msg) {
  debugger;
  var bMoney,
    s = "";
  bMoney = money.trim() != "";
  //bMoney = Number.isInteger(parseFloat(money));
  bMoney = parseFloat(money.replace(/,/g, ""));
  if (bMoney) {
    console.log("validMoneyNotZero start:", parseFloat("-214748.3648"));
    console.log("validMoneyNotZero end:", parseFloat("214748.3647"));
    bMoney = parseFloat(money) >= parseFloat("-214748.3648");
    bMoney = parseFloat(money) <= parseFloat("214748.3647");
    bMoney = parseFloat(money) != parseFloat("0.00");
    if (!bMoney) {
      s = "(between -214748.36 and 214748.36 and not 0)";
    }
  }
  if (!bMoney) {
    if (msg == "") {
      msg = "Please enter a valid dollar amount";
    }
    return msg + s;
  } else {
    return "";
  }
}

export function validMoney(money, msg) {
  debugger;
  var bMoney,
    s = "";
  bMoney = money.trim() !== "";
  //bMoney = Number.isInteger(parseFloat(money));
  bMoney = parseFloat(money.replace(/,/g, ""));
  //alert(bMoney);
  if (bMoney) {
    console.log("validMoney start:", parseFloat("-214748.3648"));
    console.log("validMoney end:", parseFloat("214748.3647"));
    bMoney = parseFloat(money) >= parseFloat("-214748.3648");
    bMoney = parseFloat(money) <= parseFloat("214748.3647");
    if (!bMoney) {
      s = "(between -214748.36 and 214748.36)";
    }
  }
  if (!bMoney) {
    if (msg === "") {
      msg = "Please enter a valid dollar amount";
    }
    return msg + s;
  } else {
    return "";
  }
}

export function getSysDate() {
  var date = new Date();
  return date;
}

export function getUTCSysDate() {
  // var date = new Date();
  //let utcDate = new Date(Date.UTC(date));
  var m,
    d = new Date();
  return d.toUTCString();
}
export function convertUTCDate(d) {
  let utcDate = Date.UTC(d);
  return utcDate;
}
export function convertDate(d) {
  var date = new Date(d);
  return date;
}
export function addMonthsUTC(date, count) {
  if (date && count) {
    var m,
      d = (date = new Date(+date)).getUTCDate();

    date.setUTCMonth(date.getUTCMonth() + count, 1);
    m = date.getUTCMonth();
    date.setUTCDate(d);
    if (date.getUTCMonth() !== m) date.setUTCDate(0);
  }
  return date;
}
//non-UTC dates
export function addMonths(date, count) {
  if (date && count) {
    var m,
      d = (date = new Date(+date)).getDate();

    date.setMonth(date.getMonth() + count, 1);
    m = date.getMonth();
    date.setDate(d);
    if (date.getMonth() !== m) date.setDate(0);
  }
  return date;
}
