import React, { Component, CSSProperties } from "react";
import { IAirlineCodesParameter, Istate } from "../../../Model/IAirlineCodes";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { BCDcodeMaintenanceController } from "../../../Controllers/BCDcodeMaintenanceController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import ReactPaginate from 'react-paginate';
import { MultilineDropdown } from "react-multiline-dropdown";
import { alert, confirm } from 'react-bootstrap-confirmation';


export class AirlineCodes extends Component<IAirlineCodesParameter,Istate> {
    constructor(props:IAirlineCodesParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {id:'',orderId:'',description:'',bcdAirlineCode:'',search:'',isEdit:false},
            selectfields:{bcdAirlineCode:''},
            errors:{id:'',orderId:'',description:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            airlineCodesGroups:[],             
            offset: 0,
            tableData: [],
            airlineVendorsGrp:[],          
            perPage: 10,
            currentPage: 0,
            pageCount:0
        } 
        this.handlePageClick = this.handlePageClick.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this)
        this.onClear = this.onClear.bind(this);
    }

    handleTextChange(e:any){  
        let fields = this.state.fields;  
        let errors = this.state.errors;
        var numregex = new RegExp("^[0-9\b]+$");
        if(e.currentTarget.id === "orderId")    
        {
            if (e.currentTarget.value === '' ||numregex.test(e.currentTarget.value)) {
                fields[e.currentTarget.id] = e.currentTarget.value;
                errors[e.currentTarget.id] = "";
            }  
        }           
        else 
        {
            fields[e.currentTarget.id] = e.currentTarget.value;  
            errors[e.currentTarget.id] = "";
            if(fields["id"] === "")
            {
                fields["isEdit"]=false;
            }   
        }     
        this.setState({fields,errors});       
    }

    handleSelect= (e: any) =>  {
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.id] =  e.currentTarget.value;
        fields[e.currentTarget.id] = e.currentTarget.value; 
        this.setState({selectfields,fields});
      }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true; 
        this.getAirlineCodeList();     
        this.getBCDAirlinevendorList();   
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    getBCDAirlinevendorList=()=>{
        let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();        
        bcdcodeMaintenanceController.GetBCDAirlineVendors().then(jsonBCDAirlineVendorsList => {
            this.setState({ airlineVendorsGrp: JSON.parse(JSON.stringify(jsonBCDAirlineVendorsList))}); 
        }).catch(error => {
            console.error("There was an error!", error);            
        })  
    }

    loadMoreData() {
		const data = this.state.airlineCodesGroups;
		
		const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
		this.setState({
			pageCount: Math.ceil(data.length / this.state.perPage),
			tableData:slice
		})
    }

    getData() {        
                //var data = res.data;
                var data = this.state.airlineCodesGroups;				
                var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)               
             this.setState({
                    pageCount: Math.ceil(data.length / this.state.perPage),                   
                    tableData:slice
                }) 
    }

    onSearch(e:any){
        e.preventDefault();
        let modal = this.state.modal; 
        modal['modalSpinnerShow']=true;         
        let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
        var data={
            description:this.state.fields.search
        }
        bcdcodeMaintenanceController.GetFilterAirlineCodesList(data).then(jsonAirlineFilterList => {
            this.setState({ airlineCodesGroups: JSON.parse(JSON.stringify(jsonAirlineFilterList))});            
            this.getData()
            modal['modalSpinnerShow']=false; 
            this.setState({modal,offset:0})         
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })
    }    

    onClear(e:any)
    {
        e.preventDefault();
        this.clearData();
        this.getAirlineCodeList();
    }

    clearData()
    {
        let fields = this.state.fields             
        let selectfields = this.state.selectfields
        let errors = this.state.errors
        fields["id"] ="";
        fields["orderId"] = "";
        fields["description"]  = "";
        fields["bcdAirlineCode"] = "";
        fields["search"] = "";
        fields["isEdit"] = false;
        selectfields["bcdAirlineCode"] = "";
        errors["id"]="";
        errors["orderId"]="";
        errors["description"]="";
        this.setState({selectfields,fields,errors})
    }

    onSave(e:any){
        e.preventDefault();
        let fields = this.state.fields
        let modal = this.state.modal;         
        var doBy = "";
        if(this.validateAirlineCode())
        {
            modal['modalSpinnerShow']=true; 
            if(fields["isEdit"])
                doBy = "U";
            else
             doBy = "I";
            let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
            var data = {  
                    doBy:doBy,           
                    id:fields["id"],
                    orderId:fields["orderId"], 
                    description:fields["description"],
                    bcdAirlineCode:fields["bcdAirlineCode"]                      
                }
                bcdcodeMaintenanceController.insertUpdateDeleteAirlineCodes(data).then(response => { 
                    if(fields["isEdit"])
                      alert('Airline Code Updated')
                    else
                      alert('Airline Code Added')
                    this.getAirlineCodeList(); 
                    this.clearData();   
                    modal['modalSpinnerShow']=false;  
                    this.setState({modal})            
                }).catch(error => {
                    console.error("There was an error!", error)
                })   
        }
    }

    

    async onDelete(data) {      
        var flag = await confirm("Are you sure want to Delete?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
        if(flag){
            let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
            var data1 = {  
                doBy:"D",           
                id:data["id"],
                orderId:data["orderId"], 
                description:data["description"],
                bcdAirlineCode:data["bcdAirlineCode"]                      
            }
            bcdcodeMaintenanceController.insertUpdateDeleteAirlineCodes(data1).then(response => { 
                alert('Data Deleted..')  
                this.getAirlineCodeList();  
                this.clearData();              
            }).catch(error => {
                console.error("There was an error!", error)
            })
        }   
    }

    onEdit=(e:any,data)=>{
        e.preventDefault();
        let fields = this.state.fields     
        let selectfields = this.state.selectfields      
        let errors = this.state.errors   
        fields["id"] = data["id"];
        fields["orderId"] = data["orderId"]; 
        fields["description"]  = data["description"];
        errors["id"] = "";
        errors["orderId"] = ""; 
        errors["description"]  = "";
        fields["bcdAirlineCode"] = (data["bcdAirlineCode"] === null) || (data["bcdAirlineCode"] === "")  ? "" : data["bcdAirlineCode"];
        fields["isEdit"] = true;
        selectfields["bcdAirlineCode"] = (data["bcdAirlineCode"] === null) || (data["bcdAirlineCode"] === "") ? "" : data["bcdAirlineCode"];
        this.setState({selectfields,fields,errors})
    }

    validateAirlineCode=()=>{
        var isValid=true
        let fields = this.state.fields;
        let errors = this.state.errors
        if(fields["id"] === "")
        {
            isValid=false;
            errors["id"]="Enter ID";
        }
        if(fields["description"] === "")
        {
            isValid=false
            errors["description"]="Enter Description";
        }
        if(fields["orderId"] === "")
        {
            isValid=false
            errors["orderId"]="Enter Order";
        } 
        if(isValid  && !fields["isEdit"])
        {
            var sta = this.state.airlineCodesGroups.filter(item => fields["id"] === item.id);
            if(sta.length >0)
            {
                isValid=false
                alert("ID Already Exists.Please Choose Differtent");
            }
        }        
        this.setState({fields,errors})
        return isValid;
    }
    

    getAirlineCodeList =()=>{
        let modal = this.state.modal;         
        let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
        
        bcdcodeMaintenanceController.GetAirlineCodeList().then(jsonAirlineList => {
            this.setState({ airlineCodesGroups: JSON.parse(JSON.stringify(jsonAirlineList))});             
            this.getData()
            modal['modalSpinnerShow']=false; 
            this.setState({modal})             
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})    
        })         
    }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Airline Codes</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                       <Col sm={1}>
                                         <FormInputs
                                                properties={{id:"id",label:"ID",type:"text",name:"id",maxlength:3,value:this.state.fields.id,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.id}
                                                fieldRequired={true}
                                                disbled={true}                                                
                                            />
                                       </Col>
                                        <Col sm={4}>
                                            <FormInputs
                                                properties={{id:"description",label:"Description",type:"text",name:"description",value:this.state.fields.description,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.description}
                                                fieldRequired={true}
                                                disbled={true}
                                            />
                                        </Col> 
                                        <Col sm={1}>
                                         <FormInputs
                                                properties={{id:"orderId",label:"Order",type:"text",name:"orderId",value:this.state.fields.orderId,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.orderId}
                                                fieldRequired={true}
                                                disbled={true}
                                            />
                                       </Col>
                                       <Col sm={4}>                                        
                                        <Form.Group>                                       
                                            <Form.Control as="select" multiple id="bcdAirlineCode" name="bcdAirlineCode" className="form-control form-control-sm"  value={this.state.selectfields.bcdAirlineCode} onChange={(e)=>{this.handleSelect(e)}}>
                                                {this.state.airlineVendorsGrp.map((prop, key) => 
                                                    {
                                                        return(
                                                        <option value={prop["id"]}>{prop["name"]}</option>
                                                        )
                                                    })}
                                                </Form.Control>                                        
                                        </Form.Group>
                                       </Col>
                                       <Col sm={1}>
                                         <FormInputs
                                                properties={{id:"bcdAirlineCode",label:"Codes",type:"text",name:"bcdAirlineCode",value:this.state.fields.bcdAirlineCode,className:"form-control input-sm"}}
                                                errorMsg={this.state.errors.search}
                                                fieldRequired={false}
                                                disbled={true}
                                            />
                                       </Col>                                       
                                    </Row>
                                   <Row>
                                       <Col sm={4}>
                                           <FormInputs
                                                properties={{id:"search",label:"Search",type:"text",name:"search",value:this.state.fields.search,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.search}
                                                fieldRequired={false}
                                                disbled={true}
                                            />
                                        </Col>                                         
                                        <Col sm={1} style={{marginTop:"2%"}} >
                                            <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSearch(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Search</span>}
                                            </Button>
                                        </Col>
                                        <Col sm={2} style={{marginTop:"2%"}}>
                                            <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onClear(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Clear</span>}
                                            </Button>                                            
                                        </Col>
                                        <Col sm={3}></Col>
                                        <Col sm={1} style={{marginTop:"2%"}}>
                                            <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSave(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Add/Update</span>}
                                            </Button>
                                        </Col>
                                    </Row>                                     
                                    <Row style={{marginBottom:"2%"}}>                                    
                                    </Row>                                    
                                    <Row>                                        
                                       <Col sm={12}>                                        
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th style={thStyle}>ID</th>
                                                                <th style={thStyle}>Description</th>
                                                                <th style={thStyle}>Order #</th>
                                                                <th style={thStyle}>CTM Airline Code</th>            
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.tableData.map((prop, key) => {
                                                                return (
                                                                    <tr style={{textAlign:"center",cursor:"pointer"}}  key={key} > 
                                                                    <td style={tdStyle}>{prop["id"]}</td>                                                                                                                         
                                                                    <td style={tdStyle}>{prop["description"]}</td>
                                                                    <td style={tdStyle}>{prop["orderId"]}</td>
                                                                    <td style={tdStyle}>{prop["bcdAirlineCode"]}</td>                                                                       
                                                                    <td style={tdStyle}>
                                                                    <tr>
                                                                            <td style={{borderTop:"0px"}}>
                                                                                <Button id="edit" type="button" size="sm" disabled={loading} onClick={(e) => this.onEdit(e,prop)}>
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Edit</span>}
                                                                                </Button>
                                                                                <Button id="edit" type="button" size="sm" style={{ marginLeft: "10px"}} disabled={loading} variant="danger" onClick={(e) => this.onDelete(prop)}>
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7", }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Delete</span>}
                                                                                </Button>
                                                                            </td>                                                                            
                                                                        </tr>
                                                                    </td>
                                                                    </tr>                                                                    
                                                                )
                                                            })}                                                                                                                      
                                                        </tbody>
                                                </Table>                                                                                                        
                                            </div> 
                                       </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}></Col>
                                         <Col><ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}/> </Col>                   
                                    </Row>                                   
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}