import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class ApprovalProcessController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    getApprovalProcess(data){
        
        var data1 = {
            labelId: data.labelId,
            expenseTypeId: data.expenseTypeId
        };
        var url = this.variables.Url + this.variables.Controller.approvalProcess + this.variables.approvalProcessService.getApprovalProcess;
        
        return this.serviceProxy.get(url,data1)
        .then(response => {
            console.log(response,"hgggh")
            var jsonProcess : Array<{"labelId": string;"labelDesc": string; "departmentId": string; "departmentDesc": string, "expenseTypeId": string, "expenseTypeDesc": string,  "maxApprovers": string,  "tierCode": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
            function label(item: { label_id: string; label_desc: string; department_id: string;  department_desc: string; expense_type_id: string, expense_type_desc: string; max_approvers: string, tier_code: string }, _index: number){
                    if (item.expense_type_id !== "2" && item.expense_type_id !== "11") {
                    jsonProcess.push({"labelId": item.label_id, "labelDesc": item.label_desc, "departmentId": item.department_id, "departmentDesc":item.department_desc, "expenseTypeId":item.expense_type_id, "expenseTypeDesc":item.expense_type_desc, "maxApprovers":item.max_approvers, "tierCode":item.tier_code })
                }}
                return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonProcess).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });  
    }
    getApproverTierCodesWeight(){
        
        var url = this.variables.Url + this.variables.Controller.approvalProcess + this.variables.approvalProcessService.getApproverTierCodesWeight;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonCodesWeight : Array<{"approverWeight": string;"description": string; }> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { approver_weight: string; description: string; }, _index: number){
                    jsonCodesWeight.push({"approverWeight": item.approver_weight, "description": item.description })
                }
                return JSON.parse(JSON.stringify(jsonCodesWeight).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCodesWeight).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });  
    }
    getApproverTierCodes(){
        
        var url = this.variables.Url + this.variables.Controller.approvalProcess + this.variables.approvalProcessService.getApproverTierCodes;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonCode : Array<{"approverTierId": string;"description": string; }> = [];
            if(response.success){
                response.recordset.forEach(code);
                function code(item: { approver_tier_id: string; description: string; }, _index: number){
                    jsonCode.push({"approverTierId": item.approver_tier_id, "description": item.description })
                }
                return JSON.parse(JSON.stringify(jsonCode).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCode).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    insertApprovalProcess (data){
        var url = this.variables.Url + this.variables.Controller.approvalProcess + this.variables.approvalProcessService.insertApprovalProcess;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }  
    updateApprovalProcess (data){
        var url = this.variables.Url+ this.variables.Controller.approvalProcess + this.variables.approvalProcessService.updateApprovalProcess;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    deleteApprovalProcess (data){
        var url = this.variables.Url+ this.variables.Controller.approvalProcess + this.variables.approvalProcessService.deleteApprovalProcess;
        return this.serviceProxy.delete(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}
