import React,{ Component } from 'react';
import { History, LocationState } from "History";
import { Button } from "../../../../node_modules/react-bootstrap";
import { BCDWSController } from '../../../Controllers/BCDWSController';
import { constants } from 'buffer';
import * as Constants from "../../../js/Constants";

export class SubmitButton extends Component<IProperties,Istate>{ 
  constructor(props:IProperties){
    super(props);
    this.state = {
      loading: false
    }
    this.onSubmit = this.onSubmit.bind(this);
  }
  
  onSubmit(){
    this.setState({ loading: true });
    var result = '';
    let bcdwsController = new BCDWSController();
    var data = {
      authReqId : sessionStorage.getItem('ta')
    }
    
    bcdwsController.BCDSubmitTAValidation(data).then(response => {
      if(response === 'BCD_VALIDATED'){
        // console.log("BCD Validation Success...");
        this.props.redirect.push('/admin/submitauthreq');
      }
      else{
        alert(response);
      }
      this.setState({ loading: false });
    })
  }

  render(){ 
    const { loading } = this.state;

    if((sessionStorage.getItem("ta_status_id") === Constants.Pending.toString() || sessionStorage.getItem("ta_status_id") === Constants.ReSubmit.toString()) && sessionStorage.getItem("user_type_id") === "R")
    {
      return(
        <div id="divSubmit">
          <Button id="submit" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit()} variant="success">
            {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" , color:"#6c757d" }}
            />
            )}
            {loading && <span style={{color:"#6c757d"}}>wait...</span>}
            {!loading && <span>Submit { sessionStorage.getItem("ta_car_service_only") === "true" ? "CA" : "TA" }</span>}
          </Button>
        </div>
      )
    }
    else
    {
      return <></>
    }
  }
}

interface IProperties{
  loading?: boolean;
  userId?: string;
  password?: string;
  history?: History<LocationState>;
  redirect?: History<LocationState>;
}

interface Istate{
  loading:boolean;
}