import React, { Component } from "react";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { IDirectBillContactParameter, Istate } from "../../../Model/IDirectBillContact";
import { CommonController } from "../../../Controllers/CommonController";
import { DirectBillActivityController } from '../../../Controllers/DirectBillActivityController';
import { MenuController } from "../../../Controllers/MenuController";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import { createLogicalOr } from "typescript";
import { alert, confirm } from 'react-bootstrap-confirmation';

export class DirectBillContact extends Component<IDirectBillContactParameter, Istate> {
  constructor(props: IDirectBillContactParameter) {
    super(props)
    this.state = {
      fields: {
        label_id: sessionStorage.getItem("label_id"), authreq_id: '', txtSearch: '', contact_id: '', country_id: '', stateprov: '', submitter: '', expense_type_id: '', weight: '', tier_code: '', startDate: '', find: '*', searchScope: '', deptApp: [], billingApp: [], authApp: [], srexecApp: [], agentApp: [], searchValue: '', sortClicked: false, sortBy: '', orderBy: '', appCnt: 0,
        contact_type: '', bookings:'', travel_type:'', resval:'', contact_name: '', address1: '', address2: '', city: '', state_province_id: '', state_province: '', zip: '', phone: '', fax: '', attn: '', dept: '', vendor_number: '', centrally_billed: true, comments: '', showBtn: false
      },
      selectfields: { tracking_grid: '', label_id: '', state_province_id: '', country: '', searchString: '', formLabel: '', bookings: '' },
      errors: { contact_type: '', contact_name: '', travel_type:'', address1: '', address2: '', city: '', state_province_id: '', state_province: '', zip: '', phone: '', fax: '', attn: '', dept: '' },
      errMsg: "",
      loading: false,
      modal: { modalShow: false, modalTitle: '', modalBody: false, modalAlertShow: false, modalAlertTitle: '', modalAlertErrorMsg: '', modalSpinnerShow: false },
      startDate: new Date(),
      endDate: new Date(),
      FormLabel: [],
      HotelSearch: [],
      Country: [],
      ConListView: [],
      Approver: [],
      State: [],
      ContactView: [],
      currentSort: 'down',
      currentColumn: 'authreq_Id',
      resCheck: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.handleOnAdd = this.handleOnAdd.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  componentWillMount() {
    
    let modal = this.state.modal;
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    //Declaration begins
    fields["country_id"] = "US";
    fields["searchValue"] = "ar.authreq_id";
    fields["txtSearch"] = "*";
    fields["state_province_id"]=0;
    selectfields["searchString"] = "AuthReq #";
    selectfields['tracking_grid'] = 'In-Progress';
    selectfields['searchDays'] = "3";
    selectfields['bookings'] = "Hotel";

    //modal['modalSpinnerShow']=true;

    this.setState({ modal, selectfields, fields });
    let commonController = new CommonController();
    commonController.getLabels().then(jsonLabels => {
      this.setState({
        FormLabel: JSON.parse(JSON.stringify(jsonLabels))
      });
      let selectfields = this.state.selectfields;
      selectfields['formLabel'] = sessionStorage.getItem("label_id");
      this.setState({ modal, selectfields });
    }).catch(error => {
      console.error("There was an error loading Labels..., " + error)
    });
    this.sortByCol();

    this.loadContact();
    this.loadCountry();
    this.loadState();

    this.setState({ selectfields, fields });

  }

  loadCountry = () => {
    let modal = this.state.modal;

    let hotelGridController = new DirectBillActivityController();
    hotelGridController.getDirectBillCountry().then(response => {
      //
      if (response.success) {
        let fields = this.state.fields;
        this.setState({
          Country: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }
        //  this.state.fields.country_id = "US"
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

  }

  loadState = () => {
    let modal = this.state.modal;

    var data = {};
    data = {
      country_id: this.state.fields.country_id
    }
    let hotelGridController = new DirectBillActivityController();
    hotelGridController.getDirectBillState(data).then(response => {
      //
      if (response.success) {
        let fields = this.state.fields;
        this.setState({
          State: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          this.state.fields.state_province_id = ''
          modal['modalSpinnerShow'] = false;
          let fields = this.state.fields;
          this.setState({ fields, modal });
        }
        this.state.State.map((prop, key) => {
          fields["state_province_id"] = prop["state_province_id"] || ''
          this.setState({ fields });

        }).catch(error => {
          console.error("There was an error.., " + error)
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        });

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });

      }

    }).catch(error => {
      console.error("There was an errorh..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

  }

  loadSearchContactData = () => {
    let modal = this.state.modal;
    let fields = this.state.fields;

    var data = {};
    data = {
      contact_id: this.state.fields.contact_id
    }
    let hotelGridController = new DirectBillActivityController();
    hotelGridController.getDirectBillContactData(data).then(response => {
      //
      if (response.success) {
        let fields = this.state.fields;
        this.setState({
          ContactView: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }
       
        this.state.ContactView.map((prop, key) => {
          fields["contact_type"] = prop["contact_type"] || ''
          fields["contact_name"] = prop["name"] || ''
          fields["country_id"] = prop["country_id"] || ''
          fields["address1"] = prop["address1"] || ''
          fields["address2"] = prop["address2"] || ''
          fields["city"] = prop["city"] || ''
          fields["state_province_id"] = prop["state_province_id"] || ''
          fields["state_province"] = prop["state_province"] || ''
          fields["zip"] = prop["zip"] || ''
          fields["phone"] = prop["phone"] || ''
          fields["fax"] = prop["fax"] || ''
          fields["attn"] = prop["attn"] || ''
          fields["dept"] = prop["dept"] || ''
          fields["vendor_number"] = prop["vendor_number"] || ''
          fields["centrally_billed"] = prop["centrally_billed"] || ''
          fields["comments"] = prop["comments"] || ''
        let contact_type = prop["contact_type"]  
          fields["showBtn"] = true

          if (contact_type == "HTL") {
            fields["bookings"] = 'Hotel'
          }
          else {
            fields["bookings"] = 'Rental Car'
          }
          this.setState({ fields });

        }).catch(error => {
          console.error("There was an error loading Travller Search..., " + error)
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        });

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });

      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

  }


  loadContact = () => {
    let modal = this.state.modal;
    let fields = this.state.fields;
    let searchstring = '*';

    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      searchstring: searchstring
    }
    hotelGridController.getDirectBillSearchContact(data).then(response => {
      //
      if (response.success) {
        this.setState({
          ConListView: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }

        modal['modalSpinnerShow'] = false;
        modal['modalSearchShow'] = false;
        fields["dropContactdis"] = false;
        this.setState({ modal, fields });
      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

  }

  handleOnSubmit = (e: any, currentTargetName) => {
    let modal = this.state.modal;
    modal['modalSpinnerShow'] = true;
    this.setState({ modal });
    this.loadSearchHotelGrid();
  }

  loadClearData = () => {
    let fields = this.state.fields;

    this.state.fields.contact_name = '';
    this.state.fields.address1 = '';
    this.state.fields.address2 = '';
    this.state.fields.city = '';
    this.state.fields.state_province = '';
    this.state.fields.zip = '';
    this.state.fields.phone = '';
    this.state.fields.fax = '';
    this.state.fields.attn = '';
    this.state.fields.dept = '';
    this.state.fields.vendor_number = '';
    this.state.fields.centrally_billed = '';
    this.state.fields.comments = '';

    this.setState({ fields });
  }

  clearErrorContact() {
    let errors = this.state.errors;
    errors["contact_type"] = "";
    errors["contact_name"] = "";
    errors["address1"] = "";
    errors["city"] = "";
    errors["state_province_id"] = "";
    errors["state_province"] = "";
    errors["zip"] = "";
    errors["phone"] = "";
    errors["fax"] = "";
    errors["attn"] = "";
    errors["dept"] = "";
  }

  ValidateContact() {

    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let errors = this.state.errors;
    let formIsValid;

    if (!selectfields["bookings"]) {
      errors["contact_type"] = "The Contact Type field is empty.";
    }
    else
    {       
      errors["contact_type"] = "";
    }
       
    if (!fields["contact_name"]) {     
      errors["contact_name"] = "The Contact Name field is empty.";
    }
    else
    {       
      errors["contact_name"] = "";
    }
    
    if (!fields["address1"]) {     
      errors["address1"] = "The Address field is empty.";
    }
    else
    {        
      errors["address1"] = "";
    }
  
    if (!fields["city"]) {      
      errors["city"] = "The City field is empty.";
    }
    else
    {         
      errors["city"] = "";
    }
     
    if (this.state.fields.state_province_id)
    {
    if (!fields["state_province_id"]) {     
      errors["state_province_id"] = "The State/Province field is empty.";
    }
    else
    {          
      errors["state_province_id"] = "";
    }
    }
    else{
      if (!fields["state_province"]) {     
        errors["state_province"] = "The State/Province field is empty.";
      }
      else
      {          
        errors["state_province"] = "";
      }
    }

    if (!fields["zip"]) {     
      errors["zip"] = "The Zip field is empty.";
    }
    else
    {         
      errors["zip"] = "";
    }

    if (!fields["phone"]) {     
      errors["phone"] = "The Phone field is empty.";
    }
    else
    {         
      errors["phone"] = "";
    }

    if (!fields["fax"]) {     
      errors["fax"] = "The Fax field is empty.";
    }
    else
    {         
      errors["fax"] = "";
    }

    if (!fields["attn"]) {     
      errors["attn"] = "The Attention field is empty.";
    }
    else
    {         
      errors["attn"] = "";
    }

    if (!fields["dept"]) {     
      errors["dept"] = "The Department field is empty.";
    }
    else
    {         
      errors["dept"] = "";
    }

    if (this.state.fields.state_province_id)
    {
    if(!selectfields["bookings"] || !fields["contact_name"] ||  !fields["address1"] ||  !fields["city"] || !fields["state_province_id"] || !fields["zip"]
    || !fields["phone"] ||  !fields["fax"] ||  !fields["attn"] ||  !fields["dept"])
    {
      formIsValid = false;
    }
    else{
      formIsValid = true;
    }
    }
    else
    {
      if(!selectfields["bookings"] || !fields["contact_name"] ||  !fields["address1"] ||  !fields["city"] || !fields["state_province"] || !fields["zip"]
    || !fields["phone"] ||  !fields["fax"] ||  !fields["attn"] ||  !fields["dept"])
    {
      formIsValid = false;
    }
    else{
      formIsValid = true;
    }
    }

    if (!formIsValid) {
      this.setState({ loading: false });

    }
    this.setState({
      errors
    });
    //console.log(formIsValid);
    return formIsValid;
  }


  handleOnSave = (e: any, currentTargetName) => {
    let fields = this.state.fields;
    let modal = this.state.modal;
    let contact_type;

    if (this.state.selectfields.bookings == 'Hotel') {
      contact_type = 'HTL'
    }
    else {
      contact_type = 'CAR'
    }
    if (this.ValidateContact()) {


      var data = {};
      data = {
        contact_id: this.state.fields.contact_id,
        contact_type: contact_type,
        contact_name: this.state.fields.contact_name,
        address1: this.state.fields.address1,
        address2: this.state.fields.address2,
        city: this.state.fields.city,
        state_province_id: this.state.fields.state_province_id,
        state_province: this.state.fields.state_province,
        zip: this.state.fields.zip,
        country_id: this.state.fields.country_id,
        phone: this.state.fields.phone,
        fax: this.state.fields.fax,
        attn: this.state.fields.attn,
        dept: this.state.fields.dept,
        vendor_number: this.state.fields.vendor_number,
        centrally_billed: this.state.fields.centrally_billed,
        comments: this.state.fields.comments,
        updateby: sessionStorage.getItem("uid")
      }


      let hotelGridController = new DirectBillActivityController();
      hotelGridController.directBillUpdateContact(data).then(response => {      //

        alert('Contact has been updated successfully!')
        
        //this.loadClearData();
        this.loadContact();

      }).catch(error => {
        console.error("There was an error loading Travller Search..., " + error)
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      });

    }

  }

  handleOnAdd = (e: any, currentTargetName) => {
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let contact_type;

    if (this.state.selectfields.bookings == 'Hotel') {
      contact_type = 'HTL'
    }
    else {
      contact_type = 'CAR'
    }

    let hotelGridController = new DirectBillActivityController();
    if (this.ValidateContact()) {     
    
      var data1 = {};
     
      data1 = {
        contact_name: this.state.fields.contact_name,
        phone: this.state.fields.phone
      }

       hotelGridController.checkDirectBillContactData(data1).then(response => {      //
      
        if (response.success) {
         
          this.setState({
            resCheck: JSON.parse(JSON.stringify(response.recordset)), fields
          });

          if (response.recordset.length === 0) {
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });
          }
          else {
          this.state.resCheck.map((prop, key) => {
            fields["resval"] = prop["avalue"] || ''
          })
        
          this.setState({ fields });

          this.insertContactdata();
        }    
      }        

      }).catch(error => {
        console.error("There was an error " + error)
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      });      
    }

  
  }

  insertContactdata ()
  {
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let contact_type;

    if (this.state.selectfields.bookings == 'Hotel') {
      contact_type = 'HTL'
    }
    else {
      contact_type = 'CAR'
    }

    let hotelGridController = new DirectBillActivityController();
       

    if (fields["resval"] == 1)
    {
      const r = window.confirm("The Contact Name "+ this.state.fields.contact_name + " already exist are you sure you wish to proceed?")
     
      if (r == true) {
          
    var data = {};
    data = {  
      contact_type: contact_type,
      contact_name: this.state.fields.contact_name,
      address1: this.state.fields.address1,
      address2: this.state.fields.address2,
      city: this.state.fields.city,
      state_province_id: this.state.fields.state_province_id,
      state_province: this.state.fields.state_province,
      zip: this.state.fields.zip,
      country_id: this.state.fields.country_id,
      phone: this.state.fields.phone,
      fax: this.state.fields.fax,
      attn: this.state.fields.attn,
      dept: this.state.fields.dept,
      vendor_number: this.state.fields.vendor_number,
      centrally_billed: this.state.fields.centrally_billed,
      comments: this.state.fields.comments,
      updateby: sessionStorage.getItem("uid")

    }
    
    hotelGridController.directBillInsertContact(data).then(response => {      //

      alert('Contact has been added successfully!')

      this.loadClearData();
      this.loadSearchContactData();
      this.loadContact();

    }).catch(error => {
      console.error("There was an error " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

    }
    else{
      this.loadClearData();
    }
    }

    else{     
      var data = {};
      data = {  
        contact_type: contact_type,
        contact_name: this.state.fields.contact_name,
        address1: this.state.fields.address1,
        address2: this.state.fields.address2,
        city: this.state.fields.city,
        state_province_id: this.state.fields.state_province_id,
        state_province: this.state.fields.state_province,
        zip: this.state.fields.zip,
        country_id: this.state.fields.country_id,
        phone: this.state.fields.phone,
        fax: this.state.fields.fax,
        attn: this.state.fields.attn,
        dept: this.state.fields.dept,
        vendor_number: this.state.fields.vendor_number,
        centrally_billed: this.state.fields.centrally_billed,
        comments: this.state.fields.comments,
        updateby: sessionStorage.getItem("uid")

      }
      
      hotelGridController.directBillInsertContact(data).then(response => {      //

        alert('Contact has been added successfully!')

        this.loadClearData();
        this.loadSearchContactData();
        this.loadContact();
        

      }).catch(error => {
        console.error("There was an error " + error)
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      });
  

  }


  }


  handleOnDelete = (e: any, currentTargetName) => {
    let fields = this.state.fields;
    let modal = this.state.modal;

    var data = {};
    data = {
      contact_id: this.state.fields.contact_id
    }

    let hotelGridController = new DirectBillActivityController();

    if(this.state.fields.contact_id)
    {
      const r = window.confirm("Are you sure you wish to delete?")
     
      if (r == true) {      
    hotelGridController.directBillDeleteContact(data).then(response => {      //

      alert('Contact has been deleted successfully!')

      this.loadSearchContactData();
      this.loadClearData();
      this.loadContact();

      fields["state_province_id"] = 1;
      this.setState({ fields });

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });
  }
  }
  else{
    
    alert('Please select Contact')
  }

  }

  handleOnClear = (e: any, currentTargetName) => {
    this.loadClearData();
  }


  handleSelectChange = (e: any) => {
    
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let currentTargetName = e.currentTarget.name;

    if (currentTargetName === "searchString") {
      fields["searchValue"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields, fields });
    }
    else if (currentTargetName === "bookings") {
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      //console.log(selectfields[e.currentTarget.name]);
      this.setState({ selectfields });     
    }
    else if (currentTargetName === "contactList") {
      let fields = this.state.fields;
      let selectfields = this.state.selectfields;
      //item["Ship_Method"] = e.currentTarget.value
      // selectfields['contact_id'] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
      fields["contact_id"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
      selectfields["contactListid"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;

      if (fields["contact_id"] == 0) {
        this.loadClearData();
        fields["showBtn"] = false;
      }

      this.setState({
        fields, selectfields
      })
      
      this.clearErrorContact();
      this.loadSearchContactData();

    }
    else if (currentTargetName === "Country") {
      let fields = this.state.fields;
      let selectfields = this.state.selectfields;

      fields["country_id"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
      selectfields["country"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;


      this.setState({
        fields, selectfields
      })

      this.loadState();

    }
    else if (currentTargetName === "State") {
      let fields = this.state.fields;
      let selectfields = this.state.selectfields;

      fields["state_province_id"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
      // selectfields["state_province_id"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;

      this.setState({
        fields, selectfields
      })
    }

    else {
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields, fields });
    }
  }


  handleChange = (e: any) => {
    
    let fields = this.state.fields;

    if (e.currentTarget.name === 'Name') {
      fields['contact_name'] = e.currentTarget.value;
    }
    else if (e.currentTarget.name === 'AddressOne') {
      fields['address1'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'AddressTwo') {
      fields['address2'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'City') {
      fields['city'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'PostalCode') {
      fields['zip'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'PhoneNo') {
      fields['phone'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'FaxNo') {
      fields['fax'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'Attention') {
      fields['attn'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'Department') {
      fields['dept'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'VendorNo') {
      fields['vendor_number'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'Comments') {
      fields['comments'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'State_Province') {
      fields['state_province'] = e.currentTarget.value.trim();
    }
    else if (e.currentTarget.name === 'txtSearch') {
      fields['txtSearch'] = e.currentTarget.value.trim();
    }


    // if (e.currentTarget.value.trim().length > 0) {
    //   fields[e.currentTarget.name] = e.currentTarget.value;
    //  }txtSearch
    // else {
    //    fields[e.currentTarget.name] = e.currentTarget.value.trim();
    //  }
    // fields['contact_name'] = e.currentTarget.value;
    this.setState({ fields });



  }

  displayContactList = () => {
    return <FormGroup>
      <FormLabel>Contact</FormLabel>
      <Form.Control as="select" size="lg" id="contactList" name="contactList" value={this.state.fields.contact_id} style={{ padding: "0px" }} onChange={this.handleSelectChange} className="form-control" >
      <option value="0">New Contact </option>
      {this.state.ConListView.map((item) => {
        return (
          <option value={item["contact_id"]}>{item["contact_id"]} - {item["name"]}</option>
        )
      })}
    </Form.Control>
    </FormGroup>
  }

  displayCountry = () => {
    return <FormGroup>
    <FormLabel>Country/Region<span className="text-danger" style={{fontSize:'16px'}}>*</span></FormLabel>
    <Form.Control as="select" size="lg" id="Country" name="Country" style={{ padding: "0px" }} onChange={this.handleSelectChange} className="form-control" >
      <option value="US">United States </option>
      {this.state.Country.map((item) => {
        return (
          <option selected={item["country_id"] == 'US'} value={item["country_id"]}>{item["name"]}</option>
        )
      })}
    </Form.Control>
    </FormGroup>
  }

  displayState = () => {
    return <FormGroup>
    <FormLabel>State/Province<span className="text-danger" style={{fontSize:'16px'}}>*</span></FormLabel>
    <Form.Control as="select" size="lg" id="State" name="State" style={{ padding: "0px" }} value={this.state.fields.state_province_id} onChange={this.handleSelectChange} className="form-control" >
      <option selected value="1">Please Select One...</option>
      {this.state.State.map((item) => {
        return (
          <option value={item["state_province_id"]}>{item["name"]}</option>
        )
      })}
    </Form.Control>
    </FormGroup>
  }

  handleContactList = (e: any, currentTargetName) => {
    let fields = this.state.fields;
    let modal = this.state.modal;
    let contact_type = this.state.fields.travel_type;
    let searchstring;
    let contact_id = this.state.fields.contact_id;

    if (!this.state.fields.txtSearch) {
      searchstring = '*'
    }
    else {
      searchstring = this.state.fields.txtSearch;
    }


    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      searchstring: searchstring
    }
    hotelGridController.getDirectBillSearchContact(data).then(response => {
      //
      if (response.success) {
        this.setState({
          ConListView: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }

        modal['modalSpinnerShow'] = false;
        modal['modalSearchShow'] = false;
        fields["dropContactdis"] = false;
        this.setState({ modal, fields });
      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });
  }


  handleModalSearchClose = (e: any) => {
    let modal = this.state.modal;
    let loading = this.state.loading;
    modal['modalSearchShow'] = false;
    modal['loading'] = false;
    this.setState({ modal, loading })
  }

  displayContactSearch = () => {
    return <div id="divContactSearch" style={{ paddingLeft: '40px', width: '100%', marginBottom: '0px' }} >
      <Row>
        <Col sm={8}>
          <div>
            <Table hover>
              <tbody>
                <tr >
                  <td>
                    Search:
                  </td>
                  <td>
                    <div><Form.Control type="text" id="txtSearch" name="txtSearch" className="form-control-sm" value={this.state.fields.txtSearch} onChange={this.handleChange} >
                    </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr >
                  <td>
                    <Button disabled={this.state.loading} name='btnok' style={{ marginRight: "5px" }} onClick={(e) => this.handleContactList(e, e.currentTarget.name)} title="Click to Search">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>OK</span>}
                    </Button>
                  </td>
                  <td>
                    <Button disabled={this.state.loading} name='cancel' style={{ marginRight: "5px" }} onClick={(e) => this.handleModalSearchClose(e)} title="Click to Print">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Cancel</span>}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  }
  handleModalSearchShow = (e: any) => {
    
    let modal = this.state.modal;
    let fields = this.state.fields;
    modal['modalSearchTitle'] = 'eTA - Search';
    modal['modalSearchShow'] = true;
    fields['txtSearch'] = '*'
    this.setState({ modal,fields });

  }

  loadSearchHotelGrid = () => {
    //
    let selectfields = this.state.selectfields;
    let modal = this.state.modal;
    let sOrderBy: string = '';
    let searchScope: string = this.state.fields.searchValue;
    let beginDate: string = Utils.FormatDate(this.state.startDate);
    let endDate: string = Utils.FormatDate(this.state.endDate);
    let searchString: string = this.state.fields.find;
    let box: string = 'incoming';
    let dbview: string = this.state.selectfields['bookings'];
    let label_id: string = this.state.fields.label_id;
    let user_id = sessionStorage.getItem("uid"); // 'Ahamedb8300';
    let usertype_id = sessionStorage.getItem("user_type_id");
    let approvertier_id = '3';

    sOrderBy = sOrderBy + " order by " //+ this.state.fields.orderBy  

    //
    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      box: box,
      dbview: dbview,
      beginDate: beginDate,
      endDate: endDate,
      label_id: label_id,
      user_id: user_id,
      usertype_id: usertype_id,
      searchString: searchString,
      approvertier_id: approvertier_id,
      searchScope: searchScope,
      sOrderBy: sOrderBy
    }
    hotelGridController.getDirectBillHotelActivity(data).then(response => {
      //
      if (response.success) {
        let fields = this.state.fields;
        this.setState({
          HotelSearch: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });

      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });
  }
  onSortChange = (col) => {
    
    const { currentSort } = this.state;
    const { currentColumn } = this.state;
    let nextSort;

    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'default';
    else if (currentSort === 'default') nextSort = 'down';

    let arrayCopy: []
    arrayCopy = [...this.state.HotelSearch];
    arrayCopy.sort(this.compareBySort(col, nextSort));

    this.setState({
      HotelSearch: arrayCopy
    });

    this.setState({
      currentSort: nextSort, currentColumn: col
    });

    //console.log("onSortChange - sort :", currentSort)
    //console.log("onSortChange - column :", currentColumn)
  }

  compareBySort(key, sort) {
    return function (a, b) {
      if (sort === 'up') {
        if (a[key] < b[key]) return -1;
      }
      else if (sort === 'down') {
        if (a[key] > b[key]) return -1;
      }
      return 0;
    }
  }

  setColApp = (rsGrid: any, rs: any, iTier: string) => {
    
    let strHtml: string = '<td></td>';
    if (!rs.success && iTier !== '0' && iTier !== '5') {
      //
      return "<td>&nbsp;</td>";
    }
    else {
      let prevName = "", sUser = "", sUserClose = "", approver_weight = "", name = ""
      let responseApprover = JSON.parse(JSON.stringify(rs.recordset));
      
      name = '';
      responseApprover.map((propApprover, keyApprover) => {

        approver_weight = propApprover["approver_weight"]
        if (sessionStorage.getItem("sysop") !== '') {
          sUser = "<a onClick=getUser('" + propApprover["user_id"] + "')>";
          sUserClose = "</a>";
        }
        
        if (propApprover["name"] !== prevName) {
          if (name !== "") { name = name + "<br>" }
          name = name + sUser + propApprover["name"] + sUserClose;
        }

        if (propApprover["updated_date"] !== null) {
          name = name + "<br><font size=1>" + propApprover["status_type_desc"] + ": " + Utils.FormatDateTime(propApprover["updated_date"]) + "</font>"
        }
        prevName = propApprover["name"];
      })
      if (name !== '' || iTier === '0' || iTier === '5') {
        if ((rsGrid["weight"] === approver_weight && rsGrid["status_id"] !== "3") && !(iTier === '4' && rsGrid["status_id"] !== '8') && !(iTier === '0' && rsGrid["status_id"] === '8') && !(iTier === '5' && rsGrid["status_id"] === '8')) {
          strHtml = '';
          strHtml = "<td style='backgroundColor:#7CFC00;vertical-align: top'>";
        }
        else {
          strHtml = '';
          strHtml = "<td style='backgroundColor:#FFFF00;vertical-align: top'>";
        }
        if (rsGrid["car_service_only"] && iTier === '5') {
          strHtml = strHtml + rsGrid["car_service_company"] + "<br>";
        }
        if (iTier === '0' || iTier === '5') {
          strHtml = strHtml + rsGrid["travel_agent_name"] + "<br>";
        }
        strHtml = strHtml + name;
        if (iTier === String(rsGrid["tier_code"]) && !rsGrid["is_corporate_card"] && iTier !== '0') {
          strHtml = strHtml + '<br><b>Coding</b>';
        }
        strHtml = strHtml + "</td>"
        //console.log ("strHtml:",strHtml)
        return strHtml;
      }
      else {
        return "<td>&nbsp;</td>"
      }
    }
  }
  sqlQ = (s) => {
    let sqlQ: string = "";
    if (s !== "" && s !== null) {
      sqlQ = s.replace("'", "''");
    }
    return sqlQ;
  }
  displayColApp = (prop, key, fields, colName, iTierId) => {
    //
    let modal = this.state.modal;
    fields[colName][key] = parse("<td></td>");
    this.setState({
      fields
    });

  }
  createSortBy = (sort, by) => {
    
    //sort = "ar.authreq_id desc, au.name, etc.description , ar.artist_project_name";
    //by = "au.name";


    var arrSort = sort.split(",");
    let sortString: string = '';
    let orderByString: string = '';
    let makeorderBy: string = '';

    arrSort.map((item, i) => {
      

      if (sort.indexOf(by) > -1) {
        if (item.indexOf(" desc") > -1) {
          if (item.indexOf(by) === -1) {
            sortString = sortString + "," + item.replace(" desc", "");
          }
        } else {
          if (item.indexOf(by) > -1) {
            orderByString = item.replace(" desc", "");;
          }
          else {
            sortString = sortString + "," + item.trim();
          }
        }
      }
      else {
        sortString = sortString + "," + item.trim();
      }
    });
    if (sort.indexOf(" desc") === -1) {
      orderByString = by + " desc";
    }
    return orderByString + sortString;
  }
  formLabel = () => {
    return <Form.Group>
      <Form.Label>Form Label</Form.Label>
      <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} onChange={this.handleSelectChange}>
        {this.state.FormLabel.map((prop, key) => {
          if (prop["is_active"] === "Yes") {
            return (
              <option value={prop["id"]}>{prop["name"]}</option>
            )
          }
          else {
            return (
              <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
            )
          }
        })}
      </Form.Control>
    </Form.Group>
  }

  sortClicked = (col) => {
    //
    let fields = this.state.fields;
    let modal = this.state.modal;
    modal['modalSpinnerShow'] = true;
    fields["sortClicked"] = true;
    fields["sortBy"] = col;
    this.setState({ fields });
    this.sortByCol();
    this.loadSearchHotelGrid();
  }
  sortByCol = () => {
    //
    let fields = this.state.fields;


    if (this.state.fields.sortClicked || this.state.fields.orderBy === "") {
      switch (this.state.fields.sortBy) {
        case "authreq_id": {
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "ar.authreq_id")
          break;
        }
        case "traveler": {
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "traveler")
          break;
        }
        case "label_id": {
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "ar.label_id")
          break;
        }
        case "expense_type_desc": {
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "expense_type_desc")
          break;
        }
        case "out_date": {
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "out_date")
          break;
        }
        case "hotel": {
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "hotel")
          break;
        }
        /* default: { 
           fields["orderBy"] = "ar.label_id, expense_type_desc, ar.authreq_id, traveler, vendor_number, invoice_number"
           break; 
        } */
      }
      //console.log("sWhere: ", fields["orderBy"])
      this.setState({ fields });
    }
  }
  onSelect(authReqId, booking_id) {

    let sbookings = this.state.selectfields['bookings'];


    this.props.history.push({ pathname: '/admin/dbincomingupdate', state: { from: "directbillincoming", authReqId: authReqId, booking_id: booking_id, sbookings: sbookings } });
    this.setState({ loading: false });

  }

  setArrow = (column) => {
    let { currentSort } = this.state;
    let { currentColumn } = this.state;
    let className = "";

    if (column === currentColumn) {
      if (currentSort === "up") {
        className = 'fa fa-fw fa-sort-up';
      }
      else if (currentSort === "down") {
        className = 'fa fa-fw fa-sort-down';
      }
      else {
        className = 'fa fa-fw fa-sort';
      }
    }
    else {
      className = 'fa fa-fw fa-sort';
    }

    return className;
  };

  getLinkWithPath = (item) => {//

    return (<NavLink
      to={{
        pathname: '/admin/billview',
        state: {
          from: "directbill",
          authReqId: item["authReqId"],
          booking_id: item["booking_id"],
          sbookings: this.state.selectfields['bookings']
        }
      }}
      activeClassName="active">{item["Order_ID"]}</NavLink>)


  };

  render() {
    if (this.state.loggedIn === false) {
      //return <Redirect to="/" />
    }
    return (<div className="content">
      <Modal show={this.state.modal.modalSearchShow}
        onHide={this.handleModalSearchClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-40w"
        centered >

        <Modal.Header closeButton>
          {this.state.modal.modalSearchTitle}
        </Modal.Header>
        <Modal.Body className="show-grid" style={{ padding: '0px' }} >
          <Container fluid style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Card style={{ width: '100%', marginBottom: '0px' }}>
              <Card.Body>
                {this.displayContactSearch()}
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>


      <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card border="light" style={{ width: '100%'}}>
              <div className="header"><h4 className="title">Direct Bill Contact</h4><p className="category"></p></div>
              <Card.Body>
                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                <Row style={{textAlign:"right"}}>
                  <Col sm={12}>
                      <Button size="sm" disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                      {this.state.loading && (
                      <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Print </span>}
                      </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}><Col sm={12}></Col></Row>
                <Row>
                  <Col sm={4}>
                    {this.displayContactList()}
                  </Col>
                  <Col sm={1}>
                  </Col>
                  <Col sm={4}>
                    <Button disabled={this.state.loading} name='btnSearch' style={{ marginRight: "5px" }} onClick={(e) => this.handleModalSearchShow(e)} title="Click to Search for a Contact">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Search</span>}
                    </Button>
                  </Col>
                  <Col sm={3}>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                  <FormGroup>
                    <FormLabel>Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></FormLabel>
                        <FormControl as="select" name="bookings" id="bookings" className="form-control-sm" value={this.state.selectfields.bookings} onChange={this.handleSelectChange}>
                          <option value="Hotel">Hotel</option>
                          <option value="Rental Car">Rental Car</option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "Name", label: "Name", type: "text", name: "Name", className: "form-control input-sm", maxlength: 50, value: this.state.fields.contact_name, onChange: this.handleChange }}
                      errorMsg={this.state.errors.contact_name}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={1}>
                  </Col>
                  <Col sm={7}>
                  </Col>
                </Row>
                <Row>
                <Col sm={4}>
                    {this.displayCountry()}
                  </Col>
                  <Col sm={8}></Col>
                </Row>
                <Row>
                  <Col sm={4}>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "AddressOne", label: "Address One", type: "text", name: "AddressOne", className: "form-control input-sm", maxlength: 50, value: this.state.fields.address1, onChange: this.handleChange }}
                      errorMsg={this.state.errors.address1}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "AddressTwo", label: "Address Two", type: "text", name: "AddressTwo", className: "form-control input-sm", maxlength: 50, value: this.state.fields.address2, onChange: this.handleChange }}
                      errorMsg={this.state.errors.AddressTwo}
                      fieldRequired={false}
                    />
                  </Col>
                </Row>
                <Row>
                <Col sm={4}>
                    <FormInputs
                      properties={{ id: "City", label: "City", type: "text", name: "City", className: "form-control input-sm", maxlength: 50, value: this.state.fields.city, onChange: this.handleChange }}
                      errorMsg={this.state.errors.city}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={8}></Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    {(this.state.fields.state_province_id)
                      ?
                      this.displayState()
                      :
                      <FormInputs
                        properties={{ id: "State_Province", label: "State_Province", type: "text", name: "State_Province", className: "form-control input-sm", maxlength: 50, value: this.state.fields.state_province, onChange: this.handleChange }}
                        errorMsg={this.state.errors.State_Province}
                        fieldRequired={true}
                      />
                    }
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "PostalCode", label: "Zip/Postal Code", type: "text", name: "PostalCode", className: "form-control input-sm", maxlength: 10, value: this.state.fields.zip, onChange: this.handleChange }}
                      errorMsg={this.state.errors.zip}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={3}>
                  </Col>
                </Row>
                <Row>
                <Col sm={4}>
                    <FormInputs
                      properties={{ id: "PhoneNo", label: "Phone Number", type: "text", name: "PhoneNo", className: "form-control input-sm", maxlength: 20, value: this.state.fields.phone, onChange: this.handleChange }}
                      errorMsg={this.state.errors.phone}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "FaxNo", label: "Fax Number", type: "text", name: "FaxNo", className: "form-control input-sm", maxlength: 20, value: this.state.fields.fax, onChange: this.handleChange }}
                      errorMsg={this.state.errors.fax}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={3}>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "Attention", label: "Attention", type: "text", name: "Attention", className: "form-control input-sm", maxlength: 50, value: this.state.fields.attn, onChange: this.handleChange }}
                      errorMsg={this.state.errors.attn}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={1}>
                  </Col>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "Department", label: "Department", type: "text", name: "Department", className: "form-control input-sm", maxlength: 50, value: this.state.fields.dept, onChange: this.handleChange }}
                      errorMsg={this.state.errors.dept}
                      fieldRequired={true}
                    />
                  </Col>
                  <Col sm={3}>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormInputs
                      properties={{ id: "VendorNo", label: "Vendor Number", type: "text", name: "VendorNo", className: "form-control input-sm", maxlength: 20, value: this.state.fields.vendor_number, onChange: this.handleChange }}
                      errorMsg={this.state.errors.VendorNo}
                      fieldRequired={false}
                    />
                  </Col>
                  <Col sm={1}>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <FormLabel>Centrally Billed</FormLabel>
                    <Form.Check checked={this.state.fields.centrally_billed} type="checkbox" label="All hotels are now Non-Disputable and Centrally Billed " />
                    </FormGroup>
                  </Col>                  
                  <Col sm={3}>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormGroup>
                      <FormLabel>Comments</FormLabel>
                        <Form.Control as="textarea" rows={3} id="Comments" name="Comments" className="form-control" value={this.state.fields.comments} onChange={this.handleChange}></Form.Control>
                    </FormGroup>
                  </Col>
                  <Col sm={1}>
                  </Col>
                  <Col sm={7}>
                  </Col>
                </Row>

                <Row style={{ marginTop: "25px" }}><Col sm={12}></Col></Row>

                <Row>
                  <Col sm={4}> </Col>
                  <Col sm={1}>
                    <Button disabled={this.state.loading} name='btnClear' style={{ marginRight: "5px" }} onClick={(e) => this.handleOnClear(e, e.currentTarget.name)} title="Click to clear the Contact">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Clear</span>}
                    </Button>
                  </Col>
                  <Col sm={1}>
                    <Button disabled={this.state.loading} name='btnAdd' style={{ marginRight: "5px" }} onClick={(e) => this.handleOnAdd(e, e.currentTarget.name)} title="Click to add the Contact">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Add</span>}
                    </Button>
                  </Col>

                  <Col sm={1}>
                    {(this.state.fields.showBtn) ?
                      <Button disabled={this.state.loading} name='btnSave' style={{ marginRight: "5px" }} onClick={(e) => this.handleOnSave(e, e.currentTarget.name)} title="Click to Save the Contact">
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>Save</span>}
                      </Button>
                      :
                      ''
                    }
                  </Col>
                  <Col sm={1}>
                    {(this.state.fields.showBtn) ?
                      <Button disabled={this.state.loading} name='btnCancel' style={{ marginRight: "5px" }} onClick={(e) => this.handleOnDelete(e, e.currentTarget.name)} title="Click to delete the Contact">
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>Delete</span>}
                      </Button>
                      :
                      ''
                    }
                  </Col>
                  <Col sm={4}></Col>
                </Row>


              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    );
  }
}