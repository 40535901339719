import React, { Component, CSSProperties } from "react";
import { ISystemMaintenanceParameter, Istate } from "../../../Model/ISystemMaintenanceParameter";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { SystemMaintenanceController } from "../../../Controllers/SystemMaintenanceController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';

///added css in style line 279 Very inportant
export class SystemMaintenanceView extends Component<ISystemMaintenanceParameter,Istate> {
    constructor(props:ISystemMaintenanceParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {message:'',isActive:false,isDelete:false},
            errors:{},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:"",
            sytemMessageGroups:[],
            delMessageId:"|",
            collMessageId:"",
            loadComplete:false           
        }
        this.onSubmit = this.onSubmit.bind(this); 
    }

    async componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;      
        await this.getSysMessage();
        modal['modalSpinnerShow']=false;
        this.setState({ modal}); 
    }

    async getSysMessage(){
        let systemMaintenanceController = new SystemMaintenanceController ();
        await systemMaintenanceController.getAllSysMessage().then(jsonGetSysMessage => {
            this.setState({ sytemMessageGroups: JSON.parse(JSON.stringify(jsonGetSysMessage))});             
            //console.log(this.state.sytemMessageGroups) 
            this.setState({loadComplete:true})
        }).catch(error => {
            let modal = this.state.modal;
            modal['modalSpinnerShow']=false;
            this.setState({ modal});        
            console.error("There was an error!", error);
        })
    }

    updateSysMessage(){
        let systemMaintenanceController = new SystemMaintenanceController();
        this.state.collMessageId.split(',').map(str => {
            {this.state.sytemMessageGroups.filter(p => p.messageId == str).map(f => {
                var data = {
                    doBy: "U",
                    messageId:f.messageId,
                    message: f.message,
                    isActive: f.isActive,
                    createdDate: "",
                    createdBy: "",
                    updatedDate: new Date(),
                    updatedBy: this.state.userId           
                }
                //console.log(data)
                systemMaintenanceController.createUpdateDeleteSysMessage(data).then(response => { 
                    this.getSysMessage();
                 }).catch(error => {
                    console.error("There was an error!", error)
                })
            })}            
        });
        this.setState({collMessageId : ""})
    }

    createSysMessage(){
        let systemMaintenanceController = new SystemMaintenanceController();
        let fields = this.state.fields;
        if(this.state.fields.message != "")
        {           
            var dataSys = {
                doBy: "I",
                //messageId:f.messageId,
                message: this.state.fields.message,
                isActive: this.state.fields.isActive,
                createdDate: new Date(),
                createdBy: this.state.userId,
                updatedDate: new Date(),
                updatedBy: this.state.userId   
            }
            //console.log(dataSys)
            systemMaintenanceController.createUpdateDeleteSysMessage(dataSys).then(response => { 
                fields['message'] ="";
                fields['isActive'] = false;
                fields['isDelete'] = false;
                this.setState({fields})
                this.getSysMessage();
            }).catch(error => {
                console.error("There was an error!", error)
            })            
        }
    }

    deleteSysMessage(){
        let systemMaintenanceController = new SystemMaintenanceController();
        if(this.state.delMessageId != "")
        {
            {this.state.delMessageId.split('|').map(str => {
                {this.state.sytemMessageGroups.filter(p => p.messageId == str).map(f => {
                    var data = {
                        doBy: "D",
                        messageId:f.messageId,
                        message: "",
                        isActive: "",
                        createdDate: new Date(),
                        createdBy: "",
                        updatedDate: new Date(),
                        updatedBy: ""           
                    }
                    //console.log(data)
                    systemMaintenanceController.createUpdateDeleteSysMessage(data).then(response => {   
                        this.getSysMessage();                       
                    }).catch(error => {
                        console.error("There was an error!", error)
                    })
                })}
            })}
          this.setState({delMessageId : "|"}) 
        }
    }
    

    onSubmit(e:any){
        e.preventDefault();
        let modal = this.state.modal;        
        modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})
        var isUpdated = false;
        this.updateSysMessage(); 
        this.createSysMessage();
        this.deleteSysMessage();
        //this.getSysMessage();
        modal['modalSpinnerShow']=false;        
        alert('System Message Updated..');        
        this.props.history.push({pathname:'/admin/home'});    
     }

    handleMessageChange(i, event) {
        let collMsg =this.state.collMessageId;
         let sytemMessageGroups = [...this.state.sytemMessageGroups];        
         sytemMessageGroups[i]["message"] = event.target.value;
         this.setState({ sytemMessageGroups });
         if(collMsg != "")
         {
             if(!collMsg.includes(sytemMessageGroups[i]["messageId"]))
             collMsg = collMsg + sytemMessageGroups[i]["messageId"]+","
         }
         else
         collMsg = sytemMessageGroups[i]["messageId"]+","
 
         this.setState({ collMessageId:collMsg });
    }

    handleCheckIsActiveChange(i, event) {
            let collMsg = this.state.collMessageId;
            let sytemMessageGroups = [...this.state.sytemMessageGroups];        
            sytemMessageGroups[i]["isActive"] = !sytemMessageGroups[i]["isActive"]
            this.setState({ sytemMessageGroups });
            if(collMsg != "")
            {
                if(!collMsg.includes(sytemMessageGroups[i]["messageId"]))
                collMsg = collMsg + sytemMessageGroups[i]["messageId"]+","
            }
            else
            collMsg = sytemMessageGroups[i]["messageId"]+","    
            this.setState({ collMessageId:collMsg });
    }

   handleCheckDeleteChange(i, event) {
        let collMsg = this.state.delMessageId;        
        let sytemMessageGroups = [...this.state.sytemMessageGroups];    
        sytemMessageGroups[i]["isDelete"] = !sytemMessageGroups[i]["isDelete"]
        this.setState({sytemMessageGroups});
        if(collMsg != "")
        {
            if (!sytemMessageGroups[i]["isDelete"])
            {
                if(collMsg.includes("|"+ sytemMessageGroups[i]["messageId"])+ "|")
                collMsg = collMsg.replace("|"+ sytemMessageGroups[i]["messageId"]+"|","|");
            }
            else
            {
                if(!collMsg.includes(sytemMessageGroups[i]["messageId"]))
                collMsg = collMsg + sytemMessageGroups[i]["messageId"]+"|"
            }        
        }
        else
         collMsg = sytemMessageGroups[i]["messageId"]+"|"    
        this.setState({ delMessageId:collMsg });
    }

    handleChange(e:any){
        let fields = this.state.fields;        
        if (e.currentTarget.name === 'message'){
            fields['message'] =  e.currentTarget.value;              
        }                
        this.setState({ fields});
    }

    handleCheckboxChange(e:any){
        let fields = this.state.fields;
        
        if (e.currentTarget.name === 'isActive'){
            fields['isActive'] = !fields['isActive'];
        }
        if (e.currentTarget.name === 'isDelete'){
            fields['isDelete'] = !fields['isDelete'];
        }          
        this.setState({ fields});
    }

    render() {
        const{ loading } = this.state;
         const tdStyle = {
            width:'50%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
        const tdStylechk ={
            width:'3%',            
            border: "2px solid #eee",
            padding:"2px" 
        };        
        const thStyle ={
            width:'50%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleChk ={
            width:'25%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">System Message Maintenance</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row>
                                        <Col sm={12} style={{textAlign:"center", marginBottom:"15px"}}>
                                            <Button size="sm" disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Refresh </span>}
                                            </Button>
                                            <Button size="sm" disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Print </span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                       <Col sm={12}>
                                       <div>
                                       <Table hover>
                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                    <tr style={{textAlign:"center"}}>
                                                        <th style={thStyle}>Message</th>
                                                        <th style={thStyleChk}>Active</th>
                                                        <th style={thStyleChk}>Delete</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.sytemMessageGroups.map((prop, key) => {
                                                        return (
                                                            <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                <td style={tdStyle}><Form.Control as="textarea" rows={3} className="form-control" maxLength={250} value={prop["message"]||''} onChange={this.handleMessageChange.bind(this, key)}></Form.Control></td>
                                                                <td style={tdStylechk}><input type="checkbox" defaultChecked={prop["isActive"]} onChange={this.handleCheckIsActiveChange.bind(this, key)}/></td>
                                                                <td style={tdStylechk}><input type="checkbox" defaultChecked={prop["isDelete"]} onChange={this.handleCheckDeleteChange.bind(this, key)}/></td>
                                                            </tr>
                                                            
                                                        )
                                                     })}
                                                     {this.state.loadComplete ? 
                                                     <tr style={{textAlign:"center"}}>
                                                            <td style={tdStyle}><Form.Control as="textarea" rows={3} id="message" maxLength={250} name="message" className="form-control" value={this.state.fields.message} onChange={this.handleChange.bind(this)}></Form.Control></td>
                                                            <td style={tdStylechk}><input type="checkbox" name="isActive" defaultChecked={this.state.fields.isActive} onChange={this.handleCheckboxChange.bind(this)}/></td>
                                                            <td style={tdStylechk}></td>
                                                            {/*<td style={tdStylechk}><input type="checkbox" name="isDelete" defaultChecked={this.state.fields.isDelete} onChange={this.handleCheckboxChange.bind(this)}/></td>*/}
                                                     </tr> : ""}
                                                </tbody>
                                        </Table>                                                        
                                        </div>
                                        <div style={{textAlign:"center"}}>
                                        {this.state.loadComplete ? <p style={{textAlign:"left",color:"red",fontSize:"10px"}}>
                                         *Please enter message to add new system message <br />
                                         *Maximun input character 250</p>:""}
                                        <Button id="submitLabel" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}  variant="success">
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Save</span>}
                                            </Button>
                                        </div>

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}
