import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class DirectBillActivityController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    getDirectBillHotelActivity(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillActivity;
        var data1 = {
            box:data.box,
            dbview:data.dbview,
            beginDate:data.beginDate,
            endDate:data.endDate,
            label_id:data.label_id,
            user_id:data.user_id,
            usertype_id:data.usertype_id,
            searchString:data.searchString,
            approvertier_id:data.approvertier_id,
            searchScope:data.searchScope,
            sOrderBy: encodeURIComponent(data.sOrderBy)
            
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //
                if(response.success){
                    //console.log("getDirectBillHotelActivity", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getDirectBillHotelView(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillHotelView;
        var data1 = {
            box:data.box,
            dbview:data.dbview,
            authreq_id : data.authreq_id,
            booking_id : data.booking_id
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //
                if(response.success){
                    //console.log("getDirectBillHotelViewReq", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getDirectBillActivityView(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillActivityView;
        var data1 = {
            box:data.box,
            dbview:data.dbview,
            authreq_id : data.authreq_id,
            booking_id : data.booking_id
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //
                if(response.success){
                    //console.log("getDirectBillActivityView", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getDirectBillTACoding(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillTACoding;
        var data1 = {
            authreq_id:data.authreq_id,
            bookings:data.bookings,
            traveler_id : data.traveler_id,
            traveler_type : data.traveler_type,
            box : data.box
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //
                if(response.success){
                    //console.log("getDirectBillTACoding", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    
    getDirectBillPrint(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillPrint;
        var data1 = {
            travel_type: data.travel_type,
            travel_id: data.travel_id,
            authreq_id: data.authreq_id,
            direct_bill_id: data.direct_bill_id,
            user_id: data.user_id
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //
                //console.log(response); 
            var printDirectBilldata = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));      
           return printDirectBilldata;
                           
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    directBillUserProcessUpdate (data)
    {
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.directBillUserProcessUpdate;
        var data1 : any = {
            processType:data.processType,
            direct_bill_id: data.direct_bill_id,
            user_id: data.user_id,
            check_number: data.check_number,
            contact_id: data.contact_id,
            searchval: data.searchval,
            travel_type: data.travel_type,
            traveler_id: data.traveler_id,
            alias: data.alias,
            vendor_number: data.vendor_number,
            comments: data.comments,
            invoice_number: data.invoice_number,
            begin_date: data.begin_date,
            end_date: data.end_date,
            invoice_amount: data.invoice_amount,
            canceled: data.canceled
        }
        //console.log(data);
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    directBillSaveDbPrint(data)
    {
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.directBillSaveDbPrint;
        var data2 : any = {
            authreq_id: data.authreq_id,
            booking_id: data.booking_id,
            contact_id: data.contact_id,
            traveler_id: data.traveler_id,
            travel_type: data.travel_type,
            centrally_billed: data.centrally_billed,
            user_id: data.user_id,
            alias: data.alias,
            travel_id: data.travel_id
        }

        //console.log(data);
        return this.serviceProxy.post(url,data2).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
        
    }

    directBillSaveInvoice(data)
    {
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.directBillSaveInvoice;
        var data2 : any = {
            direct_bill_id: data.direct_bill_id,           
            travel_type: data.travel_type,
            traveler_id: data.traveler_id,
            alias: data.alias,
            vendor_number: data.vendor_number,
            comments: data.comments,
            invoice_number: data.invoice_number,
            begin_date: data.begin_date,
            end_date: data.end_date,
            invoice_amount: data.invoice_amount,
            canceled: data.canceled,
            user_id: data.user_id
        }

        //console.log(data);
        return this.serviceProxy.post(url,data2).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
        
    }

    getDirectBillContactId(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillContactId;
        var data1 : any= {
            contact_id:data.contact_id,
            contact_type:data.contact_type,
            searchstring : data.searchstring           
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillContactId", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    getDirectBillContactList(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillContactList;
        var data1 : any= {
            contact_id:data.contact_id,
            contact_type:data.contact_type,
            searchstring : data.searchstring           
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillContactList", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    getDirectBillCountry(){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillCountry;
       
        return this.serviceProxy.get(url,{})
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillCountry", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    getDirectBillSearchContact(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillSearchContact;
        var data1 : any= {            
            searchstring : data.searchstring           
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillContactList", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    getDirectBillState(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillState;
        var data1 : any= {            
            country_id : data.country_id           
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillContactList", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    getDirectBillContactData(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.getDirectBillContactData;
        var data1 : any= {            
            contact_id : data.contact_id           
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillContactList", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    directBillDeleteContact(data)
    {
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.directBillDeleteContact;
        var data2 : any = {
            contact_id: data.contact_id            
        }

        //console.log(data);
        return this.serviceProxy.post(url,data2).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
        
    }

    directBillInsertContact(data)
    {
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.directBillInsertContact;
        var data2 : any = {
            contact_type : data.contact_type,  
            contact_name : data.contact_name,
            address1 : data.address1 ,
            address2 : data.address2 ,
            city : data.city ,
            state_province_id : data.state_province_id ,
            state_province : data.state_province ,
            zip : data.zip,
            country_id : data.country_id,
            phone : data.phone,
            fax : data.fax,
            attn : data.attn ,
            dept : data.dept ,
            vendor_number : data.vendor_number,   
            centrally_billed : data.centrally_billed,  
            comments : data.comments,
            updateby : data.updateby       

        }

        //console.log(data);
        return this.serviceProxy.post(url,data2).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
        
    }

    directBillUpdateContact(data)
    {
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.directBillUpdateContact;
        var data3 : any = {
            contact_id : data.contact_id,           
            contact_type : data.contact_type,  
            contact_name : data.contact_name,
            address1 : data.address1 ,
            address2 : data.address2 ,
            city : data.city ,
            state_province_id : data.state_province_id ,
            state_province : data.state_province ,
            zip : data.zip,
            country_id : data.country_id,
            phone : data.phone,
            fax : data.fax,
            attn : data.attn ,
            dept : data.dept ,
            vendor_number : data.vendor_number,   
            centrally_billed : data.centrally_billed,  
            comments : data.comments,
            updateby : data.updateby 
        }

        //console.log(data);
        return this.serviceProxy.post(url,data3).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
        
    }

    checkDirectBillContactData(data){
        //
        var url = this.variables.Url+ this.variables.Controller.directbill + this.variables.directBillActivityService.checkDirectBillContactData;
        var data1 : any= {            
            contact_name : data.contact_name,
            phone : data.phone          
        }
        return this.serviceProxy.get(url,data1)
        .then(response => {
            //
            if(response.success){
                //console.log("getDirectBillContactList", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }
   
}
