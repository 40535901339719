import React, { Component } from "react";
import { IReportParameter, Istate } from "../../../Model/IReport";
import { Container, Row, Col, Form, Alert, Button, Card, Table } from "react-bootstrap";
// import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "react-bootstrap";
import { format } from 'date-fns';
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { ReportController } from "../../../Controllers/ReportController";
import '../../../assets/css/Style.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Variables } from "../../../Variables/Variables";

export class CTM_reports extends Component<IReportParameter, Istate> {
    constructor(props: IReportParameter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            percentagesBeforeNoon: 0,
            percentagesNoon: 0,
            percentagesOneOClock: 0,
            percentagesTwoOClock: 0,
            percentagesThreeOClock: 0,
            percentagesFourOClock: 0,
            percentagesFiveOClock: 0,
            percentagesSixOClock: 0,
            percentagesSevenOClock: 0,
            sumBeforeNoon: 0,
            sumNoon: 0,
            sumOneOClock: 0,
            sumTwoOClock: 0,
            sumThreeOClock: 0,
            sumFourOClock: 0,
            sumFiveOClock: 0,
            sumSixOClock: 0,
            sumSevenOClock: 0,
            sumofTotal: 0,
            fields: { startDate: '', endDate: '', isLabelAllChked: false, isCallLettersAllChked: false, isStatusAllChked: false, isLabelAll: false, isCallLettersAll: false, isStatusAll: false },
            selectfields: {},
            optionfields: {},
            errors: { startDate: '', endDate: '' },
            errMsg: "",
            loading: false,
            modal: { modalSpinnerShow: false },
            userId: sessionStorage.getItem("uid"),
            labelGroups: [],
            ctmReport: [],
            deptGroups: [],
            statusTypeGroups: [],
            callLetterGroup: []
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.calculateRowSum = this.calculateRowSum.bind(this);
    }

    componentWillMount() {
        this.setDefault();
    }
    calculateRowSum(row: any) { // Added type for row
        // console.log(row, "row check")
        return row.filter(item => typeof item === 'number').reduce((acc, curr) => acc + curr, 0);
    }
    context: any;

    setDefault = () => {
        let today = new Date();
        let startDate = new Date(today.getTime() - (15 * 24 * 60 * 60 * 1000));
        let fields = this.state.fields;
        fields["startDate"] = startDate;
        fields["endDate"] = today;

        this.setState({ fields })
    }




    onSubmit(e: any) {
        this.setState({ ctmReport: [], sumBeforeNoon: 0, sumNoon: 0, sumOneOClock: 0, sumTwoOClock: 0, sumThreeOClock: 0, sumFourOClock: 0, sumFiveOClock: 0, sumSixOClock: 0, sumSevenOClock: 0, sumofTotal: 0, percentagesBeforeNoon: 0, percentagesNoon: 0, percentagesOneOClock: 0, percentagesTwoOClock: 0, percentagesThreeOClock: 0, percentagesFourOClock: 0, percentagesFiveOClock: 0, percentagesSixOClock: 0, percentagesSevenOClock: 0, });
        // console.log(this.state.fields.startDate, this.state.fields.endDate, "eee")
        e.preventDefault();
        let fields = this.state.fields;
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        let reportController = new ReportController();
        let startDate_formated = format(new Date(this.state.fields.startDate), 'MM/dd/yyyy');
        let endDate_formated = format(new Date(this.state.fields.endDate), 'MM/dd/yyyy');
        let Datas = {
            "startDate": startDate_formated,
            "endDate": endDate_formated
        }

        reportController.getCtmReportsHfm(Datas).then(res => {
            this.setState({ ctmReport: res });
            modal['modalSpinnerShow'] = false;
            this.setState({ modal })
            let sumofTotal = 0; // Initialize sumofTotal

            res.forEach(label => {
                this.setState(prevState => ({
                    sumBeforeNoon: prevState.sumBeforeNoon + label.BeforeNoon,
                    sumNoon: prevState.sumNoon + label.Noon,
                    sumOneOClock: prevState.sumOneOClock + label.OneOClock,
                    sumTwoOClock: prevState.sumTwoOClock + label.TwoOClock,
                    sumThreeOClock: prevState.sumThreeOClock + label.ThreeOClock,
                    sumFourOClock: prevState.sumFourOClock + label.FourOClock,
                    sumFiveOClock: prevState.sumFiveOClock + label.FiveOClock,
                    sumSixOClock: prevState.sumSixOClock + label.SixOClock,
                    sumSevenOClock: prevState.sumSevenOClock + label.SevenOClock
                }));

                // Calculate sumofTotal by summing all the time slots
                sumofTotal += this.calculateRowSum(Object.values(label));
            });

            // Set sumofTotal in state
            this.setState({ sumofTotal });

            // console.log(this.state, "statecheck")

            //Percentage Of Overall Total

            this.setState(prevState => ({
                percentagesBeforeNoon: this.calculatePercentage(this.state.sumBeforeNoon, sumofTotal),
                percentagesNoon: this.calculatePercentage(this.state.sumNoon, sumofTotal),
                percentagesOneOClock: this.calculatePercentage(this.state.sumOneOClock, sumofTotal),
                percentagesTwoOClock: this.calculatePercentage(this.state.sumTwoOClock, sumofTotal),
                percentagesThreeOClock: this.calculatePercentage(this.state.sumThreeOClock, sumofTotal),
                percentagesFourOClock: this.calculatePercentage(this.state.sumFourOClock, sumofTotal),
                percentagesFiveOClock: this.calculatePercentage(this.state.sumFiveOClock, sumofTotal),
                percentagesSixOClock: this.calculatePercentage(this.state.sumSixOClock, sumofTotal),
                percentagesSevenOClock: this.calculatePercentage(this.state.sumSevenOClock, sumofTotal),
            }));
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow'] = false;
            this.setState({ modal })
        })

    }

    calculatePercentage(value, sumofTotal) {
        return sumofTotal !== 0 ? Math.round((value / sumofTotal) * 100) : 0;

    }

    ValidateValues() {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let modal = this.state.modal;
        let labelCount = 0;
        let callLetterCount = 0;
        let statusCount = 0;
        let formIsValid = true;
        if (fields["startDate"] === "" || fields["startDate"] === null) {
            formIsValid = false;
            errors["startDate"] = "Enter From Date";
            modal['modalSpinnerShow'] = false;
        }
        if (fields["endDate"] === "" || fields["endDate"] === null) {
            formIsValid = false;
            errors["endDate"] = "Enter End Date";
            modal['modalSpinnerShow'] = false;
        }




        this.setState({ errors, modal, fields });
        return formIsValid;
    }

    handleChangeStartDate = date => {
        let fields = this.state.fields;
        fields["startDate"] = date
        this.setState({ fields })
    }

    handleChangeEndDate = date => {

        let fields = this.state.fields;
        fields["endDate"] = date
        this.setState({ fields })
    }










    render() {
        const { loading } = this.state;

        const tdStyle = {
            width: '20%',
            border: "2px solid #eee",
            padding: "2px"
        };

        const thStyle = {
            width: '20%',
            border: "2px solid #eee",
            padding: "2px"
        };


        return (
            <div >
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%' }}>




                                <Row>
                                    <Col sm={8} style={{ textAlign: "center", marginBottom: "15px" }}>
                                        <div className="header"><h4 className="title">  Number of TAs Arriving at CTM by Timeframe by HFM Entity</h4></div>

                                    </Col>
                                    <Col sm={4} style={{ textAlign: "end", marginTop: "15px" }}>
                                    </Col>
                                </Row>

                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                    <Row>
                                        <Col sm={2}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>From Date</Form.Label>
                                                    <DatePicker style={{ fontSize: "12px" }}
                                                        Id="startDate"
                                                        selected={this.state.fields.startDate}
                                                        onChange={this.handleChangeStartDate}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        maxDate={new Date()}
                                                        className="dateStyle" />
                                                    {this.state.errors.startDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.startDate}</div> : <></>}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>End Date</Form.Label>
                                                    <DatePicker style={{ fontSize: "12px" }}
                                                        Id="endDate"
                                                        selected={this.state.fields.endDate}
                                                        onChange={this.handleChangeEndDate}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        maxDate={new Date()}
                                                        className="dateStyle" />
                                                    {this.state.errors.endDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.endDate}</div> : <></>}
                                                </div>
                                            </Form.Group>
                                        </Col>

                                    </Row>




                                    <Row>
                                        <Col md={4} style={{}}><label></label></Col>
                                        <Col sm={4} style={{ textAlign: "center", alignSelf: "center" }} >
                                            <Button disabled={this.state.loading} name='searchHistory' style={{ marginRight: "5px" }} onClick={(e) => this.onSubmit(e)} title="Click to start search">
                                                {this.state.loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px", color: "#487afa" }}
                                                    />
                                                )}
                                                {this.state.loading && <span style={{ color: "#487afa" }}>wait...</span>}
                                                {!this.state.loading && <span>Search</span>}
                                            </Button>
                                            <Button disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px" }} onClick={() => window.location.reload()} title="Click to Refresh the Screen">
                                                {this.state.loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px", color: "#3472F7" }}
                                                    />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Clear </span>}
                                            </Button>
                                            <Button disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px" }} onClick={() => window.print()} title="Click to print page">
                                                {this.state.loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px", color: "#3472F7" }}
                                                    />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Print </span>}
                                            </Button>
                                        </Col>
                                    </Row>




                                    <Row>
                                        <Col sm={12} style={{ marginTop: "5%" }}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Table hover striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th >HFM Entity <i ></i></th>
                                                            <th >&lt; Noon <i ></i></th>
                                                            <th >12:00 P.M <i ></i></th>
                                                            <th >1:00 P.M <i ></i></th>
                                                            <th >2:00 P.M <i ></i></th>
                                                            <th >3:00 P.M <i ></i></th>
                                                            <th >4:00 P.M <i ></i></th>
                                                            <th >5:00 P.M <i ></i></th>
                                                            <th >6:00 P.M <i ></i></th>
                                                            <th >7:00 P.M & &gt; <i ></i></th>
                                                            <th >Total <i ></i></th>

                                                        </tr>
                                                    </thead>




                                                    <tbody>
                                                        {this.state.ctmReport.map((row, index) => (

                                                            <tr key={index}>
                                                                <td>{row.Label}</td>
                                                                <td>{row.BeforeNoon}</td>
                                                                <td>{row.Noon}</td>
                                                                <td>{row.OneOClock}</td>
                                                                <td>{row.TwoOClock}</td>
                                                                <td>{row.ThreeOClock}</td>
                                                                <td>{row.FourOClock}</td>
                                                                <td>{row.FiveOClock}</td>
                                                                <td>{row.SixOClock}</td>
                                                                <td>{row.SevenOClock}</td>
                                                                <td>{this.calculateRowSum(Object.values(row))}</td>
                                                            </tr>
                                                        ))}


                                                        {this.state.ctmReport.length > 0 && (
                                                            <>
                                                                <tr >
                                                                    <td className="bold-row">Total For Time Range</td>
                                                                    <td className="bold-row">{this.state.sumBeforeNoon}</td>
                                                                    <td className="bold-row">{this.state.sumNoon}</td>
                                                                    <td className="bold-row">{this.state.sumOneOClock}</td>
                                                                    <td className="bold-row">{this.state.sumTwoOClock}</td>
                                                                    <td className="bold-row">{this.state.sumThreeOClock}</td>
                                                                    <td className="bold-row">{this.state.sumFourOClock}</td>
                                                                    <td className="bold-row">{this.state.sumFiveOClock}</td>
                                                                    <td className="bold-row">{this.state.sumSixOClock}</td>
                                                                    <td className="bold-row">{this.state.sumSevenOClock}</td>
                                                                    <td className="bold-row">{this.state.sumofTotal}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="bold-row">Percentage Of Overall Total</td>
                                                                    <td className="bold-row">{this.state.percentagesBeforeNoon}%</td>
                                                                    <td className="bold-row">{this.state.percentagesNoon}%</td>
                                                                    <td className="bold-row">{this.state.percentagesOneOClock}%</td>
                                                                    <td className="bold-row">{this.state.percentagesTwoOClock}%</td>
                                                                    <td className="bold-row">{this.state.percentagesThreeOClock}%</td>
                                                                    <td className="bold-row">{this.state.percentagesFourOClock}%</td>
                                                                    <td className="bold-row">{this.state.percentagesFiveOClock}%</td>
                                                                    <td className="bold-row">{this.state.percentagesSixOClock}%</td>
                                                                    <td className="bold-row">{this.state.percentagesSevenOClock}%</td>
                                                                    <td></td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </tbody>

                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>)
    }
}