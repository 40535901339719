import React, { Component } from "react";
import { ICarVoucherParameter, Istate } from "../../../Model/ICarVoucher";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "../../../../node_modules/react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import DatePicker from 'react-datepicker';
import validator from 'validator';
import dateFormat from 'dateformat'
import { CarVoucherController } from "../../../Controllers/CarVoucherController";
import '../../../assets/css/Style.css'
import { SubmitButton } from "../Common/SubmitButton";
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { EmailController } from "../../../Controllers/EmailController";
import addSubtractDate from 'add-subtract-date';
import { CommonController } from "../../../Controllers/CommonController";

export class CarVoucher extends Component<ICarVoucherParameter,Istate> {
    constructor(props:ICarVoucherParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {passengerName: '' ,company:'', phoneNumber: '',empNumber:'',businessUnit:'',vipNumber:'',
                     pickUpPoint:'',pickUpTime: '', AM_PM: 'AM',destination:'',comment:'',email:'',emailCC:'',voucherId:''},
            selectfields: {carServiceCompanyId:'', carServiceCompanyName:'',labelId:'',labelName:'',pickUpTime:'1:00'},
            errors:{passengerName: '',company:'',phoneNumber: '',pickUpDate:'',empNumber:'',businessUnit:'',vipNumber:'',
            pickUpPoint:'',pickUpTime: '', AM_PM: 'AM',destination:'',comment:''},
            errMsg:"",
            loading:false,
            CarVoucher:[],
            modal: {modalSpinnerShow: false},
            carCompany:[],
            pickUpDate:new Date(),
            userId:"",
            HFMEntity:[],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChangePickUpDate = this.handleChangePickUpDate.bind(this);
        this.handleSelectTimeFormat = this.handleSelectTimeFormat.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleHFMSelectChange = this.handleHFMSelectChange.bind(this); 
    }

    componentWillMount(){
        let modal = this.state.modal;
        let fields = this.state.fields;
        this.setState({userId:sessionStorage.getItem("uid")})                 
        modal['modalSpinnerShow']=true;
        this.setState({ modal,fields });
        
        let carVoucherController = new CarVoucherController();
        carVoucherController.getCarCompanyName().then(jsonCarCompanyName => {
            this.setState({ carCompany: JSON.parse(JSON.stringify(jsonCarCompanyName))});            
        }).catch(error => {
            console.error("There was an error!", error);
        })
        carVoucherController.getHFMEntity().then(jsonHFMEntity => {
            this.setState({ HFMEntity: JSON.parse(JSON.stringify(jsonHFMEntity))});
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        })

        /* UnLocking TA for the current users */
        
        let comController = new CommonController();
        var data = {
            authreqid: sessionStorage.getItem("ta"),
            userid: sessionStorage.getItem("uid"),
            shadowid: sessionStorage.getItem("shadow"),
            check: ""
        }
        comController.updateUnLockAuthreq(data).then(response => {
        }).catch(error => {
            console.error("There was an error!", error)
        })

    }

    handleChangePickUpDate = date => {
        this.setState({
            pickUpDate: date
            }); 
    }

    handleSelectChange(e: any) {
        let selectfields = this.state.selectfields; 
        let fields = this.state.fields;       
        let errors = this.state.errors
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        
        if(selectfields["carServiceCompanyName"] === "Select Car Company")
        {
            errors["carServiceCompanyId"] = "You must select car company."; 
            fields["email"] = "";
            fields["emailCC"] =""
        }
        else
        errors["carServiceCompanyId"] = "";
        
        this.setState({ selectfields,errors });
        var carCompanyDetails = {     
            companyNameId : e.currentTarget.value                  
        }

        let carVoucherController = new CarVoucherController();
        carVoucherController.getCarCompanyDetails(carCompanyDetails).then(jsonCarCompanydetail => {
            fields["email"] = jsonCarCompanydetail[0].email || "";
            fields["emailCC"] = jsonCarCompanydetail[0].emailCC || "";            
            //alert(this.state.fields.email)
        }).catch(error => {
            console.error("There was an error!", error);
        })
        this.setState({fields});
    }
    handleHFMSelectChange(e: any) {
        let selectfields = this.state.selectfields;  
        let errors = this.state.errors; 
        selectfields[e.currentTarget.name] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        selectfields[e.currentTarget.id] = e.currentTarget.value;
        if(selectfields["labelId"] === "Please select a HFM Entity...")
        errors["labelId"]="You must select HFM Entity." 
        else
        errors["labelId"]="" 
         
        this.setState({ selectfields,errors });
    }

    handleChange(e:any){
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        if (e.currentTarget.name === 'name'){
            selectfields["carCompanyDesc"] = e.currentTarget.value;
            errors["carServiceCompanyId"]=""
        }
        if (e.currentTarget.name === 'scAmount'){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            if (e.currentTarget.value.trim().length > 0){
                fields[e.currentTarget.name] = e.currentTarget.value;
                errors[e.currentTarget.name]=""
            }
            else{
                fields[e.currentTarget.name] = e.currentTarget.value.trim();
                errors[e.currentTarget.name]=""
            }
        }
        
        this.setState({ fields, selectfields,errors });
    }

    handleSelectTimeFormat(e){
        let selectfields = this.state.selectfields;       
        if (e.currentTarget.name === 'pickUpTime'){
            selectfields["pickUpTime"] = e.currentTarget.value;           
        }
        this.setState({ selectfields});
    }    

    handleOptionChange(e:any) {      
        const fields = this.state.fields;
        let currentTargetId = e.currentTarget.id;
        if (currentTargetId === "bAM") {
            fields['AM_PM'] = 'AM';
        }
        else if (currentTargetId === "bPM"){
            fields['AM_PM'] = 'PM';
        }
        else if (currentTargetId === "bHour"){
            fields['isRateHourly'] = true;
        }
        else if (currentTargetId === "bFlat"){
            fields['isRateHourly'] = false;
        }
        else if (currentTargetId === "bYes") {
            fields['isPhoneIncluded'] = true;
        }
        else if (currentTargetId === "bNo"){
            fields['isPhoneIncluded'] = false;
        }
        this.setState({ fields });
        //console.log("handleOptionChange:", this.state.values)
    }
    
    ValidateVoucher(){        
        let fields = this.state.fields;
        let modal = this.state.modal;
        let selectfields = this.state.selectfields;
        let errors = this.state.errors;
        let formIsValid = true;
        if (!fields["passengerName"]) {
            formIsValid = false;
            errors["passengerName"] = "The Passenger Name field is empty.";
            modal['modalSpinnerShow']=false;
          }        
          if (!fields["phoneNumber"]) {
            formIsValid = false;
            errors["phoneNumber"] = "The phone number field is empty.";
            modal['modalSpinnerShow']=false;
          }
          if (!fields["empNumber"]) {
            formIsValid = false;
            errors["empNumber"] = "The emp Number field is empty.";
            modal['modalSpinnerShow']=false;
          }
          if (!fields["businessUnit"]) {
            formIsValid = false;
            errors["businessUnit"] = "The cost/business unit field is empty.";
            modal['modalSpinnerShow']=false;
          }          
          if (this.state.pickUpDate === "" || this.state.pickUpDate === null) {
            formIsValid = false;
            errors["pickUpDate"] = "Enter Date";   
            modal['modalSpinnerShow']=false; 
          }         
          if (!fields["pickUpPoint"]) {
            formIsValid = false;
            errors["pickUpPoint"] = "The pick up point field is empty.";   
            modal['modalSpinnerShow']=false; 
          } 
          /*if (!fields["pickUpTime"]) {
            formIsValid = false;
            errors["pickUpTime"] = "Enter Time."; 
            modal['modalSpinnerShow']=false;   
          }*/
          if (!fields["destination"]) {
            formIsValid = false;
            errors["destination"] = "The destination field is empty.";
            modal['modalSpinnerShow']=false;
          }         
          if((selectfields["labelId"] === "Please select a HFM Entity...")||(selectfields["labelId"] === ""))
          {
            formIsValid = false;
            errors["labelId"] = "You must select HFM Entity.";
            modal['modalSpinnerShow']=false;
          }
          if((selectfields["carServiceCompanyName"] === "Select Car Company")||(selectfields["carServiceCompanyName"] === ""))
          {
            formIsValid = false;
            errors["carServiceCompanyId"] = "You must select car company.";
            modal['modalSpinnerShow']=false;
          }          
          this.setState({errors,modal});
          return formIsValid;
    }

    generateEmail(){
        var subject = "Car Reservation Request from Universal Music Group"; 
        var to = this.state.fields.email;
        var cC = this.state.fields.emailCC;
        var sD = new Date (this.state.pickUpDate);
        var curr_date = sD.getDate();
        var curr_month = sD.getMonth() + 1; //Months are zero based
        var curr_year = sD.getFullYear();
        var startDate = curr_month + "/" + curr_date + "/" + curr_year;
        var body = sessionStorage.getItem("name") + " from " + this.state.selectfields.labelName+" (Universal Music Group) is requesting a car.<br><br>Voucher ID = "
                   + this.state.fields.voucherId + "<br> Passenger Name = "+ this.state.fields.passengerName + "<br> Callback Number = "+
                    this.state.fields.phoneNumber+"<br> Employee Number = "+this.state.fields.empNumber+"<br> Cost Center = "+this.state.fields.businessUnit+
                        /*"<br> VIP Number = "+  this.state.fields.vipNumber+*/
                    "<br> Pick Up Point = "+this.state.fields.pickUpPoint+"<br> Pick Up Time = "+
                    startDate +' '+ this.state.selectfields.pickUpTime +' '+ this.state.fields.AM_PM +"<br> Drop Off Point ="+
                    this.state.fields.destination+"<br><br> (Valid for a Sedan only)"

        let emailController = new EmailController();    
        emailController.sendEmail('',to,subject,body);
    }

    onSubmit(e){
        
        e.preventDefault();
        let modal = this.state.modal;
        let fields = this.state.fields;
        modal['modalSpinnerShow']=true;
        if (this.ValidateVoucher()) {
        let carVoucherController = new CarVoucherController();
        var sD = new Date (this.state.pickUpDate);
        var curr_date = sD.getDate();
        var curr_month = sD.getMonth() + 1; //Months are zero based
        var curr_year = sD.getFullYear();
        var startDate = curr_month + "/" + curr_date + "/" + curr_year;

        var data = {
            userId: sessionStorage.getItem('uid'),
            passengerName: this.state.fields.passengerName,
            company: this.state.selectfields.labelName,
            phone: this.state.fields.phoneNumber,
            employeeNumber: this.state.fields.empNumber,
            costCenter: this.state.fields.businessUnit,
            vipNumber: this.state.fields.vipNumber,
            pickUpPoint:this.state.fields.pickUpPoint,
            pickUpTime: startDate +' '+ this.state.selectfields.pickUpTime +' '+ this.state.fields.AM_PM,
            dropOffPoint: this.state.fields.destination,
            companyNameId: this.state.selectfields.carServiceCompanyName,
            comments: this.state.fields.comment   
        }
        carVoucherController.createVoucher(data).then(response => { 
            //alert('Car Voucher Created.. '+response.voucherId,{title: "eTA - Car Voucher"})
            modal['modalSpinnerShow']=false;
            fields["voucherId"] = response.voucherId
            this.setState({fields,modal})
             this.generateEmail(); 
             window.open('/carvoucherprint?voucherId='+ response.voucherId, '_blank', 'location=yes,height=570,width=620,scrollbars=yes,status=yes');
             this.props.history.push({pathname:'/admin/carvoucherconfirmation',state:{voucherId:response.voucherId}})                          
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }
        
}

    render() {
        const{ loading } = this.state;        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">{this.state.fields.authType} Black Car Voucher – For Sedan Service Only. <br />
                            (Reservations must first be made directly with the car service, and voucher presented to the driver when exiting the car)
                            </h4><p className="category"></p></div>
                            <Card.Body>
                            <Form>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                    <Col sm={11}></Col>
                                    <Col sm={1}>
                                        <Button id="submitVoucher" type="button" size="sm" disabled={loading} onClick={() => window.print()} >
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Print</span>}
                                            </Button>
                                    </Col> 
                                </Row>
                                <Row style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Passenger Name:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"passengerName",label:"",type:"text",name:"passengerName",value:this.state.fields.passengerName,className:"form-control input-sm",maxlength:30,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.passengerName}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}>
                                         <p style={{textAlign:"left",color:"#007bff",paddingTop:"5%",fontSize:"10px"}}>&nbsp;Actual Traveler</p>
                                    </Col>                                    
                                </Row>
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>HFM Entity Name:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormSelect
                                            properties={{ label: "", id: "labelId", name: "labelName", value: this.state.selectfields.labelId, className: "form-control form-control-sm", onChange: this.handleHFMSelectChange }}
                                            fieldRequired={false}
                                            errorMsg={this.state.errors.labelId}
                                            defaultOption="Please select a HFM Entity..."
                                            fillOption={this.state.HFMEntity}
                                        />
                                    </Col>
                                    <Col sm={7}>                                         
                                    </Col>                                   
                                </Row>
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Phone/Callback No:<text style={{color:"Red"}}>*</text></Form.Label>
                                    </Col>
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"phoneNumber",label:"",type:"phoneNumber",name:"phoneNumber",value:this.state.fields.phoneNumber,className:"form-control input-sm",maxlength:15,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.phoneNumber}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}></Col>                                    
                                </Row>
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Employee Number:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"empNumber",label:"",type:"text",name:"empNumber",value:this.state.fields.empNumber,className:"form-control input-sm",maxlength:7,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.empNumber}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}>
                                         <p style={{textAlign:"left",color:"#007bff",paddingTop:"3%",fontSize:"10px"}}>
                                         Please enter your SAP Vendor number. Do not use your 7-digit PeopleSoft number.<br/> 
                                         If you do not know your SAP Vendor number, <br/>
                                         please contact your Finance department 
                                         or the UMG Vendor Desk (umg.apvendor.usa@umusic.com) for assistance. </p>
                                    </Col>                                  
                                </Row>
                                <Row style={{marginTop:"-10px"}}>                                    
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Cost Center/Business Unit:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"businessUnit",label:"",type:"text",name:"businessUnit",value:this.state.fields.businessUnit,className:"form-control input-sm",maxlength:8,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.businessUnit}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}>
                                         <p style={{textAlign:"left",color:"#007bff",paddingTop:"5%",fontSize:"10px"}}>For Ex US1AXXXX</p>
                                    </Col>                                    
                                </Row>
                                {/*<Row  style={{marginTop:"-10px"}}>
                                     <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>VIP Number:</Form.Label> 
                                    </Col>                                   
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"vipNumber",label:"",type:"text",name:"vipNumber",value:this.state.fields.vipNumber,className:"form-control input-sm",maxlength:20,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.vipNumber}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}></Col>                                
                                 </Row>*/}
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Pick Up Point:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"pickUpPoint",label:"",type:"text",name:"pickUpPoint",value:this.state.fields.pickUpPoint,className:"form-control input-sm",maxlength:40,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.pickUpPoint}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}>
                                         <p style={{textAlign:"left",color:"#007bff",paddingTop:"5%",fontSize:"10px"}}>
                                         (For airport pickup include airline, flight number and where to meet traveler - 
                                         baggage claim, gate, etc.)</p>
                                    </Col>                                     
                                </Row>
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={1} style={{paddingTop:"2%"}}>
                                        <Form.Label>Pick Up Date:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>          
                                    <Col sm={2} style={{paddingTop:"2%"}}>                                    
                                    <Form.Group>             
                                        <DatePicker style={{fontSize:"12px"}}                                                                              
                                        Id="pickUpDate"
                                        selected={this.state.pickUpDate}
                                        onChange={this.handleChangePickUpDate}
                                        minDate={new Date()}                                        
                                        showMonthDropdown
                                        showYearDropdown    
                                         
                                        className="datePicker form-control form-control-sm"/>
                                        {this.state.errors.pickUpDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.pickUpDate}</div> : <></> } 
                                    </Form.Group>
                                    </Col>
                                    <Col sm={1} style={{paddingTop:"2%"}}>
                                        <Form.Label>Pick Up Time</Form.Label> 
                                    </Col>
                                    <Col sm={2} style={{paddingTop:"2%"}}> 
                                    <Form.Group>
                                    <Form.Control as="select" className="form-control form-control-sm" id="pickUpTime" name="pickUpTime" defaultValue="1:00" value={this.state.selectfields.pickUpTime} onChange={this.handleSelectTimeFormat}>
                                        <option value="1:00">1:00</option>  
                                        <option value="2:00">2:00</option>
                                        <option value="3:00">3:00</option>
                                        <option value="4:00">4:00</option>
                                        <option value="5:00">5:00</option>
                                        <option value="6:00">6:00</option>
                                        <option value="7:00">7:00</option>
                                        <option value="8:00">8:00</option>
                                        <option value="9:00">9:00</option>
                                        <option value="10:00">10:00</option>
                                        <option value="11:00">11:00</option>      
                                        <option value="12:00">12:00</option>                               
                                    </Form.Control>                                        
                                    </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Label></Form.Label> 
                                        <Form.Group>
                                            <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                                                <Col sm={4}><Form.Check type="radio" label="AM" name="bAM"  id="bAM" onChange={(e)=>this.handleOptionChange(e)} title="AM" checked={this.state.fields.AM_PM === "AM" ? true : false} value="AM"/></Col>
                                                <Col sm={4}><Form.Check type="radio" label="PM" name="bPM"  id="bPM" onChange={(e)=>this.handleOptionChange(e)} title="PM" checked={this.state.fields.AM_PM === "PM" ? true : false} value="PM"/></Col>
                                                <Col sm={4}></Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                         <p style={{textAlign:"left",color:"#007bff",paddingTop:"10%",fontSize:"10px"}}>
                                         Please enter actual date and time.</p>
                                    </Col>                                    
                                </Row>
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Final Destination:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormInputs
                                        properties={{id:"destination",label:"",type:"text",name:"destination",value:this.state.fields.destination,className:"form-control input-sm",maxlength:99,onChange:this.handleChange}}
                                        errorMsg={this.state.errors.destination}
                                        fieldRequired={false}
                                        />
                                    </Col>
                                    <Col sm={7}></Col>                                    
                                </Row>                                
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Comments:</Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <Form.Group>
                                        <Form.Control as="textarea" rows={3} id="comment" name="comment" className="form-control" value={this.state.fields.comment} onChange={this.handleChange}></Form.Control>
                                    </Form.Group>
                                    </Col>
                                    <Col sm={7}>
                                         <p style={{textAlign:"left",color:"#007bff",paddingTop:"5%",fontSize:"10px"}}>
                                         For UMG internal use only. Car company does not see comments.</p>
                                    </Col>                                    
                                </Row>
                                <Row  style={{marginTop:"-10px"}}>
                                    <Col sm={2} style={{paddingTop:"2%"}}>
                                        <Form.Label>Car Company:<text style={{color:"Red"}}>*</text></Form.Label> 
                                    </Col>
                                    <Col sm={3}>
                                    <FormSelect
                                            properties={{ label: "", id: "carServiceCompanyId", name: "carServiceCompanyName", value: this.state.selectfields.carServiceCompanyName, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                            fieldRequired={false}
                                            errorMsg={this.state.errors.carServiceCompanyId}
                                            defaultOption="Select Car Company"
                                            fillOption={this.state.carCompany}
                                        />
                                    </Col>
                                    <Col sm={7}></Col>                                    
                                </Row>
                                <Row>
                                <Col style={{fontSize:"100% !important"}} sm={4}>
                                <NavLink href={'/admin/carvoucherhistory'}  className="car-voucher-history-lin">Car Voucher History</NavLink>                                
                                
                                </Col>                                
                                <Col sm={4}><tr style={{height:"30px"}}>
                                    <td style={{textAlign:"center"}} colSpan={2}>
                                        <Button id="submitVoucher" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)} variant="success">
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                            {!loading && <span>Submit</span>}
                                        </Button>
                                    </td>
                                </tr></Col> 
                                <Col sm={4}></Col>
                                </Row>                              
                            </Form>                         
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}