import React, { Component } from "react";
import { NavItem, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt,faGlobe,faHome,faUser,faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
//import {LogoutController} from "../../../Controllers/LogoutController";

export class AdminNavbarLinks extends Component<IAdminNavBar> {

  myHandler(e) { 
    e.persist(); 
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation(); 
  }

  logoutHandler (e : any) {
    //let logoutController = new LogoutController();
    //logoutController.updateLoginFlag(sessionStorage.getItem("uid")?.toString().trim()!)
  }

  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    return (
      <div style={{width:"100%"}}>
        
{/*<Nav>
  <NavItem  >
    <i className="fa fa-dashboard" />
    <p className="hidden-lg hidden-md">Dashboard</p>
  </NavItem>
  <NavDropdown
    eventKey={2}
    title={notification}
    noCaret
    id="basic-nav-dropdown"
  >
    <NavDropdown.Item >Notification 1</NavDropdown.Item>
    <NavDropdown.Item >Notification 2</NavDropdown.Item>
    <NavDropdown.Item >Notification 3</NavDropdown.Item>
    <NavDropdown.Item >Notification 4</NavDropdown.Item>
    <NavDropdown.Item >Another notifications</NavDropdown.Item>
  </NavDropdown>
  <NavItem  >
    <i className="fa fa-search" />
    <p className="hidden-lg hidden-md">Search</p>
 </NavItem>
 <Nav className="mr-auto">
      <Nav.Link href="#home"><FontAwesomeIcon icon={faHome} size="2x" style={{paddingRight:"5px"}}/><p>Home</p></Nav.Link>   
    </Nav>
</Nav>*/}
<span style={{paddingLeft:"15px",color:"#1DC7EA"}}><FontAwesomeIcon icon={faUser} size="2x" style={{paddingRight:"5px"}}/>{sessionStorage.getItem("user_full_name")}</span>
<Nav>
      <Nav.Link eventKey={2} href="/" onClick={this.logoutHandler}><FontAwesomeIcon icon={faSignOutAlt} size="2x" style={{paddingRight:"5px"}}/>
      Log out
      </Nav.Link>
    </Nav>

</div>
    );
  }
}

interface IAdminNavBar{
  brandText?:string;
}
