import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class CodingSummaryController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;

    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getCodingSummary(data: any){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.getCodingSummary;
        var data1 = {
            sBooking: data.sBooking,
            taExpenseTypeId: data.taExpenseTypeId,
            authreqId: data.authreqId,
            taCarService2: data.taCarService2
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //debugger;
                if(response.success){
                    //console.log("getCodingSummary", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getCodingSummaryForUnique(data: any){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.getCodingSummaryForUnique;
        var data1 = {
            sBooking: data.sBooking,
            taExpenseTypeId: data.taExpenseTypeId,
            authreqId: data.authreqId,
            taCarService2: data.taCarService2
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //debugger;
                if(response.success){
                    //console.log("getCodingSummary", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getTravellerDetails(authreqId: string){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.getTravellerDetails;
        var data = {
            authreqId: authreqId
        }
        return this.serviceProxy.get(url,data)
            .then(response => {
                //debugger;
                if(response.success){
                    //console.log("getTravellerDetails", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    getTACoding(data: any){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.getTACoding;
        var data1 = {
            authreqId: data.authreqId,
            sBooking: data.sBooking,
            taCarService2: data.taCarService2,
            travelId: data.travelId,
            travelType: data.travelType
        }
        return this.serviceProxy.get(url,data1).then(response => {
            if(response.success){
                //console.log("getTACoding", response);
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }
    
    getCodingsCount(data: any){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.getCodingsCount;
        var data1 = {
            bookingId: data.bookingId,
            travelType: data.travelType,
            poNumber: data.poNumber,
            poLine: data.poLine,
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                //debugger;
                if(response.success){
                    //console.log("getTACoding", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    insertCoding (data){
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.insertCoding;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    updateTRCoding (data){
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.updateTRCoding;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    deleteCoding (codingId){
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.deleteCoding;
        var data = {
            codingId: codingId,
        }
        return this.serviceProxy.delete(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    insertUpdateCoding (data){
        var url = this.variables.Url+ this.variables.Controller.codingSummary + this.variables.codingSummaryService.insertUpdateCoding;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}