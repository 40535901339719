import React, { Component } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import { NavLink } from 'react-router-dom';
import {FormInputs} from '../../CustomComponents/FormInput';
import {FormSelect} from '../../CustomComponents/FormSelect';
import { IDirectBillActivityParameter, Istate } from "../../../Model/IDirectBillActivity";
import {CommonController} from "../../../Controllers/CommonController";
import {DirectBillActivityController} from '../../../Controllers/DirectBillActivityController';
import {MenuController} from "../../../Controllers/MenuController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import { createLogicalOr } from "typescript";

export class DirectBillActivity extends Component<IDirectBillActivityParameter,Istate> {
  constructor(props:IDirectBillActivityParameter){
    super(props)
    this.state={
      fields: {label_id:sessionStorage.getItem("label_id"),authreq_id:'',submitter:'',expense_type_id:'',weight:'',tier_code:'',startDate:'',find:'*',searchScope:'',deptApp:[],billingApp:[],authApp:[],srexecApp:[],agentApp:[],searchValue:'',sortClicked:false,sortBy:'',orderBy:'',appCnt:0},
      selectfields: {tracking_grid:'',label_id:'',searchString:'', formLabel:'', bookings:''},
      errors: { username:'', password:'' },
      errMsg:"",
      loading: false,
      modal: {modalShow: false, modalTitle: '', modalBody: false, modalAlertShow:false,modalAlertTitle:'',modalAlertErrorMsg:'',modalSpinnerShow:false},
      startDate: new Date(),   
      endDate: new Date(),
      FormLabel:[],
      HotelSearch:[],
      Approver:[],
      currentSort: 'down',
      currentColumn: 'authreq_Id'
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
  }
  componentWillMount(){
    /* UnLocking TA for the current users */
    
    let comController = new CommonController();
    var data = {
      authreqid: sessionStorage.getItem("ta"),
      userid: sessionStorage.getItem("uid"),
      shadowid: sessionStorage.getItem("shadow"),
      check: ""
    }
    comController.updateUnLockAuthreq(data).then(response => {
    }).catch(error => {
      console.error("There was an error!", error)
    })
    
    let modal = this.state.modal;
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    //Declaration begins
    fields["searchValue"] = "ar.authreq_id";
    fields["find"] = "*";
    selectfields["searchString"] = "AuthReq #";
    selectfields['tracking_grid'] = 'In-Progress';
    selectfields['searchDays'] = "3";
    selectfields['bookings'] = "Hotel";
    this.changeStartDate();
    //modal['modalSpinnerShow']=true;
    this.setState({ modal, selectfields, fields });
    let commonController = new CommonController();
      commonController.getLabels().then(jsonLabels => {
        this.setState({                           
          FormLabel : JSON.parse(JSON.stringify(jsonLabels))
        });
        let selectfields = this.state.selectfields;
        selectfields['formLabel'] = sessionStorage.getItem("label_id");
        this.setState({ modal,selectfields });
      }).catch(error => {
          console.error("There was an error loading Labels..., " + error)
      });
      this.sortByCol();
     // this.loadTrakingGrid();

     let DH   
     let prevpage1,prevpage2
     prevpage1 = sessionStorage.getItem("prevpage") 
     prevpage2 = sessionStorage.getItem("prevpageC") 
     
     if (prevpage1 === 'incoming' || prevpage2 === 'Car' )
     {
      sessionStorage.setItem('SearchDataView', '0' )  
     }

       DH = sessionStorage.getItem("SearchDataView")  
      // alert(DH);
       sessionStorage.setItem('SearchDataView', '0' )    
       if (DH==='1'){
        this.setState({ 
          HotelSearch: JSON.parse(sessionStorage.getItem('SearchData') || "") 
        });

        //console.log("Hotel Search :", this.state.HotelSearch);
       
        this.state.selectfields.searchDays = sessionStorage.getItem("SearchDays")  
        this.state.selectfields.bookings = sessionStorage.getItem("SearchBooking")
        this.state.selectfields.searchString = sessionStorage.getItem("SearchString")
        this.state.fields.find  = sessionStorage.getItem("SearchFind")      

        this.setState({selectfields, fields});   
        this.changeStartDate();
      }
      else
      {
        this.loadSearchHotelGrid();
      }
     
    
     this.setState({selectfields, fields});
      
  }
  handleOnSubmit = (e:any, currentTargetName) => {
    let modal = this.state.modal;
    modal['modalSpinnerShow']=true;
    this.setState({modal});
    this.loadSearchHotelGrid();
  }
  handleSelectChange =(e:any)=>{
    
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let currentTargetName = e.currentTarget.name;

    if (currentTargetName === "searchString"){
      fields["searchValue"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields,fields });
    }
    else if (currentTargetName === "tracking_grid")
    {
      modal['modalSpinnerShow']=true;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields,fields,modal });
      this.loadSearchHotelGrid();
    }
    else if (currentTargetName === "formLabel"){
      modal['modalSpinnerShow']=true;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      fields["label_id"] = e.currentTarget.value;
      this.setState({ selectfields,fields,modal });
      this.loadSearchHotelGrid();
    }
    else if (currentTargetName === "searchDays"){
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      //console.log(selectfields[e.currentTarget.name]);
      this.setState({ selectfields,fields });
      this.changeStartDate();
    }
    else if (currentTargetName === "bookings"){
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      //console.log(selectfields[e.currentTarget.name]);
      this.setState({ selectfields });    
      this.loadSearchHotelGrid();
    }
    else
    {
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields,fields });
    }   
  }
  handleChange=(e:any)=>{
    
    let fields = this.state.fields;
    if (e.currentTarget.value.trim().length > 0)
    {
      fields[e.currentTarget.name] = e.currentTarget.value;
    }
    else
    {
      fields[e.currentTarget.name] = e.currentTarget.value.trim();
    }
    this.setState({ fields });
  }
  
  changeStartDate(){
    if(this.state.selectfields.searchDays === "all"){
      var date = new Date("01/01/2000");
    }
    else if(this.state.selectfields.searchDays === "other"){
      var date = new Date();
    }
    else{
      var date = new Date();
      date.setDate(date.getDate() - this.state.selectfields.searchDays);
    }
    this.setState({
      startDate: date, endDate:new Date() 
    })
  };
  handleChangeStartDate = date => {
    this.setState({
      startDate: date
    });
  };
  handleChangeEndDate = date => {
    let selectfields = this.state.selectfields;
    selectfields['searchDays'] = "other";
        this.setState({
          endDate: date, selectfields
        });
      };

  loadSearchHotelGrid=()=>{
    //
    let selectfields = this.state.selectfields;   
    let modal = this.state.modal;
    let sOrderBy:string = '';
    let searchScope:string = this.state.fields.searchValue;
    let beginDate:string = Utils.FormatDate(this.state.startDate);
    let endDate:string = Utils.FormatDate(this.state.endDate);
    let searchString:string = this.state.fields.find;
    let box:string = '';
    let dbview:string = this.state.selectfields['bookings'];
    let label_id:string = this.state.fields.label_id;
    let user_id = sessionStorage.getItem("uid"); // 'Ahamedb8300';
    //let usertype_id = 'R';
    let usertype_id = sessionStorage.getItem("user_type_id");
    let approvertier_id = '3';  
    
    sOrderBy = sOrderBy + " order by " //+ this.state.fields.orderBy  
    
    //
    let hotelGridController = new DirectBillActivityController();
     //return sWhere; 
      var data = {};  
      data = {
        box:box,
        dbview:dbview,
        beginDate:beginDate,
        endDate:endDate,
        label_id:label_id,
        user_id:user_id,
        usertype_id:usertype_id,
        searchString:searchString,
        approvertier_id:approvertier_id,
        searchScope:searchScope,
        sOrderBy: sOrderBy
      }
      hotelGridController.getDirectBillHotelActivity(data).then(response => {
       //
      if (response.success){
        let fields = this.state.fields;
        this.setState({                           
          HotelSearch : JSON.parse(JSON.stringify(response.recordset)), fields
        });
        //console.log("Hotel Search :", this.state.HotelSearch);
        if(response.recordset.length === 0){
          modal['modalSpinnerShow']=false;
          this.setState({modal});
        }
        sessionStorage.setItem('SearchData', JSON.stringify(response.recordset))
        sessionStorage.setItem('SearchDays', this.state.selectfields.searchDays)
        sessionStorage.setItem('SearchBooking', this.state.selectfields.bookings)
        sessionStorage.setItem('SearchString', this.state.selectfields.searchString)
        sessionStorage.setItem('SearchFind', this.state.fields.find)
        sessionStorage.setItem('prevpage', '' ) 
        sessionStorage.setItem('prevpageC', '' ) 
        sessionStorage.setItem('prevpageH', 'Hotel' ) 

        modal['modalSpinnerShow']=false;
        this.setState({modal});
     
       }     
            
    }).catch(error => {
        console.error("There was an error loading Travller Search..., " + error)
        modal['modalSpinnerShow']=false;
        this.setState({modal});
    });
  } 
  onSortChange = (col) => {
    
    const { currentSort } = this.state;
    const { currentColumn } = this.state;
    let nextSort;

    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'default';
    else if (currentSort === 'default') nextSort = 'down';

    let arrayCopy: any
    arrayCopy = [...this.state.HotelSearch];
    arrayCopy.sort(this.compareBySort(col, nextSort));

    this.setState({
      HotelSearch: arrayCopy
    });
  
    this.setState({
        currentSort: nextSort, currentColumn: col
    });

    //console.log("Hotel Search :", this.state.HotelSearch);
    //console.log("onSortChange - sort :", currentSort)
    //console.log("onSortChange - column :", currentColumn)
}

  compareBySort(key, sort) {
    return function (a, b) {
        if (sort === 'up') {
            if (a[key] < b[key]) return -1;
        }
        else if (sort === 'down') {
            if (a[key] > b[key]) return -1;
        }
        return 0;
    }
}

  setColApp=(rsGrid:any,rs:any,iTier:string)=>{
    
    let strHtml:string = '<td></td>';
    if(!rs.success && iTier !== '0' && iTier !== '5'){
      //
      return "<td>&nbsp;</td>";
    }
    else
    {
      let prevName = "", sUser = "", sUserClose= "", approver_weight="",name=""
      let responseApprover = JSON.parse(JSON.stringify(rs.recordset));
      
      name='';
      responseApprover.map((propApprover, keyApprover) => {
        
        approver_weight = propApprover["approver_weight"]
        if (sessionStorage.getItem("sysop") !== ''){
          sUser = "<a onClick=getUser('" + propApprover["user_id"] + "')>";
          sUserClose = "</a>";
        }
        
        if (propApprover["name"] !== prevName){
          if (name !== "") { name = name + "<br>"}
          name = name + sUser + propApprover["name"] + sUserClose;
        }
        
        if(propApprover["updated_date"] !== null){
          name = name + "<br><font size=1>" + propApprover["status_type_desc"] + ": " + Utils.FormatDateTime(propApprover["updated_date"]) + "</font>"
        }  
        prevName = propApprover["name"];
      })
      if(name !== '' || iTier === '0' || iTier === '5'){
        if((rsGrid["weight"] === approver_weight && rsGrid["status_id"] !== "3") && !(iTier === '4' && rsGrid["status_id"] !== '8') && !(iTier === '0' && rsGrid["status_id"] === '8') && !(iTier === '5' && rsGrid["status_id"] === '8')){
          strHtml = '';
          strHtml =  "<td style='backgroundColor:#7CFC00;vertical-align: top'>";
        }
        else
        {
          strHtml = '';
          strHtml = "<td style='backgroundColor:#FFFF00;vertical-align: top'>";
        }
        if (rsGrid["car_service_only"] && iTier === '5')
        {
          strHtml = strHtml + rsGrid["car_service_company"]+"<br>";
        }
        if(iTier === '0' || iTier === '5'){
          strHtml = strHtml + rsGrid["travel_agent_name"]+"<br>";
        }
        strHtml = strHtml + name;
        if(iTier === String(rsGrid["tier_code"]) && !rsGrid["is_corporate_card"] && iTier !=='0'){
          strHtml = strHtml + '<br><b>Coding</b>';
        }
        strHtml = strHtml + "</td>"
        ////console.log ("strHtml:",strHtml)
        return strHtml;
      }
      else
      {
        return "<td>&nbsp;</td>"
      }     
    }
  }
  sqlQ=(s)=>{
    let sqlQ:string="";
    if (s !== "" && s !== null){
		  sqlQ = s.replace("'","''");
    }
    return sqlQ;
  }
  displayColApp=(prop, key, fields, colName,iTierId)=>{
    //
    let modal = this.state.modal;
    fields[colName][key] = parse("<td></td>");
    this.setState({                           
      fields
    });
    
  }
  createSortBy=(sort, by)=>{
    
    //sort = "ar.authreq_id desc, au.name, etc.description , ar.artist_project_name";
    //by = "au.name";
   

    var arrSort = sort.split(",");
    let sortString:string = '';
    let orderByString:string = '';
    let makeorderBy:string = '';

    arrSort.map((item, i) => {
      
     
      if(sort.indexOf(by) > -1){ 
        if(item.indexOf(" desc") > -1){ 
          if (item.indexOf(by) === -1){
            sortString = sortString + "," + item.replace(" desc", "");
          }
        }else{
          if (item.indexOf(by) > -1){
            orderByString = item.replace(" desc", "");;
          }
          else
          {
            sortString = sortString + "," + item.trim();
          }
        }
       }
       else{
        sortString = sortString + "," + item.trim();
       }
      });
      if(sort.indexOf(" desc") === -1){
        orderByString = by + " desc";
      }
    return orderByString + sortString;
  }
  formLabel=()=>{
    return  <Form.Group>
        <Form.Label>Form Label</Form.Label>
        <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} onChange={this.handleSelectChange}>
            {this.state.FormLabel.map((prop, key) => {
              if(prop["is_active"] === "Yes"){
                return(
                    <option value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else
              {
                return(
                    <option className="text-danger" value={prop["id"]}>{ prop["name"]}</option>
                )
              }
            })}
        </Form.Control>
        </Form.Group>   
  }
      
  sortClicked=(col)=>{
    //
    let fields = this.state.fields;
    let modal = this.state.modal;
    modal['modalSpinnerShow']=true;
    fields["sortClicked"] = true;
    fields["sortBy"] = col;
    this.setState({fields});
    this.sortByCol();
    this.loadSearchHotelGrid();
  }
  sortByCol=()=>{
    //
    let fields = this.state.fields;
   

    if (this.state.fields.sortClicked || this.state.fields.orderBy === ""){
      switch(this.state.fields.sortBy) { 
        case "authreq_id":{
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "ar.authreq_id")
          break;
        }
        case "traveler":{
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "traveler")
          break;
        }
        case "label_id":{
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "ar.label_id")
          break;
        }
        case "expense_type_desc":{
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "expense_type_desc")
          break;
        }
        case "out_date":{
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "out_date")
          break;
        }
        case "hotel":{
          fields["orderBy"] = this.createSortBy(this.state.fields.orderBy, "hotel")
          break;
        }
       /* default: { 
          fields["orderBy"] = "ar.label_id, expense_type_desc, ar.authreq_id, traveler, vendor_number, invoice_number"
          break; 
       } */
      }
      //console.log ("sWhere: ",fields["orderBy"])
      this.setState({fields});
    }
  }
  onSelect(authReqId,booking_id){

   let sbookings = this.state.selectfields['bookings'];
   let usertype_id = sessionStorage.getItem("user_type_id");

     if (usertype_id == 'DB')
     {
      this.props.history.push({pathname:'/admin/billview',state:{from:"directbill",authReqId:authReqId,booking_id:booking_id, sbookings:sbookings}});
      this.setState({ loading: false });
     }
     else
     {          
        this.props.history.push({pathname:'/admin/billview',state:{from:"directbill",authReqId:authReqId,booking_id:booking_id, sbookings:sbookings}});
        this.setState({ loading: false });
     }
   
}

setArrow = (column) => {
  let { currentSort } = this.state;
  let { currentColumn } = this.state;
  let className = "";

  if (column === currentColumn) {
      if (currentSort === "up") {
          className = 'fa fa-fw fa-sort-up';
      }
      else if (currentSort === "down") {
          className = 'fa fa-fw fa-sort-down';
      }
      else {
          className = 'fa fa-fw fa-sort';
      }
  }
  else {
      className = 'fa fa-fw fa-sort';
  }

  return className;
};

getLinkWithPath= (item) =>{//
     
     return (<NavLink
     to={{
       pathname:'/admin/billview',
       state:{
        from:"directbill",
        authReqId:item["authReqId"],
        booking_id:item["booking_id"],
        sbookings: this.state.selectfields['bookings']
       }
     }}                                        
     activeClassName="active">{item["Order_ID"]}</NavLink>)
   

};

  render() {
    if (this.state.loggedIn === false){
        //return <Redirect to="/" />
    }
    return (<div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
              <Row>
                <Col sm={12}>
                  <Card border="light" style={{ width: '100%', height:'650px' }}>
                  <div className="header"><h4 className="title">Direct Bill Hotel Activity</h4><p className="category"></p></div>
                  <Card.Body>
                      <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                   
                   
                    <Row style={{marginTop:"15px"}}><Col sm={12}></Col></Row>
                    <Row>
                      <Col sm={2}> 
                      <Form.Group>                                                  
                            <div><Form.Control as="select" name="bookings" id="bookings" className="form-control-sm" value={this.state.selectfields.bookings} onChange={this.handleSelectChange}>
                                <option value="Hotel">Hotel</option> 
                                <option value="Rental Car">Rental Car</option>                               
                         </Form.Control></div>
                      </Form.Group>
                      </Col>
                      
                    </Row>
                    <Row>
                      <Col sm={1}><div className="text-dark font-size-xs small">For</div></Col>
                      <Col sm={2}> 
                      <Form.Group>                                                  
                            <div><Form.Control as="select" name="searchDays" id="searchDays" className="form-control-sm" value={this.state.selectfields.searchDays} onChange={this.handleSelectChange}>
                                <option value="all">All</option> 
                                <option value="3">Last 3 days</option> 
                                <option value="7">Last 7 days</option> 
                                <option value="14">Last 14 days</option> 
                                <option value="30">Last 30 days</option> 
                                <option value="45">Last 45 days</option> 
                                <option value="60">Last 60 days</option>
                                <option value="90">Last 90 days</option> 
                                <option value="other">Other</option> 
                         </Form.Control></div>
                      </Form.Group>
                      </Col>
                      <Col sm={2}>                            
                              <DatePicker style={{fontSize:"12px"}} 
                                Id="startDate"
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown                            
                                className="datePicker"/> 
                      </Col>
                      <Col sm={1}><div className="text-dark font-size-xs small"> and</div></Col>
                      <Col sm={2}>
                              <DatePicker style={{fontSize:"12px"}} 
                                Id="endDate"
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown                            
                                className="datePicker"/>
                      </Col>
                      
                      <Col sm={4} className="text-dark font-size-xs small"> (Search is based  on Check-Out date)</Col>
                    </Row>
                    <Row>
                      <Col sm={1}><div className="text-dark font-size-xs small">Find</div></Col>
                      <Col sm={3}>
                      <Form.Group>
                            <div><Form.Control type="text" name="find" className="form-control-sm" value={this.state.fields.find} onChange={this.handleChange}> 
                            </Form.Control></div>
                        </Form.Group>
                      </Col>
                      <Col sm={0.5}><div className="text-dark font-size-xs small">In</div></Col>
                      <Col sm={2.5}>
                        <Form.Group>                                                     
                          <div><Form.Control as="select" name="searchString" className="form-control-sm"  value={this.state.selectfields.searchString} onChange={this.handleSelectChange}>
                            <option value="ar.authreq_id">AuthReq #</option>
                            <option value="au.name">Submitter</option>
                            <option value="etc.description">AuthReq Type</option>
                            <option value="ar.artist_project_name">Project</option>
                            <option value="TD.First_Name">Traveler First Name</option>
                            <option value="TD.Last_Name">Traveler Last Name</option>
                          </Form.Control></div>
                        </Form.Group>
                      </Col>
                      <Col sm={0.5}><div className="text-dark font-size-xs small">field.</div></Col>
                      <Col sm={1.5} style={{textAlign: "center", alignSelf: "center"}} >
                        <Button disabled={this.state.loading} name = 'searchHistory' style={{marginRight: "5px"}} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name)} title="Click to start search">
                            {this.state.loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px",color:"#3472F7"}}
                            />
                            )}
                            {this.state.loading && <span style={{color:"#3472F7"}}>wait...</span>}
                            {!this.state.loading && <span>Search</span>}
                        </Button>
                      </Col> 
                      <Col sm={1.5} style={{textAlign: "center", alignSelf: "center"}} >
                        <Button disabled={this.state.loading} name = 'Refresh' style={{marginRight: "5px"}} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name)} title="Click to start search">
                            {this.state.loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px",color:"#3472F7"}}
                            />
                            )}
                            {this.state.loading && <span style={{color:"#3472F7"}}>wait...</span>}
                            {!this.state.loading && <span>Refresh</span>}
                        </Button>
                      </Col>                       
                      <Col sm={1.5} style={{textAlign: "center", alignSelf: "center"}}>
                      <Button disabled={this.state.loading} name = 'searchHistory' style={{marginRight: "5px"}} onClick={(e) => window.print()} title="Click to start print">
                            {this.state.loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px",color:"#3472F7"}}
                            />
                            )}
                            {this.state.loading && <span style={{color:"#3472F7"}}>wait...</span>}
                            {!this.state.loading && <span>Print</span>}
                        </Button>
                      </Col>  
                    </Row>
                    <Row style={{ marginTop: "15px" }}><Col sm={12}></Col></Row>
                    <Row>
                      <Col sm={12}>
                        <div style={{width: '100%'}}>
                          <Table hover striped bordered>                    
                            <thead style={{backgroundColor:'#052a5e', fontSize: 'large'}}>
                              <tr>                                
                             <th onClick={() => this.onSortChange('authreq_id')}> <b>Auth Req</b><i className={this.setArrow('auth_req')}></i></th>                             
                             <th onClick={() => this.onSortChange('traveler')}><b>Traveler</b><i className={this.setArrow('traveler')}></i></th>
                             <th onClick={() => this.onSortChange('label_id')}><b>HFM Entity</b><i className={this.setArrow('label_id')}></i></th>
                             <th onClick={() => this.onSortChange('expense_type_desc')}><b>Type</b><i className={this.setArrow('expense_type_desc')}></i></th>
                              {
                                (this.state.selectfields['bookings'] === 'Hotel')
                                ?                              
                              <th onClick={() => this.onSortChange('out_date')}><b>Check-Out</b><i className={this.setArrow('out_date')}></i></th>                             
                              :
                              <th onClick={() => this.onSortChange('return_date')}><b>Return</b><i className={this.setArrow('return_date')}></i></th>                              
                              }

                              {
                                (this.state.selectfields['bookings'] === 'Hotel')
                                ?
                              <th onClick={() => this.onSortChange('hotel')}><b>Hotel</b><i className={this.setArrow('hotel')}></i></th>
                                :
                              <th onClick={() => this.onSortChange('car_company')}><b>Car Rental</b><i className={this.setArrow('car_company')}></i></th>                           
                              }
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.HotelSearch.map((prop, key) => {
                                //
                                  return (
                                    <tr key={key} onClick={() => this.onSelect(prop["authreq_id"],prop["booking_id"])}  style={{color: prop["color"], cursor:'pointer'}}  >
                                    <td> {prop["authreq_id"]} </td>
                                    <td> {prop["traveler"]}</td>
                                    <td> {prop["label_id"]}</td>
                                    <td style={{color: prop["tacolor"]}}> {prop["expense_type_desc"]}</td>
                                    {
                                      (this.state.selectfields['bookings'] === 'Hotel')
                                      ?
                                      <td> {Utils.FormatDate(prop["out_date"])}</td>
                                      :
                                      <td> {Utils.FormatDate(prop["return_date"])}</td>
                                    }
                                    {
                                      (this.state.selectfields['bookings'] === 'Hotel')
                                      ?
                                      <td> {prop["hotel"]}</td>
                                      :
                                      <td> {prop["car_company"]}</td>
                                    }                                    
                                    
                                    </tr>      
                                  );         
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>                                  
                  </Card>
                </Col>
              </Row>
            </Container>
        </div>
    );
  }
}