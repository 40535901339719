import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class SystemMaintenanceController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getAllSysMessage (){
        var url = this.variables.Url+ this.variables.Controller.systemMaintenance + this.variables.systemMaintenanceService.getAllSysMessage;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetSysMessage : Array<{"messageId": string, "message": string,"isActive":boolean,"createdDate":string,"createdBy":string,"updatedDate":string,"updatedBy":string,"isDelete":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { Message_ID: any; Message: any;Is_Active:any;Created_Date:any;Created_By:any; Updated_Date:any;Updated_By:any;}){
                        jsonGetSysMessage.push({"messageId": item.Message_ID, "message": item.Message, "isActive": item.Is_Active,
                        "createdDate": item.Created_Date, "createdBy": item.Created_By, "updatedDate": item.Updated_Date, "updatedBy": item.Updated_By,"isDelete": false})
                    }
                    return JSON.parse(JSON.stringify(jsonGetSysMessage).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetSysMessage).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    createUpdateDeleteSysMessage(data)
    {
        var url = this.variables.Url+ this.variables.Controller.systemMaintenance + this.variables.systemMaintenanceService.createUpdateDeleteSysMessage;
        var data1 = {
            doBy: data.doBy,
            messageId:data.messageId, 
            message:data.message,
            isActive:data.isActive,
            createdDate:data.createdDate,
            createdBy:data.createdBy,     
            updatedDate:data.updatedDate,
            updatedBy:data.updatedBy,
        }
        //console.log(data);
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getActiveSysMessage (){
        var url = this.variables.Url+ this.variables.Controller.systemMaintenance + this.variables.systemMaintenanceService.getAllActiveSysMessage;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetSysMessage : Array<{ "message": string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: {message: any;}){
                        jsonGetSysMessage.push({"message": item.message})
                    }
                    return JSON.parse(JSON.stringify(jsonGetSysMessage).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetSysMessage).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}