import React, { Component } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Alert,
  Button,
  Table,
} from "../../../../node_modules/react-bootstrap";
import { NavLink } from "react-router-dom";
import { ITrackingGridParameter, Istate } from "../../../Model/ITrackingGrid";
import { CommonController } from "../../../Controllers/CommonController";
import { TrackingGridController } from "../../../Controllers/TrackingGridController";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";
import { TravellerController } from "../../../Controllers/TravellerController";
import * as Utils from "../../../js/Utils.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import parse from "html-react-parser";
import addSubtractDate from "add-subtract-date";

export class TrackingGrid extends Component<ITrackingGridParameter, Istate> {
  constructor(props: ITrackingGridParameter) {
    super(props);
    this.state = {
      fields: {
        label_id: sessionStorage.getItem("label_id"),
        authreq_id: "",
        submitter: "",
        expense_type_id: "",
        weight: "",
        tier_code: "",
        startDate: "",
        find: "",
        searchScope: "",
        deptApp: [],
        billingApp: [],
        authApp: [],
        srexecApp: [],
        agentApp: [],
        searchValue: "",
        sortClicked: false,
        sortBy: "",
        orderBy: "",
        appCnt: 0,
      },
      selectfields: {
        tracking_grid: "",
        label_id: "",
        searchString: "",
        formLabel: "",
      },
      errors: { username: "", password: "" },
      errMsg: "",
      loading: false,
      modal: { modalSpinnerShow: false },
      startDate: new Date(),
      endDate: new Date(),
      FormLabel: [],
      TravellerSearch: [],
      Approver: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
  }

  componentWillMount() {
    let modal = this.state.modal;
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    //Declaration begins
    fields["searchValue"] = "ar.authreq_id";
    fields["find"] = "";
    selectfields["searchString"] = "AuthReq #";
    selectfields["tracking_grid"] = "In-Progress";
    selectfields["searchDays"] = "3";
    this.changeStartDate();
    modal["modalSpinnerShow"] = true;
    this.setState({ modal, selectfields, fields });
    let commonController = new CommonController();
    commonController
      .getLabels()
      .then((jsonLabels) => {
        this.setState({
          FormLabel: JSON.parse(JSON.stringify(jsonLabels)),
        });
        //console.log("FormLabel:", this.state.FormLabel);
        debugger;
        let selectfields = this.state.selectfields;
        if (
          sessionStorage.getItem("label_id") === "null" ||
          sessionStorage.getItem("label_id") === ""
        ) {
          fields["label_id"] =
            this.state.FormLabel.length > 0 ? jsonLabels[0].id : "";
        } else {
          selectfields["formLabel"] = sessionStorage.getItem("label_id");
        }
        this.setState({ modal, selectfields });
        this.sortByCol();
        this.loadTrakingGrid();
      })
      .catch((error) => {
        console.error("There was an error loading Labels..., " + error);
      });
  }
  handleChange = (e: any) => {
    debugger;
    let fields = this.state.fields;
    if (e.currentTarget.value.trim().length > 0) {
      fields[e.currentTarget.name] = e.currentTarget.value;
    } else {
      fields[e.currentTarget.name] = e.currentTarget.value.trim();
    }
    this.setState({ fields });
  };

  handleSelectChange = (e: any) => {
    debugger;
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let currentTargetName = e.currentTarget.name;
    if (currentTargetName === "searchString") {
      fields["searchValue"] =
        e.currentTarget.options[e.currentTarget.selectedIndex].value;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields, fields });
    } else if (currentTargetName === "tracking_grid") {
      modal["modalSpinnerShow"] = true;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields, fields, modal });
      this.loadTrakingGrid();
    } else if (currentTargetName === "formLabel") {
      debugger;
      const elMsg: HTMLSelectElement = document.getElementById(
        "formLabel"
      ) as HTMLSelectElement;
      //console.log("HTML element :", elMsg.selectedOptions);
      var selected_box = (
        document.getElementById("formLabel") as HTMLSelectElement
      ).value;
      //console.log("HTML :", selected_box);

      modal["modalSpinnerShow"] = true;
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      fields["label_id"] = e.currentTarget.value;
      this.setState({ selectfields, fields, modal });
      this.loadTrakingGrid();
    } else if (currentTargetName === "searchDays") {
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      //console.log(selectfields[e.currentTarget.name]);
      this.setState({ selectfields, fields });
      this.changeStartDate();
    } else {
      selectfields[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ selectfields, fields });
    }
  };

  changeStartDate() {
    if (this.state.selectfields.searchDays === "all") {
      var date = new Date("01/01/2000");
    } else if (this.state.selectfields.searchDays === "other") {
      let beginDate: string = Utils.DefaultFormatDate(this.state.startDate);
      var date = new Date(beginDate);
      //console.log("other beginDate: utils", beginDate);
      //console.log("other beginDate: date", date);
    } else {
      var date = new Date();
      date.setDate(date.getDate() - this.state.selectfields.searchDays);
    }
    this.setState({
      startDate: date,
      endDate: new Date(),
    });
    //console.log("stateDate:", this.state.startDate);
    //console.log("endDate:", this.state.endDate);
  }

  handleChangeStartDate = (date) => {
    debugger;
    this.setState({
      startDate: date,
    });
  };

  handleChangeEndDate = (date) => {
    debugger;
    let selectfields = this.state.selectfields;
    selectfields["searchDays"] = "other";
    this.setState({
      endDate: date,
      selectfields,
    });
  };

  handleOnSubmit = (e: any, currentTargetName) => {
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({ modal });
    this.loadTrakingGrid();
  };

  loadTrakingGrid = () => {
    debugger;
    let selectfields = this.state.selectfields;
    let modal = this.state.modal;
    let sWhere: string = "";
    let searchScope: string = this.state.fields.searchValue;
    let beginDate: string = Utils.DefaultFormatDate(this.state.startDate);
    let endDate: string = Utils.DefaultFormatDate(this.state.endDate);
    
    let addStar="*"

    let searchString: string = this.state.fields.find;
    searchString = addStar.concat(searchString);

    //console.log(searchString, "searchString");
    // //console.log(result, "searchScope111");
    
    //console.log("search-stateDate:", this.state.startDate);
    //console.log("beginDate:", beginDate);
    //console.log("search-endDate:", this.state.endDate);
    //console.log("endDate:", endDate);

    sWhere = " ar.label_id = '" + this.state.fields.label_id + "'";
    if (selectfields["tracking_grid"] === "completed") {
      sWhere =
        sWhere +
        " and (ar.status_id = '4' or ar.status_id = '6' or ar.status_id = '7' or ar.status_id = '9')";
    } else {
      sWhere =
        sWhere +
        " and (ar.status_id = '2' or ar.status_id = '3' or ar.status_id = '5' or ar.status_id = '8')";
    }
    //sWhere = sWhere +  " and ar.created_date between '" + beginDate + "' and '" + dateadd("d", 1, endDate) + "'"
    sWhere =
      sWhere +
      " and ar.created_date between '" +
      beginDate +
      "' and '" +
      Utils.DefaultFormatDate(
        addSubtractDate.add(new Date(endDate), 1, "day")
      ) +
      "'";
    sWhere = sWhere + " and " + searchScope;
//console.log(searchString,"searchString")

    if (searchString.indexOf("*") > -1) {
      sWhere =
        sWhere + " like '" + this.sqlQ(searchString.replace("*", "%")) + "'";
    } else {
      sWhere = sWhere + " = '" + this.sqlQ(searchString) + "'";
    }
    sWhere = sWhere + " order by " + this.state.fields.orderBy;
    //sWhere = sWhere + " order by ar.authreq_id"
    //console.log(sWhere);
    let trackingGridController = new TrackingGridController();
    var data = {
      sWhere: sWhere,
    };

    //console.log(data, "datafinaal seraffdsfff");
    trackingGridController
      .getTravellerSearch(data)
      .then((response) => {
        //debugger;
        if (response.success) {
          let fields = this.state.fields;
          this.setState({
            TravellerSearch: JSON.parse(JSON.stringify(response.recordset)),
            fields,
          });
          if (response.recordset.length === 0) {
            modal["modalSpinnerShow"] = false;
            this.setState({ modal });
          }
          //console.log("Traveller Search", this.state.TravellerSearch);
          this.state.TravellerSearch.map((prob, i) => {
            //console.log(response.recordset[i]);
            this.displayColApp(
              response.recordset[i],
              i,
              fields,
              "deptApp",
              "3"
            );
            this.displayColApp(
              response.recordset[i],
              i,
              fields,
              "billingApp",
              "2"
            );
            this.displayColApp(
              response.recordset[i],
              i,
              fields,
              "authApp",
              "1"
            );
            this.displayColApp(
              response.recordset[i],
              i,
              fields,
              "srexecApp",
              "4"
            );
            debugger;
            //console.log("index traveller search", i);
            
            if (response.recordset[i].car_service_only) {
              this.displayColApp(
                response.recordset[i],
                i,
                fields,
                "agentApp",
                "5"
              );
            } else {
              this.displayColApp(
                response.recordset[i],
                i,
                fields,
                "agentApp",
                "6"
              );
            }
          });
        }
      })
      .catch((error) => {
        console.error("There was an error...", error);
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      });
  };

  setColApp = (rsGrid: any, rs: any, iTier: string) => {
    debugger;
    let strHtml: string = "<td></td>";
    if (!rs.success && iTier !== "6" && iTier !== "5") {
      return "<td>&nbsp;</td>";
    } else {
      let prevName = "",
        sUser = "",
        sUserClose = "",
        approver_weight = "",
        name = "",
        updateDate = "";
      let responseApprover = JSON.parse(JSON.stringify(rs.recordset));
      debugger;
      name = "";
      updateDate = "";
      responseApprover.map((propApprover, keyApprover) => {
        approver_weight = propApprover["approver_weight"];
        if (sessionStorage.getItem("sysop") !== "") {
          sUser = "<a onClick=getUser('" + propApprover["user_id"] + "')>";
          sUserClose = "</a>";
        }
        debugger;
        if (propApprover["name"] !== prevName) {
          if (name !== "") {
            name = name + "<br>";
          }
          name = name + sUser + propApprover["name"] + sUserClose;
        }

        if (propApprover["updated_date"] !== null) {
          name =
            name +
            "<br><font size=1>" +
            propApprover["status_type_desc"] +
            ": " +
            Utils.FormatDateTime(propApprover["updated_date"]) +
            "</font>";
          updateDate =
            "<font size=1>" +
            propApprover["status_type_desc"] +
            ": " +
            Utils.FormatDateTime(propApprover["updated_date"]) +
            "</font>";
        }
        prevName = propApprover["name"];
      });
      if (name !== "" || iTier === "6" || iTier === "5") {
        if (
          rsGrid["weight"] == approver_weight &&
          rsGrid["status_id"] != "3" &&
          !(iTier == "4" && rsGrid["status_id"] != "8") &&
          !(iTier == "6" && rsGrid["status_id"] == "8") &&
          !(iTier == "5" && rsGrid["status_id"] == "8")
        ) {
          strHtml = "";
          strHtml = "<td style='backgroundColor:#39BF22;vertical-align: top'>";
        } else {
          strHtml = "";
          strHtml = "<td style='backgroundColor:#F1E821;vertical-align: top'>";
        }
        if (rsGrid["car_service_only"] && iTier === "5") {
          strHtml = strHtml + rsGrid["car_service_company"] + "<br>";
        }
        if (iTier === "6" || iTier === "5") {
          //strHtml = strHtml + rsGrid["travel_agent_name"] + "<br>";
          
          if (name.indexOf(rsGrid["travel_agent_name"]) === -1) {
            strHtml = strHtml + rsGrid["travel_agent_name"] + "<br>";
          }
          strHtml = strHtml + name;
        } else {
          strHtml = strHtml + name;
        }
        if (
          iTier === String(rsGrid["tier_code"]) &&
          !rsGrid["is_corporate_card"] &&
          iTier !== "6"
        ) {
          strHtml = strHtml + "<br><b>Coding</b>";
        }
        strHtml = strHtml + "</td>";
        return strHtml;
      } else {
        return "<td>&nbsp;</td>";
      }
    }
  };

  sqlQ = (s) => {
    let sqlQ: string = "";
    if (s !== "" && s !== null) {
      sqlQ = s.replace("'", "''");
    }
    return sqlQ;
  };

  displayColApp = (prop, key, fields, colName, iTierId) => {
    //debugger;
    let modal = this.state.modal;
    fields[colName][key] = parse("<td></td>");
    this.setState({ fields });
    let trackingGridController = new TrackingGridController();
    var data = {};
    let iTier: string = iTierId;
    data = {
      authreq_id: prop["authreq_id"],
      iTier: iTier,
      tracking_grid: this.state.selectfields.tracking_grid,
      label_id: this.state.fields.label_id,
      expense_type_id: prop["expense_type_id"],
      department_id: prop["department_id"],
    };
    trackingGridController
      .getApprover(data)
      .then((responseApprover) => {
        fields[colName][key] = parse(
          this.setColApp(prop, responseApprover, iTier)
        );
        modal["modalSpinnerShow"] = false;
        this.setState({ fields, modal });
        /*if (colName === "agentApp") {
        debugger;
      }
      if (responseApprover.recordset.length > 0) {
        fields[colName][key] = parse(this.setColApp(prop, responseApprover, iTier));
      }
      else {
        fields[colName][key] = parse("<td></td>");
      }
      if (colName === "agentApp" && this.state.TravellerSearch.length - 1 === key) {
        modal['modalSpinnerShow'] = false;
      }
      this.setState({ fields, modal });*/
      })
      .catch((error) => {
        console.error("There was an error loading getApprover...", error);
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      });
  };

  createSortBy = (sort, by) => {
    debugger;
    //sort = "ar.authreq_id desc, au.name, etc.description , ar.artist_project_name";
    //by = "au.name";

    var arrSort = sort.split(",");
    let sortString: string = "";
    let orderByString: string = "";
    let makeorderBy: string = "";

    arrSort.map((item, i) => {
      debugger;

      if (sort.indexOf(by) > -1) {
        if (item.indexOf(" desc") > -1) {
          if (item.indexOf(by) === -1) {
            sortString = sortString + "," + item.replace(" desc", "");
          }
        } else {
          if (item.indexOf(by) > -1) {
            orderByString = item.replace(" desc", "");
          } else {
            sortString = sortString + "," + item.trim();
          }
        }
      } else {
        sortString = sortString + "," + item.trim();
      }
    });
    if (sort.indexOf(" desc") === -1) {
      orderByString = by + " desc";
    }
    return orderByString + sortString;
  };
  formLabel = () => {
    return (
      <Form.Group>
        <Form.Label>Form Label</Form.Label>
        <Form.Control
          as="select"
          className="form-control form-control-sm"
          id="formLabel"
          name="formLabel"
          value={this.state.selectfields.formLabel}
          onChange={this.handleSelectChange}
        >
          {this.state.FormLabel.map((prop, key) => {
            if (prop["is_active"] === "Yes") {
              return <option value={prop["id"]}>{prop["name"]}</option>;
            } else {
              return (
                <option className="text-danger" value={prop["id"]}>
                  {prop["name"]}
                </option>
              );
            }
          })}
        </Form.Control>
      </Form.Group>
    );
  };
  loadColTA = (prop) => {
    //debugger;
    if (prop["status_id"] === "3") {
      return (
        <td style={{ backgroundColor: "#39BF22", verticalAlign: "top" }}>


         <NavLink
            onClick={() => this.onSelect(prop["authreq_id"])}
            to={{}}
            activeClassName="active"
            style={{ cursor: "pointer" }}
          >
            {prop["car_service_only"]
              ? "CA" + prop["authreq_id"]
              : prop["authreq_id"]}
          </NavLink>
          <div>{prop["submitter"]}</div>
          <div>{prop["expense_type_desc"]}</div>
          {prop["artist_project_name"].trim() !== "" ? (
            <div>{prop["artist_project_name"]}</div>
          ) : (
            ""
          )}
          <div>{Utils.FormatDateTime(prop["created_date"])}</div>
          {prop["is_corporate_card"] ? (
            <div>
              <b>Corporate Card</b>
            </div>
          ) : prop["tier_code"] === 0 ? (
            <div>
              <b>Coding</b>
            </div>
          ) : (
            ""
          )}
          {prop["status_id"] === "3" ? <div>Resubmit</div> : ""}
        </td>
      );
    } else {
      //console.log(prop, "prpossss");
      return (
        <td style={{ verticalAlign: "top" }}>
          <NavLink
            onClick={() => this.onSelect(prop["authreq_id"])}
            to={{}}
            activeClassName="active"
            style={{ cursor: "pointer" }}
          >
            {prop["car_service_only"]
              ? "CA" + prop["authreq_id"]
              : prop["authreq_id"]}
          </NavLink>

          <div>{prop["submitter"]}</div>
          <div>{prop["expense_type_desc"]}</div>
          {prop["artist_project_name"].trim() !== "" ? (
            <div>{prop["artist_project_name"]}</div>
          ) : (
            ""
          )}
          <div>{Utils.FormatDateTime(prop["created_date"])}</div>
          {prop["is_corporate_card"] ? (
            <div>
              <b>Corporate Card</b>
            </div>
          ) : prop["tier_code"] === 0 ? (
            <div>
              <b>Coding</b>
            </div>
          ) : (
            ""
          )}
          {prop["status_id"] === "3" ? <div>Resubmit</div> : ""}
        </td>
      );
    }
  };

  onSelect(authReqId) {
    // debugger;
    let travellerController = new TravellerController();
    travellerController
      .getTA(authReqId)
      .then((response) => {
        // sessionStorage.setItem("page", "approver");
        this.props.history.push({
          pathname: "/admin/decision",
          state: { from: "tahistory" },
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  sortClicked = (col) => {
    //debugger;
    let fields = this.state.fields;
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    fields["sortClicked"] = true;
    fields["sortBy"] = col;
    this.setState({ fields });
    this.sortByCol();
    this.loadTrakingGrid();
  };

  sortByCol = () => {
    //debugger;
    let fields = this.state.fields;
    if (this.state.fields.sortClicked || this.state.fields.orderBy === "") {
      switch (this.state.fields.sortBy) {
        case "authreq_id": {
          fields["orderBy"] = this.createSortBy(
            this.state.fields.orderBy,
            "ar.authreq_id"
          );
          break;
        }
        case "created_by": {
          fields["orderBy"] = this.createSortBy(
            this.state.fields.orderBy,
            "au.name"
          );
          break;
        }
        case "type": {
          fields["orderBy"] = this.createSortBy(
            this.state.fields.orderBy,
            "etc.description"
          );
          break;
        }
        case "artist_project_name": {
          fields["orderBy"] = this.createSortBy(
            this.state.fields.orderBy,
            "artist_project_name"
          );
          break;
        }
        default: {
          fields["orderBy"] =
            "ar.authreq_id desc, au.name, etc.description , ar.artist_project_name";
          break;
        }
      }
      //console.log("sWhere: ", fields["orderBy"]);
      this.setState({ fields });
    }
  };

  render() {
    if (this.state.loggedIn === false) {
      //return <Redirect to="/" />
    }
    return (
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: "100%", height: "650px" }}>
                <div className="header">
                  <h4 className="title">eTA - Tracking Grid</h4>
                  <p className="category"></p>
                </div>
                <Card.Body>
                  <Form autoComplete="off">
                    <Alert
                      variant="danger"
                      style={{
                        display: this.state.errMsg ? "inherit" : "none",
                      }}
                    >
                      {this.state.errMsg}
                    </Alert>
                    <Row style={{ marginTop: "10px" }}>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Label>eTA Tracking Grid</Form.Label>
                          <div>
                            <Form.Control
                              as="select"
                              name="tracking_grid"
                              className="form-control-sm"
                              value={this.state.selectfields.tracking_grid}
                              onChange={this.handleSelectChange}
                            >
                              <option value="In-Process" selected>
                                In-Process
                              </option>
                              <option value="completed">completed</option>
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>{this.formLabel()}</Col>
                      <Col
                        sm={3}
                        className="align-self-left"
                        style={{ textAlign: "justify" }}
                      >
                        <Form.Group>
                          <Form.Label>Selected Operating Company</Form.Label>
                          <br></br>
                          <span className="badge badge-blue">
                            {" " + this.state.fields.label_id}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <Form.Group>
                          <div>
                            <Form.Label>For</Form.Label>
                            <Form.Control
                              as="select"
                              name="searchDays"
                              id="searchDays"
                              className="form-control-sm"
                              value={this.state.selectfields.searchDays}
                              onChange={this.handleSelectChange}
                            >
                              <option value="all">All</option>
                              <option value="3">Last 3 days</option>
                              <option value="7">Last 7 days</option>
                              <option value="14">Last 14 days</option>
                              <option value="30">Last 30 days</option>
                              <option value="45">Last 45 days</option>
                              <option value="60">Last 60 days</option>
                              <option value="90">Last 90 days</option>
                              <option value="other">Other</option>
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group>
                          <div>
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker
                              style={{ fontSize: "12px" }}
                              Id="startDate"
                              selected={this.state.startDate}
                              onChange={this.handleChangeStartDate}
                              isClearable
                              showMonthDropdown
                              showYearDropdown
                              className="dateStyle"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group>
                          <div>
                            <Form.Label>End Date</Form.Label>
                            <DatePicker
                              style={{ fontSize: "12px" }}
                              Id="endDate"
                              selected={this.state.endDate}
                              onChange={this.handleChangeEndDate}
                              isClearable
                              showMonthDropdown
                              showYearDropdown
                              className="dateStyle"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={1}></Col>
                      <Col sm={5}>
                        <div
                          style={{ width: "100%" }}
                          className="alert alert-primary alert-cus-info"
                        >
                          <div>
                            <h6 className="text-dark">
                              Legend for Tracking Grid
                            </h6>
                            <div className="text-dark font-size-xs">
                              If the cell is{" "}
                              <span className="badge badge-green">Green</span>{" "}
                              then that is where the TA is sitting
                            </div>
                            <div className="text-dark font-size-xs">
                              If the cell is{" "}
                              <span className="badge badge-yellow">Yellow</span>{" "}
                              then the TA has been there or will go there
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Label>Find</Form.Label>
                          <div>
                            <Form.Control
                              type="text"
                              name="find"
                              className="form-control-sm"
                              value={this.state.fields.find}
                              onChange={this.handleChange}
                            ></Form.Control>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col sm={2}>
                        <Form.Group>
                          <Form.Label>In</Form.Label>
                          <div>
                            <Form.Control
                              as="select"
                              name="searchString"
                              className="form-control-sm"
                              value={this.state.selectfields.searchString}
                              onChange={this.handleSelectChange}
                            >
                              <option value="ar.authreq_id">AuthReq #</option>
                              <option value="au.name">Submitter</option>
                              <option value="etc.description">
                                AuthReq Type
                              </option>
                              <option value="ar.artist_project_name">
                                Project
                              </option>
                              <option value="TD.First_Name">
                                Traveler First Name
                              </option>
                              <option value="TD.Last_Name">
                                Traveler Last Name
                              </option>
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={7} style={{ marginTop: "15px" }}>
                        <Button
                          disabled={this.state.loading}
                          name="searchHistory"
                          style={{ marginRight: "5px" }}
                          onClick={(e) =>
                            this.handleOnSubmit(e, e.currentTarget.name)
                          }
                          title="Click to start search"
                        >
                          {this.state.loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px", color: "#487afa" }}
                            />
                          )}
                          {this.state.loading && (
                            <span style={{ color: "#487afa" }}>wait...</span>
                          )}
                          {!this.state.loading && <span>Search</span>}
                        </Button>
                        <Button
                          disabled={this.state.loading}
                          name="btnRefresh"
                          style={{ marginRight: "5px" }}
                          onClick={() => window.location.reload(false)}
                          title="Click to Refresh the Screen"
                        >
                          {this.state.loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px", color: "#3472F7" }}
                            />
                          )}
                          {this.state.loading && (
                            <span style={{ color: "#3472F7" }}>wait...</span>
                          )}
                          {!this.state.loading && <span>Refresh </span>}
                        </Button>
                        <Button
                          disabled={this.state.loading}
                          name="btnPrint"
                          style={{ marginRight: "5px" }}
                          onClick={() => window.print()}
                          title="Click to print page"
                        >
                          {this.state.loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px", color: "#3472F7" }}
                            />
                          )}
                          {this.state.loading && (
                            <span style={{ color: "#3472F7" }}>wait...</span>
                          )}
                          {!this.state.loading && <span>Print </span>}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div style={{ width: "100%" }}>
                          <Table striped bordered hover>
                            <thead
                              style={{
                                backgroundColor: "#00B0F0",
                                fontSize: "large",
                              }}
                            >
                              <tr>
                                <th>
                                  <NavLink
                                    to={"#"}
                                    onClick={() =>
                                      this.sortClicked("authreq_id")
                                    }
                                  >
                                    <a className="a-table">
                                      <b> TA </b>
                                    </a>
                                  </NavLink>
                                  |
                                  <NavLink
                                    to={"#"}
                                    onClick={() =>
                                      this.sortClicked("created_by")
                                    }
                                  >
                                    <a className="a-table">
                                      <b> Submitter </b>
                                    </a>
                                  </NavLink>
                                  |
                                  <NavLink
                                    to={"#"}
                                    onClick={() =>
                                      this.sortClicked("artist_project_name")
                                    }
                                  >
                                    <a className="a-table">
                                      <b> Type </b>
                                    </a>
                                  </NavLink>
                                  |
                                  <NavLink
                                    to={"#"}
                                    onClick={() =>
                                      this.sortClicked("artist_project_name")
                                    }
                                  >
                                    <a className="a-table">
                                      <b> Project </b>
                                    </a>
                                  </NavLink>
                                </th>
                                <th>Department Approver</th>
                                <th>Billing/Coding Approver</th>
                                <th>Finance Approver</th>
                                {/*<th>Sr Exec App</th> as per PAM instruction removed due to less usability*/}
                                <th>Agent</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.TravellerSearch.map((prop, key) => {
                                //debugger;
                                return (
                                  <tr key={key}>
                                    {this.loadColTA(prop)}
                                    {this.state.fields["deptApp"][key]}
                                    {this.state.fields["billingApp"][key]}
                                    {this.state.fields["authApp"][key]}
                                    {/*this.state.fields["srexecApp"][key]*/}
                                    {this.state.fields["agentApp"][key]}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
