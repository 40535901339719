import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class LogoutController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    logoutUser(adUserId : string, sessionId : string){
        var url = this.variables.Url + this.variables.Controller.logout + this.variables.logoutService.logoutUser;
        var data = {
            adUserId: adUserId,
            sessionId: sessionId
        }
        this.serviceProxy.put(url, data).then(response =>{
            sessionStorage.clear();
            localStorage.clear();
        }).catch(error => {
            console.error("Error in user logout...," + error);
        });
    }
}
