import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class VoucherPlusController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getUsers(){
        var url = this.variables.Url + this.variables.Controller.voucherPlus + this.variables.voucherPlusService.getUsers;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonusers : Array<{"id": string; "name": string; }> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { user_id: string;}, _index: number){
                    jsonusers.push({"id": item.user_id, "name": item.user_id})
                }
                return JSON.parse(JSON.stringify(jsonusers).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonusers).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    
    getCrossLabels (userId:string){
        var url = this.variables.Url+ this.variables.Controller.voucherPlus + this.variables.voucherPlusService.getCrossLabels;
        var data = {
            userId : userId
        };
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncrossLabel : Array<{"id": string; "name": string; "is_active": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { label_id: string; description: string;  is_active: string }, _index: number){
                    jsoncrossLabel.push({"id": item.label_id, "name": item.description, "is_active": item.is_active ? "Yes" : "No"})
                }
                return JSON.parse(JSON.stringify(jsoncrossLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsoncrossLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    getVoucherPlusExpenseTypeUser(userId:string,labelId:string){
        
        var url = this.variables.Url + this.variables.Controller.voucherPlus + this.variables.voucherPlusService.getVoucherPlusExpenseTypeUser;
        var data = {
            userId: userId,
            labelId: labelId
        };
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonExpenseType : Array<{"id": string; "name": string; "bit":boolean }> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { expense_type_id: string; description: string; bit: boolean}, _index: number){
                    jsonExpenseType.push({"id": item.expense_type_id, "name": item.description, "bit":item.bit })
                }
                return JSON.parse(JSON.stringify(jsonExpenseType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonExpenseType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    insertUpdVoucherPlusUser (data){
        var url = this.variables.Url + this.variables.Controller.voucherPlus + this.variables.voucherPlusService.insertUpdVoucherPlusUser;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }  
    insertUpdVoucherPlusData (data){
        var url = this.variables.Url + this.variables.Controller.voucherPlus + this.variables.voucherPlusService.insertUpdVoucherPlusData;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }  
    getVoucherPlusExpTypeAccountData(data){
        
        var data1 = {
            labelId: data.labelId,
            expenseTypeId: data.expenseTypeId
        };
        var url = this.variables.Url + this.variables.Controller.voucherPlus + this.variables.voucherPlusService.getVoucherPlusExpTypeAccountData;
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = "";
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
                throw new Error('There was an error! '+ error)
            }); 
    }
}