import React, { Component, CSSProperties } from "react";
import { ICarCompanyListViewParameter, Istate } from "../../../Model/ICarCompanyList";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CarServiceMaintenanceController } from "../../../Controllers/CarServiceMaintenanceController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';

export class CarCompanyListView extends Component<ICarCompanyListViewParameter,Istate> {
    constructor(props:ICarCompanyListViewParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {carServiceCompanyName:'',carServiceCompanyId:'',userTypeId:'',isActive:false},
            selectfields:{},
            errors:{carServiceCompanyName:'',userTypeId:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            carCompanyGroups:[],
            userTypeCodeGroups:[],
            carServiceCompanyName:"",
            carServiceCompanyId:"",
            isDisabled:false,                    
        }
        this.onEdit = this.onEdit.bind(this);
        this.onSaveUpdate = this.onSaveUpdate.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleNavigatePage = this.handleNavigatePage.bind(this);
    }

    async componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true; 
        await this.getUserTypeCodes();     
        await this.getCarCompany();               
        modal['modalSpinnerShow'] = false;
        this.setState({ modal}); 
    }

    onEdit = (e:any,item) => {
        e.preventDefault();
        let modal = this.state.modal;
        let fields = this.state.fields;         
        this.setState({errMsg:""})        
        
        fields["carServiceCompanyId"] = item["carServiceCompanyId"]
        fields["carServiceCompanyName"] = item["carServiceCompanyName"]
        fields["userTypeId"] = item["userTypeId"]   
        fields["isActive"] = item["isActive"]              
       
        this.setState({modal,fields,isDisabled:true});
          
    }

    handleNavigatePage= (e:any,item) => {

    }
    

    async getCarCompany(){
        let modal = this.state.modal;         
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        
        await carServiceMaintenanceController.getCarCompanyDetails().then(jsoncarServiceCompany => {
            this.setState({ carCompanyGroups: JSON.parse(JSON.stringify(jsoncarServiceCompany))});             
            //console.log(this.state.carCompanyGroups)  
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })         
    }

    async getUserTypeCodes(){
        let modal = this.state.modal;         
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        
        await carServiceMaintenanceController.getUserTypeCodes().then(jsonUserTypeCodes => {
            this.setState({ userTypeCodeGroups: JSON.parse(JSON.stringify(jsonUserTypeCodes))});             
            //console.log(this.state.userTypeCodeGroups)            
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })       
        
    }

    onSaveUpdate = (e:any) => {
        e.preventDefault();
         let modal = this.state.modal;
         let fields = this.state.fields;
         modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})        
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        if(this.validateSave())
        {
            if(fields["carServiceCompanyId"] === "")
            {
                var data={
                    doBy : "I",
                    carServiceCompanyId:"",
                    userTypeId : fields["userTypeId"],
                    carServiceCompanyName : fields["carServiceCompanyName"],                   
                    isActive : fields["isActive"]
                   // bcdVendorCode:null                  
                }
                carServiceMaintenanceController.inserUpdateCarCompany(data).then(response => { 
                    alert('Car Company Inserted');
                    carServiceMaintenanceController.getCarCompanyDetails().then(jsoncarServiceCompany => {
                        this.setState({ carCompanyGroups: JSON.parse(JSON.stringify(jsoncarServiceCompany))});             
                        //console.log(this.state.carCompanyGroups)  
                    }).catch(error => {
                        console.error("There was an error", error);
                        modal['modalSpinnerShow']=false;
                        this.setState({modal})
                    })
                }).catch(error => {
                    console.error("There was an error", error)
                    modal['modalSpinnerShow']=false;
                })
            }
            else 
            {
                var data1={
                    doBy : "U",
                    carServiceCompanyId:fields["carServiceCompanyId"],
                    userTypeId : "",
                    carServiceCompanyName : fields["carServiceCompanyName"],                   
                    isActive : fields["isActive"]
                    //bcdVendorCode:null                   
                }
                carServiceMaintenanceController.inserUpdateCarCompany(data1).then(response => { 
                    alert('Car Company Updated');
                    carServiceMaintenanceController.getCarCompanyDetails().then(jsoncarServiceCompany => {
                        this.setState({ carCompanyGroups: JSON.parse(JSON.stringify(jsoncarServiceCompany))});             
                        //console.log(this.state.carCompanyGroups)  
                    }).catch(error => {
                        console.error("There was an error", error);
                        modal['modalSpinnerShow']=false;
                        this.setState({modal})
                    })
                }).catch(error => {
                    console.error("There was an error", error)
                    modal['modalSpinnerShow']=false;
                })
            }                     
            this.clearText()
            //this.getCarCompany();          
        }  
        modal['modalSpinnerShow']=false;      
    }

    validateSave(){        
        let formIsValid = true;
        if(this.state.fields.carServiceCompanyId ==="" )
        {
            if(this.state.fields.userTypeId != "")
            {
                {this.state.userTypeCodeGroups.filter(p => p.userTypeId == this.state.fields.userTypeId).map(f => {
                    alert('User Type (ID) already exists.. Please choose another code(ID)');   
                    formIsValid = false      
                })} 
            }
            else{
                alert('Enter ID!!!');   
                    formIsValid = false
            }
                    
        }
        
        return formIsValid;
    }

    clearText(){
        let fields = this.state.fields;        
        fields["carServiceCompanyId"]=""
        fields["carServiceCompanyName"]=""
        fields["userTypeId"]=""
        fields["isActive"]=false        
        this.setState({fields})    
    }   

    handleTextChange(e:any){  
        let fields = this.state.fields;               
        if (e.currentTarget.id === "carServiceCompanyName"){
            fields[e.currentTarget.id] = e.currentTarget.value;  
            if(fields[e.currentTarget.id] === "")
            {
                fields["userTypeId"] =""
                fields["carServiceCompanyId"]=""
                this.setState({isDisabled:false});  
            }          
        }  
        if (e.currentTarget.id === "userTypeId") {
            fields[e.currentTarget.id] = e.currentTarget.value; 
        }          
        this.setState({fields});       
    }

    handleCheckboxChange(e:any){
        let fields = this.state.fields;        
        if (e.currentTarget.name === 'isActive'){
            fields['isActive'] = !fields['isActive'];
        }
                 
        this.setState({ fields});
    }  
   
    displayActiveCompanyName=(item)=>{
        return (<p className={(item["isActive"] ? "approverSmall" : "approverSmallIsActive")}>
        {item["carServiceCompanyName"]}</p>);   
    }

    displayActiveUserTypeId=(item)=>{
        return (<p className={(item["isActive"] ? "approverSmall" : "approverSmallIsActive")}>
                 {item["userTypeId"]}</p>);          
    }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
        const tdStylechk ={
            width:'3%',            
            border: "2px solid #eee",
            padding:"2px" 
        };  
        const tdStyleChk1 ={
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };       
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };
        const thStyleChk ={
            width:'3%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleChk1 ={
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Sysop - Car Service Maintenance</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                   <Row>
                                        <Col sm={2}>
                                            <FormInputs
                                                properties={{id:"carServiceCompanyName",label:"Company Name",type:"text",name:"carServiceCompanyName",value:this.state.fields.carServiceCompanyName,className:"form-control input-sm",onChange: this.handleTextChange }}
                                                errorMsg={this.state.errors.carServiceCompanyName}
                                                fieldRequired={false}
                                                disbled={true}
                                            />
                                        </Col>  
                                        <Col sm={2}>
                                            {this.state.isDisabled ?
                                                <FormInputs
                                                    properties={{id:"userTypeId",label:"ID",type:"text",name:"userTypeId",maxlength:4,value:this.state.fields.userTypeId,className:"form-control input-sm"}}
                                                    errorMsg={this.state.errors.userTypeId}
                                                    fieldRequired={false}
                                                    disbled={true}
                                                /> :
                                                <FormInputs
                                                    properties={{id:"userTypeId",label:"ID",type:"text",name:"userTypeId",maxlength:4,value:this.state.fields.userTypeId,className:"form-control input-sm",onChange: this.handleTextChange}}
                                                    errorMsg={this.state.errors.userTypeId}
                                                    fieldRequired={false}
                                                    disbled={true}
                                                />
                                            }
                                        </Col> 
                                        <Col sm={1} style={{alignSelf: "flex-end"}}>
                                            <FormGroup>
                                                <Form.Label>Active &nbsp;</Form.Label>
                                                <input type="checkbox" name="isActive" checked={this.state.fields.isActive} defaultChecked={this.state.fields.isActive} onChange={this.handleCheckboxChange.bind(this)} />
                                            </FormGroup>
                                        </Col>                                     
                                        <Col sm={6} style={{paddingTop:"2.5%"}}>
                                            <Button id="save" type="button" size="sm" style={{ marginRight: "5px"}}  disabled={loading} onClick={(e) => this.onSaveUpdate(e)} variant="success" >
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Add/Update</span>}
                                            </Button>
                                            <Button disabled={this.state.loading} size="sm" name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Refresh </span>}
                                            </Button>
                                            <Button disabled={this.state.loading} size="sm" name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Print </span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>                                        
                                       <Col sm={10}>
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th style={thStyle}>Navigate</th>
                                                                <th style={thStyle}>Company Name</th>
                                                                <th style={thStyle}>ID</th>
                                                                <th style={thStyleChk}>Active</th>  
                                                                <th style={thStyleChk1}>Process</th>                                                      
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.carCompanyGroups.map((prop, key) => {
                                                                return (
                                                                    <tr style={{textAlign:"center"}} key={key} > 
                                                                       <td style={tdStyle}><Button type="button" style={{marginLeft:'5px'}} onClick={() => {this.props.history.push({pathname: '/admin/carserviceaddedit',state: { companyId:prop["carServiceCompanyId"],companyName:prop["carServiceCompanyName"] }})}}>....</Button></td>                                                                                                                         
                                                                       <td style={tdStyle}>{this.displayActiveCompanyName(prop)}</td>
                                                                       <td style={tdStyle}>{this.displayActiveUserTypeId(prop)}</td>
                                                                       <td style={tdStylechk}><input type="checkbox" name="isActive" defaultChecked={prop["isActive"]} disabled={true}/></td>
                                                                       <td style={tdStyleChk1}>
                                                                       <tr>
                                                                            <td style={{borderTop:"0px"}}>
                                                                                <Button id="edit" type="button" size="sm" disabled={loading} onClick={(e) => this.onEdit(e,prop)} >
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Edit</span>}
                                                                                </Button>
                                                                            </td>                                                                            
                                                                        </tr>
                                                                       </td>
                                                                    </tr>
                                                                    
                                                                )
                                                            })}                                                            
                                                        </tbody>
                                                </Table>                                                        
                                            </div>
                                        

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}