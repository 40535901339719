import React, { Component } from "react";
import {History, LocationState} from "History";
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,NavItem } from "react-bootstrap";
import {AdminNavbarLinks} from "../NabvBar/AdminNavbarLinks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt,faGlobe,faUser,faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { LogoutController } from "../../../Controllers/LogoutController";
import etalogosm from "../../../assets/img/eta_blue.png";
import umglogosm from "../../../assets/img/umglogo.png";
import GLOBAL from "../../../js/global";
import { allowedNodeEnvironmentFlags } from "process";
import { CommonController } from "../../../Controllers/CommonController";

export class AdminNavbar extends Component<IAdminNavbar,Istate> {
    
  constructor(objHeader:IAdminNavbar) {
    super(objHeader)
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({sidebarExists:true});
    }
    e.preventDefault();
    ((document as Document).documentElement as HTMLElement).classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    (node as HTMLElement).onclick=function(e:any){
        var targetElement:HTMLElement=((e as Event).target as HTMLElement);
        (targetElement.parentElement as HTMLElement).removeChild(targetElement);
        ((document as Document).documentElement as HTMLElement).classList.toggle("nav-open");
      }
    document.body.appendChild(node);
  }
  
  myHandler(e) { 
    e.persist(); 
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation(); 
  }

  async logoutHandler (e : any) {
    /* UnLocking TA for the current users */
   
    let comController = new CommonController();
    var data = {
      authreqid: sessionStorage.getItem("ta"),
      userid: sessionStorage.getItem("uid"),
      shadowid: sessionStorage.getItem("shadow"),
      check: ""
    }
    comController.updateUnLockAuthreq(data).then(response => {

    }).catch(error => {
      console.error("There was an error!", error)
    })
    
    let userAgentApplication:any = GLOBAL.userAgent;
    let logoutController = new LogoutController();
    if(userAgentApplication !== undefined){
       await userAgentApplication.logout();
       await logoutController.logoutUser(sessionStorage.getItem("adID")!,"");
       alert("Page is getting loggedout");
    }
    else
    {
      await logoutController.logoutUser(sessionStorage.getItem("adID")!,"");
    }

  }
 
  redirectToSelectRole=()=>{    
    this.props.history.push('/admin/selectrole');
  }
  render() {
    const styles = {
      headerBGColor: {
        paddingRight:"5px",
        color: '#6c7293',
        fontSize:"12px",
        fontWeight: 600,
        cursor: "default"
      },
      anchorBGColor: {
        paddingRight:"20px",
        //color: '#3F4254',
        fontSize:"12px"
      },
      anchorBGColorBlue: {
        paddingRight:"20px",
        color: '#00B0F0',
        fontSize:"12px"
      }
    }
    const displaySelectUserProfile =()=>{
      if (sessionStorage.getItem("adID") !== null && sessionStorage.getItem("uid") !== null && sessionStorage.getItem("multirole") == "1"){
        return <Nav.Link eventKey={2} title="Click to go to Profile Selection" href="#" style={styles.anchorBGColor} onClick={() => this.redirectToSelectRole()}>Select User Profile</Nav.Link>
      }
      //return '';  
    }
    return (
      <Navbar expand="lg" style={{ backgroundColor: "#2F2F2F"}}>
        <div >
        {<div style={{color:"#FFF"}}>
            <img height="75px" src={`${etalogosm}`}></img>
            </div>}
        </div>
        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-end link" style={{ width: "100%",height:"62px" }}>
            {displaySelectUserProfile()}
            <Nav.Link href="#" style={styles.headerBGColor} onClick={this.myHandler}><FontAwesomeIcon icon={faUser} style={styles.headerBGColor} size="2x" /><span style={{color:"#FFF",fontSize:"14px"}}>{sessionStorage.getItem("name")}</span></Nav.Link>
            <Nav.Link eventKey={2} title="Click to Log Out" href="/" style={styles.anchorBGColor} onClick={this.logoutHandler}><FontAwesomeIcon icon={faSignOutAlt} style={styles.headerBGColor} size="2x"/>
              Logout
            </Nav.Link>
            <Nav.Link eventKey={2} title="Click for Help" href="../../../help/eTAHelp.pdf" target="_blank" style={styles.anchorBGColor}>Help</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

 interface IAdminNavbar
 {
    brandText?:string;
    history?:History<LocationState>;
 }
interface Istate
{
    sidebarExists:boolean;
}