import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class TrackingGridController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    getTravellerSearch(data: any){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.trackinggrid + this.variables.trackinggridService.getTravellerSearch;
        var data1 = {
            "reqsWhere": encodeURIComponent(data.sWhere)
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("getTravellerSearch", response);
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }
    
    getApprover(data:any){
        //debugger;
        var url = this.variables.Url+ this.variables.Controller.trackinggrid + this.variables.trackinggridService.getApprover;
        var data1 = {
            "authreq_id": data.authreq_id,
            "iTier": data.iTier,
            "tracking_grid": data.tracking_grid,
            "label_id": data.label_id,
            "expense_type_id": data.expense_type_id,
            "department_id": data.department_id
        }
        return this.serviceProxy.get(url,data1)
            .then(responseApprover => {
                //debugger;
                if(responseApprover.success){
                    //console.log("getApprover", responseApprover);
                    return responseApprover;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            });  
    }
}