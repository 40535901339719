import React, { Component, CSSProperties } from "react";
import { ILabelMaintenanceParameter, Istate } from "../../../Model/ILabelMaintenance";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {FormInputs} from '../../CustomComponents/FormInput';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { LabelMaintenanceController } from "../../../Controllers/LabelMaintenanceController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { Variables } from '../../../Variables/Variables';
import { createKeywordTypeNode } from "typescript";
///added css in style line 279 Very inportant
export class LabelMaintenanceView extends Component<ILabelMaintenanceParameter,Istate> {
    constructor(props:ILabelMaintenanceParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {labelId:'',description:'',environNumber:'',opCode:'',shortName:'',travelCount:'',
                    isActive:false,hasDha:false,transFee:false,inPolicy:false,travelForm:false,oldOpCode:''},
            errors:{labelId:'',travelCount:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:"",
            labelGroups:[],
            labelUpdate:[],
            collLablel:"",
            loadComplete:false           
        }  
        this.onSubmit = this.onSubmit.bind(this);  
        //this.handleChange = this.handleChange.bind(this);    
    }

    async componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;      
        let labelMaintenanceController = new LabelMaintenanceController();
        await labelMaintenanceController.getAllLabels().then(jsonGetLabels => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonGetLabels))}); 
            this.setState({loadComplete:true})
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal}); 
            console.error("There was an error!", error);
        })
        modal['modalSpinnerShow']=false;
       this.setState({ modal}); 
    }

    onSubmit(e:any){
       e.preventDefault();
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})        
        let labelMaintenanceController = new LabelMaintenanceController();
        if(this.validateLable())
        {
            this.state.collLablel.split(',').map(str => {
                {this.state.labelGroups.filter(p => p.labelid == str).map(f => {
                    var data = {
                        doBy: "U",
                        labelId: f.labelid,
                        description: f.description,
                        environNumber: f.environNumber,
                        opCode: f.opCode,
                        shortName: f.shortName,
                        travelCount: f.travelCount,
                        isActive: f.isActive,
                        hasDha:f.hasDHA,
                        transFee:f.transFee,
                        inPolicy: f.inPolicy,
                        travelForm:f.travelForm,
                        oldOpCode:f.oldOpCode   
                    }
                    labelMaintenanceController.createUpdateLabel(data).then(response => {                   
                    }).catch(error => {
                        console.error("There was an error!", error)
                    })
                })}               
            }); 
            if(this.state.fields.labelId === "")
            {
                modal['modalSpinnerShow']=false;
                this.setState({modal});
                alert('Data Saved..');
                this.props.history.push({pathname:'/admin/home'}); 
            } 
            else
            {           
                var dataLbl = {
                    doBy: "I",
                    labelId: this.state.fields.labelId,
                    description: this.state.fields.description,
                    environNumber: this.state.fields.environNumber,
                    opCode: this.state.fields.opCode,
                    shortName: this.state.fields.shortName,
                    travelCount: this.state.fields.travelCount,
                    isActive: this.state.fields.isActive,
                    hasDha:this.state.fields.hasDha,
                    transFee:this.state.fields.transFee,
                    inPolicy: this.state.fields.inPolicy,
                    travelForm:this.state.fields.travelForm,
                    oldOpCode:this.state.fields.oldOpCode   
                }
                labelMaintenanceController.createUpdateLabel(dataLbl).then(response => { 
                    modal['modalSpinnerShow']=false;
                    this.setState({modal});
                    alert('Data Saved..');
                    this.props.history.push({pathname:'/admin/home'}); 
                }).catch(error => {
                    console.error("There was an error!", error)
                })            
            }
        }
        modal['modalSpinnerShow']=false;
        this.setState({modal});     
    }

    validateLable(){        
        let fields = this.state.fields;
        let modal = this.state.modal;
        let isLabel = false;
        let isDesc = false;
        let isOOpCode = false;
        let isValidLabel=false;
        var errorMessage="";
        var labelregex = new RegExp("^[a-zA-Z0-9 ]{3,10}$");
        let formIsValid = true;
        if(fields["labelId"] != "")
        {        
            {this.state.labelGroups.filter(p => p.labelid == fields["labelId"]).map(f => {
                isValidLabel=true;
                formIsValid = false;                
                modal['modalSpinnerShow']=false;  
            })}        

           if (!labelregex.test(fields["labelId"])) {
                formIsValid = false;
                isLabel =true;               
                modal['modalSpinnerShow']=false;         
            }
            if (!fields["description"]) {
                formIsValid = false;
                isDesc=true;                
                modal['modalSpinnerShow']=false; 
            }
            if (!fields["oldOpCode"]) {
                formIsValid = false;
                isOOpCode =true;               
                modal['modalSpinnerShow']=false; 
            }
            
            if ( isValidLabel || isLabel || isDesc || isOOpCode )  
            {
                if (isValidLabel)
                {
                    var errorMessage="Label Id is dupicated. Please choose different ID.";
                }
                else{
                    var errorMessage="Please enter ";
                    if(isLabel)
                        errorMessage = errorMessage+"label ID must be min of 3 character, "
                    if(isDesc)
                        errorMessage = errorMessage+"label, "
                    if(isOOpCode)
                        errorMessage = errorMessage+"old HFM entity"
                }
                errorMessage = errorMessage.replace(/,\s*$/, "")                
            }            
            this.setState({errMsg : errorMessage});
        }
        else
        {
            formIsValid = true;             
        }
        return formIsValid;
    }


    handleDescChange(i, event) {
       let collLal =this.state.collLablel;
        let labelGroups = [...this.state.labelGroups];        
        labelGroups[i]["description"] = event.target.value;
        this.setState({ labelGroups });
        if(collLal != "")
        {
            if(!collLal.includes(labelGroups[i]["labelid"]))
            collLal = collLal + labelGroups[i]["labelid"]+","
        }
        else
        collLal = labelGroups[i]["labelid"]+","

        this.setState({ collLablel:collLal });
     }

     handleEnvChange(i, event) {
        var numNcommaregex =new RegExp("^[0-9,]{0,30}$")
        var collLal =this.state.collLablel;
        let labelGroups = [...this.state.labelGroups];   
        if (event.target.value === '' || numNcommaregex.test(event.target.value)) {
            labelGroups[i]["environNumber"] = event.target.value;
        }    
        this.setState({ labelGroups });
        if(collLal != "")
        {
            if(!collLal.includes(labelGroups[i]["labelid"]))
            collLal = collLal + labelGroups[i]["labelid"]+","
        }
        else
        collLal = labelGroups[i]["labelid"]+","

        this.setState({ collLablel:collLal });
     }

     handleOpCodeChange(i, event) {
        let collLal =this.state.collLablel;
         let labelGroups = [...this.state.labelGroups];        
         labelGroups[i]["opCode"] = event.target.value;
         this.setState({ labelGroups });
         if(collLal != "")
         {
             if(!collLal.includes(labelGroups[i]["labelid"]))
             collLal = collLal + labelGroups[i]["labelid"]+","
         }
         else
         collLal = labelGroups[i]["labelid"]+"," 
         this.setState({ collLablel:collLal });
      }

      handleTravelCountChange(i, event) {
         var numregex = new RegExp("^[0-9\b]+$"); 
         let collLal = this.state.collLablel;
         let labelGroups = [...this.state.labelGroups]; 
         if (event.target.value === '' || numregex.test(event.target.value)) {
            labelGroups[i]["travelCount"] = event.target.value;
        }       
         
         this.setState({ labelGroups });
         if(collLal != "")
         {
             if(!collLal.includes(labelGroups[i]["labelid"]))
             collLal = collLal + labelGroups[i]["labelid"]+","
         }
         else
         collLal = labelGroups[i]["labelid"]+"," 
         this.setState({ collLablel:collLal });
      }

      handleOldOpCodeChange(i, event) {
         var numregex = new RegExp("^[0-9\b]+$"); 
         let collLal = this.state.collLablel;
         let labelGroups = [...this.state.labelGroups];    
         if (event.target.value === '' || numregex.test(event.target.value)) {
            labelGroups[i]["oldOpCode"] = event.target.value;
        }    
         this.setState({ labelGroups });
         if(collLal != "")
         {
             if(!collLal.includes(labelGroups[i]["labelid"]))
             collLal = collLal + labelGroups[i]["labelid"]+","
         }
         else
         collLal = labelGroups[i]["labelid"]+"," 
         this.setState({ collLablel:collLal });
      }

      
      handleCheckIsActiveChange(i, event) {
            let collLal = this.state.collLablel;
            let labelGroups = [...this.state.labelGroups];        
            labelGroups[i]["isActive"] = !labelGroups[i]["isActive"]
            this.setState({ labelGroups });
            if(collLal != "")
            {
                if(!collLal.includes(labelGroups[i]["labelid"]))
                collLal = collLal + labelGroups[i]["labelid"]+","
            }
            else
            collLal = labelGroups[i]["labelid"]+","    
            this.setState({ collLablel:collLal });
       }

       handleCheckDHAChange(i, event) {
            let collLal = this.state.collLablel;
            let labelGroups = [...this.state.labelGroups];        
            labelGroups[i]["hasDHA"] = !labelGroups[i]["hasDHA"]
            this.setState({ labelGroups });
            if(collLal != "")
            {
                if(!collLal.includes(labelGroups[i]["labelid"]))
                collLal = collLal + labelGroups[i]["labelid"]+","
            }
            else
            collLal = labelGroups[i]["labelid"]+","
            this.setState({ collLablel:collLal });
        }

        handleCheckTransFeeChange(i, event) {
            let collLal = this.state.collLablel;
            let labelGroups = [...this.state.labelGroups];        
            labelGroups[i]["transFee"] = !labelGroups[i]["transFee"]
            this.setState({ labelGroups });
            if(collLal != "")
            {
                if(!collLal.includes(labelGroups[i]["labelid"]))
                collLal = collLal + labelGroups[i]["labelid"]+","
            }
            else
            collLal = labelGroups[i]["labelid"]+","
            this.setState({ collLablel:collLal });
        }

        handleCheckInPolicyChange(i, event) {
            let collLal = this.state.collLablel;
            let labelGroups = [...this.state.labelGroups];        
            labelGroups[i]["inPolicy"] = !labelGroups[i]["inPolicy"]
            this.setState({ labelGroups });
            if(collLal != "")
            {
                if(!collLal.includes(labelGroups[i]["labelid"]))
                collLal = collLal + labelGroups[i]["labelid"]+","
            }
            else
            collLal = labelGroups[i]["labelid"]+","
            this.setState({ collLablel:collLal });
        }

        handleCheckTravelFormChange(i, event) {
            let collLal = this.state.collLablel;
            let labelGroups = [...this.state.labelGroups];        
            labelGroups[i]["travelForm"] = !labelGroups[i]["travelForm"]
            this.setState({ labelGroups });
            if(collLal != "")
            {
                if(!collLal.includes(labelGroups[i]["labelid"]))
                collLal = collLal + labelGroups[i]["labelid"]+","
            }
            else
            collLal = labelGroups[i]["labelid"]+","

            this.setState({ collLablel:collLal });
        }

        handleChange(e:any){
            let fields = this.state.fields;
            let errors = this.state.errors;
            var labelregex = new RegExp("^[a-zA-Z0-9 ]{0,10}$");
            var numregex = new RegExp("^[0-9\b]+$"); 
            var numNcommaregex =new RegExp("^[0-9,]{0,30}$")
            if (e.currentTarget.name === 'labelId'){
                if (e.currentTarget.value === '' || labelregex.test(e.currentTarget.value)) {
                    fields['labelId'] =  e.currentTarget.value;
                }  
            }
            if (e.currentTarget.name === 'description'){
                fields['description'] = e.currentTarget.value;
            }
            if (e.currentTarget.name === 'environNumber'){
                if (e.currentTarget.value === '' || numNcommaregex.test(e.currentTarget.value)) {
                    fields['environNumber'] = e.currentTarget.value;
                }                
            }
            if (e.currentTarget.name === 'opCode'){                
                fields['opCode'] = e.currentTarget.value;
            }
            if (e.currentTarget.name === 'travelCount'){
                if (e.currentTarget.value === '' || numregex.test(e.currentTarget.value)) {
                    fields['travelCount'] = e.currentTarget.value;
                }                 
            }
            if (e.currentTarget.name === 'oldOpCode'){
                if (e.currentTarget.value === '' || numregex.test(e.currentTarget.value)) {
                    fields['oldOpCode'] = e.currentTarget.value;
                }                
            }            
            this.setState({ fields,errors });
        }

        handleCheckboxChange(e:any){
            let fields = this.state.fields;
            let errors = this.state.errors;
            if (e.currentTarget.name === 'isActive'){
                fields['isActive'] = !fields['isActive'];
            }
            if (e.currentTarget.name === 'hasDha'){
                fields['hasDha'] = !fields['hasDha'];
            }
            if (e.currentTarget.name === 'transFee'){
                fields['transFee'] = !fields['transFee'];
            }
            if (e.currentTarget.name === 'travelForm'){
                fields['travelForm'] = !fields['travelForm'];
            }
            if (e.currentTarget.name === 'inPolicy'){
                fields['inPolicy'] = !fields['inPolicy'];
            }
                       
            this.setState({ fields,errors });
        }

      

    displayActiveLabel=(item)=>{
       return (<p className={(item["isActive"] === true ? "approverSmall" : "approverSmallIsActive")}>
                {item["labelid"]}</p>);          
    }

    render() {
        const{ loading } = this.state;
         const alignContentTdbilling = {
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const tdStyleHFM = {
            width:'15%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };
        const tdStyleDet = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
        const tdStylechk ={
            width:'3%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const alignContentTd = {
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px",
            paddingBottom:'0px'
        };
        const thStyle ={
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleDet ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleHFM ={
            width:'15%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStylechk ={
            width:'3%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        let CkhValue="";
        let CkhRecValue="";
        let CkhOverheadValue="";
        let CkhIntnatValue="";
        let CkhUltRecValue="";
        let CkhUltMarketingValue="";
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Label Maintenance</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row>
                                        <Col sm={12} style={{textAlign:"center", marginBottom:"15px"}}>
                                            <Button size="sm" disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Refresh </span>}
                                            </Button>
                                            <Button size="sm" disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Print </span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                       <Col sm={12}>
                                       <div>
                                       <Table hover>
                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                    <tr style={{textAlign:"center"}}>
                                                        <th style={thStyle}>Label ID</th>
                                                        <th style={thStyleDet}>Label</th>
                                                        <th style={thStyleHFM}>Environ #</th>
                                                        <th style={thStyleHFM}>HFM Entity</th>
                                                        <th style={thStylechk}>Active</th>
                                                        <th style={thStylechk}>DHA</th>
                                                        <th style={thStylechk}>Trans Fee</th>
                                                        <th style={thStylechk}>In Policy</th>
                                                        <th style={thStylechk}>Travel Form</th>
                                                        <th style={thStyle}>YTD Traveler Limit</th>
                                                        <th style={thStyle}>Old HFM Entity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.labelGroups.map((prop, key) => {
                                                        return (
                                                            <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                <td style={alignContentTd}>{this.displayActiveLabel(prop)}</td>
                                                                <td style={tdStyleDet}><input type="text" style={{width:'100%'}} value={prop["description"]||''} onChange={this.handleDescChange.bind(this, key)}/></td>
                                                                <td style={tdStyleHFM}><input type="text" value={prop["environNumber"]||''} onChange={this.handleEnvChange.bind(this, key)}/></td>
                                                                <td style={tdStyleHFM}><input type="text" value={prop["opCode"]||''} onChange={this.handleOpCodeChange.bind(this, key)}/></td>
                                                                <td style={tdStylechk}>
                                                                    <input type="checkbox" checked={prop["isActive"]} onChange={this.handleCheckIsActiveChange.bind(this, key)}/>
                                                                </td>
                                                                <td style={tdStylechk}>
                                                                    <input type="checkbox" checked={prop["hasDHA"]} onChange={this.handleCheckDHAChange.bind(this, key)}/>
                                                                </td>
                                                                <td style={tdStylechk}>
                                                                    <input type="checkbox" checked={prop["transFee"]} onChange={this.handleCheckTransFeeChange.bind(this, key)}/>
                                                                </td>
                                                                <td style={tdStylechk}>
                                                                    <input type="checkbox" checked={prop["inPolicy"]} onChange={this.handleCheckInPolicyChange.bind(this, key)}/>
                                                                </td>
                                                                <td style={tdStylechk}>
                                                                    <input type="checkbox" checked={prop["travelForm"]} onChange={this.handleCheckTravelFormChange.bind(this, key)}/>
                                                                </td>
                                                                <td style={alignContentTd}><input type="text" style={{width:'50%'}} maxLength={3} value={prop["travelCount"]||''} onChange={this.handleTravelCountChange.bind(this, key)}/></td>
                                                                <td style={alignContentTd}><input type="text" style={{width:'50%'}} maxLength={3} value={prop["oldOpCode"]||''} onChange={this.handleOldOpCodeChange.bind(this, key)}/></td>
                                                               </tr>
                                                        )
                                                     })}
                                                     {this.state.loadComplete ? 
                                                     <tr style={{textAlign:"center"}}>
                                                        <td style={alignContentTd}><input type="text" name="labelId" value={this.state.fields.labelId} onChange={this.handleChange.bind(this)}/></td>
                                                        <td style={tdStyleDet}><input type="text" name="description" style={{width:'100%'}} value={this.state.fields.description} onChange={this.handleChange.bind(this)}/></td>
                                                        <td style={tdStyleHFM}><input type="text" name="environNumber" value={this.state.fields.environNumber} onChange={this.handleChange.bind(this)}/></td>
                                                        <td style={tdStyleHFM}><input type="text" name="opCode" value={this.state.fields.opCode} onChange={this.handleChange.bind(this)}/></td>
                                                        <td style={tdStylechk}>
                                                            <input type="checkbox" name="isActive" checked={this.state.fields.isActive} onChange={this.handleCheckboxChange.bind(this)}/>
                                                        </td>
                                                        <td style={tdStylechk}>
                                                            <input type="checkbox" name="hasDha" checked={this.state.fields.hasDha} onChange={this.handleCheckboxChange.bind(this)}/>
                                                        </td>
                                                        <td style={tdStylechk}>
                                                            <input type="checkbox" name="transFee" checked={this.state.fields.transFee} onChange={this.handleCheckboxChange.bind(this)}/>
                                                        </td>
                                                        <td style={tdStylechk}>
                                                            <input type="checkbox" name="inPolicy" checked={this.state.fields.inPolicy} onChange={this.handleCheckboxChange.bind(this)}/>
                                                        </td>
                                                        <td style={tdStylechk}>
                                                            <input type="checkbox"  name="travelForm" checked={this.state.fields.travelForm} onChange={this.handleCheckboxChange.bind(this)}/>
                                                        </td>
                                                        <td style={alignContentTd}><input type="text" name="travelCount" maxLength={3} style={{width:'50%'}} value={this.state.fields.travelCount} onChange={this.handleChange.bind(this)}/></td>
                                                        <td style={alignContentTd}><input type="text" name="oldOpCode" maxLength={3} style={{width:'50%'}} value={this.state.fields.oldOpCode} onChange={this.handleChange.bind(this)}/></td>
                                                     </tr> : ""}
                                                </tbody>
                                        </Table>                                                        
                                        </div>
                                        <div style={{textAlign:"center"}}>
                                        {this.state.loadComplete ? <p style={{textAlign:"left",color:"red",fontSize:"10px"}}>
                                         *Please enter label Id,label(description) and old HFM entity to add new label</p>:""}
                                            <Button id="submitLabel" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)} variant="success">
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Save</span>}
                                            </Button>
                                        </div>

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>                                                                                                        
                                            </div>
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}