import React, { Component, CSSProperties } from "react";
import { IDirectBillReportSubmitPaymentParamter, Istate } from "../../../Model/IDirectBillReportSubmitPayment";
import { FormLabel, Container, Row, Col, Form, Alert, Button, Card, NavLink, FormControl, Table, InputGroup, FormGroup } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { DirectBillReportController } from "../../../Controllers/DirectBillReportController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import addSubtractDate from 'add-subtract-date';
import * as Utils from '../../../js/Utils.js';
import * as Constants from "../../../js/Constants";
import { SearchBoxDBReport } from "../../CustomComponents/SearchBoxDBReport";
import { isEmptyStatement, textChangeRangeIsUnchanged } from "typescript";
import { ChangeEvent } from 'react';
import { History, LocationState } from "History";

export class DirectBillReportSubmitPayment extends Component<IDirectBillReportSubmitPaymentParamter, Istate> {
    constructor(props: IDirectBillReportSubmitPaymentParamter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")

        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: {},
            selectfields: { originpathname: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1), labelId: '', labelName: '', expenseTypeId: '', expenseTypeName: '', vendorTypeId: '' },
            submitPaymentSearch: [],
            submitPayment: [],
            labelGroups: [],
            expenseTypeGroups: [],
            vendorTypeGroups: [],
            labelId: "",
            labelName: "",
            expenseTypeId: "",
            expenseTypeName: "",
            chkDirectList: "",
            errors: {},
            errMsg: "",
            loggedIn,
            currentSort: 'down',
            currentColumn: 'authReqId',
            loading: false,
            modal: { modalSpinnerShow: false },
        }

        this.onSaveUpdate = this.onSaveUpdate.bind(this);
    }

    componentWillMount() {
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        var date = new Date();
        date.setDate(date.getDate() - this.state.fields.searchDays);
        fields["beginDate"] = date;
        this.getLabel();
        this.getExpenseType();
        this.setState({ fields })
        this.pageLoad();
    }
    async pageLoad() {

        
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        //        this.setState({ modal });
        sessionStorage.setItem("frompage", Constants.dbrsubmitPayment);
        let directbillreportController = new DirectBillReportController();

        var dbsubmitdata = {

            uid: sessionStorage.getItem("uid"),
            labelID: this.state.selectfields.labelId,
            expensetypeID: this.state.selectfields.expenseTypeId,
            dbView: this.state.selectfields.originpathname,
            vendorType: this.state.selectfields.vendorTypeId
        }

        // //console.log('dbsubmitteddata:', dbsubmitdata);
        await directbillreportController.getDirectBillReportSubmitPayment(dbsubmitdata).then(jsonsubmitpayment => {
            this.setState({
                submitPayment: JSON.parse(JSON.stringify(jsonsubmitpayment))
            });
            //console.log('dbsubmitdata:', this.state.submitPayment);
            //            this.setState({ modal });

        }).catch(error => {
            console.error("There was an error...," + error)
        });
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
    }

    formatCurrency = (amount) => {
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    getLabel() {
        //
        let modal = this.state.modal;
        let directbillreportController = new DirectBillReportController();
        directbillreportController.getAllLabels().then(jsonGetLabels => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonGetLabels)) });
            //console.log(this.state.labelGroups)
            // this.setState({loadComplete:true})
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow'] = false;
        })
        this.setState({ modal })
    }

    getExpenseType() {

        // 
        let modal = this.state.modal;
        let directbillreportController = new DirectBillReportController();
        directbillreportController.getExpenseType().then(jsonGetExpenseType => {
            this.setState({ expenseTypeGroups: JSON.parse(JSON.stringify(jsonGetExpenseType)) });
            //console.log(this.state.expenseTypeGroups)
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow'] = false;
        })
        modal['modalSpinnerShow'] = false;
        this.setState({ modal })
    }

    handleSelectChange(e) {
        
        let selectfields = this.state.selectfields;

        if (e.currentTarget.id === 'labelId') {
            if (e.currentTarget.value !== "All") {
                selectfields["labelId"] = e.currentTarget.value;
                {
                    this.state.labelGroups.filter(p => p.id == e.currentTarget.value).map(f => {
                        selectfields["labelName"] = f.name;
                        //console.log(f.name)
                    })
                }
                this.setState({ labelId: e.currentTarget.value })
            }
            else {
                selectfields["labelId"] = ""
                selectfields["labelName"] = "";
            }
        }

        if (e.currentTarget.id === "expenseTypeId") {
            if (e.currentTarget.value !== "All") {
                selectfields[e.currentTarget.id] = e.currentTarget.value;
                {
                    this.state.expenseTypeGroups.filter(p => p.id == e.currentTarget.value).map(f => {
                        selectfields["expenseTypeName"] = f.name;
                        //console.log(f.name)
                    })
                }

            }
            else {
                selectfields["expenseTypeId"] = "";
                selectfields["expenseTypeName"] = "";
            }
        }

        if (e.currentTarget.id === "vendorTypeId") {
            if (e.currentTarget.value !== "All") {
                selectfields[e.currentTarget.id] = e.currentTarget.value;
            }
            else
                selectfields[e.currentTarget.id] = "";
        }

        this.setState({ selectfields });
        this.pageLoad();
    }

    formAllLabel = () => {
        return <Form.Group>
            <Form.Label></Form.Label>
            <Form.Control as="select" className="form-control form-control-sm" id="labelId" name="labelName" defaultValue="All" value={this.state.selectfields.labelId} onChange={this.handleSelectChange.bind(this)}>
                <option value="All">All</option>
                {this.state.labelGroups.map((prop, key) => {
                    if (prop["isActive"]) {
                        return (
                            <option value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                    else {
                        return (
                            <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                })}
            </Form.Control>
        </Form.Group>
    }

    formAllExpenseType = () => {
        return <Form.Group>
            <Form.Label></Form.Label>
            <Form.Control as="select" className="form-control form-control-sm" id="expenseTypeId" name="expenseTypeName" defaultValue="All" value={this.state.selectfields.expenseTypeId} onChange={this.handleSelectChange.bind(this)}>
                <option value="All">All</option>
                {this.state.expenseTypeGroups.map((prop, key) => {
                    if (prop["isActive"]) {
                        return (
                            <option value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                    else {
                        return (
                            <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                })}
            </Form.Control>
        </Form.Group>
    }

    formAllVendorType = () => {
        return <Form.Group>
            <Form.Label></Form.Label>
            <Form.Control as="select" className="form-control form-control-sm" id="vendorTypeId" name="vendorTypeId" defaultValue="All" value={this.state.selectfields.vendorTypeId} onChange={this.handleSelectChange.bind(this)}>
                <option value="All">All</option>
                <option value="1">Corp Card / 328063</option>
                <option value="2">Non Corporate Card</option>
            </Form.Control>
        </Form.Group>
    }

    handlecheckboxchange = (e: ChangeEvent<HTMLInputElement>) => {
        

        let ischk = e.target.checked;
        let dbid = e.target.value;
        let chkdbill = this.state.chkDirectList;

        if (ischk === true) {
            if (chkdbill !== "") {
                if (!chkdbill.includes(dbid))
                    chkdbill = chkdbill + dbid + ","
            }
            else
                chkdbill = dbid + ","
        }
        else {
            if (chkdbill.search(dbid) > 0) {
                chkdbill = chkdbill.replace(dbid + ",", "")
            }
            else
                chkdbill = dbid + ","
        }
        this.setState({ chkDirectList: chkdbill });
    }

    onSaveUpdate = (e: any) => {
        
        if (this.state.chkDirectList !== "") {
            // e.preventDefault();
            let modal = this.state.modal;
            modal['modalSpinnerShow'] = true;
            this.setState({ errMsg: "" })
            let directbillreportController = new DirectBillReportController();
            this.state.chkDirectList.split(',').map(dbvalue => {
                {
                    this.state.submitPayment.filter(p => p.directbillid == dbvalue).map(f => {
                        var data = {
                            uid: sessionStorage.getItem("uid"),
                            directbillid: dbvalue
                        }
                        directbillreportController.updatesubmitpayment(data).then(response => {
                            this.setState({ chkDirectList: "" })
                            alert('Submitted Payment Updated..');
                            modal['modalSpinnerShow'] = false;
                            this.setState({ modal });
                            window.location.reload();

                        }).catch(error => {
                            console.error("There was an error!", error)
                        })
                    })
                }
            });
        }
        else
        {
            alert("Please select atleast one Vendor")
        }
    }

    render() {
        //
        const { loading } = this.state;
        var prevLabelID = "";
        var prevExpenseTypeID = "";

        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%' }}>
                                <div className="header"><h2 className="title" style={{ textAlign: 'center' }}>eTA Travel Payment System</h2></div>
                                <div className="header"><p style={{ textAlign: 'center', marginTop: '-12px' }}>{Utils.FormatTodayDate()}</p></div>
                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                    {(this.state.submitPayment.length > 0) ?
                                        <div>
                                            <Row>
                                                <Col sm={12}>
                                                    {this.state.submitPayment.map((prop, key) => {
                                                        //

                                                        if (prevLabelID != prop["labelid"]) {
                                                            prevLabelID = prop["labelid"];
                                                            let i = 0;
                                                            prevExpenseTypeID = "";
                                                            return (<div>
                                                                <Row style={{ marginTop: "1%" }}>
                                                                    <Col>
                                                                        <Row><Col style={{ fontSize: "20px" }}> Submit {(this.state.selectfields.originpathname == "Hotel") ? "Hotel" : "Rental Car"} for Payment </Col></Row>
                                                                        <Row><Col sm={3} style={{ fontWeight: "bold", fontSize: "12px" }}>HFM Entity: {prop["labeldesc"]}</Col><Col></Col><Col sm={3} style={{ marginTop: "-1%" }}>{this.formAllLabel()}</Col></Row>
                                                                    </Col>
                                                                </Row>
                                                                {this.state.submitPayment.filter(r => r.labelid == prop["labelid"]).map((lprop, lkey) => {
                                                                    if (prevExpenseTypeID != lprop["expensetypeid"]) {
                                                                        prevExpenseTypeID = lprop["expensetypeid"];
                                                                        i = i + 1;
                                                                        return (<Row style={{ fontSize: "12px" }}>
                                                                            <Col>
                                                                                <Row><Col sm={2} style={{ fontWeight: "bold" }}>TYPE: {lprop["expensetypedesc"]}</Col><Col></Col><Col sm={2} style={{ marginTop: "-1%" }}>{(i > 1) ? <Col></Col> : this.formAllExpenseType()}</Col></Row>
                                                                                {(this.state.selectfields.originpathname == "Hotel") ? <Row><Col sm={2} style={{ fontWeight: "bold" }}>Vendor:</Col><Col></Col><Col sm={2} style={{ marginTop: "-1%" }}>{this.formAllVendorType()}</Col></Row> : <Row></Row>}
                                                                                <Row>
                                                                                    <Col sm={1}></Col>
                                                                                    <Col sm={1} style={{ fontWeight: "bold" }}>Vendor#</Col>
                                                                                    <Col sm={2} style={{ fontWeight: "bold" }}>Traveller</Col>
                                                                                    <Col sm={3} style={{ fontWeight: "bold" }}>Company</Col>
                                                                                    {(this.state.selectfields.originpathname == 'Hotel')
                                                                                        ?
                                                                                        <Col sm={1} style={{ fontWeight: "bold" }}>Check-In</Col>
                                                                                        :
                                                                                        <Col sm={1} style={{ fontWeight: "bold" }}>Pickup</Col>
                                                                                    }
                                                                                    {(this.state.selectfields.originpathname == 'Hotel')
                                                                                        ?
                                                                                        <Col sm={1} style={{ fontWeight: "bold" }}>Check-Out</Col>
                                                                                        :
                                                                                        <Col sm={1} style={{ fontWeight: "bold" }}>Return</Col>
                                                                                    }
                                                                                    <Col sm={1} style={{ fontWeight: "bold" }}>Invoice / Folio#</Col>
                                                                                    <Col sm={1} style={{ fontWeight: "bold" }}>Auth Req</Col>
                                                                                    <Col sm={1} style={{ fontWeight: "bold" }}>Folio Amount</Col>

                                                                                </Row>
                                                                                {this.state.submitPayment.filter(t => t.labelid == prop["labelid"] && t.expensetypeid == lprop["expensetypeid"]).map((tprop, tkey) => {
                                                                                    return (<Row>
                                                                                        <Col style={{ fontSize: "12px" }}>
                                                                                            <Row key={tkey}>
                                                                                                <Col sm={1}></Col>
                                                                                                <Col sm={1}><input type="checkbox" id="chkbid" name="chkbid" defaultChecked={false} value={tprop["directbillid"]} onChange={this.handlecheckboxchange.bind(this)} />&nbsp;{tprop["vendornumber"]}</Col>
                                                                                                <Col sm={2}>{tprop["traveller"]}</Col>
                                                                                                <Col sm={3}>{tprop["company"]}</Col>
                                                                                                <Col sm={1}>{Utils.FormatDate(tprop["checkindate"])}</Col>
                                                                                                <Col sm={1}>{Utils.FormatDate(tprop["checkoutdate"])}</Col>
                                                                                                <Col sm={1}>{tprop["invoicenumber"]}</Col>
                                                                                                <Col sm={1}>{tprop["authReq"]}</Col>
                                                                                                <Col sm={1}>{this.formatCurrency(tprop["amount"])}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                {(tprop["comments"] == "")
                                                                                                    ?
                                                                                                    <Col></Col>
                                                                                                    :
                                                                                                    <Col sm={12}><b>Comments:</b> {tprop["comments"]}</Col>}
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>)
                                                                                })}
                                                                            </Col>
                                                                        </Row>
                                                                        )
                                                                    }
                                                                })
                                                                }
                                                            </div>)
                                                        }
                                                    })
                                                    }
                                                </Col>
                                            </Row>
                                            <Row style={{ textAlign: "center" }}>
                                                <Col style={{ alignItems: "center" }}>
                                                    <Button id="Update" type="button" size="sm" disabled={loading} onClick={(e) => this.onSaveUpdate(e)} >
                                                        {loading && (
                                                            <i
                                                                className="fa fa-refresh fa-spin"
                                                                style={{ marginRight: "5px", color: "#3472F7", alignItems: "center" }}
                                                            />
                                                        )}
                                                        {loading && <span style={{ color: "#3472F7" }} >wait...</span>}
                                                        {!loading && <span>Update</span>}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div> :
                                        <div>
                                            <Row>
                                                <Col sm={12}>
                                                    <Row><Col sm={1} style={{ fontWeight: "bold", fontSize: "12px" }}>HFM Entity: </Col><Col></Col><Col sm={3} style={{ marginTop: "-1%" }}>{this.formAllLabel()}</Col></Row>
                                                    <Row><Col sm={1} style={{ fontWeight: "bold", fontSize: "12px" }}>TYPE: </Col><Col></Col><Col sm={2} style={{ marginTop: "-1%" }}>{this.formAllExpenseType()}</Col></Row>
                                                    {(this.state.selectfields.originpathname == "Hotel") ? <Row><Col sm={1} style={{ fontWeight: "bold", fontSize: "12px" }}>Vendor:</Col><Col></Col><Col sm={2} style={{ marginTop: "-1%" }}>{this.formAllVendorType()}</Col></Row> : <Row></Row>}
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}