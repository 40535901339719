import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class BCDcodeMaintenanceController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    GetRadioRoutingList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getRadioRoutingList;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonRadioRoutingList : Array<{"email": string, "cwEmail": string,"cwReport":string,"rpException":string,"rpTravelCount":string,"id":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceMaitenance);
                    function carServiceMaitenance(item: { complianceOfficerEmailAddr: any; cw_exceptionEmailYN: any;cw_exceptionReportYN:any;rp_approvalExceptionEmailYN:any;rp_travelCountExceptionEmailYN:any;
                                         id:any;}){
                                            jsonRadioRoutingList.push({"email": item.complianceOfficerEmailAddr, "cwEmail": item.cw_exceptionEmailYN, "cwReport": item.cw_exceptionReportYN,
                        "rpException": item.rp_approvalExceptionEmailYN, "rpTravelCount": item.rp_travelCountExceptionEmailYN,id:item.id})
                    }
                    return JSON.parse(JSON.stringify(jsonRadioRoutingList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonRadioRoutingList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    inserUpdateRadioRouting(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insertUpdateRadioRouting;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    deleteRadioRouting(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.deleteRadioRouting;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    /* Airline Codes   */

    GetAirlineCodeList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getAirlineCodeList;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonAirlineList : Array<{"id":string,"orderId":string,"description":string,"bcdAirlineCode":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { Airline_ID: any; Order_ID: any;Description:any;BCD_Airline_code:any;}){
                        jsonAirlineList.push({"id": item.Airline_ID, "orderId": item.Order_ID, "description": item.Description,
                                            "bcdAirlineCode": item.BCD_Airline_code})
                    }
                    return JSON.parse(JSON.stringify(jsonAirlineList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonAirlineList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    GetFilterAirlineCodesList (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getFilterAirlineCodesList;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonAirlineFilterList : Array<{"id":string,"orderId":string,"description":string,"bcdAirlineCode":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { Airline_ID: any; Order_ID: any;Description:any;BCD_Airline_code:any;}){
                        jsonAirlineFilterList.push({"id": item.Airline_ID, "orderId": item.Order_ID, "description": item.Description,
                                            "bcdAirlineCode": item.BCD_Airline_code})
                    }
                    return JSON.parse(JSON.stringify(jsonAirlineFilterList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonAirlineFilterList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    GetBCDAirlineVendors (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getBCDAirlineVendors;
        var data={}
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonBCDAirlineVendorsList : Array<{"id":string,"name":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { id: any; name:any;}){
                        jsonBCDAirlineVendorsList.push({"id": item.id, "name": item.name})
                    }
                    return JSON.parse(JSON.stringify(jsonBCDAirlineVendorsList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonBCDAirlineVendorsList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    insertUpdateDeleteAirlineCodes(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelAirlineCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    /* Airport Codes   */

    GetAirportCodeList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getAirportCodeList;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonAirportList : Array<{"id":string,"airportName":string,"airportCity":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { Airport_code: any; Airport_name: any;Airport_city:any;}){
                        jsonAirportList.push({"id": item.Airport_code, "airportName": item.Airport_name, "airportCity": item.Airport_city})
                    }
                    return JSON.parse(JSON.stringify(jsonAirportList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonAirportList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    GetFilterAirportCodesList (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getFilterAirportCodesList;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonFilterAirportList : Array<{"id":string,"airportName":string,"airportCity":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { Airport_code: any; Airport_name: any;Airport_city:any;}){
                        jsonFilterAirportList.push({"id": item.Airport_code, "airportName": item.Airport_name, "airportCity": item.Airport_city})
                    }
                    return JSON.parse(JSON.stringify(jsonFilterAirportList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonFilterAirportList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsertUpdateDeleteAirportCodes(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelAirportCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    /* Car Vendor Codes   */

    GetCarVendorCodesList (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getCarVendorCodesList;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonCarVendorList : Array<{"id":string,"name":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { CarVendor_code: any; CarVendor_name: any;}){
                        jsonCarVendorList.push({"id": item.CarVendor_code, "name": item.CarVendor_name})
                    }
                    return JSON.parse(JSON.stringify(jsonCarVendorList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCarVendorList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsUpdDelCarVendortCode(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelCarVendortCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    /* Airline Vendor Codes   */

    GetAirlineVendorCodesList (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getAirlineVendorCodesList;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonAirlineVendorList : Array<{"id":string,"name":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { Carrier_code: any; Carrier_name: any;}){
                        jsonAirlineVendorList.push({"id": item.Carrier_code, "name": item.Carrier_name})
                    }
                    return JSON.parse(JSON.stringify(jsonAirlineVendorList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonAirlineVendorList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsUpdDelAirlineVendortCode(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelAirlineVendortCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }   

    /* Travel Class Codes   */

    GetTravelClassCodesList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getTravelClassCodesList;
        var data={

        }
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonTravelClassList : Array<{"id":string,"name":string}> = [];
                if(response.success){
                    response.recordset.forEach(airlineList);
                    function airlineList(item: { Travel_Class_ID: any; Description: any;}){
                        jsonTravelClassList.push({"id": item.Travel_Class_ID, "name": item.Description})
                    }
                    return JSON.parse(JSON.stringify(jsonTravelClassList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonTravelClassList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsUpdDelTravelClassCode(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelTravelClassCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    GetAirlegCount (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getAirlegCount;
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }

    /* Rental Type Codes   */

    GetRentalTypeCodesList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getRentalTypeCodesList;
        var data={

        }
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonRentalTypeList : Array<{"id":string,"name":string,"order":string}> = [];
                if(response.success){
                    response.recordset.forEach(rentalTypeList);
                    function rentalTypeList(item: { Rental_Type_ID: any; Description: any;FieldOrder:any}){
                        jsonRentalTypeList.push({"id": item.Rental_Type_ID, "name": item.Description,"order":item.FieldOrder})
                    }
                    return JSON.parse(JSON.stringify(jsonRentalTypeList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonRentalTypeList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsUpdDelRentalTypeCode(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelRentalTypeCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    GetRentalTypeMaxCount (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getRentalTypeMaxCount;
        var data={
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }

    GetRentalCarCount (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getRentalCarCount;
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }

        /* Hotel Bill Codes   */

    GetHotelBillCodesList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getHotelBillCodesList;
        var data={

        }
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonHotelBillList : Array<{"id":string,"name":string,"order":string,"isCorporate":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(hotelBillList);
                    function hotelBillList(item: { Hotel_Bill_ID: any; Description: any;FieldOrder:any;Is_Corporate_Card:any}){
                        jsonHotelBillList.push({"id": item.Hotel_Bill_ID, "name": item.Description,"order":item.FieldOrder,"isCorporate":item.Is_Corporate_Card})
                    }
                    return JSON.parse(JSON.stringify(jsonHotelBillList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonHotelBillList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsUpdDelHotelBillCode(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelHotelBillCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    GetHotelBillCount (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getHotelBillCount;
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }

        /* Rental Bill Codes   */

    GetRentalBillCodesList (){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getRentalBillCodesList;
        var data={

        }
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonRentalBillList : Array<{"id":string,"name":string,"order":string,"isCorporate":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(rentalBillList);
                    function rentalBillList(item: { Rental_Bill_ID: any; Description: any;FieldOrder:any;Is_Corporate_Card:any}){
                        jsonRentalBillList.push({"id": item.Rental_Bill_ID, "name": item.Description,"order":item.FieldOrder,"isCorporate":item.Is_Corporate_Card})
                    }
                    return JSON.parse(JSON.stringify(jsonRentalBillList).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonRentalBillList).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    InsUpdDelRentalBillCode(data)
    {
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.insUpdDelRentalBillCode;
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    GetRentalBillCount (data){
        var url = this.variables.Url+ this.variables.Controller.bcdMaintatenanceCode + this.variables.bcdMaintatenanceService.getRentalBillCount;
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        });
    }

}