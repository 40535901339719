import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class BCDAgentController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getWTPIncomingTAs (data: any){        
        var url = this.variables.Url+ this.variables.Controller.bcdAgent + this.variables.bcdAgentService.getWTPIncomingTAs;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonWTPIncoming : Array<{"authReqId": string, "travelDate": string, "traveller": string, "travelAgentName": string, "createdBy": string, "createdDate": string, "updatedDate": string}> = [];
                if(response.success){
                    response.recordset.forEach(WTPIncoming);
                    function WTPIncoming(item: { AuthReq_ID: any; Travel_Date: any; Traveler: any; Travel_Agent_Name: any ; Created_By: any; Created_Date: any; Updated_Date: any}){
                        jsonWTPIncoming.push({"authReqId": item.AuthReq_ID, "travelDate": item.Travel_Date,  "traveller":item.Traveler, "travelAgentName":item.Travel_Agent_Name, "createdBy": item.Created_By, "createdDate": item.Created_Date, "updatedDate": item.Updated_Date})
                    }
                    return JSON.parse(JSON.stringify(jsonWTPIncoming).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonWTPIncoming).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getConfirmationPage(data: any){
        var url = this.variables.Url+ this.variables.Controller.bcdAgent + this.variables.bcdAgentService.getConfirmationPage;
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getAuthorizer(data: any){
        var url = this.variables.Url+ this.variables.Controller.bcdAgent + this.variables.bcdAgentService.getAuthorizer;
        return this.serviceProxy.get(url,data).then(response => {
            if(response.success){
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }

    applyConfirmation (data){
        
        var url = this.variables.Url+ this.variables.Controller.bcdAgent + this.variables.bcdAgentService.applyConfirmation;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    saveConfirmation (data){
        
        var url = this.variables.Url+ this.variables.Controller.bcdAgent + this.variables.bcdAgentService.saveConfirmation;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getWTPHistory (data: any){        
        var url = this.variables.Url+ this.variables.Controller.bcdAgent + this.variables.bcdAgentService.getWTPHistory;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonWTPHistory : Array<{"authReqId": string, "createdDate": string, "traveler": string, "createdBy": string,  "travelAgentName": string, "statusDesc": string, "bit": boolean}> = [];
                if(response.success){
                    response.recordset.forEach(WTPHistory);
                    function WTPHistory(item: { authreq_id: any; created_date: any; traveler: any; created_by: any; travel_agent_name: any ; status_desc: any; bit: any}){
                        jsonWTPHistory.push({"authReqId": item.authreq_id, "createdDate": item.created_date, "traveler": item.traveler, "createdBy":item.created_by, "travelAgentName":item.travel_agent_name, "statusDesc": item.status_desc, "bit": item.bit})
                    }
                    return JSON.parse(JSON.stringify(jsonWTPHistory).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonWTPHistory).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}