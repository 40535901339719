import { CommonController } from "../Controllers/CommonController";
import { CodingSummaryController } from "../Controllers/CodingSummaryController";

export class TAInclude
{
    async taCoding(sCode){
        //1-Pending,2-New,3-Re-Submit,4-Declined,5-Approved,6-Complete,7-Terminated,8-Sr Exec Approval,9-Car Service Complete
        if (sessionStorage.getItem("ta_is_corporate_card")==='true' || sessionStorage.getItem("ta_status_id") === "4" || sessionStorage.getItem("ta_status_id") === "5" || sessionStorage.getItem("ta_status_id") === "6" || sessionStorage.getItem("ta_status_id") === "7" || sessionStorage.getItem("ta_status_id") === "9"){
            //exit;
            return false;
        }
        //debugger;
        if (sessionStorage.getItem("user_type_id")==="R"){
        	if (sessionStorage.getItem("ta_tier_code") !== "0" || sessionStorage.getItem("ta_status_id") === "2")
            {
                return false;
            } 
            let commonController = new CommonController();
            var data={
                authreqId: sessionStorage.getItem('ta')!,
                sCode: sCode,
                taCarService2: sessionStorage.getItem("ta_car_service2")!
            }
            return await commonController.getTaCoding(data).then(response => {
               
                //debugger;
                return (response !== undefined)?true:false
            })
            .catch(error => {
                console.error("There was an error", error);
            })  
        }
        else if (sessionStorage.getItem("user_type_id")==="A")
        {
            if (sessionStorage.getItem("ta_tier_code") !== sessionStorage.getItem("approver_tier_id") || sessionStorage.getItem("approver_tier_id") !== sessionStorage.getItem("ta_weight")){
                return false;
            }
        }else{
            return false;
        }
        return true;
    }

    async taCodingOKRequest(authreqId,sBooking,sType,sCode){
        let commonController = new CommonController();
        var data={};
            if (sCode === "Car Service"){
                data = {
                    authreqId: authreqId,
                    sType: sType,
                    iBooking: sBooking,
                    carService: sessionStorage.getItem("ta_car_service2")!
                }
            }
            else{
                data = {
                    authreqId: authreqId,
                    sType: sType,
                    iBooking: sBooking
                }
            }
            if(sCode === 'Air'){
                return await commonController.getTaAirCodingOK(data).then(response => {
                    return response.retVal;
                })
                .catch(error => {
                    console.error("There was an error", error);
                })
            }
            else if(sCode === 'Hotel'){
                return await commonController.getTaHotelCodingOK(data).then(response => {
                    return response.retVal;
                })
                .catch(error => {
                    console.error("There was an error", error);
                })
            }
            else if(sCode === 'Rental Car'){
                return await commonController.getTaRentalCarCodingOK(data).then(response => {
                    return response.retVal;
                })
                .catch(error => {
                    console.error("There was an error", error);
                })
            }
            else if(sCode === 'Car Service'){
                //debugger
                return await commonController.getTaCarServiceCodingOK(data).then(response => {
                    return response.retVal;
                })
                .catch(error => {
                    console.error("There was an error", error);
                })
            }
    }
    taCodingRow(iHeader, bCoding, authreq_id, travel_id, travel_type, sBooking){
        let codingSummaryContoller = new CodingSummaryController();
        var data={
            authreqId: authreq_id,
            sBooking: sBooking,
            taCarService2: sessionStorage.getItem("ta_car_service2")!,
            travelId: travel_id,
            travelType: travel_type
        };
        return codingSummaryContoller.getTACoding(data).then(response => {
            return response.recordset;
        })
        .catch(error => {
            console.error("There was an error", error);
        })
    }
}