import { 
  faHome,faPrint,faInbox,faFileInvoiceDollar,faFileAlt,faFileInvoice,faHistory,faSearch,faUserEdit,faQuestionCircle,faUsers,
  faFolder,faCogs,faAngleDoubleRight,faSlidersH,faUserTie,faUserPlus,faSignal,faUser,faFileSignature,faBinoculars,faUserTag,faSignOutAlt,faKey,faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { CarService } from '../Components/View/Common/CarService';
import { CodingSummary } from '../Components/View/Common/CodingSummary';
import { CreateAuth } from '../Components/View/Common/CreateAuth';
import { SubmitAuthReq } from '../Components/View/Common/SubmitAuthReq';
import { Traveller } from '../Components/View/Common/Traveller';
import { AirRail } from '../Components/View/CreateTA/AirRail';
import { Hotel } from '../Components/View/CreateTA/Hotel';
import { RentalCar } from '../Components/View/CreateTA/RentalCar';
import { Home } from "../Components/View/Home/Home"
import { SelectRole } from "../Components/View/Home/SelectRole"
import { Shawdow } from '../Components/View/Home/Shawdow';
import { StopShadow } from '../Components/View/Home/StopShadow';
import { TrackingGrid } from '../Components/View/TrackingGrid/TrackingGrid';
import { ApproverInbox } from '../Components/View/Approver/ApproverInbox';
import { Decision } from '../Components/View/Approver/Decision';
import { WTP } from '../Components/View/BCDAgent/WTP';
import { Confirmation } from '../Components/View/BCDAgent/Confirmation';
import { Submitter } from '../Components/View/Submitter/Submitter';
import { TAHistory } from '../Components/View/Submitter/TAHistory';
import { CarVoucherHistory } from '../Components/View/CarVoucher/CarVoucherHistory';
import { CarVoucherPrint } from '../Components/View/CarVoucher/CarVoucherPrint';
import { CarVoucher } from '../Components/View/CarVoucher/CarVoucher';
import { ApprovalDiagram  } from '../Components/View/ApprovalDiagram/ApprovalDiagramChart';
import { DirectBillActivity } from '../Components/View/DirectBill/DirectBillActivity';
import { DirectBillActivityView } from '../Components/View/DirectBill/DirectBillActivityView';
import { DirectBillIncoming } from '../Components/View/DBIncoming/DirectBillIncoming';
import { DirectBillUpdate } from '../Components/View/DBIncoming/DirectBillUpdate';
import { DirectBillContact} from '../Components/View/DBIncoming/DirectBillContact';
import { DirectBillCar } from '../Components/View/DirectBill/DirectBillCar';
import { DirectBillReportCallFolioLog} from '../Components/View/DirectBillReport/DirectBillReportCallFolioLog';
import { DirectBillReportNotFolioLog} from '../Components/View/DirectBillReport/DirectBillReportNotFolioLog';
import { DirectBillReportCheckin} from '../Components/View/DirectBillReport/DirectBillReportCheckin';
import { DirectBillReportVendorPayment} from '../Components/View/DirectBillReport/DirectBillReportVendorPayment';
import { DirectBillReportSubmittedPayment } from '../Components/View/DirectBillReport/DirectBillReportSubmittedPayment';
import { DirectBillReportSubmitPayment } from '../Components/View/DirectBillReport/DirectBillReportSubmitPayment';
import { LabelMaintenanceView } from '../Components/View/LabelMaintenance/LabelMaintenanceView';
import { SystemMaintenanceView } from '../Components/View/SystemMaintenance/SystemMaintenanceView';
import { BusinessUnitMaintenanceView } from '../Components/View/BusinessUnit/BusinessUnitMaintenance';
import { CarCompanyListView } from '../Components/View/CarServiceMaintenance/CarCompanyListView';
import { CarServiceMainAddEdit } from '../Components/View/CarServiceMaintenance/CarServiceMainAddEdit';
import { WTPHistory } from '../Components/View/BCDAgent/WTPHistory';
import { ApproverHistory } from '../Components/View/Approver/ApproverHistory';
import { DepartmentMaintenance } from '../Components/View/DepartmentMaintenance/DepartmentMaintenance';
import { CarServiceIncomingCAList } from '../Components/View/CarServiceIncomingCA/IncomingCAList';
import { CarServiceConfirmation } from '../Components/View/CarServiceIncomingCA/CarServiceConfirmation';
import { CarServiceDecision } from '../Components/View/CarServiceIncomingCA/CarServiceDecision';
import { CarServiceHistory } from '../Components/View/CarServiceIncomingCA/CarServiceHistory';
import { RadioRouting  } from '../Components/View/BCD Code Maintenance/RadioRouting';
import { AirlineCodes} from '../Components/View/BCD Code Maintenance/AirlineCodes';
import { AirportCodes} from '../Components/View/BCD Code Maintenance/AirportCodes';
import { CarVoucherCodes} from '../Components/View/BCD Code Maintenance/CarVoucherCodes';
import { CarVoucherConfirmation } from '../Components/View/CarVoucher/CarVoucherConfirmation';
import { Utils } from '../Common/Utils';
import { UserDetails } from '../Components/View/UserDocMaintenance/UserDetails';
import { AirlineVendorCodes} from '../Components/View/BCD Code Maintenance/AirlineVendorCodes';
import { TravelClassCodes } from '../Components/View/BCD Code Maintenance/TravelClassCodes';
import { RentalTypeCodes } from '../Components/View/BCD Code Maintenance/RentalTypeCodes';
import { HotelBillCodes } from '../Components/View/BCD Code Maintenance/HotelBillCodes';
import { RentalBillCodes } from '../Components/View/BCD Code Maintenance/RentalBillCodes';
import { MenuPage } from '../Components/View/BCD Code Maintenance/MenuPage';
import { ContentWinnerReports } from '../Components/View/Reports/ContestWinnerReport';
import {TravellerMaintainenceAdmin} from '../Components/View/Common/TravellerMaintainenceAdmin'
import { RadioPersonnelReports  } from '../Components/View/Reports/RadioPersonnelReport';
import { QuaterlyContentWinnerReports } from '../Components/View/Reports/QuaterlyContentWinnerReport';
import{CTM_reports} from '../Components/View/Reports/CTM_reports'
import {OnlineDocumentHelpUpload} from '../Components/View/Common/OnlineDocumentHelpUpload'
import { User } from '../Components/View/User/User';
import { UserMaintenance } from '../Components/View/User/UserMaintenance';
import { VoucherPlus } from '../Components/View/VoucherPlus/VoucherPlus';
import { ApprovalProcess } from '../Components/View/ApprovalProcess/ApprovalProcess';
import { AddBCDCoding } from '../Components/View/BCD Code Maintenance/AddBCDCoding';
import { ViewBCDRemarks } from '../Components/View/BCD Code Maintenance/ViewBCDRemarks';
import { AddBCDRemarks } from '../Components/View/BCD Code Maintenance/AddBCDRemarks';
import { AuthenticateUser } from '../Components/View/BCD Code Maintenance/AuthenticateUser';
import { ContentWinnerListView } from '../Components/View/ContentWinner/ContentWinnerListView';

export class Routes {
dashboardRoutes = [
  {
    path: "/selectrole",
    name: "SelectRole",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/billview",
    name: "Direct Bill Hotel Activity View",
    icon: faHome,
    component: DirectBillUpdate,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  }, 
  {
    path: "/incomingupdate",
    name: "Direct Bill Hotel Activity View",
    icon: faHome,
    component: DirectBillUpdate,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  }, 
  {
    path: "/addbcdcoding",
    name: "eTA / CTM Codes",
    icon: faHome,
    component: AddBCDCoding,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  /*{
    path: "/authuser",
    name: "Authenticate User",
    icon: faHome,
    component: AuthenticateUser,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },*/
  {
    path: "/viewremarks",
    name: "View CTM Remarks",
    icon: faHome,
    component: ViewBCDRemarks,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/addremarks",
    name: "View CTM Remarks",
    icon: faHome,
    component: AddBCDRemarks,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/callfolio",
    name: "Call folio log",
    icon: faHome,
    component: DirectBillReportCallFolioLog,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/notfolio",
    name: "Call folio log",
    icon: faHome,
    component: DirectBillReportNotFolioLog,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/guestarrival",
    name: "Guest Arrival",
    icon: faHome,
    component: DirectBillReportCheckin,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/hotelvendorpay/Hotel",
    name: "Vendor Payment",
    icon: faHome,
    component: DirectBillReportVendorPayment,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/carvendorpay/Car",
    name: "Vendor Payment",
    icon: faHome,
    component: DirectBillReportVendorPayment,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/submittedhotelpay/Hotel",
    name: "Submitted Payment",
    icon: faHome,
    component: DirectBillReportSubmittedPayment,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/submittedcarpay/Car",
    name: "Submitted Payment",
    icon: faHome,
    component: DirectBillReportSubmittedPayment,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/submithotelpay/Hotel",
    name: "Submit Payment",
    icon: faHome,
    component: DirectBillReportSubmitPayment,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/submitcarpay/Car",
    name: "Submit Payment",
    icon: faHome,
    component: DirectBillReportSubmitPayment,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/carview",
    name: "Direct Bill Hotel Activity View",
    icon: faHome,
    component: DirectBillUpdate,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/traveller",
    name: "Traveller",
    icon: faHome,
    component: Traveller,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/airRail",
    name: "AirRail",
    icon: faHome,
    component: AirRail,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/hotel",
    name: "Hotel",
    icon: faHome,
    component: Hotel,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/rentalcar",
    name: "RentalCar",
    icon: faHome,
    component: RentalCar,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/carservice",
    name: "CarService",
    icon: faHome,
    component: CarService,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/codingsummary",
    name: "CodingSummary",
    icon: faHome,
    component: CodingSummary,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/submitauthreq",
    name: "SubmitAuthReq",
    icon: faHome,
    component: SubmitAuthReq,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/decision",
    name: "Decision",
    icon: faHome,
    component: Decision,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    icon: faHome,
    component: Confirmation,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/carvoucherconfirmation",
    name: "Car Voucher Confirmation",
    icon: faHome,
    component: CarVoucherConfirmation,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/usrmain",
    name: "User Maintenance",
    icon: faHome,
    component: UserMaintenance,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: "/home",
    name: "MachineName",
    icon: faHome,
    component: Home,
    layout: "/admin",
    view:"MNU_0",
    submenu:[]
  },
  {
    path: "/shawdow",
    name: "Shadow",
    icon: faHome,
    component: Shawdow,
    layout: "/admin",
    view:"MNU_1",
    submenu:[]
  },
  {
    path: "/qtrycontest",
    name: "Quarterly Contest Winner Report",
    icon: faHome,
    component: QuaterlyContentWinnerReports,
    layout: "/admin",
    view:"MNU_2",
    submenu:[]
  },
  {
    path: "/rsvltcontest",
    name: "(RSVLT) Contest Winner Exception Report",
    icon: faHome,
    component: ContentWinnerReports,
    layout: "/admin",
    view:"MNU_2",
    submenu:[]
  },
  {
    path: "/rsvltradio",
    name: "(RSVLT) Radio Personnel Exception Report",
    icon: faHome,
    component: RadioPersonnelReports,
    layout: "/admin",
    view:"MNU_2",
    submenu:[]
  },
  /*
  {
    path: "/selectrole",
    name: "Emergency Project Add",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_3",
    submenu:[]
  },*/
  {
    path: "/label",
    name: "Label Maintenance",
    icon: faHome,
    component: LabelMaintenanceView,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/apmaint",
    name: "Approval Process",
    icon: faHome,
    component: ApprovalProcess,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/sysmsg",
    name: "System Message",
    icon: faHome,
    component: SystemMaintenanceView,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/intrebill",
    name: "Int'l Rebill BU",
    icon: faHome,
    component: BusinessUnitMaintenanceView,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },  
  {
    path: "/carservicecompanylist",
    name: "Car Service",
    icon: faHome,
    component: CarCompanyListView,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },   
  {
    path: "/carserviceaddedit",
    name: "Car Service Add Edit",
    icon: faHome,
    component: CarServiceMainAddEdit,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  }, 
  {
    path: "/dept",
    name: "Department",
    icon: faHome,
    component: DepartmentMaintenance,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/travelmain",
    name: "Traveller Maintainence",
    icon: faHome,
    component: TravellerMaintainenceAdmin,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/airlineCodes",
    name: "Airline Codes",
    icon: faHome,
    component: AirlineCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/airportCodes",
    name: "CTM Airport Codes",
    icon: faHome,
    component: AirportCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },  
  {
    path: "/carvouchercodes",
    name: "CTM Car Vendor Codes",
    icon: faHome,
    component: CarVoucherCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
 {
    path: "/airlinevendorcodes",
    name: "CTM Airline Vendor Codes",
    icon: faHome,
    component: AirlineVendorCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
 {
    path: "/travelclasscodes",
    name: "Travel Class Codes",
    icon: faHome,
    component: TravelClassCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  }, 
  {
    path: "/hotelbillcodes",
    name: "Hotel Bill Codes",
    icon: faHome,
    component: HotelBillCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/rentalbillcodes",
    name: "Rental Bill Codes",
    icon: faHome,
    component: RentalBillCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/rentaltypecodes",
    name: "Rental Type Codes",
    icon: faHome,
    component: RentalTypeCodes,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/addbcdcodingremarks",
    name: "Add CTM Coding Remarks",
    icon: faHome,
    component: AddBCDCoding,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/radiorouting",
    name: "Radio Routing",
    icon: faHome,
    component: RadioRouting,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    icon: faHome,
    component: Home,
    layout: "/admin",
    view:"MNU_31",
    submenu:[{path: "/sysmsg",name: "System Message",icon: faHome,component: SystemMaintenanceView,layout: "/admin"},{path: "/label",name: "Label",icon: faHome,component: LabelMaintenanceView,layout: "/admin"},{path: "/apmaint",name: "Approval Process",icon: faHome,component: ApprovalProcess,layout: "/admin"},{path: "/intrebill",name: "Int'l Rebill BU",icon: faHome,component: BusinessUnitMaintenanceView,layout: "/admin"},{path: "/carservicecompanylist",name: "Car Service",icon: faHome,component: CarCompanyListView,layout: "/admin"},{path: "/dept",name: "Department",icon: faHome,component: DepartmentMaintenance,layout: "/admin"},{path: "/travelmain",name: "Traveler",icon: faHome,component: TravellerMaintainenceAdmin,layout: "/admin"},{path: "/user",name: "User",icon: faHome,component: User,layout: "/admin"}]
  },
  {
    path: "/etabcdcodes",
    name: "eTA / CTM Codes",
    icon: faHome,
    component: Home,
    layout: "/admin",
    view:"MNU_31",
    submenu:[{path: "/RadioRouting",name: "Compliance Officer",icon: faHome,component: RadioRouting,layout: "/admin"},{path: "/airlineCodes",name: "Airline Codes",icon: faHome,component: AirlineCodes,layout: "/admin"},{path: "/airportCodes",name: "CTM Airport Codes",icon: faHome,component: AirportCodes,layout: "/admin"},{path: "/carvouchercodes",name: "CTM Car Vendor Codes",icon: faHome,component: CarVoucherCodes,layout: "/admin"},{path: "/airlinevendorcodes",name: "CTM Airline Vendor Codes",icon: faHome,component: AirlineVendorCodes,layout: "/admin"},{path: "/travelclasscodes",name: "Travel Class Codes",icon: faHome,component: TravelClassCodes,layout: "/admin"},{path: "/hotelbillcodes",name: "Hotel Bill Codes",icon: faHome,component: HotelBillCodes,layout: "/admin"},{path: "/rentalbillcodes",name: "Rental Bill Codes",icon: faHome,component: RentalBillCodes,layout: "/admin"},{path: "/rentaltypecodes",name: "Rental Type Codes",icon: faHome,component: RentalTypeCodes,layout: "/admin"},{path: "/addbcdcodingremarks",name: "Add CTM Coding Remarks",icon: faHome,component: AddBCDCoding,layout: "/admin"},]
  },
  {
    path: "/report",
    name: "Report",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_32",
    submenu:[{path: "/qtrycontest",name: "Quarterly Contest Winner",icon: faHome,component: QuaterlyContentWinnerReports,layout: "/admin"},{path: "/rsvltcontest",name: "(RSVLT) Contest Winner",icon: faHome,component: ContentWinnerReports,layout: "/admin"},{path: "/rsvltradio",name: "(RSVLT) Radio Personnel",icon: faHome,component: RadioPersonnelReports,layout: "/admin"}]
  },
  {
    path: "/documentupload",
    name: "Online Help Document Upload",
    icon: faHome,
    component: OnlineDocumentHelpUpload,
    layout: "/admin",
    view:"MNU_33",
    submenu:[]
  },
  {
    path: "/voucherplus",
    name: "Voucher Plus",
    icon: faHome,
    component: VoucherPlus,
    layout: "/admin",
    view:"MNU_33",
    submenu:[]
  },
  /*{
    path: "/selectrole",
    name: "Merlin",
    icon: faHome,
    component: 'SelectRole',
    layout: "/admin",
    view:"MNU_33",
    submenu:[]
  },*/
  {//pending
    path: "/selectrole",
    name: "Users Logged In:",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_33",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Active In Last 10 Min:",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_4",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "IIS Server Time:",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_4",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "SQL Server Time:",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_4",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "TA Maintenance",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_4",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Test Mail",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_4",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Test",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_4",
    submenu:[]
  },
  {
    path: "/createTA",
    name: "Create Travel Authorization",
    icon: faHome,
    component: CreateAuth,
    layout: "/admin",
    view:"MNU_5",
    submenu:[]
  },
  {
    path: "/createCA",
    name: "Create Standalone Car Service",
    icon: faHome,
    component: CreateAuth,
    layout: "/admin",
    view:"MNU_5",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Hotel Extended Stay",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_5",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Rental Car Extended Stay",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_5",
    submenu:[]
  },
  {
    path: "/unsubmittedTA",
    name: "Unsubmitted/Draft TA's",
    icon: faHome,
    component: Submitter,
    layout: "/admin",
    view:"MNU_5",
    submenu:[]
  },
  {
    path: "/taHistory",
    name: "Travel Authorization History",
    icon: faHome,
    component: TAHistory,
    layout: "/admin",
    view:"MNU_5",
    submenu:[]
  },
  {
    path: "/approverinbox",
    name: "Approver Inbox",
    icon: faHome,
    component: ApproverInbox,
    layout: "/admin",
    view:"MNU_6",
    submenu:[]
  },
  {
    path: "/approverhistory",
    name: "Approver History",
    icon: faHome,
    component: ApproverHistory,
    layout: "/admin",
    view:"MNU_7",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Traveller Information Form Report",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_8",
    submenu:[]
  },
  {
    path: "/contestwinner",
    name: "Contest Winner",
    icon: faHome,
    component: ContentWinnerListView,
    layout: "/admin",
    view:"MNU_9",
    submenu:[]
  },
  {
    path: "/dbincoming",
    name: "Direct Bill Incoming Hotel TA's",
    icon: faHome,
    component: DirectBillIncoming,    
    layout: "/admin",
    view:"MNU_A",
    submenu:[]
  },
  {//instr(Request.ServerVariables("SERVER_NAME"), "usitg-wongm") > 0
    path: "/selectrole",
    name: "Direct Bill Incoming Rental Car TA's",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_A",
    submenu:[]
  },
  {
    path: "/directbill",
    name: "Direct Bill Hotel Activity",
    icon: faHome,
    component: DirectBillActivity,
    layout: "/admin",
    view:"MNU_B",
    submenu:[]
  },
  {
    path: "/dbcar",
    name: "Direct Bill Rental Car Activity",
    icon: faHome,
    component: DirectBillCar,
    layout: "/admin",
    view:"MNU_B1",
    submenu:[]
  },
  {
    path: "/dbContact",
    name: "Direct Bill Contact",
    icon: faHome,
    component: DirectBillContact,
    layout: "/admin",
    view:"MNU_C",
    submenu:[]
  },
  {
    path: "/directbillreports",
    name: "Direct Bill Reports",
    icon: faHome,
    component: Home,
    layout: "/admin",
    view:"MNU_C",
    submenu:[{path: "/callfolio",name: "Call for Folio",icon: faHome,component: DirectBillReportCallFolioLog,layout: "/admin"},
  {path: "/notfolio",name: "Folio Not Received",icon: faHome,component: DirectBillReportNotFolioLog,layout: "/admin"},
  {path: "/submithotelpay/Hotel",name: "Submit Hotel For Payment",icon: faHome,component: DirectBillReportSubmitPayment,layout: "/admin"},
  {path: "/submitcarpay/Car",name: "Submit Rental Car For Payment",icon: faHome,component: DirectBillReportSubmitPayment,layout: "/admin"},
  {path: "/submittedhotelpay/Hotel",name: "Submitted Hotel Payment",icon: faHome,component: DirectBillReportSubmittedPayment,layout: "/admin"},
  {path: "/submittedcarpay/Car",name: "Submitted Rental Car Payment",icon: faHome,component: DirectBillReportSubmittedPayment,layout: "/admin"},
  {path: "/hotelvendorpay/Hotel",name: "Hotel Vendor Payment",icon: faHome,component:DirectBillReportVendorPayment ,layout: "/admin"},
  {path: "/carvendorpay/Car",name: "Rental Car Vendor Payment",icon: faHome,component: DirectBillReportVendorPayment,layout: "/admin"},
  {path: "/guestarrival",name: "Guest Arrival",icon: faHome,component: DirectBillReportCheckin,layout: "/admin"}]
},
  {
    path: "/selectrole",
    name: "Direct Bill Report Hotel Trans Fee",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_D",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "Printed Letters",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_D",
    submenu:[]
  },
  {
    path: "/carVoucher",
    name: "New York City Car Voucher",
    icon: faHome,
    component: CarVoucher,
    layout: "/admin",
    view:"MNU_E",
    submenu:[]
  },
  {
    path: "/selectrole",
    name: "New York City Car Voucher PLUS",
    icon: faHome,
    component: SelectRole,
    layout: "/admin",
    view:"MNU_F",
    submenu:[]
  },
  {
    path: "/approvalprocess",
    name: "Approval Process",
    icon: faHome,
    component: ApprovalDiagram,
    layout: "/admin",
    view:"MNU_G",
    submenu:[]
  },
  {
    path: "/wtpincomingta",
    name: "CTM Incoming TA's",
    icon: faHome,
    component: WTP,
    layout: "/admin",
    view:"MNU_H",
    submenu:[]
  },
  {
    path: "/wtphistory",
    name: "CTM TA History",
    icon: faHome,
    component: WTPHistory,
    layout: "/admin",
    view:"MNU_I",
    submenu:[]
  },
  {
    path: "/CTM_reports",
    name: "TAs At CTM Report",
    icon: faHome,
    component: CTM_reports,
    layout: "/admin",
    view:"MNU_J",
    submenu:[]
  },
  {
    path: "/incomingca",
    name: "Car Service Incoming CA's",
    icon: faHome,
    component: CarServiceIncomingCAList,
    layout: "/admin",
    view:"MNU_K",
    submenu:[]
  },  
  {
    path: "/carservicehistory",
    name: "Car Service History",
    icon: faHome,
    component: CarServiceHistory,
    layout: "/admin",
    view:"MNU_L",
    submenu:[]
  },
  {
    path: "/trackinggrid",
    name: "eTA Tracking Grid",
    icon: faHome,
    component: TrackingGrid,
    layout: "/admin",
    view:"MNU_M",
    submenu:[]
  },
  {
    path: Utils.getAbsoluteDomainUrl() + "/Documents/eTAWhatsNew.pdf",
    name: "What's New",
    icon: faHome,
    component: 'pdf',
    layout: "",
    view:"MNU_N",
    submenu:[]
  },
  {
    path: Utils.getAbsoluteDomainUrl() + "/Documents/eTAFAQ.pdf",
    name: "FAQ",
    icon: faHome,
    component: 'pdf',
    layout: "",
    view:"MNU_N",
    submenu:[]
  },
  {
    path: "/travelmain",
    name: "Traveler Maintenance",
    icon: faHome,
    component: TravellerMaintainenceAdmin,
    layout: "/admin",
    view:"MNU_O",
    submenu:[]
  },
  {
    path: "/user",
    name: "User",
    icon: faHome,
    component: User,
    layout: "/admin",
    view:"MNU_P",
    submenu:[]
  },
  {
    path: "/myinfo",
    name: "View My User Info",
    icon: faHome,
    component: UserDetails,
    layout: "/admin",
    view:"MNU_Q",
    submenu:[]
  },
  {
    path: "/stopShadow",
    name: "Stop Shadowing",
    icon: faHome,
    component: StopShadow,
    layout: "/admin",
    view:"MNU_R",
    submenu:[]
  },
  {
    path: "/carserviceconfirmation",
    name: "Car Service Confirmation",
    icon: faHome,
    component: CarServiceConfirmation,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/carservicedecision",
    name: "Car Service Decision",
    icon: faHome,
    component: CarServiceDecision,
    layout: "/admin",
    view:"MNU_Z",
    submenu:[]
  },
  {
    path: "/carvoucherhistory",
    name: "Car Voucher History",
    icon: faHome,
    component: CarVoucherHistory,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
  {
    path: Utils.getAbsoluteDomainUrl() + "/carvoucherprint",
    name: "Car Voucher Print",
    icon: faHome,
    component: CarVoucherPrint,
    layout: "/admin",
    view:"MNU",
    submenu:[]
  },
];

}
