import React, { Component, CSSProperties } from "react";
import { IDirectBillReportSubmittedPaymentParamter, Istate } from "../../../Model/IDirectBillReportSubmittedPayment";
import { FormLabel, Container, Row, Col, Form, Alert, Button, Card, NavLink, FormControl, Table, InputGroup, FormGroup } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { DirectBillReportController } from "../../../Controllers/DirectBillReportController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import addSubtractDate from 'add-subtract-date';
import * as Utils from '../../../js/Utils.js';
import * as Constants from "../../../js/Constants";
import { SearchBoxDBReport } from "../../CustomComponents/SearchBoxDBReport";
import { isEmptyStatement, textChangeRangeIsUnchanged } from "typescript";

export class DirectBillReportSubmittedPayment extends Component<IDirectBillReportSubmittedPaymentParamter, Istate> {
    constructor(props: IDirectBillReportSubmittedPaymentParamter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")

        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: { searchDays: "14", beginDate: "", endDate: new Date(), searchScope: "ar.authreq_id", searchStringLike: "*" },
            selectfields: { originpathname: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1), labelId: '', labelName: '', expenseTypeId: '', expenseTypeName: '' },
            submittedPaymentSearch: [],
            submittedPayment: [],
            labelGroups: [],
            expenseTypeGroups: [],
            labelId: "",
            labelName: "",
            expenseTypeId: "",
            expenseTypeName: "",
            errors: {},
            errMsg: "",
            loggedIn,
            currentSort: 'down',
            currentColumn: 'authReqId',
            loading: false,
            modal: { modalSpinnerShow: false },
        }
    }

    componentWillMount() {
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        var date = new Date();
        date.setDate(date.getDate() - this.state.fields.searchDays);
        fields["beginDate"] = date;
        this.getLabel();
        this.getExpenseType();
        this.setState({ fields })
        this.pageLoad();
    }
    pageLoad() {

        
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.setState({ modal });
        sessionStorage.setItem("frompage", Constants.dbrsubmittedPayment);
        let directbillreportController = new DirectBillReportController();
        var searchScopev = "";
        if (this.state.fields.searchScope.indexOf("*") != -1) {
            searchScopev = this.state.fields.searchScope.replace("*", "");
        }
        else {
            searchScopev = this.state.fields.searchScope;
        }
        let endDate: string = Utils.FormatDate(addSubtractDate.add(new Date(this.state.fields.endDate), 1, "day"));
        let beginDate: string = Utils.FormatDate(this.state.fields.beginDate);
        //console.log("beginDate:", beginDate);
        //console.log("endDate:", endDate);

        var dbsubmitteddata = {

            uid: sessionStorage.getItem("uid"),
            labelID: this.state.selectfields.labelId,
            expensetypeID: this.state.selectfields.expenseTypeId,
            box: "",
            dbView: this.state.selectfields.originpathname,
            beginDate: beginDate,
            endDate: endDate,
            searchScope: this.state.fields.searchStringLike,
            searchStringLike: searchScopev

        }
        //console.log('dbsubmitteddata:', dbsubmitteddata);
        directbillreportController.getDirectBillReportSubmittedPayment(dbsubmitteddata).then(jsonsubmittedpayment => {
            this.setState({
                submittedPayment: JSON.parse(JSON.stringify(jsonsubmittedpayment))
            });
            //console.log('dbsubmitteddata:', this.state.submittedPayment);
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });

        }).catch(error => {
            console.error("There was an error...," + error)
        });

        modal['modalSpinnerShow'] = false;
    }

    handleCallback = (searchDays, beginDate, endDate, searchScope, searchStringLike) => {
        let fields = this.state.fields;
        fields["searchDays"] = searchDays;
        fields["beginDate"] = beginDate;
        fields["endDate"] = endDate;
        fields["searchScope"] = searchScope;
        fields["searchStringLike"] = searchStringLike;
        this.setState({ fields });
        this.pageLoad();
    }

    formatCurrency = (amount) => {
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    getLabel() {
        
        let modal = this.state.modal;
        let directbillreportController = new DirectBillReportController();
        directbillreportController.getAllLabels().then(jsonGetLabels => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonGetLabels)) });
            //console.log(this.state.labelGroups)
            // this.setState({loadComplete:true})
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow'] = false;
        })
        this.setState({ modal })
    }

    getExpenseType() {

        
        let modal = this.state.modal;
        let directbillreportController = new DirectBillReportController();
        directbillreportController.getExpenseType().then(jsonGetExpenseType => {
            this.setState({ expenseTypeGroups: JSON.parse(JSON.stringify(jsonGetExpenseType)) });
            //console.log(this.state.expenseTypeGroups)
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow'] = false;
        })
        modal['modalSpinnerShow'] = false;
        this.setState({ modal })
    }

    handleSelectChange(e) {
        
        let selectfields = this.state.selectfields;

        if (e.currentTarget.id === 'labelId') {
            if (e.currentTarget.value != "All") {
                selectfields["labelId"] = e.currentTarget.value;
                {
                    this.state.labelGroups.filter(p => p.id == e.currentTarget.value).map(f => {
                        selectfields["labelName"] = f.name;
                        //console.log(f.name)
                    })
                }
                this.setState({ labelId: e.currentTarget.value })
            }
            else {
                selectfields["labelId"] = ""
                selectfields["labelName"] = "";
            }
        }

        if (e.currentTarget.id === "expenseTypeId") {
            if (e.currentTarget.value != "All") {
                selectfields[e.currentTarget.id] = e.currentTarget.value;
                {
                    this.state.expenseTypeGroups.filter(p => p.id == e.currentTarget.value).map(f => {
                        selectfields["expenseTypeName"] = f.name;
                        //console.log(f.name)
                    })
                }

            }
            else {
                selectfields["expenseTypeId"] = "";
                selectfields["expenseTypeName"] = "";
            }
        }
        this.setState({ selectfields });
        this.pageLoad();
    }

    formAllLabel = () => {
        return <Form.Group>
            <Form.Label></Form.Label>
            <Form.Control as="select" className="form-control form-control-sm" id="labelId" name="labelName" defaultValue="All" value={this.state.selectfields.labelId} onChange={this.handleSelectChange.bind(this)}>
                <option value="All">All</option>
                {this.state.labelGroups.map((prop, key) => {
                    if (prop["isActive"]) {
                        return (
                            <option value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                    else {
                        return (
                            <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                })}
            </Form.Control>
        </Form.Group>
    }

    formAllExpenseType = () => {
        return <Form.Group>
            <Form.Label></Form.Label>
            <Form.Control as="select" className="form-control form-control-sm" id="expenseTypeId" name="expenseTypeName" defaultValue="All" value={this.state.selectfields.expenseTypeId} onChange={this.handleSelectChange.bind(this)}>
                <option value="All">All</option>
                {this.state.expenseTypeGroups.map((prop, key) => {
                    if (prop["isActive"]) {
                        return (
                            <option value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                    else {
                        return (
                            <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                })}
            </Form.Control>
        </Form.Group>
    }

    render() {
        //
        var prevLabelID = "";
        var prevExpenseTypeID = "";
        return (
            <div className="content">
                <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Card border="light" style={{ width: '100%' }}>
                                <Card.Body>
                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                    <SearchBoxDBReport parentCallback={this.handleCallback} from={this.state.selectfields.originpathname} />
                                    <div className="header"><h2 className="title" style={{ textAlign: 'center' }}>eTA Travel Payment System</h2></div>
                                    <div className="header"><p style={{ textAlign: 'center', marginTop: '-12px' }}>{Utils.FormatTodayDate()}</p></div>
                                    {(this.state.submittedPayment.length > 0) ?
                                        <div>
                                            <Row>
                                                <Col sm={12}>
                                                    {this.state.submittedPayment.map((prop, key) => {
                                                        //

                                                        if (prevLabelID != prop["labelid"]) {
                                                            prevLabelID = prop["labelid"];

                                                            prevExpenseTypeID = "";
                                                            return (<div key={key}>
                                                                <Row style={{ marginTop: "1%" }}>
                                                                    <Col>
                                                                        <Row><Col style={{ fontSize: "20px" }}>{(this.state.selectfields.originpathname == "Hotel") ? "Hotel" : "Rental Car"} Submitted for Payment to Delray</Col></Row>
                                                                        <Row><Col sm={3} style={{ fontWeight: "bold", fontSize: "12px" }}>HFM Entity: {prop["labeldesc"]}</Col><Col></Col><Col sm={3} style={{ marginTop: "-1%" }}>{this.formAllLabel()}</Col></Row>
                                                                    </Col>
                                                                </Row>
                                                                {this.state.submittedPayment.filter(r => r.labelid == prop["labelid"]).map((lprop, key) => {
                                                                    if (prevExpenseTypeID != lprop["expensetypeid"]) {
                                                                        prevExpenseTypeID = lprop["expensetypeid"];
                                                                        return (<Row style={{ fontSize: "12px" }}>
                                                                            <Col>
                                                                                <Row><Col sm={2} style={{ fontWeight: "bold" }}>TYPE: {lprop["expensetypedesc"]}</Col><Col></Col><Col sm={2} style={{ marginTop: "-1%" }}>{this.formAllExpenseType()}</Col></Row>
                                                                                <Row>
                                                                                    <Col sm={1}></Col>
                                                                                    <Col sm={2} style={{ fontWeight: "bold" }}>Traveller</Col>
                                                                                    <Col sm={2} style={{ fontWeight: "bold" }}>Company</Col>
                                                                                    {(this.state.selectfields.originpathname == 'Hotel')
                                                                                        ?
                                                                                        <Col sm={1} style={{ fontWeight: "bold" }}>Check-Out</Col>
                                                                                        :
                                                                                        <Col sm={1} style={{ fontWeight: "bold" }}>Return</Col>
                                                                                    }
                                                                                    <Col sm={1} style={{ fontWeight: "bold" }}>Auth Req</Col>
                                                                                    <Col sm={1} style={{ fontWeight: "bold" }}>Folio Amount</Col>
                                                                                </Row>
                                                                                {this.state.submittedPayment.filter(t => t.labelid == prop["labelid"] && t.expensetypeid == lprop["expensetypeid"]).map((tprop, key) => {
                                                                                    return (<Row>
                                                                                        <Col style={{ fontSize: "12px" }}>
                                                                                            <Row>
                                                                                                <Col sm={1}></Col>
                                                                                                <Col sm={2}>{tprop["traveller"]}</Col>
                                                                                                <Col sm={2}>{tprop["company"]}</Col>
                                                                                                <Col sm={1}>{Utils.FormatDate(tprop["checkindate"])}</Col>
                                                                                                <Col sm={1}>{tprop["authReq"]}</Col>
                                                                                                <Col sm={1}>{this.formatCurrency(tprop["amount"])}</Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>)
                                                                                })}
                                                                            </Col>
                                                                        </Row>

                                                                        )
                                                                    }
                                                                })
                                                                }
                                                            </div>)
                                                        }
                                                    })
                                                    }
                                                </Col>
                                            </Row>
                                        </div> :
                                        <div>
                                            <Row>
                                                <Col sm={12}>
                                                    <Row><Col style={{ fontWeight: "bold", fontSize: "12px" }}>{(this.state.selectfields.originpathname == "Hotel") ? "Hotel" : "Rental Car"} Submitted for Payment to Delray</Col></Row>
                                                    <Row><Col sm={3} style={{ fontWeight: "bold", fontSize: "12px" }}>HFM Entity:</Col><Col></Col><Col sm={3} style={{ marginTop: "-1%" }}>{this.formAllLabel()}</Col></Row>
                                                    <Row><Col sm={2} style={{ fontWeight: "bold", fontSize: "12px" }}>TYPE: </Col><Col></Col><Col sm={2} style={{ marginTop: "-1%" }}>{this.formAllExpenseType()}</Col></Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }
}