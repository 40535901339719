import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class SubmitterController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getUnsubmittedTA (data: any){        
        var url = this.variables.Url+ this.variables.Controller.submitter + this.variables.submitterService.getUnsubmittedTA;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonUnsubmittedTA : Array<{"authReqId": string, "createdBy": string, "createdDate": string, "artistProjectNumber": string, "artistProjectName": string, "carServiceOnly": boolean}> = [];
                if(response.success){
                    response.recordset.forEach(UnsubmittedTA);
                    function UnsubmittedTA(item: { AuthReq_ID: any; Created_By: any; Created_Date: any; Artist_Project_Number: any ; Artist_Project_Name: any; Car_Service_Only: boolean}){
                        jsonUnsubmittedTA.push({"authReqId": item.AuthReq_ID, "createdBy": item.Created_By,  "createdDate":item.Created_Date, "artistProjectNumber":item.Artist_Project_Number, "artistProjectName": item.Artist_Project_Name, "carServiceOnly": item.Car_Service_Only})
                    }
                    return JSON.parse(JSON.stringify(jsonUnsubmittedTA).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonUnsubmittedTA).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getTAHistory (data: any){        
        var url = this.variables.Url+ this.variables.Controller.submitter + this.variables.submitterService.getTAHistory;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonTAHistory : Array<{"authReqId": string, "createdDate": string, "createdBy": string, "artistProjectName": string, "artistName": string, "artistProjectNumber": string, "statusDesc": string, "carServiceOnly": boolean, "traveler": string, "statusId": string, "inbox": string}> = [];
                if(response.success){
                    response.recordset.forEach(TAHistory);
                    function TAHistory(item: { authreq_id: any; created_date: any; created_by: any; artist_project_name: any ; artist_name: any; artist_project_number: any; status_desc: any; car_service_only: boolean; traveler: any; status_id: any; inbox: any}){
                        jsonTAHistory.push({"authReqId": item.authreq_id, "createdDate": item.created_date,  "createdBy":item.created_by, "artistProjectName":item.artist_project_name, "artistName": item.artist_name, "artistProjectNumber": item.artist_project_number, "statusDesc": item.status_desc, "carServiceOnly": item.car_service_only, "traveler": item.traveler, "statusId": item.status_id, "inbox": item.inbox})
                    }
                    return JSON.parse(JSON.stringify(jsonTAHistory).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonTAHistory).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}