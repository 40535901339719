import React, { Component, CSSProperties } from "react";
import { ICarServiceHistoryParameter, Istate } from "../../../Model/ICarServiceHistory";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CarServiceIncomingCAController } from "../../../Controllers/CarServiceIncomingCAController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import * as Utils from '../../../js/Utils.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import addSubtractDate from 'add-subtract-date';
import { TravellerController } from "../../../Controllers/TravellerController";

export class CarServiceHistory extends Component<ICarServiceHistoryParameter,Istate> {
    constructor(props:ICarServiceHistoryParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let today     =new Date();      
        let startDate =new Date(today.getTime() - (3 * 24 * 60 * 60 * 1000));
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {find:'',searchValue:''},
            selectfields:{searchDays:'',searchString:''},
            errors:{},
            errMsg:"",
            loading:false,    
            authReqId:'',  
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            historyCAGroups:[],            
            loadComplete:false,
            startDate:Utils.FormatDate(startDate),
            endDate:Utils.FormatDate(today),                       
            userTypeId:sessionStorage.getItem("user_type_id"),      
        } 
        this.handleChange = this.handleChange.bind(this);        
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this); 
        this.handleOnSubmit = this.handleOnSubmit.bind(this);     
    }

    componentWillMount(){
        let modal = this.state.modal;
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        var today     =new Date();      
        var startDate =new Date(today.getTime() - (3 * 24 * 60 * 60 * 1000));
        this.setState({ startDate: startDate});     
        this.setState({ endDate: today});
        selectfields['searchDays'] = "3";
        this.changeStartDate();
        this.setState({userId:sessionStorage.getItem("uid")})        
        modal['modalSpinnerShow']=true;  
        fields["searchValue"] = "ar.authreq_id";
        fields["find"] = "";
        selectfields["searchString"] = "AuthReq #";
        selectfields['tracking_grid'] = 'In-Progress';       
        modal['modalSpinnerShow'] = false;
        this.setState({ modal, selectfields, fields });  
        this.getHistory()       
    }

    getHistory()
    {
        
        let modal = this.state.modal;         
        let carServiceIncomingCA= new CarServiceIncomingCAController();
            var sD = new Date (this.state.startDate);
            var curr_date = sD.getDate();
            var curr_month = sD.getMonth() + 1; //Months are zero based
            var curr_year = sD.getFullYear();
            var startDate = curr_month + "/" + curr_date + "/" + curr_year;

            var eD = new Date (this.state.endDate);
            var curr_date = eD.getDate() + 1;
            var curr_month = eD.getMonth() + 1; //Months are zero based
            var curr_year = eD.getFullYear();
            var endDate = curr_month + "/" + curr_date + "/" + curr_year;

        var data={
            beginDate:startDate,
            endDate:endDate,
            searchString:this.state.fields.find,
            searchBy:this.state.fields.searchValue,
            userType: sessionStorage.getItem("user_type_id")
        }
        
        carServiceIncomingCA.getCarServiceHistory(data).then(jsoncarHistorylist => {
            this.setState({ historyCAGroups: JSON.parse(JSON.stringify(jsoncarHistorylist))});             
            //console.log(this.state.historyCAGroups)  
            modal['modalSpinnerShow']=false;        
            this.setState({modal});  
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })  
        
    }

    handleChange = (e: any) => {
        
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0) {
          fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else {
          fields[e.currentTarget.name] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }
    
    handleSelectChange = (e: any) => {
    
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let currentTargetName = e.currentTarget.name;
        if (currentTargetName === "searchString") {
            fields["searchValue"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
            selectfields[e.currentTarget.name] = e.currentTarget.value;
            this.setState({ selectfields, fields });
        }
            else if (currentTargetName === "searchDays") {
            selectfields[e.currentTarget.name] = e.currentTarget.value;
            //console.log(selectfields[e.currentTarget.name]);
            this.setState({ selectfields, fields });
            this.changeStartDate();
        }
        else {
            selectfields[e.currentTarget.name] = e.currentTarget.value;
            this.setState({ selectfields, fields });
        }
    }
    
    changeStartDate() {
        if (this.state.selectfields.searchDays === "all") {
            var date = new Date("01/01/2000");
        }
        else if (this.state.selectfields.searchDays === "other") {
            var date = new Date();
        }
        else {
            var date = new Date();
            date.setDate(date.getDate() - this.state.selectfields.searchDays);
        }
        this.setState({
            startDate: date, endDate: new Date()
        })
    }
    
    handleChangeStartDate = date => {
        
        this.setState({
            startDate: date
        });
    }
    
    handleChangeEndDate = date => {
        
        let selectfields = this.state.selectfields;
        selectfields['searchDays'] = "other";
        this.setState({
            endDate: date, selectfields
        })
    }

    handleOnSubmit = (e: any, currentTargetName) => {
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        this.setState({ modal });    
        this.getHistory()    
    }

    displayTraveller = (authId) =>{
       return this.state.historyCAGroups.filter(p => p.authReqId == authId).map(fil => {
        return (<div>{fil["traveler"]}</div>);                  
       })
    }

    onSelect(authReqId){
        let travellerController = new TravellerController();
        travellerController.getTA(authReqId).then(response => {
            this.props.history.push({pathname:'/admin/carserviceconfirmation',state:{from:"incoming",isView:true}});
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }

    render() {
        const{ loading } = this.state;
         
        const tdStyle = {
            width:'15%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };  
         const divStyle1 = {
          width: '100%',                   
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };

        let authId = '';
              
    return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Car Service History</h4></div>                            
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row style={{marginBottom:"2%"}}>
                                        <Col sm={11}></Col>
                                        <Col sm={1}>
                                            <Button id="submitVoucher" type="button" size="sm" disabled={loading} onClick={() => window.print()} >
                                                    {loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                    />
                                                    )}
                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                    {!loading && <span>Print</span>}
                                                </Button>
                                        </Col> 
                                    </Row>
                                   <Row>                                        
                                       <Col sm={12}>
                                           <Row>
                                                <Col sm={2}>
                                                    <Form.Group>
                                                    <div>
                                                    <Form.Label>For</Form.Label>
                                                        <Form.Control as="select" name="searchDays" id="searchDays" className="form-control-sm" value={this.state.selectfields.searchDays} onChange={this.handleSelectChange}>
                                                        <option value="all">All</option>
                                                        <option value="3">Last 3 days</option>
                                                        <option value="7">Last 7 days</option>
                                                        <option value="14">Last 14 days</option>
                                                        <option value="30">Last 30 days</option>
                                                        <option value="45">Last 45 days</option>
                                                        <option value="60">Last 60 days</option>
                                                        <option value="90">Last 90 days</option>
                                                        <option value="other">Other</option>
                                                    </Form.Control></div>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                <Form.Group>
                                                <div>                    
                                                    <Form.Label>Start Date</Form.Label>
                                                    <DatePicker style={{ fontSize: "12px" }}
                                                    Id="startDate"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChangeStartDate}
                                                    isClearable
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    className="dateStyle" />                    
                                                </div>
                                                </Form.Group>
                                                </Col>                  
                                                <Col sm={2}>
                                                <Form.Group>
                                                <div>                    
                                                    <Form.Label>End Date</Form.Label>
                                                    <DatePicker style={{ fontSize: "12px" }}
                                                    Id="endDate"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleChangeEndDate}
                                                    isClearable
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    className="dateStyle" />
                                                </div>
                                                </Form.Group>                    
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group>
                                                    <Form.Label>Find</Form.Label>
                                                    <div><Form.Control type="text" name="find" className="form-control-sm" value={this.state.fields.find} onChange={this.handleChange}>
                                                    </Form.Control></div>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <Form.Group>
                                                    <Form.Label>In</Form.Label>
                                                    <div><Form.Control as="select" name="searchString" className="form-control-sm" value={this.state.selectfields.searchString} onChange={this.handleSelectChange}>
                                                        <option value="ar.authreq_id">AuthReq #</option>
                                                        <option value="au.name">Submitter</option>
                                                        <option value="etc.description">AuthReq Type</option>
                                                        <option value="ar.artist_project_name">Project</option>
                                                        <option value="TD.First_Name">Traveler First Name</option>
                                                        <option value="TD.Last_Name">Traveler Last Name</option>
                                                    </Form.Control></div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={5}></Col>
                                                <Col sm={6}>
                                                    <Form.Group>
                                                    <Form.Label></Form.Label>
                                                        <div><Button disabled={this.state.loading} name='searchHistory' style={{ marginBottom: "15px" }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name)} title="Click to start search">
                                                        {this.state.loading && (
                                                            <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: "5px", color: "#487afa" }}
                                                            />
                                                        )}
                                                        {this.state.loading && <span style={{ color: "#487afa" }}>wait...</span>}
                                                        {!this.state.loading && <span>Search</span>}
                                                        </Button></div>
                                                    </Form.Group>                    
                                                </Col>                                                                                                                                               
                                            </Row>                                 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th>Auth Req </th>
                                                                <th>Date Submitted </th>
                                                                <th>Traveler</th>
                                                                <th>Submitter</th>  
                                                                <th>Agent Name </th> 
                                                                <th>Status</th>                                                     
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.historyCAGroups.map((prop, key) => {
                                                               if(authId != prop["authReqId"])
                                                               {      
                                                                    authId =  prop["authReqId"]
                                                                    return (
                                                                        <tr style={{textAlign:"center",cursor:"pointer"}} key={key}onClick={() => this.onSelect(prop["authReqId"])}> 
                                                                            <td>{prop["authReqId"]}</td>                                                                                                                         
                                                                            <td>{Utils.FormatDate(prop["createdDate"])}</td>
                                                                            <td>{this.displayTraveller(prop["authReqId"])}</td>
                                                                            <td>{prop["createdBy"]}</td>
                                                                            <td>{prop["travelAgentName"]}</td>
                                                                            <td>{prop["status"]}</td>
                                                                        </tr>                                                                    
                                                                    )
                                                               }
                                                            })}                                                            
                                                        </tbody>
                                                </Table>                                                        
                                            </div>                                        
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}