import {FormGroup} from "../../../node_modules/react-bootstrap";
import React,{ Component } from 'react';

export class FormCheckBox extends Component<IFormCheckBox> {

    render() { 
      const {id, label}  = this.props.properties
         return(<div id={"div"+id} >
           <FormGroup>
              <div>
                <input {...this.props.properties} />
                <span style={{paddingLeft:'5px',fontSize:"12px"}}>{label}</span>
              </div>
          </FormGroup>
          </div>)
      }
    }

  interface IFormCheckBox
  {
    properties:IProperties;
  }

  interface IProperties
  {
    id:string;
    label:string;
    type:any;
    name:string;
    className?:string;
    onChange?:any;
    defaultChecked?:boolean;
    checked?:boolean;
    disabled?:boolean;
  }
  interface Istate
  {

  }