import React, { Component, CSSProperties } from "react";
import { IDepartmentMaintenanceParameter, Istate } from "../../../Model/IDepartmentMaintenance";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { CarServiceMaintenanceController } from "../../../Controllers/CarServiceMaintenanceController";
import { DepartmentMaintenanceController } from "../../../Controllers/DepartmentMaintenanceController";
import '../../../assets/css/Style.css'
import { alert, confirm } from 'react-bootstrap-confirmation';

export class DepartmentMaintenance extends Component<IDepartmentMaintenanceParameter,Istate> {
    constructor(props:IDepartmentMaintenanceParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {description:'',isActive:false},           
            errors:{},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:"",
            labelGroups:[],
            departmentGroups:[],
            departmentUpdate:[],
            labelId:"",
            collDepartment:"",
            loadComplete:false           
        } 
        this.onSubmit = this.onSubmit.bind(this);  
        this.handleSelectChange = this.handleSelectChange.bind(this)
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;     
        
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        carServiceMaintenanceController.getAllLabels().then(jsonGetLabels => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonGetLabels))});            
            //console.log(this.state.labelGroups)            
            this.loadDepartment(sessionStorage.getItem("label_id"))
        }).catch(error => {
            console.error("There was an error!", error);
        })
        //modal['modalSpinnerShow']=false;
       this.setState({ modal}); 
    }

    loadDepartment(labelId:any){
        let modal = this.state.modal; 
        let departmentController = new DepartmentMaintenanceController()
        var data={
            labelId:labelId
        }
        departmentController.getDepartmentsByLabel(data).then(jsonGetDepartment => {
            this.setState({ departmentGroups: JSON.parse(JSON.stringify(jsonGetDepartment))}); 
            //console.log(this.state.departmentGroups) 
            this.setState({labelId:labelId})
            this.setState({loadComplete:true})             
        }).catch(error => {
            modal['modalSpinnerShow']=false;   
            console.error("There was an error!", error);
        })
        modal['modalSpinnerShow']=false; 
    }

    formLabel(){
        return <Form.Group>
          <Form.Label>Select Label</Form.Label>
          <Form.Control as="select" className="form-control form-control-sm" id="labelId" name="labelName" value={this.state.labelId} onChange={this.handleSelectChange}>
           {this.state.labelGroups.map((prop, key) => {
              if (prop["isActive"]) {
                return (
                  <option value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else {
                return (
                  <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                )
              }
            })}
          </Form.Control>
        </Form.Group>
    }

    handleSelectChange(e:any){             
        let modal = this.state.modal;     
        modal['modalSpinnerShow']=true;  
        this.loadDepartment(e.currentTarget.value)       
    }

    handleDescChange(i, event) {
        let collDept =this.state.collDepartment;
         let departmentGroups = [...this.state.departmentGroups];        
         departmentGroups[i]["description"] = event.target.value;
         this.setState({ departmentGroups });
         if(collDept != "")
         {
             if(!collDept.includes(departmentGroups[i]["departmentId"]))
             collDept = collDept + departmentGroups[i]["departmentId"]+","
         }
         else
         collDept = departmentGroups[i]["departmentId"]+","
 
         this.setState({ collDepartment:collDept });
      }

      handleCheckIsActiveChange(i, event) {
        let collDept =this.state.collDepartment;
        let departmentGroups = [...this.state.departmentGroups];        
        departmentGroups[i]["isActive"] = !departmentGroups[i]["isActive"]
        this.setState({ departmentGroups });
        if(collDept != "")
        {
            if(!collDept.includes(departmentGroups[i]["departmentId"]))
            collDept = collDept + departmentGroups[i]["departmentId"]+","
        }
        else
        collDept = departmentGroups[i]["departmentId"]+","    
        this.setState({ collDepartment:collDept });
   }

   handleChange(e:any){
        let fields = this.state.fields;   
        if (e.currentTarget.name === 'description'){
            fields['description'] = e.currentTarget.value;
        }            
        this.setState({ fields});
    }

    handleCheckboxChange(e:any){
        let fields = this.state.fields;       
        if (e.currentTarget.name === 'isActive'){
            fields['isActive'] = !fields['isActive'];
        }       
        this.setState({ fields});
    }

    onSubmit(e:any){
        e.preventDefault();
         let modal = this.state.modal;
         modal['modalSpinnerShow']=true;
         this.setState({errMsg:""})        
         let departmentController = new DepartmentMaintenanceController()
         
        this.state.collDepartment.split(',').map(str => {
            {this.state.departmentGroups.filter(p => p.departmentId == str).map(f => {
                var data = {
                    doBy: "U",
                    departmentId:f.departmentId,
                    description: f.description, 
                    labelId:"",                
                    isActive: f.isActive                     
                }
                //console.log(data)
                departmentController.createUpdateDepartment(data).then(response => {                   
                }).catch(error => {
                    console.error("There was an error!", error)
                })
            })}               
        }); 
        if(this.state.fields.description === "")
        {
            modal['modalSpinnerShow']=false;
            this.setState({modal});
            alert('Department Updated');
            this.props.history.push({pathname:'/admin/home'}); 
        } 
        else
        {           
            var dataDept = {
                doBy: "I",
                departmentId:"",
                description: this.state.fields.description, 
                labelId:this.state.labelId,                
                isActive: this.state.fields.isActive 
            }
            //console.log(dataDept)
            departmentController.createUpdateDepartment(dataDept).then(response => { 
                modal['modalSpinnerShow']=false;
                this.setState({modal});
                alert('Department Inserted..');
                this.props.history.push({pathname:'/admin/home'}); 
            }).catch(error => {
                console.error("There was an error!", error)
            })            
        }         
         modal['modalSpinnerShow']=false;
         this.setState({modal});     
     }
    
    render() {
        const{ loading } = this.state;
         const alignContentTdbilling = {
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const tdStyle = {
            width:'33%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };        
        const alignContentTd = {
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px",
            paddingBottom:'0px'
        };
        const thStyle ={
            width:'33%',            
            border: "2px solid #eee",
            padding:"2px" 
        };        
       
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Department Maintenance</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row>
                                        <Col sm={12} style={{textAlign:"center", marginBottom:"15px"}}>
                                            <Button size="sm" disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Refresh </span>}
                                            </Button>
                                            <Button size="sm" disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Print </span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                       <Col sm={4}>
                                            {this.formLabel()}
                                        </Col>
                                        <Col sm={2}></Col>                                                                          
                                    </Row>
                                    <Row>
                                       <Col sm={12}>
                                       <div>
                                       <Table hover>
                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                    <tr style={{textAlign:"center"}}>
                                                        <th style={thStyle}>Label</th>
                                                        <th style={thStyle}>Department</th>                                                        
                                                        <th style={thStyle}>Active</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.departmentGroups.map((prop, key) => {
                                                        return (
                                                            <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                <td style={tdStyle}>{prop["labelid"]}</td>
                                                                <td style={tdStyle}><input type="text" style={{width:'100%',color:prop["isActive"] ? 'black' : 'red'}} value={prop["description"]||''} onChange={this.handleDescChange.bind(this, key)}/></td>
                                                                <td style={tdStyle}>
                                                                    <input type="checkbox" checked={prop["isActive"]} onChange={this.handleCheckIsActiveChange.bind(this, key)}/>
                                                                </td>
                                                               
                                                            </tr>
                                                        )
                                                     })}
                                                     {this.state.loadComplete ? 
                                                     <tr style={{textAlign:"center"}}>
                                                        <td style={tdStyle}>{this.state.labelId}</td>
                                                        <td style={tdStyle}><input type="text" name="description" style={{width:'100%'}} value={this.state.fields.description} onChange={this.handleChange.bind(this)}/></td>
                                                         <td style={tdStyle}>
                                                            <input type="checkbox" name="isActive" defaultChecked={this.state.fields.isActive} onChange={this.handleCheckboxChange.bind(this)}/>
                                                        </td>
                                                        
                                                     </tr> : ""}
                                                </tbody>
                                        </Table>                                                        
                                        </div>
                                        <div style={{textAlign:"center"}}>
                                        {this.state.loadComplete ? <p style={{textAlign:"left",color:"red",fontSize:"10px"}}>
                                         *Please enter description(department) to add new department for this label</p>:""}
                                            <Button id="submitLabel" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)} variant="success">
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Save</span>}
                                            </Button>
                                        </div>

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>                                                                                                        
                                            </div>
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}