import React, { Component } from "react";
import { ITravellerMaintenanceParameter, Istate } from "../../../Model/ITravellerMaintenance";
import {Container,Row,Col,Form,Alert,Button,Card,Modal,Table} from "../../../../node_modules/react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import '../../../assets/css/Style.css'
import { FormInputs } from "../../CustomComponents/FormInput";
import { NavLink } from 'react-router-dom'
import { TravellerController } from "../../../Controllers/TravellerController";
import { CommonController } from  "../../../Controllers/CommonController";
import * as Const from '../../../js/Constants.js';
import {alert} from 'react-bootstrap-confirmation';
import parse from 'html-react-parser';

export class TravellerMaintainenceAdmin extends Component<ITravellerMaintenanceParameter,Istate> {
    constructor(props:ITravellerMaintenanceParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {sessionTA: sessionStorage.getItem("ta")!, expenseTypeId: parseInt(sessionStorage.getItem("ta_expense_type_id")!), SAPEmpNo:'', projectName:'', artistName:'', projectNumber:'',firstName:'',lastName:'',reason:'',resv:'',lblName:'',lblType:'',lblComments:'',lblFormOnFile:'', lblRestrictions:'',lblSumbitter:'',lblOpCo:'',travellerId:'',divCreateTA:'block',divTA:'none',blnReturn:false,bDupRLN:false,bResult:false,content:'',IsFirstTime:false,bTravelerInfoFormRequired:false,comments:'',SSNo:'',fedID:'',address1:'', address2:'', city: '',stateProvinceId:'', postalCode: '', phone: '', callLetters:'', searchFirstName:'',searchLastName:'',submitter:'',travellerDetailId:'',label_id:''},
            selectfields: {typeId:'',formonFile:'',Restrictions:'',travelAgentName:'', expenseTypeId:'', expenseTypeName:'', selCountryId:'',selStateProvinceId:'', formLabel: ''},
            checkfields: {isCorporateCard:false,manualResv:false,chkDirectorHeadApproval:false},
            optionfields : {employeeTravel:true, conventionProject:false, rdoArtist:false,rdoContestWinner:false,rdoRadioPersonel:false,rdoOverhead:false, rdoFormonfileyes:false,rdoFormonfileno:false,rdoRestrictionsyes:false,rdoRestrictionsno:false, rdoRestrictionsawaitingform:false, rdoIssuer2:false},
            errors:{firstName:'', lastName:'',submitter:'',formLabel:'', reason:'',resv:'',SSNoFedID:'',address1:'',countryId:'',city:'',stateProvinceId:'',postalCode:'',callLetters:'', travelAgentId:'', expenseTypeId:'', SAPEmpNo:'', projectName:'', artistName:'', projectNumber:'',comments:'',rdotypeid:'',rdoformonfile:'',rdoRestrictions:''},
            errMsg:"",
            loading:false,
            travelAgent:[],
            expenseType:[],
            listTravelers:[],
            HMEntity:[],
            Travelers:[],
            jsonTraveller:[],
            RLN: [],
            IsBCD_A: [],
            modal: {modalSpinnerShow: false,modalBody:'',modalShow:false,modalTAShow:false,modalTitle:'',modalTATitle:''},
            FlightInfo:[],
            travelerTypeId:0,
            Country: [],
            StateProvince: [],
            AirLineBCDCode:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
		this.handleCheckChangeEvent = this.handleCheckChangeEvent.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalTAClose = this.handleModalTAClose.bind(this);
        this.handleModalTAShow = this.handleModalTAShow.bind(this);
        this.handleButtonSearch = this.handleButtonSearch.bind(this);
    }

    async componentWillMount(){ 
        let modal = this.state.modal;
        let selectfields = this.state.selectfields;
        //call IGA
        modal['modalSpinnerShow']=true;
        selectfields['formLabel'] = "Please Select";
        let commonController = new CommonController();
        commonController.getLabels().then(jsonLabels => {
        this.setState({
            HMEntity: JSON.parse(JSON.stringify(jsonLabels))
        });
        //let selectfields = this.state.selectfields;
        //selectfields['formLabel'] = sessionStorage.getItem("label_id");
        modal['modalSpinnerShow']=false;
        this.setState({ modal });
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
            console.error("There was an error loading Labels..., " + error)
        });   
    }
    clearControl(){
        let fields = this.state.fields;
        let selectfields=this.state.selectfields;
        let optionfields=this.state.optionfields;
        //fields['searchFirstName'] = "";
        //fields['searchLastName'] = "";
        fields['firstName'] ="";
        fields['lastName']="";
        fields['submitter']="";
        fields['comments']="";
        selectfields['typeId']="";
        if(selectfields["typeId"]===""){
            optionfields["rdoArtist"]=false;
            optionfields["rdoContestWinner"]=false;
            optionfields["rdoOverhead"]=false;
            optionfields["rdoRadioPersonel"]=false;
        }
        selectfields['formonFile']="";
        if(selectfields["formonFile"]===""){
            optionfields["rdoFormonfileyes"]=false;
            optionfields["rdoFormonfileno"]=false;
           }
        selectfields['Restrictions']=""
        if(selectfields["Restrictions"]===""){
            optionfields["rdoRestrictionsno"]=false;
            optionfields["rdoRestrictionsyes"]=false;
            optionfields["rdoRestrictionsawaitingform"]=false;
            }
        selectfields['formLabel']=""
        this.setState({fields,selectfields,optionfields});
    }
    //addTraveler
    handleModalShow = (e:any,typeId) => {
        let fields = this.state.fields;
        fields['searchFirstName'] = ""
        fields['searchLastName'] = ""
        //
        //clear search travelers
        this.state.listTravelers.splice(0, this.state.listTravelers.length);
        let modal= this.state.modal;
        modal['modalTitle'] = 'eTA - Add Traveler';
        modal['modalShow']=true;
        this.setState({modal, fields, travelerTypeId:typeId});
    }
    handleModalTAShow = (e:any, travellerId:any) => {
       this.clearControl();
       this.clearError();
        let modal= this.state.modal;
        let fields =this.state.fields;
		let checkfields=this.state.checkfields;
        let optionfields = this.state.optionfields;
        let selectfields = this.state.selectfields;
        let currentTargetId = e.currentTarget.id;
        
        var travelerTypeId = 0;
        
        //Reset State province
        
        if(e.currentTarget.id === 'createNewTraveler'){
            modal['modalTATitle'] = 'eTA - Add New Traveler';
            modal["modalBody"] = "save";
            {this.showModalFooterButton(this.state.loading)}
        }
        else { //add
            
            modal['modalTATitle'] = 'eTA - Edit Traveler';
            modal["modalBody"] = "update";
            let currentTargetId = e.currentTarget.id;
            fields["lblName"] = e.currentTarget.children[0].innerText;
            selectfields["typeId"] = e.currentTarget.children[1].innerText;
            selectfields["formonFile"] = e.currentTarget.children[2].innerText;
            selectfields["formLabel"]=e.currentTarget.children[3].innerText;
            selectfields["Restrictions"]=e.currentTarget.children[4].innerText;
            fields["comments"] = e.currentTarget.children[5].innerText; 
            fields["submitter"]=e.currentTarget.children[6].innerText;
            fields["travellerId"]=e.currentTarget.children[7].innerText;

            var name = fields["lblName"].split(',');
            fields["firstName"] = this.splitCamelCaseToString(name[1].trim());
            fields["lastName"] = this.splitCamelCaseToString(name[0].trim());
           
            {this.showModalFooterButton(this.state.loading)}
            if(selectfields["typeId"]==="Contest Winner"){
                optionfields["rdoArtist"]=false;
                optionfields["rdoContestWinner"]=true;
                optionfields["rdoOverhead"]=false;
                optionfields["rdoRadioPersonel"]=false;
            }
            if(selectfields["typeId"]==="Artist"){
                optionfields["rdoArtist"]=true;
                optionfields["rdoContestWinner"]=false;
                optionfields["rdoOverhead"]=false;
                optionfields["rdoRadioPersonel"]=false;
            }
            if(selectfields["typeId"]==="Overhead/Other"){
                optionfields["rdoArtist"]=false;
                optionfields["rdoContestWinner"]=false;
                optionfields["rdoOverhead"]=true;
                optionfields["rdoRadioPersonel"]=false;
            }
            if(selectfields["typeId"]==="Radio Personnel"){
                optionfields["rdoArtist"]=false;
                optionfields["rdoContestWinner"]=false;
                optionfields["rdoOverhead"]=false;
                optionfields["rdoRadioPersonel"]=true;
            }
            if(selectfields["formonFile"]==="Yes"){
                optionfields["rdoFormonfileyes"]=true;
                optionfields["rdoFormonfileno"]=false;
               
            }
            if(selectfields["formonFile"]==="No"){
                optionfields["rdoFormonfileyes"]=false;
                optionfields["rdoFormonfileno"]=true;
               
            }
            if(selectfields["Restrictions"]==="Yes"){
                optionfields["rdoRestrictionsno"]=false;
                optionfields["rdoRestrictionsyes"]=true;
                optionfields["rdoRestrictionsawaitingform"]=false;
               
            }
            if(selectfields["Restrictions"]==="None"){
                optionfields["rdoRestrictionsno"]=true;
                optionfields["rdoRestrictionsyes"]=false;
                optionfields["rdoRestrictionsawaitingform"]=false;
               
            }
            if(selectfields["Restrictions"]==="Awaiting Form"){
                optionfields["rdoRestrictionsno"]=false;
                optionfields["rdoRestrictionsyes"]=false;
                optionfields["rdoRestrictionsawaitingform"]=true;
               
            }
            if ( currentTargetId === "formLabel") {
                modal['modalSpinnerShow'] = false;
                selectfields[e.currentTarget.name] = e.currentTarget.value;
                fields["label_id"] = e.currentTarget.value;
                this.setState({ selectfields, fields, modal });
              }
        }
        
        modal['modalTAShow']=true;

        this.setState({modal,fields,checkfields,optionfields,selectfields});     
    }

    handleModalClose = (e : any) => {
        let modal = this.state.modal;
        let loading = this.state.loading;
        modal['modalShow'] = false;
        modal['loading'] = false;
        this.setState({ modal, loading })
    }

    handleModalTAClose = (e : any) => {
        let modal = this.state.modal;
        let loading = this.state.loading;
        modal['modalTAShow'] = false;
        modal['loading'] = false;
        this.setState({ modal, loading })
    }

    showModalFooterButton = (loading) => {
        
        //console.log("this.state.modal.modalBody",this.state.modal.modalBody)
        if(this.state.modal.modalBody === "save") {
          return <div style={{width: "100%"}}><Row>
            <Col md={3}></Col>
            <Col md={6} style={{textAlign:"center"}}>
                <Button style={{height: 'auto'}} size="sm" id="save" name="save" onClick={(e) => this.handleButtonSearch(e)}>Save</Button>
                <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="saveCancel" name="saveCancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
            </Col>
            <Col md={3}></Col>
        </Row></div>
        }
         else {
            return <div style={{width: "100%"}}><Row>
            <Col md={3}></Col>
            <Col md={6} style={{textAlign:"center"}}>
                <Button style={{height: 'auto'}} size="sm" id="update" name="update" onClick={(e) => this.handleButtonSearch(e)}>Update</Button>
                <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="updateCancel" name="updateCancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
            </Col>
            <Col md={3}></Col>
        </Row></div>
        }
      
    }
    formLabel = () => {
        return <Form.Group>
          <Form.Label>HFM Entity</Form.Label>
          <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} onChange={this.handleSelectChange}>
            <option value={-1}>{"Please Select"}</option>
            {this.state.HMEntity.map((prop, key) => {
              if (prop["is_active"] === "Yes") {
                return (
                  <option value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else {
                return (
                  <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                )
              }
            })}
          </Form.Control>
          {this.state.errors.formLabel != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.formLabel}</div> : <></> }
        </Form.Group>
      }

    showModalBody = () => {       
        return <div id="divTravelerSave">
            <Row>
                <Col md={6}>
                    <FormInputs
                        properties={{id:"firstName",label:"First Name",type:"text",name:"firstName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.firstName,onChange:this.handleChange,autocomplete:'off'}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.firstName}
                    />
                </Col>
                <Col md={6}>
                    <FormInputs
                        properties={{id:"lastName",label:"Last Name",type:"text",name:"lastName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.lastName,onChange:this.handleChange,autocomplete:'off'}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.lastName}
                    />
                </Col>
               
            </Row>
            <Row>
            <Col sm={2} >
              <Form.Label>Type</Form.Label> 
              </Col>

            <Col sm={6}>
                <Form.Group>
                <Form.Check type="radio" label="Artist" name="typeId"  id="Artist" onChange={(e)=>this.handleOptionChange(e)} title="Artist" checked={this.state.optionfields.rdoArtist} value="Artist"/>
                <Form.Check type="radio" label="Contest Winner" name="typeId" id="ContestWinner" onChange={(e)=>this.handleOptionChange(e)} title="ContestWinner" checked={this.state.optionfields.rdoContestWinner} value="Contest Winner"/>
                <Form.Check type="radio" label="Overhead/Other" name="typeId"  id="Overhead" onChange={(e)=>this.handleOptionChange(e)} title="Overhead" checked={this.state.optionfields.rdoOverhead} value="Overhead/Other"/>
                <Form.Check type="radio" label="Radio Personnel" name="typeId"  id="RadioPersonnel" onChange={(e)=>this.handleOptionChange(e)} title="RadioPersonnel" checked={this.state.optionfields.rdoRadioPersonel} value="Radio Personnel"/>
                {this.state.errors.rdotypeid !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.rdotypeid}</div> : <></> }
                </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col sm={2} >
              <Form.Label>Form on file?</Form.Label> 
              </Col>
            <Col sm={6}>
                <Form.Group>
                <Form.Check type="radio" label="Yes" name="formonFile"  id="Formonfileyes" onChange={(e)=>this.handleOptionChange(e)} title="Formonfileyes" defaultChecked={this.state.optionfields.rdoFormonfileyes}  checked={this.state.optionfields.rdoFormonfileyes} value="1"/>
                <Form.Check type="radio" label="No" name="formonFile"  id="Formonfileno" onChange={(e)=>this.handleOptionChange(e)} title="Formonfileyes" defaultChecked={this.state.optionfields.rdoFormonfileno} checked={this.state.optionfields.rdoFormonfileno} value="0"/>
                {this.state.errors.rdoformonfile !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.rdoformonfile}</div> : <></> }
                </Form.Group>
                </Col>
            </Row>
            <Row>
            <Col sm={2}>
              <Form.Label>Restrictions</Form.Label> 
              </Col>
            <Col sm={6}>
            <Form.Group>
                <Form.Check type="radio" label="Yes" name="Restrictions"  id="Restrictionsyes" onChange={(e)=>this.handleOptionChange(e)} title="Restrictionsyes" checked={this.state.optionfields.rdoRestrictionsyes} value="1"/>
                <Form.Check type="radio" label="No" name="Restrictions"  id="Restrictionsno" onChange={(e)=>this.handleOptionChange(e)} title="Restrictionsno" checked={this.state.optionfields.rdoRestrictionsno} value="0"/>
                <Form.Check type="radio" label="Awaiting Form" name="Restrictions"  id="Restrictionsawaitingform" onChange={(e)=>this.handleOptionChange(e)} title="Restrictionsawaitingform" checked={this.state.optionfields.rdoRestrictionsawaitingform} value="2"/>
                {this.state.errors.rdoRestrictions !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.rdoRestrictions}</div> : <></> }
                </Form.Group>
                </Col>
            </Row>
            <Row>
        
            <Col md={6}>
            <Form.Group>
            <Form.Label>Comments</Form.Label> 
            <Form.Control as="textarea" rows={3} id="comments" type="text "name="comments" className="form-control" value={this.state.fields.comments} onChange={(e)=>this.handleChange(e)}></Form.Control>
            {this.state.errors.comments !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.comments}</div> : <></> }
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6}>
                    <FormInputs
                        properties={{id:"submitter",label:"Submitter",type:"text",name:"submitter",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.submitter,onChange:this.handleChange,autocomplete:'off'}}
                        fieldRequired={true}
                        errorMsg={this.state.errors.submitter}
                    />
                </Col>
                
            </Row>
            <Row>
            <Col md={6}>{this.formLabel()}</Col>   
            </Row>
             </div>
    }
    
    handleButtonSearch=(e:any)=>{
        
        //console.log("****************checkfields***************")
        //console.log("Checkfields:",this.state.checkfields)
        //console.log("optionfields:",this.state.optionfields)
        let modal = this.state.modal;
        var data = {}
        var eventCurrentTargetId = e.currentTarget.id;
        if (eventCurrentTargetId === "saveCancel" || eventCurrentTargetId === "updateCancel" ){
            modal["modalTAShow"] = false;
            
        }

        else if(eventCurrentTargetId === "search"){
            var firstName = null;
            var lastName = null;
            if(this.state.fields.searchFirstName.trim().length != 0){
                firstName = this.state.fields.searchFirstName
            }
            if(this.state.fields.searchLastName.trim().length != 0){
                lastName = this.state.fields.searchLastName
            }
            let modal = this.state.modal;
            modal["modalSpinnerShow"] = true;
            let travellerController = new TravellerController();
            travellerController.etaTravelerSearchAdmin(firstName,lastName,null).then(jsonTraveller => {
                this.setState({                           
                    listTravelers : JSON.parse(JSON.stringify(jsonTraveller)),
                });
                //console.log("etaTravelerSearch:", this.state.listTravelers)
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error!", error)
            })
        }
        
       else if(eventCurrentTargetId === "clear"){
           
            let fields = this.state.fields;
            fields["searchFirstName"]="";
            fields["searchLastName"]="";
            this.setState({fields});
            this.clearControl();
        }
        //save and update
        else {
            
            if(this.validateForm()){
                var typeId;
                if(this.state.selectfields.typeId==="Artist"){
                    typeId=1;
                }
                else if(this.state.selectfields.typeId==="Contest Winner"){
                    typeId=2;
                }
                else if(this.state.selectfields.typeId==="Overhead/Other"){
                    typeId=3;
                }
                else //Radio Personnel
                {
                    typeId=4;
                }
                if(eventCurrentTargetId === "save"){              
                    let travellerController = new TravellerController();
                    var newTraveller = {
                        firstName: this.state.fields.firstName,
                        lastName: this.state.fields.lastName,
                        type: typeId,
                        formonFile:(this.state.optionfields.rdoFormonfileyes)?1:0,
                        Restrictions:(this.state.optionfields.rdoRestrictionsyes)?1:(this.state.optionfields.rdoRestrictionsno)?0:2,
                        notes: this.state.fields.comments,
                        submitter: this.state.fields.submitter,
                        formLabel:this.state.selectfields.formLabel
                    }
                    //console.log("insertTraveler-Request", newTraveller);
                    travellerController.insertTraveler(newTraveller).then(response => {
                        //console.log("insertTraveler-Response", response)
                        if(response.p_ErrorCode > 0){
                            if(response.p_ErrorCode === 1){
                                alert("The traveler you are trying to add already exists in the eTA system.")
                            }
                            else{
                                alert("Unable to add new traveler.")
                            }
                        }
                        modal['modalTAShow'] = false;
                        this.setState(modal);
                    }).catch(error => {
                        modal['modalTAShow'] = false;
                        this.setState(modal);
                        console.error("There was an error!", error)
                    })
                }
                else
                {
                    let modal = this.state.modal;
                    modal["modalSpinnerShow"] = true;
                    let travellerController = new TravellerController();
                    var updateTraveller = {
                        firstName: this.state.fields.firstName,
                        lastName: this.state.fields.lastName,
                        type: typeId,
                        formonFile:(this.state.optionfields.rdoFormonfileyes)?1:0,
                        Restrictions:(this.state.optionfields.rdoRestrictionsyes)?1:(this.state.optionfields.rdoRestrictionsno)?0:2,
                        notes: this.state.fields.comments,
                        submitter: this.state.fields.submitter,
                        formLabel:this.state.selectfields.formLabel,
                        travelerId:this.state.fields.travellerId
                    }                    
                    //console.log("updateTravelerInfo-request",updateTraveller);
                    travellerController.updateTravelerInfo(updateTraveller).then(response => {
                        //console.log("updateTravelerInfo-response",response);
                        //console.log(response);
                        var firstName = null;
                        var lastName = null;
                        if(this.state.fields.searchFirstName.trim().length != 0){
                            firstName = this.state.fields.searchFirstName
                        }
                        if(this.state.fields.searchLastName.trim().length != 0){
                            lastName = this.state.fields.searchLastName
                        }
                        let travellerController = new TravellerController();
                        travellerController.etaTravelerSearchAdmin(firstName,lastName,null).then(jsonTraveller => {
                            this.setState({                           
                                listTravelers : JSON.parse(JSON.stringify(jsonTraveller)),
                            });
                            //console.log("etaTravelerSearch:", this.state.listTravelers)
                            modal["modalSpinnerShow"] = false;
                            this.setState({modal});
                        }).catch(error => {
                            modal["modalSpinnerShow"] = false;
                            modal['modalTAShow'] = false;
                            this.setState({modal});
                            console.error("There was an error!", error)
                        })                       
                        modal['modalTAShow'] = false;
                        this.setState(modal);
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        modal['modalTAShow'] = false;
                        this.setState({modal});
                        console.error("There was an error in UpdateTravelerInfo...", error);
                    })
                }
            }
        }        
        this.setState({modal});
    }

    searchLastNameStartsWith = (c) => {
        let modal = this.state.modal;
        modal["modalSpinnerShow"] = true;
        let travellerController = new TravellerController();
        travellerController.etaTravelerSearchAdmin(null,null,c).then(jsonTraveller => {
            //console.log("etaTravelerSearchAdmin:", jsonTraveller)
            this.setState({                           
                listTravelers : JSON.parse(JSON.stringify(jsonTraveller)),
            });
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error!", error)
        })
    }
    async travelerInfoFormRequired(authReqId){
        let commonController = new CommonController();
        return await commonController.getTravelerInfoFormRequired(authReqId).then(response => {
            //
            return response.Travel_Form;
        }).catch(error => {
            console.error("There was an error!", error)
        });
    }
    
    displayTravelerSearch=()=>{
        return <div id="divTravelerSearch">
                <Row>
                    <Col md={3}></Col>
                    <Col md={3}>
                        <FormInputs
                            properties={{id:"searchFirstName",label:"First Name",type:"text",name:"searchFirstName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.searchFirstName,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                            errorMsg={this.state.errors.searchFirstName}
                        />
                    </Col>
                    <Col md={3}>
                        <FormInputs
                            properties={{id:"searchLastName",label:"Last Name",type:"text",name:"searchLastName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.searchLastName,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                            errorMsg={this.state.errors.searchLastName}
                        />
                    </Col>
                    <Col md={3}></Col>
                </Row>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4} style={{textAlign:"center"}}>
                        <Button style={{height: 'auto'}} size="sm" id="search" name="search" onClick={(e) => this.handleButtonSearch(e)}>Search</Button>
                        <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="clear" name="clear" onClick={(e) => this.handleButtonSearch(e)}>Clear</Button>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col md={12} style={{textAlignLast: "center"}}><div className="text-dark font-size-xs small">Click on the first Letter of the Last Name to list Travelers</div></Col>
                </Row>
                <Row>
                    <Col md={12} className="small" style={{textAlignLast: "center"}}>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("A")}><a>A</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("B")}><a>B</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("C")}><a>C</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("D")}><a>D</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("E")}><a>E</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("F")}><a>F</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("G")}><a>G</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("H")}><a>H</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("I")}><a>I</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("J")}><a>J</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("K")}><a>K</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("L")}><a>L</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("M")}><a>M</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("N")}><a>N</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("O")}><a>O</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("P")}><a>P</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("Q")}><a>Q</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("R")}><a>R</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("S")}><a>S</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("T")}><a>T</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("U")}><a>U</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("V")}><a>V</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("W")}><a>W</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("X")}><a>X</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("Y")}><a>Y</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("Z")}><a>Z</a></NavLink>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col md={12}>
                    <div className="scroll" style={{ width: '100%',height: "250px" }}>
                    <Table hover id="TblTraveler">
                        <thead>
                        <tr>
                            <th>Traveler Name</th>                                                     
                            <th>Type</th> 
                            <th>Form On File</th> 
                            <th>HFM Entity</th> 
                            <th>Restrictions</th> 
                            <th>Comments</th>    
                        </tr>
                        </thead>
                        {this.state.listTravelers.length === 0 ? 
                            <tbody>
                                <tr>
                                    <td colSpan={8} align="center" className="text-danger font-size-xs small">No match records. Please try again.</td>
                                </tr>
                            </tbody>
                        :
                            <tbody>
                            {this.state.listTravelers.map((prop, key) => 
                                <tr id="add" onClick={(e) => this.handleModalTAShow(e, '')}>
                                    <td>{prop["name"]}</td>
                                    <td>{prop["type"]}</td>
                                    <td>{prop["formOnFile"]}</td>
                                    <td>{prop["opCo"]}</td>
                                    <td>{prop["restrictions"]}</td>
                                    <td>{prop["comments"]}</td>
                                    <td hidden>{prop["submitter"]}</td>
                                    <td hidden>{prop["id"]}</td>
                                </tr>
                            )}
                            </tbody>
                        }
                    </Table>
                    </div>
                    </Col>
                </Row>
            </div>
    }

    handleChange(e:any){
        
        let fields= this.state.fields;
        let errors=this.state.errors;
        var eCurrentTargetName = e.currentTarget.name;
        if(eCurrentTargetName === 'projectName'){
            var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/)
            if (regexp.test(e.currentTarget.value)){
                fields[e.currentTarget.name] = e.currentTarget.value;
                
            }
            else{
                alert("Project Name must be alphanumeric only...")
            }
        }
        else if(eCurrentTargetName === 'firstName' || eCurrentTargetName === 'lastName'){
            fields[e.currentTarget.name] = this.splitCamelCaseToString(e.currentTarget.value);
            // errors[firstName]=''
        }
        else if(eCurrentTargetName === 'resv'){
            fields[e.currentTarget.name] = e.currentTarget.value.toUpperCase();
        }
        else{
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({ fields });
    }
    //Apply CamelCase
    splitCamelCaseToString(s) {
        return s.split(/(?=[A-Z])/).map(function(p) {
            return p.charAt(0).toUpperCase() + p.slice(1);
        }).join(' ');
    }
    handleSelectChange(e: any) {
        
    let fields = this.state.fields;
    let modal = this.state.modal;
    let selectfields = this.state.selectfields;
    let currentTargetName = e.currentTarget.name;

    if (currentTargetName === "formLabel") {
        modal['modalSpinnerShow'] = false;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        fields["label_id"] = e.currentTarget.value;
        this.setState({ selectfields, fields, modal });
      }
    
        
    
        //console.log("Select Fields onchange: ", this.state.selectfields)
    }

    handleCheckChange(e, index){
        //
        //console.log(e.currentTarget);
        const {name, value} = e.currentTarget;
        const Travelers = [...this.state.Travelers];
        Travelers[index][name] = !Travelers[index][name];
        this.setState({ Travelers });
        //console.log("handleCheckChange:", this.state.Travelers)
    }
	handleCheckChangeEvent=(e:any)=>{
        
        let checkfields = this.state.checkfields;
        let fields = this.state.fields;
        let errors = this.state.errors;
        
        this.setState({
            checkfields, fields, errors
        });

    }
    handleOptionChange(e:any){
        
        let selectfields=this.state.selectfields;
        let optionfields = this.state.optionfields;
        let errors=this.state.errors;
        let currentTargetId = e.currentTarget.id;
      
        if(e.currentTarget.id==="Artist"){
            //alert(optionfields["rdoArtist"]);
            optionfields["rdoArtist"]=true;
            optionfields["rdoContestWinner"]=false;
            optionfields["rdoOverhead"]=false;
            optionfields["rdoRadioPersonel"]=false;   
            selectfields[e.currentTarget.name]=e.currentTarget.value;
            errors["rdotypeid"]=""
            
        }
        if(e.currentTarget.id==="ContestWinner"){
            optionfields["rdoArtist"]=false;
            optionfields["rdoContestWinner"]=true;
            optionfields["rdoOverhead"]=false;
            optionfields["rdoRadioPersonel"]=false;
            selectfields[e.currentTarget.name]=e.currentTarget.value;   
            errors["rdotypeid"]=""
        }
        if(e.currentTarget.id==="Overhead"){
            optionfields["rdoArtist"]=false;
            optionfields["rdoContestWinner"]=false;
            optionfields["rdoOverhead"]=true;
            optionfields["rdoRadioPersonel"]=false; 
            selectfields[e.currentTarget.name]=e.currentTarget.value;  
            errors["rdotypeid"]=""
            
        }
        if(e.currentTarget.id==="RadioPersonnel"){
            optionfields["rdoArtist"]=false;
            optionfields["rdoContestWinner"]=false;
            optionfields["rdoOverhead"]=false;
            optionfields["rdoRadioPersonel"]=true;  
            selectfields[e.currentTarget.name]=e.currentTarget.value; 
            errors["rdotypeid"]=""
            
        }
        
        if(e.currentTarget.id==="Formonfileyes"){

            optionfields["rdoFormonfileyes"]=true;
            optionfields["rdoFormonfileno"]=false;
            selectfields[e.currentTarget.name]=e.currentTarget.value;
            errors["rdoformonfile"]=""
        }

        if(e.currentTarget.id==="Formonfileno"){
            optionfields["rdoFormonfileyes"]=false;
            optionfields["rdoFormonfileno"]=true;
            selectfields[e.currentTarget.name]=e.currentTarget.value;
            errors["rdoformonfile"]=""
        }
       

     if(e.currentTarget.id==="Restrictionsyes")
     {
        optionfields["rdoRestrictionsyes"]=true;
        optionfields["rdoRestrictionsno"]=false;
        optionfields["rdoRestrictionsawaitingform"]=false;
        selectfields[e.currentTarget.name]=e.currentTarget.value;
        errors["rdoRestrictions"]=""

     }
     
     if(e.currentTarget.id==="Restrictionsno")
     {
        optionfields["rdoRestrictionsyes"]=false;
        optionfields["rdoRestrictionsno"]=true;
        optionfields["rdoRestrictionsawaitingform"]=false;
        selectfields[e.currentTarget.name]=e.currentTarget.value;
        errors["rdoRestrictions"]="";

     }
     if(e.currentTarget.id==="Restrictionsawaitingform")
     {
        optionfields["rdoRestrictionsyes"]=false;
        optionfields["rdoRestrictionsno"]=false;
        optionfields["rdoRestrictionsawaitingform"]=true;
        selectfields[e.currentTarget.name]=e.currentTarget.value;
        errors["rdoRestrictions"]="";

     }
        
        
        this.setState({ optionfields,travelerTypeId:e.currentTarget.value,errors,selectfields});
        //console.log("Option Events: ",this.state.optionfields)
    }
    checkSpecialChar(e:any){
        if (!((e.keyCode >= 65) && (e.keyCode <= 90) || (e.keyCode >= 97) && (e.keyCode <= 122) || (e.keyCode >= 48) && (e.keyCode <= 57) || (e.keyCode == 32))) {
        e.returnValue = true;
        return;
        }
        e.returnValue = false;
    }

    

    onSubmit (e:any) {
        e.preventDefault();
        this.setState({ loading: true });
    }

    parseHTML(el){
        
        if (el !== undefined && el !== null){
            var arr = el.split("\n");
            //console.log("parseHTML Array :", arr)
            var str="";
            arr.forEach(element => {
                str = str + "<div>"+element+"</div>" 
            });
            //console.log("parse HTML:",str);
            return parse(str);
        }
        else
        {
            return el;
        }
    }
    
   clearError(){
       let errors = this.state.errors;
	   errors["firstName"]=""
       errors["lastName"]=""
       errors["submitter"]=""
       errors["rdotypeid"]=""
       errors["rdoformonfile"]=""
       errors["rdoRestrictions"]=""
       errors["formLabel"]=""
       this.setState({errors});
   }
   validateForm(){
       
       this.clearError();

       let fields = this.state.fields;
       let selectfields=this.state.selectfields;
       let errors = this.state.errors;
       let optionfields=this.state.optionfields;
       let formIsValid = true;

    if (!fields["firstName"]) {
        formIsValid = false;
        errors["firstName"] = "Please enter traveler's First name";
    }
    if (!fields["lastName"]) {
        formIsValid = false;
        errors["lastName"] = "Please enter traveler's last name";
    }
    if (!fields["submitter"]) {
        formIsValid = false;
        errors["submitter"] = "Please enter Submitter's name";
    }
   
    if(!optionfields["rdoArtist"]&&!optionfields["rdoContestWinner"]&&!optionfields["rdoOverhead"]&&!optionfields["rdoRadioPersonel"]){
        formIsValid = false;
        errors["rdotypeid"]="please select traveller type"
    }
    if(!optionfields["rdoFormonfileyes"]&&!optionfields["rdoFormonfileno"]){
        formIsValid = false;
        errors["rdoformonfile"]="please indicate whether there is a travel form on file for this traveler"
    }
    if(!optionfields["rdoRestrictionsyes"]&&!optionfields["rdoRestrictionsno"]&&!optionfields["rdoRestrictionsawaitingform"]){
        formIsValid = false;
        errors["rdoRestrictions"]="please indicate traveler Restrictions"
    }

	if(selectfields["formLabel"]==="Please Select"){
        formIsValid = false;	
        errors["formLabel"]="Please select OpCo or HFM Entity.";
    }
    

    // optionfields["rdoArtist"]=true;
    // optionfields["rdoContestWinner"]=false;
    // optionfields["rdoOverhead"]=false;
    // optionfields["rdoRadioPersonel"]=false; 

       
       this.setState({ errors });

       return formIsValid;
   }
   trimStr(str)
   {
       return str.replace(/^\s*|\s*$/g,"");
   } 
    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <Modal show={this.state.modal.modalShow}
                onHide={this.handleModalClose} 
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-50w"
                centered
                >
                <Modal.Header closeButton>
                    {this.state.modal.modalTitle}
                </Modal.Header>
                <Modal.Body className="show-grid" style={{padding:'0px'}} >
                    <Container fluid style={{paddingLeft:'0px',paddingRight:'0px'}}>
                         <Card style={{ width: '100%',marginBottom: '0px' }}>
                            <Card.Body>
                                {this.displayTravelerSearch()}
                            </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal id="modalTA" show={this.state.modal.modalTAShow}
                onHide={this.handleModalTAClose} 
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-md"
                centered
                >
                <Modal.Header closeButton>
                    {this.state.modal.modalTATitle}
                </Modal.Header>
                <Modal.Body className="show-grid" style={{padding:'0px'}} >
                    <Container fluid style={{paddingLeft:'0px',paddingRight:'0px'}}>
                         <Card style={{ width: '100%',marginBottom: '0px' }}>
                            <Card.Body>
                                {this.showModalBody()}
                            </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {this.showModalFooterButton(this.state.loading)}
                </Modal.Footer>
            </Modal>
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                {this.displayTravelerSearch()}  
                                <div style={{width: "100%"}}>
                                    <Row>
                                        <Col md={12} style={{textAlignLast: "center"}}><div className="text-dark font-size-xs small">- Click on the Traveler to select -</div></Col>
                                    </Row>
                                    <Row>
                                    <Col md={3}></Col>
                                    <Col md={6} style={{textAlign:"center"}}>
                                        <Button size="sm" id="createNewTraveler" name="createNewTraveler" style={{height:'auto',color:(this.state.listTravelers.length===0)? "grey" :"#487afa"}}  disabled={(this.state.listTravelers.length===0)? true:false} onClick={(e) => this.handleModalTAShow(e, '')}>Add New Traveler</Button>
                                    </Col>
                                    <Col md={3}></Col>
                                    </Row>
                                </div>
                            </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}