import React, { Component } from "react";
import { IContentWinnerParameter, Istate } from "../../../Model/IContentWinner";
import {Container,Row,Col,Form,Alert,Button,Card,Table} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { ContentwinnerController } from "../../../Controllers/ContentWinnerController";
import '../../../assets/css/Style.css'
import * as Utils from '../../../js/Utils.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CommonController } from "../../../Controllers/CommonController";

export class ContentWinnerListView extends Component<IContentWinnerParameter,Istate> {
    constructor(props:IContentWinnerParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let today     =new Date();      
        let startDate =new Date(today.getTime() - (3 * 24 * 60 * 60 * 1000));
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {find:'',searchValue:''},
            selectfields:{searchDays:'',searchString:''},
            errors:{},
            errMsg:"",
            loading:false,  
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            contentWinnerGroups:[],            
            loadComplete:false,
            startDate:Utils.FormatDate(startDate),
            endDate:Utils.FormatDate(today), 
        } 
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this); 
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleChange = this.handleChange.bind(this); 
    }

    componentWillMount(){
        let modal = this.state.modal;
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        var today     =new Date();      
        var startDate =new Date(today.getTime() - (3 * 24 * 60 * 60 * 1000));
        this.setState({ startDate: startDate});     
        this.setState({ endDate: today});
        selectfields['searchDays'] = "3";
        this.changeStartDate();
        this.setState({userId:sessionStorage.getItem("uid")})        
        modal['modalSpinnerShow']=true;  
        fields["searchValue"] = "ar.authreq_id";
        fields["find"] = "";
        selectfields["searchString"] = "AuthReq #";              
        modal['modalSpinnerShow'] = false;
        this.setState({ modal, selectfields, fields });  
         /* UnLocking TA for the current users */ 
        let comController = new CommonController();
        var data = {
            authreqid: sessionStorage.getItem("ta"),
            userid: sessionStorage.getItem("uid"),
            shadowid: sessionStorage.getItem("shadow"),
            check: ""
        }
        comController.updateUnLockAuthreq(data).then(response => {
        }).catch(error => {
            console.error("There was an error!", error)
        })      
    }

    getContentWinnerList()
    {
        
        let modal = this.state.modal;         
        let contentwinnerController= new ContentwinnerController();
            var sD = new Date (this.state.startDate);
            var curr_date = sD.getDate();
            var curr_month = sD.getMonth() + 1; //Months are zero based
            var curr_year = sD.getFullYear();
            var startDate = curr_month + "/" + curr_date + "/" + curr_year;

            var eD = new Date (this.state.endDate);
            var curr_date = eD.getDate() + 1;
            var curr_month = eD.getMonth() + 1; //Months are zero based
            var curr_year = eD.getFullYear();
            var endDate = curr_month + "/" + curr_date + "/" + curr_year;

        var data={
            searchDaysBy:'',
            beginDate:startDate,
            endDate:endDate,
            searchFind:this.state.fields.find,
            findBy:this.state.fields.searchValue,
            userID: sessionStorage.getItem("uid")
        }
        
        contentwinnerController.getContentWinnerList(data).then(jsonContentWinnerlist => {
            this.setState({ contentWinnerGroups: JSON.parse(JSON.stringify(jsonContentWinnerlist))}); 
            modal['modalSpinnerShow']=false;        
            this.setState({modal});  
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })
        
    }

    changeStartDate() {
        if (this.state.selectfields.searchDays === "all") {
            var date = new Date("01/01/2000");
        }
        else if (this.state.selectfields.searchDays === "other") {
            var date = new Date();
        }
        else if (this.state.selectfields.searchDays === "TDate") {
            var date = new Date(this.state.startDate);
        }
        else if (this.state.selectfields.searchDays === "CDate") {
            var date = new Date(this.state.startDate);
        }
        else {
            var date = new Date();
            date.setDate(date.getDate() - this.state.selectfields.searchDays);
        }
        this.setState({
            startDate: date, endDate: new Date()
        })
    }
    
    handleChangeStartDate = date => {
        
        this.setState({
            startDate: date
        });
    }
    
    handleChangeEndDate = date => {
       
        /*let selectfields = this.state.selectfields;
        selectfields['searchDays'] = "other";*/
        this.setState({
            endDate: date
        })
    }

    handleSelectChange = (e: any) => {
       
        let fields = this.state.fields;
        let modal = this.state.modal;
        let selectfields = this.state.selectfields;
        let currentTargetName = e.currentTarget.name;
            if (currentTargetName === "searchString") {
                fields["searchValue"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
                selectfields[e.currentTarget.name] = e.currentTarget.value;
                this.setState({ selectfields, fields });
            }
                else if (currentTargetName === "searchDays") {
                selectfields[e.currentTarget.name] = e.currentTarget.value;
                this.setState({ selectfields, fields });
                this.changeStartDate();
            }
            else {
                selectfields[e.currentTarget.name] = e.currentTarget.value;
                this.setState({ selectfields, fields });
            }
        }

    handleChange = (e: any) => {
            
            let fields = this.state.fields;
            if (e.currentTarget.value.trim().length > 0) {
              fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else {
              fields[e.currentTarget.name] = e.currentTarget.value.trim();
            }
            this.setState({ fields });
        }

    handleOnSubmit = (e: any, currentTargetName) => {
            let modal = this.state.modal;
            modal['modalSpinnerShow'] = true;
            if (this.ValidateValues())
            {
                this.setState({ modal });    
                this.getContentWinnerList()
            }    
        }

        ValidateValues(){ 
            let errors = this.state.errors;
            let modal = this.state.modal; 
            let formIsValid = true;             
            if (this.state.startDate === "" || this.state.startDate === null) {
            formIsValid = false;
            errors["startDate"] = "Enter Start Date";   
            modal['modalSpinnerShow']=false; 
            }         
            if (this.state.endDate === "" || this.state.endDate === null) {
            formIsValid = false;
            errors["endDate"] = "Enter End Date";   
            modal['modalSpinnerShow']=false; 
            }            
                    
            this.setState({errors,modal});
            return formIsValid;
        }

    

    displayTraveler = (authId,traveler) =>{
        var count = 0 
        return this.state.contentWinnerGroups.filter(p => p.authReqId == authId && p.traveler == traveler).map(fil => {                            
            if(count  == 0)
            {
                count = count + 1;
                return (<div>
                            <div>
                                <Row><Col style={{color:"red",textAlign:"left"}}>{fil["travelPurpose"]}</Col></Row>
                                <Row><Col style={{textAlign:"left"}}>{fil["traveler"]}</Col></Row>
                                <Row><Col style={{textAlign:"left"}}>SSN: {fil["ssn"]}</Col></Row>
                                <Row><Col style={{textAlign:"left"}}>{fil["address1"]}</Col></Row>
                                <Row><Col style={{textAlign:"left"}}>{fil["address2"]}</Col></Row>
                                <Row><Col style={{textAlign:"left"}}>{fil["city"]},&nbsp;{fil["state"]}&nbsp;&nbsp;{fil["zip"]}</Col></Row>
                                <Row><Col style={{textAlign:"left"}}>{fil["country"]}&nbsp;{fil["phone"]}</Col></Row>
                            </div>
                    </div>)                   
            }              
        })
    }

    displayProjDetails = (authId,traveler) =>{
        var count = 0 
        return this.state.contentWinnerGroups.filter(p => p.authReqId == authId && p.traveler == traveler).map(fil => {                            
            if(count  == 0)
            {
                count = count + 1;
                return (<div>
                            <div>                                    
                                <Row><Col style={{textAlign:"left",marginLeft:"10%"}}>{fil["labelId"]}&nbsp; • &nbsp;{fil["artistProjectNumber"]} </Col></Row>
                                <Row><Col style={{textAlign:"left",marginLeft:"10%"}}>{fil["artistProjectName"]}</Col></Row>
                                <Row><Col style={{textAlign:"left",marginLeft:"10%"}}>{fil["artistName"]}</Col></Row>                                   
                            </div>
                    </div>)                   
            }              
        })
    }

    formatCurrency = (amount) => {
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    displayCost = (authId,traveler) =>{
    var count = 0 
    var disCount = 0 
    return this.state.contentWinnerGroups.filter(p => p.authReqId == authId && p.traveler == traveler).map(fil => {                            
        if(count  == 0)
        {
            count = count + 1;      
            if (fil["airTotal"] > 0) 
                disCount = disCount + 1; 
            if (fil["hotelTtotal"] > 0) 
                disCount = disCount + 1; 
            if (fil["rentalCarTotal"] > 0) 
                disCount = disCount + 1; 
            if (fil["carServiceTotal"] > 0) 
                disCount = disCount + 1; 
            if (fil["carService2Total"] > 0) 
                disCount = disCount + 1;                 
            return (<div>
                        <div> 
                            {fil["airTotal"] > 0 ? <Row><Col style={{textAlign:"right",marginLeft:"10%"}}>Air:&nbsp;</Col><Col style={{textAlign:"right"}}>{this.formatCurrency(fil["airTotal"]) }</Col></Row>  : ""}    
                            {fil["hotelTtotal"] > 0 ? <Row><Col style={{textAlign:"right",marginLeft:"10%"}}>Hotel:&nbsp;</Col><Col style={{textAlign:"right"}}>{this.formatCurrency(fil["hotelTtotal"])}</Col></Row> : ""} 
                            {fil["rentalCarTotal"] > 0 ? <Row><Col style={{textAlign:"right",marginLeft:"10%"}}>Rental Car:&nbsp;</Col><Col style={{textAlign:"right"}}>{this.formatCurrency(fil["rentalCarTotal"])}</Col></Row> : ""} 
                            {fil["carServiceTotal"] > 0 ? <Row><Col style={{textAlign:"right",marginLeft:"10%"}}>Car Service:&nbsp;</Col><Col style={{textAlign:"right"}}>{this.formatCurrency(fil["carServiceTotal"])}</Col></Row> : ""} 
                            {fil["carService2Total"] > 0 ? <Row><Col style={{textAlign:"right",marginLeft:"10%"}}>Car Service:&nbsp;</Col><Col style={{textAlign:"right"}}>{this.formatCurrency(fil["carService2Total"])}</Col></Row> : ""} 
                            { disCount > 1 ? <Row><Col style={{textAlign:"right",marginLeft:"10%"}}>Total:&nbsp;</Col><Col style={{textAlign:"right",borderTop:"2px solid black"}}>{this.formatCurrency(fil["taTotal"])}</Col></Row> : ""} 
                        </div>
                </div>)                   
        }              
    })
    }

    displayDate = (authId,traveler) =>{
    var count = 0 
    return this.state.contentWinnerGroups.filter(p => p.authReqId == authId && p.traveler == traveler).map(fil => {                            
        if(count  == 0)
        {
            count = count + 1;
            return (<div>
                        <div>                                    
                            <Row><Col style={{textAlign:"center",marginLeft:"10%"}}>Travel</Col></Row>
                            <Row><Col style={{textAlign:"center",marginLeft:"10%"}}>{Utils.FormatDate(fil["travelDate"])}</Col></Row>
                            <Row><Col style={{textAlign:"center",marginLeft:"10%"}}>&nbsp;</Col></Row>
                            <Row><Col style={{textAlign:"center",marginLeft:"10%"}}>Created</Col></Row>     
                            <Row><Col style={{textAlign:"center",marginLeft:"10%"}}>{Utils.FormatDate(fil["createdDate"])}</Col></Row>                              
                        </div>
                </div>)                   
        }              
    })
    }

    render() {
        const{ loading } = this.state;
         
        const tdStyle = {
            width:'15%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };  
         const divStyle1 = {
          width: '100%',                   
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };

        let authId = '';
        let traveler ='';      
    return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Contest Winner</h4></div>                            
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row style={{marginBottom:"2%"}}>
                                        <Col sm={11}></Col>
                                        <Col sm={1}>
                                            <Button id="submitVoucher" type="button" size="sm" disabled={loading} onClick={() => window.print()} >
                                                    {loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                    />
                                                    )}
                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                    {!loading && <span>Print</span>}
                                                </Button>
                                        </Col> 
                                    </Row>
                                   <Row>                                        
                                       <Col sm={12}>
                                           <Row>
                                                <Col sm={2}>
                                                    <Form.Group>
                                                    <div>
                                                    <Form.Label>For</Form.Label>
                                                        <Form.Control as="select" name="searchDays" id="searchDays" className="form-control-sm" value={this.state.selectfields.searchDays} onChange={this.handleSelectChange}>
                                                        <option value="all">All</option>
                                                        <option value="3">Last 3 days</option>
                                                        <option value="7">Last 7 days</option>
                                                        <option value="14">Last 14 days</option>
                                                        <option value="30">Last 30 days</option>
                                                        <option value="45">Last 45 days</option>
                                                        <option value="60">Last 60 days</option>
                                                        <option value="90">Last 90 days</option>
                                                        <option value="CDate">Created Date</option>
                                                        <option value="TDate">Travel Date</option>
                                                        <option value="other">Other</option>
                                                    </Form.Control></div>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                <Form.Group>
                                                <div>                    
                                                    <Form.Label>Start Date</Form.Label>
                                                    <DatePicker style={{ fontSize: "12px" }}
                                                    Id="startDate"
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChangeStartDate}                                                   
                                                    maxDate={new Date()}
                                                    showMonthDropdown                                                    
                                                    showYearDropdown
                                                    className="dateStyle" />  
                                                    {this.state.errors.startDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.startDate}</div> : <></> }                  
                                                </div>
                                                </Form.Group>
                                                </Col>                  
                                                <Col sm={2}>
                                                <Form.Group>
                                                <div>                    
                                                    <Form.Label>End Date</Form.Label>
                                                    <DatePicker style={{ fontSize: "12px" }}
                                                    Id="endDate"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleChangeEndDate}
                                                    maxDate={new Date()}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    className="dateStyle" />
                                                    {this.state.errors.endDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.endDate}</div> : <></> } 
                                                </div>
                                                </Form.Group>                    
                                                </Col>                                                
                                            </Row>
                                            <Row>
                                            <Col sm={3}>
                                                    <Form.Group>
                                                    <Form.Label>Find</Form.Label>
                                                    <div><Form.Control type="text" name="find" className="form-control-sm" value={this.state.fields.find} onChange={this.handleChange}>
                                                    </Form.Control></div>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <Form.Group>
                                                    <Form.Label>In</Form.Label>
                                                    <div><Form.Control as="select" name="searchString" className="form-control-sm" value={this.state.selectfields.searchString} onChange={this.handleSelectChange}>
                                                        <option value="ar.authreq_id">AuthReq #</option>
                                                        <option value="td.name">Traveller Name</option>
                                                        <option value="ar.artist_project_number">Proj/Emp No</option>
                                                        <option value="ar.artist_project_name">Project Name</option>
                                                        <option value="ar.artist_name">Artist Name</option>
                                                       {/*<option value="ta_total">AuthReq Total</option>*/}
                                                    </Form.Control></div>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}></Col>
                                                <Col sm={5}>
                                                    <Form.Group>
                                                    <Form.Label></Form.Label>
                                                        <div><Button disabled={this.state.loading} name='searchHistory' style={{ marginBottom: "15px" }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name)} title="Click to start search">
                                                        {this.state.loading && (
                                                            <i
                                                            className="fa fa-refresh fa-spin"
                                                            style={{ marginRight: "5px", color: "#487afa" }}
                                                            />
                                                        )}
                                                        {this.state.loading && <span style={{ color: "#487afa" }}>wait...</span>}
                                                        {!this.state.loading && <span>Search</span>}
                                                        </Button></div>
                                                    </Form.Group>                    
                                                </Col>                                                                                                                                               
                                            </Row>                                 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"left"}}>
                                                                <th style={{width:"25%"}}>Traveler </th>
                                                                <th style={{width:"25%"}}>Proj Num / Proj Name / Artist </th>
                                                                <th style={{width:"20%",textAlign:"center"}}>Total</th>
                                                                <th style={{width:"20%",textAlign:"center"}}>Travel / Submit</th>  
                                                                <th style={{width:"10%"}}>Auth Req</th>                                                                                                                     
                                                            </tr>
                                                        </thead>
                                                        <tbody>                                                           
                                                            {this.state.contentWinnerGroups.map((prop, key) => { 
                                                                if(authId != prop["authReqId"] || traveler != prop["traveler"])
                                                                {   
                                                                    authId =  prop["authReqId"];
                                                                    traveler = prop["traveler"];
                                                                    return (
                                                                        <tr style={{textAlign:"center",cursor:"pointer"}}>                                                                                                                                                                                                
                                                                            <td style={{width:"25%"}}>{this.displayTraveler(prop["authReqId"],prop["traveler"])}</td>
                                                                            <td style={{width:"25%"}}>{this.displayProjDetails(prop["authReqId"],prop["traveler"])}</td>
                                                                            <td style={{width:"20%"}}>{this.displayCost(prop["authReqId"],prop["traveler"])}</td>
                                                                            <td style={{width:"20%"}}>{this.displayDate(prop["authReqId"],prop["traveler"])}</td>
                                                                            <td style={{width:"10%"}}>{prop["authReqId"]}</td>  
                                                                        </tr>                                                                    
                                                                    )
                                                                }
                                                               
                                                            })}                                                            
                                                        </tbody>
                                                </Table>                                                        
                                            </div>                                        
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            {this.state.contentWinnerGroups.length > 3 ? "" :
                                                <table>
                                                    <tr>
                                                        <td style={{height:"120px"}}>&nbsp;</td>
                                                    </tr>
                                                </table>
                                            }
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }

}