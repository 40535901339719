import React, { Component } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import { NavLink } from 'react-router-dom'
import {FormInputs} from '../../CustomComponents/FormInput'
import {CommonController} from "../../../Controllers/CommonController"
import {MenuController} from "../../../Controllers/MenuController"
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';
import { IUserParameter, Istate } from "../../../Model/IUser";
import { UserDetailsController } from "../../../Controllers/UserDetailsController";
import { LogoutController } from "../../../Controllers/LogoutController";
import {alert,confirm} from 'react-bootstrap-confirmation';

export class UserMaintenance extends Component<IUserParameter,Istate> {
  constructor(props:IUserParameter){
    super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {activeRoleCnt:0,adUserId:'',title:'',userprocess:'',addressLineOne:'',addressLineTwo:'',city:'',zip:'',email:'',workPhone:'',altPhone:'',faxPhone:'',userTypeId:'',userId:'',prevADUserId:'',agentId:'',txtLoggedInDate:'',lastActivityDate:'',formLabelDisabled:false,crossLabelDisabled:false,approverTierIdDisabled:false,expenseTypeIdDisabled:false,dhApproverDisabled:false,departmentId:'',departmentIdDisabled:false,crossDepartmentId:'',crossDepartmentIdDisabled:false,isdha:false,hasdha:false,trackingGrid:false,wtpHistory:false,receiveVoucherEmail:false,voucherPlus:false,txtFailedAttempts:'',userTypeIdTemp:'',adEmail:'',adEmailDisabled:false},
            checkfields: {isServiceAccount:false,isVerificationAnswered:false,isLocked:false,isActive:false,isDisabled:false,isLoggedIn:false,isTrackingGrid:true,isBCDHistory:false,isVoucherEmail:false,isVoucherPlus:false,isExternalUser:false},
            selectfields: {formLabel: '',crossLabel:'',stateProvinceId:'',userTypeId:'',approverTierId:'',expenseTypeId:'',departmentId:'',crossDepartmentId:'',dhApprover:''},
            errors: {adUserId:'',userId:'',addressLineOne:'',city:'',zip:'',email:'',workPhone:'',faxPhone:'',agentId:'',formLabel:'',departmentId:'',userTypeId:'',adEmail:''},
            errMsg: "",
            users: [],
            loading: false,
            loggedIn: false,
            modal: {modalSpinnerShow:false},
            FormLabel: [],
            CrossLabel: [],
            StateProvince: [],
            RoleType: [],
            ExpenseType: [],
            ApproverTier: [],
            UserDetail: [],
            SrExecApproval: [],
            Department: [],
            CrossDepartment: [],
            CrossLabelId: [],
            CrossDepartmentId: [],
            ExpenseTypeId: [],
            SrExecApprovalId: []
            
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleBlur=this.handleBlur.bind(this);
        this.onClear=this.onClear.bind(this);
    }
    async componentWillMount(){
        //console.log("this.props.location.state.useraction :", this.props.location.state.useraction)
        //console.log("this.props.location.state.userId :", this.props.location.state.userId )
        let modal = this.state.modal;
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        let checkfields = this.state.checkfields;
        let userDetailsController  = new UserDetailsController();
        modal["modalSpinnerShow"] = true;
        
        fields["userprocess"] = this.props.location.state.useraction;

        //console.log("step:1");
        //Role Type
        await userDetailsController.getUserRoleType(sessionStorage.getItem("uid")!,sessionStorage.getItem("user_type_id")!).then(jsonRoleType => {
        this.setState({
            RoleType: JSON.parse(JSON.stringify(jsonRoleType))
        });
        
        //console.log("RoleType: ", this.state.RoleType);
        this.setState({ modal });
        //console.log("step:1.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading Role Type..., " + error)
        });

        if (this.state.fields.userprocess === '')
        {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            alert("Please try again to create or change user profile");
            this.props.history.push('/admin/user');
        }

        if(fields["userprocess"] === 'New'){
            fields["title"]="Welcome to eTA New User Registration";
            fields["userId"]="";
            fields["userTypeId"]= 'R';
            selectfields["userTypeId"]= 'R';
            selectfields["departmentId"] = "null";
            selectfields["formLabel"] = "null";
            selectfields["approvertierId"] = "null";
            this.checkTypeTier('R', false);
            fields["adEmailDisabled"]=true;
        }
        else
        {
            fields["title"]="Change User Information";
            fields["userId"]=this.props.location.state.userId;
            //fields["adEmailDisabled"]=true;
            await userDetailsController.getUserDetail(fields["userId"]).then(jsonUser => {
                this.setState({
                    UserDetail: JSON.parse(JSON.stringify(jsonUser))
                });
                //console.log("UserDetail: ", this.state.UserDetail);

                if(this.state.UserDetail.recordset.length > 0){
                    let userDetail = jsonUser.recordset[0];
                    fields["userId"] = userDetail.user_id;
                    fields["adUserId"] = userDetail.ad_user_id;
                    fields["agentId"] = userDetail.agent_id;
                    selectfields["approverTierId"] = userDetail.approver_tier_id;
                    if(userDetail.approver_tier_id === null){
                        selectfields["approvertierId"] = "null";
                    }
                    fields["firstName"] = userDetail.name.split(" ")[0];
                    fields["lastName"] = userDetail.name.split(" ")[1];
                    fields["addressLineOne"] = userDetail.addresslineOne;
                    fields["addressLineTwo"] = userDetail.addressLineTwo;
                    fields["city"] = userDetail.city;
                    selectfields["stateProvinceId"] = userDetail.state;
                    fields["zip"] = userDetail.zip;
                    fields["workPhone"] = userDetail.business_phone;
                    fields["altPhone"] = userDetail.alt_phone;
                    fields["faxPhone"] = userDetail.fax_phone;
                    fields["email"] = userDetail.email;
                    fields["userTypeIdTemp"] = userDetail.user_type_id;
                    selectfields["userTypeId"] = userDetail.user_type_id;
                    if(this.state.fields.userTypeIdTemp !== ""){
                        //Reformation with _C in the editable record
                        var userType;
                        userType = this.state.RoleType.filter(f=>f.carId.toLowerCase() == this.state.fields.userTypeIdTemp.toLocaleLowerCase()).map(m=>m.id);
                        if (userType.length > 0){
                            selectfields["userTypeId"] = userType[0];
                        }
                    }                    
                    selectfields["formLabel"] = userDetail.label_id;
                    selectfields["departmentId"] = userDetail.department_id;
                    fields["isdha"] = userDetail.is_dha;
                    fields["hasdha"] = userDetail.has_dha;
                    checkfields["isDisabled"] = userDetail.is_disabled;
                    checkfields["isLoggedIn"] = userDetail.is_logged_in;
                    checkfields["isServiceAccount"] = userDetail.is_ServiceAccount;
                    checkfields["isLocked"] = userDetail.is_locked;
                    checkfields["isActive"] = userDetail.is_active;
                    checkfields["isTrackingGrid"] = (userDetail.tracking_grid)?true:true;
                    checkfields["isBCDHistory"] = userDetail.wtp_history;
                    checkfields["isVoucherEmail"] = userDetail.receive_voucher_email;
                    checkfields["isVoucherPlus"] = userDetail.voucher_plus;
                    checkfields["isVerificationAnswered"] = userDetail.verification_answered;
                    fields["txtLoggedInDate"] = Utils.DefaultFormatDate(userDetail.logged_in_date);
                    fields["lastActivityDate"] = Utils.DefaultFormatDate(userDetail.last_activity_date);
                    fields["txtFailedAttempts"] = userDetail.failedAttempts;
                    //console.log("userDetail-recordset:", userDetail)
                    
                }
                //console.log("step:0.0");
                this.checkTypeTier(this.state.selectfields.userTypeId, false);
                }).catch(error => {
                console.error("There was an error loading UserDetail..., " + error)
                });
        }
        this.setState({checkfields, fields});
        //disable dropdown
        //fields["expenseTypeIdDisabled"] = true;
        //fields["approverTierIdDisabled"] = true;
        //fields["crossLabelDisabled"] = true;
        //fields["crossDepartmentIdDisabled"] = true;
        //fields["dhApproverDisabled"] = true;
        
        //if label_id <> "" then cross_departments = "cross_departments"  & label_id
        //if label_id <> "" then dh_approver = "dh_approver"  & label_id%>
        
        //console.log("step:2");
        //Expense Type
        await userDetailsController.getUserExpenseType(this.state.fields.userId).then(jsonExpenseType => {
        this.setState({
            ExpenseType: JSON.parse(JSON.stringify(jsonExpenseType))
        });
        //console.log("RoleType: ", this.state.ExpenseType);
        if (this.state.ExpenseType.length > 0){
            var value : string[] = [];
            this.state.ExpenseType.filter(f=>f.bit == true).map((prop,key)=>{
                value.push(prop["id"]);
            });
            //
            this.setState({ExpenseTypeId:value});
        }
        this.setState({ modal });
        //console.log("step:2.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading Role Type..., " + error)
        });
        //console.log("step:3");
         //Approver Tier
        await userDetailsController.getUserApproverTier().then(jsonApproverTier => {
        this.setState({
            ApproverTier: JSON.parse(JSON.stringify(jsonApproverTier))
        });
        //console.log("ApproverTier: ", this.state.ApproverTier);
        this.setState({ modal });
        //console.log("step:3.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading Role Type..., " + error)
        });
        //Sr Exec Approval
        var data = {};
        data={
            userID: this.state.fields.userId,
            sessionUserID: sessionStorage.getItem("uid"),
            sessionSysop: sessionStorage.getItem("sysop")
        }
        await userDetailsController.getUserSrExecApproval(data).then(response => {
        var responseExecApproval = JSON.parse(JSON.stringify(response));
        this.setState({
            SrExecApproval: responseExecApproval.recordset
        });
        //console.log("SrExecApproval: ", this.state.SrExecApproval);
        if (this.state.SrExecApproval.length > 0){
            var value : string[] = [];
            this.state.SrExecApproval.filter(f=>f.label_id == this.state.selectfields.formLabel && f.bit == true).map((prop,key)=>{
                value.push(prop["department_id"]);
            });
            //
            this.setState({SrExecApprovalId:value});
        }
        this.setState({ modal });
        //console.log("step:3.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading SrExecApproval..., " + error)
        });
        //Label
        //console.log("step:4");
        await userDetailsController.getUserLabels(sessionStorage.getItem("uid")!,sessionStorage.getItem("sysop")!).then(jsonLabels => {
        this.setState({
            FormLabel: JSON.parse(JSON.stringify(jsonLabels))
        });
        this.setState({ modal });
        //console.log("step:4.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading Labels..., " + error)
        });
        //Cross label
        //console.log("step:5");
        await userDetailsController.getUserCrossLabel(this.state.fields.userId).then(jsonLabels => {
        this.setState({
            CrossLabel: JSON.parse(JSON.stringify(jsonLabels))
        });
        //console.log("Cross Label:", this.state.CrossLabel);
        if (this.state.CrossLabel.length > 0){
            var value : string[] = [];
            this.state.CrossLabel.filter(f=>f.bit == true).map((prop,key)=>{
                value.push(prop["id"]);
            });
            //
            this.setState({CrossLabelId:value});
        }
        this.setState({ modal });
        //console.log("step:5.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading Cross Labels..., " + error)
        });
        //Department
        await userDetailsController.getUserDepartment(data).then(response => {
        var responseDepart = JSON.parse(JSON.stringify(response));
        this.setState({
            Department: responseDepart.recordset, CrossDepartment:responseDepart.recordset
        });
        if (this.state.CrossDepartment.length > 0){
            var value : string[] = [];
            this.state.CrossDepartment.filter(f=>f.label_id == this.state.selectfields.formLabel && f.bit == true).map((prop,key)=>{
                value.push(prop["department_id"]);
            });
            //
            this.setState({CrossDepartmentId:value});
            //console.log("cross dept id:", this.state.CrossDepartmentId);
        }
        //console.log("Department: ", this.state.Department);
        this.setState({ modal });
        //console.log("step:5.2");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading SrExecApproval..., " + error)
        });
        if(!(sessionStorage.getItem("uid") === "" || this.state.fields.userprocess === "New" || (sessionStorage.getItem("sysop") === "" && sessionStorage.getItem("uid") !== ""))){
            //console.log("step:6");
            await userDetailsController.getUserActiveRoleCount(this.state.fields.adUserId).then(response => {
                var resResult = JSON.parse(JSON.stringify(response));
                //console.log("acitive count :", resResult);
                fields["activeRoleCnt"] = resResult.recordset[0].activeRoleCnt;
            this.setState({ modal });
            //console.log("step:6.1");
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error loading Active Count..., " + error)
            });
        }
        //State
        //console.log("step:7");
        let commonController = new CommonController();
        await commonController.getStateProvince('US').then(jsonState => {
            //
            this.setState({                           
                StateProvince : JSON.parse(JSON.stringify(jsonState))
            });
            //set default value 
            //console.log("StateProvince: ", this.state.StateProvince)
            if(fields["userprocess"] === 'New'){
                let selectfields = this.state.selectfields;
                selectfields['stateProvinceId'] = "-1"
                this.setState({selectfields});
            }
            //console.log("step:7.1");
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error loading States..., " + error)
        });
        //let selectfields = this.state.selectfields;
        //selectfields["formLabel"]="AST";
        modal["modalSpinnerShow"] = false;
        //
        this.setState({ modal,fields,selectfields,checkfields })
    }
    handleCheckChange=(e:any)=>{
        //
        let checkfields = this.state.checkfields;        
        let modal = this.state.modal;
        let fields = this.state.fields;
        if (e.currentTarget.name === "isLoggedIn") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.isLoggedIn
        }
        else if(e.currentTarget.name === "isServiceAccount"){
            var updateData={};
            //
            checkfields[e.currentTarget.name] = !this.state.checkfields.isServiceAccount
            //update service Account
            modal["modalSpinnerShow"] = true;
            let userDetailsController = new UserDetailsController();
            updateData={
                adUserId : this.state.fields.adUserId,
                userId : sessionStorage.getItem("uid"),
                isServiceAccount : this.state.checkfields.isServiceAccount
            }
            userDetailsController.updateADUserServiceAccount(updateData).then(response => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
        else if (e.currentTarget.name === "isActive") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.isActive
        }
        else if(e.currentTarget.name === "isLocked"){
            checkfields[e.currentTarget.name] = !this.state.checkfields.isLocked
        }
        else if(e.currentTarget.name === "isDisabled"){
            checkfields[e.currentTarget.name] = !this.state.checkfields.isDisabled
        }
        //else if(e.currentTarget.name === "isTrackingGrid"){
        //    checkfields[e.currentTarget.name] = !this.state.checkfields.isTrackingGrid
        //}
        else if (e.currentTarget.name === "isBCDHistory") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.isBCDHistory
        }
        else if(e.currentTarget.name === "isVoucherEmail"){
            checkfields[e.currentTarget.name] = !this.state.checkfields.isVoucherEmail
        }
        else if(e.currentTarget.name === "isVoucherPlus"){
            checkfields[e.currentTarget.name] = !this.state.checkfields.isVoucherPlus
        }
        else  if(e.currentTarget.name === "isExternalUser"){
            checkfields[e.currentTarget.name] = !this.state.checkfields.isExternalUser 
            if(!this.state.checkfields.isExternalUser){
                fields["adEmail"] = "";
                fields["adEmailDisabled"] = true;
                let errors = this.state.errors;
                errors["adEmail"] = ""
                this.setState({errors});
            }
            else
            {
                fields["adEmailDisabled"] = false;
            }
        }
        this.setState({checkfields,fields});
    }
    handleChange(e:any){
        let fields= this.state.fields;
        if (e.currentTarget.name === "adUserId" || e.currentTarget.name === "userId"){
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
        }
        else
        {    
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({ fields });
    }
    async handleBlur(e:any){
        //
        let userDetailsController = new UserDetailsController();
        let modal = this.state.modal;
        let fields = this.state.fields;
        let checkfields = this.state.checkfields;
        modal["modalSpinnerShow"] = true;
        await userDetailsController.getADUser(this.state.fields.adUserId).then(response => {
           // 
            if(response.success){
                if (response.recordset.length > 0){
                    var userDetail = response.recordset[0];
                    checkfields["isDisabled"] = userDetail.Is_Disabled;
                    checkfields["isLoggedIn"] = userDetail.Is_Logged_In;
                    checkfields["isServiceAccount"] = userDetail.is_ServiceAccount;
                    checkfields["isLocked"] = userDetail.Is_Locked;
                    checkfields["isActive"] = userDetail.Is_Active;
                    checkfields["isVerificationAnswered"] = userDetail.Verification_Answered;
                    fields["txtLoggedInDate"] = Utils.DefaultFormatDate(userDetail.Logged_In_Date);
                    fields["adEmail"] = userDetail.AD_Email;
                    checkfields["isExternalUser"] = true;
                }
            }
            else
            {
                checkfields["isDisabled"] = false;
                checkfields["isLoggedIn"] = false;
                checkfields["isServiceAccount"] = false;
                checkfields["isLocked"] = false;
                checkfields["isActive"] = false;
                checkfields["isVerificationAnswered"] = false;
                fields["txtLoggedInDate"] = '';
                fields["adEmail"] = '';
                checkfields["isExternalUser"] = false;
            }
            modal["modalSpinnerShow"] = false;
            this.setState({modal, fields, checkfields});
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error...", error);
        })
        modal["modalSpinnerShow"] = false;
        this.setState({modal});
    }
    clearError(){
        let errors = this.state.errors;
        errors["adUserId"] = "";
        errors["adEmail"] = "";
        errors["firstName"] = "";
        errors["lastName"] = "";
        errors["formLabel"] = "";
        errors["addressLineOne"] = "";
        errors["city"] = "";
        errors["stateProvinceId"] = "";
        errors["zip"] = "";
        errors["email"] = "";
        errors["workPhone"] = "";
        errors["faxPhone"] = "";
        errors["agentId"] = "";
        errors["departmentId"] = "";
        errors["userTypeId"] = "";
        this.setState({errors});
    }
    handleSelectChange(e:any){
        let selectfields = this.state.selectfields;
        let fields = this.state.fields;
        let errors = this.state.errors;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        
        var currTargetName = e.currentTarget.name;
        const elExpenseTypeId:HTMLElement = document.getElementById("expenseTypeId") as HTMLElement;
        const elApproverTierId:HTMLElement = document.getElementById("approverTierId") as HTMLElement;
        const elCrossLabel:HTMLElement = document.getElementById("crossLabel") as HTMLElement;
        const elFormLabel:HTMLElement = document.getElementById("formLabel") as HTMLElement;
        //fields["crossDepartmentIdDisabled"] = false;
        fields["deparmentIdDisabled"] = false;
        fields["dhApproverDisabled"] = false;
        //fields["crossLabelDisabled"] = false;
        if(currTargetName === 'userTypeId')
        {
            errors["agentId"] = '';
            this.checkTypeTier(e.currentTarget.value, true);
        }
        else if(currTargetName === 'formLabel' && e.currentTarget.Value === "null"){
            //this.checkTypeTier(this.state.selectfields.userTypeId, false);
            
        }
        else if (currTargetName === "crossDepartmentId" || this.state.selectfields.dhApprover === 4){
            //this.checkTypeTier(this.state.selectfields.userTypeId);
            if (this.state.selectfields.userTypeId === 'S' || this.state.selectfields.userTypeId === 'A'){
                fields["formLabelDisabled"] = false;
            }
            else
            {
                fields["formLabelDisabled"] = true;
            }
        }
        else
        {
            //fields["formLabelDisabled"] = true;
            //this.checkTypeTier(this.state.selectfields.userTypeId);
        }
        this.state.CrossDepartmentId.splice(0, this.state.CrossDepartmentId.length);
        this.state.SrExecApprovalId.splice(0, this.state.SrExecApprovalId.length);
        this.setState({selectfields,fields, errors});
    }
    handleSelectMultipleChange(e:any){
        var options = e.currentTarget.options;
        var value : string[] = [];
        
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        if(e.currentTarget.name === 'CrossLabelId'){
            this.setState({CrossLabelId: value});
            //console.log("CrossLabelId", this.state.CrossLabelId)
        }
        else if(e.currentTarget.name === 'CrossDepartmentId'){
            this.setState({CrossDepartmentId: value});
            //console.log("CrossDepartmentId", this.state.CrossDepartmentId)
        }
        else if(e.currentTarget.name === 'ExpenseTypeId'){
            this.setState({ExpenseTypeId: value});
            //console.log("ExpenseTypeId", this.state.ExpenseTypeId)
        } //SrExecApprovalId
        else{
            this.setState({SrExecApprovalId: value});
            //console.log("SrExecApprovalId", this.state.SrExecApprovalId)
        }
    }
    checkTypeTier(val, bLoad){
        
        var currTargetVal =  val;
        let selectfields = this.state.selectfields;
        let fields = this.state.fields;
        fields["expenseTypeIdDisabled"] = true;
        fields["crossLabelDisabled"] = true;
        fields["approverTierIdDisabled"] = true;
        fields["crossDepartmentIdDisabled"] = true;
        fields["dhApproverDisabled"] = true;

        if (currTargetVal === 'S' || currTargetVal === 'A'){
            fields["expenseTypeIdDisabled"] = false;
            fields["crossLabelDisabled"] = false;
            fields["approverTierIdDisabled"] = false;
            fields["formLabelDisabled"] = false;

            fields["crossDepartmentIdDisabled"] = false;
            fields["dhApproverDisabled"] = false;
            if(currTargetVal === 'A' || currTargetVal === 'S') { 
                if (bLoad && currTargetVal === 'A'){
                    selectfields["approverTierId"] = 1;
                }
                //this.state.ApproverTier.filter(f=>f.id==1).map(m=>m.name)[0];
            }
        }
        else if (currTargetVal === 'W')
        {        
            fields["approverTierIdDisabled"] = false;
            if (bLoad) {
                selectfields["approverTierId"] = 0; 
            }
        }
        else if (currTargetVal === 'CT')
        {        
            fields["approverTierIdDisabled"] = false;
            if (bLoad) {
                selectfields["approverTierId"] = 6; 
            }
        }
        else if (currTargetVal.indexOf("_c") !== -1) //'''Margarita added to be able to reflect any Car Company (not hardcoded); IT# 44
        {
            fields["approverTierIdDisabled"] = false;
            //need to confirm with basheer.
            if (bLoad) {
                selectfields["approverTierId"] = 5
            }
            //selectfields["userTypeId"] = selectfields["userTypeId"].split("_")[0];
        }
        else{
            if (currTargetVal === 'R' || currTargetVal === 'AU')
            {
                fields["formLabelDisabled"] = false;
                fields["approverTierIdDisabled"] = true;
                selectfields["approverTierId"] = "null"
            }
            else
            {
                
                /*if(currTargetVal.indexOf('_c') > -1){
                    fields["approverTierIdDisabled"] = false;
                }else{
                    selectfields["approverTierIdDisabled"] = "null";
                    fields["formLabelDisabled"] = true;
                }*/
                
                fields["approverTierIdDisabled"] = true;
                selectfields["approverTierId"] = "null"
                //.user_type.value = mid(.user_type.value ,1,len(.user_type.value )-2)
            }
        }
        if(currTargetVal ==='-1_c' || currTargetVal === 'AL_c' || currTargetVal === 'S' || currTargetVal === 'A' || currTargetVal === 'R' || currTargetVal === 'AU'){
            fields["formLabelDisabled"] = false;
        }else{
            selectfields["formLabel"] = "null";
            fields["formLabelDisabled"] = true;
        }
        this.setState({fields, selectfields});
    }
    async onSubmit(e:any){
        let modal = this.state.modal;
        let checkfields = this.state.checkfields;
        let currentTargetName = e.currentTarget.name;
        var data={};
        
        
        //Insert record
        let userDetailsController = new UserDetailsController();
        this.clearError()
        if (this.validateForm()){ 
            modal["modalSpinnerShow"] = true;
            
            if (currentTargetName === 'Create' || currentTargetName === 'Update'){
                //console.log("approverTierId :",this.state.selectfields.approverTierId)
                data={
                    approverTierId:(this.state.selectfields.approverTierId === 'null')?null:this.state.selectfields.approverTierId, 
                    userTypeId:(this.state.selectfields.userTypeId.indexOf("_") > -1)?this.state.selectfields.userTypeId.split("_")[0]:this.state.selectfields.userTypeId,
                    firstName:this.state.fields.firstName,
                    lastName:this.state.fields.lastName,
                    addressLineOne:this.state.fields.addressLineOne,
                    addressLineTwo:this.state.fields.addressLineTwo,
                    city:this.state.fields.city,
                    stateProvinceId:this.state.selectfields.stateProvinceId,
                    zip:this.state.fields.zip,
                    businessPhone:this.state.fields.workPhone,
                    altPhone:this.state.fields.altPhone,
                    faxPhone:this.state.fields.faxPhone,
                    email:this.state.fields.email,
                    agentId:this.state.fields.agentId,
                    labelId:(this.state.selectfields.formLabel === 'null')?null:this.state.selectfields.formLabel,
                    departmentId:(this.state.selectfields.departmentId === 'null')?null:this.state.selectfields.departmentId,
                    sessionuid:sessionStorage.getItem("uid"),
                    isdha:this.state.fields.isdha,
                    trackingGrid:this.state.checkfields.isTrackingGrid,
                    wtpHistory:this.state.checkfields.isBCDHistory,
                    receiveVoucherEmail:this.state.checkfields.isVoucherEmail,
                    userID:this.state.fields.userId,
                    adUserId:this.state.fields.adUserId,
                    adEmail:(this.state.checkfields.isExternalUser && currentTargetName === 'Create')?this.state.fields.adEmail:this.state.fields.email
                }
                
                //console.log("User Detail-Request:", data)
                if(currentTargetName === 'Create'){
                   await userDetailsController.insertUserDetail(data).then(async response => {
                        //console.log("User Detail Insert-Response:",response);
                        if(response.code === 200){
                            if (response.returnUserID !== undefined){
                                let fields = this.state.fields;
                                fields["userId"] = response.returnUserID;
                                this.setState({fields});
                                await this.reloadUserDetails();
                            }
                            if (sessionStorage.getItem("uid") === ''){
                                let logoutController = new LogoutController();
                                await logoutController.logoutUser(sessionStorage.getItem("adID")!,"");
                                modal["modalSpinnerShow"] = false;
                                this.setState({modal});
                                this.props.history.push('/admin/login');
                            }
                            else
                            {
                                modal["modalSpinnerShow"] = false;
                                this.setState({modal});
                                alert("User Profile Created Successfully");
                                this.props.history.push('/admin/user');
                            }    
                        }
                        else
                        {
                            alert("User Profile not Created Successfully");
                        }
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error...", error);
                    })
                }
                else //Update
                {
                    await userDetailsController.updateUserDetail(data).then(async response => {
                        //console.log("User Detail Update-Response:",response);
                        if(response.code === 200){
                            if (sessionStorage.getItem("sysop") === ""){
                                modal["modalSpinnerShow"] = false;
                                this.setState({modal});
                                alert("User Profile Updated Successfully");
                                this.props.history.push('/admin/home');
                            }
                            else
                            {
                                await this.reloadUserDetails();
                                alert("User Profile Updated Successfully");
                                this.props.history.push('/admin/user');
                            }
                        }
                        else
                        {
                            alert("User Profile not Updated Successfully");
                            modal["modalSpinnerShow"] = false;
                            this.setState({modal});
                            if (sessionStorage.getItem("sysop") === ""){
                                modal["modalSpinnerShow"] = false;
                                this.setState({modal});
                                this.props.history.push('/admin/home');
                            }
                        }
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});                        
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error...", error);
                    })
                    
                    modal["modalSpinnerShow"] = false;
                    this.setState({modal});
                }
            }
        }
        var updateData={};
        if(e.currentTarget.name === 'Unlock'){
            modal["modalSpinnerShow"] = true;
            updateData={
                in_user_id : this.state.fields.adUserId
            }
            userDetailsController.updateReEnableUserLogin(updateData).then(response => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                if(response.out_err_msg !== ''){
                    alert("Input User ID was NULL or empty!");
                }
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
        
        if(e.currentTarget.innerText === 'Disable' || e.currentTarget.innerText === 'Enable'){
            modal["modalSpinnerShow"] = true;
            
            if(e.currentTarget.innerText === 'Disable'){
                checkfields["isDisabled"] = true;
                checkfields["isLocked"] = true;
            }
            else
            {
                checkfields["isDisabled"] = false;
                checkfields["isLocked"] = false;
            }
            let userDetailsController = new UserDetailsController();
            updateData={
                adUserId : this.state.fields.adUserId,
                userId : sessionStorage.getItem("uid"),
                isDisabled : this.state.checkfields.isDisabled
            }
            userDetailsController.updateADUserDisabled(updateData).then(response => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal, checkfields});
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
    }
    async reloadUserDetails(){
        let modal = this.state.modal;
        let userDetailsController = new UserDetailsController();
        var concatInputLabel = '';
        var concatInputDept = '';
        var concatInputExecApproval= '';
        var concatInputExpenseType = '';
        
        //userLabel
        if ((this.state.selectfields.formLabel !== 'null' || this.state.CrossLabelId.length > 0) && sessionStorage.getItem("sysop") !== ""){
            this.state.CrossLabelId.map(prop => {
                if(concatInputLabel === ''){
                    concatInputLabel = prop;
                }
                else{
                    concatInputLabel = concatInputLabel + ',' + prop;
                }
            });
            var dataLabel={
                userID:this.state.fields.userId,
                labelID:this.state.selectfields.formLabel,
                crossLabels:concatInputLabel
            }
            //console.log("insertUserLabel-Request:",dataLabel);
            await userDetailsController.insertUserLabel(dataLabel).then(response => {
                //console.log("insertUserLabel-Response:",response);
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
        //userDept
        if (this.state.selectfields.departmentId !== 'null' || this.state.CrossDepartmentId.length > 0  && sessionStorage.getItem("sysop") !== ""){
            this.state.CrossDepartmentId.map(prop => {
                if(concatInputDept === ''){
                    concatInputDept = prop;
                }
                else{
                    concatInputDept = concatInputDept + ',' + prop;
                }
            });
            var dataDept={
                userID:this.state.fields.userId,
                departmentID:this.state.selectfields.departmentId,
                crossDepartments:concatInputDept
            }
            //console.log("insertUserDept-Request:",dataDept);
            await userDetailsController.insertUserDept(dataDept).then(response => {
                //console.log("insertUserDept-Response:",response);
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
        ////userDH
        if (this.state.SrExecApprovalId.length > 0){
            this.state.SrExecApprovalId.map(prop => {
                if(concatInputExecApproval === ''){
                    concatInputExecApproval = prop;
                }
                else{
                    concatInputExecApproval = concatInputExecApproval + ',' + prop;
                }
            });
            var dataExceApproval={
                userID:this.state.fields.userId,
                dhApprover:concatInputExecApproval
            }
            //console.log("insertUserDH-Request:",dataExceApproval);
            await userDetailsController.insertUserDH(dataExceApproval).then(response => {
                //console.log("insertUserDH-Response:",response);
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
        //userExpType
        if (this.state.ExpenseTypeId.length > 0){
            this.state.ExpenseTypeId.map(prop => {
                if(concatInputExpenseType === ''){
                    concatInputExpenseType = prop;
                }
                else{
                    concatInputExpenseType = concatInputExpenseType + ',' + prop;
                }
            });
            var dataExpType={
                userID:this.state.fields.userId,
                expenseTypeID:concatInputExpenseType
            }
            //console.log("insertUserExpType-Request:",dataExpType);
            await userDetailsController.insertUserExpType(dataExpType).then(response => {
                //console.log("insertUserExpType-Response:",response);
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error...", error);
            })
        }
    }
    validateForm(){
        //this.clearError();
 
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;

        if (!fields["adUserId"]){
            formIsValid = false;
            errors["adUserId"] = "Please enter a Login ID";
        }
        // if role type is BCD Travel Agent (W) must enter agent id
        if(this.state.selectfields.userTypeId === 'W'){
            if (!fields["agentId"]){
                formIsValid = false;
                errors["agentId"] = "Please enter an Agent ID";
            }
        }
        if(this.state.checkfields.isExternalUser){
            if(!fields["adEmail"]){
                formIsValid = false;
                errors["adEmail"] = "Please enter umusic email id";
            }
            if(!this.validUmusicEmail(fields["adEmail"]))
            {
                errors["adEmail"]="Please enter a valid umusic email address";
                formIsValid = false;	
            }
        }
        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter a First Name";
        }
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter a Last Name";
        }
        if(this.state.selectfields.userTypeId === 'A' || this.state.selectfields.userTypeId === 'S' || this.state.selectfields.userTypeId === 'R' || this.state.selectfields.userTypeId === 'AU'){
            if (this.state.selectfields.formLabel === 'null' || this.state.selectfields.formLabel === ''){
                formIsValid = false;
                errors["formLabel"] = "Please select a Label";
            }
            if(this.state.Department.filter(f=>f.label_id == this.state.selectfields.formLabel).length > 0){
                if (this.state.selectfields.departmentId === 'null' || this.state.selectfields.departmentId === ''){
                    formIsValid = false;
                    errors["departmentId"] = "Please select a Department";
                }
            }
        }
        if(fields["addressLineOne"] === "" || fields["addressLineOne"].length === 0)
        {
            errors["addressLineOne"] = "Please enter an Address One"
            formIsValid = false;	
        }
        if(fields["city"] === "" || fields["city"].length === 0)
        {
            errors["city"]="Please enter a City.";
            formIsValid = false;	
        }
        if(this.state.StateProvince.length > 0){
            if(this.state.selectfields.stateProvinceId === "-1"){
                errors["stateProvinceId"]="Please select a State";
                formIsValid = false;	
            }
        }
        
        
        if(!this.validZip(this.state.fields.zip, "US"))
        {
            errors["zip"] = "Please enter a valid Zip Code";
            formIsValid = false;
        }
        if(!fields["email"])
        {
            errors["email"]="Please enter a email address";
            formIsValid = false;	
        }
        if(!this.validEmail(fields["email"]))
        {
            errors["email"]="Please enter a valid email address";
            formIsValid = false;	
        }
        if(!this.validPhone(fields["workPhone"]))
        {
            errors["workPhone"]="Please enter a valid Work Phone Number";
            formIsValid = false;	
        }
        if(!this.validPhone(fields["faxPhone"]))
        {
            errors["faxPhone"]="Please enter a Fax Phone Number";
            formIsValid = false;	
        }
        
        if(sessionStorage.getItem("sysop") !== ""){
            if(this.state.selectfields.userTypeId.toString() === 'W'){
                if (this.state.selectfields.approverTierId.toString() !== '0' && this.state.selectfields.approverTierId.toString() !== 'null'){
                    alert("Approver Tier can only be NULL or WTP");
                    formIsValid = false;
                }
            }
            if(this.state.selectfields.userTypeId === 'A'){
                if (this.state.selectfields.approverTierId.toString() !== '1' && this.state.selectfields.approverTierId.toString() !== '2' && this.state.selectfields.approverTierId.toString() !== '3' && this.state.selectfields.approverTierId.toString() !== '4'){
                    alert("Approver Tier can only be Authorizor, Billing, Department or Sr Exec");
                    formIsValid = false;
                }
            }
            if(this.state.selectfields.userTypeId.toString() === 'ME' || this.state.selectfields.userTypeId.toString() === 'DL'){
                if (this.state.selectfields.approverTierId.toString() !== '5' && this.state.selectfields.approverTierId.toString() !== 'null'){
                    alert("Approver Tier can only be Authorizor, Billing, Department or Sr Exec");
                    formIsValid = false;
                }
            }
        }
        this.setState({ errors });
        return formIsValid;
    }
    validPhone(str){
        //Issue 55 (new regular expression to allow multiple periods before @ sign)
        var regEx = new RegExp("^[ 0-9()-.]{10,20}$");
        var blnValid = false;
        if(regEx.test(str))
        {
            blnValid = true;
        }
        return blnValid;
    }
    validEmail(str){
        
        //Issue 55 (new regular expression to allow multiple periods before @ sign)
        //var regEx = new RegExp("\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*");
        //var regEx = new RegExp("^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$")
        var blnValid = false;
        //if(regEx.test(str))
        //{
        //    blnValid = true;
        //}
        //var regexEmail = /^\\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(regexEmail.test(str)){
          //alert("Plesae enter the valid email address")
          blnValid = true;
        }
        return blnValid;
    }
    validUmusicEmail(str){
        
        var blnValid = false;
        var regexEmail = /^\w+([\.-]?\w+)+@umusic\.com$/;
        //var regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(regexEmail.test(str)){
          blnValid = true;
        }
        return blnValid;
    }
    validZip(strCode, strCountry)
    {
        
        var regEx = new RegExp("[A-Za-z0-9- ]{1,10}$");
        var regExUS = new RegExp("[0-9]{5}$|^[0-9]{5}[-][0-9]{4}$");
        var regExMX = new RegExp("[0-9]{5}$|^[0-9]{5}[-][0-9]{4}$");
        var regExCA = new RegExp("[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$|^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[ ]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$");
        var regExNL = new RegExp("[0-9]{4}[ ]{1}[A-Za-z]{2}$");

        var blnValid = false;
        
        switch(strCountry)
        {
            case "US":
                if(regExUS.test(strCode))
                {
                    blnValid = true;
                }
                break;
            case "MX":
                if(regExMX.test(strCode))
                {
                    blnValid = true;
                }
                break;
            case "CA":
                if(regExCA.test(strCode))
                {
                    blnValid = true;
                }
                break;
            case "NL":
                if(regExNL.test(strCode))
                {
                    blnValid = true;
                }
                break;
            default:
                if(regEx.test(strCode))
                {
                    blnValid = true;
                }
                break;
        }
            
        return blnValid;
    }
    cancelButton(){
        if (sessionStorage.getItem("uid") === ""){
			this.props.history.push('/admin/login');
        }
		else{
			this.props.history.push('/admin/home');
        }
    }
    onClear(){
        /*let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        fields["adUserId"] = "";
        fields["adEmail"] = "";
        fields["userId"] = "";
        fields["txtLoggedInDate"] = "";
        fields["lastActivityDate"] = "";
        fields["txtFailedAttempts"] = "";

        fields["firstName"] = "";
        fields["lastName"] = "";
        fields["agentId"] = "";

        fields["addressLineOne"] = "";
        fields["addressLineTwo"] = "";
        fields["city"] = "";
        fields["zip"] = "";
        fields["email"] = "";
        fields["workPhone"] = "";
        fields["altPhone"] = "";
        fields["faxPhone"] = "";

        fields["userId"]="";
        fields["userTypeId"]= 'R';
        selectfields["userTypeId"]= 'R';
        selectfields["departmentId"] = "null";
        selectfields["formLabel"] = "null";
        selectfields["approvertierId"] = "null";
        this.checkTypeTier('R', false);
        fields["adEmailDisabled"]=true;

        this.setState({fields, selectfields});*/
        window.location.reload();
    }
    execApproval = () => {
        if(this.state.SrExecApproval.length > 0){
            var filterExeAppCnt = this.state.SrExecApproval.filter(f=>f.label_id == this.state.selectfields.formLabel);
            if(filterExeAppCnt.length > 0){
                let fields = this.state.fields;
                if(filterExeAppCnt[0].has_dha){
                    fields["dhApproverDisabled"] = false;
                }else
                {
                    fields["dhApproverDisabled"] = true;
                }
            }
        return <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                    <Form.Group>
                        <Form.Label>Sr Exec Approval</Form.Label>
                        {(filterExeAppCnt.length > 0)?
                        <Form.Control as="select" multiple={true} className="form-control form-control-sm" id="SrExecApprovalId" name="SrExecApprovalId" value={this.state.SrExecApprovalId} onChange={this.handleSelectMultipleChange} disabled={this.state.fields.dhApproverDisabled}>
                            {this.state.SrExecApproval.filter(f=>f.label_id == this.state.selectfields.formLabel).map((prop, key) => {
                            if (prop["is_active"] === "Yes") {
                                return (
                                <option selected={(prop["bit"])?true:false} value={prop["department_id"]}>{prop["description"]}</option>
                                )
                            }
                            else {
                                return (
                                <option selected={(prop["bit"])?true:false} className="text-danger" value={prop["department_id"]}>{prop["description"]}</option>
                                )
                            }
                            })}
                        </Form.Control>
                        :
                        <div style={{height:"100px"}}></div>
                        }
                        </Form.Group>
                    </Col>
                    <Col sm={6}></Col>
                </Row>
        }
        else
        {
            return <></>
        }
        
    }
    formLabel = () => {
        return <Form.Group>
          <Form.Label>HFM Entity<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
          <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} onChange={this.handleSelectChange} disabled={this.state.fields.formLabelDisabled}>
            <option selected={true} value="-1">Please select a Label...</option>
            {this.state.FormLabel.map((prop, key) => {
              if (prop["is_active"] === "Yes") {
                return (
                  <option selected={(this.state.selectfields.formLabel === prop["id"])?true:false} value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else {
                return (
                  <option selected={(this.state.selectfields.formLabel === prop["id"])?true:false} className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                )
              }
            })}
          </Form.Control>
          {this.state.errors["formLabel"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["formLabel"]}</div> : <></> }
        </Form.Group>
    }
    crossLabel = () => {
        if(this.state.fields.crossLabelDisabled){
            this.state.CrossLabelId.splice(0,this.state.CrossLabelId.length);
        }
        return <Form.Group>
          <Form.Label>Cross Label</Form.Label>
          <Form.Control as="select" multiple={true} className="form-control form-control-sm" id="CrossLabelId" name="CrossLabelId" value={this.state.CrossLabelId} onChange={this.handleSelectMultipleChange} disabled={this.state.fields.crossLabelDisabled}>
            {this.state.CrossLabel.map((prop, key) => {
              if (prop["is_active"] === "Yes") {
                return (
                  <option selected={(prop["bit"])?true:false} value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else {
                return (
                  <option selected={(prop["bit"])?true:false} className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                )
              }
            })}
          </Form.Control>
        </Form.Group>
    }
    department = () => {
        if(this.state.Department.length > 0){
            var filterDeptCnt = this.state.Department.filter(f=>f.label_id == this.state.selectfields.formLabel);
        return <Row>
                <Col sm={3}></Col>
                <Col sm={3}>
                    <Form.Group>
                    <Form.Label>Department<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                    {(filterDeptCnt.length > 0)?
                    <Form.Control as="select" className="form-control form-control-sm" id="departmentId" name="departmentId" value={this.state.selectfields.departmentId} onChange={this.handleSelectChange} disabled={this.state.fields.departmentIdDisabled}>
                        <option selected={true} value="null">Please select a Department...</option>
                        {this.state.Department.filter(f=>f.label_id == this.state.selectfields.formLabel).map((prop, key) => {
                        if (prop["is_active"] === "Yes") {
                            return (
                            <option selected={(this.state.selectfields.dhApprover === prop["department_id"])?true:false} value={prop["department_id"]}>{prop["description"]}</option>
                            )
                        }
                        else {
                            return (
                            <option selected={(this.state.selectfields.dhApprover === prop["department_id"])?true:false} className="text-danger" value={prop["department_id"]}>{prop["description"]}</option>
                            )
                        }
                        })}
                    </Form.Control>
                    :
                    <div style={{height:"20px"}}></div>
                    }
                    {(this.state.errors["departmentId"] !== "") ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["departmentId"]}</div> : <></>}
                    </Form.Group>
                </Col>
                <Col sm={6}></Col>
            </Row>
        }
        else
        {
            return <></>
        }
        
    }
    crossDepartment = () => {
        if(this.state.CrossDepartment.length > 0){
            var filterCnt = this.state.CrossDepartment.filter(f=>f.label_id == this.state.selectfields.formLabel);
            if(this.state.fields.crossDepartmentIdDisabled){
                this.state.CrossDepartmentId.splice(0,this.state.CrossDepartmentId.length);
            }
        return <Row>
                <Col sm={3}></Col>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label>Cross Department</Form.Label>
                        {(filterCnt.length > 0)?
                            <Form.Control as="select" multiple={true} className="form-control form-control-sm" id="CrossDepartmentId" name="CrossDepartmentId" value={this.state.CrossDepartmentId} onChange={this.handleSelectMultipleChange} disabled={this.state.fields.crossDepartmentIdDisabled}>
                                {this.state.CrossDepartment.filter(f=>f.label_id == this.state.selectfields.formLabel).map((prop, key) => {
                                if (prop["is_active"] === "Yes") {
                                    return (
                                    <option selected={(prop["bit"])?true:false} value={prop["department_id"]}>{prop["description"]}</option>
                                    )
                                }
                                else {
                                    return (
                                    <option selected={(prop["bit"])?true:false} value={prop["department_id"]}>{prop["description"]}</option>
                                    )
                                }
                                })}
                            </Form.Control>
                        :
                            <div style={{height: "100px"}}></div>
                        }
                    </Form.Group>
                </Col>
                <Col sm={6}></Col>
            </Row>
        }
        else
        {
            return <></>
        }
        
    }
    roleType = () => {        
        if(sessionStorage.getItem("sysop") !== ''){
            //console.log("UserTypeId:", this.state.fields.userTypeId)
            //''' Margarita added "_c" to be able to track Car companies IT#44%>
            return <>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Role Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                            <Form.Control as="select" className="form-control form-control-sm" id="userTypeId" name="userTypeId" value={this.state.selectfields.userTypeId} onChange={this.handleSelectChange} disabled={(this.state.fields.userId === "sysAdmin" || (this.state.fields.userTypeId === "S" && sessionStorage.getItem("sysop") !== "sysAdmin"))}>
                                {this.state.RoleType.map((prop, key) => {
                                    return (
                                    <option selected={(this.state.selectfields.userTypeId === prop["id"] || this.state.selectfields.userTypeId + "_c" === prop["id"])?true:false} value={prop["id"]}>{prop["name"]}</option>
                                    )
                                })}
                            </Form.Control>
                            {this.state.errors["userTypeId"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["userTypeId"]}</div> : <></> }
                    </Form.Group></Col>
                    <Col sm={3}>
                        <FormInputs
                            properties={{id:"agentId",label:"Agent ID",type:"text",name:"agentId",value:this.state.fields.agentId,className:"form-control input-sm",maxlength:50,onChange:this.handleChange}}
                            errorMsg={this.state.errors.agentId}
                            fieldRequired={(this.state.selectfields.userTypeId === 'W')?true:false}
                        />
                    </Col>
                    <Col sm={3}></Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                    <Form.Group>
                        <Form.Label>Expense Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" multiple={true} className="form-control form-control-sm" id="ExpenseTypeId" name="ExpenseTypeId" value={this.state.ExpenseTypeId} onChange={this.handleSelectMultipleChange} disabled={this.state.fields.expenseTypeIdDisabled}>
                        {this.state.ExpenseType.map((prop, key) => {
                            return (
                                <option selected={(prop["bit"])?true:false} value={prop["id"]}>{prop["name"]}</option>
                            )
                        })}
                        </Form.Control>
                    </Form.Group>
                    </Col>
                    <Col sm={6}></Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                    <Form.Group>
                        <Form.Label>Approver Tier<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" className="form-control form-control-sm" id="approverTierId" name="approverTierId" value={this.state.selectfields.approverTierId} onChange={this.handleSelectChange} disabled={this.state.fields.approverTierIdDisabled}>
                        <option value={"null"}>{"NULL"}</option>
                        {this.state.ApproverTier.map((prop, key) => {
                            return (
                                <option value={prop["id"]}>{prop["name"]}</option>
                            )
                        })}
                        </Form.Control>
                    </Form.Group>
                    </Col>
                    <Col sm={6}></Col>
                </Row>
            </>
        }
    }
    
    displayAdditionalPermission(){
        if(sessionStorage.getItem("sysop") !== ''){
            return <>
                <Row>
                    <Col sm={3}><Form.Check style={{display:"none"}} type="checkbox" label="Tracking Grid" name="isTrackingGrid" id="isTrackingGrid" checked={this.state.checkfields.isTrackingGrid} onChange={this.handleCheckChange} /></Col>
                    <Col sm={2}>
                        <Form.Check type="checkbox" label="Voucher Email" name="isVoucherEmail" id="isVoucherEmail" checked={this.state.checkfields.isVoucherEmail} onChange={this.handleCheckChange}/>
                    </Col>
                    <Col sm={2}>
                        <Form.Check type="checkbox" label="BCD History" name="isBCDHistory" id="isBCDHistory" checked={this.state.checkfields.isBCDHistory} onChange={this.handleCheckChange}/>
                    </Col>
                    <Col sm={2}>
                    <Form.Check type="checkbox" label="Voucher Plus" name="isVoucherPlus" id="isVoucherPlus" checked={this.state.checkfields.isVoucherPlus} onChange={this.handleCheckChange}/>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
            </>
        }
    }
    render() {
        if (this.state.loggedIn === false){
            //return <Redirect to="/" />
        }
        const { loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">{this.state.fields.title}</h4><p className="category"></p></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row style={{textAlign:"right"}}>
                                    <Col sm={12}>
                                        <Button disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                        {this.state.loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color: "#3472F7" }}
                                        />
                                        )}
                                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                        {!this.state.loading && <span>Print </span>}
                                        </Button>
                                    </Col>
                                </Row>
                                <Form>
                                    {(this.state.fields.userprocess === 'New')?
                                        <>
                                        <Row>
                                            <Col sm={3}></Col>
                                            <Col sm={3}>
                                                <FormInputs
                                                    properties={{id:"adUserId",label:"Login ID",type:"text",name:"adUserId",value:this.state.fields.adUserId,className:"form-control input-sm",maxlength:16,onChange:this.handleChange,onBlur:this.handleBlur,autocomplete:'off'}}
                                                    errorMsg={this.state.errors.adUserId}
                                                    fieldRequired={true}
                                                />
                                            </Col>
                                            <Col sm={6}></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={3}></Col>
                                            <Col sm={3}>
                                                <Form.Check type="checkbox" label="External User" name="isExternalUser" id="isExternalUser" checked={this.state.checkfields.isExternalUser} onChange={this.handleCheckChange}/>
                                            </Col>
                                            <Col sm={3}>
                                                <FormInputs
                                                    properties={{id:"adEmail",label:"umusic email Id for login",type:"text",name:"adEmail",value:this.state.fields.adEmail,className:"form-control input-sm",maxlength:50,onChange:this.handleChange,disabled:this.state.fields.adEmailDisabled,placeholder:"@umusic.com"}}
                                                    errorMsg={this.state.errors.adEmail}
                                                    fieldRequired={(this.state.checkfields.isExternalUser)?true:false}
                                                />
                                            </Col>
                                            <Col sm={3}></Col>
                                        </Row></>
                                        :
                                        <Row>
                                            <Col sm={3}></Col>
                                            <Col sm={3}>
                                                <FormInputs
                                                    properties={{id:"adUserId",label:"Search (Login Id)",type:"text",name:"adUserId",value:this.state.fields.adUserId,className:"form-control input-sm",maxlength:16,onChange:this.handleChange,disabled:true}}
                                                    errorMsg={this.state.errors.adUserId}
                                                    fieldRequired={true}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <FormInputs
                                                    properties={{id:"userId",label:"Role ID",type:"text",name:"userId",value:this.state.fields.userId,className:"form-control input-sm",maxlength:16,onChange:this.handleChange,disabled:true}}
                                                    errorMsg={this.state.errors.userId}
                                                    fieldRequired={true}
                                                />
                                            </Col>
                                            <Col sm={3}></Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <Form.Check type="checkbox" label="Service Account" name="isServiceAccount" id="isServiceAccount" value="1" checked={this.state.checkfields.isServiceAccount} onChange={this.handleCheckChange}/>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Check type="checkbox" label="Identify Verification" name="isVerificationAnswered" id="isVerificationAnswered" checked={this.state.checkfields.isVerificationAnswered} disabled />
                                        </Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3} style={{marginTop:"20px"}}>
                                            <Form.Check type="checkbox" label="Logged In" name="isLoggedIn" id="isLoggedIn" checked={this.state.checkfields.isLoggedIn} disabled={(this.state.fields.isLoggedIn)?true:false} onChange={this.handleCheckChange}/>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group>
                                                <Form.Label>Last Login</Form.Label>
                                                <Form.Control type="text" id="txtLoggedInDate" name="txtLoggedInDate" className="form-control-sm" readOnly value={this.state.fields.txtLoggedInDate} onChange={this.handleChange}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4} style={{alignSelf: "flex-end"}}>
                                            <Form.Group>
                                                <Form.Label>{(this.state.fields.lastActivityDate.length > 0)? 'Last Access : '+this.state.fields.lastActivityDate: ''}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={1} style={{alignSelf: "center"}}>
                                            <Form.Check type="checkbox" label="LOCKED" name="isLocked" id="isLocked" checked={this.state.checkfields.isLocked} disabled />
                                        </Col>
                                        <Col sm={1} style={{alignSelf: "center"}}>
                                            <Form.Check type="checkbox" label="Active" name="isActive" id="isActive" checked={this.state.checkfields.isActive} disabled />
                                        </Col>
                                        <Col sm={1}>
                                            <Form.Group>
                                                <Form.Label>Failed Login</Form.Label>
                                                <Form.Control type="text" id="txtFailedAttempts" name="txtFailedAttempts" className="form-control-sm" readOnly maxLength={1} value={this.state.fields.txtFailedAttempts} onChange={this.handleChange}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={1} style={{alignSelf: "center"}}>
                                            <Form.Check type="checkbox" label="Disabled" name="isDisabled" id="isDisabled" checked={this.state.checkfields.isDisabled} disabled />
                                        </Col>
                                        <Col sm={1} style={{alignSelf: "center"}}>
                                            <Button type="button" id="Disable" name="Disable" size="sm" style={{ marginRight: "5px", backgroundColor:(this.state.fields.userprocess === "New" || sessionStorage.getItem("sysop") === "")? "grey" :"#ffffff" }} onClick={(e) => this.onSubmit(e)} title={(this.state.fields.activeRoleCnt === 0)?"To enable, the user must have one or more valid roles":""} disabled={(this.state.fields.userprocess === "New" || sessionStorage.getItem("sysop") === "")?true:false}>{(this.state.checkfields.isDisabled)?"Enable":"Disable"}</Button>
                                        </Col>
                                        <Col sm={4}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={1} style={{alignSelf: "center"}}>
                                            {/*<Button type="button" id="Unlock" name="Unlock" size="sm" style={{ marginRight: "5px", backgroundColor:(this.state.checkfields.isDisabled || !(this.state.fields.txtFailedAttempts >= 5 || this.state.checkfields.isLoggedIn || !this.state.checkfields.isActive) || this.state.fields.userprocess === "New" || sessionStorage.getItem("sysop") === "")? "grey" :"#ffffff" }} onClick={(e) => this.onSubmit(e)} disabled={(this.state.checkfields.isDisabled || !(this.state.fields.txtFailedAttempts >= 5 || this.state.checkfields.isLoggedIn || !this.state.checkfields.isActive) || this.state.fields.userprocess === "New" || sessionStorage.getItem("sysop") === "")?true:false}>Unlock</Button>*/}
                                        </Col>
                                        <Col sm={1} style={{alignSelf: "center"}}></Col>
                                        <Col sm={1}></Col>
                                        <Col sm={1} style={{alignSelf: "center"}}>    
                                        </Col>
                                        <Col sm={5}></Col>
                                    </Row>
                                    {this.roleType()}
                                    {this.execApproval()}
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"firstName",label:"First Name:",type:"text",name:"firstName",value:this.state.fields.firstName,className:"form-control input-sm",maxlength:25,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.firstName}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"lastName",label:"Last Name",type:"text",name:"lastName",value:this.state.fields.lastName,className:"form-control input-sm",maxlength:25,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.lastName}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                        {this.formLabel()}
                                        </Col>
                                        <Col sm={3}></Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                        {this.crossLabel()}
                                        </Col>
                                        <Col sm={3}></Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    {this.department()}
                                    {this.crossDepartment()}
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"addressLineOne",label:"Work Address One",type:"text",name:"addressLineOne",value:this.state.fields.addressLineOne,className:"form-control input-sm",maxlength:50,onChange:this.handleChange,placeholder:"Street Address"}}
                                                errorMsg={this.state.errors.addressLineOne}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"addressLineTwo",label:"Work Address Two",type:"text",name:"addressLineTwo",value:this.state.fields.addressLineTwo,className:"form-control input-sm",maxlength:50,onChange:this.handleChange,placeholder:"Work Location"}}
                                                fieldRequired={false}
                                            />
                                        </Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"city",label:"City",type:"text",name:"city",value:this.state.fields.city,className:"form-control input-sm",maxlength:50,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.city}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Group>
                                                <Form.Label>State<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                                                <Form.Control as="select" id="stateProvinceId" name="stateProvinceId" value={this.state.selectfields.stateProvinceId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                                                    <option value={-1}>{"Please Select a State..."}</option>
                                                    {this.state.StateProvince.map((prop, key) => {
                                                        return(
                                                                <option selected={(this.state.selectfields.stateProvinceId === prop["id"])?true:false} value={prop["id"]}>{prop["name"]}</option>
                                                            )
                                                    })}
                                                </Form.Control>
                                                {this.state.errors.stateProvinceId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.stateProvinceId}</div> : <></> }
                                            </Form.Group>
                                        </Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"zip",label:"Zip",type:"text",name:"zip",value:this.state.fields.zip,className:"form-control input-sm",maxlength:10,onChange:this.handleChange,placeholder:"e.g. 99999 or 99999-9999"}}
                                                errorMsg={this.state.errors.zip}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"email",label:"Email",type:"text",name:"email",value:this.state.fields.email,className:"form-control input-sm",maxlength:50,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.email}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"workPhone",label:"Work Phone",type:"text",name:"workPhone",value:this.state.fields.workPhone,className:"form-control input-sm",maxlength:20,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.workPhone}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"altPhone",label:"Alternate Phone",type:"text",name:"altPhone",value:this.state.fields.altPhone,className:"form-control input-sm",maxlength:20,onChange:this.handleChange}}
                                                fieldRequired={false}
                                            />
                                        </Col>
                                        <Col sm={3}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>  
                                        <Col sm={3}>
                                            <FormInputs
                                                properties={{id:"faxPhone",label:"Fax Number",type:"text",name:"faxPhone",value:this.state.fields.faxPhone,className:"form-control input-sm",maxlength:20,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.faxPhone}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Row>
                                    {this.displayAdditionalPermission()}
                                    {(this.state.fields.userprocess === 'New')?
                                    <Row style={{marginTop:"30px",marginBottom:"30px",textAlign:"center"}}>
                                        <Col sm={12}>
                                            {(sessionStorage.getItem("sysop") !== "")?<Button type="button" id="Clear" name="Clear" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.onClear()}>Clear</Button>:<></>}
                                            <Button type="button" id="Create" name="Create" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.onSubmit(e)}>Create</Button>
                                            <Button type="button" id="Cancel" name="Cancel" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.cancelButton()}>Cancel</Button>
                                        </Col>
                                    </Row>:
                                    <Row style={{marginTop:"30px",marginBottom:"30px",textAlign:"center"}}>
                                        <Col sm={12}>
                                            {(sessionStorage.getItem("sysop") !== "")?<Button type="button" id="Clear" name="Clear" size="sm" style={{ marginRight: "5px", display:"none" }}  onClick={(e) => this.onClear()}>Clear</Button>:<></>}
                                            <Button type="button" id="Update" name="Update" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.onSubmit(e)}>Update</Button>
                                            <Button type="button" id="Cancel" name="Cancel" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.cancelButton()}>Cancel</Button>
                                        </Col>
                                    </Row>
                                    }
                                </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
                
            </Container>
        </div>)
    }
}