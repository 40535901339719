import React, { Component } from "react";
import { ICarVoucherPrintParameter, Istate } from "../../../Model/ICarVoucherPrint";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,FormControl,Table,InputGroup,FormGroup} from "../../../../node_modules/react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { CarVoucherController } from "../../../Controllers/CarVoucherController";
//import logo from "../../assets/img/UMG_Logo.png";
import queryString from 'query-string';
import {useLocation} from "react-router-dom";
import { alert, confirm } from 'react-bootstrap-confirmation';

export class CarVoucherPrint extends Component<ICarVoucherPrintParameter,Istate> {
    constructor(props:ICarVoucherPrintParameter){
        super(props)
        const userLogin = sessionStorage.getItem("uid")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            lblFields: {account:'',passengerName:'',company:'',phone:'',empNo:'',costService:'',vipNo:'',
                        pickUpPoint:'',pickUpDtTm:'',dropOffPoint:'',name:'',output:'',isLoggedIn:true},            
            errors:{},
            errMsg:"",
            loading:false,           
            modal: {modalSpinnerShow: false} ,
            userId:sessionStorage.getItem("uid"), 
            voucherId:""                          
        }
        this.onNavigate = this.onNavigate.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        let lblFields = this.state.lblFields; 

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let vId = params.get('voucherId');
        this.setState({voucherId:vId});

        /*const search = useLocation().search;
        const vId = new URLSearchParams(search).get('voucherId');*/   
       if(this.state.userId == null || this.state.userId == "" || this.state.userId == "undefined")
       {
         lblFields["isLoggedIn"] = false; 
         this.setState({lblFields});
       }
       else
       {
            modal['modalSpinnerShow']=true;
            this.setState({modal});
            let carVoucherController = new CarVoucherController();
            
            var carVoucherDetails = {     
                voucherId : vId                   
            }
        
            carVoucherController.getCarVoucherdetails(carVoucherDetails).then(jsonVoucherDetails => {  
                    lblFields["account"] = jsonVoucherDetails[0].account || "";
                    lblFields["passengerName"] = jsonVoucherDetails[0].passengerName || "";
                    lblFields["phone"] = jsonVoucherDetails[0].phone || "";
                    lblFields["company"] = jsonVoucherDetails[0].company || "";
                    lblFields["empNo"] = jsonVoucherDetails[0].empNo || "";
                    lblFields["costService"] = jsonVoucherDetails[0].costService || "";
                    lblFields["vipNo"] = jsonVoucherDetails[0].vipNo || "";
                    lblFields["pickUpPoint"] = jsonVoucherDetails[0].pickUpPoint || "";
                    lblFields["pickUpDtTm"] = jsonVoucherDetails[0].pickUpDtTm || "";
                    lblFields["dropOffPoint"] = jsonVoucherDetails[0].dropOffPoint || "";
                    lblFields["name"] = jsonVoucherDetails[0].name || "";
                    lblFields["output"] = jsonVoucherDetails[0].output || "";          
                    modal['modalSpinnerShow']=false;       
                    this.setState({modal,lblFields});     
                    
                    window.print();
            
            }).catch(error => {
                console.error("There was an error...," + error)
            });        
        }
    }

     getDominUrl =() => {
        if (window
            && "location" in window
            && "protocol" in window.location
            && "host" in window.location) {
            return window.location.protocol + "//" + window.location.host;
        }
        return '';
    }

    onNavigate = (e:any) => {
        e.preventDefault();  
        this.props.history.push({pathname:"/login"})   
    }

    render() {
        const{ loading } = this.state;
        const bottomBorder = {
            borderBottom: "1px solid rgb(212, 212, 212)",
            width:"100%"
          };
          const para = {
            textAlign:"center",
            fontSize:"6",
            color:"#ffffff",
            fontFamily:"Lucida Sans Unicode"
          };  
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                {this.state.lblFields.isLoggedIn ?
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><p className="category"></p></div>
                            <Card.Body>
                            <Form>
                              <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                               <Row>
                                   <Col>
                                    
                                   <table  cellSpacing={0} cellPadding={0} style={{width:"100%", height:"1"}}>
                                    <tbody><tr>
                                        <td style={{width:"30%",height:"6",background:"#ff0000"}} >
                                        <p style={{textAlign:"center",fontSize:"6",fontFamily:"Lucida Sans Unicode",color:"#ffffff"}}><strong>
                                        &nbsp;Voucher ID&nbsp;</strong></p></td>

                                        <td style={{width:"100%",height:"1",textAlign:"center"}} rowSpan={2}>
                                            <table style={{width:"80%"}} >
                                                <tbody>
                                                    <tr>
                                                        <td align="left" style={{width:"100%",textAlign:"left",fontFamily:"Lucida Sans Unicode",color:"#000000",paddingLeft:"5%"}}><h2>U N I V E R S A L
                                                        </h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <tr>
                                                                <td align="left" style={{textAlign:"left",width:"50%",fontSize:"4",fontFamily:"Lucida Sans Unicode",color:"#000000",paddingLeft:"10%"}}>MUSIC GROUP</td>
                                                                <td style={{textAlign:"right" ,width:"50%",fontSize:"2",fontFamily:"Arial",color:"red"}}>Account Number {this.state.lblFields.account} </td>
                                                            </tr>
                                                        </td>                                                        
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"30%",height:"1",background:"#000000"}}>
                                        <p style={{textAlign:"center",fontSize:"6",fontFamily:"Lucida Sans Unicode",color:"#ffffff"}}><strong>&nbsp;{this.state.voucherId}&nbsp;</strong></p></td>
                                    </tr>
                                    </tbody></table>

                                    <hr style={{width:"100%"}} />   

                                    <table style={{width:"100%",borderColor:"#c0c0c0"}}  cellPadding={0}>
                                    <tbody><tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>Passenger Name :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong> {this.state.lblFields.passengerName} </strong></p></td>
                                        </tr>
                                        <tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>Arranger's Name :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong> {this.state.lblFields.name} </strong></p></td>
                                        </tr>
                                        <tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>Company :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong> {this.state.lblFields.company} </strong></p></td>
                                        </tr>
                                        <tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>Contact Phone/Callback Number :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong>{this.state.lblFields.phone} </strong></p></td>
                                        </tr>
                                        <tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>Employee Number :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong>{this.state.lblFields.empNo}</strong></p></td>
                                        </tr>
                                        <tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>Cost Center/Business Unit :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong>{this.state.lblFields.costService}</strong></p></td>
                                        </tr>
                                        <tr>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"right"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000"}}>VIP Number :</p></td>
                                        <td style={{width:"50%",verticalAlign:"centre",height:"16",textAlign:"left"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"left"}}><strong>{this.state.lblFields.vipNo}</strong></p></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",height:"77",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"30%",verticalAlign:"centre",textAlign:"center",border: "1px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Pick Up Point</strong></p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"14",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Date &amp; Time</strong></p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"14",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>ZONE - ZIP</strong></p></td>
                                                <td style={{width:"30%",verticalAlign:"centre",textAlign:"center",height:"14",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Final Destination</strong></p></td>
                                                </tr>
                                                <tr>
                                                <td style={{width:"30%",verticalAlign:"centre",textAlign:"center",height:"55",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"center"}}><strong>{this.state.lblFields.pickUpPoint}</strong></p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"55",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"center"}}><strong>{this.state.lblFields.pickUpDtTm}</strong></p>
                                                    <p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"center"}}><strong></strong></p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"55",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#ffffff"}}><strong>x</strong></p></td>
                                                <td style={{width:"30%",verticalAlign:"centre",textAlign:"center",height:"55",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"center"}}><strong>{this.state.lblFields.dropOffPoint}</strong></p>
                                                    <p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#000000",textAlign:"center"}}><strong></strong></p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Car Number</strong></p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Driver I.D. / OPR #</strong></p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Date</strong></p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Job Number</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"10",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"10",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"10",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"20%",verticalAlign:"centre",textAlign:"center",height:"10",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Reservation Time&nbsp;&nbsp;&nbsp;&nbsp; AM
                                                        &nbsp;&nbsp;&nbsp;&nbsp; PM</strong></p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Pickup Time&nbsp;&nbsp;&nbsp;&nbsp; AM
                                                        &nbsp;&nbsp;&nbsp;&nbsp; PM</strong></p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Drop Time&nbsp;&nbsp;&nbsp;&nbsp; AM
                                                        &nbsp;&nbsp;&nbsp;&nbsp; PM</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                             </tr>
                                        </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Tel Mins</strong></p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>INIT</strong></p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Total W.T</strong></p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>INIT</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"25%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",height:"38",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"left",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>STOPS</strong></p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>ZONE/ZIP</strong></p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>WAIT</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"left",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#ff0000",textAlign:"left"}}>1.&nbsp; </p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"left",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#ff0000",textAlign:"left"}}>2.&nbsp; </p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                                <td style={{width:"33%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",height:"38",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"30%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Remarks</strong></p>
                                                    <p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>(time call, route,
                                                        etc)</strong></p></td>
                                                <td style={{width:"70%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"30%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}><strong>Reason</strong></p></td>
                                                <td style={{width:"70%",verticalAlign:"centre",textAlign:"center",height:"19",border: "2px solid #eee"}}><p style={{color:"#ffffff"}}>x</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table style={{width:"100%",borderColor:"#c0c0c0",height:"108",border: "2px solid #eee"}} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"50%",borderColor:"#c0c0c0",height:"104"}}  rowSpan={2}>
                                                    <table style={{width:"100%",borderColor:"#c0c0c0",height:"44",border: "2px solid #eee"}} cellSpacing={0} cellPadding={0}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>FARE</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",backgroundColor:"#c0c0c0",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>PROC CHG</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",backgroundColor:"#c0c0c0",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>TOLLS</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",backgroundColor:"#c0c0c0",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>WAIT TIME</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>STOPS</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>PCKG/MISC</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>MEET &amp;GREET</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",textAlign:"center",border: "2px solid #eee"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>PHONE</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",border: "2px solid #eee",textAlign:"center"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>PKNG</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",border: "2px solid #eee",textAlign:"center"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>NYS WORKERS COMP SURCHARGE</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"50%",border: "2px solid #eee",textAlign:"center"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>TOTAL</p></td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"9%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"2%",border: "2px solid #eee",backgroundColor:"#c0c0c0"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                                <td style={{width:"10%",border: "2px solid #eee"}}>&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                 </td>
                                                 <td style={{width:"50%",verticalAlign:"center",textAlign:"left",height:"1"}}><p style={{fontSize:"12px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>Passenger Signature Below</p></td>
                                            </tr>
                                            <tr>
                                              <td style={{width:"50%",verticalAlign:"center",textAlign:"left",height:"115"}}><p style={{fontSize:"16px",fontFamily:"arial",color:"#ff0000",textAlign:"center"}}>X</p></td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <table style={{width:"100%",borderColor:"#c0c0c0",height:"27",border: "2px solid #eee"}} cellSpacing={0} cellPadding={0}>
                                            <tbody>
                                                <tr>
                                                    <td style={{width:"100%",verticalAlign:"center",textAlign:"center",borderColor:"#c0c0c0",height:"3"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#ff0000",textAlign:"center"}}><strong>{this.state.lblFields.output}</strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:"100%",verticalAlign:"center",textAlign:"center",borderColor:"#c0c0c0",height:"2"}}><p style={{fontSize:"12px",fontFamily:"Lucida Sans Unicode",color:"#ff0000",textAlign:"center"}}><strong>Company Use Only! To be used by Universal Music Group
                                                        only. Valid for a Sedan only.</strong></p></td>
                                                </tr>
                                            </tbody>
                                        </table>                    
                                     </Col>
                               </Row>                   
                            </Form>                         
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row> :
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={8}>
                            <h4>eTA was not Logged In !!!</h4><br/><br/>
                            
                            <span>Please click here to </span><a  style={{color:"blue",textDecoration:"underline"}} onClick={(e) => this.onNavigate(e)}>log in eTA</a><br/><br/>
                            
                        </Col>
                    </Row>
                }

            </Container>
        </div>)
    }


}
        