import React, { Component } from "react";
import { Container, Card, Row, Col, Table } from "../../../../node_modules/react-bootstrap";
import { NavLink } from 'react-router-dom'
import { ISelectRoleParameter, Istate } from "../../../Model/ISelectRole";
import { CommonController } from "../../../Controllers/CommonController"
import { MenuController } from "../../../Controllers/MenuController"
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';

export class SelectRole extends Component<ISelectRoleParameter,Istate> {
  constructor(props:ISelectRoleParameter){
    super(props)
    const userLogin = sessionStorage.getItem("userLogin")
    let loggedIn = true;
    if (userLogin == null){
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      userRole: [],
      modal: {modalSpinnerShow:false}
    }
  }

  componentWillMount(){ 
    let modal = this.state.modal;
    modal['modalSpinnerShow'] = true;
    this.setState({ modal })
      

    let commmonController = new CommonController();
    commmonController.selectMultipleRole(sessionStorage.getItem("adID")!).then(jsonMultipleRole => {
      this.setState({                           
        userRole : JSON.parse(JSON.stringify(jsonMultipleRole))
      });
      modal['modalSpinnerShow']=false;
      this.setState({ modal })
    }).catch(error => {
        console.error("There was an error loading Personal Contact..., " + error)
    });
  }

  redirectToHomePage=(userId)=>{
    let commonController = new CommonController();
    commonController.getUser(userId).then( response => {
      let menuController = new MenuController();
      menuController.getMenuVariable(userId,Utils.SysDateTime()).then( response => {
        var result = (response.menuVariable === null) ? '' : response.menuVariable.trim();
        if(result != ''){
          sessionStorage.setItem("menuVariable",result);
        }
        else{
          sessionStorage.setItem("menuVariable",result);
        }
        if(sessionStorage.getItem("user_type_id") === "R"){
          this.props.history.push('/admin/trackinggrid');
        }
        else if(sessionStorage.getItem("user_type_id") === "A"){
          this.props.history.push('/admin/approverinbox');
        }
        else if(sessionStorage.getItem("user_type_id") === "W"){
          this.props.history.push('/admin/wtpincomingta');
        }
        else if(sessionStorage.getItem("user_type_id") === "CT"){
          this.props.history.push('/admin/wtpincomingta');
        }
        else{
          this.props.history.push('/admin/home');
        } 
        }).catch(error => {
          console.error("There was an error in getMenuVariable...", error);
        });   
    }).catch(error => {
        console.error("There was an error:", error);
    })
  }
  
  getLinkMultipleRole = (item) => {
    return(
      <NavLink to={"#"} onClick={() => this.redirectToHomePage(item["userId"])}>
        <a>{item["role"]}</a>
      </NavLink>
    )
  }

  render(){
    if (this.state.loggedIn === false){
        //return <Redirect to="/" />
    }
    return(
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: '100%' }}>
                <div className="header"><h4 className="title">eTA - Select User Profile</h4><p className="category"></p></div>
                <Card.Body>
                  {(sessionStorage.getItem("adID") !== null) ?
                    <Row>
                      <Col sm={12}>
                        <div style={{width: '100%', height: "auto"}}>
                          <Table hover striped bordered>                    
                            <thead>
                              <tr>
                                {new Variables().thSelectRoleArray.map((prop, key) => {
                                  return <th key={key}>{prop}</th>;
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.userRole.map((prop, key) => {
                                return(
                                  <tr key={key}>
                                    <td>{this.getLinkMultipleRole(prop)}</td>                                                                           
                                    <td>{prop["family"]}</td>                                 
                                    <td>{prop["department"]}</td>                                 
                                  </tr>
                                );         
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  :
                    <Row>
                      <Col sm={12}>
                        {"For security reason, this page is not displayed.<br>Please try to login again."}
                      </Col>
                    </Row>
                  }
                </Card.Body>                                  
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}