import React, { Component, CSSProperties } from "react";
import { ITravelClassCodesParameter, Istate } from "../../../Model/ITravelClassCodes";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { BCDcodeMaintenanceController } from "../../../Controllers/BCDcodeMaintenanceController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import ReactPaginate from 'react-paginate';
import { MultilineDropdown } from "react-multiline-dropdown";
import { alert, confirm } from 'react-bootstrap-confirmation';


export class TravelClassCodes extends Component<ITravelClassCodesParameter,Istate> {
    constructor(props:ITravelClassCodesParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {id:'',name:'',isEdit:false},
            selectfields:{},
            errors:{id:'',name:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            travelClassCodesGroups:[]  
        } 
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);       
        this.onClear = this.onClear.bind(this);
    }

    handleTextChange(e:any){ 
        let fields = this.state.fields; 
        let errors = this.state.errors;
        var numregex = new RegExp("^[0-9\b]+$");
        if(e.currentTarget.id === "id")    
        {
            if (e.currentTarget.value === '' ||numregex.test(e.currentTarget.value)) {
                fields[e.currentTarget.id] = e.currentTarget.value;
                errors[e.currentTarget.id] = "";
            }
        } 
        else
        {        
            fields[e.currentTarget.id] = e.currentTarget.value;
            errors[e.currentTarget.id] = "";
        }  
            
        this.setState({fields,errors});       
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true; 
        this.getTravelClassCodeList();
    }
    
    onClear(e:any)
    {
        e.preventDefault();
        let modal = this.state.modal; 
        modal['modalSpinnerShow']=true; 
        this.clearData();
        this.getTravelClassCodeList();
    }

    clearData()
    {
        let fields = this.state.fields
        let errors = this.state.errors;
        fields["id"] ="";
        fields["name"] = "";        
        fields["isEdit"] = false;    
        errors["id"] ="";
        errors["name"] = "";     
        this.setState({fields,errors})
    }

    onSave(e:any){
        e.preventDefault();
        let fields = this.state.fields
        let modal = this.state.modal;         
        var doBy = "";
        if(this.validateTravelClassCode())
        {
            modal['modalSpinnerShow']=true; 
            if(fields["isEdit"])
                doBy = "U";
            else
             doBy = "I";
            let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
            var data = {  
                    doBy:doBy,           
                    id:fields["id"],
                    name:fields["name"]
                }
                bcdcodeMaintenanceController.InsUpdDelTravelClassCode(data).then(response => { 
                    if(fields["isEdit"])
                        alert('Travel Class Code Updated')
                    else
                        alert('Travel Class Code Added') 
                    this.getTravelClassCodeList(); 
                    this.clearData();   
                    modal['modalSpinnerShow']=false;  
                    this.setState({modal})            
                }).catch(error => {
                    console.error("There was an error!", error)
                })   
        }
    }

    async onDelete(data) {      
        var flag = await confirm("Are you sure want to Delete?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
        if(flag){
            if(data["id"] == "-1")
            { 
                alert("Cannot DELETE this row due to this is a required key value.")
            }
            else{
                let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
                var data1={
                    id:data["id"]
                }        
                bcdcodeMaintenanceController.GetAirlegCount(data1).then(response => {            
                var count = response[0].count || "";
                if(count > 0)
                { 
                alert("Cannot DELETE this row due to it is active in the Air Leg table.")
                }
                else
                {               
                    var data1 = {  
                        doBy:"D",           
                        id:data["id"],
                        name:data["name"]  
                    }
                    bcdcodeMaintenanceController.InsUpdDelTravelClassCode(data1).then(response => { 
                        alert('Data Deleted..')  
                        this.getTravelClassCodeList();  
                        this.clearData();              
                    }).catch(error => {
                        console.error("There was an error!", error)
                    })   
                }
                }).catch(error => {
                    console.error("There was an error!", error);            
                })
            }
        }
    }

    onEdit=(e:any,data)=>{
        e.preventDefault();
        let fields = this.state.fields
        let errors = this.state.errors;
        fields["id"] = data["id"];
        fields["name"] = data["name"]; 
        fields["isEdit"] = true;
        errors["id"] ="";
        errors["name"] = "";
        this.setState({fields,errors})
    }

    validateTravelClassCode=()=>{
        var isValid=true
        let fields = this.state.fields;
        let errors = this.state.errors;
        if(fields["id"] === "")
        {
            isValid=false;
            errors["id"] ="Enter ID";
        }
        if(fields["id"] === "-1")
        {
            isValid=false;
            errors["name"] = "Cannot Update this row due to this is a required key value";
        }
        if(fields["name"] === "")
        {
            isValid=false
            errors["name"] ="Enter Name";
        }        
        if(isValid  && !fields["isEdit"])
        {
            var sta = this.state.travelClassCodesGroups.filter(item => fields["id"] === item.id);
            if(sta.length >0)
            {
                isValid=false
                alert("ID Already Exists.Please Choose Differtent");
            }
        }
        this.setState({errors})
        return isValid;
    }

    validateIsdelete=(id:any)=>{
        var isValid=true
        if(id == "-1")
        {
            isValid =false;
            alert("Cannot DELETE this row due to this is a required key value.")
        }
        else{
            let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
            var data={
                id:id
            }        
            bcdcodeMaintenanceController.GetAirlegCount(data).then(response => {            
            var count = response[0].count || "";
            if(count > 0)
            {
                isValid =false;
                alert("Cannot DELETE this row due to it is active in the Air Leg table.")
            }
            }).catch(error => {
                console.error("There was an error!", error);            
            })
        }
        
        return isValid
    }

    getTravelClassCodeList =()=>{
        let modal = this.state.modal;         
        let bcdcodeMaintenanceController = new BCDcodeMaintenanceController();
        
        bcdcodeMaintenanceController.GetTravelClassCodesList().then(jsonTravelClassList => {
            this.setState({ travelClassCodesGroups: JSON.parse(JSON.stringify(jsonTravelClassList))});   
            modal['modalSpinnerShow']=false;  
            this.setState({modal})        
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })
    }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Travel Class Codes</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                       <Col sm={1}>
                                         <FormInputs
                                                properties={{id:"id",label:"ID",type:"text",name:"id",maxlength:5,value:this.state.fields.id,className:"form-control input-sm", onChange: this.state.fields.isEdit ? "" : this.handleTextChange}}
                                                errorMsg={this.state.errors.id}
                                                fieldRequired={true}
                                                disbled={true}                                                
                                            />
                                       </Col>
                                        <Col sm={4}>
                                            <FormInputs
                                                properties={{id:"name",label:"Name",type:"text",name:"name",value:this.state.fields.name,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.name}
                                                fieldRequired={true}
                                                disbled={true}
                                            />
                                        </Col>                                         
                                       <Col sm={1} style={{marginTop:"2%"}}>
                                            <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSave(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Add/Update</span>}
                                            </Button>
                                        </Col> 
                                        <Col sm={1} style={{marginTop:"2%",marginLeft:"2%"}}>
                                            <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onClear(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Clear</span>}
                                            </Button>                                            
                                        </Col>                                                                          
                                    </Row>                                                                 
                                    <Row style={{marginBottom:"2%"}}>                                    
                                    </Row>                                    
                                    <Row>                                        
                                       <Col sm={12}>                                        
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th style={thStyle}>ID</th>
                                                                <th style={thStyle}>Name</th>                                                                                                                                          
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.travelClassCodesGroups.map((prop, key) => {
                                                                return (
                                                                    <tr style={{textAlign:"center",cursor:"pointer"}}  key={key} > 
                                                                    <td style={tdStyle}>{prop["id"]}</td>                                                                                                                         
                                                                    <td style={tdStyle}>{prop["name"]}</td>                                                                                           
                                                                    <td style={tdStyle}>
                                                                    <tr>
                                                                            <td style={{borderTop:"0px"}}>
                                                                                <Button id="edit" type="button" size="sm" disabled={loading} onClick={(e) => this.onEdit(e,prop)}>
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Edit</span>}
                                                                                </Button>
                                                                                <Button id="edit" type="button" size="sm" style={{ marginLeft: "10px"}} disabled={loading} variant="danger" onClick={(e) => this.onDelete(prop)}>
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7", }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Delete</span>}
                                                                                </Button>
                                                                            </td>                                                                            
                                                                        </tr>
                                                                    </td>
                                                                    </tr>                                                                    
                                                                )
                                                            })}                                                                                                                      
                                                        </tbody>
                                                </Table>                                                                                                        
                                            </div> 
                                       </Col>
                                    </Row>                                                                   
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}