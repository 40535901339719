import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import * as GlobalVar from '../js/GlobalVar.js';
import * as Utils from '../js/Utils.js';

export class CommonController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getUser(userId : string){
        
        var data = {
            userId: userId,
        };
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getUser;
        return this.serviceProxy.get(url,data).then(response =>{
            if(response.success){
                var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                
                sessionStorage.setItem("uid",res.User_ID);
                sessionStorage.setItem("label_id",res.Label_ID);
                sessionStorage.setItem("user_type_id",res.User_Type_ID);
                sessionStorage.setItem("department_id",res.Department_ID);
                sessionStorage.setItem("wtp_history",res.WTP_History);
                sessionStorage.setItem("is_dha",res.Is_DHA);
                sessionStorage.setItem("tracking_grid",res.Tracking_Grid);
                sessionStorage.setItem("approver_tier_id",res.Approver_Tier_ID);
                sessionStorage.setItem("name",res.Name);
                sessionStorage.setItem("email",res.Email);
                sessionStorage.setItem("has_dha",res.has_dha);
                sessionStorage.setItem("label_desc",res.label_desc);
                sessionStorage.setItem("dept_desc",res.dept_desc);
                sessionStorage.setItem("trans_fee",res.trans_fee);
                sessionStorage.setItem("in_policy",res.in_policy);
                sessionStorage.setItem("approver_weight",res.approver_weight);
                sessionStorage.setItem("voucher_plus",res.Voucher_Plus);
                sessionStorage.setItem("car_service_company_id",res.car_service_company_id);

                if(res.User_Type_ID === 'S'){
                    sessionStorage.setItem("sysop",res.User_ID);
                }
                else{
                    sessionStorage.setItem("sysop","");
                }
                return response;
            }
            else {
                this.response.msg = "";
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    } 
    
    selectMultipleRole(adUserId: string){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.selectMultipleRole;
        var data = {
            adUserId: adUserId
        }
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonMultipleRole : Array<{"userId": string, "role": string, "family": string, "department": string}> = [];
                if(response.success){
                    response.recordset.forEach(multipleRole);
                    function multipleRole(item: { user_id: any; role: any; family: any; department: any }){
                        jsonMultipleRole.push({"userId": item.user_id, "role": item.role, "family": item.family, "department": item.department})
                    }
                    return JSON.parse(JSON.stringify(jsonMultipleRole).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonMultipleRole).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getLabels(){
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getLabels;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string; "is_active": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { label_id: string; description: string;  is_active: string }, _index: number){
                    jsonLabel.push({"id": item.label_id, "name": item.description, "is_active": item.is_active ? "Yes" : "No"})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getTATraveler(data:any){
        var data1 = {
            id: data.id,
            view: data.view,
            bInner: data.bInner,
            sessionTA: data.sessionTA
        }
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getTATraveler;
        return this.serviceProxy.get(url,data1).then((response : any) => {
            var jsonTravellers : Array<{"id": string; "travelerId": string; "isDH": string; "address": string; "city": string; "stateProvinceId": string; "zip": string; "phone": string; "ssn": string; "type": string; "issuer": string; "callLetters": string; "travelPurpose": string; "fedId": string; "radioLetterReceived": string; "overrideReceived": string; "preapprovalReceived": string; "firstName": string; "lastName": string; "name": string; "formOnFile": string; "travelRestrictions": string; "RLN": string; "airfareAmount": string; "feeAmount": string; "totalAmount": string; "bit": boolean; "specialInstructions": string}> = [];
            if(response.success){
                response.recordset.forEach(traveller);
                function traveller(item: { traveler_id: string; is_dh: string;  address: string; city: string; state_province_id: string; zip: string; phone: string; ssn: string; type: string; issuer: string; call_letters: string; travel_purpose: string; fed_id: string; radio_letter_received: string; override_received: string; preapproval_received: string; first_name: string; last_name: string; name: string; FormOnFile: string; TravelRestrictions: string; RLN: string; airfare_amount: string; fee_amount: string; total_amount: string; bit: boolean; special_instructions: string}, _index: number){
                    jsonTravellers.push({"id": data.id, "travelerId": item.traveler_id, "isDH": item.is_dh, "address": item.address, "city": item.city, "stateProvinceId": item.state_province_id, "zip": item.zip, "phone": item.phone, "ssn": item.ssn, "type": item.type, "issuer": item.issuer, "callLetters": item.call_letters, "travelPurpose": item.travel_purpose, "fedId": item.fed_id, "radioLetterReceived": item.radio_letter_received, "overrideReceived": item.override_received, "preapprovalReceived": item.preapproval_received, "firstName": item.first_name, "lastName": item.last_name, "name": item.name, "formOnFile": item.FormOnFile, "travelRestrictions": item.TravelRestrictions, "RLN": item.RLN, "airfareAmount": item.airfare_amount, "feeAmount": item.fee_amount, "totalAmount": item.total_amount, "bit": item.bit, "specialInstructions": item.special_instructions})
                }
                return JSON.parse(JSON.stringify(jsonTravellers).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonTravellers).replace(/"\s+|\s+"/g, '"'));
            }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    deleteAuthReq (authReqId, airId, hotelId, carRentId, carServiceId){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.deleteAuthReq;
        var data = {
            authReqId: authReqId,
            airId: airId,
            hotelId: hotelId,
            carRentId: carRentId,
            carServiceId: carServiceId,
            sessionTA: sessionStorage.getItem("ta"),
            taCarService2: sessionStorage.getItem("ta_car_service2")
        }
        return this.serviceProxy.delete(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    callWebService (){
        fetch('http://eta-epictest.umusic.net/eta_webservice/BCDWS.asmx', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Host': 'eta-epictest.umusic.net',
            'SOAPAction': 'http://eta.umusic.com/BCD_SubmitTA_Validation',
            'Content-Type': 'text/plain'
        },
        body: '<?xml version="1.0"?><soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><BCD_SubmitTA_Validation xmlns="http://eta.umusic.com/"><authReqId>421775</authReqId></BCD_SubmitTA_Validation></soap:Body></soap:Envelope>'
        //body: '<?xml version="1.0" encoding="utf-8"?><soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope"><soap12:Body><BCD_SubmitTA_Validation xmlns="http://eta.umusic.com/"><authReqId>421775</authReqId></BCD_SubmitTA_Validation></soap12:Body></soap12:Envelope>'
        }).then(function (response) {
            //console.log(response.text());
        }).catch(function (error) {
            //console.log('Request failed', error);
        });
    }

    getTaAirCodingOK(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getTaAirCodingOK;
        var data1 = {
            authreqId: data.authreqId,
            sType: data.sType,
            iBooking: data.iBooking
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("getTaAirCodingOK");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    getTaHotelCodingOK(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getTaHotelCodingOK;
        var data1 = {
            authreqId: data.authreqId,
            sType: data.sType,
            iBooking: data.iBooking
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("getTaHotelCodingOK");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    getTaRentalCarCodingOK(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getTaRentalCarCodingOK;
        var data1 = {
            authreqId: data.authreqId,
            sType: data.sType,
            iBooking: data.iBooking
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("getTaRentalCarCodingOK");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    getTaCarServiceCodingOK(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getTaCarServiceCodingOK;
        var data1 = {
            authreqId: data.authreqId,
            sType: data.sType,
            iBooking: data.iBooking,
            carService: data.carService
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("getTaCarServiceCodingOK");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    getTaCoding(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getTaCoding;
        var data1 = {
            authreqId: data.authreqId,
            sCode: data.sCode,
            taCarService2: data.taCarService2
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("getTaCoding");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    UpdateTaMove (data){
        
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.updateTAMove;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    selectAppUserByExpType(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.selectAppUserByExpType;
        var data1 = {
            expenseTypeId: data.expenseTypeId,
            departmentId: data.departmentId
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("selectAppUserByExpType");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    selectMaxApprovers(data: any){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.selectMaxApprovers;
        var data1 = {
            expenseTypeId: data.expenseTypeId,
            departmentId: data.departmentId,
            labelId: data.labelId
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    //console.log("selectAppUserByExpType");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }
    getTravelerInfoFormRequired(authreqId:string){
        //
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getTravelerInfoFormRequired;
        var data = {
            authreqId: authreqId,
        }
        return this.serviceProxy.get(url,data)
            .then(response => {
                if(response.success){
                    //console.log("getTaCarServiceCodingOK");
                    //console.log(response);
                    return response;
                }
            }).catch(error =>{
                throw new Error('There was an error! '+ error)
                console.error('There was an error!', error);
            }); 
    }

    UpdateTAHistory (data){
        
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.updateTAHistory;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    callRestAPI (){
        var url = this.variables.Url+ this.variables.Controller.restAPI + this.variables.restAPIService.postRestAPI;
        return this.serviceProxy.post(url,{}).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

//     userLogsInOptions = () => {
//         debugger;
//         // alert("userLogs");
//         const formData = new URLSearchParams();
//         formData.append('grant_type', this.variables.grant_type); // 'client_credentials');
//         formData.append('client_id', this.variables.client_id); // '5910bdae66lhsb2qgjkmhosa05');
//         formData.append('client_secret', this.variables.client_secret); // '175uuosmoj0k819stc6h336p7hqojdfoonu8si7cqtt2pjat2vt5');
//         console.log(formData, "check");
//         return (
//           {
//             method: 'POST',
//             headers: {
//               "Content-Type": "application/x-www-form-urlencoded",
//           },
//             body: formData.toString(),
//             json: true,
//         }
//         );
//   };
//   getTokenAccess = async () => {
//     debugger;
//     // alert("getTokenAccess");
//       const tokenAccesspath = this.variables.BCD_TokenAccessPath; //'https://auth.travel-data-api.bcdtravel.com/oauth2/token';
//       const response = await fetch(
//         tokenAccesspath,
//         this.userLogsInOptions(),
//       ).then(async response => {
//         debugger;
//         console.log(response,"response1")
//         const responseJson = await response.json();
        
//         console.log(responseJson,"responseJson")
//        /* localStorage.setItem('accessToken',responseJson.access_token);
//         localStorage.setItem('expiresIn',responseJson.expires_in);
//         localStorage.setItem('tokenType',responseJson.token_type);*/

//         var access_token =   await JSON.parse(JSON.stringify(responseJson.data.attributes.token));
//         var expires_in = await JSON.parse(JSON.stringify(responseJson.data.attributes.expires_in));
//         var token_type = await JSON.parse(JSON.stringify(responseJson.data.attributes.token_type));
//         localStorage.setItem('accessToken',access_token);
//         localStorage.setItem('expiresIn',expires_in);
//         localStorage.setItem('tokenType',token_type);

//         let d: Date = Utils.getSysDate();  
//         localStorage.setItem('tokenGenerated',d.toString());   
//         let addHoursInDate: Date = new Date(d.getTime() + (1*expires_in*1000));
//         localStorage.setItem('tokenExpiry',addHoursInDate.toString());
//         let expIn: Date = new Date(addHoursInDate.setMinutes(addHoursInDate.getMinutes() - 10));
//         localStorage.setItem('refreshToken', expIn.toString());

//         //console.log('access_token ', access_token);

//         if (responseJson.error) {
//         console.error('error ', responseJson.error);
//         }
//         //console.log('json ', responseJson);
//         return access_token
//         //return responseJson;
//     }).catch(error =>{
//         console.error('There was an error!', error);
//         throw new Error('There was an error! '+ error)
//     });
//   };

// Frontend: Calling backend API for token
getTokenAccess = async () => {
    const tokenAccesspath =this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getAccessToken;  // This points to your backend API
    const response = await fetch(tokenAccesspath)
        .then(async (response) => {
            // console.log(response,"responsenewcode")

            const responseJson = await response.json();
// console.log(responseJson,"responseJsonnewcode")
            const access_token = responseJson.access_token;
            const expires_in = responseJson.expires_in;
            const token_type = responseJson.token_type;

            // console.log(access_token,"access_token")
            // console.log(expires_in,"expires_in")
            // console.log(token_type,"token_type")

            // Store access token and expiry details in localStorage
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('expiresIn', expires_in);
            localStorage.setItem('tokenType', token_type);



            let d: Date = Utils.getSysDate();  
            localStorage.setItem('tokenGenerated',d.toString());   
            let addHoursInDate: Date = new Date(d.getTime() + (1*expires_in*1000));
            localStorage.setItem('tokenExpiry',addHoursInDate.toString());
            let expIn: Date = new Date(addHoursInDate.setMinutes(addHoursInDate.getMinutes() - 10));
            localStorage.setItem('refreshToken', expIn.toString());


            return access_token;
        })
        .catch((error) => {
            console.error('There was an error!', error);
            throw new Error('There was an error! ' + error);
        });
};





  async getPNRByXML (data:any){
    
    var jsonResult;
    var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getPNRByXML;
    //jsonResult = await this.serviceProxy.asyncPost(url,data);
    jsonResult = await this.serviceProxy.asyncGetOne(url,data);
    
    return jsonResult;

  }

  authTokenLocalStorage = async () => {
    
    try{
        if(localStorage.getItem("accessToken") === null){
            
            await this.getTokenAccess();
            //alert("undefined");
            //console.log("Step: 2");
        }
        else
        {
            
            var d = Utils.getSysDate();
            var expIn = new Date(localStorage.getItem("refreshToken")!);
            //Regenerate Token if exceed expIn date.
            if(d > expIn){
                await this.getTokenAccess();
            }
            //console.log("Step: 2");
        }
    }
    catch(error){
        console.error('There was an error!', error);
        throw new Error('There was an error! '+ error)
    }
  }

  getBookingsBCD(authReqId, sCode){
    //
    var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getBookingsBCD;
    var data = {
        authReqId: authReqId,
        sCode: sCode
    }
    return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){
                //console.log("getBookingsBCD");
                //console.log(response);
                return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error =>{
            console.error('There was an error!', error);
            throw new Error('There was an error! '+ error)
        }); 
    }
    
    deleteBookingBCD (Id, sCode){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.deleteBookingBCD;
        var data = {
            Id: Id,
            sCode: sCode
        }
        return this.serviceProxy.delete(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
   }

   searchTripsByRecordLocator = async (data : any)=>
    {
        var url='https://travel-data-api.bcdtravel.com/v1/trips';
        const requestOptions = {
            method: 'POST',
            //mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': 'Bearer '+ localStorage.getItem("accessToken")
            },
            body: JSON.stringify(data),
            json: true,
        }
        return fetch(url,
            {
                //credentials: "include",
                method: 'POST',
                //requestOptions,
                //json: true,
            })
        .then(response => {
            ////console.log("searchTripsByRecordLocator:", response.json())
            return response;//.json()
        })
        .catch(error => {return error});
    }
    
    requestOptions = () => {
        
        var data={};
        data={
            "recordLocator": "BMSAAD"
        }
        return (
          {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("accessToken"),
                'Content-Type': 'text/json',
                'Accept': 'text/json',
                'Host': 'travel-data-api.bcdtravel.com', 
                'mode':'no-cars',            
          },
            body: JSON.stringify(data),
            }
        );
  };
  getSuperPNR = async () => {
      const tokenAccesspath = 'https://travel-data-api.bcdtravel.com/v1/trips'
      const response = await fetch(
        tokenAccesspath,
        this.requestOptions(),
      ).then(async response => {
        const responseJson = await response.json();
        //console.log("Logged", responseJson)     
        if (responseJson.error) {
            console.error('error ', responseJson.error);
            }
            //console.log('json ', responseJson);
    
            return responseJson;
    }).catch(error =>{
        console.error('There was an error!', error);
        throw new Error('There was an error! '+ error)
    });
  };

   async callPORestAPI(data:any){
        var url = this.variables.Url+ this.variables.Controller.soapSAP + this.variables.soapSAPService.sapPOValidation;
        
        return await this.serviceProxy.get(url,data).then(async res => {
            return await res;  
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    UpdateTA (data){
        
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.updateTA;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    UpdateTRCoding (data){
        
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.updateTRCoding;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    getAllCountry(){
        
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getAllCountry;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonCountry : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(country);
                function country(item: { Country_ID: string; Name: string }, _index: number){
                    jsonCountry.push({"id": item.Country_ID, "name": item.Name})
                }
                
                return JSON.parse(JSON.stringify(jsonCountry).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCountry).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getStateProvince(countryId){
        
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getStateProvince;
        var data={
            countryId : countryId
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonState : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(state);
                function state(item: { State_Province_ID: string; Name: string }, _index: number){
                    jsonState.push({"id": item.State_Province_ID, "name": item.Name})
                }
                
                return JSON.parse(JSON.stringify(jsonState).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonState).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    getSubmitter(userId : string){
        
        var data = {
            userId: userId,
        };
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getSubmitter;
        return this.serviceProxy.get(url,data).then(response =>{
            if(response.success){
                return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = "";
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    deleteTraveller (authReqId, travellerId){
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.deleteTraveller;
        var data = {
            authReqId: authReqId,
            travellerId: travellerId
        }
        return this.serviceProxy.delete(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
   }
   getApproverHistoryBCD(authReqId : string){
    
    var data = {
        authReqId: authReqId,
    };
    var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.getApproverHistoryBCD;
    return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){
                return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = "";
                this.response.success = false;
                return this.response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
            throw new Error('There was an error! '+ error)
        }); 
    }
    getAirLineBCDCodes(){
        
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getAirLineBCDCodes;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonAirLineCode : Array<{"id": string; "BCDCode": any}> = [];
            if(response.success){
                response.recordset.forEach(airlineid);
                function airlineid(item: { Airline_ID: string; BCD_Airline_code: any }, _index: number){
                    jsonAirLineCode.push({"id": item.Airline_ID, "BCDCode": item.BCD_Airline_code})
                }
                
                return JSON.parse(JSON.stringify(jsonAirLineCode).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonAirLineCode).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    upload (data){
        
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.upload;
        return this.serviceProxy.postupload(url,data).then(response => {
            
           return response;
        }).catch(error =>{
            
            console.error('There was an error!', error);
        });
    }
    writeFile (errMsg:any){
        
        var data = {
            errMsg: errMsg
        }
        var url = this.variables.Url+ this.variables.Controller.common + this.variables.commonService.writeFile;
        return this.serviceProxy.post(url,data)
        .then(response => {
            if(response.success){
                return JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = "";
                this.response.success = false;
                return this.response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
            throw new Error('There was an error! '+ error)
        }); 
    }
    getAuthlockedDetails(data) {
        
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getAuthlockedDetails;
        return this.serviceProxy.get(url, data)
            .then(response => {
                if (response.success) {
                    return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
                    //return response;
                }
                else {
                    this.response.msg = response.message.toString();
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });
    }

    getAuthlockedCount(data) {
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.getAuthlockedCount;
        return this.serviceProxy.get(url, data)
            .then(response => {
                if (response.success) {
                    return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    this.response.msg = response.message.toString();
                    this.response.success = false;
                    return this.response;
                }
            }).catch(error => {
                console.error('There was an error!', error);
                return error;
            });
    }

    updateLockAuthreq(data) {
        
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.updateLockAuthreq;
        return this.serviceProxy.post(url, data).then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    updateUnLockAuthreq(data) {
        
        var url = this.variables.Url + this.variables.Controller.common + this.variables.commonService.updateUnLockAuthreq;
        return this.serviceProxy.post(url, data).then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}
