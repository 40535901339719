import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';
import { stringify } from 'querystring';
import { CarVoucherHistory } from '../Components/View/CarVoucher/CarVoucherHistory';

export class CarVoucherController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getCarCompanyName (){
        var url = this.variables.Url+ this.variables.Controller.carVoucher + this.variables.carVoucherService.getCarCompanyName;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonCarCompanyName : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { company_name_id: any; description: any }){
                        jsonCarCompanyName.push({"id": item.company_name_id, "name": item.description})
                    }
                    return JSON.parse(JSON.stringify(jsonCarCompanyName).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCarCompanyName).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getHFMEntity (){
        var url = this.variables.Url+ this.variables.Controller.carVoucher + this.variables.carVoucherService.getHFMEntity;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonHFMEntity : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { label_id: any; description: any }){
                        jsonHFMEntity.push({"id": item.label_id, "name": item.description})
                    }
                    return JSON.parse(JSON.stringify(jsonHFMEntity).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonHFMEntity).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    createVoucher(data)
    {
        var url = this.variables.Url+ this.variables.Controller.carVoucher + this.variables.carVoucherService.createVoucher;
        var data1 = {
            userId: data.userId,
            passengerName: data.passengerName,
            company: data.company,
            phone: data.phone,
            employeeNumber: data.employeeNumber,
            costCenter: data.costCenter,
            vipNumber: data.vipNumber,
            pickUpPoint: data.pickUpPoint,
            pickUpTime: data.pickUpTime,
            dropOffPoint: data.dropOffPoint,
            companyNameId: data.companyNameId,
            comments: data.comments           
        }
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getCarVoucherHistory (data: any){
        var url = this.variables.Url+ this.variables.Controller.carVoucher + this.variables.carVoucherService.getCarVoucherHistory;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonVoucherHistory : Array<{"voucherId": string, "passengerName": string, "createdDate": string}> = [];
                if(response.success){
                    //alert(response.success)
                     response.recordset.forEach(carVoucherHistory);                   
                    function carVoucherHistory(item: { voucher_id: any; passenger_name: any;created_date:any }){
                        jsonVoucherHistory.push({"voucherId": item.voucher_id, "passengerName": item.passenger_name,
                        "createdDate":item.created_date})
                    }
                    return JSON.parse(JSON.stringify(jsonVoucherHistory).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonVoucherHistory).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarVoucherdetails (data: any){
        var url = this.variables.Url+ this.variables.Controller.carVoucher + this.variables.carVoucherService.getCarVoucherDetails;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonVoucherDetails : Array<{"account": string, "passengerName": string, "company": string, "phone": string,
             "empNo": string, "costService": string, "vipNo": string, "pickUpPoint": string, "pickUpDtTm": string, "dropOffPoint": string,
              "name": string, "output": string,"description":string}> = [];
                if(response.success){                                        
                     response.recordset.forEach(carVoucherDetails);                   
                    function carVoucherDetails(item: { account: any; passenger_name: any;company:any;phone:any ;employee_number:any ;cost_center:any 
                        ;vip_number:any ;pick_up_point:any ;pick_up_time:any ;drop_off_point:any ;name:any ;output:any;description:any })
                        {
                           jsonVoucherDetails.push({"account": item.account, "passengerName": item.passenger_name,"company":item.company,
                        "phone":item.phone,"empNo":item.employee_number,"costService":item.cost_center,"vipNo":item.vip_number,"pickUpPoint":item.pick_up_point,
                        "pickUpDtTm":item.pick_up_time,"dropOffPoint":item.drop_off_point,"name":item.name,"output":item.output,description:item.description })
                    }                    
                    return JSON.parse(JSON.stringify(jsonVoucherDetails).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonVoucherDetails).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarCompanyDetails(data:any)
    {
        var url = this.variables.Url+ this.variables.Controller.carVoucher + this.variables.carVoucherService.getCarCompanyDetails;
       return this.serviceProxy.get(url,data).then(response=>{
            var jsonCarCompanydetail : Array<{"carCompany": string, "email": string,"emailCC":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { car_company: any; email: any;email_cc:any }){
                        jsonCarCompanydetail.push({"carCompany": item.car_company, "email": item.email, "emailCC": item.email_cc})
                    }
                    return JSON.parse(JSON.stringify(jsonCarCompanydetail).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCarCompanydetail).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }


    

}