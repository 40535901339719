import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';
import { stringify } from 'querystring';

export class MenuController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    getMenuVariable(userId : string, lastActivityDate: string){
        
        var url = this.variables.Url+ this.variables.Controller.menu + this.variables.menuService.getMenuVariable;
        var data1 = {
            "userId": userId,
            "lastActivityDate": lastActivityDate
        }
        return this.serviceProxy.get(url,data1)
            .then(response => {
                if(response.success){
                    return response;
                }
            }).catch(error =>{
                console.error('There was an error!', error);
            }); 
    }

    getCarServiceCompany (){
        var url = this.variables.Url+ this.variables.Controller.menu + this.variables.menuService.getCarServiceCompany;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonCarServiceCompany : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { Car_Service_Company_ID: any; Car_Service_Company: any }){
                        jsonCarServiceCompany.push({"id": item.Car_Service_Company_ID, "name": item.Car_Service_Company})
                    }
                    return JSON.parse(JSON.stringify(jsonCarServiceCompany).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCarServiceCompany).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    searchUserDirect (searchValue: string, type: string){
        var url = this.variables.Url+ this.variables.Controller.menu + this.variables.menuService.searchUserDirect;
        var data = {
            searchValue: searchValue,
            type: type
        };
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){
                var resUser = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resUser;
            }
            else {
                
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    searchUser (searchValue: string, type: string){
        var url = this.variables.Url+ this.variables.Controller.menu + this.variables.menuService.searchUser;
        var data = {
            searchValue: searchValue,
            type: type
        };
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){
                var resUsers = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                return resUsers;
            }
            else {
                
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}