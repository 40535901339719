import React, { Component } from "react";
import { ICreateAuthParameter, Istate } from "../../../Model/ICreateAuth";
import { FormLabel, Container, Row, Col, Form, Alert, Button, Card, FormGroup, FormControl } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CreateTAController } from "../../../Controllers/CreateTAController";
import { MenuController } from "../../../Controllers/MenuController";
import { TravellerController } from "../../../Controllers/TravellerController";
import { CommonController } from "../../../Controllers/CommonController";
import { alert, confirm } from 'react-bootstrap-confirmation';

export class CreateAuth extends Component<ICreateAuthParameter,Istate> {
    constructor(props:ICreateAuthParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {authType:'', SAPEmpNo:'', projectName:'', artistName:'', projectNumber:''},
            selectfields: {carServiceCompanyId:'', carServiceCompanyName:'', travelAgentId:'', travelAgentName:'', expenseTypeId:'', expenseTypeName:''},
            checkfields: {isCorporateCard:false},
            optionfields : {employeeTravel:true, conventionProject:false},
            errors:{carServiceCompanyId:'', travelAgentId:'', expenseTypeId:'', SAPEmpNo:'', projectName:'', artistName:'', projectNumber:''},
            errMsg:"",
            loading:false,
            carServiceCompany:[],
            travelAgent:[],
            expenseType:[],
            modal: {modalSpinnerShow: false}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);        
    }

    componentWillMount(){
        let modal = this.state.modal;
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        modal['modalSpinnerShow']=true;       
        fields['authType'] = this.props.location.state.authType;
        sessionStorage.setItem("frompage", "createauthreq");
        if(this.state.fields.authType === "Car Service"){
            selectfields["carServiceCompanyName"] = "Select Car Service Company";
            selectfields["travelAgentName"] = "Select Car Service Agent"
        }
        else
        {
            selectfields["travelAgentName"] = "Select Travel Agent";
        }
        selectfields["expenseTypeName"] = "Select Authorization Type";
        this.setState({ fields, selectfields, modal });
        let menuController = new MenuController();
        menuController.getCarServiceCompany().then(jsonCarServiceCompany => {
            this.setState({ carServiceCompany: JSON.parse(JSON.stringify(jsonCarServiceCompany))});
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error!", error);
        })
        let createTAController = new CreateTAController();
        //if(sessionStorage.getItem("ta_car_service_only") === "true")
        if(this.state.fields.authType === "Car Service")
        {
            createTAController.getCarServiceAgent('').then(jsonCarServiceAgent => {
                this.setState({                           
                    travelAgent : JSON.parse(JSON.stringify(jsonCarServiceAgent))
                });
            }).catch(error => {
                console.error("There was an error!", error);
            })
        }
        else{
            createTAController.getTravelAgent().then(jsonTravelAgent => {
                this.setState({                           
                    travelAgent : JSON.parse(JSON.stringify(jsonTravelAgent))
                });
            }).catch(error => {
                console.error("There was an error!", error);
            })

        }
        
        createTAController.getAuthorizationType(sessionStorage.getItem('uid')!).then(jsonExpenseType => {
            this.setState({                           
                expenseType : JSON.parse(JSON.stringify(jsonExpenseType))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }).catch(error => {
            console.error("There was an error!", error);
        })
    }

    handleChange(e:any){
        let fields= this.state.fields;
        if(e.currentTarget.name === 'projectName'){
            var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/)
            if (regexp.test(e.currentTarget.value)){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                alert("Project Name must be alphanumeric only...")
            }
        }
        else{
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({ fields });
    }

    handleSelectChange(e: any) {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        if(e.currentTarget.name === 'carServiceCompanyId'){
            let modal = this.state.modal;
            modal['modalSpinnerShow'] = true;
            this.setState({modal});
            selectfields["carServiceCompanyName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            if (e.currentTarget.value === "Select Car Service Company"){
                selectfields["travelAgentName"] = "Select Car Service Agent";
            }
            else{
                selectfields["travelAgentId"] = "Not Yet Assigned";
                selectfields["travelAgentName"] = "Not Yet Assigned";
            }
            
            let createTAController = new CreateTAController();
            //console.log(e.currentTarget.value)
            createTAController.getCarServiceAgent(e.currentTarget.value).then(jsonCarServiceAgent => {
                modal['modalSpinnerShow'] = false;
                this.setState({                           
                    travelAgent : JSON.parse(JSON.stringify(jsonCarServiceAgent), modal)
                });
                //console.log("travelAgent:", this.state.travelAgent)
            }).catch(error => {
                modal['modalSpinnerShow'] = false;
                this.setState({modal});
                console.error("There was an error!", error);
            })
        }
        if(e.currentTarget.name === 'travelAgentId'){
            selectfields["travelAgentName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        if(e.currentTarget.name === 'expenseTypeId'){
            selectfields["expenseTypeName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        this.setState({ selectfields });
        //console.log(e.currentTarget.value)
    }

    handleCheckChange(e:any){
        let checkfields = this.state.checkfields;
        checkfields[e.currentTarget.name] = !this.state.checkfields.isCorporateCard;
        this.setState({
            checkfields
        });
    }

    handleOptionChange(e:any){
        let optionfields = this.state.optionfields;
        let currentTargetId = e.currentTarget.id;
        if (currentTargetId === "employeeTravel") {
            optionfields['employeeTravel'] = true;
            optionfields['conventionProject'] = false;
        }
        else if (currentTargetId === "conventionProject") {
            optionfields['employeeTravel'] = false;
            optionfields['conventionProject'] = true;
        }
        this.setState({ optionfields });
    }

    async taMaxWeight(taExpenseTypeId){
        if (sessionStorage.getItem("ta") !== ""){
            if (sessionStorage.getItem("department_id") === '143' && sessionStorage.getItem("ta_expense_type_id") === "10"){
                return 2;
            }
        }
        var data = {};
        data = {
            expenseTypeId: taExpenseTypeId,
            departmentId: sessionStorage.getItem("department_id")
        }
        let commonController = new CommonController()
        let appUserByExpType: boolean = false;
        await commonController.selectAppUserByExpType(data).then(response => {
            appUserByExpType = (response !== undefined) ? true : false
            //console.log("selectAppUserByExpType", appUserByExpType)
        }).catch(error => {
            console.error("There was an error!", error)
        });

        if (sessionStorage.getItem("has_dha")==='true' && sessionStorage.getItem("ta_expense_type_id") === "100" && sessionStorage.getItem("ta_is_dh") === 'true'){
            return sessionStorage.getItem("ta_max_approvers");
        }	
		else if (appUserByExpType){
            return 3;
        }
        else{
            data={
                expenseTypeId: taExpenseTypeId,
                departmentId: sessionStorage.getItem("department_id"),
                labelId: sessionStorage.getItem("label_id")
            }
            return await commonController.selectMaxApprovers(data).then(response => {
                //console.log("selectMaxApprovers")
                return (response != undefined) ? response.max_approvers : sessionStorage.getItem("ta_weight"); //'user should not be moving the ta
            }).catch(error => {
                console.error("There was an error!", error)
            }) 
        }
    }

    validateForm(){
        let isFormValid = true;
        if(this.state.selectfields.expenseTypeId == 100 && this.state.optionfields.employeeTravel){
            return isFormValid;
        }
        if(this.state.fields.projectName === ""){
            alert("Please enter a project name")
            isFormValid = false;
        }
        return isFormValid;
    }

    async onSubmit(e:any){
        e.preventDefault();
        if(this.validateForm()){
            this.setState({ loading: true });
            var weight = await this.taMaxWeight(this.state.selectfields.expenseTypeId);
            //console.log("weight", weight);
            let createTAController = new CreateTAController();
            var data = {
                departmentId: sessionStorage.getItem('department_id'),
                labelId: sessionStorage.getItem('label_id'),
                expenseTypeId: this.state.selectfields.expenseTypeId,
                weight: weight,
                artistProjectName: this.state.fields.projectName,
                artistProjectNumber: this.state.fields.projectNumber,
                artistName: this.state.fields.artistName,
                businessUnit: "",
                sb2: 0,
                travelAgentName: this.state.selectfields.travelAgentName,
                isCorporateCard: this.state.checkfields.isCorporateCard,

                carServiceOnly:(this.state.fields.authType === "Car Service")?1:0,
                carServiceCompanyId:(this.state.fields.authType === "Car Service")?this.state.selectfields.carServiceCompanyId:null,
                inPolicy: 0,
                createdBy: sessionStorage.getItem('uid'),
                updatedBy: sessionStorage.getItem('uid')
            }
    
            //console.log("create TA data", data);
            createTAController.createTA(data).then(response => {
                //console.log(response);
                let travellerController = new TravellerController();
                travellerController.getTA(response.authreqId).then(response => {
                    sessionStorage.setItem('page','submitter')
                    this.props.history.push('/admin/traveller');
                    this.setState({ loading: false });
                }).catch(error => {
                    this.setState({ loading: false });
                    console.error("There was an error!", error)
                })  
            }).catch(error => {
                this.setState({ loading: false });
                console.error("There was an error!", error)
            })
        }
    }

    projectName(){
        return <FormGroup as={Row}>
            <FormLabel column sm="4">Project Name  <span style={{color:"Red"}}>Only alphanumeric allowed with Max of 35 character</span></FormLabel>
            <Col sm="4">
                <FormControl id="projectName" name="projectName" value={this.state.fields.projectName} maxLength={35} onChange={this.handleChange}/>
            </Col>
            <Col sm="4"></Col>
        </FormGroup>
    }

    artistName(){
        return <FormGroup as={Row}>
            <FormLabel column sm="4">Artist Name (optional)</FormLabel>
            <Col sm="4">
                <FormControl id="artistName" name="artistName" value={this.state.fields.artistName} maxLength={30} onChange={this.handleChange}/>
            </Col>
            <Col sm="4"></Col>
        </FormGroup> 
    }

    projectNumber(){
        return <FormGroup as={Row}>
            <FormLabel column sm="4">Project Number (optional)</FormLabel>
            <Col sm="4">
                <FormControl id="projectNumber" name="projectNumber" value={this.state.fields.projectNumber} maxLength={24} onChange={this.handleChange}/>
            </Col>
            <Col sm="4"></Col>
        </FormGroup> 
    }

    render() {
        const{ loading } = this.state;
        //console.log("selectfields:", this.state.selectfields);
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Create {this.state.fields.authType} Authorization</h4><p className="category"></p></div>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                    <Col sm={12} style={{textAlign:"right"}}>
                                    <Button id="print" type="button" size="sm" onClick={() => window.print()} >
                                        Print
                                    </Button>
                                    </Col>
                                </Row>
                                {this.state.fields.authType === "Car Service" ?
                                <Row>
                                    <Col sm={4}>
                                        <FormSelect
                                            properties={{ label: "Select Car Service Company:", id: "carServiceCompanyId", name: "carServiceCompanyId", value: this.state.selectfields.carServiceCompanyId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                            fieldRequired={true}
                                            errorMsg={this.state.errors.carServiceCompanyId}
                                            defaultOption="Select Car Service Company"
                                            fillOption={this.state.carServiceCompany}
                                        />
                                    </Col>
                                    <Col sm={4}></Col>
                                    <Col sm={4}></Col>
                                </Row>
                                :<></>}
                                <Row>
                                    <Col sm={4}>
                                    <FormLabel>Charge {(this.state.fields.authType === "Car Service")?"Car":"Travel"} to Employee Corporate Card:</FormLabel>
                                        <FormCheckBox
                                            properties={{label:'Check if "Yes"',id:"isCorporateCard",name:"isCorporateCard",type:"checkbox",className:"form-control-md",onChange:this.handleCheckChange,checked:this.state.checkfields.isCorporateCard}}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        {(this.state.fields.authType === "Car Service")?
                                        <Form.Group>
                                            <Form.Label>Car Service Agent<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                                            <Form.Control as="select" id="travelAgentId" name="travelAgentId" className="form-control form-control-sm"  value={this.state.selectfields.travelAgentId} onChange={(e)=>{this.handleSelectChange(e)}}>
                                             {(this.state.travelAgent.length > 0)?<option value={"Not Yet Assigned"}>{"Not Yet Assigned"}</option> : <option value={-1}>{"Select Car Service Agent"}</option>}
                                                {this.state.travelAgent.map((prop, key) => 
                                                {
                                                    return(
                                                    <option value={prop["id"]}>{prop["name"]}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                            {this.state.errors.travelAgentId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.travelAgentId}</div> : <></> }
                                        </Form.Group>:
                                         <FormSelect
                                            properties={{ label: "Travel Agent:", id: "travelAgentId", name: "travelAgentId", value: this.state.selectfields.travelAgentId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                            fieldRequired={true}
                                            errorMsg={this.state.errors.travelAgentId}
                                            defaultOption="Select Travel Agent"
                                            fillOption={this.state.travelAgent}
                                        />}
 
                                    </Col>
                                    <Col sm={4}>
                                        <FormSelect
                                            properties={{ label: "Authorization Type:", id: "expenseTypeId", name: "expenseTypeId", value: this.state.selectfields.expenseTypeId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                            fieldRequired={true}
                                            errorMsg={this.state.errors.expenseTypeId}
                                            defaultOption="Select Authorization Type"
                                            fillOption={this.state.expenseType}
                                        />
                                    </Col>
                                </Row>
                                {this.state.selectfields.expenseTypeName === "Select Authorization Type" ? 
                                <></> 
                                : (this.state.selectfields.expenseTypeName === "Marketing" || this.state.selectfields.expenseTypeName === "Recording" || this.state.selectfields.expenseTypeName === "Ultra Marketing" || this.state.selectfields.expenseTypeName === "Ultra Recording") ?
                                    <>{this.projectName()}
                                    {this.artistName()}
                                    {this.projectNumber()}</>
                                : this.state.selectfields.expenseTypeName === "Overhead" ?
                                    <><Row>
                                        <Col sm={4}>
                                            <FormLabel>Overhead Type:</FormLabel>
                                            <Form.Group>
                                                <Row style={{alignItems: "flex-end", paddingLeft: "1rem", paddingTop: "0.50rem"}}>
                                                    <Col sm={6} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Employee Travel" name="overheadType"  id="employeeTravel" onChange={this.handleOptionChange} defaultChecked={this.state.optionfields.employeeTravel} value='0' /></Col>
                                                    <Col sm={6} style={{paddingRight: "0.50rem"}}><Form.Check type="radio" label="Convention Project" name="overheadType"  id="conventionProject" onChange={this.handleOptionChange} defaultChecked={this.state.optionfields.conventionProject} value='1' /></Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={8}></Col>
                                    </Row>
                                    <>{this.state.optionfields.employeeTravel ?
                                        <FormGroup as={Row}>
                                            <FormLabel column sm="4">SAP Employee Number (optional)</FormLabel>
                                            <Col sm="4">
                                                <FormControl id="projectNumber" name="projectNumber" value={this.state.fields.projectNumber} maxLength={24} onChange={this.handleChange}/>
                                            </Col>
                                            <Col sm="4"></Col>
                                        </FormGroup>
                                        :
                                        <>{this.projectName()}
                                        {this.projectNumber()}
                                        </>}
                                    </></>
                                : this.state.selectfields.expenseTypeName === "International ReBill" ?
                                <>
                                    {this.projectName()}
                                    {this.projectNumber()}
                                </>
                                : <></>}
                                <Row>
                                    <Col sm={4} className="align-self-center" style={{textAlign: "justify"}}></Col>
                                    {(this.state.selectfields.carServiceCompanyName === "Select Car Service Company" || this.state.selectfields.expenseTypeName === "Select Authorization Type" || (this.state.selectfields.travelAgentName === "Select Travel Agent" || this.state.selectfields.travelAgentName === "Select Car Service Agent" || this.state.selectfields.travelAgentName === "Not Yet Assigned"))?
                                    <Col sm={4}></Col>
                                    :<Col sm={4} style={{textAlign: "center", alignSelf: "center", marginBottom:"15px"}} >
                                        <Button id="createTA" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" , color:"#3472F7" }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                        {!loading && <span>Create</span>}
                                        </Button>
                                    </Col>}
                                    <Col sm={4}></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className="text-primary font-size-xs small">if this {(this.state.fields.authType === "Car Service")?"Car":"Traveller"} Authorization is to be paid by Employee Corporate Card, click the checkbox.</div>
                                        {(this.state.fields.authType === "Car Service" && this.state.selectfields.carServiceCompanyName === "Select Car Service Company")?<div className="text-danger font-size-xs small">{this.state.selectfields.carServiceCompanyName}</div>:''}
                                        {(this.state.fields.authType === "Car Service")?
                                            (this.state.selectfields.travelAgentName === "Select Car Service Agent")?<div className="text-danger font-size-xs small">{this.state.selectfields.travelAgentName}</div>:''
                                            
                                        :
                                            (this.state.selectfields.travelAgentName === "Select Travel Agent")?<div className="text-danger font-size-xs small">{this.state.selectfields.travelAgentName}</div>:''
                                        }        
                                         {(this.state.fields.authType === "Car Service")?
                                            (this.state.selectfields.travelAgentName === "Not Yet Assigned")?<div className="text-danger font-size-xs small">Please Assign Car Service Agent</div>:''
                                            
                                        :
                                            ''
                                        }                                    
                                        {(this.state.selectfields.expenseTypeName === "Select Authorization Type")?<div className="text-danger font-size-xs small">{this.state.selectfields.expenseTypeName}</div>:''}
                                        {(this.state.selectfields.carServiceCompanyName === "Select Car Service Company" || this.state.selectfields.expenseTypeName === "Select Authorization Type" || (this.state.selectfields.travelAgentName === "Select Travel Agent" || this.state.selectfields.travelAgentName === "Select Car Service Agent" ))?'':<div className="text-primary font-size-xs small">Click create to continue.</div>}
                                    </Col>
                                </Row>
                            </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}