import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class BusinessUnitController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getAllBusinessUnits (data:any){
        var url = this.variables.Url+ this.variables.Controller.businessUnitMaintenance + this.variables.businessUnitMaintenanceService.getBusinessUnitByExpenseType;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetBunits : Array<{"businessUnit": string, "description": string,"isActive":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(businessUnitValue);
                    function businessUnitValue(item: { business_unit: any; description: any;is_active:any;}){
                    jsonGetBunits.push({"businessUnit": item.business_unit, "description": item.description,"isActive": item.is_active})
                    }
                    return JSON.parse(JSON.stringify(jsonGetBunits).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetBunits).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    createUpdateBusinessUnit(data)
    {
        var url = this.variables.Url+ this.variables.Controller.businessUnitMaintenance + this.variables.businessUnitMaintenanceService.createUpdateBusinessUnit;
        var data1 = {
            doBy: data.doBy,
            expenseTypeId: data.expenseTypeId,
            businessUnit: data.businessUnit,
            description: data.description,            
            isActive: data.isActive                 
        }
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

}