import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class LabelMaintenanceController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    createUpdateLabel(data)
    {
        var url = this.variables.Url+ this.variables.Controller.labelMaintenance + this.variables.labelMaintenanceService.createUpdateLabel;
        var data1 = {
            doBy: data.doBy,
            labelId: data.labelId,
            description: data.description,
            environNumber: data.environNumber,
            opCode: data.opCode,
            shortName: data.shortName,
            travelCount: data.travelCount,
            isActive: data.isActive,
            hasDha: data.hasDha,
            transFee: data.transFee,
            inPolicy: data.inPolicy,
            travelForm: data.travelForm,
            oldOpCode: data.oldOpCode         
        }
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getAllLabels (){
        var url = this.variables.Url+ this.variables.Controller.labelMaintenance + this.variables.labelMaintenanceService.getAllLabels;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetLabels : Array<{"labelid": string, "description": string,"environNumber":string,"opCode":string,"isActive":boolean
            ,"hasDHA":boolean,"transFee":boolean,"inPolicy":boolean,"travelForm":boolean,"shortName":string,"travelCount":string,"oldOpCode":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { Label_ID: any; Description: any;Environ_Number:any;Op_Code:any;Is_Active:any;
                        Has_DHA:any;Trans_Fee:any;In_Policy:any;Travel_Form:any;Short_Name:any;Travel_Count:any;Old_Op_Code:any }){
                    jsonGetLabels.push({"labelid": item.Label_ID, "description": item.Description, "environNumber": item.Environ_Number,
                        "opCode": item.Op_Code, "isActive": item.Is_Active, "hasDHA": item.Has_DHA, "transFee": item.Trans_Fee,
                        "inPolicy": item.In_Policy, "travelForm": item.Travel_Form, "shortName": item.Short_Name,
                        "travelCount": item.Travel_Count, "oldOpCode": item.Old_Op_Code})
                    }
                    return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}