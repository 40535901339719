import React, { Component } from "react";
import {IApprovalProcessParameter, Istate } from "../../../Model/IApprovalProcess";
import {Container,Row,Col,Form,Alert,Button,Card,Modal, Table} from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'
import { FormInputs } from "../../CustomComponents/FormInput";
import { FormSelect } from "../../CustomComponents/FormSelect";
import { CommonController } from  "../../../Controllers/CommonController";
import {CarServiceMaintenanceController} from  "../../../Controllers/CarServiceMaintenanceController";
import { ApprovalProcessController } from "../../../Controllers/ApprovalProcessController";
import { VoucherPlusController } from "../../../Controllers/VoucherPlusContoller";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import {alert,confirm} from 'react-bootstrap-confirmation';
import { timeStamp } from "console";
import { createEmitAndSemanticDiagnosticsBuilderProgram, isConstructorDeclaration } from "typescript";

export class ApprovalProcess extends Component<IApprovalProcessParameter,Istate> {
    constructor(props:IApprovalProcessParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
       
        this.state={
            fields: {approvalWeight:'',labelId:'',departmentId:'',approvalCode:''},
            selectfields: {approvalWeightId:'', expenseTypeId:'', formLabel: '',labelId:'', approvalCodeId:'', approvalWeightFlag:false, approvalCodeFlag:false},
            errors:{userId:'',labelId:'',expenseTypeId:'',formLabel:''},
            errMsg:"",
            loading:false,
            Department:[],
            ApprovalProcess:[],
            ApprovalCode:[],
            ApprovalWeight:[],
            HMEntity:[],
            modal: {modalSpinnerShow: false,modalBody:'',modalShow:false,modalTAShow:false,modalTitle:'',modalTATitle:''},
            expenseTypeGroups:[]
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    async componentWillMount() {
        let modal = this.state.modal;
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;
        selectfields['formLabel'] = sessionStorage.getItem("label_id");
        modal['modalSpinnerShow'] = true;
                
        this.setState({ modal, selectfields });

        let commonController = new CommonController();
        await commonController.getLabels().then(jsonLabels => {
        this.setState({
            HMEntity: JSON.parse(JSON.stringify(jsonLabels))
        });
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
            console.error("There was an error loading Labels..., " + error)
        });   

        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        await carServiceMaintenanceController.getExpenseType().then(jsonGetExpenseType => {
            this.setState({ expenseTypeGroups: JSON.parse(JSON.stringify(jsonGetExpenseType))});           
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })
        selectfields["expenseTypeId"] = "All";
        let approvalProcessController = new ApprovalProcessController();
        var data = {
            labelId : (this.state.selectfields.formLabel === 'All')?'':this.state.selectfields.formLabel,
            expenseTypeId : (this.state.selectfields.expenseTypeId === 'All')?'':this.state.selectfields.expenseTypeId
        }
        await approvalProcessController.getApprovalProcess(data).then(jsonProcess => {
            this.setState({
                ApprovalProcess: JSON.parse(JSON.stringify(jsonProcess))
            });
            selectfields["departmentId"] = "Select a Department";
                //.log("department:", JSON.parse(JSON.stringify(response)).recordset);
            }).catch(error => {
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                console.error("There was an error loading approval process..., " + error)
            });
        await approvalProcessController.getApproverTierCodes().then(jsonCode => {
            this.setState({
                ApprovalCode: JSON.parse(JSON.stringify(jsonCode))
            });
            //.log("department:", JSON.parse(JSON.stringify(response)).recordset);
            }).catch(error => {
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                console.error("There was an error loading approval Code..., " + error)
            });
        await approvalProcessController.getApproverTierCodesWeight().then(jsonCodesWeight => {
            this.setState({
                ApprovalWeight: JSON.parse(JSON.stringify(jsonCodesWeight))
            });
            //.log("department:", JSON.parse(JSON.stringify(response)).recordset);
            }).catch(error => {
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                console.error("There was an error loading approval Weight..., " + error)
            }); 
        
        selectfields["approvalcodeId"] = "0";
        fields["approvalCode"] = "Submitter";
        selectfields["approvalWeightId"] = "1";
        fields["approvalWeight"] = "Authorizer";

        modal['modalSpinnerShow']=false;
        this.setState({modal, fields})
    }
    validateForm(){
        let errors = this.state.errors;
        let formIsValid = true;

        /*if(this.state.selectfields.userId === 'None Selected'){
            formIsValid = false;
            errors["userId"] = "Please Select a User ID";
        }

        if(this.state.selectfields.labelId === 'None Selected'){
            formIsValid = false;
            errors["labelId"] = "Please Select a Label";
        }*/
        
        this.setState({ errors });
        return formIsValid;
    }
    
    handleChange(e:any){
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0)
        {
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else
        {
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
        } 
        
        this.setState({fields})
    }
  
    handleSelectChange = async(e: any) => {       
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        let selectfields = this.state.selectfields;
        let currentTargetId = e.currentTarget.id;
        let currentTargetName = e.currentTarget.name;
        let fields =this.state.fields;
        
        selectfields[e.currentTarget.name] = e.currentTarget.value; 
        let approvalProcessController = new ApprovalProcessController();
        if (currentTargetId === 'formLabel' || currentTargetId === 'expenseTypeId'){
            var data = {
                labelId : (this.state.selectfields.formLabel === 'All')?'':this.state.selectfields.formLabel,
                expenseTypeId : (this.state.selectfields.expenseTypeId === 'All')?'':this.state.selectfields.expenseTypeId
            }
            await approvalProcessController.getApprovalProcess(data).then(jsonProcess => {
                this.setState({
                    ApprovalProcess: JSON.parse(JSON.stringify(jsonProcess))
                });
                selectfields["departmentId"] = "Select a Department";
                modal["modalSpinnerShow"] = false;
                    //.log("department:", JSON.parse(JSON.stringify(response)).recordset);
                }).catch(error => {
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal });
                    console.error("There was an error loading approval process..., " + error)
                });
            
                //this.state.ApprovalCode.splice(0,this.state.ApprovalCode.length);
                //this.state.ApprovalWeight.splice(0,this.state.ApprovalWeight.length);        
        }
        else if(currentTargetId === 'departmentId'){
            selectfields["approvalWeightFlag"] = false;
            selectfields["approvalCodeFlag"] = false;
            this.state.ApprovalProcess.filter(f=>f.departmentId == e.currentTarget.value && f.expenseTypeId == this.state.selectfields.expenseTypeId && f.labelId != null).map((prop,key) => {
                selectfields["approvalCodeId"] = prop["tierCode"];    
                if(prop["tierCode"] === 0){
                    fields["approvalCode"] = "Submitter";
                }
                else{
                    fields["approvalCode"] = this.state.ApprovalCode.filter(f=>f.approverTierId == prop["tierCode"]).map(m=>m.description)[0];
                }
                selectfields["approvalWeightId"] = prop["maxApprovers"];
                fields["approvalWeight"] = this.state.ApprovalWeight.filter(f=>f.approverWeight == prop["maxApprovers"]).map(m=>m.description)[0];
            })
            
            fields["labelId"] = this.state.ApprovalProcess.filter(f=>f.departmentId == e.currentTarget.value && f.expenseTypeId == this.state.selectfields.expenseTypeId).map(m=>m.labelId)[0];
            if (fields["labelId"] === null)
            {
                selectfields["approvalcodeId"] = "0";
                fields["approvalCode"] = "Submitter";
                selectfields["approvalWeightId"] = "1";
                fields["approvalWeight"] = "Authorizer";
            }
            modal["modalSpinnerShow"] = false;
        }
        else if(currentTargetId.indexOf('approvalCodeId') > -1){
            selectfields[currentTargetName] = e.currentTarget.value;
            selectfields["approvalCodeFlag"] = true;
            modal["modalSpinnerShow"] = false;
        }
        else if(currentTargetId.indexOf('approvalWeightId') > -1){
            selectfields[currentTargetName] = e.currentTarget.value;
            selectfields["approvalWeightFlag"] = true;
            modal["modalSpinnerShow"] = false;
        }
        else
        {            
            selectfields[currentTargetName] = e.currentTarget.value;
            modal["modalSpinnerShow"] = false;
        }
        //else if(currentTargetId === 'approvalCodeId'){
        //    fields["approvalCode"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        //    selectfields["approvalCodeId"] = e.currentTarget.value;
        //}
        //else if(currentTargetId === 'approvalWeightId'){
        //    fields["approvalWeight"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        //    selectfields["approvalWeightId"] = e.currentTarget.value;
        //}
            
        this.setState({ selectfields, fields, modal });
    }

    onSubmit=async(e:any,key)=>{
        let selectfields = this.state.selectfields;
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        var eventCurrentTargetId = e.currentTarget.id;
        let approvalProcessController = new ApprovalProcessController();
        const elExpenseTypeId:HTMLInputElement = document.getElementById('hidExpenseTypeId'+key) as HTMLInputElement;
        if(eventCurrentTargetId === "insert"){
            var insertData={
                departmentId:this.state.selectfields.departmentId,
                expenseTypeId:elExpenseTypeId.value,
                maxApprovers:selectfields["approvalWeightId"+key],
                tierCode:selectfields["approvalCodeId"+key],
                approvalStatus:"userinsert"
            }
            await approvalProcessController.insertApprovalProcess(insertData).then(response => {
                alert("Approval Process Inserted Successfully");
                //modal['modalSpinnerShow'] = false;
                
                //this.setState(modal);
            }).catch(error => {
                modal['modalSpinnerShow'] = false;
                this.setState(modal);
                console.error("There was an error!", error)
            })
        }
        else if(eventCurrentTargetId === "update"){
            var updatData={
                departmentId:this.state.selectfields.departmentId,
                expenseTypeId:elExpenseTypeId.value,
                maxApprovers:selectfields["approvalWeightId"+key],
                tierCode:selectfields["approvalCodeId"+key],
                approvalStatus:"userupdate"
            }
            await approvalProcessController.updateApprovalProcess(updatData).then(response => {
                //modal['modalSpinnerShow'] = false;
                alert("Approval Process Updated Successfully");
                //this.setState(modal);
            }).catch(error => {
                modal['modalSpinnerShow'] = false;
                this.setState(modal);
                console.error("There was an error!", error)
            })
        }
        else{ //delete
            var flag = await confirm("Are you sure want to Delete?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
            if(flag){
                var deleteData={
                    departmentId:this.state.selectfields.departmentId,
                    expenseTypeId:elExpenseTypeId.value,
                    maxApprovers:selectfields["approvalWeightId"+key],
                    tierCode:selectfields["approvalCodeId"+key],
                    approvalStatus:"userdelete"
                }
                await approvalProcessController.deleteApprovalProcess(deleteData).then(response => {
                    //modal['modalSpinnerShow'] = false;
                    alert("Approval Process Deleted Successfully");
                    //this.setState(modal);
                }).catch(error => {
                    modal['modalSpinnerShow'] = false;
                    this.setState(modal);
                    console.error("There was an error!", error)
                })
            }
        }

        var data = {
            labelId : (this.state.selectfields.formLabel === 'All')?'':this.state.selectfields.formLabel,
            expenseTypeId : (this.state.selectfields.expenseTypeId === 'All')?'':this.state.selectfields.expenseTypeId
        }
        await approvalProcessController.getApprovalProcess(data).then(jsonProcess => {
            this.setState({
                ApprovalProcess: JSON.parse(JSON.stringify(jsonProcess))
            });

            selectfields["approvalWeightFlag"] = false;
            selectfields["approvalCodeFlag"] = false;
            modal['modalSpinnerShow']=false;
            }).catch(error => {
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                console.error("There was an error loading approval process..., " + error)
            });
        
        this.setState({modal, selectfields})
    }
    formLabel(){
        return <Form.Group>
                <Form.Label>Label<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} onChange={this.handleSelectChange}>
                    <option value="All">{"All"}</option>
                    {this.state.HMEntity.map((prop, key) => {
                    if (prop["is_active"] === "Yes") {
                        return (
                        <option value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                    else {
                        return (
                        <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                        )
                    }
                    })}
                </Form.Control>
                {this.state.errors["formLabel"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["formLabel"]}</div> : <></> }
            </Form.Group>
    }
    approvalProcess(){
        var prevDept = '';
        return <Form.Group>
                <Form.Label>Department<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                <Form.Control as="select" className="form-control form-control-sm" id="departmentId" name="departmentId" value={this.state.selectfields.departmentId} onChange={this.handleSelectChange}>
                    <option value="Select a Department">{"Select a Department"}</option>
                    {this.state.ApprovalProcess.map((prop, key) => {
                    if(prevDept !== prop["departmentId"]){
                        prevDept = prop["departmentId"];
                        if (prop["labelId"] !== null) {
                            return (
                            <option value={prop["departmentId"]}>{prop["departmentDesc"]}</option>
                            )
                        }
                        else {
                            return (
                            <option className="text-success" value={prop["departmentId"]}>{prop["departmentDesc"]}</option>
                            )
                        }
                    }
                    })}
                </Form.Control>
                {this.state.errors["deparmentId"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["deparmentId"]}</div> : <></> }
            </Form.Group>
    }
    approval(maxApprovers,key){
        let selectfields = this.state.selectfields;
        if(!selectfields["approvalWeightFlag"]){
            if (maxApprovers === null){
                selectfields["approvalWeightId"+key] = "1";
            }
            else
            {
                selectfields["approvalWeightId"+key] = maxApprovers;
            }
        }
        //this.setState({selectfields});
        return <Form.Group>
                <Form.Control as="select" className="form-control form-control-sm" id={"approvalWeightId"+key} name={"approvalWeightId"+key} value={selectfields["approvalWeightId"+key]} onChange={this.handleSelectChange}>
                    {this.state.ApprovalWeight.map((prop, key) => {
                    if (this.state.fields.labelId !== null) {
                        return (
                        <option value={prop["approverWeight"]}>{prop["description"]}</option>
                        )
                    }
                    else {
                        return (
                        <option className="text-success" value={prop["approverWeight"]}>{prop["description"]}</option>
                        )
                    }
                    })}
                </Form.Control>
            </Form.Group>
    }
    code(tierCode,key){
        let selectfields = this.state.selectfields;
        if(!selectfields["approvalCodeFlag"]){
            if (tierCode === null){
            selectfields["approvalCodeId"+key] = "0";
            }
            else
            {
                selectfields["approvalCodeId"+key] = tierCode;
            }
        }
        //this.setState({selectfields});
        return <Form.Group>
        <Form.Control as="select" className="form-control form-control-sm" id={"approvalCodeId"+key} name={"approvalCodeId"+key} value={selectfields["approvalCodeId"+key]} onChange={this.handleSelectChange}>
            <option value={"0"}>{"Submitter"}</option>
            {this.state.ApprovalCode.map((prop, key) => {
            if (this.state.fields.labelId !== null) {
                return (
                <option value={prop["approverTierId"]}>{prop["description"]}</option>
                )
            }
            else {
                return (
                <option className="text-success" value={prop["approverTierId"]}>{prop["description"]}</option>
                )
            }
            })}
        </Form.Control>
    </Form.Group>
    }
    displayApprovalProcess(){
        var approvalProcess;
        if (this.state.selectfields.formLabel === "All" && this.state.selectfields.expenseTypeId === "All"){
            approvalProcess = this.state.ApprovalProcess.filter(f=>f.departmentId == this.state.selectfields.departmentId);
        }
        else if(this.state.selectfields.formLabel !== "All" && this.state.selectfields.expenseTypeId === "All"){
            approvalProcess = this.state.ApprovalProcess.filter(f=>f.departmentId == this.state.selectfields.departmentId);
        }
        else if(this.state.selectfields.formLabel === "All" && this.state.selectfields.expenseTypeId !== "All"){
            approvalProcess = this.state.ApprovalProcess.filter(f=>f.expenseTypeId == this.state.selectfields.expenseTypeId && f.departmentId == this.state.selectfields.departmentId);
        }
        else
        {
            approvalProcess = this.state.ApprovalProcess.filter(f=>f.expenseTypeId == this.state.selectfields.expenseTypeId && f.departmentId == this.state.selectfields.departmentId);
        }
        return approvalProcess.map((prop, key) => {
            return(
                <tr key={key}>
                    {(prop["labelId"] === null)?<td className="text-success">{prop["departmentDesc"]}<input id={"hidDepartmentId"+key} name={"hidDepartmentId"+key} type="hidden" value={prop["departmentId"]} /></td>:<td>{prop["departmentDesc"]}<input id={"hidDepartmentId"+key} name={"hidDepartmentId"+key} type="hidden" value={prop["departmentId"]} /></td>}
                    {(prop["labelId"] === null)?<td className="text-success">{prop["expenseTypeDesc"]}<input id={"hidExpenseTypeId"+key} name={"hidExpenseTypeId"+key} type="hidden" value={prop["expenseTypeId"]} /></td>:<td>{prop["expenseTypeDesc"]}<input id={"hidExpenseTypeId"+key} name={"hidExpenseTypeId"+key} type="hidden" value={prop["expenseTypeId"]} /></td>}
                    {(prop["labelId"] === null)?<td className="text-success">{this.approval(prop["maxApprovers"],key)}</td>:<td>{this.approval(prop["maxApprovers"],key)}</td>}
                    {(prop["labelId"] === null)?<td className="text-success">{this.code(prop["tierCode"],key)}</td>:<td>{this.code(prop["tierCode"],key)}</td>}
                    {(prop["labelId"] === null)?<td><Button type="button" id="insert" name="insert" variant="success" className="btn btn-success btn-sm" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.onSubmit(e,key)}>Insert</Button></td>:<td><Button type="button" id="update" name="update" variant="secondary" className="btn btn-secondary btn-sm" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.onSubmit(e,key)}>Update</Button><Button type="button" id="delete" name="delete" variant="danger" className="btn btn-danger btn-sm" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.onSubmit(e,key)}>Delete</Button></td>}
                </tr>
            )
        })
    }
    render() {
        return (
          <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                        <div className="header"><h4 className="title">Sysop - Approval Process Maintenance</h4><p className="category"></p></div>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row style={{textAlign:"right"}}>
                                    <Col sm={12}>
                                        <Button disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                        {this.state.loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color: "#3472F7" }}
                                        />
                                        )}
                                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                        {!this.state.loading && <span>Print </span>}
                                        </Button>
                                    </Col>
                                </Row>
                                <div style={{width: "100%"}}>
                                        <Row>
                                            <Col md={12}>
                                            <Row style={{marginTop:"-10px"}}>
                                                <Col sm={3}></Col>               
                                                <Col sm={3}>
                                                    {this.formLabel()}
                                                </Col>
                                                <Col sm={6}></Col>                                                                                       
                                            </Row>
                                            <Row style={{marginTop:"-10px"}}> 
                                                <Col sm={3}></Col>
                                                <Col sm={3}>
                                                    <FormSelect
                                                            properties={{ label: "Expense Type", id: "expenseTypeId", name: "expenseTypeId", value: this.state.selectfields.expenseTypeId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                                            fieldRequired={true}
                                                            defaultOption="All"
                                                            fillOption={this.state.expenseTypeGroups}
                                                            errorMsg={this.state.errors.expenseTypeId}
                                                        />
                                                </Col>
                                                <Col sm={6}></Col>
                                            </Row>
                                            <Row style={{marginTop:"-10px"}}>
                                                <Col sm={3}></Col>               
                                                <Col sm={3}>
                                                    {this.approvalProcess()}
                                                </Col>
                                                <Col sm={6}></Col>                                                                                       
                                            </Row>
                                            {(this.state.selectfields.departmentId === "Select a Department")?
                                            <Row>
                                            <Col sm={12}>
                                            <div className="scroll" style={{width: '100%'}}>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:"30%"}}>Department</th>
                                                            <th style={{width:"20%"}}>AuthReq Type</th>
                                                            <th style={{width:"15%"}}>Approval</th>
                                                            <th style={{width:"15%"}}>Code</th>
                                                            <th style={{width:"20%"}}>Process</th>
                                                        </tr>
                                                    </thead>
                                                </Table>
                                            </div>
                                            </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col sm={12}>
                                                <div style={{width: '100%'}}>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{width:"30%"}}>Department</th>
                                                                <th style={{width:"20%"}}>AuthReq Type</th>
                                                                <th style={{width:"15%"}}>Approval</th>
                                                                <th style={{width:"15%"}}>Code</th>
                                                                <th style={{width:"20%"}}>Process</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.displayApprovalProcess()}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                </Col>
                                            </Row>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }

}