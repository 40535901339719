import React, { Component, CSSProperties } from "react";
import { ICarServiveConfirmationParameter, Istate } from "../../../Model/ICarServiceConfirmation";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CarServiceIncomingCAController } from "../../../Controllers/CarServiceIncomingCAController";
import { CommonController } from "../../../Controllers/CommonController";
import { EmailController } from "../../../Controllers/EmailController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import * as Utils from '../../../js/Utils.js';
import addSubtractDate from 'add-subtract-date'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { AuthStatus } from "../Common/AuthStatus";

export class CarServiceConfirmation extends Component<ICarServiveConfirmationParameter,Istate> {
    constructor(props:ICarServiveConfirmationParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {sessionTA: sessionStorage.getItem('ta'),confirmation:''},
            fieldsTA:{authreqId:'',soundBiz2:'',inPolicy:'',traveler_Count:'',isDh:false,hasAir:false , airMissingTraveler:'',airTravelerCount:'',airTotal:'',airCoding:'',
                        hasHotel:false,hotelMissingTraveler:'',hotelTravelerCount:'',hotelTotal:'',hotelCoding:'',nonDisputableHotel:'',hasRentalCar:false,rentalCarMissingTraveler:'',
                        rentalCarTravelerCount:'',rentalCarTotal:'',rentalCarCoding:'',hasCarService:false,carServiceMmissingTraveler:'',carServiceTravelerCount:'',
                        carServiceTotal:'',carServiceCoding:'',carService2:'',isCorporateCard:false,labelId:'',taStatusId:'',weight:'',createdDate:'',artistName:'',businessUnit:'', 
                        travelAgentName:'',expenseTypeId:'',artistProjectName:'',artistProjectNumber:'',name:'',email:'',businessPhone:'',opCode:'',labelDesc:'',statusDesc:'', 
                        expTypeDesc:'',departmentId:'',departmentDesc:'',carServiceOnly:'',carServiceCompanyId:'',carServiceCompany:'',maxApprovers:'',tierCode:'', 
                        updatedDate:'',updatedBy:'',lastCmtToRequestor:'',commentsToApprover:'' },
            fieldsAplHis:{name:'',updatedDate:''},
            errors:{},
            errMsg:"",
            loading:false, 
            carServiveDetailGrp:[], 
            carServiveCodingGrp:[],  
            authReqId:'',  
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),                     
            loadComplete:false,                      
            userTypeId:sessionStorage.getItem("user_type_id"),      
        } 
        this.onSubmit = this.onSubmit.bind(this);        
    }

    componentWillMount(){
        this.setState({userId:sessionStorage.getItem("uid")})
         this.getAllDetails();
    }

    getAllDetails =() =>{
        
        let modal = this.state.modal;    
        modal['modalSpinnerShow']=true;  
        let fieldsTA = this.state.fieldsTA;    
        let fieldsAplHis = this.state.fieldsAplHis;  
        let fields = this.state.fields;
        let carServiceIncomingCA= new CarServiceIncomingCAController();
        var data={
            authReqId: this.state.fields.sessionTA
        }
        //console.log(data);
        carServiceIncomingCA.getTA(data).then(response => {            
            fieldsTA["name"] = response[0].name || "";
            fieldsTA["businessPhone"] = response[0].business_phone || ""; 
            fieldsTA["expTypeDesc"] = response[0].expense_type_desc || "" ;
            fieldsTA["updatedDate"] = response[0].updated_date || "" ;
            fieldsTA["updatedBy"] = response[0].updated_by || "" ;
            fieldsTA["carService2"] = response[0].car_service2 || "" ;
            fieldsTA["taStatusId"] = response[0].status_id || "" ;  
            fieldsTA["lastCmtToRequestor"] = response[0].comments_to_requestor || "" ; 
            fieldsTA["isCorporateCard"] = Boolean(Number(response[0].is_corporate_card)); 
            fieldsTA["labelDesc"] = response[0].label_desc || "" ; 
            fieldsTA["opCode"] = response[0].op_code || "" ;             
            fieldsTA["soundBiz2"] = response[0].sound_biz_2 || "" ; 
            fieldsTA["inPolicy"] = response[0].in_policy || "" ; 
            fieldsTA["traveler_Count"] = response[0].traveler_count || "" ; 
            fieldsTA["isDh"] = Boolean(Number(response[0].is_dh)); 
            fieldsTA["hasAir"] = Boolean(Number(response[0].has_air)); 
            fieldsTA["airMissingTraveler"] = response[0].air_missing_traveler || "" ; 
            fieldsTA["airTravelerCount"] = response[0].air_traveler_count || "" ; 
            fieldsTA["airTotal"] = response[0].air_total || "" ; 
            fieldsTA["airCoding"] = response[0].air_coding || "" ; 
            fieldsTA["hasHotel"] = Boolean(Number(response[0].has_hotel)); 
            fieldsTA["hotelMissingTraveler"] = response[0].hotel_missing_traveler || "" ; 
            fieldsTA["hotelTravelerCount"] = response[0].hotel_traveler_count || "" ; 
            fieldsTA["hotelTotal"] = response[0].hotel_total || "" ; 
            fieldsTA["hotelCoding"] = response[0].hotel_coding || "" ; 
            fieldsTA["nonDisputableHotel"] = response[0].non_disputable_hotel || "" ; 
            fieldsTA["hasRentalCar"] = Boolean(Number(response[0].has_rental_car)); 
            fieldsTA["rentalCarMissingTraveler"] = response[0].rental_car_missing_traveler || "" ; 
            fieldsTA["rentalCarTravelerCount"] = response[0].rental_car_traveler_count || "" ; 
            fieldsTA["rentalCarTotal"] = response[0].rental_car_total || "" ; 
            fieldsTA["rentalCarCoding"] = response[0].rental_car_coding || "" ; 
            fieldsTA["hasCarService"] = Boolean(Number(response[0].has_car_service)); 
            fieldsTA["carServiceMmissingTraveler"] = response[0].car_service_missing_traveler || "" ; 
            fieldsTA["carServiceTravelerCount"] = response[0].car_service_traveler_count || "" ; 
            fieldsTA["carServiceTotal"] = response[0].car_service_total || "" ; 
            fieldsTA["carServiceCoding"] = response[0].car_service_coding || "" ;           
            fieldsTA["labelId"] = response[0].label_id || "" ; 
            fieldsTA["weight"] = response[0].weight || "" ; 
            fieldsTA["createdDate"] = response[0].created_date || "" ; 
            fieldsTA["artistName"] = response[0].artist_name || "" ; 
            fieldsTA["businessUnit"] = response[0].business_unit || "" ;  
            fieldsTA["travelAgentName"] = response[0].travel_agent_name || "" ; 
            fieldsTA["expenseTypeId"] = response[0].expense_type_id || "" ; 
            fieldsTA["artistProjectName"] = response[0].artist_project_name || "" ; 
            fieldsTA["artistProjectNumber"] = response[0].artist_project_number || "" ; 
            fieldsTA["email"] = response[0].email || "" ; 
            fieldsTA["statusDesc"] = response[0].status_desc || "" ; 
            fieldsTA["departmentId"] = response[0].department_id || "" ; 
            fieldsTA["departmentDesc"] = response[0].department_desc || "" ; 
            fieldsTA["carServiceOnly"] = response[0].car_service_only || "" ; 
            fieldsTA["carServiceCompanyId"] = response[0].car_service_company_id || "" ; 
            fieldsTA["carServiceCompany"] = response[0].car_service_company || "" ; 
            fieldsTA["maxApprovers"] = response[0].max_approvers || "" ; 
            fieldsTA["tierCode"] = response[0].tier_code || "" ;  
            fieldsTA["commentsToApprover"] = response[0].comments_to_approver || "" ;  
            this.setState({ fieldsTA}); 

            var data1={
                userType : sessionStorage.getItem("user_type_id"),
                authReqId : this.state.fields.sessionTA,
                taCarService2: this.state.fieldsTA.carService2
            }
            carServiceIncomingCA.getIncomingTADetails(data1).then(jsoncarServiceTADetlist => {
                this.setState({ carServiveDetailGrp: JSON.parse(JSON.stringify(jsoncarServiceTADetlist))}); 
                     modal['modalSpinnerShow']=false;
                    this.setState({ fields}); 
                     //console.log(this.state.carServiveDetailGrp);
                }).catch(error => {
                console.error("There was an error!", error);
                modal['modalSpinnerShow']=false; 
            })
            
            var data2={
                authReqId : this.state.fields.sessionTA,
                taCarService2: this.state.fieldsTA.carService2
            }

            carServiceIncomingCA.getCarserviceCoding(data2).then(jsoncarCodinglist => {
                this.setState({ carServiveCodingGrp: JSON.parse(JSON.stringify(jsoncarCodinglist))});
            }).catch(error => {
                console.error("There was an error!", error);
                modal['modalSpinnerShow']=false; 
            })

        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        }) 

        carServiceIncomingCA.getApprovalHistory(data).then(response => {
            fieldsAplHis["name"] = response[0].Name || "";      
            fieldsAplHis["updatedDate"] = response[0].Updated_Date || "";
            this.setState({fieldsAplHis}); 
             
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        }) 
        
        this.setState({modal});       
    }

    handleChange(i, event){
        let fields = this.state.fields;        
         let carServiveDetailGrp = [...this.state.carServiveDetailGrp]; 
        carServiveDetailGrp[i]["confirmation"] = event.target.value;
        if(fields["confirmation"] != "")
         {
             if(!fields["confirmation"].includes(carServiveDetailGrp[i]["carServiceId"]+"|"+carServiveDetailGrp[i]["travellerId"]))
             fields["confirmation"] = fields["confirmation"] +carServiveDetailGrp[i]["carServiceId"]+"|"+carServiveDetailGrp[i]["travellerId"]+","
         }
         else
         {
            fields["confirmation"] = carServiveDetailGrp[i]["carServiceId"]+"|"+carServiveDetailGrp[i]["travellerId"]+"," 
         }
        this.setState({ carServiveDetailGrp,fields });
    }

    onSubmit(e:any){
        e.preventDefault();
        let modal = this.state.modal;
        let fields = this.state.fields;
        modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})        
        let carServiceIncomingCA= new CarServiceIncomingCAController();
        if (this.validateConfirmation())
        {
            fields["confirmation"].split(',').map(str => {
                {this.state.carServiveDetailGrp.filter(p => p.carServiceId == str.split('|')[0] && p.travellerId == str.split('|')[1] && p.confirmation != "").map(f => {
                    var data = {
                        carServiceId : f["carServiceId"],
                        travellerId : f["travellerId"],
                        confirmationNumber : f["confirmation"]
                    }        
                               
                    //console.log(data)

                    carServiceIncomingCA.updateConfirmation(data).then(response => {                   
                    }).catch(error => {
                        console.error("There was an error!", error)
                    })
                })}               
            }); 

            this.taHistory()         
        }      
        
    }

    validateConfirmation()
    {
        let retVal=true;
        let modal = this.state.modal;
        let carServiceId ="";
        let travellerId ="";
        {this.state.carServiveDetailGrp.map(f => {
            if(carServiceId != f["carServiceId"] || travellerId!= f["travellerId"])
            {
                carServiceId = f["carServiceId"];
                travellerId  = f["travellerId"];
                if(f["confirmation"] === "" || f["confirmation"] === null)
                {
                    retVal =false;
                }
            }
        })}
        if(!retVal)
        {
            alert("Please enter all confirmation #fields");     
            modal['modalSpinnerShow']=false;
            this.setState({modal})    
        }

        return retVal;
    }

    taHistory(){
       var statusId = "9"
        let commonController = new CommonController();
        var taHistoryData = {
            authReqId: this.state.fields.sessionTA,
            userId: sessionStorage.getItem("uid"),
            statusId: statusId,
            taIsDH: this.state.fieldsTA.isDh,
            taWeight: this.state.fieldsTA.weight,
            isDHA: sessionStorage.getItem("is_dha"),
            approverTierId: sessionStorage.getItem("approver_tier_id"),
            commentsToRequestor: "",
            commentsToApprover: "",
        }
        //console.log("update TA History data",taHistoryData)
        commonController.UpdateTAHistory(taHistoryData).then(response => {
            //console.log("update TA History", response);
            this.taMove(statusId);
        })
    }

    taMove(statusId){
        let commonController = new CommonController()
        var taMoveData={
            userName: sessionStorage.getItem("uid"),
            weight: "",
            statusId: statusId,
            authreqId: this.state.fields.sessionTA
        }
        //console.log("taMove", taMoveData);
        commonController.UpdateTaMove(taMoveData).then(response => {
            //console.log("UpdateTaMove", response)
            this.emailSubmitter();            
        })
    }

    emailSubmitter(){
        let fields = this.state.fields;       
        let modal = this.state.modal;
        var to = this.state.fieldsTA.email!;
        var subject = "AuthReq #" + this.state.fields.sessionTA + " has been"; 
        
        subject = subject + " completed by " + this.state.fieldsTA.carServiceCompany
        
        let commonController = new CommonController();
        var travellerData={
            id:'',
            view:'assigned',
            bInner:'false',
            sessionTA:this.state.fields.sessionTA
        }
        commonController.getTATraveler(travellerData).then(jsonTravellers => {
            var traveller = '';
            jsonTravellers.map(prop => {
                if(traveller === ''){
                    traveller = prop["name"];
                }
                else{
                    traveller = traveller + ' ,' + prop["name"];
                }
            })
            subject = subject + " for travelers: " + traveller
            var body = subject + ".<br>"            
            body = body + "<br> <br> Logon to eTA at " + Utils.getSiteCollectionUrl() + " for more information. <br> <br>Thank you for using eTA!" 
            let emailController = new EmailController();
            modal['modalSpinnerShow']=false;
            this.setState({modal});
            emailController.sendEmail('',to,subject,body);
            alert("AuthReq #" + this.state.fields.sessionTA + " has been confirmed");
            this.props.history.push({pathname:'/admin/incomingca'}); 
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });        

    }

    convertDateTime = (data:any)=>{
      var dateString = data;
      var date = new Date(dateString);
     
      var curr_date = date.getDate();
      var curr_month = date.getMonth() + 1; //Months are zero based
      var curr_year = date.getFullYear();
      var curr_hour = date.getHours();
      var curr_min = date.getMinutes();
      var curr_sec = date.getSeconds();
      var reqDate = curr_month + "/" + curr_date + "/" + curr_year + " " + curr_hour + ":" + curr_min + ":" + curr_sec;
      //var reqTime = new Date(data).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
      return reqDate ;
    }

    convertDate =(dt:any)=>{
      var dateString = dt;
      var date = new Date(dateString);
     
      var curr_date = date.getDate();
      var curr_month = date.getMonth() + 1; //Months are zero based
      var curr_year = date.getFullYear();
      var reqDate = curr_month + "/" + curr_date + "/" + curr_year;
      return reqDate;
    }

    getLastupdated =()=>{
        
        if(this.state.fieldsTA.taStatusId != "7")
        {
            return<div>
                <Row>
                    <Col sm={4} style={{fontSize:"12px",marginTop:"2px"}}></Col>
                    <Col sm={4} style={{fontSize:"12px",marginTop:"2px",marginBottom:"3%"}}>{this.state.fieldsTA.isCorporateCard ? "EMPLOYEE CORPORATE CARD" : ""}</Col>
                </Row>
                <Row>
                    <Col sm={2} style={{fontSize:"12px",marginTop:"2px"}}>Last Update:</Col>
                    <Col sm={4} style={{fontSize:"12px",marginTop:"2px"}}>{this.state.fieldsTA.updatedBy}&nbsp;{Utils.FormatDateTime(this.state.fieldsTA.updatedDate)}</Col>
                </Row>
                <Row>
                    <Col sm={2} style={{fontSize:"12px",marginTop:"2px"}}>Authorized:</Col>
                    <Col sm={4} style={{fontSize:"12px",marginTop:"2px"}}>{this.state.fieldsAplHis.name}&nbsp;{Utils.FormatDateTime(this.state.fieldsAplHis.updatedDate)}</Col>
                </Row>
            </div>
        }
        else
        {
            return<div>
                <Row>
                    <Col sm={2} style={{fontSize:"12px",marginTop:"2px"}}>Terminated by:</Col>
                    <Col sm={4} style={{fontSize:"12px",marginTop:"2px"}}>{this.state.fieldsTA.updatedBy}&nbsp;{Utils.FormatDateTime(this.state.fieldsTA.updatedDate)}</Col>
                </Row>
                <Row>
                    <Col sm={6} style={{fontSize:"12px",marginTop:"2px"}}>per submitter request with the following comments:</Col>
                    <Col sm={6} style={{fontSize:"12px",marginTop:"2px"}}>{this.state.fieldsTA.lastCmtToRequestor}</Col>
                </Row>
            </div>
        }        
    }

    formatCurrency = (amount) => {
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Confirmation':
                this.props.history.push({pathname:'/admin/carserviceconfirmation',state:{from:this.props.location.state.from}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/carservicedecision',state:{from:this.props.location.state.from}});
                break;
        }
    }

    /*bindData(data : any){
        let carSerId =""
        var i=0;       
        return<div>
                <Row>
                    <Col style={{fontSize:"75%"}}>
                        <Row><Col style={{height:"5%",backgroundColor:"#869beb",marginTop:"2%",padding:"5px"}}>Car Service</Col></Row>
                        <Row><Col sm={2} style={{marginTop:"2px"}}>Traveler:</Col><Col sm={6} style={{color:"red",marginTop:"2px"}}>{data["traveler"]}</Col></Row>
                        <Row><Col sm={2} style={{marginTop:"2px"}}>General Comments:</Col><Col style={{marginTop:"2px"}} sm={6}>{data["splInstructions"]}</Col></Row>
                        <Row><Col sm={2} style={{marginTop:"2px"}}>Account Number:</Col><Col style={{marginTop:"2px"}} sm={6}>{data["isCorporateCard"] ? data["accCCNumber"] : data["accNumber"]}</Col></Row>
                    </Col>
                </Row>
            </div>        
    }*/

    displayConfirmation=()=>{
       const{ loading } = this.state;
        let   carSerId = "";
        let   travellerId = "";        
        return<div>  
            {this.getLastupdated()}
            <Row style={{padding:"5px"}}><Col style={{height:"5%",backgroundColor:"navy",padding:"5px",color:"white",fontSize:"12px"}}>Car Service</Col></Row>
            {this.state.carServiveDetailGrp.map((prop, key) => {              
                if(prop["carServiceId"] != carSerId)
                {
                    carSerId = prop["carServiceId"];
                    travellerId = prop["travellerId"];
                    let i=0;
                   return(<div key={key}>
                            <Row>
                                <Col style={{fontSize:"75%"}}>                                    
                                    <Row><Col sm={2} style={{marginTop:"1%"}}>Traveler:</Col><Col sm={6} style={{color:"red",marginTop:"1%"}}>{prop["traveller"]}</Col></Row>
                                    <Row><Col sm={2} style={{marginTop:"1%"}}>General Comments:</Col><Col style={{marginTop:"1%"}} sm={6}>{prop["splInstructions"]}</Col></Row>
                                    <Row><Col sm={2} style={{marginTop:"1%"}}>Account Number:</Col><Col style={{marginTop:"1%"}} sm={6}>{this.state.fieldsTA.isCorporateCard ? prop["accCCNumber"] : prop["accNumber"]}</Col></Row>
                                </Col>
                            </Row>


                            <Row style={{padding:"5px"}}>
                                <Col sm={12} style={{fontSize:"12px",marginTop:"1%"}}>
                                    <Row>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Service Company</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Service Type</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Car Type</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Pickup Date</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Pickup Time</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["companyName"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["serviceType"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["carType"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{this.convertDate(prop["pickUpDate"])}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["pickUpTime"]}&nbsp;{prop["pickUpAmPm"]}</Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                            {this.state.carServiveDetailGrp.filter(p => p.carServiceId == prop["carServiceId"] &&  p.travellerId === prop["travellerId"]).map(ca  => {
                            i= i+1;
                            return (<Row key={key} >
                                        <Col style={{fontSize:"12px",marginTop:"1%"}}>
                                            <Row style={{padding:"5px"}}><Col style={{backgroundColor:"cyan",padding:"5px"}}>Location {i} :</Col></Row>
                                            <Row><Col sm={2}>Pick Up:</Col><Col sm={6}>{ca.pickUpLocation1}<br />{ca.pickUpLocation2}</Col></Row>
                                            <Row><Col sm={2}>Drop Off:</Col><Col sm={6}>{ca.dropOffLocation1}<br />{ca.dropOffLocation2}</Col></Row>                                                  
                                        </Col>
                                    </Row>)                                                   
                                })}

                            <Row>
                                <Col sm={12} style={{fontSize:"12px",marginTop:"1%"}}>
                                    <Row style={{padding:"5px"}}>
                                        {/*<Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Phone Usage</Col>*/}
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}></Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Rate</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Estimated Total</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Passengers</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Confirmation # {prop.bcdGenerated ? "BCD" : "Manual"} <span className="text-danger" style={{fontSize:'16px'}}>*</span></Col>
                                    </Row>
                                    <Row style={{borderBottom:"1px solid black",paddingBottom:"20px"}}>
                                        {/*<Col style={{width:"20%",textAlign:"center"}}>{prop["phoneUsage"]}</Col>*/}
                                        <Col style={{width:"20%",textAlign:"center"}}></Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{this.formatCurrency(prop["rate"])}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{this.formatCurrency(prop["total"])}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["passengerCount"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>
                                        {this.props.location.state.isView ? prop["confirmation"] :
                                            <FormControl style={{border: "1px solid #bebebe"}} type="text" name="confirmation" id="confirmation" value={prop["confirmation"]}  maxLength={20} onChange={this.handleChange.bind(this, key)} />
                                        }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>)
                }
                if(prop["travellerId"] != travellerId && prop["carServiceId"] === carSerId)
                {
                    carSerId = prop["carServiceId"];
                    travellerId = prop["travellerId"];
                    let i=0;
                   return(<div key={key}>
                            <Row>
                                <Col style={{fontSize:"75%"}}>                                    
                                    <Row><Col sm={2} style={{marginTop:"1%"}}>Traveler:</Col><Col sm={6} style={{color:"red",marginTop:"1%"}}>{prop["traveller"]}</Col></Row>
                                    <Row><Col sm={2} style={{marginTop:"1%"}}>General Comments:</Col><Col style={{marginTop:"1%"}} sm={6}>{prop["splInstructions"]}</Col></Row>
                                    <Row><Col sm={2} style={{marginTop:"1%"}}>Account Number:</Col><Col style={{marginTop:"1%"}} sm={6}>{this.state.fieldsTA.isCorporateCard ? prop["accCCNumber"] : prop["accNumber"]}</Col></Row>
                                </Col>
                            </Row>


                            <Row style={{padding:"5px"}}>
                                <Col sm={12} style={{fontSize:"12px",marginTop:"1%"}}>
                                    <Row>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Service Company</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Service Type</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Car Type</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Pickup Date</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>Pickup Time</Col>
                                    </Row>
                                    <Row>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["companyName"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["serviceType"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["carType"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{this.convertDate(prop["pickUpDate"])}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["pickUpTime"]}&nbsp;{prop["pickUpAmPm"]}</Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                            {this.state.carServiveDetailGrp.filter(p => p.carServiceId == prop["carServiceId"] &&  p.travellerId === prop["travellerId"]).map(ca  => {
                            i= i+1;
                            return (<Row key={key} >
                                        <Col style={{fontSize:"12px",marginTop:"1%"}}>
                                            <Row style={{padding:"5px"}}><Col style={{backgroundColor:"cyan",padding:"5px"}}>Location {i} :</Col></Row>
                                            <Row><Col sm={2}>Pick Up:</Col><Col sm={6}>{ca.pickUpLocation1}<br />{ca.pickUpLocation2}</Col></Row>
                                            <Row><Col sm={2}>Drop Off:</Col><Col sm={6}>{ca.dropOffLocation1}<br />{ca.dropOffLocation2}</Col></Row>                                                  
                                        </Col>
                                    </Row>)                                                   
                                })}

                            <Row>
                                <Col sm={12} style={{fontSize:"12px",marginTop:"1%"}}>
                                    <Row style={{padding:"5px"}}>
                                        {/*<Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Phone Usage</Col>*/}
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}></Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Rate</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Estimated Total</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Passengers</Col>
                                        <Col style={{width:"20%",textAlign:"center",backgroundColor:"#ffff80"}}>Confirmation # {prop.bcdGenerated ? "BCD" : "Manual"} <span className="text-danger" style={{fontSize:'16px'}}>*</span></Col>
                                    </Row>
                                    <Row style={{borderBottom:"1px solid black",paddingBottom:"20px"}}>
                                        {/*<Col style={{width:"20%",textAlign:"center"}}>{prop["phoneUsage"]}</Col>*/}
                                        <Col style={{width:"20%",textAlign:"center"}}></Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{this.formatCurrency(prop["rate"])}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{this.formatCurrency(prop["total"])}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>{prop["passengerCount"]}</Col>
                                        <Col style={{width:"20%",textAlign:"center"}}>
                                        {this.props.location.state.isView ? prop["confirmation"] :
                                            <FormControl style={{border: "1px solid #bebebe"}} type="text" name="confirmation" id="confirmation" value={prop["confirmation"]}  maxLength={20} onChange={this.handleChange.bind(this, key)} />
                                        }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>)
                }                
                
            })}
            <Row style={{marginTop:"10px"}}>                                        
                <Col sm={12}>
                {this.props.location.state.isView ? "" :
                    <div style={{textAlign:"center",marginTop:"5%"}}>
                        <Button id="submitConfirmation" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)} variant="success">
                            {loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , color:"#3472F7" }}
                            />
                            )}
                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                            {!loading && <span>Apply Confirmation</span>}
                        </Button>
                    </div>
                }
                    {this.state.fieldsTA.isCorporateCard ? "" :
                        <Row>
                            <Col>
                                <Row style={{padding:"5px"}}>
                                    <Col sm={12} style={{fontSize:"12px",marginTop:"1%",backgroundColor:"red"}}>
                                        <Row><Col sm={2}>Coding</Col></Row>
                                        <Row><Col sm={2}>HFM Entity:&nbsp;</Col><Col sm={6}>{this.state.fieldsTA.labelDesc}</Col></Row>
                                        <Row><Col sm={2}>HFM Code:&nbsp;</Col><Col sm={6}>{this.state.fieldsTA.opCode}</Col></Row>
                                    </Col>
                                </Row>
                                <Row style={{padding:"5px"}}>
                                    <Col>
                                        <Row style={{fontSize:"12px",textAlign:"center",backgroundColor:"silver",padding:"5px"}}>
                                            <Col style={{width:"15%"}}>Profit Center</Col>
                                            <Col style={{width:"15%"}}>Cost Center</Col>
                                            <Col style={{width:"15%"}}>GL Account #</Col>
                                            <Col style={{width:"15%"}}>WBS</Col>
                                            <Col style={{width:"20%"}}>Emp/Project #</Col>
                                            <Col style={{width:"15%"}}>PO #</Col>
                                            <Col style={{width:"10%"}}>PO Line</Col>
                                            <Col style={{width:"15%"}}>Amount</Col>
                                        </Row> 
                                        {this.state.carServiveCodingGrp.map((prop, key) => {
                                        return (<Row key={key}>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["codingID"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["costCenter"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["objectAccount"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["subAccount"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["projectEmpNumber"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["ctsNumber"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{prop["poLine"]}</Col>
                                                    <Col style={{fontSize:"12px",textAlign:"center"}}>{this.formatCurrency(prop["amount"])}</Col>
                                                </Row>)                                                   
                                            })} 
                                    </Col>
                                </Row>                                                                                      
                            </Col>
                        </Row>
                        }
                </Col>
            </Row>
        </div>
    }
    
    render() {               
        const tdStyle = {
            width:'15%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };  
         const divStyle1 = {
          width: '100%',                   
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };
        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Car Service Confirmation</h4></div>
                            <Card.Body>
                                <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <AuthStatus/>
                                <section style={{marginTop:"10px"}}>
                                {this.props.location.state.isView ?
                                    <Tabs selected={0} mountOnExit>                                            
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation">{this.displayConfirmation()}</Tab>                                        
                                    </Tabs>
                                    :
                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={0} mountOnExit>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation">{this.displayConfirmation()}</Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>
                                    </Tabs>
                                }
                                </section>
                                <Row className="mt-3">
                                    <Col sm={12} style={{ paddingBottom: "15px"}}>
                                        
                                    </Col>
                                </Row>
                                </Form>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}