import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class HotelController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getBillingType(isCorporateCard:string){
        var url = this.variables.Url + this.variables.Controller.hotel + this.variables.hotelService.getBillingType;
        var data = {
            isCorporateCard : isCorporateCard
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonBillingType : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(billingType);
                function billingType(item: { Hotel_Bill_ID: string; Description: string}){
                    jsonBillingType.push({"id": item.Hotel_Bill_ID, "name": item.Description})
                }
                return JSON.parse(JSON.stringify(jsonBillingType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonBillingType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    
    getHotelBookingHotelId(hotelId:string,sessionTA:string){
        var url = this.variables.Url + this.variables.Controller.hotel + this.variables.hotelService.getHotelId;
        var data = {
            hotelId: hotelId,
            sessionTA: sessionTA
        }
        return this.serviceProxy.get(url,data).then(response => {
            var jsonHotelId : Array<{"hotelId": string}> = [];
            if(response.success){
                response.recordset.forEach(hotelId);
                function hotelId(item: { hotel_id: string}){
                    jsonHotelId.push({"hotelId": item.hotel_id})
                }
                return JSON.parse(JSON.stringify(jsonHotelId).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonHotelId).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getBooking(authreqId, hotelId){
        var url = this.variables.Url + this.variables.Controller.hotel + this.variables.hotelService.getBooking;
        var data = {
            authreqId: authreqId,
            hotelId: hotelId
        }
        return this.serviceProxy.get(url,data).then((response : any) => {
            var jsonHotelBooking : Array<{"hotelId": number; "hotelBillId": string; "hotelBillDesc": string; "hotel": string; "city": string; "rate": number; "tax": number; "incidentals": number; "total": number; "inDate": string; "outDate": string; "nonDisputable": boolean, "scAmount": number; "scDescription": string; "bcdGenerated": boolean; "bcdValidated": boolean; "RLN": string}> = [];
            if(response.success){
                response.recordset.forEach(hotelBooking);
                function hotelBooking(item: { Hotel_ID: number, Hotel_Bill_ID: string, Hotel_Bill_Desc: string, Hotel: string, City: string, Rate: number, Tax: number, Incidentals: number, Total: number, in_date: string, out_date: string, non_disputable: boolean, SC_Amount: number, SC_Description: string, BCD_Generated: boolean, BCD_Validated: boolean, RLN: string }){
                    jsonHotelBooking.push({"hotelId": item.Hotel_ID, "hotelBillId": item.Hotel_Bill_ID, "hotelBillDesc": item.Hotel_Bill_Desc, "hotel": item.Hotel, "city": item.City, "rate": item.Rate, "tax": item.Tax, "incidentals": item.Incidentals, "total": item.Total, "inDate": item.in_date, "outDate": item.out_date, "nonDisputable": item.non_disputable, "scAmount": item.SC_Amount, "scDescription": item.SC_Description, "bcdGenerated": item.BCD_Generated, "bcdValidated": item.BCD_Validated, "RLN": item.RLN})
                }
                return JSON.parse(JSON.stringify(jsonHotelBooking).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonHotelBooking).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    userProcessInsert (data){
        var url = this.variables.Url+ this.variables.Controller.hotel + this.variables.hotelService.userProcessInsert;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessCopy (data){
        var url = this.variables.Url+ this.variables.Controller.hotel + this.variables.hotelService.userProcessCopy;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessUpdate (data){
        var url = this.variables.Url+ this.variables.Controller.hotel + this.variables.hotelService.userProcessUpdate;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    bookingMap (data){
        var url = this.variables.Url+ this.variables.Controller.hotel + this.variables.hotelService.bookingMap;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateSpecialIns (data){
        var url = this.variables.Url+ this.variables.Controller.hotel + this.variables.hotelService.updateSpecialIns;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateBCDToManual(hotelId,authreqId){
        var url = this.variables.Url+ this.variables.Controller.hotel + this.variables.hotelService.updateBCDToManual;
        var data = {
            authreqId: authreqId,
            hotelId: hotelId
        }
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}