import React, { Component } from "react";
import { IReportParameter, Istate } from "../../../Model/IReport";
import {Container,Row,Col,Form,Alert,Button,Card, NavItem} from "react-bootstrap";
import { FormSelect } from "../../CustomComponents/FormSelect";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { ReportController } from "../../../Controllers/ReportController";
import '../../../assets/css/Style.css'
import "react-datepicker/dist/react-datepicker.css";
import { Variables } from "../../../Variables/Variables";
import { resolve } from "dns";

export class QuaterlyContentWinnerReports extends Component<IReportParameter,Istate> {
    constructor(props:IReportParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
       
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={ 
            percentagesBeforeNoon: 0,
            percentagesNoon: 0,
            percentagesOneOClock: 0,
            percentagesTwoOClock: 0,
            percentagesThreeOClock: 0,
            percentagesFourOClock: 0,
            percentagesFiveOClock: 0,
            percentagesSixOClock: 0,
            percentagesSevenOClock: 0, 
            sumBeforeNoon: 0,
            sumNoon: 0,
            sumOneOClock: 0,
            sumTwoOClock: 0,
            sumThreeOClock: 0,
            sumFourOClock: 0,
            sumFiveOClock: 0,
            sumSixOClock: 0,
            sumSevenOClock: 0,
            sumofTotal:0,
            fields: {isLabelAllChked:false},
            selectfields:{selectedFileFormat:'',isActiveLabel:'',selectedYear:'',selectedPeriod:''},
            optionfields : {rdoLabelIsActiveAll:true,rdoLabelIsActiveYes:false,rdoLabelIsActiveNo:false,rdoFormatExcel:true},
            errors:{selectLabel:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            labelGroups:[],
            deptGroups:[],
            selectedLabel:[],
            filterLabelGroups:[],
            statusTypeGroups:[],
            callLetterGroup:[],
            yearGroup:[],
            ctmReport:[],
        }    
        this.onClear = this.onClear.bind(this);     
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})  
        modal['modalSpinnerShow']=true;       
        this.setDefault();
        this.getData();
    }

    setDefault =()=>{        
        let selectfields = this.state.selectfields;      
        let optionfields = this.state.optionfields 
        let errors = this.state.errors;
        errors["selectLabel"] =""
        selectfields["selectedFileFormat"]="Excel"; 
        selectfields["selectedPeriod"]="1"
        selectfields["isActiveLabel"] = "All"
        optionfields["rdoLabelIsActiveAll"]= true;     
        optionfields["rdoLabelIsActiveYes"]= false;  
        optionfields["rdoLabelIsActiveNo"]= false; 
        optionfields["rdoFormatExcel"]= true;       
        this.setState({selectfields,optionfields,errors})
    }

    getData =()=>{
        let modal = this.state.modal; 
        let reportController = new ReportController();
        let selectfields = this.state.selectfields;     
        reportController.getLabels().then(jsonLabel => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonLabel))});             
            //console.log(this.state.labelGroups)
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })

        reportController.getYears().then(jsonYear => {
            this.setState({ yearGroup: JSON.parse(JSON.stringify(jsonYear))});     
            selectfields["selectedYear"] =  this.state.yearGroup[0]["id"]; 
            modal['modalSpinnerShow']=false;        
            //console.log(this.state.yearGroup)
            this.setState({selectfields,modal})
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })
    }

    onClear(e:any)
    {
        e.preventDefault();
        let modal = this.state.modal; 
        modal['modalSpinnerShow']=true; 
        this.setDefault();
        this.getData();
    }

    onSubmit(e:any){debugger;
        e.preventDefault();
        let fields = this.state.fields;
        let modal = this.state.modal; 
        let listOfLabels;
        let finalListOfLabels;
        let selectfields = this.state.selectfields;
        let selectedLabel=this.state.selectedLabel
        //modal['modalSpinnerShow']=true; 
        let reportController = new ReportController();  
        this.state.labelGroups.filter(p => p.isChecked === true).map((item,key) => {selectedLabel.push(item)})
        this.setState({selectedLabel})
       
        let labelsString=selectedLabel.map((item,key)=>{
        listOfLabels = "<Label ID=\"" +item.id + "\" />"
        return listOfLabels;
        })
        finalListOfLabels="<ROOT>"+labelsString.map((item,key)=>{return item})+"</ROOT>"
       if (this.ValidateDate())
        {   
            let startDates;
            let endDates;
            var bulidlink = "";
            var reportURL= new Variables().ReportPath + "eTA Contest Winner Report Finance";            
            bulidlink = bulidlink + '&UserID=' + sessionStorage.getItem("uid")
            if(selectfields["selectedPeriod"] === "1"){
                 bulidlink = bulidlink + "&StartDate=01/01/"+selectfields["selectedYear"]+"&EndDate=03/31/"+selectfields["selectedYear"]
                 
                 startDates= "01/01/"+selectfields["selectedYear"]
                 endDates="03/31/"+selectfields["selectedYear"]
            }
            else if(selectfields["selectedPeriod"] === "2"){
                bulidlink = bulidlink + "&StartDate=04/01/"+selectfields["selectedYear"]+"&EndDate=06/30/"+selectfields["selectedYear"]
                startDates="04/01/"+selectfields["selectedYear"]
                endDates ="06/30/"+selectfields["selectedYear"]
            }
            else if(selectfields["selectedPeriod"] === "3") {
                bulidlink = bulidlink + "&StartDate=07/01/"+selectfields["selectedYear"]+"&EndDate=09/30/"+selectfields["selectedYear"]
                
                startDates="07/01/"+selectfields["selectedYear"]
                endDates ="09/30/"+selectfields["selectedYear"]
        }
           else if(selectfields["selectedPeriod"] === "4"){
               bulidlink = bulidlink + "&StartDate=10/01/"+selectfields["selectedYear"]+"&EndDate=12/31/"+selectfields["selectedYear"]

               startDates="10/01/"+selectfields["selectedYear"]
               endDates ="12/30/"+selectfields["selectedYear"]

           }
            bulidlink = bulidlink + '&Quarter=' + selectfields["selectedPeriod"];
            bulidlink = bulidlink + '&rs:Format=' + selectfields["selectedFileFormat"];
            bulidlink = bulidlink + '&rs:ClearSession=true';
            var Params={
                "UserID": sessionStorage.getItem("uid"),
                "ListOfLabels":finalListOfLabels,
                "StartDate":startDates,
                "EndDate":endDates
            }
            
            reportController.sendLabels(Params).then(res => {
           
            window.open(reportURL+bulidlink);
            selectedLabel=[];
            this.setState({selectedLabel})

            }).catch(error => {
                console.error("There was an error...," + error)
            });
           /*this.setDefault();
            this.getData();
            this.props.history.push('/admin/qtrycontest');*/
        }
    }

    ValidateDate(){        
        let errors = this.state.errors;
        let modal = this.state.modal; 
        let labelCount = 0;          
        let formIsValid = true;             
        this.state.labelGroups.map(item =>{
            if(item["isChecked"])
                labelCount++;           
        })
        this.state.filterLabelGroups.map(item =>{
            if(item["isChecked"])
                labelCount++;           
        })  
        if(labelCount === 0)
        {
            formIsValid = false;
            errors["selectLabel"] ="Please Select Label(s)"
        }
                
        this.setState({errors,modal});
        return formIsValid;
    }

    handleOptionChange(e:any){
        debugger;
        let optionfields = this.state.optionfields;
        let selectfields = this.state.selectfields 
        let fields = this.state.fields 
        let currentTargetId = e.currentTarget.id;   
        let filterLabelGroups = [...this.state.filterLabelGroups]; 
        filterLabelGroups.splice(0, filterLabelGroups.length)
        if(e.currentTarget.name === "rdoLabelActive")
        {
            if (currentTargetId === 'rdoLabelIsActiveYes'){
                optionfields["rdoLabelIsActiveYes"] = true;
                optionfields["rdoLabelIsActiveAll"] = false;
                optionfields["rdoLabelIsActiveNo"] = false;
                fields["isLabelAllChked"] = false;
                {this.state.labelGroups.filter(p => p.isActive === true).map((item,key) => {      
                    item["isChecked"] = false;             
                    filterLabelGroups.push(item)
                })}                      
            }
            if (currentTargetId === 'rdoLabelIsActiveNo'){
                optionfields["rdoLabelIsActiveNo"] = true;
                optionfields["rdoLabelIsActiveYes"] = false;
                optionfields["rdoLabelIsActiveAll"] = false; 
                fields["isLabelAllChked"] = false;
                {this.state.labelGroups.filter(p => p.isActive === false).map((item,key) => {
                    item["isChecked"] = false; 
                    filterLabelGroups.push(item)
                })}                 
            }          
            if (currentTargetId === 'rdoLabelIsActiveAll'){
                optionfields["rdoLabelIsActiveAll"] = true;
                optionfields["rdoLabelIsActiveYes"] = false;
                optionfields["rdoLabelIsActiveNo"] = false; 
                fields["isLabelAllChked"] = false;
                let labelGroups = [...this.state.labelGroups];
                {this.state.labelGroups.map((item,key) => {
                    labelGroups[key]["isChecked"] = false
                    item["isChecked"] = false; 
                })} 
                this.setState({ labelGroups });               
            } 
            this.setState({filterLabelGroups,fields})
            selectfields["isActiveLabel"] = e.currentTarget.value;   
        } 
       
        if(e.currentTarget.name === "rdoFileFormat")
        {
            if (currentTargetId === 'rdoFormatExcel'){
                optionfields["rdoFormatExcel"] = true;
                optionfields["rdoFormatPDF"] = false;          
            }
            selectfields["selectedFileFormat"] = e.currentTarget.value;
        }
        this.setState({ optionfields, selectfields});
        //console.log("Option Events: ",this.state.optionfields)
    }

    handleCheckChange = (i,e) => {debugger;
        if(this.state.selectfields.isActiveLabel === "All")
        {
            let labelGroups = [...this.state.labelGroups];
            let errors = this.state.errors;
            labelGroups[i]["isChecked"] = !labelGroups[i]["isChecked"]                
            errors["selectLabel"] =""
            this.setState({ labelGroups,errors });
        }
        else
        {
            let filterLabelGroups = [...this.state.filterLabelGroups];
            let errors = this.state.errors;
            filterLabelGroups[i]["isChecked"] = !filterLabelGroups[i]["isChecked"]                
            errors["selectLabel"] =""
            this.setState({ filterLabelGroups,errors });
        }
    }

    handleSelectChange = (e: any) => {
        debugger;       
        let selectfields = this.state.selectfields;        
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ selectfields});
    }  
    
    handleCheckChangeAll = (e) => {   
        if(this.state.selectfields.isActiveLabel === "All")
        {
            let labelGroups = [...this.state.labelGroups];
            let fields = this.state.fields;
            labelGroups.forEach(item => {
                item.isChecked = e.target.checked;                   
            });
            fields["isLabelAllChked"] = e.target.checked;
            this.setState({ labelGroups,fields });    
        }
        else
        {
            let filterLabelGroups = [...this.state.filterLabelGroups];
            let fields = this.state.fields;
            filterLabelGroups.forEach(item => {
                item.isChecked = e.target.checked;                   
            });
            fields["isLabelAllChked"] = e.target.checked;
            this.setState({ filterLabelGroups,fields });             
        }
    }
    
    renderFilterlabelList = () => {debugger;
        return this.state.filterLabelGroups.map((item,key) =>
            <div>
                <input key={item["id"]} type="checkbox" name={item["name"]} value={item["name"]} checked={item["isChecked"]} onChange={this.handleCheckChange.bind(this, key)} />
                &nbsp;<label>{item["name"]}</label>
            </div>
        )
    }

    renderList = () => {debugger;
        return this.state.labelGroups.map((item,key) =>
            <div>
                <input key={item["id"]} type="checkbox" name={item["name"]} value={item["name"]} checked={item["isChecked"]} onChange={this.handleCheckChange.bind(this, key)} />
                &nbsp;<label>{item["name"]}</label>
            </div>
        )
    }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Quarterly Contest Winner Report</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                 <Row style={{}}>
                                    <Col md={4} style={{}}><label>HFM Entity</label>
                                        <Form.Group>
                                                <Row >
                                                    <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="All" name="rdoLabelActive" id="rdoLabelIsActiveAll" onChange={(e)=>this.handleOptionChange(e)} value="All"  checked={this.state.optionfields.rdoLabelIsActiveAll} /></Col>
                                                    <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Active"  name="rdoLabelActive" id="rdoLabelIsActiveYes" onChange={(e)=>this.handleOptionChange(e)} value="1"  checked={this.state.optionfields.rdoLabelIsActiveYes} /></Col>
                                                    <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Inactive" name="rdoLabelActive" id="rdoLabelIsActiveNo" onChange={(e)=>this.handleOptionChange(e)} value="0"  checked={this.state.optionfields.rdoComplianceOfficerNo} /></Col>
                                                </Row>
                                        </Form.Group>
                                    </Col>
                                    <Col md={8} style={{}}>                                   
                                        <div style={{width:"50%",height:"100px",overflowY:"auto",overflowX:"auto",border:"groove",padding:"1%"}}>
                                        <input type="checkbox" 
                                        id="callLetters"
                                        value="checkAll" 
                                        checked={this.state.fields.isLabelAllChked} 
                                        onChange={this.handleCheckChangeAll} />&nbsp;<label>Check All</label>
                                        <br/>
                                        {this.state.selectfields.isActiveLabel === "All" ? this.renderList(): this.renderFilterlabelList()}
                                        </div>
                                        {this.state.errors.selectLabel != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.selectLabel}</div> : <></>}
                                    </Col> 
                                </Row>
                                <Row  style={{}}> 
                                 <Col md={4} style={{}}><label>Year</label></Col>                                   
                                    <Col sm={1}>
                                    <FormSelect
                                            properties={{ label: "", id: "selectedYear", name: "selectedYear", value: this.state.selectfields.selectedYear, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                            fieldRequired={false}
                                            errorMsg={this.state.errors.labelId}
                                            defaultOption=""
                                            fillOption={this.state.yearGroup}
                                        />
                                    </Col>
                                    <Col sm={7}>                                         
                                    </Col>                                   
                                </Row>
                                <Row style={{}}>
                                <Col md={4} style={{}}><label>Quarter</label></Col>
                                    <Col md={1} style={{}}>
                                    <Form.Group>                                       
                                        <div><Form.Control as="select" name="selectedPeriod" className="form-control-sm" value={this.state.selectfields.selectedPeriod} onChange={this.handleSelectChange}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option> 
                                            <option value="4">4</option>                                             
                                        </Form.Control></div>
                                    </Form.Group> 
                                    </Col>                                    
                                </Row>
                                <Row style={{}}>
                                <Col md={4} style={{}}><label></label></Col>
                                    <Col md={8} style={{}}>
                                      <Form.Group>
                                            <Row >
                                                <Col sm={1} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Excel"  name="rdoFileFormat" id="rdoFormatExcel" onChange={(e)=>this.handleOptionChange(e)} value="Excel"  checked={this.state.optionfields.rdoFormatExcel} /></Col>                                                
                                            </Row>
                                        </Form.Group>   
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} style={{}}><label></label></Col>
                                    <Col sm={1.5} style={{marginTop:"2%"}}>
                                        <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSubmit(e)}>
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                            {!loading && <span>Get Report</span>}
                                        </Button>
                                    </Col> 
                                    <Col sm={1} style={{marginTop:"2%",marginLeft:"2%"}}>
                                        <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onClear(e)}>
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                            {!loading && <span>Clear</span>}
                                        </Button>                                            
                                    </Col> 
                                </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}