import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class CarServiceIncomingCAController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getCarserviceIncomingCADetails (data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.carServiceIncomingCAList;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncarCAlist : Array<{"authReqId": string, "travelDate": string,"traveler":string,"travelerAgent":string,"createdBy":string,"createdDate":string
                                        "updatedDate":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCAlist);
                    function carServiceCAlist(item: { authreq_id: any; travel_date: any;traveler:any;travel_agent_name:any;created_by:any;
                                         created_date:any;updated_date:any;}){
                     jsoncarCAlist.push({"authReqId": item.authreq_id, "travelDate": item.travel_date, "traveler": item.traveler,
                        "travelerAgent": item.travel_agent_name, "createdBy": item.created_by, "createdDate": item.created_date, "updatedDate": item.updated_date})
                    }
                    return JSON.parse(JSON.stringify(jsoncarCAlist).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsoncarCAlist).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getTA (data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.getTa;
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
            return error;
        }); 
    }

    getIncomingTADetails (data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.getIncomingTaDetails;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncarServiceTADetlist : Array<{"carServiceId": string, "companyName": string,"pickUpDate":string,"pickUpTime":string,"pickUpAmPm":string,
                                    "rate":string,"total":string,"pickUpLocation1":string,"pickUpLocation2":string,"dropOffLocation1":string,"dropOffLocation2":string,"phoneUsage":string,
                                    "carType":string,"serviceType":string,"travellerId":string,"traveller":string,"scAmount":string,"scDesc":string,"passengerCount":string,
                                    "locationCount":string,"rln":string,"bcdGenerated":string,"splInstructions":string,"confirmation":string,"accNumber":string,"accCCNumber":string}> = [];
            if(response.success){
                response.recordset.forEach(carServiceTAlist);
                function carServiceTAlist(item: { booking_id: any;company_name : any;pick_up_date: any;pick_up_time: any;am_pm: any;
                                rate: any;total: any;pick_up_location1: any;pick_up_location2: any;drop_off_location1: any;drop_off_location2: any;phone_usage: any;
                                car_type: any;service_type: any;special_instructions: any;confirmation_number: any;traveller_id: any; traveler: any;sc_amount: any;sc_description: any;
                                passenger_count: any;location_count: any; car_service_account_number: any;car_service_cc_account_number: any;RLN: any;BCD_generated: any;}){
                    jsoncarServiceTADetlist.push({"carServiceId": item.booking_id, "companyName": item.company_name, "pickUpDate": item.pick_up_date,"pickUpTime": item.pick_up_time,
                                "pickUpAmPm": item.am_pm, "rate": item.rate, "total": item.total, "pickUpLocation1": item.pick_up_location1,"pickUpLocation2": item.pick_up_location2,
                                "dropOffLocation1": item.drop_off_location1,"dropOffLocation2": item.drop_off_location2,"phoneUsage": item.phone_usage,"carType": item.car_type,
                                "serviceType": item.service_type,"travellerId": item.traveller_id,"traveller": item.traveler,"scAmount": item.sc_amount,"scDesc": item.sc_description,
                                "passengerCount": item.passenger_count,"locationCount": item.location_count,"rln": item.RLN,"bcdGenerated": item.BCD_generated,
                                "splInstructions":item.special_instructions,"confirmation":item.confirmation_number,"accNumber":item.car_service_account_number
                                ,"accCCNumber":item.car_service_cc_account_number})
                }
                return JSON.parse(JSON.stringify(jsoncarServiceTADetlist).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsoncarServiceTADetlist).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getApprovalHistory(data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.getCarServiceApprovalHistory;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    updateConfirmation(data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.updateConfirmation;
      //  var data = {};
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getCarserviceCoding (data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.getCarServiceCoding;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncarCodinglist : Array<{"codingID":string,"travelType":string,"businessUnit":string,"objectAccount":string,
                                        "subAccount":string,"projectEmpNumber":string,"ctsNumber":string,"amount":string,
                                        "costCenter":string,"poLine":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCodinglist);
                    function carServiceCodinglist(item: { coding_id: any;travel_type: any;business_unit:any;object_account:any;sub_account:any;
                                                    project_emp_number:any;cts_number:any;amount:any;cost_center:any;po_line:any;}){
                    jsoncarCodinglist.push({ "codingID":item.coding_id,"travelType":item.travel_type,"businessUnit":item.business_unit,
                     "objectAccount":item.object_account,"subAccount":item.sub_account,"projectEmpNumber":item.project_emp_number,
                     "ctsNumber":item.cts_number,"amount":item.amount,"costCenter":item.cost_center,"poLine":item.po_line})
                    }
                    return JSON.parse(JSON.stringify(jsoncarCodinglist).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsoncarCodinglist).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarServiceHistory (data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceIncomingCA + this.variables.incomingCAService.getCarServiceHistory;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncarHistorylist : Array<{"authReqId":string,"createdDate":string,"traveler":string,"createdBy":string,
                                        "travelAgentName":string,"status":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCodinglist);
                    function carServiceCodinglist(item: {authreq_id:any;created_date:any;traveler:any;created_by:any;travel_agent_name:any;
                        status_desc:any}){
                            jsoncarHistorylist.push({ "authReqId":item.authreq_id,"createdDate":item.created_date,"traveler":item.traveler,
                            "createdBy":item.created_by,"travelAgentName":item.travel_agent_name,"status":item.status_desc})
                    }
                    return JSON.parse(JSON.stringify(jsoncarHistorylist).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsoncarHistorylist).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}