import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class AirRailController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getBooking(data: any){
        var data1 = {
            "air_id":data.air_id,
            "leg_id":data.leg_id,
            "sessionTA":data.sessionTA
        };
        var url = this.variables.Url + this.variables.Controller.airRail + this.variables.airRailService.getBooking;
        return this.serviceProxy.get(url,data1).then((response : any) => {
            var jsonAirBooking : Array<{"airId": string; "recordLocator": string; "legId": string; "airlineId": string; "airlineDesc": string; "travelClassId": string; "travelClassDesc": string; "travelDate":string; "fromLocation": string; "fromTime": string; "from_AM_PM": string; "toLocation": string; "toTime": string; "to_AM_PM": string; "scAmount": string; "scDescription": string; "BCDGenerated": boolean; "BCDValidated": boolean; "totalAmount": string }> = [];
            if(response.success){
                response.recordset.forEach(airBooking);
                function airBooking(item: { air_id: string; record_locator: string; leg_id:string; airline_id: string; airline_desc: string; travel_class_id: string; travel_class_desc: string; travel_date:string; from_location: string; from_time: string; From_AM_PM: string; to_location: string; to_time: string; to_am_pm: string; sc_amount: string; sc_description: string; BCD_generated: boolean; BCD_validated: boolean; Total_Amount: string}, _index: number){
                    jsonAirBooking.push({"airId": item.air_id, "recordLocator": item.record_locator, "legId": item.leg_id, "airlineId": item.airline_id, "airlineDesc": item.airline_desc, "travelClassId": item.travel_class_id, "travelClassDesc": item.travel_class_desc, "travelDate": item.travel_date, "fromLocation": item.from_location, "fromTime": item.from_time, "from_AM_PM": item.From_AM_PM, "toLocation": item.to_location, "toTime": item.to_time, "to_AM_PM": item.to_am_pm, "scAmount": item.sc_amount, "scDescription": item.sc_description, "BCDGenerated": item.BCD_generated, "BCDValidated": item.BCD_validated, "totalAmount": item.Total_Amount})
                }
                return JSON.parse(JSON.stringify(jsonAirBooking).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonAirBooking).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getAirLineCodes(){
        var url = this.variables.Url + this.variables.Controller.airRail + this.variables.airRailService.getAirLineCodes;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { airline_id: string; description: string}, _index: number){
                    jsonLabel.push({"id": item.airline_id, "name": item.description})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getTravelClassCodes(){
        var url = this.variables.Url + this.variables.Controller.airRail + this.variables.airRailService.getTravelClassCodes;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { travel_class_id: string; description: string}, _index: number){
                    jsonLabel.push({"id": item.travel_class_id, "name": item.description})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getAirBookingAirId(air_id:string,sessionTA:string){
        var url = this.variables.Url + this.variables.Controller.airRail + this.variables.airRailService.getAirBookingAirId;
        var data = {
            air_id:air_id,
            sessionTA: sessionTA
        }
        return this.serviceProxy.get(url,data).then(response => {
            var jsonAirId : Array<{"airId": string}> = [];
            if(response.success){
                response.recordset.forEach(airId);
                function airId(item: { air_id: string}){
                    jsonAirId.push({"airId": item.air_id})
                }
                return JSON.parse(JSON.stringify(jsonAirId).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonAirId).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    insertAirLeg (data){
        var url = this.variables.Url+ this.variables.Controller.airRail + this.variables.airRailService.insertAirLeg;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertAirMap (data){
        var url = this.variables.Url+ this.variables.Controller.airRail + this.variables.airRailService.insertAirMap;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateAirMap (data){
        var url = this.variables.Url+ this.variables.Controller.airRail + this.variables.airRailService.updateAirMap;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessUpdate (data){
        var url = this.variables.Url+ this.variables.Controller.airRail + this.variables.airRailService.userProcessUpdate_AirRail;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessInsertCopy (data){
        var url = this.variables.Url+ this.variables.Controller.airRail + this.variables.airRailService.userProcessInsertCopy_AirRail;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}