import React, { Component } from "react";
import { IWTPParameter, Istate } from "../../../Model/IWTP";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { ApproverController } from "../../../Controllers/ApproverController";
//import '../../../assets/css/Blink.css'
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import * as Utils from '../../../js/Utils.js';
import { TravellerController } from "../../../Controllers/TravellerController";
import { BCDAgentController } from "../../../Controllers/BCDAgentController";
import addSubtractDate from 'add-subtract-date';
import * as Constants from "../../../js/Constants";
import parse from 'html-react-parser';

export class WTP extends Component<IWTPParameter, Istate> {
    constructor(props: IWTPParameter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: {},
            errors: {},
            errMsg: "",
            loggedIn,
            wtpIncoming: [],
            currentSort: 'down',
            currentColumn: 'traveller',
            loading: false,
            modal: { modalSpinnerShow: false }
        }
    }

    componentWillMount() {
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal});
        sessionStorage.setItem("page","wtp");
        sessionStorage.setItem("frompage",Constants.bcdincomingta); 
        let bcdAgentController = new BCDAgentController();
        var wtpData = {
            sortOrder: "traveler, travel_date, authreq_id, created_by, travel_agent_name",
        }
        bcdAgentController.getWTPIncomingTAs(wtpData).then(jsonWTPIncoming => {
            var dataArr = jsonWTPIncoming.map(item => {
                return [item.authReqId, item]
            })
            var mapArr = new Map(dataArr);
            var result = [...mapArr.values()];
            this.setState({
                wtpIncoming: JSON.parse(JSON.stringify(result))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
        }).catch(error => {
            console.error("There was an error...," + error)
        });
    }

    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = this.state.wtpIncoming;
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            wtpIncoming: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });

    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    onSelect(authReqId){
        let travellerController = new TravellerController();
        travellerController.getTA(authReqId).then(response => {
            this.props.history.push({pathname:'/admin/confirmation',state:{from:"incoming"}});
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }

    render() {
        var prevAuthReqId = 0;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">CTM Incoming TA's</h4><p className="category"></p></div>
                            <Card.Body>
                                <Row>
                                    <Col sm={6}></Col>
                                    <Col sm={6}>
                                        <div style={{ width: '100%' }} className="alert alert-primary alert-cus-info">
                                            <div><h6 className="text-dark">Legend for Incoming TA's</h6>
                                                <div className="text-dark font-size-xs"><span className="badge badge-blue">(1 - day)</span> indicates travel is occuring within 1 day.</div>                                    
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{textAlign:"center", marginBottom:"15px"}}>
                                        <Button size="sm" disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload()} title="Click to Refresh the Screen">
                                            {this.state.loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", color: "#3472F7" }}
                                            />
                                            )}
                                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                            {!this.state.loading && <span>Refresh </span>}
                                        </Button>
                                        <Button size="sm" disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                            {this.state.loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", color: "#3472F7" }}
                                            />
                                            )}
                                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                            {!this.state.loading && <span>Print </span>}
                                        </Button>
                                    </Col>
                                </Row>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                {(sessionStorage.getItem("adID") !== null) ?
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Table hover striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th onClick={() => this.onSortChange('traveller')}>Traveler <i className={this.setArrow('traveller')}></i></th>
                                                            <th onClick={() => this.onSortChange('travelDate')}>Travel Date <i className={this.setArrow('travelDate')}></i></th>
                                                            <th onClick={() => this.onSortChange('authReqId')}>Auth Req <i className={this.setArrow('authReqId')}></i></th>
                                                            <th onClick={() => this.onSortChange('createdBy')}>Submitter <i className={this.setArrow('createdBy')}></i></th>
                                                            <th onClick={() => this.onSortChange('travelAgentName')}>Agent Name <i className={this.setArrow('travelAgentName')}></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.wtpIncoming.map((prop, key) => {
                                                            if(prevAuthReqId != prop["authReqId"]){
                                                                prevAuthReqId = prop["authReqId"] 
                                                                var concateTravelDate='';
                                                                var i=0;
                                                                var filterTravelDate = this.state.wtpIncoming.filter(x => x.authReqId == prop["authReqId"]).map(m=> m.travelDate)
                                                                for(let travelDate of filterTravelDate){
                                                                    if(i===0){
                                                                        concateTravelDate = Utils.FormatDate(travelDate);
                                                                    }else
                                                                    {
                                                                        concateTravelDate = concateTravelDate + "<br>" + Utils.FormatDate(travelDate);
                                                                    }
                                                                    i=i+1;
                                                                }
                                                                var bBlink = false;
                                                                var blinkDate = Utils.FormatDate(addSubtractDate.add(new Date(Date.now()),1,"day"))
                                                                if(prop["travelDate"] != null && new Date(prop["travelDate"]) <= new Date(blinkDate)){
                                                                    bBlink = true;
                                                                }
                                                                var color = "", blinkColor = "";
                                                                if(bBlink){
                                                                    if(new Date(prop["updatedDate"]) < new Date(addSubtractDate.add(new Date(Date.now()),-1,"day"))){
                                                                        blinkColor = "#FF0000";
                                                                    }
                                                                    else{
                                                                        blinkColor = "";
                                                                    }
                                                                }
                                                                else{
                                                                    if(new Date(prop["updatedDate"]) < new Date(addSubtractDate.add(new Date(Date.now()),-1,"day"))){
                                                                        color = "#FF0000";
                                                                    }
                                                                    else{
                                                                        color = "";
                                                                    }        
                                                                }
                                                                return (
                                                                    <tr key={key} style={{cursor:"pointer"}} onClick={() => this.onSelect(prop["authReqId"])}>
                                                                        <td>{prop["traveller"]}</td>
                                                                        <td>{parse(concateTravelDate)}</td>
                                                                        {bBlink ? <td>{prop["carServiceOnly"] ? 'CA' + prop["authReqId"]:prop["authReqId"]}<span className="badge badge-blue " style={{fontWeight: "bold", fontSize: '85%'}}> (1 - day)</span></td>
                                                                        :
                                                                            <td>{prop["authReqId"]}</td>
                                                                        }
                                                                        <td>{prop["createdBy"]}</td>
                                                                        <td>{prop["travelAgentName"]}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col sm={12}>
                                            {"For security reason, this page is not displayed.<br>Please try to login again."}
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }

}