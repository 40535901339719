import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class CarServiceController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getServiceType(){
        var url = this.variables.Url + this.variables.Controller.carService + this.variables.carServiceService.getServiceType;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonServiceType : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(serviceType);
                function serviceType(item: { service_type_id: string; Description: string}){
                    jsonServiceType.push({"id": item.service_type_id, "name": item.Description})
                }
                return JSON.parse(JSON.stringify(jsonServiceType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonServiceType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarCompany(){
        var url = this.variables.Url + this.variables.Controller.carService + this.variables.carServiceService.getCarCompany;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonCarCompany : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(carCompany);
                function carCompany(item: { Car_Service_Company_ID: string; Car_Service_Company: string}){
                    jsonCarCompany.push({"id": item.Car_Service_Company_ID, "name": item.Car_Service_Company})
                }
                return JSON.parse(JSON.stringify(jsonCarCompany).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCarCompany).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarType(){
        var url = this.variables.Url + this.variables.Controller.carService + this.variables.carServiceService.getCarType;
        return this.serviceProxy.get(url,{})
        .then(response => {
            var jsonCarType : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(carType);
                function carType(item: { car_type_id: string; Description: string}){
                    jsonCarType.push({"id": item.car_type_id, "name": item.Description})
                }
                return JSON.parse(JSON.stringify(jsonCarType).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCarType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarServiceBookingCarServiceId(carServiceId:string, sessionTA:string){
        var url = this.variables.Url + this.variables.Controller.carService + this.variables.carServiceService.getCarServiceBookingCarServiceId;
        var data = {
            carRentId:carServiceId,
            sessionTA: sessionTA
        }
        return this.serviceProxy.get(url,data).then(response => {
            var jsonCarServiceId : Array<{"carServiceId": string}> = [];
            if(response.success){
                response.recordset.forEach(carServiceId);
                function carServiceId(item: { car_service_id: string}){
                    jsonCarServiceId.push({"carServiceId": item.car_service_id})
                }
                return JSON.parse(JSON.stringify(jsonCarServiceId).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCarServiceId).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getBooking(data: any){
        var url = this.variables.Url + this.variables.Controller.carService + this.variables.carServiceService.getBooking;
        return this.serviceProxy.get(url,data).then((response : any) => {                                                                                                                                                                                                                 
            var jsonBooking : Array<{"carServiceId": number; "serviceTypeId": string; "serviceTypeDesc": string;"carCompanyId": number; "carCompanyDesc": string; "carTypeId": number; "carTypeDesc": string; "pickUpDate": string; "pickUpTime": string; "ampm": string; "isPhoneIncluded": boolean; "rate": number; "isRateHourly": boolean; "total": number; "passengerCount": number; "scAmount": number; "scDescription": string; "bcdGenerated":boolean; "bcdValidated":boolean; "RLN": string}> = [];
            if(response.success){
                response.recordset.forEach(carServiceBooking);
                function carServiceBooking(item: { car_service_id: number; service_type_id: string; service_type_desc: string; car_service_company_id:number; car_service_company: string; car_type_id: number; car_type_desc: string; pick_up_date: string; pick_up_time: string; am_pm: string, is_phone_included: boolean, rate: number; is_rate_hourly: boolean; total: number; passenger_count: number; sc_amount: number; sc_description: string; bcd_generated: boolean; bcd_validated: boolean; RLN: string}, _index: number){
                    jsonBooking.push({"carServiceId": item.car_service_id, "serviceTypeId": item.service_type_id, "serviceTypeDesc": item.service_type_desc, "carCompanyId":item.car_service_company_id, "carCompanyDesc": item.car_service_company, "carTypeId": item.car_type_id, "carTypeDesc": item.car_type_desc, "pickUpDate": item.pick_up_date, "pickUpTime": item.pick_up_time, "ampm": item.am_pm, "isPhoneIncluded": item.is_phone_included, "rate": item.rate, "isRateHourly": item.is_rate_hourly, "total": item.total, "passengerCount": item.passenger_count, "scAmount": item.sc_amount, "scDescription": item.sc_description, "bcdGenerated": item.bcd_generated, "bcdValidated": item.bcd_validated, "RLN": item.RLN})
                }
                return JSON.parse(JSON.stringify(jsonBooking).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonBooking).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getCarServiceLocation(carServiceId:string){
        var url = this.variables.Url + this.variables.Controller.carService + this.variables.carServiceService.getCarServiceLocation;
        var data = {
            carServiceId:carServiceId
        }
        return this.serviceProxy.get(url,data).then(response => {
            var jsonCarServiceLocation : Array<{"carServiceLocationId": string;"pickUpLocation1": string;"dropOffLocation1": string;"pickUpLocation2": string;"dropOffLocation2": string}> = [];
            if(response.success){
                response.recordset.forEach(carServiceLocation);
                function carServiceLocation(item: { car_service_location_id: string, pick_up_location1: string, drop_off_location1: string, pick_up_location2: string, drop_off_location2: string}){
                    jsonCarServiceLocation.push({"carServiceLocationId": item.car_service_location_id, "pickUpLocation1": item.pick_up_location1, "dropOffLocation1": item.drop_off_location1, "pickUpLocation2": item.pick_up_location2, "dropOffLocation2": item.drop_off_location2})
                }
                return JSON.parse(JSON.stringify(jsonCarServiceLocation).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCarServiceLocation).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    userProcessInsert (data){
        var url = this.variables.Url+ this.variables.Controller.carService + this.variables.carServiceService.userProcessInsert;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessCopy (data){
        var url = this.variables.Url+ this.variables.Controller.carService + this.variables.carServiceService.userProcessCopy;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    userProcessUpdate (data){
        var url = this.variables.Url+ this.variables.Controller.carService + this.variables.carServiceService.userProcessUpdate;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    bookingMap (data){
        var url = this.variables.Url+ this.variables.Controller.carService + this.variables.carServiceService.bookingMap;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateSpecialIns (data){
        var url = this.variables.Url+ this.variables.Controller.carService + this.variables.carServiceService.updateSpecialIns;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    carServiceLocation (data){
        var url = this.variables.Url+ this.variables.Controller.carService + this.variables.carServiceService.carServiceLocation;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}