import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class AddBCDCodingController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }


    GetBCDAuthorizationRequest (data){
        var url = this.variables.Url+ this.variables.Controller.addBCDCoding + this.variables.addingBCDCodingService.getBCDAuthorizationRequest;       
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonBCDAuthorizationRequest : Array<{"authReqId":any;"statusDesc":any;"codingRdy":boolean;"rdyDate":any;"codingSent":boolean;"projName":any;"projNo":any;
            "artist":any;"travelAgent":any;"createdBy":any;"expenseType":any;"codingSentDate":any}> = [];
                if(response.success){
                    response.recordset.forEach(BCDAuthorizationRequest);
                    function BCDAuthorizationRequest(item: {AuthReq_ID:string,statusDesc:string,BCD_remarksRDY:boolean,BCD_remarksRDYDate:string,BCD_remarksSent:boolean,BCD_remarksSentDate:string,
                        Artist_Project_Name:string,Artist_Project_Number:string,Artist_Name:string,Travel_Agent_Name:string,Created_By:string,expTypeDesc:string}){
                            jsonBCDAuthorizationRequest.push({"authReqId": item.AuthReq_ID,"statusDesc": item.statusDesc,"codingRdy": item.BCD_remarksRDY,"rdyDate": item.BCD_remarksRDYDate,
                                            "codingSent": item.BCD_remarksSent,"projName": item.Artist_Project_Name,"projNo": item.Artist_Project_Number,"artist": item.Artist_Name,
                                            "travelAgent": item.Travel_Agent_Name,"createdBy": item.Created_By,"expenseType": item.expTypeDesc,"codingSentDate": item.BCD_remarksSentDate})
                    }
                    return JSON.parse(JSON.stringify(jsonBCDAuthorizationRequest).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonBCDAuthorizationRequest).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    GetTravellerDetailsForPNRXML(data: any){
        var url = this.variables.Url+ this.variables.Controller.addBCDCoding + this.variables.addingBCDCodingService.getTravellerDetailsForPNRXML;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    GetValidatePNRXML(data: any){
        var url = this.variables.Url+ this.variables.Controller.addBCDCoding + this.variables.addingBCDCodingService.getValidatePNRXML;
      
        return this.serviceProxy.get(url,data).then(response=>{
            if(response.success){                
                return JSON.parse(JSON.stringify(response.recordset).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                this.response.msg = response.message.toString();
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    viewSortRemarkToPNR (data: any){        
        var url = this.variables.Url+ this.variables.Controller.bcdws + this.variables.bcdwsService.viewSortRemarkToPNR;
        return this.serviceProxy.get(url,data).then(response=>{
                return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}
