export const MARKETING_TA = 1;
export const RECORDING_TA = 10;
export const OVERHEAD_TA = 100;
export const INTERNATIONAL_REBILL_TA = 1000;
export const ULTRA_RECORDING_TA = 11;
export const ULTRA_MARKETING_TA = 2;

export const TYPE_ARTIST = 1;
export const TYPE_CONTEST_WINNER = 2;
export const TYPE_OVERHEAD = 3;
export const TYPE_RADIO_PERSONNEL = 4;

export const temporaryRLN = "999999";

export const airBooking = "Air";
export const hotelBooking = "Hotel";
export const rentalCarBooking = "Rental Car";
export const carServiceBooking = "Car Service";

export const airCode = "Air";
export const hotelCode = "Hotel";
export const rentalCarCode = "Rental_Car";
export const carServiceCode = "Car_Service";

export const Pending = 1;
export const New = 2;
export const ReSubmit = 3;
export const Declined = 4;
export const Approved = 5;
export const Complete = 6;
export const Terminated = 7;
export const SrExecApproval = 8;
export const CarServiceComplete = 9;
export const Expired = 10;

export const unsubmitted = "UnsubmittedTAs";
export const tahistory = "TAHistory";
export const bcdincomingta = "BCDIncommingTAs";
export const bcdtahistory = "BCDTAHistory";
export const approverinbox = "ApproverInbox";
export const approverhistory = "ApproverHistory";
export const carserviceincomingca = "Car Service Incoming CAs";
export const carservicehistory = "Car Service History";
export const dbcallforlog = "DirectBillReportCallFolioLog";
export const dbrvendorpayment = "DirectBillReportVendorPayment";
export const dbrsubmittedPayment = "DirectBillReportsubmittedPayment";
export const dbrsubmitPayment = "DirectBillReportsubmitPayment";
