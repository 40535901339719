import React, { Component } from "react";
import { ITravellerParameter, Istate } from "../../../Model/ITraveller";
import {FormLabel,FormGroup,Container,Row,Col,InputGroup,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { MenuController } from "../../../Controllers/MenuController";
import '../../../assets/css/Style.css'
import { CreateTAController } from "../../../Controllers/CreateTAController";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { FormInputs } from "../../CustomComponents/FormInput";
import { AuthStatus } from "../Common/AuthStatus"
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom'
import jsonTraveler from "../../../json/Traveler.json";
import { Variables } from '../../../Variables/Variables';
import { TravellerController } from "../../../Controllers/TravellerController";
import { CommonController } from  "../../../Controllers/CommonController";
import * as Const from '../../../js/Constants.js';
import { SubmitButton } from "./SubmitButton";
import {alert,confirm} from 'react-bootstrap-confirmation';
import { isConstructorDeclaration, isExpressionWithTypeArguments, isUnparsedPrepend, updateAwait } from "typescript";
import { isThisHour } from "date-fns/esm";
import * as Utils from "../../../js/Utils.js";
import { el } from "date-fns/locale";
import parse from 'html-react-parser';
import { constants } from "buffer";
import { timeStamp } from "console";
import * as Config from '../../../js/GlobalVar.js';
import { EmailController } from "../../../Controllers/EmailController";

export class Traveller extends Component<ITravellerParameter,Istate> {
    constructor(props:ITravellerParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {sessionTA: sessionStorage.getItem("ta")!, expenseTypeId: parseInt(sessionStorage.getItem("ta_expense_type_id")!), SAPEmpNo:'', projectName:'', artistName:'', projectNumber:'',firstName:'',lastName:'',reason:'',resv:'',lblName:'',lblType:'',lblComments:'',lblFormOnFile:'', lblRestrictions:'',lblSumbitter:'',lblOpCo:'',travellerId:'',divCreateTA:'block',divTA:'none',blnReturn:false,bDupRLN:false,bResult:false,content:'',IsFirstTime:false,bTravelerInfoFormRequired:false,comments:'',SSNo:'',fedID:'',address1:'', address2:'', city: '',stateProvinceId:'', postalCode: '', phone: '', callLetters:'', searchFirstName:'',searchLastName:'',submitter:'',travellerDetailId:''},
            selectfields: {travelAgentId:'', travelAgentName:'', expenseTypeId:'', expenseTypeName:'', selCountryId:'',selStateProvinceId:''},
            checkfields: {isCorporateCard:false,manualResv:false,chkDirectorHeadApproval:false},
            optionfields : {employeeTravel:true, conventionProject:false, rdoOverrideYes:true, rdoOverrideNo:false,rdoRadioLetterYes:true,rdoRadioLetterNo:false,rdoRadioLetterNA:false, rdoIssuer1:true, rdoIssuer2:false},
            errors:{firstName:'', lastName:'', reason:'',resv:'',SSNoFedID:'',address1:'',countryId:'',city:'',stateProvinceId:'',postalCode:'',callLetters:'', travelAgentId:'', expenseTypeId:'', SAPEmpNo:'', projectName:'', artistName:'', projectNumber:''},
            errMsg:"",
            loading:false,
            travelAgent:[],
            expenseType:[],
            listTravelers:[],
            Travelers:[],
            jsonTraveller:[],
            RLN: [],
            IsBCD_A: [],
            modal: {modalSpinnerShow: false,modalBody:'',modalShow:false,modalTAShow:false,modalTitle:'',modalTATitle:''},
            FlightInfo:[],
            travelerTypeId:0,
            Country: [],
            StateProvince: [],
            AirLineBCDCode:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
		this.handleCheckChangeEvent = this.handleCheckChangeEvent.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalTAClose = this.handleModalTAClose.bind(this);
        this.handleModalTAShow = this.handleModalTAShow.bind(this);
        this.handleButtonSearch = this.handleButtonSearch.bind(this);
        this.ConfirmAccept = this.ConfirmAccept.bind(this);
    }

    async componentWillMount(){
        let modal = this.state.modal;
        let selectfields = this.state.selectfields;
        selectfields['selCountryId'] = 'US';
        //load state
       // alert(localStorage.getItem("accessToken"))

        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        let fields = this.state.fields;
        if(sessionStorage.getItem("ta_car_service_only") === "true"){
            this.hideBCDRetrievalElements();
        }
        //console.log("Load: 1");
        await this.loadTraveller();
        //console.log("Load: 2");
        let commonController = new CommonController();
        await commonController.getTravelerInfoFormRequired(this.state.fields.sessionTA).then(response => {
            let fields = this.state.fields;
            var bTravelForm = JSON.parse(JSON.stringify(response.Travel_Form)) 
            if (bTravelForm){
                //console.log("bTravelForm",bTravelForm)
                if (!(sessionStorage.getItem("ta_expense_type_id") === "100" &&  sessionStorage.getItem("ta_artist_project_number") !== "00080000")){
                    fields['blnReturn'] = true;
                }
            }
            this.setState({
                fields
            })
            //console.log("blnReturn", this.state.fields.blnReturn);
        }).catch(error => {
            //console.log("There was an error...", error)
        })
           
        //console.log("Load: 3");
        fields['bTravelerInfoFormRequired'] = await this.travelerInfoFormRequired(sessionStorage.getItem('ta'));  
        //console.log("Load: 4");
        await this.isBCD_generated_exists();
        //console.log("Load: 5");
        await commonController.getAllCountry().then(jsonCountry => {
            
            this.setState({                           
                Country : JSON.parse(JSON.stringify(jsonCountry))
            });
            //console.log("Country:", this.state.Country)
        }).catch(error => {
            console.error("There was an error loading States..., " + error)
        });
        await commonController.getSubmitter(sessionStorage.getItem("uid")!).then(response => {
            let fields = this.state.fields;
            var res = JSON.parse(JSON.stringify(response)) 
            fields['submitter'] = res.name;
            this.setState({
                fields
            })
            //console.log("Submitter", this.state.fields.submitter);
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
            //console.log("There was an error...", error)
        })
        await commonController.getAirLineBCDCodes().then(jsonAirLineCode => {
            this.setState({                           
                AirLineBCDCode : JSON.parse(JSON.stringify(jsonAirLineCode))
            });
            //console.log("AirlineBCDCode:", this.state.AirLineBCDCode);
            //console.log("AirlineBCDCode ID:", this.state.AirLineBCDCode.filter(f=>f.BCDCode == '2V').map(f=>(f.id)));
        
            if(this.state.AirLineBCDCode.filter(f=>f.BCDCode == '2V0').map(f=>(f.id))[0] !== undefined){
                //console.log("AirlineBCDCode no undefined: ", this.state.AirLineBCDCode.filter(f=>f.BCDCode == '2V').map(f=>(f.id))[0])
            }
            else{
            //console.log("AirlineBCDCode: No Data")
            }
        }).catch(error => {
            console.error("There was an error loading getAirLineBCDCodes..., " + error)
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        });
        modal['modalSpinnerShow']=false;
        this.setState({ modal, selectfields });
    }
    clearControl(){
        let fields = this.state.fields;
        fields['travellerDetailId']=''
        fields['searchFirstName']=''
        fields['searchLastName']=''
        fields['firstName'] =''
        fields['lastName']=''
        fields['comments']=''
        fields['reason'] = ''
        fields['fedID']=''
        fields['address1'] = ''
        fields['address2'] = ''
        fields['city'] = ''
        fields['postalCode'] = ''
        fields['phone'] = ''
        fields['callLetters'] = ''
        fields['SSNo']=''
        
        this.setState(fields);
    }
    //addTraveler
    handleModalShow = (e:any,typeId) => {
        let fields = this.state.fields;
        fields['searchFirstName'] = ""
        fields['searchLastName'] = ""
        //
        //clear search travelers
        this.state.listTravelers.splice(0, this.state.listTravelers.length);
        let modal= this.state.modal;
        modal['modalTitle'] = 'eTA - Add Traveler';
        modal['modalShow']=true;
        this.setState({modal, fields, travelerTypeId:typeId});
    }
    handleModalTAShow = (e:any, travellerId:any) => {
       this.resetOptions();
       this.clearControl();
       this.clearError();
        let modal= this.state.modal;
        let fields =this.state.fields;
		let checkfields=this.state.checkfields;
        let optionfields = this.state.optionfields;
        let selectfields = this.state.selectfields;
        let currentTargetId = e.currentTarget.id;
        var travelerTypeId = 0;
        
        //Reset State province
        if(currentTargetId === 'add' || currentTargetId === 'save' || currentTargetId === 'createNewTraveler'){
            
            let selectfields = this.state.selectfields;
            selectfields['selCountryId'] = 'US';
            this.setState({selectfields})
         
            if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)
            {
                let modal = this.state.modal;
                modal["modalSpinnerShow"] = true;
                
                let commonController = new CommonController();
                commonController.getStateProvince(this.state.selectfields.selCountryId).then(jsonState => {
                    
                    this.setState({                           
                        StateProvince : JSON.parse(JSON.stringify(jsonState))
                    });
                    //set default value 
                    //console.log("StateProvince: ", this.state.StateProvince)
                    let selectfields = this.state.selectfields;
                    selectfields['selStateProvinceId'] = "-1"
                    modal["modalSpinnerShow"] = false;
                    this.setState({selectfields, modal});
                }).catch(error => {
                    modal["modalSpinnerShow"] = false;
                    this.setState({modal});
                    console.error("There was an error loading States..., " + error)
                });
            }
        }
    
        if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER || this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL)
        {
            fields["callLetters"] = "";
        }

        if(e.currentTarget.id === 'createNewTraveler'){
            modal['modalTATitle'] = 'eTA - Add New Traveler On-The-Fly';
            modal["modalBody"] = "save";
            fields["lblName"] = "";
            fields["lblType"] = "";
            fields["lblComments"] = "";
            fields["lblFormOnFile"]=''; 
            fields["lblRestrictions"]='';
            fields["lblOpCo"]='';
            fields["lblSumbitter"]=""

            checkfields["manualResv"] = false;
            checkfields['chkDirectorHeadApproval'] = false;

            if(fields["resv"] === '999999'){
                fields["resv"]='';
            }
        }
        else if(e.currentTarget.id === 'edit'){
            
            modal['modalTATitle'] = (sessionStorage.getItem("ta_car_service_only") === "true")?'eTA - Edit Traveler to CA':'eTA - Edit Traveler to TA';
            modal["modalBody"] = "edit";
            //console.log("MAP begin travelers: ", this.state.Travelers)
            var lstTravelers = this.state.Travelers.filter(t=>t.travellerId == travellerId);
            //console.log("MAP begin lstTravelers: ", lstTravelers)
            lstTravelers.map((prop, key)=>{
                fields["lblName"] = prop["travellerName"];
                fields["lblType"] = prop["type"];
                fields["travellerDetailId"] = prop["travellerId"];
                fields["lblComments"] = prop["comments"];
                fields["lblFormOnFile"]=(prop["travelFormOnFile"])?1:0; 
                fields["lblRestrictions"]=prop["travelRestrictions"];
                fields["resv"] = prop["RLN"];
                fields["SSNo"] = prop["SSN"];
                fields["address1"] = prop["Address1"];
                fields["address2"] = prop["Address2"];
                fields["city"] = prop["City"];
                selectfields["selCountryId"] = prop["CountryId"];
                selectfields["selStateProvinceId"] = prop["StateProvinceId"];
                fields["stateProvinceId"] = prop["StateProvince"];
                fields["postalCode"] = prop["Zip"];
                fields["phone"]=prop["Phone"]
                fields["lblOpCo"]=prop["OpCo"]
                fields["lblSumbitter"]=prop["Sumbitter"]
                fields["firstName"] = prop["FirstName"]
                fields["lastName"] = prop["LastName"]
                fields["reason"]=prop["reason"];
                fields["callLetters"]=prop["CallLetters"];

                optionfields["rdoIssuer1"] = false;
                optionfields["rdoIssuer2"] = false;
                if (prop["Issuer"] === 1){
                    optionfields["rdoIssuer1"] = true;
                }
                else
                {
                    optionfields["rdoIssuer2"] = true;
                }

                optionfields["rdoRadioLetterYes"] = false;
                optionfields["rdoRadioLetterNo"] = false;
                optionfields["rdoRadioLetterNA"] = false;
                if (prop["TravelerType"] === Const.TYPE_CONTEST_WINNER){
                    if (prop["RadioLetterReceived"] === "Y"){
                        optionfields["rdoRadioLetterYes"] = true
                    }else if (prop["RadioLetterReceived"] === "N"){
                        optionfields["rdoRadioLetterNo"] = true;
                    }
                    else{
                        optionfields["rdoRadioLetterNA"] = true;
                    }
                }
                else
                {
                    optionfields["rdoRadioLetterNA"] = true;
                }

                optionfields["rdoOverrideYes"] = false;
                optionfields["rdoOverrideNo"] = false;

                if (prop["TravelerType"] === Const.TYPE_CONTEST_WINNER){
                    if(prop["OverrideReceived"]){
                        optionfields["rdoOverrideYes"] = true;
                    }
                    else
                    {
                        optionfields["rdoOverrideNo"] = true;
                    }
                }
                
                if (prop["TravelerType"] === Const.TYPE_RADIO_PERSONNEL){
                    if(prop["PreapprovalReceived"])
                    {
                        optionfields["rdoOverrideYes"] = true;
                    }
                    else
                    {
                        optionfields["rdoOverrideNo"] = true;
                    }
                }
               
                if(prop["TravelerType"] === Const.TYPE_OVERHEAD || prop["TravelerType"] === Const.TYPE_ARTIST){
                    optionfields["rdoOverrideNo"] = true;
                }

                //console.log("MAP travel Type Id:", prop["TravelerType"]);
                travelerTypeId = prop["TravelerType"];

                //load stateprovince
                if(travelerTypeId === Const.TYPE_CONTEST_WINNER)
                {
                    let modal = this.state.modal;
                    modal["modalSpinnerShow"] = true;
                    
                    let commonController = new CommonController();
                    commonController.getStateProvince(prop["CountryId"]).then(jsonState => {
                        
                        this.setState({                           
                            StateProvince : JSON.parse(JSON.stringify(jsonState))
                        });
                        //set default value 
                        //console.log("StateProvince: ", this.state.StateProvince)
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error loading States..., " + error)
                    });
                }
            });
            if(travelerTypeId > 0){
                this.setState({travelerTypeId:travelerTypeId})
            }
            if(fields["resv"] === '999999'){
                checkfields["manualResv"] = true;
            }
            //console.log("Edit -> Traveller Type: ", this.state.travelerTypeId)
        }
        else { //add
            
            modal['modalTATitle'] = (sessionStorage.getItem("ta_car_service_only") === "true")?'eTA - Add Traveler to CA':'eTA - Add Traveler to TA';;
            modal["modalBody"] = "add";
            fields["lblName"] = e.currentTarget.children[0].innerText;
            fields["lblType"] = e.currentTarget.children[1].innerText;
            fields["travellerId"] = e.currentTarget.children[2].innerText;
            fields["lblComments"] = e.currentTarget.children[3].innerText;
            fields["lblFormOnFile"]=e.currentTarget.children[4].innerText; 
            fields["lblRestrictions"]=e.currentTarget.children[5].innerText;
            fields["lblOpCo"]=e.currentTarget.children[6].innerText;
            fields["lblSumbitter"]=e.currentTarget.children[7].innerText;
            var name = fields["lblName"].split(',');
            fields["firstName"] = this.splitCamelCaseToString(name[1].trim());
            fields["lastName"] = this.splitCamelCaseToString(name[0].trim());
            fields["reason"]= (this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?"Contest Winner":"";        
            
            checkfields["manualResv"] = false;
            checkfields['chkDirectorHeadApproval'] = false;

            if(fields["resv"] === '999999'){
                fields["resv"]='';
            }
        }
        
        modal['modalTAShow']=true;

        this.setState({modal,fields,checkfields,optionfields,selectfields});     
    }

    handleModalClose = (e : any) => {
        let modal = this.state.modal;
        let loading = this.state.loading;
        modal['modalShow'] = false;
        modal['loading'] = false;
        this.setState({ modal, loading })
    }

    handleModalTAClose = (e : any) => {
        let modal = this.state.modal;
        let loading = this.state.loading;
        modal['modalTAShow'] = false;
        modal['loading'] = false;
        this.setState({ modal, loading })
    }

    showModalFooterButton = (loading) => {
        if(this.state.modal.modalBody === "save") {
            return <div style={{width: "100%"}}>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6} style={{textAlign:"center"}}>
                        <Button style={{height: 'auto'}} size="sm" id="save" name="save" onClick={(e) => this.handleButtonSearch(e)}>Save</Button>
                        <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="saveCancel" name="saveCancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </div>
        }
        else if(this.state.modal.modalBody === "add") {
            return <div style={{width: "100%"}}><Row>
            <Col md={3}></Col>
            <Col md={6} style={{textAlign:"center"}}>
                <Button style={{height: 'auto'}} size="sm" id="add" name="add" onClick={(e) => this.handleButtonSearch(e)}>Add</Button>
                <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="addCancel" name="addCancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
            </Col>
            <Col md={3}></Col>
        </Row></div>
        }
        else
        {
            return <div style={{width: "100%"}}><Row>
            <Col md={3}></Col>
            <Col md={6} style={{textAlign:"center"}}>
                <Button style={{height: 'auto'}} size="sm" id="edit" name="edit" onClick={(e) => this.handleButtonSearch(e)}>Save</Button>
                <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="editCancel" name="editCancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
            </Col>
            <Col md={3}></Col>
        </Row></div>
        }
    }

    showModalBody = () => {
        //Traveller, Artist, Radio, ContestWinner
        if(this.state.modal.modalBody === "save"){
            return <div id="divTravelerSave">
                <Row>
                    <Col md={6}>
                        <FormInputs
                            properties={{id:"firstName",label:"First Name",type:"text",name:"firstName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.firstName,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={true}
                            errorMsg={this.state.errors.firstName}
                        />
                    </Col>
                    <Col md={6}>
                        <FormInputs
                            properties={{id:"lastName",label:"Last Name",type:"text",name:"lastName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.lastName,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={true}
                            errorMsg={this.state.errors.lastName}
                        />
                    </Col>
                </Row>
                {/*Artist Or Contest Winner*/}
                {((this.state.travelerTypeId === Const.TYPE_ARTIST || this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER || this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL) && parseInt(sessionStorage.getItem("ta_expense_type_id")!) !==  Const.OVERHEAD_TA)?
                <Row>
                     <Col sm={8}>
                        <Form.Group>
                        <Form.Label>Any Travel Restrictions</Form.Label>
                            <Form.Control as="textarea" rows={3} id="comments" name="comments" className="form-control" value={this.state.fields.comments} maxLength={40} onChange={this.handleChange} ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}></Col>
                </Row>:
                    ""
                }
            </div>
        }
        else{
            return <div id="divTravelerAdd">
                    <Row>
                        <Col md={6}><div className="text-dark font-size-xs small">Name: <b>{this.state.fields.lblName}</b></div></Col>
                        <Col md={6}><div className="text-dark font-size-xs small">Type: <b>{this.state.fields.lblType}</b></div></Col>
                    </Row>
                    <Row>
                        <Col md={12}><div style={{marginTop:"5px"}} className="text-dark font-size-xs small">Comments: <b>{this.state.fields.lblComments}</b></div></Col>
                    </Row>
                </div>
        }
    }
    
    handleButtonSearch=(e:any)=>{
        //console.log("****************checkfields***************")
        //console.log("Checkfields:",this.state.checkfields)
        //console.log("optionfields:",this.state.optionfields)
        let modal = this.state.modal;
        var data = {}
        var eventCurrentTargetId = e.currentTarget.id;
        if (e.currentTarget.id === "saveCancel" || e.currentTarget.id === "addCancel" || e.currentTarget.id === "editCancel"){
            modal["modalTAShow"] = false;
        }
        else if (e.currentTarget.id === "cancel"){
            modal["modalTAShow"] = false;
            modal["modalShow"] = false;
        }
        else{
            if(e.currentTarget.id === 'add' || e.currentTarget.id === 'save' || e.currentTarget.id === 'edit'){
                var hasRadioLetter='';
                var action = (e.currentTarget.id === 'edit'?'edit':'insert')
                var travellerDetailId = (e.currentTarget.id === 'edit'?this.state.fields.travellerDetailId:'')
                var notes = '';
                //comments
                if (parseInt(sessionStorage.getItem("ta_expense_type_id")!) !== Const.OVERHEAD_TA){ //Session("ta_expense_type_id")) <> OVERHEAD_TA
                    if (e.currentTarget.id === 'add'){
                        notes = this.state.fields.lblComments;
                    }else{
                        notes = this.state.fields.comments; 
                    }
                }
                else
                {
                        notes = this.state.fields.lblComments;
                }
                var submitter = (e.currentTarget.id === 'add')?this.state.fields.lblSumbitter:this.state.fields.submitter;
                var opCo = (e.currentTarget.id === 'add')?this.state.fields.lblOpCo:sessionStorage.getItem("label_id");
                //hasRadioLetter
                if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER){
                    if(this.state.optionfields.rdoRadioLetterYes){
                        hasRadioLetter = 'Y';
                    }
                    if(this.state.optionfields.rdoRadioLetterNo){
                        hasRadioLetter = 'N';
                    }
                    if(this.state.optionfields.rdoRadioLetterNA){
                        hasRadioLetter = 'N/A';
                    }
                }
                else
                {
                    hasRadioLetter = 'N/A';
                }
                //travelRestrictions
                var travelRestrictions = '0';
                if(e.currentTarget.id === 'save'){ //create new traveler
                    //(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?'2':(parseInt(sessionStorage.getItem("ta_expense_type_id")!) !== Const.OVERHEAD_TA)?'2':'1',
                    travelRestrictions = (parseInt(sessionStorage.getItem("ta_expense_type_id")!) !== Const.OVERHEAD_TA)?'2':'1';
                }
                else
                {
                    travelRestrictions = this.state.fields.lblRestrictions;                    
                }
                data = {
                    action: action,
                    travellerId: travellerDetailId,
                    authreqId: this.state.fields.sessionTA,
                    firstName: this.state.fields.firstName,
                    lastName: this.state.fields.lastName,
                    name: this.state.fields.firstName + " " + this.state.fields.lastName,
                    reason: this.state.fields.reason,
                    seniorExecApproval: this.state.checkfields.chkDirectorHeadApproval,
                    contestWinner:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?'1':'0',
                    SSN:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?(this.state.fields.SSNo.indexOf("-") > 0)?this.state.fields.SSNo:this.state.fields.SSNo.substring(0, 3) + '-' + this.state.fields.SSNo.substring(5, 3) + '-' + this.state.fields.SSNo.substring(9, 5):'',
                    fedID:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.fields.fedID:'',
                    countryId:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.selectfields.selCountryId:'',
                    address1:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.fields.address1:'',
                    address2:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.fields.address2:'',
                    city:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.fields.city:'',
                    provinceStateId:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?(this.state.StateProvince.length > 0)?this.state.selectfields.selStateProvinceId:'':'',
                    provinceState:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?(this.state.StateProvince.length > 0)?'':this.state.fields.stateProvinceId:'',
                    zip:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.fields.postalCode:'',
                    phone:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.state.fields.phone:'',
                    notes:notes,
                    formOnFile:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER || this.state.fields.travelerTypeId === Const.TYPE_ARTIST)?'0':'',
                    travelRestrictions:travelRestrictions,
                    travellerType: this.state.travelerTypeId,
                    submitter:submitter,
                    opCo: opCo,
                    issuer:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?(this.state.optionfields.rdoIssuer1)?'1':'2':'0',
                    callLetters:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER || this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL)?this.state.fields.callLetters:'',
                    hasRadioLetter:hasRadioLetter,
                    hasOverride:(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?(this.state.optionfields.rdoRadioLetterNo)?(this.state.optionfields.rdoOverrideYes)?'1':'0':'0':'0',
                    hasPreapproval:(this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL)?(this.state.optionfields.rdoOverrideYes)?'1':'0':'0',
                    RLN: this.state.fields.resv
                    }
            }
            //console.log("Traveler input data :", data);
        }
       
       if(e.currentTarget.id === "search"){
            var firstName = null;
            var lastName = null;
            if(this.state.fields.searchFirstName.trim().length !== 0){
                firstName = this.state.fields.searchFirstName
            }
            if(this.state.fields.searchLastName.trim().length !== 0){
                lastName = this.state.fields.searchLastName
            }
            let modal = this.state.modal;
            modal["modalSpinnerShow"] = true;
            let travellerController = new TravellerController();
            travellerController.etaTravelerSearch(firstName,lastName,null,this.state.travelerTypeId,this.state.fields.sessionTA).then(jsonTraveller => {
                //console.log("etaTravelerSearch:", jsonTraveller)
                this.setState({                           
                    listTravelers : JSON.parse(JSON.stringify(jsonTraveller)),
                });
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
            }).catch(error => {
                modal["modalSpinnerShow"] = false;
                this.setState({modal});
                console.error("There was an error!", error)
            })
        }
        else if(e.currentTarget.id === "add" || e.currentTarget.id === "edit"){
            if(this.validateForm()){
                let travellerController = new TravellerController();
                
                travellerController.addTravellerDetails(data).then(response => {
                    
                    //console.log(response);
                    //console.log("Traveler added to TA: ", this.state.fields.sessionTA)
                    //alert("Traveler added to TA:" + this.state.fields.sessionTA);
                    if (eventCurrentTargetId === "add"){
                        travellerController.getTravellerInfo(this.state.fields.travellerId).then(response => {
                            
                            //console.log("getTravellerInfo", response);
                            if(response.success){
                                this.loadTraveller();
                            }
                        }).catch(error => {
                            console.error("There was an error!", error)
                        })
                    }
                    else
                    {
                        this.loadTraveller();
                    }
                    let modal = this.state.modal;
                    let loading = this.state.loading;
                    modal['modalShow'] = false;
                    modal['modalTAShow'] = false;
                    modal['loading'] = false;
                    this.setState({ modal, loading })
                }).catch(error => {
                    console.error("There was an error!", error)
                })
            }
        }
        else if(e.currentTarget.id === "save"){
            if(this.validateForm()){
                let travellerController = new TravellerController();
                var newTraveller = {
                    firstName: this.state.fields.firstName,
                    lastName: this.state.fields.lastName,
                    type: this.state.travelerTypeId,
                    notes: this.state.fields.comments,
                    submitter: sessionStorage.getItem("name"),
                    opCo: sessionStorage.getItem("label_id")
                }
                travellerController.insertTravelerOnTheFly(newTraveller).then(response => {
                    if(response.p_ErrorCode > 0){
                        if(response.p_ErrorCode === 1){
                            alert("The traveler you are trying to add already exists in the eTA system.")
                        }
                        else{
                            alert("Unable to add new traveler.")
                        }
                    }
                    else{
                        let fields = this.state.fields;
                        fields["travellerId"] = response.p_TravelerID;
                        this.setState({fields});
                        travellerController.addTravellerDetails(data).then(response => {
                            //console.log("addtraveller",response);
                            //alert("Traveler created and added to TA:" + this.state.fields.sessionTA);
                            travellerController.getTravellerInfo(this.state.fields.travellerId).then(response => {
                                //console.log("getTravellerInfo", response);
                                if(response.success){
                                    this.loadTraveller();
                                }
                            }).catch(error => {
                                console.error("There was an error!", error)
                            })
                            let modal = this.state.modal;
                            let loading = this.state.loading;
                            modal['modalShow'] = false;
                            modal['modalTAShow'] = false;
                            modal['loading'] = false;
                            this.setState({ modal, loading })
                        }).catch(error => {
                            console.error("There was an error!", error)
                        })
                    }
                }).catch(error => {
                    console.error("There was an error!", error)
                })
            }
        }
        else if(e.currentTarget.id === "clear"){
            this.clearControl();
        }
        this.setState({modal});
    }

    searchLastNameStartsWith = (c) => {
        let modal = this.state.modal;
        modal["modalSpinnerShow"] = true;
        let travellerController = new TravellerController();
        travellerController.etaTravelerSearch(null,null,c,this.state.travelerTypeId,this.state.fields.sessionTA).then(jsonTraveller => {
            //console.log("etaTravelerSearch:", jsonTraveller)
            this.setState({                           
                listTravelers : JSON.parse(JSON.stringify(jsonTraveller)),
            });
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
        }).catch(error => {
            modal["modalSpinnerShow"] = false;
            this.setState({modal});
            console.error("There was an error!", error)
        })
    }
    async travelerInfoFormRequired(authReqId){
        let commonController = new CommonController();
        return await commonController.getTravelerInfoFormRequired(authReqId).then(response => {
            //
            return response.Travel_Form;
        }).catch(error => {
            console.error("There was an error!", error)
        });
    }
    async loadTraveller(){
        let fields = this.state.fields;
        let travellerController = new TravellerController();
        await travellerController.getTravellersForTA(this.state.fields.sessionTA).then(jsonTraveller => {
            //
            this.setState({
                Travelers: JSON.parse(JSON.stringify(jsonTraveller))
            })
            //get duplicate RLN, if any
            fields['bDupRLN'] = this.duplicateRLN();
            this.setState({
                jsonTraveller: JSON.parse(JSON.stringify(jsonTraveller)),fields
            })
            //console.log("Traveler:",this.state.Travelers);
            //Load RLN
            var jsonRLN = this.state.jsonTraveller;
            this.state.RLN.splice(0, this.state.RLN.length);
            jsonRLN.map((prop,key) => {
                this.state.RLN.push({"RLN": prop['RLN']});
            })
            //console.log("RLN", this.state.RLN)
            this.state.jsonTraveller.map((prop,key) =>{
                const elMsg:HTMLElement = document.getElementById('resultMsg'+key) as HTMLElement;
                const elAcceptName:HTMLOptionElement = document.getElementById('BCDAcceptName'+key) as HTMLOptionElement;
                const elNoRetrieve:HTMLOptionElement = document.getElementById('BCDNoRetrieve'+key) as HTMLOptionElement;
                var bcd = (prop["BCDResults"] === null)?"":prop["BCDResults"];  
                
                if(bcd.length > 0){    
                    if(prop["BCDResults"].indexOf("BCD NAME:") !== -1){
                        elMsg.style.color = "red";
                        elAcceptName.disabled = false; // enable if name match exception error
                        elNoRetrieve.disabled = true; 
                    }
                    else if(prop["BCDResults"].indexOf("Invalid travel reservation data received") !== -1){
                        elMsg.style.color = "red";
                        elAcceptName.disabled = true;
                        elNoRetrieve.disabled = false; 
                    }
                    else if(prop["BCDResults"] === "Successfully Retrieved"){
                        elMsg.style.color = "navy";
                        elAcceptName.disabled = true;
                        elNoRetrieve.disabled = false; 
                    }
                    else if(prop["BCDResults"] === "Not retrieved"){
                        elAcceptName.disabled = false; 
                        elNoRetrieve.disabled = false; 
                    }
                    else if(bcd.indexOf("The eTA traveler name does not exactly match") === -1){
                        elAcceptName.disabled = false; 
                        elNoRetrieve.disabled = true; 
                    }
                    else //|| prop.RLN === Const.temporaryRLN
                    {
                        elAcceptName.disabled = true; // disable check box 
                        elNoRetrieve.disabled = false; 
                    }  
                } 
                else
                {
                    elAcceptName.disabled = true; // disable check box 
                }               
            });
        }).catch(error => {
            //console.log("There was an error...", error)
        })
        //await this.checkTravellerDetailsDuplicatsByRLN(this.state.fields.sessionTA!);
    }
    async ConfirmAccept(e,index)
    {
        const {name, value} = e.currentTarget;
        const Travelers = [...this.state.Travelers];
        // if accept name check box is checked, show confirm message
        if(e.currentTarget.checked)
        {
            var flag = await confirm("Are you sure you want to accept differences in eTA vs. CTM Traveler Names?",{title:"Confirm Accept", okText:"Yes", cancelText:"No"})
            // if ok 
            //if(!window.confirm("")){
            if(!flag){
                
                Travelers[index][name] = false; //uncheck 
                
                //console.log("confirm alert", false)
            }
            else
            {
                Travelers[index][name] = !Travelers[index][name];
                //console.log("confirm alert", !Travelers[index][name])
            }
        }
        else
        {
            Travelers[index][name] = !Travelers[index][name];
        }
        this.setState({ Travelers });
        //console.log("ConfirmAccept:", this.state.Travelers)
        
    }
    displayTravelerSearch=()=>{
        return <div id="divTravelerSearch">
                <Row>
                    <Col md={6}>
                        <FormInputs
                            properties={{id:"searchFirstName",label:"First Name",type:"text",name:"searchFirstName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.searchFirstName,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                        />
                    </Col>
                    <Col md={6}>
                        <FormInputs
                            properties={{id:"searchLastName",label:"Last Name",type:"text",name:"searchLastName",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.searchLastName,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4} style={{textAlign:"center"}}>
                        <Button style={{height: 'auto'}} size="sm" id="search" name="search" onClick={(e) => this.handleButtonSearch(e)}>Search</Button>
                        <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="clear" name="clear" onClick={(e) => this.handleButtonSearch(e)}>Clear</Button>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col md={12} style={{textAlignLast: "center"}}><div className="text-dark font-size-xs small">Click on the first Letter of the Last Name to list Travelers</div></Col>
                </Row>
                <Row>
                    <Col md={12} className="small" style={{textAlignLast: "center"}}>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("A")}><a>A</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("B")}><a>B</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("C")}><a>C</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("D")}><a>D</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("E")}><a>E</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("F")}><a>F</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("G")}><a>G</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("H")}><a>H</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("I")}><a>I</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("J")}><a>J</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("K")}><a>K</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("L")}><a>L</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("M")}><a>M</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("N")}><a>N</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("O")}><a>O</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("P")}><a>P</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("Q")}><a>Q</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("R")}><a>R</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("S")}><a>S</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("T")}><a>T</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("U")}><a>U</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("V")}><a>V</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("W")}><a>W</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("X")}><a>X</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("Y")}><a>Y</a></NavLink>
                        <NavLink to={"#"} style={{margin:"3px"}} onClick={() => this.searchLastNameStartsWith("Z")}><a>Z</a></NavLink>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col md={12}>
                    <div className="scroll" style={{ width: '100%',height: "250px" }}>
                    <Table hover id="TblTraveler">
                        <thead>
                        <tr>
                            <th>Traveler Name</th>                                                     
                            <th>Type</th>                                                          
                        </tr>
                        </thead>
                        {this.state.listTravelers.length === 0 ? 
                            <tbody>
                                <tr>
                                    <td colSpan={8} align="center" className="text-danger font-size-xs small">No match records. Please try again.</td>
                                </tr>
                            </tbody>
                        :
                            <tbody>
                            {this.state.listTravelers.map((prop, key) => 
                                <tr id="add" onClick={(e) => this.handleModalTAShow(e, '')}>
                                    <td>{prop["name"]}</td>
                                    <td>{prop["type"]}</td>
                                    <td hidden>{prop["id"]}</td>
                                    <td hidden>{prop["comments"]}</td>
                                    <td hidden>{(prop["travelFormOnFile"])?1:0}</td>
                                    <td hidden>{prop["travelRestrictions"]}</td>
                                    <td hidden>{prop["opCo"]}</td>
                                    <td hidden>{prop["submitter"]}</td>
                                    <td hidden>{prop["formOnFile"]}</td>
                                    <td hidden>{prop["restrictions"]}</td>
                                </tr>
                            )}
                            </tbody>
                        }
                    </Table>
                    </div>
                    </Col>
                </Row>
            </div>
    }

    handleChange(e:any){
        
        let fields= this.state.fields;
        var eCurrentTargetName = e.currentTarget.name;
        if(eCurrentTargetName === 'projectName'){
            var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/)
            if (regexp.test(e.currentTarget.value)){
                fields[e.currentTarget.name] = e.currentTarget.value;
            }
            else{
                alert("Project Name must be alphanumeric only...")
            }
        }
        else if(eCurrentTargetName === 'firstName' || eCurrentTargetName === 'lastName'){
            fields[e.currentTarget.name] =e.currentTarget.value
        }
        else if(eCurrentTargetName === 'resv'){
            fields[e.currentTarget.name] = e.currentTarget.value.toUpperCase();
        }
        else{
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        this.setState({ fields });
    }
    //Apply CamelCase (/(?=[A-Z])/)
    splitCamelCaseToString(s) {
        return s.split(/([A-Z][a-z]+)/).map(function(p) {
            return p.charAt(0).toUpperCase() + p.slice(1);
        }).join(' ');
    }
    handleSelectChange(e: any) {
        let selectfields = this.state.selectfields;
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        var eveName = e.currentTarget.name;
        if(eveName === 'travelAgentId'){
            selectfields["travelAgentName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        if(eveName === 'expenseTypeId'){
            selectfields["expenseTypeName"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
        }
        if(eveName === 'selCountryId'){
            let commonController = new CommonController();
            var countryId = e.currentTarget.options[e.currentTarget.selectedIndex].value;
            //console.log("StateProvince input:", countryId)
            selectfields['selCountryId'] = countryId;
            
            if(countryId !== "-1"){
                let modal = this.state.modal;
                modal['modalSpinnerShow']=true;
                commonController.getStateProvince(countryId).then(jsonState => {
                    this.setState({                           
                        StateProvince : JSON.parse(JSON.stringify(jsonState))
                    });
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal });
                    //console.log("StateProvince: ", this.state.StateProvince)
                }).catch(error => {
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal });
                    console.error("There was an error loading States..., " + error)
                });
            }            
        }
        if (eveName === 'selStateProvinceId'){
            selectfields["selStateProvinceId"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
        }
        this.setState({ selectfields });
        //console.log("Select Fields onchange: ", this.state.selectfields)
    }

    handleCheckChange(e, index){
        //
        //console.log(e.currentTarget);
        const {name, value} = e.currentTarget;
        const Travelers = [...this.state.Travelers];
        Travelers[index][name] = !Travelers[index][name];
        this.setState({ Travelers });
        //console.log("handleCheckChange:", this.state.Travelers)
    }
	handleCheckChangeEvent=(e:any)=>{
        
        let checkfields = this.state.checkfields;
        let fields = this.state.fields;
        let errors = this.state.errors;
        if (e.currentTarget.name === "manualResv") {
            checkfields[e.currentTarget.name] = !this.state.checkfields.manualResv
        }
        if (checkfields["manualResv"]){
            fields["resv"] = '999999';
            errors["resv"] = ""
        }
        else
        {
            fields["resv"] = "";
        }
        if(e.currentTarget.name === "chkDirectorHeadApproval"){
            checkfields[e.currentTarget.name] = !this.state.checkfields.chkDirectorHeadApproval
        }
        this.setState({
            checkfields, fields, errors
        });

    }
    handleOptionChange(e:any){
        
        let optionfields = this.state.optionfields;
        let currentTargetId = e.currentTarget.id;
        if (currentTargetId === "employeeTravel") {
            optionfields['employeeTravel'] = true;
            optionfields['conventionProject'] = false;
        }
        if (currentTargetId === "conventionProject") {
            optionfields['employeeTravel'] = false;
            optionfields['conventionProject'] = true;
        }
        if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER || this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL){
            if (currentTargetId === 'rdoOverrideYes'){
                optionfields["rdoOverrideYes"] = true;
                optionfields["rdoOverrideNo"] = false;
            }
            if (currentTargetId === 'rdoOverrideNo'){
                optionfields["rdoOverrideNo"] = true;
                optionfields["rdoOverrideYes"] = false;
            }
        }
        if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER){
            if (currentTargetId === 'rdoRadioLetterYes'){
                optionfields["rdoRadioLetterYes"] = true;
                optionfields["rdoRadioLetterNo"] = false;
                optionfields["rdoRadioLetterNA"] = false;
                //const elrdoOverride:HTMLElement = document.getElementById('trOverride') as HTMLElement;    
                //elrdoOverride.style.display="none";
            }
            if (currentTargetId === 'rdoRadioLetterNo'){
                optionfields["rdoRadioLetterNo"] = true;
                optionfields["rdoRadioLetterYes"] = false;
                optionfields["rdoRadioLetterNA"] = false;  
                //const elrdoOverride:HTMLElement = document.getElementById('trOverride') as HTMLElement;    
                //elrdoOverride.style.display="block";
                optionfields["rdoOverrideYes"] = true;
                optionfields["rdoOverrideNo"] = false;
            }
            if (currentTargetId === 'rdoRadioLetterNA'){
                optionfields["rdoRadioLetterNA"] = true;
                optionfields["rdoRadioLetterNo"] = false;
                optionfields["rdoRadioLetterYes"] = false;
                //const elrdoOverride:HTMLElement = document.getElementById('trOverride') as HTMLElement;    
                //elrdoOverride.style.display="none";
            }    
            if (currentTargetId === 'rdoIssuer1'){
                optionfields['rdoIssuer1'] = true;
                optionfields['rdoIssuer2'] = false;
            }
            if (currentTargetId === 'rdoIssuer2'){
                optionfields['rdoIssuer2'] = true;
                optionfields['rdoIssuer1'] = false;
            }
        }
        this.setState({ optionfields });
        //console.log("Option Events: ",this.state.optionfields)
    }
    checkSpecialChar(e:any){
        if (!((e.keyCode >= 65) && (e.keyCode <= 90) || (e.keyCode >= 97) && (e.keyCode <= 122) || (e.keyCode >= 48) && (e.keyCode <= 57) || (e.keyCode === 32))) {
        e.returnValue = false;
        return;
        }
        e.returnValue = true;
    }

    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Traveler':
                this.props.history.push('/admin/traveller');
                break;
            case 'Air / Rail':
                this.props.history.push('/admin/airrail');
                break;
            case 'Hotel':
                this.props.history.push('/admin/hotel');
                break;
            case 'Rental Car':
                this.props.history.push('/admin/rentalcar');
                break;
            case 'Car Service':
                this.props.history.push('/admin/carservice');
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:""}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/decision',state:{from:"tahistory"}});
                break;
        }
    }

    onSubmit (e:any) {
        e.preventDefault();
        this.setState({ loading: true });
    }

    showSubmitterMsg = () => {
        if(this.state.modal.modalBody === "save") {
            return <Row style={{marginTop: "5px", visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible"}}>
                <Col md={12} style={{textAlign: "justify"}}><div className="text-dark font-size-xs small">Submitter:  Only enter Resv # 999999 when advised by CTM Travel.
				(Applies to reservations for carriers such as Jet Blue, Air Tran,
				Virgin America, Spirit, Amtrak, etc.)
				Reservations for 999999 must be entered manually.
				CTM Travel will update to correct Resv. # upon Confirmation.</div></Col>
            </Row>
        }
        else {
            return <Row style={{visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible"}}>
                <Col md={12} style={{textAlign: "justify", marginTop:"10px"}}><div className="text-dark font-size-xs small">Submitter:  Enter 999999 when you are advised by CTM Travel for non-GDS bookings 
				-or- when you only need to send CTM Travel an update on an existing reservation 
				(hotel extension, change fee on air booking or if the web server is down).</div></Col>
                </Row>
        }

    }

    btnArtist(loading, typeId){
        return <Button id="traveler" disabled={loading} style={{float:"left",height:'40px',border: '2px solid',backgroundColor:'transparent',borderColor: '#1D62F0',color:'#1D62F0',cursor:'pointer'}} onClick={(e) => this.handleModalShow(e, typeId)}>
            {loading && (
            <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px",color:"#487afa" }}
            />)}
            {!loading && <span>Artist<FontAwesomeIcon style={{marginLeft:"5px"}} icon={faSearch} size="1x"/></span>}
        </Button>
    }
    //create button
    btnSearchFor(loading,type,typeId){
        //
        let buttonAlign = {}
        if(typeId === Const.TYPE_OVERHEAD){
            buttonAlign = {
                width: "120px"
            };
        }
        else if(typeId === Const.TYPE_CONTEST_WINNER){
            buttonAlign = {
                width: "160px"
            };
        }
        else if(typeId === Const.TYPE_RADIO_PERSONNEL){
            buttonAlign = {
                width: "160px",
                marginLeft: "20px"
            };
        }
        else
        {
            buttonAlign = {
                marginLeft: "20px",
                width: "95px"
            };
        }
        return <Button id="traveler" disabled={loading} className="searchButton" style={buttonAlign} onClick={(e) => this.handleModalShow(e, typeId)}>
            {loading && (
            <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px",color:"#487afa" }}
            />)}
            {!loading && <span>{type}<FontAwesomeIcon style={{marginLeft:"5px"}} icon={faSearch} size="1x"/></span>}
        </Button>
    }
    parseHTML(el){
        
        if (el !== undefined && el !== null){
            var arr = el.split("\n");
            //console.log("parseHTML Array :", arr)
            var str="";
            arr.forEach(element => {
                str = str + "<div>"+element+"</div>" 
            });
            //console.log("parse HTML:",str);
            return parse(str);
        }
        else
        {
            return el;
        }
    }
    FormTravelersTable()
    {
        if(this.state.fields.blnReturn){
            return <><Row>
                        <Col sm={12}>
                            <div style={{width: '100%', height: "auto"}}>
                                <Table id="tblXML" hover>                    
                                    <thead>
                                        <tr>
                                            {new Variables().thTravelersArray.map((prop, key) => {
                                                return <th key={key}>{prop}</th>;
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Travelers.map((prop,key) => {
                                            return <tr>
                                            <td>{prop["travellerName"]} <input id={"tId"+key} type="hidden" value={prop["travellerId"]} /><input id={"tType"+key} type="hidden" value={prop["TravelType"]} /></td>
                                            <td>{prop["RLN"]}</td>
                                            <td>{prop["reason"]}</td>
                                            <td></td>
                                            <td>
                                                <Form.Check type="checkbox" label="" name="BCDAcceptName" id={"BCDAcceptName" + key} onChange={(e)=>this.handleCheckChange(e, key)} checked={prop["BCDAcceptName"] === null ? false : prop["BCDAcceptName"]} />
                                            </td>
                                            <td>
                                                <Form.Check type="checkbox" label="" name="BCDNoRetrieve" id={"BCDNoRetrieve" + key} onChange={(e)=>this.handleCheckChange(e, key)} checked={prop["BCDNoRetrieve"] === null ? false : prop["BCDNoRetrieve"]} />
                                            </td>
                                            <td>
                                                <Button variant = "warning" style={{height: 'auto', marginRight: '5px'}} size="sm" id="edit" name="edit" onClick={(e) => this.handleModalTAShow(e, prop["travellerId"])}>Edit</Button>
                                                <Button variant = "danger" style={{height: 'auto'}} size="sm" id="remove" name="remove" onClick={(e) => this.RemoveETAReservationsById(this.state.fields.sessionTA, prop["RLN"], prop["travellerId"])}>Remove</Button>
                                            </td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <table id="tableResultMessage" style={{display:"none",border:"0px",width:"95%"}} >
                        <tbody>
                            <tr >
                                <td style={{display:"none",height:"40px", alignSelf:"bottom", alignContent:"center",fontWeight:"bold", color:"green"}}>Results column must say (Successfully Retrieved) for all travelers on the TA before you proceed</td>
                            </tr>
                        </tbody>
                    </table>
                    </>
        }
        else
        {
            return <><Row>
                        <Col sm={12}>
                            <div style={{width: '100%', height: "auto"}}>
                                <Table id="tblXML" hover>                    
                                    <thead>
                                        <tr>
                                            {/*{new Variables().thTravelersArray.map((prop, key) => {
                                                return <th key={key}>{prop}</th>;
                                            })}*/}
                                            <th style={{textAlign:"left"}}>Name</th>
                                            <th style={{textAlign:"left"}}><span style={{ visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>Resv #</span></th>
                                            <th style={{textAlign:"left"}}>Reason For Travel</th>
                                            {(sessionStorage.getItem("ta_expense_type_id") === Const.MARKETING_TA.toString())?
                                                <th>Traveler Type</th>
                                                :
                                                (sessionStorage.getItem("has_dha") === "true" && sessionStorage.getItem("ta_expense_type_id") === Const.OVERHEAD_TA.toString())?
                                                    <th>Senior Exec Approval</th>
                                                    :
                                                    <th>&nbsp;</th>
                                            }
                                            <th style={{textAlign:"left",width:"30%"}}><span style={{ visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>Retrieve Resv Results</span></th>
                                            <th><span style={{ visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>Accept Name</span></th>
                                            <th><span style={{ visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>Don't re-retrieve</span></th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Travelers.map((prop,key) => {
                                            return <tr>
                                            <td style={{textAlign:"left"}}>{prop["travellerName"]} <input type="hidden" value={prop["travellerId"]} /><input  type="hidden" value={prop["TravelType"]} /></td>
                                            <td id="rln" style={{textAlign:"left", visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible"}}>{prop["RLN"]}</td>
                                            <td style={{textAlign:"left"}}>{prop["reason"]}</td>
                                            {(sessionStorage.getItem("ta_expense_type_id") === Const.MARKETING_TA.toString())?
                                                <td>{prop["type"]}</td>
                                                :
                                                (sessionStorage.getItem("has_dha") === "true" && sessionStorage.getItem("ta_expense_type_id") === Const.OVERHEAD_TA.toString())?
                                                    <td>{prop["DHA"]}</td>
                                                    :
                                                    <td>&nbsp;</td>
                                            }
                                            <td id={"resultMsg"+key} style={{textAlign:"left",width:"30%", visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>{prop["BCDResults"]}</td>
                                            <td id={"cbAccept"+key} style={{visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>
                                                <Form.Check type="checkbox" label="" name="BCDAcceptName" id={"BCDAcceptName" + key} onChange={(e) => this.ConfirmAccept(e, key)} checked={prop["BCDAcceptName"] === null ? false : prop["BCDAcceptName"]} />
                                            </td>
                                            <td id={"cbNoretrieve"+key} style={{visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible" }}>
                                                <Form.Check type="checkbox" label="" name="BCDNoRetrieve" id={"BCDNoRetrieve" + key} onChange={(e)=>this.handleCheckChange(e, key)} checked={prop["BCDNoRetrieve"] === null ? false : prop["BCDNoRetrieve"]} />
                                            </td>
                                            <td style={{textAlign:"right"}}>
                                                <Button variant = "warning" style={{height: 'auto', marginRight: '5px'}} size="sm" id="edit" name="edit" onClick={(e) => this.handleModalTAShow(e,prop["travellerId"])}>Edit</Button>
                                                <Button variant = "danger" style={{height: 'auto'}} size="sm" id="remove" name="remove" onClick={(e) => this.RemoveETAReservationsById(this.state.fields.sessionTA, prop["RLN"], prop["travellerId"])}>Remove</Button>
                                            </td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <table id="tableResultMessage" style={{display:"none",border:"0px",width:"95%"}} >
                        <tbody>
                            <tr >
                                <td style={{display:"none",height:"40px", alignSelf:"bottom", alignContent:"center",fontWeight:"bold", color:"green"}}>Results column must say (Successfully Retrieved) for all travelers on the TA before you proceed</td>
                            </tr>
                        </tbody>
                    </table>
                </>
        }
    }
    //Remove BCD generated travel reservations from eTA by Id values.
    async RemoveETAReservationsById(authReqId, rln, travelerId){
        
        //console.log("Delete Traveler:", travelerId)
        var flag = await confirm("Are you sure you want to remove this traveler?",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
        //if(window.confirm("Are you sure you want to remove this traveler?"))
        if (flag)
	    {
            let modal = this.state.modal;
            modal['modalSpinnerShow']=true;
            this.setState({modal});
            //Remove rows from eTA reservation for single reservation
            
            //console.log("Remove Traveler: 1");
            /// Remove rows from eTA reservation for retrieve from BCD
            let commonController = new CommonController();
            // remove traveller
            await commonController.deleteTraveller(authReqId,travelerId).then(async response => {
                
                //console.log("delete traveller:", response);
                // delete invalid air bookings
                await commonController.getBookingsBCD(authReqId,'Air').then(async response => {
                    //console.log("Remove Traveler: 2");
                    //console.log("getBookingsBCD Air:",response);
                    //console.log("Remove Traveler: 3");
                    
                    if (response !== undefined){
                        await response.recordset.map(async (prop,key)=>{
                            //console.log("Step: 3-a=>",key); 
                            
                            // get delete values
                            var rowRln = (prop.Record_Locator === null || prop.Record_Locator === "") ? '' : prop.Record_Locator;
                            var rowTravelerId = (prop.Traveller_ID === null || prop.Traveller_ID === "") ? -1 : prop.Traveller_ID;
                            //console.log("begin AirId_TraverllerId Air:",prop["Air_ID"] +'-'+rowTravelerId); 
                            
                            // determine if okay to remove
                            if (prop.BCD_generated && rowRln === rln && rowTravelerId === travelerId){
                                //console.log("AirId_TraverllerId Air:",prop["Air_ID"] +'-'+rowTravelerId); 
                                await this.DeleteBookings(prop.BCD_generated, prop.Air_ID,'Air', authReqId, rln);
                            }
                        });
                    }
                });
                //console.log("Remove Traveler: 4");
                // delete invalid hotel bookings
                await commonController.getBookingsBCD(authReqId,'Hotel').then(async response => {
                    //console.log("Remove Traveler: 5");
                    //console.log("getBookingsBCD Hotel:",response);
                    //console.log("Remove Traveler: 6");
                    //
                    if (response !== undefined){
                        await response.recordset.map(async (prop,key)=>{
                            //console.log("Step: 6-a=>",key); 
                            // get delete values
                            var rowRln = (prop.Record_Locator === null || prop.Record_Locator === "") ? '' : prop.Record_Locator;
                            var rowTravelerId = (prop.Traveller_ID === null || prop.Traveller_ID === "") ? -1 : prop.Traveller_ID;
                            //console.log("begin HotelID_TraverllerId Air:",prop["Hotel_ID"] +'-'+rowTravelerId); 
                            // determine if okay to remove
                            if (prop.BCD_generated && rowRln === rln && rowTravelerId === travelerId){
                                //console.log("rln_TraverllerId Hotel:",rowRln +'-'+rowTravelerId); 
                                await this.DeleteBookings(prop.BCD_generated, prop.Hotel_ID,'Hotel', authReqId, rln);
                            }
                        });
                    }
                });
                //console.log("Remove Traveler: 7");
                // delete invalid Car rentals
                await commonController.getBookingsBCD(authReqId,'Rental_Car').then(async response => {
                    //console.log("Remove Traveler: 8");
                    //console.log("getBookingsBCD Rental_Car:",response);
                    //console.log("Remove Traveler: 9");
                    //
                    if (response !== undefined){
                        await response.recordset.map(async (prop,key)=>{
                            //console.log("Step: 9-a=>",key); 
                            // get delete values
                            var rowRln = (prop.Record_Locator === null || prop.Record_Locator === "") ? '' : prop.Record_Locator;
                            var rowTravelerId = (prop.Traveller_ID === null || prop.Traveller_ID === "") ? -1 : prop.Traveller_ID;
                            //console.log("rln_TraverllerId:",rln +'-'+travelerId);  
                            // determine if okay to remove
                            if (prop.BCD_generated && rowRln === rln && rowTravelerId === travelerId){
                                //console.log("rln_TraverllerId RentalCar:",rowRln +'-'+rowTravelerId); 
                                await this.DeleteBookings(prop.BCD_generated, prop.Car_Rent_ID,'Rental_Car', authReqId, rln);
                            }
                        });
                    }
                });
                //console.log("Remove Traveler: 10");
                // delete Car Service Bookings
                await commonController.getBookingsBCD(authReqId,'Car_Service').then(async response => {
                    //console.log("Remove Traveler: 11");
                    //console.log("getBookingsBCD Car_Service:",response);
                    //console.log("Remove Traveler: 12");
                // 
                    if (response !== undefined){
                        await response.recordset.map(async (prop,key)=>{
                            //console.log("Step: 12-a=>",key); 
                            // get delete values
                            var rowRln = (prop.Record_Locator === null || prop.Record_Locator === "") ? '' : prop.Record_Locator;
                            var rowTravelerId = (prop.Traveller_ID === null || prop.Traveller_ID === "") ? -1 : prop.Traveller_ID;
                            //console.log("rln_TraverllerId:",rln +'-'+travelerId);  
                            // determine if okay to remove
                            if (prop.BCD_generated && rowRln === rln && rowTravelerId === travelerId){
                                //console.log("rln_TraverllerId Car Service:",rowRln +'-'+rowTravelerId); 
                                await this.DeleteBookings(prop.BCD_generated, prop.Car_Service_ID,'Car_Service', authReqId, rln);
                            }
                        });
                    }
                });
                //console.log("begin Load Traveller")
                //Reload Traveller
                await this.loadTraveller();
            });
            
            //console.log("End Load Traveller")
            modal['modalSpinnerShow']=false;
            this.setState({modal});
        }
    }
    
    checkauth(){
        const token = localStorage.getItem('token');
        let expiry:number; //| localStorage.getItem("expiry");
        if(!token){
            return false;
        }
        try{
            //var exp = new Date().getDate() + expiry
            //if(exp < new Date().getTime()){

            //}
        }
        catch (e){
            return false;
        }
    }
    async RetrieveRLN1(){
        let promise = new Promise(function(resolve, reject) {
            resolve(1);
          
            setTimeout(() => resolve(2), 1000);
          });
          
          promise.then(alert);

    }
    // Retrieve BCD generated travel
    async isBCD_generated_exists(){//:Promise<boolean>{
        //console.log("Generated: 1");
        let fields = this.state.fields;
        let travellerController = new TravellerController();
        let returnValue=false;
        let count=0;
            await travellerController.getBCDGeneratedTravel(sessionStorage.getItem('ta')!).then(async response => {
                //console.log("getBCDGeneratedTravel:",response);
                //
                //console.log("Generated: 2");
                if (response.success){
                    //add traveler id to javascript array string
                    this.state.IsBCD_A.splice(0, this.state.IsBCD_A.length);
                    await response.recordset.map(async (prop, key) => {
                        //console.log("Generated: 3-", key);
                        await this.state.IsBCD_A.push({"travellerId": prop['traveller_id']});
                    });
                    //console.log("Generated: 4");
                    // determine if first time retrieval
                    if(this.state.IsBCD_A.length === 0){
                        fields["IsFirstTime"] = true;
                    }
                    else{
                        fields["IsFirstTime"] = false;
                    }
                    this.setState({fields})
                    //console.log("Generated: 5");
                }
                else{
                    fields["IsFirstTime"] = true;
                    this.setState({fields})
                }
                //console.log("Generated: 6");
            });
        
        //})
    }
    //begin
    async RetrieveRLN(){
        //console.log("--------------START----------------");
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal });
        try {
            let commonController = new CommonController();
            //console.log("Step: 1");
            await commonController.authTokenLocalStorage();
            //console.log("Step: 3");
            let fields = this.state.fields;
            var	ta_status_desc = sessionStorage.getItem("ta_status_desc");
            var IsAllNoretrieve = true; 
            var result = false;  // default value

            //
            // check don't retrieve flag
            await this.state.Travelers.map((prop,key) => {
              
                // get no retrieve check box 
                var noretrieve = (prop["BCDNoRetrieve"] === null)?false:prop["BCDNoRetrieve"];
                
                // if no retrieve is not checked and ignore temporary rln
                
                if(noretrieve === false && prop["RLN"] !== Const.temporaryRLN && IsAllNoretrieve)
                {
                    
                    //console.log("Step: 3a");
                    IsAllNoretrieve = false;  // at least one not set to true
                    //return; // stop loop
                }
            });
            //console.log("Step: 4");
            // Don't retrieve flag set for all travelers
            if(IsAllNoretrieve)
            {
                alert("All Don't Re-Retrieve checkboxes are checked, there are no CTM reservations to retrieve.");
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                return false;
            }

            // display confirmation if new TA reservation
            if(ta_status_desc !== "Pending" || this.state.IsBCD_A.length > 0)
            {
                var flag = await confirm("                                                           ** WARNING **\n" + 
                "You are about to retrieve reservation information from CTM for all Travelers " + 
                "with a blank 'Don't re-retrieve' checkbox. (Manual reservations will not be overwritten).\n" + 
                "To proceed with the retrieval, click the OK button.",{title:"Confirm Delete", okText:"Yes", cancelText:"No"})
                if(!flag){
                    modal['modalSpinnerShow']=false;
                    this.setState({ modal });
                    return false;
                }
                /*if(!window.confirm("                                                           ** WARNING **\n" + 
                            "You are about to retrieve reservation information from BCD for all Travelers " + 
                            "with a blank 'Don't re-retrieve' checkbox. (Manual reservations will not be overwritten).\n" + 
                            "To proceed with the retrieval, click the OK button.")){
                        modal['modalSpinnerShow']=false;
                        this.setState({ modal });
                    return false;

                }*/
            }
            //console.log("Step: 4a");
            // validate traveler RLN's
            // must enter at lease one traveler
            if(this.state.Travelers.length === 0)  
            {
                alert("Please add at least one traveler.");
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                return false;
            }
            //Check for duplication entry of RLN
            if (fields['isDupRLN']){
                alert("Each traveler must have unique Resv #");
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                return false;
            }
            //console.log("Step: 5");
            // check for duplicate traveller details by RLN
            await this.checkTravellerDetailsDuplicatsByRLN(this.state.fields.sessionTA)
            //console.log("Content: ", this.state.fields.content);
            //
            if (fields["content"] !== ''){
                // display duplicate message 
                //if(window.confirm(this.state.fields.content)){
                var flag = await confirm(this.state.fields.content,{okText:"Yes", cancelText:"No"})
                if(flag){
                    result = true;   // if user oks duplicate exception 
                }
            }
            else{
                result = true; // no duplicates or exceptions found
            }

            if (!result){
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                return false;
            }
            //console.log("Step: 6");   

            var data={
                recordLocator: "BMSAAD"
            }

            /*await commonController.getSoapXML().then(response => {
                //console.log("searchTripsByRecordLocator");
                ////console.log(response);
            }).catch(error => {
                console.error("There was an error in UpdateTravellerDetailsWithResults...", error);
            })

            await commonController.getSuperPNR().then(responseJson => {
                //console.log("searchTripsByRecordLocator");
                //console.log(responseJson);
            }).catch(error => {
                console.error("There was an error in UpdateTravellerDetailsWithResults...", error);
            })
    */
            //await this.GetPNR_ByXmlTest();
            await this.bRetrieveRLN_onclick();
            //console.log("--------------END----------------");   
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }
        catch(ex){
            alert("error in Retrieve reservation:", ex)
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
        }
    }

    // Retrieve RLN from BCD web service 
    async bRetrieveRLN_onclick(){
        try {
            //console.log("Step: 7");
            //
            var IsOk = true;  // default value
            //console.log("-----Begin RemoveETAReservations---------")
            // delete eTA reservations
            await this.RemoveETAReservations();
            //console.log("-----End RemoveETAReservations---------")

            // Call the BCD web service to receive the PNR
            const promises = await this.state.Travelers.map(async(prop, key) =>{
                //console.log("Step: 14");
                // ignore it traveLers RLN is temporary
                if(prop.RLN === Const.temporaryRLN)
                {
                    // save results as delimitted string
                    const Travelers = [...this.state.Travelers];
                    Travelers[key]['BCDResults'] = "Temporary Resv #";
                    this.setState({ Travelers });
                    //console.log("Traveler Temp:", this.state.Travelers)

                    //console.log("Step: 15");
                    // update the traveler details with result values
                    await this.UpdateTravellerDetailsWithResults(prop.authReqId, prop.travellerId, "Temporary Resv #", false, false);
                    //console.log("Step: 15a=>");
                    var bIsBCDFound = await this.IsBCD_Found(prop.travellerId);
                    // if not in array add it
                    //console.log("Step: 15b=>",bIsBCDFound);
                    
                }
                // call web service, if call failed
                if(!prop.BCDNoRetrieve && prop.RLN !== Const.temporaryRLN){  // ignore if flag is set to true
                    let content:string = "";
                    
                    content = await this.GetPNR_ByXml(prop.authReqId, prop.travellerId, prop.RLN, prop.travellerName, prop.BCDAcceptName, prop.BCDNoRetrieve,key) || '';
                    //console.log("content: ", content)
                    //console.log("prop.travellerId",prop.travellerId)
                    //console.log("prop.rln",prop.RLN)
                    
                    const elMsg:HTMLElement = document.getElementById('resultMsg'+key) as HTMLElement;
                    const elAcceptName:HTMLOptionElement = document.getElementById('BCDAcceptName'+key) as HTMLOptionElement;
                    const elNoRetrieve:HTMLOptionElement = document.getElementById('BCDNoRetrieve'+key) as HTMLOptionElement;
                    if(content.length > 0){
                        //Change color     
                        elMsg.style.color = "red";
                        if(content.indexOf("BCD NAME:") !== -1){
                            elAcceptName.disabled = false; // enable if name match exception error
                        }       
                        else
                        {
                            elAcceptName.disabled = true; // disable check box 
                        }         
                        elNoRetrieve.disabled = true;
                        const Travelers = [...this.state.Travelers];
                        Travelers[key]['BCDResults'] = content; // set results text
                        this.setState({ Travelers });
                        //console.log("inside if")
                        IsOk = false;
                    }
                    else
                    {
                        elMsg.style.color = "black";
                        elAcceptName.disabled = true; // disable check box 
                        const Travelers = [...this.state.Travelers];
                        Travelers[key]['BCDResults'] = 'Successfully Retrieved'; // set results text
                        this.setState({ Travelers });
                    }
                    //console.log("ouside if", IsOk)
                }
                else
                {
                    if (prop.RLN !== Const.temporaryRLN){
                        // save results as delimitted string
                        const Travelers = [...this.state.Travelers];
                        Travelers[key]['BCDResults'] = "Not retrieved"; // set results text
                        this.setState({ Travelers });
                        //console.log("Traveler Temp:", this.state.Travelers)

                        //console.log("Step: 15");
                        // update the traveler details with result values
                        await this.UpdateTravellerDetailsWithResults(prop.authReqId, prop.travellerId, "Not retrieved", prop.BCDAcceptName, prop.BCDNoRetrieve);
                    }
                }
            });
            return Promise.all(promises).then(async response=>{

                //console.log("Step: 17");
                
                //console.log("IsOk",IsOk)
                
                // if all RLN retrieved successfully
                if(IsOk){

                    // add missing travelers to the Is BCD Found array

                    // set the table attributes, if update occurs, reload travelers
                    await this.SetTableAttributes(true);

                    alert("Successful retrieval of traveler reservations.");
                }
                else{
                    
                    // remove eTA BCD reservations
                    await this.RemoveETAReservations();

                    // reset the table values
                    await this.SetTableAttributes(false);

                    // show results required message
                    const el:HTMLElement = document.getElementById('tableResultMessage') as HTMLElement;
                    el.style.display = "block";

                    // show message if no BCD generated reservations exist
                    if(this.state.fields.IsFirstTime)
                    {
                        alert("The Retrieve Resv Results column above must display (Successfully Retrieved) for \n" +  
                            "ALL travelers before reservation data retrieved from CTM will be available on \n" +
                            "the Air/Rail and/or Hotel and/or Rental Car screens. If any exceptions are \n" + 
                            "displayed, please correct them and click Retrieve Resv again.");
                    }
                }
            });
        }
        catch(ex){
            throw ex;
        }
    }

    async IsBCD_Found(travelerId)
    {
        
        //console.log("IsBCD_Found, ",this.state.IsBCD_A)
        var result = false; // defalt value

        if(this.state.IsBCD_A.length === 0){
            return result;
        }

        await this.state.IsBCD_A.map((prop, key)=>{
            if(prop.travellerId.toString() === travelerId){
                return true; // stop loop
            }
            if (key === this.state.fields.IsBCD_A.length-1){
                return result;
            }
        });
    }

    async getPNRByXML(rln){
        var getPNRJson;
        let travellerController = new TravellerController();
        var data = {
            recordLocator: rln,
            token: localStorage.getItem("accessToken")
           // token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJiMjEwM2JhMS1lNjE2LTQyMzEtYmNiOC00ZDRhMmEyYTUzYjkiLCJqdGkiOiJlYTg5ZTg1ZWE5M2U1MmQwY2EzMWE1MWRlODM4YzFiZWNiMmE0NTZhZjk1ODIwNmZjMmZhZTExOTJiYWJiYjQ0ZTUyMjJhZWFhZDhkOGExMiIsImlhdCI6MTY1MTc0MjY3Ni41MzgyNDUsIm5iZiI6MTY1MTc0MjY3Ni41MzgyNDgsImV4cCI6MTY1MzU0MjY3Ni4zMjE3ODUsInN1YiI6IiIsInNjb3BlcyI6W119.gRP7byZZiFMrz4n6HdLETD-cQInfsf3jzfqrpnLv-PkVuKuuMJt6lrvbShYJJ_ErUcxqxHqJxlyyRLP8moYFIorJYoUy_38jPgJbtd94XQCxMBK20A0r2qn5ybakaDT4ZXWe8UOtn3QlxaKBtxqJUKp6gDLRBj-1DDMcrvochkD3eV58L137lmFa8hgBt2HCaf_iIYkur5dB1UQ58FhMyCrGKYPXDsivGjflJCpXY5vrv3kl7PwurgMtrvGtw_J-Wb8zl1KcrP4sQts0jeT9FxAfdmzArFD9jt-WwL3TzLTY7Tc-fu46Oz7QBsPa6sxaOJoHwTs1SfWTRXw7zKKxnr5SrQZk_iF3dlfNRF24bXeJDrT4XmExlmwntJHefVRl0XHQk65SVJv7yCIrxR8gYUVhGKM0NiOAhUU5DljZU375O5OztPnLE5oQUpxAzmxlSF6F5oqCC8_C7geCf-NjWd6lq5qqHgiO5DFAAolj-9bVsaP7XvOE2DB0-UFYeFHe773D6DZjz9fGr0YFiim74baubRMSnVilozzXdSSTITo74sfn91oEIw5_Of7r078zP0SuhijAldM_jbEMupajVxRw65FN0gpgMnK3mNznog2R83kv4WGQVyES6nsTBVdK8dq3bLiS99jeoK9jAqmakzFfJ2kUGGAAlLvavRjIf68"
        }
        //console.log("getPNRByXML data",data)
        
        getPNRJson = await travellerController.getPNRByXML(data);

       // let commonController = new CommonController();            
        //getPNRJson =  await commonController.getPNRByXML(data);


      //  //console.log('Get PNR XML Json Result :', getPNRJson);
        /*getPNRJson = await travellerController.getBCDGeneratedTravel('421940').then(response => {
            //console.log("Step: 16 => updateTravellerDetailsBCDRecLoc");
            //console.log(response);
            return response;
        }).catch(error => {
            console.error("There was an error in updateTravellerDetailsBCDRecLoc...", error);
        })*/
        
        return getPNRJson;
    }
    async GetTravelerInfo(travelers,isAcceptName,travelerName){
        
        var result = "";
        var bResult = true;
        // validate traveler name
        var fullName = "";
        try{
            await travelers.map(async(prop, key) => {
                
                fullName = prop.name.firstName + " " + prop.name.lastName;

                // if client is not accepting name match and travler names do not match
                if (!isAcceptName && travelerName.toUpperCase().replaceAll(' ', '') !== fullName.toUpperCase().replaceAll(' ', '') && bResult)
                {
                    result = "The eTA traveler name does not exactly" + 
                    "match CTM traveler name for this Resv #. " +
                    "(CTM NAME: " + fullName + ") " + 
                    "Either correct traveler name in eTA or check the " + 
                    "Accept box to confirm this is the correct traveler for " +
                    "this reservation only, then click [Retrieve Resv] again.";
                    bResult = false;
                    //return await result;
                }
                else
                {
                    //return await result;
                    bResult = true;
                }
            });
            
            return await result;
        }
        catch(ex){
            throw ex;
        }    
    } 
    async GetPNR_ByXml(authReqId, travelerId, rln, travelerName, acceptName, noRetrieve, row){
        var result="";
        var exceptionMsg="";
        var bcdRecordLocator="";
        var emailInfo = "Arranger: " + sessionStorage.getItem("ta_created_by") +" / Label: " + sessionStorage.getItem("ta_label_desc") + "<br/><br/>";  
        try {
            //console.log("GetPNR_ByXml - 1");
            //var resultResponse = await JSON.parse(JSON.stringify(jsonTraveler));
            
            var resultResponse = await this.getPNRByXML(rln);                    
            let variables = new Variables();
            let emailaddress:string= variables.UserSupportEmailAddr; // process.env.REACT_APP_Tech_Support_EmailAddr||'';
            
            //console.log("GetPNR_ByXml - 2")
             
           // //console.log(await JSON.parse(JSON.stringify(resultResponse)));
                    
           var jsonres = JSON.parse(resultResponse);
          // alert(JSON.parse(JSON.stringify(resultReasponse)))

           var resultResponsecode = jsonres.code;
           var resultResponsestatus = jsonres.status;
           var resultResponseresultlen = jsonres.result.length;
                
            
   
            if (resultResponsecode === 200 && resultResponsestatus === 'ok' && jsonres.result[0].segments.length>0){
                if(resultResponseresultlen > 0){
                    //console.log("GetPNR_ByXml - 2a");
                    var isValidateJson = await this.IsValidJSONString(resultResponse,exceptionMsg);
                    //console.log("GetPNR_ByXml - 2b");
                    if (!isValidateJson){

                        // send to tech support
                        //Utils.SendTechSupportEmail("Error", "Invalid travel reservation data received from BCD for resv#: " + rln);
                        let emailController = new EmailController();
                        emailController.sendTechSupportEmailAPI("Error","Invalid travel reservation data received from CTM for resv#: " + rln, "");
                        result = "Invalid travel reservation data received from CTM for resv#: " + rln + exceptionMsg
                        + " If condition persists, contact application support.";
                        await this.UpdateTravellerDetailsWithResults(authReqId, travelerId, result, acceptName, noRetrieve);
                        // return exception error to caller
                        return result;
                        // update the traveler details with result values
                        
                    }

                    ////console.log("JSON Response :",JSON.parse(JSON.stringify(jsonTraveler)));
                    ////console.log("JSON Response Result :",JSON.parse(JSON.stringify(jsonTraveler.result)));
                     
                     var segments = jsonres.result[0].segments;                   
                   // var segments = await JSON.parse(JSON.stringify(resultResponse.result[0].segments));
                    //console.log("GetPNR_ByXml - 2c");
                    //Is valid RLN
                    //console.log(segments,"segments123");
                    bcdRecordLocator = jsonres.result[0].identification.recordLocator;
                    //console.log(bcdRecordLocator)                    
                    var bcdErr = await this.ValidateRLN(bcdRecordLocator);
                    //console.log("GetPNR_ByXml - 2d");
                    if (bcdErr){
                        //console.log("inside bcd err", bcdErr)
                        result = "Invalid Resv # Please check your entry then click [Retrieve Resv] again.";
                        // update the traveler details with the BCD values
                        await this.UpdateTravellerDetailsBCDresultsRecLoc(authReqId, travelerId, result, acceptName, false, JSON.stringify(resultResponse), bcdRecordLocator)
                        return result;
                    }
                    //console.log("outside bcd err", bcdErr)
                    
                    // get the traveler information     
                   // var travelers = await JSON.parse(JSON.stringify(resultResponse.result[0].travelers));
                   var travelers = jsonres.result[0].travelers;
                    
                    result = await this.GetTravelerInfo(travelers,acceptName,travelerName) || '';
                    //console.log("GetTravelerInfo", result)
                    
                    // if name match is invalid 
                    if (result.length > 0){
                        // update the traveler details with the BCD values
                        await this.UpdateTravellerDetailsBCDresultsRecLoc(authReqId, travelerId, result, acceptName, false, resultResponse, bcdRecordLocator)
                        return result;
                    }
                    else
                    {
                        //console.log("GetTravelerInfo:Not continue")
                        // get the segment information
                        // fight, vehical, hotel, car service
                        //await this.GetSegmentsInfo(jsonTraveler);
                        var airETAResult = "";
                        var hotelETAResult = "";
                        var rentalCarETAResult = "";
                        //console.log("GetPNR_ByXml - 2e");
                        airETAResult = await this.CreateETA_AirBookings(authReqId, travelerId, rln, segments);
                        //console.log("GetPNR_ByXml - 2f");
                        hotelETAResult = await this.CreateETA_HotelBookings(authReqId, travelerId, bcdRecordLocator, segments);
                        //console.log("GetPNR_ByXml - 2dg");
                        rentalCarETAResult = await this.CreateETA_CarRentals(authReqId, travelerId, rln, segments);
                        //console.log("GetPNR_ByXml - 2h");
                        // create the eTA Car Service Bookings information table rows
                        // MC 10/20/07 ITS 61514 Car Service/ BCD Restrieved reservation/now manual
                        //CreateETA_CarServices
                        result = airETAResult + hotelETAResult + rentalCarETAResult;
                        //if(result !== ""){
                        // update the traveler details with the BCD values
                        await this.UpdateTravellerDetailsBCDresultsRecLoc(authReqId, travelerId, result, acceptName, false, JSON.parse(JSON.stringify(resultResponse)), bcdRecordLocator)
                        //console.log("GetPNR_ByXml - 2i");
                        //}
                        // update the traveler details with result values
                        await this.UpdateTravellerDetailsWithResults(authReqId, travelerId, result, acceptName, noRetrieve);
                        //console.log("GetPNR_ByXml - 2j");
                    }
                    return result;
                }
                else
                {
                    result = "Invalid travel reservation data received from CTM for resv#: " + rln + " If condition persists, contact application support.";
                    //console.log("GetPNR_ByXml - result set as empty");
                    await this.UpdateTravellerDetailsWithResults(authReqId, travelerId, result, acceptName, noRetrieve);
                    return await result;
                }
            }
            else if (resultResponsecode === 400 && resultResponsestatus === 'error') //Bad Request: Invalid Search Query.
            {
                // send email to user support
				//SendEmail('<%=Application("UserSupportEmailAddr")%>', "BCD system error occurred for TA# " + authReqId, emailInfo + resultResponse.messages)
                let emailController = new EmailController();
                emailController.sendEmail("",emailaddress, "CTM system error occurred for TA# " + authReqId,emailInfo + resultResponse.messages);

                //console.log("GetPNR_ByXml - errored", resultResponse.messages);
                return await resultResponse.messages;
            }
            else if (resultResponsecode === 403 && resultResponsestatus === 'error') //User is not authorized to access this resource with an explicit deny
            {
                // send email to user support
				//SendEmail('<%=Application("UserSupportEmailAddr")%>', "BCD system error occurred for TA# " + authReqId, emailInfo + resultResponse.messages)
                let emailController = new EmailController();
                emailController.sendEmail("",emailaddress, "CTM system error occurred for TA# " + authReqId,emailInfo + resultResponse.messages);

                //console.log("GetPNR_ByXml - errored", resultResponse.messages);
                return await resultResponse.messages;
            }
            else
            {
                // send to tech support
                //Utils.SendTechSupportEmail("Error", "Invalid travel reservation data received from BCD for resv#: " + rln);
                let emailController = new EmailController();
                emailController.sendTechSupportEmailAPI("Error","Invalid travel reservation data received from CTM for resv#: " + rln, "");

                //console.log("GetPNR_ByXml - 3");
                result = "Invalid travel reservation data received from CTM for resv#: " + rln + " If condition persists, contact application services.";
                await this.UpdateTravellerDetailsWithResults(authReqId, travelerId, result, acceptName, noRetrieve);
                return await result;
            }
        }
        catch(ex) {
            throw ex;
        }
    }
    async UpdateTravellerDetailsBCDresultsRecLoc(authReqId, travelerId, bcdResults, acceptName, noretrieve, pnrJson, bcdRecordLocator){
        var wrkResults="";
   
        // results length is 1000, truncate if >
        wrkResults = bcdResults.Length > 1000 ? bcdResults.substring(0, 1000) : bcdResults;

        let travellerController = new TravellerController();
        var updateData = {
            authReqId: authReqId,
            travelerId: travelerId,
            bcdResults: wrkResults,
            acceptName: acceptName,
            noretrieve: noretrieve,
            pnrJson: pnrJson,
            recordLocator: bcdRecordLocator
        };
        await travellerController.updateTravellerDetailsBCDRecLoc(updateData).then(response => {
            //console.log("Step: 16 => updateTravellerDetailsBCDRecLoc");
            //console.log(response);
        }).catch(error => {
            console.error("There was an error in updateTravellerDetailsBCDRecLoc...", error);
        })
    }
    
  /*  async CreateETA_AirBookings(authReqId, travellerId, bcdRecordLocator, segments){
        
        var result : string = "";
        var isBooking : boolean = false;
        var newAirId : number = -1;
        var FlightInfo = segments.filter(f=>f.type == 'Air');
        if (FlightInfo.length === 0){ 
            return result; 
        }
        let travellerController = new TravellerController();
        var data1 = {}, data2 = {}, data3 = {};
        //console.log("createETA air log1")
        await FlightInfo.map(async(prop,key) => {
            
            //console.log("createETA air log2")
            if(!isBooking){
                data1 = {
                    authReqId: authReqId,
                    recordLocator: bcdRecordLocator,
                    confirmationNumber: prop.confirmationNumber,
                    scAmount: 0,
                    scDescription: "",
                    bcdGenerated: true,
                    bcdValidated: false
                } 
                //console.log("insert air booking data", data1)
                await travellerController.insertAirBookingsBCD(data1).then(response => {
                    //console.log("insert air bookings response", response);
                    newAirId = response.airId;
                    if(newAirId > 0){
                        data2 = {
                            airId: newAirId,
                            travellerId: travellerId,
                            specialInstructions: "",
                            airfareAmount: 0, //missing
                            feeAmount: 0,
                            totalAmount: 0, //missing
                            confirmationNumber: prop.confirmationNumber
                        }
                        //console.log("insert Air map data", data2);
                        travellerController.insertAirMapBCD(data2).then(response => {
                            //console.log("insert air map response",response)
                        })
                    }
                    else{
                        return result = "Air Id not generated, Please contact administrator......"
                    }
                    isBooking = true;
                }).catch(error => {
                    console.error("There was an error...", error)
                    return result = error;
                })
            }
            if(newAirId > 0){
                var deptAirport = prop.departureAirport.airport;
                var arrivalAirport = prop.arrivalAirport.airport;
                
                data3 = {
                    airId: newAirId,
                    airlineId : (prop.marketingFlight.airlineCode === undefined)? "-1" : (this.state.AirLineBCDCode.filter(f=>f.BCDCode == prop.marketingFlight.airlineCode).map(f=>(f.id))[0] !== undefined) ? this.state.AirLineBCDCode.filter(f=>f.BCDCode == prop.marketingFlight.airlineCode).map(f=>(f.id))[0] : "-1",
                    travelClassId : "-1",
                    travelDate : prop.startDateTime,
                    fromLocation : deptAirport.name + ', ' + deptAirport.address.city.name + ', ' + deptAirport.address.region.code + ', ' + deptAirport.address.country.code,
                    fromTime : prop.startDateTime,
                    fromAMPM : prop.startDateTime,
                    toLocation : arrivalAirport.name + ', ' + arrivalAirport.address.city.name + ', ' + arrivalAirport.address.region.code + ', ' + arrivalAirport.address.country.code,
                    toTime : prop.endDateTime,
                    toAMPM : prop.endDateTime
                }
                //console.log("Insert air leg data", data3)
                await travellerController.insertAirLegBCD(data3);            
            }
            else // send critical error message
            {
                //Utils.SendTechSupportEmail("Error", "Exception error occured in CreateETA_AirBookings() - Unable to create new air id ", "<\n/>RLN = " + bcdRecordLocator + "<\n/>");
                let emailController = new EmailController();
                emailController.sendTechSupportEmailAPI("Error","Exception error occured in CreateETA_AirBookings() - Unable to create new air id, RLN = " + bcdRecordLocator, "");
            }
        });
        //console.log("createETA air log3")
        return result;
    }
*/

async CreateETA_AirBookings(
    authReqId,
    travellerId,
    bcdRecordLocator,
    segments
  ) {
    var result: string = "";
    var isBooking: boolean = false;
    var newAirId: number = -1;
    var FlightInfo = segments.filter((f) => f.type == "Air");
    
    if (FlightInfo.length === 0) {
      return result;
    }
    let travellerController = new TravellerController();
    var data1 = {},
      data2 = {},
      data3 = {};
    //console.log(FlightInfo,"createETA air FlightInfo");
 if (!isBooking) {
    data1 = {
      authReqId: authReqId,
      recordLocator: bcdRecordLocator,
      confirmationNumber: FlightInfo[0].confirmationNumber,
      scAmount: 0,
      scDescription: "",
      bcdGenerated: true,
      bcdValidated: false,
    };
    //console.log("insert air booking data", data1);
    await travellerController
      .insertAirBookingsBCD(data1)
      .then((response) => {
        //console.log("insert air bookings response", response);
        newAirId = response.airId;}).catch((error) => {
          console.error("There was an error...", error);
          return (result = error);
        });
      }
await FlightInfo.map(async (prop, key) => {
      //console.log("createETA air log2");
  if (!isBooking) {
            if (newAirId > 0) {
              data2 = {
                airId: newAirId,
                travellerId: travellerId,
                specialInstructions: "",
                airfareAmount: 0, //missing
                feeAmount: 0,
                totalAmount: 0, //missing
                confirmationNumber: prop.confirmationNumber,
              };
              //console.log("insert Air map data", data2);
              travellerController.insertAirMapBCD(data2).then((response) => {
                //console.log("insert air map response", response);
                
              });
            } else {
              return (result =
                "Air Id not generated, Please contact administrator......");
            }
            isBooking = true;
      }
      if (newAirId > 0) {
          
        var deptAirport = prop.departureAirport.airport;
        var arrivalAirport = prop.arrivalAirport.airport;
        data3 = {
          airId: newAirId,
          airlineId:
            prop.marketingFlight.airlineCode === undefined
              ? "-1"
              : this.state.AirLineBCDCode.filter(
                  (f) => f.BCDCode == prop.marketingFlight.airlineCode
                ).map((f) => f.id)[0] !== undefined
              ? this.state.AirLineBCDCode.filter(
                  (f) => f.BCDCode == prop.marketingFlight.airlineCode
                ).map((f) => f.id)[0]
              : "-1",
          travelClassId: "-1",
          travelDate: prop.startDateTime,
          fromLocation:
            deptAirport.name +
            ", " +
            deptAirport.address.city.name +
            ", " +
            deptAirport.address.region.code +
            ", " +
            deptAirport.address.country.code,
          fromTime: prop.startDateTime,
          fromAMPM: prop.startDateTime,
          toLocation:
            arrivalAirport.name +
            ", " +
            arrivalAirport.address.city.name +
            ", " +
            arrivalAirport.address.region.code +
            ", " +
            arrivalAirport.address.country.code,
          toTime: prop.endDateTime,
          toAMPM: prop.endDateTime,
        };
        //console.log("Insert air leg data", data3);
        await travellerController.insertAirLegBCD(data3);
      } // send critical error message
      else {
        //Utils.SendTechSupportEmail("Error", "Exception error occured in CreateETA_AirBookings() - Unable to create new air id ", "<\n/>RLN = " + bcdRecordLocator + "<\n/>");
        let emailController = new EmailController();
        emailController.sendTechSupportEmailAPI(
          "Error",
          "Exception error occured in CreateETA_AirBookings() - Unable to create new air id, RLN = " +
            bcdRecordLocator,
          ""
        );
      }
    });
    //console.log("createETA air log3");
    return result;
  }
    async CreateETA_HotelBookings(authReqId, travellerId, bcdRecordLocator, segments){
        
        var result : string = "";
        var newHotelId: number = -1;
        var HotelInfo = segments.filter(f=>f.type == 'Hotel');
        
        if (HotelInfo.length === 0){ return result; }
        let travellerController = new TravellerController();
        var data1 = {}, data2 = {};
        //console.log("createETA hotel log1")
        await HotelInfo.map((prop,key)=>{
            //console.log("createETA hotel log2")
            data1 = {
                authReqId: authReqId,
                hotelBillId: "-1",
                name: prop.title,
                city: prop.property.address.city.name === undefined ? "" : prop.property.address.city.name ,
                rate: (Config.GETRATES)?prop.fare.rate.amount:0,
                tax: 0, 
                incidentals: 0, 
                inDate: prop.startDateTime,
                outDate: prop.endDateTime,
                total: 0,
                scAmount: 0,
                scDescription: "",
                directBillProgress: false, 
                nonDisputable: true,
                bcdGenerated: true,
                bcdValidated: false,
                recordLocator: bcdRecordLocator
            };
            //console.log("insert hotel bookings data", data1);
            travellerController.insertHotelBookingsBCD(data1).then(response => {
                //console.log("insert hotel bookings response", response);
                newHotelId = response.hotelId;
                data2 = {
                    hotelId: newHotelId,
                    travellerId: travellerId,
                    specialInstructions: "",
                    confirmationNumber: prop["confirmationNumber"]
                };
                if(newHotelId > 0){
                    //console.log("insert hotel map data", data2);
                    travellerController.insertHotelMapBCD(data2).then(response => {
                        //console.log("insert hotel map response", response);
                    })
                }
                else{ // send critical error message
                    //send 
                    //Utils.SendTechSupportEmail("Error", "Exception error occured in CreateETA_HotelBookings() - Unable to create new air id ", "<\n/>RLN = " + bcdRecordLocator + "<\n/>");
                    let emailController = new EmailController();
                    emailController.sendTechSupportEmailAPI("Error","Exception error occured in CreateETA_HotelBookings() - Unable to create new hotel id, RLN = " + bcdRecordLocator, "");
                    return result = "Hotel Id not generated, Please contact administrator..."
                }
            }).catch(error => {
                console.error("There was an error...", error);
                return result = error;
            })            
        });
        //console.log("createETA hotel log3")
        return result;
    }

    async CreateETA_CarRentals(authReqId, travellerId, bcdRecordLocator, segments){
        
        var result : string = "";
        var newCarRentId : number = -1;
        var VehicalInfo = segments.filter(f=>f.type == 'Car');
        if (VehicalInfo.length === 0){ return ""; }
        let travellerController = new TravellerController();
        var data1 = {}, data2 = {};
        //console.log("createETA CarRentals log1")
        await VehicalInfo.map((prop,key)=>{
            //console.log("createETA CarRentals log2")
            data1 = {
                authReqId: authReqId,
                rentalBillId: "-1",
                rentalTypeId: "-1",
                carCompany: prop.rentalCompany.name,
                city: (prop.pickupLocation.address.city.name === undefined)?'':prop.pickupLocation.address.city.name,
                rate: (Config.GETRATES)?prop.fare.baseRate.amount:0,
                tax: 0,
                otherCosts: 0,
                pickUpDate: prop.startDateTime,
                returnDate: prop.endDateTime,
                total: 0,
                scAmount: 0,
                scDescription: "",
                directBillProgress: false,
                bcdGenerated: true,
                bcdValidated: false,
                recordLocator: bcdRecordLocator
            };
            //console.log("insert car rentals booking data", data1);
            travellerController.insertCarRentalsBookingsBCD(data1).then(response => {
                //console.log("insert car rentals booking response", response);
                newCarRentId = response.carRentId;
                if(newCarRentId > 0){
                    data2 = {
                        carRentId: newCarRentId,
                        travellerId: travellerId,
                        specialInstructions: "",
                        confirmationNumber: prop["confirmationNumber"]
                    };
                    //console.log("insert car rentals map data", data2);
                    travellerController.insertCarRentMapBCD(data2).then(response => {
                        //console.log("insert car rentals map response", response);
                    })
                }
                else{ // send critical error message
                    //send mail
                    //Utils.SendTechSupportEmail("Error", "Exception error occured in CreateETA_CarRentals() - Unable to create new car rental id ", "<\n/>RLN = " + bcdRecordLocator + "<\n/>");
                    let emailController = new EmailController();
                    emailController.sendTechSupportEmailAPI("Error","Exception error occured in CreateETA_CarRentals() - Unable to create new car rental id, RLN = " + bcdRecordLocator, "");
                    return result = "Car Rent Id not generated, Please contact administrator..."
                }
            }).catch(error => {
                console.error("There was an error...", error);
                return result = error;
            })
        });
        //console.log("createETA CarRentals log3")
        return result;
    }

    async ValidateRLN(rln)
    {
        var result = ''; // default to successful validation
        var IsError = false;
        
        // get error segment
        // extract error messgage from header segment
        // if error contains this value
        //if (rln.indexOf("RECORD LOCATOR NOT FOUND") === -1 || rln.indexOf("RESTRICTED‡ *NOT AA PNR")  === -1
        //|| rln.indexOf("INVALID RECORD LOCATOR")  === -1 || rln === ""){
        if(rln === ""){
            return await true;  // error exists
        }
        else{
            return await false;  // no error
        }

    }
    async GetSegmentsInfo(jsonTraveler){
        JSON.stringify(jsonTraveler)

    }
    CallBCDWebService(authReqId, travelerId, rln, travelerName, acceptName, row){
        
    }
    async IsValidJSONString(str,exceptionMsg) {
        try {
            //console.log("Json String Valid: true");
            JSON.parse(JSON.stringify(str)); // valid JSON document
        } catch (e) {
            //console.log("Json String Valid: false")
            exceptionMsg = e;
            return false;
        }
        return true;
    }
    
    // hide elements if Car service only
    hideBCDRetrievalElements(){
        //const el:HTMLElement = document.getElementById('tableResultMessage') as HTMLElement;
        //el.style.display = "none";
        //const elBtn:HTMLElement = document.getElementById('bRetrieveRLN') as HTMLElement;
        //elBtn.style.display = "none";
    }

    async SetTableAttributes(IsOk){
        var IsReload = false;
        
        try{
            await this.state.Travelers.map(async (prop, key) =>{
                // get results text
                var results = (prop.BCDResults === null)?'':prop.BCDResults;

                //console.log("prop.BCDResults",prop.BCDResults)
                
                // if error exists
                if(results !== "Successfully Retrieved" && results !== "Not retrieved" && results !== "Temporary Resv #") 
                {
                    
                    console.log ("SetTableAttributes: Failure, ", IsOk);
                    //Change color              
                    const elMsg:HTMLElement = document.getElementById('resultMsg'+key) as HTMLElement;
                    elMsg.style.color = "red";
                    // show results required message
                    const el:HTMLElement = document.getElementById('tableResultMessage') as HTMLElement;
                    el.style.display = "block";
                }
                //if temporary RLN acccept name is inactive
                if(prop.RLN === Const.temporaryRLN){
                    const Travelers = [...this.state.Travelers];
                    Travelers[key]['BCDAcceptName'] = false; // set results text
                    this.setState({ Travelers });
                }
                //disable check box if not the name match error
                const el:HTMLOptionElement = document.getElementById('BCDAcceptName'+key) as HTMLOptionElement;
                if(results.indexOf("The eTA traveler name does not exactly match") === -1){
                    el.disabled = false;            
                }
                else if(prop.RLN === Const.temporaryRLN)
                {
                    el.disabled = true;
                }
                else
                {
                    el.disabled = false;
                }

                // if first time successful retrieval set this checked to true	
                if(IsOk){
                    // set temporary resv # values	
                    if(prop.RLN === Const.temporaryRLN)
                    {
                        const Travelers = [...this.state.Travelers];
                        Travelers[key]['BCDAcceptName'] = false; // set results text
                        Travelers[key]['BCDNoRetrieve'] = true;
                        this.setState({ Travelers });
                        //console.log("SetTableAttributes-True-TempRLN-results :", results)
                        //update the traveler details with result values
                        await this.UpdateTravellerDetailsWithResults(prop.authReqId, prop.travellerId, results, false, false);
                    }
                    else // not temporary resv #
                    {
                        const Travelers = [...this.state.Travelers];
                        Travelers[key]['BCDNoRetrieve'] = true;
                        this.setState({ Travelers });

                        // determine status of accept name check box, if disabled then accept name is true
                        var acceptName = false;

                        const el:HTMLOptionElement = document.getElementById('BCDAcceptName'+key) as HTMLOptionElement;
                        
                        //const el:HTMLElement = document.getElementById('cbAccept') as HTMLElement;
                        if (el.disabled){
                        acceptName = true;
                        }
                        //console.log("SetTableAttributes-True-RLN-results:", results)
                        // update the traveler details with result values
                        await this.UpdateTravellerDetailsWithResults(prop.authReqId, prop.travellerId, results, acceptName, true);   
                    }
                    //console.log("begin Load Traveller")
                    //Reload Traveller
                    await this.loadTraveller();
                    //console.log("End Load Traveller")
                }
            });
        }
        catch(ex){
            throw ex;
        }
    }
    // Call web service to update traveller details with result values
    async UpdateTravellerDetailsWithResults(authReqId, travelerId, bcdResults, acceptName, noretrieve){
        var wrkResults = "";
        // results length is 1000, truncate if >
        wrkResults = bcdResults.Length > 1000 ? bcdResults.substring(0, 1000) : bcdResults;

        let travellerController = new TravellerController();
        var updateData = {
            authReqId: authReqId,
            travelerId: travelerId,
            bcdResults: wrkResults,
            acceptName: acceptName,
            noretrieve: noretrieve
        };
        // update traveller details
        await travellerController.updateTravellerDetailsBCD(updateData).then(response => {
            //console.log("Step: 16 => UpdateTravellerDetailsWithResults");
            //console.log(response);
        }).catch(error => {
            console.error("There was an error in UpdateTravellerDetailsWithResults...", error);
            throw error;
        })
    }
    // remove invalid RLNs by TA#
    async IsRemoveTraveler(rln, travelerId){
        //console.log("Step: 10-b IsRemoveTraveler"); 
        //
        var result = true;   // default value to true
        let filterTravelers:any;
        filterTravelers = this.state.Travelers.filter(item => item.RLN !== Const.temporaryRLN)
        //console.log("IsRemoveTraveler without Temp RLN: ", filterTravelers)
        var isRemoveTraveler = filterTravelers.filter(f=> f.RLN == rln && f.travelerId == travelerId && f.IsNoretrieve == true).length;
        if (isRemoveTraveler > 0){
            return false; // do not remove
        }
        else
        {
            return true;
        }
/*
        if(filterTravelers.length === 0){
            return result;
        }
        // loop through list
        const promises = await filterTravelers.map(async (prop,key) => {
            //console.log("Step: 10-b=>",key); 
            // if rln and traveler id matches and no trieve if true
            //console.log("rln",prop['RLN'])
            //console.log("travelerId",prop['travellerId'])
            //console.log("isNoretrieve",prop['IsNoretrieve'])
            if(prop['RLN'] === rln && prop['travellerId'] === travelerId && prop['IsNoretrieve']){
                //console.log("inside if")
                result = false;  // do not remove
                return result; // stop loop
            }
            if(key === this.state.Travelers.length - 1){
                //console.log("travelers length",this.state.Travelers.length - 1)
                return result;
            }
        });
        return Promise.all(promises);
    */
    }

    // Remove rows from eTA reservation for retrieve from BCD
    async RemoveETAReservations(){
        try{
            //console.log("Step: 8");
            /// Remove rows from eTA reservation for retrieve from BCD
            let commonController = new CommonController();
            // delete invalid air bookings
            await commonController.getBookingsBCD(this.state.fields.sessionTA,'Air').then(async response => {
                //console.log("Step: 9");
                //console.log("getBookingsBCD Air:",response);
                //console.log("Step: 10"); 
                
                if (response !== undefined){
                    await response.recordset.map(async (prop,key)=>{
                        //console.log("Step: 10-a=>",key); 
                        // get RLN
                        var rln = (prop.Record_Locator === null || prop.Record_Locator === '') ? '' : prop.Record_Locator;
                        var travelerId = (prop.IsTraveller === null || prop.IsTraveller === '') ? -1 : prop.Traveller_ID;
                        //console.log("rln_TraverllerId:",rln +'-'+travelerId);
                        var bRemoveTraveller = await this.IsRemoveTraveler(rln, travelerId);
                        //console.log("RemoveTraveller:", bRemoveTraveller);

                        //console.log("Step: 10-c=>", key); 
                        await this.deleteBooking_BCD(prop.BCD_generated, bRemoveTraveller, prop.Air_ID,'Air',rln);
                    });
                }
            });
            //console.log("Step: 11");
            // delete invalid hotel bookings
            await commonController.getBookingsBCD(this.state.fields.sessionTA,'Hotel').then(async response => {
                //console.log("Step: 9");
                //console.log("getBookingsBCD Hotel:",response);
                //console.log("Step: 10"); 
                //
                if (response !== undefined){
                    await response.recordset.map(async (prop,key)=>{
                        //console.log("Step: 10-a=>",key); 
                        // get RLN
                        var rln = (prop.Record_Locator === null || prop.Record_Locator === '') ? '' : prop.Record_Locator;
                        var travelerId = (prop.IsTraveller === null || prop.IsTraveller === '') ? -1 : prop.Traveller_ID;
                        //console.log("rln_TraverllerId:",rln +'-'+travelerId);
                        var bRemoveTraveller = await this.IsRemoveTraveler(rln, travelerId);
                        //console.log("RemoveTraveller:", bRemoveTraveller);

                        //console.log("Step: 10-c=>", key); 
                        await this.deleteBooking_BCD(prop.BCD_generated, bRemoveTraveller, prop.Hotel_ID,'Hotel',rln);
                    });
                }
            });
            //console.log("Step: 12");
            // delete invalid Car rentals
            await commonController.getBookingsBCD(this.state.fields.sessionTA,'Rental_Car').then(async response => {
                //console.log("Step: 9");
                //console.log("getBookingsBCD Rental_Car:",response);
                //console.log("Step: 10"); 
                //
                if (response !== undefined){
                    await response.recordset.map(async (prop,key)=>{
                        //console.log("Step: 10-a=>",key); 
                        // get RLN
                        var rln = (prop.Record_Locator === null || prop.Record_Locator === '') ? '' : prop.Record_Locator;
                        var travelerId = (prop.IsTraveller === null || prop.IsTraveller === '') ? -1 : prop.Traveller_ID;
                        //console.log("rln_TraverllerId:",rln +'-'+travelerId);
                        var bRemoveTraveller = await this.IsRemoveTraveler(rln, travelerId);
                        //console.log("RemoveTraveller:", bRemoveTraveller);

                        //console.log("Step: 10-c=>", key); 
                        await this.deleteBooking_BCD(prop.BCD_generated, bRemoveTraveller, prop.Car_Rent_ID,'Rental_Car',rln);
                    });
                }
            });
            //console.log("Step: 13");
            // delete Car Service Bookings
            await commonController.getBookingsBCD(this.state.fields.sessionTA,'Car_Service').then(async response => {
                //console.log("Step: 9");
                //console.log("getBookingsBCD Car_Service:",response);
                //console.log("Step: 10"); 
            // 
                if (response !== undefined){
                    await response.recordset.map(async (prop,key)=>{
                        //console.log("Step: 10-a=>",key); 
                        // get RLN
                        var rln = (prop.Record_Locator === null || prop.Record_Locator === '') ? '' : prop.Record_Locator;
                        var travelerId = (prop.IsTraveller === null || prop.IsTraveller === '') ? -1 : prop.Traveller_ID;
                        //console.log("rln_TraverllerId:",rln +'-'+travelerId);
                        var bRemoveTraveller = await this.IsRemoveTraveler(rln, travelerId);
                        //console.log("RemoveTraveller:", bRemoveTraveller);
                        //console.log("Step: 10-c=>", key); 
                        await this.deleteBooking_BCD(prop.BCD_generated, bRemoveTraveller, prop.Car_Service_ID,'Car_Service',rln);
                    });
                }
            });
        }
        catch(ex){
            throw ex;
        }
    }

    async DeleteBookings(BCDgerenated, Id, sCode, authReqId, rln){
        //console.log("Step: 10-d=>deleteBooking_BCD:", sCode); 
        // determine if okay to remove  
        let commonController = new CommonController();
        if(BCDgerenated){
            // remove invalid RLNs by TA#
            await commonController.deleteBookingBCD(Id,sCode).then(async response => {
                //console.log("Step: 10-e"); 
                //console.log("response", response);
                if(response.code === 400){
                    //Utils.SendTechSupportEmail("Error", "Exception error occured in RemoveeTAReservationsById()", "TA#: " + authReqId + " RLN " + rln + "<br/><br/>" + error.Message);
                    let emailController = new EmailController();
                    emailController.sendTechSupportEmailAPI("Error","Exception error occured in RemoveeTAReservationsById()", "TA#:" +authReqId + " RLN " + rln + "");
                }
            }).catch(error => {
                console.error("There was an error...", error);
                //Utils.SendTechSupportEmail("Error", "Exception error occured in RemoveeTAReservationsById()", "TA#: " + authReqId + " RLN " + rln + "<br/><br/>" + error.Message);
                //result = " Unable to remove invalid eTA BCD reservations. \nIf condition persists, contact\n application support.\n\nMessage Details:\n\n" + error.Message;
                let emailController = new EmailController();
                emailController.sendTechSupportEmailAPI("Error","Exception error occured in RemoveeTAReservationsById()", "TA#:" +authReqId + " RLN " + rln + "");
            });
            
        } 
    }

    async deleteBooking_BCD(BCDgerenated, bRemoveTraveller, Id, sCode,rln){
        //console.log("Step: 10-d=>deleteBooking_BCD:", sCode); 
        // determine if okay to remove  
        let commonController = new CommonController();
        if(BCDgerenated && bRemoveTraveller){
            // remove invalid RLNs by TA#
            await commonController.deleteBookingBCD(Id,sCode).then(async response => {
                if(response.code === 400){
                    //Utils.SendTechSupportEmail("Error", "Exception error occured in RemoveeTAReservations()", "TA#: " + this.state.fields.sessionTA + "<br/><br/>" + response.message);
                    //result = BCDWS.SystemError + " Unable to remove invalid eTA BCD reservations. \nIf condition persists, contact\n application support.\n\nMessage Details:\n\n" + e.Message;
                    let emailController = new EmailController();
                    emailController.sendTechSupportEmailAPI("Error","Exception error occured in RemoveeTAReservations()", "TA#: " +this.state.fields.sessionTA + " RLN " + rln + "");
                }
                //console.log("Step: 10-e"); 
                //console.log("response", response);
            }).catch(error => {
                console.error("There was an error...", error);
                //Utils.SendTechSupportEmail("Error", "Exception error occured in RemoveeTAReservationsById()", "TA#: " + authReqId + " RLN " + rln + "<br/><br/>" + error.Message);
                //result = " Unable to remove invalid eTA BCD reservations. \nIf condition persists, contact\n application support.\n\nMessage Details:\n\n" + error.Message;
                let emailController = new EmailController();
                emailController.sendTechSupportEmailAPI("Error","Exception error occured in RemoveeTAReservationsById()", "TA#: " +this.state.fields.sessionTA + " RLN " + rln + "");
            });
        } 
    }
    // check for duplicate traveller details by RLN
    async checkTravellerDetailsDuplicatsByRLN(authReqId:string){
        let fields = this.state.fields;
        fields["content"]='';
        var content='';   // return default value
        var cnt = 0;
        var tempRLNCnt = 0;
        let travellerController = new TravellerController();
        // check for duplicate traveller details
        const promises = await this.state.RLN.map(async (prop, key) => {
            //console.log("RLN index:", key);
            //console.log("Step: 5a");
            // ignore invalid strings and temporary resv #'s
            if (prop['RLN'] !== Const.temporaryRLN) {
                //
                var dupStr = '';
                // check for duplicate traveller details
                await travellerController.getTravellerDetailsByRLN(prop['RLN']).then(response => {
                    //console.log("getTravellerDetailsByRLN-response:",response)
                    //console.log("getTravellerDetailsByRLN index:", key)
                    //console.log("Step: 5b");
                   if (response !== undefined){
                       // if RLN is matches and TA# is different add to return string
                       // MC ITS 60977 - check for activity within 6 months
                       response.recordset.map((resprop,reskey)=>{
                        //console.log("response index:", reskey)
                        //console.log("Step: 5c");
                        //if (resprop.RLN == rln && resprop.AuthReq_ID != authReqId && DateTime.Now < row.Created_Date.AddMonths(6))
                            ////console.log("prop['Created_Date']:",resprop['Created_Date'])
                            //var myDate = new Date(resprop['Created_Date']);
                            //let dt:Date = resprop['Created_Date'];
                            var currDate = Utils.getSysDate();
                            //console.log("System Date:", currDate.toISOString());
                            //console.log("Create Date:", resprop['Created_Date'])
                            var addMonthDate = new Date(resprop['Created_Date']);
                            addMonthDate = Utils.addMonths(addMonthDate, 6);
                            //var myDate = new Date(Utils.convertUTCDate(resprop['Created_Date']));
                            ////console.log("convert UTC:",myDate)
                            //var myDate = new Date(resprop['Created_Date']);
                            //var myDate = new Date(dt);
                            //let myDate:Date = Utils.convertDate(resprop['Created_Date']);
                            //console.log("AddMonth 6:", addMonthDate.toISOString())
                            //var result1 = Utils.addMonths(dt, 6);
                            //let d: Date = Utils.getUTCSysDate();
                            //let nDate = new Date(result1);
                            ////console.log("AddMonth 6 :", nDate);
                            ////console.log("date:",d)
                            if (resprop.RLN === prop['RLN'] && resprop.AuthReq_ID !== authReqId && currDate < addMonthDate){
                                    dupStr += resprop.AuthReq_ID + "; ";
                            }
                       });
                      //   
                        // if duplicate exists add to return result string
                        if(dupStr !== ''){
                            content += "The Resv # " + prop['RLN'] + " you have selected has already been used on the following Completed TA(s): " + dupStr;
                        }
                    }
                    else{
                        content +='';
                    }
                }).catch(error => {
                    console.error("There was an error!", error)
                });
                //  
                cnt = cnt+1;
                //alert("CNT"+cnt);
            }
            else {
                //  
                tempRLNCnt = tempRLNCnt+1;
                //alert("TMPCNT"+tempRLNCnt);
            }
            //
            if ((tempRLNCnt + cnt) === this.state.RLN.length) {
                if (content !== '') {
                    content += "To continue with this Resv # on this TA, click OK. (Note: You should then remove reservations from the tabs on this TA that are not to be included and/or have previously been completed.";
                    //alert("BEFORE TRUE");
                    fields["content"]=content;
                    this.setState({fields});
                }
            }
        });
        return Promise.all(promises);
        //return bResult;
    }

    duplicateRLN(){
        
        let fields = this.state.fields;
        let IsDupRLN:any;
        let isAllow:boolean | false;
        // cannot enter same RLN twice
        this.state.Travelers.map((prop,key) => {
            //
            IsDupRLN = this.state.Travelers.filter(item => item.RLN == prop["RLN"] && item.RLN != Const.temporaryRLN)
            // if duplicate exists
            if(IsDupRLN.length > 1 && !isAllow)
            {
                fields['isDupRLN']=true;// stop loop, return from function
            }	
            this.setState({fields})
        })
    }
    
    displayTraveler(){
        const{ loading } = this.state;
        return <div>
            <Row>
                <Col sm={12} style={{textAlign:"right"}}>
                <Button id="print" type="button" size="sm" onClick={() => window.print()} >
                    Print
                </Button>
                </Col>
            </Row>
            <Row style={{display:(sessionStorage.getItem("ta_car_service_only")==="true"?"none":"block"),marginTop:"5px"}}>
                <Col sm={12} style={{textAlign:"right"}}>
                    <Button id="bRetrieveRLN" name="bRetrieveRLN" title="Click to retrieve CTM reservations" variant="info" type="button" size="sm" disabled={loading} onClick={(e) => this.RetrieveRLN()}>
                        {loading && (
                        <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" , color:"#6c757d" }}
                        />
                        )}
                        {loading && <span style={{color:"#6c757d"}} >wait...</span>}
                        {!loading && <span>Retrieve Resv</span>}
                    </Button>
                </Col>
            </Row>
            <Row style={{marginTop:"15px"}}>
                <Col sm={3}></Col>
                <Col sm={6} style={{padding: "5px"}}>
                    <FormGroup style={{paddingRight:'15px'}}>
                        <FormLabel>Search For</FormLabel>
                        {(this.state.fields.expenseTypeId === Const.RECORDING_TA || this.state.fields.expenseTypeId === Const.ULTRA_RECORDING_TA || this.state.fields.expenseTypeId === Const.ULTRA_MARKETING_TA) ?
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Artist", Const.TYPE_ARTIST)}
                                </Col>
                                <Col sm={4}></Col>
                            </Row> 
                        : this.state.fields.expenseTypeId === Const.INTERNATIONAL_REBILL_TA ?
                            <Row>
                                <Col sm={2}></Col>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Artist", Const.TYPE_ARTIST)}
                                </Col>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Traveller", Const.TYPE_OVERHEAD)}
                                </Col>
                                <Col sm={2}></Col>
                            </Row> 
                        : this.state.fields.expenseTypeId === Const.MARKETING_TA ?
                            <Row>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Artist", Const.TYPE_ARTIST)}
                                </Col>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Contest Winner", Const.TYPE_CONTEST_WINNER)}
                                </Col>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Radio Personnel", Const.TYPE_RADIO_PERSONNEL)}
                                </Col>
                            </Row> 
                        : this.state.fields.expenseTypeId === Const.OVERHEAD_TA ?
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={4}>
                                    {this.btnSearchFor(loading,"Traveller", Const.TYPE_OVERHEAD)}
                                </Col>
                                <Col sm={4}></Col>
                            </Row> 
                        : <></>
                        }
                    </FormGroup>
                </Col>
                <Col sm={3}></Col>
            </Row>
            {this.FormTravelersTable()}
        </div>
    }

    codingTab(){
        if(sessionStorage.getItem("page") === "submitter" && parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 || sessionStorage.getItem("ta_is_corporate_card") === "true")){
            return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for summary page" label="Print"></Tab>
        }
        if(sessionStorage.getItem("page") === "approver" || sessionStorage.getItem("page") === "submitter"){
            if(parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 || (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 && parseInt(sessionStorage.getItem("ta_tier_code")!) === 0 || sessionStorage.getItem("ta_is_corporate_card") === "false")){
                return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
            }    
        }     
    }
    decisionTabTAHistoryResubmit(){
        
        if(sessionStorage.getItem("ta_status_id") === Const.ReSubmit.toString()){
           return <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>           
        }
        else
        {
           return <Tab headerClass="nav-link disabled" style={{color:"white"}} disabled></Tab>
        }
    }
    contestWinner() {
        //Traveller, Artist, Radio, ContestWinner
        return (
          <div>
            <Card border="secondary" style={{ width: "100%", marginTop: "5px" }}>
              <Card.Header>Contest Winner Details</Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={10}>
                      <FormInputs
                        properties={{
                          id: "SSNo",
                          label: "SSN",
                          type: "text",
                          name: "SSNo",
                          className: "form-control form-control-sm",
                          maxlength: 11,
                          value: this.state.fields.SSNo,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={true}
                        errorMsg={this.state.errors.SSNoFedID}
                      />
                    <p className="text-dark font-size-xs small">*Example (123-45-7890).</p>
                   
                    <p className="text-dark font-size-xs small">*If you don't have a SSN, Please enter 000-00-0000.</p>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={10}>
                      <FormInputs
                        properties={{
                          id: "fedID",
                          label: "Federal ID",
                          type: "text",
                          name: "fedID",
                          className: "form-control form-control-sm",
                          maxlength: 10,
                          value: this.state.fields.fedID,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={false}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Country/Region</Form.Label>
                        <Form.Control
                          as="select"
                          id="selCountryId"
                          name="selCountryId"
                          value={this.state.selectfields.selCountryId}
                          className="form-control form-control-sm"
                          onChange={this.handleSelectChange}
                        >
                          <option value={-1}>{"Select a Country"}</option>
                          {this.state.Country.map((prop, key) => {
                            //if(prop["id"] === "US"){
                            return (
                              <option
                                value={prop["id"]}
                                selected={prop["id"] === "US" ? true : false}
                              >
                                {prop["name"]}
                              </option>
                            );
                            /* }
                                       else
                                       {
                                           return(
                                               <option value={prop["id"]}>{prop["name"]}</option>
                                           )
                                       }*/
                          })}
                        </Form.Control>
                        {this.state.errors.countryId !== "" ? (
                          <div className="text-danger font-size-xs small font-weight-bold">
                            {this.state.errors.countryId}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={12}>
                      <FormInputs
                        properties={{
                          id: "address1",
                          label: "Address 1",
                          type: "text",
                          name: "address1",
                          className: "form-control form-control-sm",
                          maxlength: 50,
                          value: this.state.fields.address1,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={true}
                        errorMsg={this.state.errors.address1}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={12}>
                      <FormInputs
                        properties={{
                          id: "address2",
                          label: "Address 2",
                          type: "text",
                          name: "address2",
                          className: "form-control form-control-sm",
                          maxlength: 50,
                          value: this.state.fields.address2,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={false}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={6}>
                      <FormInputs
                        properties={{
                          id: "city",
                          label: "City",
                          type: "text",
                          name: "city",
                          className: "form-control form-control-sm",
                          maxlength: 50,
                          value: this.state.fields.city,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={true}
                        errorMsg={this.state.errors.city}
                      />
                    </Col>
                    <Col md={6}></Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    {this.state.StateProvince.length > 0 ? (
                      <Col md={12}>
                        <Form.Group>
                          <Form.Label>State/Province</Form.Label>
                          <Form.Control
                            as="select"
                            id="selStateProvinceId"
                            name="selStateProvinceId"
                            value={this.state.selectfields.selStateProvinceId}
                            className="form-control form-control-sm"
                            onChange={this.handleSelectChange}
                          >
                            <option value={-1}>{"Select a State/Province"}</option>
                            {this.state.StateProvince.map((prop, key) => {
                              return (
                                <option selected={true} value={prop["id"]}>
                                  {prop["name"]}
                                </option>
                              );
                            })}
                          </Form.Control>
                          {this.state.errors.stateProvinceId !== "" ? (
                            <div className="text-danger font-size-xs small font-weight-bold">
                              {this.state.errors.stateProvinceId}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </Col>
                    ) : (
                      <Col md={12}>
                        <FormInputs
                          properties={{
                            id: "stateProvinceId",
                            label: "State/Province",
                            type: "text",
                            name: "stateProvinceId",
                            className: "form-control form-control-sm",
                            maxlength: 50,
                            value: this.state.fields.stateProvinceId,
                            onChange: this.handleChange,
                          }}
                          fieldRequired={true}
                          errorMsg={this.state.errors.stateProvinceId}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={7}>
                      <FormInputs
                        properties={{
                          id: "postalCode",
                          label: "Zip/Postal Code",
                          type: "text",
                          name: "postalCode",
                          className: "form-control form-control-sm",
                          maxlength: 10,
                          value: this.state.fields.postalCode,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={true}
                        errorMsg={this.state.errors.postalCode}
                      />
                    </Col>
                    <Col md={5}>
                      <FormInputs
                        properties={{
                          id: "phone",
                          label: "Phone",
                          type: "text",
                          name: "phone",
                          className: "form-control form-control-sm",
                          maxlength: 20,
                          value: this.state.fields.phone,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={false}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={12} style={{ alignSelf: "flex-end" }}>
                      <label>1099 Issuer</label>
                      <Form.Group>
                        <Row
                          style={{ alignItems: "flex-end", paddingLeft: "1rem" }}
                        >
                          <Col sm={4} style={{ paddingRight: "1rem" }}>
                            <Form.Check
                              type="radio"
                              label="Radio Station"
                              name="rdoIssuer"
                              id="rdoIssuer1"
                              onChange={(e) => this.handleOptionChange(e)}
                              value="1"
                              defaultChecked={this.state.optionfields.rdoIssuer1}
                              checked={this.state.optionfields.rdoIssuer1}
                            />
                          </Col>
                          <Col sm={4} style={{ paddingRight: "1rem" }}>
                            <Form.Check
                              type="radio"
                              label="UMG"
                              name="rdoIssuer"
                              id="rdoIssuer2"
                              onChange={(e) => this.handleOptionChange(e)}
                              value="2"
                              defaultChecked={this.state.optionfields.rdoIssuer2}
                              checked={this.state.optionfields.rdoIssuer2}
                            />
                          </Col>
                          <Col sm={4}></Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ borderTop: "1px solid #6c757d" }}>
                    <Col md={8}>
                      <FormInputs
                        properties={{
                          id: "callLetters",
                          label: "Radio Station Call Letters",
                          type: "text",
                          name: "callLetters",
                          className: "form-control form-control-sm",
                          maxlength: 10,
                          value: this.state.fields.callLetters,
                          onChange: this.handleChange,
                        }}
                        fieldRequired={true}
                        errorMsg={this.state.errors.callLetters}
                      />
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                  <Row style={{ marginTop: "-15px" }}>
                    <Col md={12} style={{ alignSelf: "flex-end" }}>
                      <label>Obtained Contest Letter</label>
                      <Form.Group>
                        <Row
                          style={{ alignItems: "flex-end", paddingLeft: "1rem" }}
                        >
                          <Col sm={3} style={{ paddingRight: "1rem" }}>
                            <Form.Check
                              type="radio"
                              label="Yes"
                              name="rdoRadioLetter"
                              id="rdoRadioLetterYes"
                              onChange={(e) => this.handleOptionChange(e)}
                              value="Y"
                              defaultChecked={
                                this.state.optionfields.rdoRadioLetterYes
                              }
                              checked={this.state.optionfields.rdoRadioLetterYes}
                            />
                          </Col>
                          <Col sm={3} style={{ paddingRight: "1rem" }}>
                            <Form.Check
                              type="radio"
                              label="No"
                              name="rdoRadioLetter"
                              id="rdoRadioLetterNo"
                              onChange={(e) => this.handleOptionChange(e)}
                              value="N"
                              defaultChecked={
                                this.state.optionfields.rdoRadioLetterNo
                              }
                              checked={this.state.optionfields.rdoRadioLetterNo}
                            />
                          </Col>
                          <Col sm={6} style={{ paddingRight: "1rem" }}>
                            <Form.Check
                              type="radio"
                              label="Not Radio Related"
                              name="rdoRadioLetter"
                              id="rdoRadioLetterNA"
                              onChange={(e) => this.handleOptionChange(e)}
                              value="N/A"
                              defaultChecked={
                                this.state.optionfields.rdoRadioLetterNA
                              }
                              checked={this.state.optionfields.rdoRadioLetterNA}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  {this.state.optionfields.rdoRadioLetterNo ? (
                    <Row id="trOverride" style={{ marginTop: "-15px" }}>
                      <Col md={12} style={{ alignSelf: "flex-end" }}>
                        <label>Override Received from Compliance Officer</label>
                        <Form.Group>
                          <Row
                            style={{ alignItems: "flex-end", paddingLeft: "1rem" }}
                          >
                            <Col sm={4} style={{ paddingRight: "1rem" }}>
                              <Form.Check
                                type="radio"
                                label="Yes"
                                name="rdoOverride"
                                id="rdoOverrideYes"
                                onChange={(e) => this.handleOptionChange(e)}
                                value="1"
                                defaultChecked={
                                  this.state.optionfields.rdoOverrideYes
                                }
                                checked={this.state.optionfields.rdoOverrideYes}
                              />
                            </Col>
                            <Col sm={4} style={{ paddingRight: "1rem" }}>
                              <Form.Check
                                type="radio"
                                label="No"
                                name="rdoOverride"
                                id="rdoOverrideNo"
                                onChange={(e) => this.handleOptionChange(e)}
                                value="0"
                                defaultChecked={
                                  this.state.optionfields.rdoOverrideNo
                                }
                                checked={this.state.optionfields.rdoOverrideNo}
                              />
                            </Col>
                            <Col sm={4}></Col>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        );
      }
    /*
    contestWinner(){
        //Traveller, Artist, Radio, ContestWinner
        return <div>
            <Card border="secondary" style={{ width: '100%',marginTop: "5px" }}>
               <Card.Header>Contest Winner Details</Card.Header>
               <Card.Body>
               <Card.Text>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={6}>
                       <FormInputs
                           properties={{id:"SSNo",label:"SSN",type:"text",name:"SSNo",className:"form-control form-control-sm",maxlength:11,value:this.state.fields.SSNo,onChange:this.handleChange}}
                           fieldRequired={true}
                           errorMsg = {this.state.errors.SSNoFedID}
                       />
                   </Col>
                   <Col md={6}>
                       <FormInputs
                           properties={{id:"fedID",label:"Federal ID",type:"text",name:"fedID",className:"form-control form-control-sm",maxlength:10,value:this.state.fields.fedID,onChange:this.handleChange}}
                           fieldRequired={true}
                       />
                   </Col>
               </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={12}>
                       <Form.Group>
                           <Form.Label>Country/Region</Form.Label>
                           <Form.Control as="select" id="selCountryId" name="selCountryId" value={this.state.selectfields.selCountryId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                               <option value={-1}>{"Select a Country"}</option>
                               {this.state.Country.map((prop, key) => {
                                   //if(prop["id"] === "US"){
                                       return(
                                           <option value={prop["id"]} selected={(prop["id"] === "US")?true:false}>{prop["name"]}</option>
                                       )
                                  /* }
                                   else
                                   {
                                       return(
                                           <option value={prop["id"]}>{prop["name"]}</option>
                                       )
                                   }*/
                             /*  })}
                           </Form.Control>
                           {this.state.errors.countryId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.countryId}</div> : <></> }
                       </Form.Group>
                   </Col>
                </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={12}>
                       <FormInputs
                           properties={{id:"address1",label:"Address 1",type:"text",name:"address1",className:"form-control form-control-sm",maxlength:50,value:this.state.fields.address1,onChange:this.handleChange}}
                           fieldRequired={true}
                           errorMsg={this.state.errors.address1}
                       />
                   </Col>
                </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={12}>
                       <FormInputs
                           properties={{id:"address2",label:"Address 2",type:"text",name:"address2",className:"form-control form-control-sm",maxlength:50,value:this.state.fields.address2,onChange:this.handleChange}}
                           fieldRequired={false}                           
                       />
                   </Col>
               </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={6}>
                       <FormInputs
                           properties={{id:"city",label:"City",type:"text",name:"city",className:"form-control form-control-sm",maxlength:50,value:this.state.fields.city,onChange:this.handleChange}}
                           fieldRequired={true}
                           errorMsg={this.state.errors.city}
                       />
                   </Col>
                   <Col md={6}></Col>
               </Row>
               <Row style={{marginTop: "-15px"}}>
                   {(this.state.StateProvince.length > 0) ?
                   <Col md={12}>
                       <Form.Group>
                           <Form.Label>State/Province</Form.Label>
                           <Form.Control as="select" id="selStateProvinceId" name="selStateProvinceId" value={this.state.selectfields.selStateProvinceId} className="form-control form-control-sm" onChange={this.handleSelectChange}>
                               <option value={-1}>{"Select a State/Province"}</option>
                               {this.state.StateProvince.map((prop, key) => {
                                   return(
                                           <option selected={true} value={prop["id"]}>{prop["name"]}</option>
                                       )
                               })}
                           </Form.Control>
                           {this.state.errors.stateProvinceId !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.stateProvinceId}</div> : <></> }
                       </Form.Group>
                   </Col>
                   :
                   <Col md={12}>
                       <FormInputs
                           properties={{id:"stateProvinceId",label:"State/Province",type:"text",name:"stateProvinceId",className:"form-control form-control-sm",maxlength:50,value:this.state.fields.stateProvinceId,onChange:this.handleChange}}
                           fieldRequired={true}
                           errorMsg={this.state.errors.stateProvinceId}
                       />
                   </Col>
                   }
                </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={7}>
                       <FormInputs
                           properties={{id:"postalCode",label:"Zip/Postal Code",type:"text",name:"postalCode",className:"form-control form-control-sm",maxlength:10,value:this.state.fields.postalCode,onChange:this.handleChange}}
                           fieldRequired={true}
                           errorMsg={this.state.errors.postalCode}
                       />
                   </Col>
                   <Col md={5}>
                       <FormInputs
                           properties={{id:"phone",label:"Phone",type:"text",name:"phone",className:"form-control form-control-sm",maxlength:20,value:this.state.fields.phone,onChange:this.handleChange}}
                           fieldRequired={false}
                       />
                   </Col>
               </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={12} style={{alignSelf: "flex-end"}}>
                       <label>1099 Issuer</label>
                       <Form.Group>
                           <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                               <Col sm={4} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Radio Station" name="rdoIssuer" id="rdoIssuer1" onChange={(e)=>this.handleOptionChange(e)} value="1" defaultChecked={this.state.optionfields.rdoIssuer1} checked={this.state.optionfields.rdoIssuer1} /></Col>
                               <Col sm={4} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="UMG" name="rdoIssuer" id="rdoIssuer2" onChange={(e)=>this.handleOptionChange(e)} value="2" defaultChecked={this.state.optionfields.rdoIssuer2} checked={this.state.optionfields.rdoIssuer2} /></Col>
                               <Col sm={4}></Col>
                           </Row>
                       </Form.Group>   
                   </Col>
               </Row>
               <Row style={{borderTop: "1px solid #6c757d"}}>
                   <Col md={8}>
                       <FormInputs
                           properties={{id:"callLetters",label:"Radio Station Call Letters",type:"text",name:"callLetters",className:"form-control form-control-sm",maxlength:10,value:this.state.fields.callLetters,onChange:this.handleChange}}
                           fieldRequired={true}
                           errorMsg={this.state.errors.callLetters}
                       />
                   </Col>
                   <Col md={4}></Col>
               </Row>
               <Row style={{marginTop: "-15px"}}>
                   <Col md={12} style={{alignSelf: "flex-end"}}>
                       <label>Obtained Contest Letter</label>
                       <Form.Group>
                           <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                               <Col sm={3} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Yes" name="rdoRadioLetter" id="rdoRadioLetterYes" onChange={(e)=>this.handleOptionChange(e)} value="Y" defaultChecked={this.state.optionfields.rdoRadioLetterYes} checked={this.state.optionfields.rdoRadioLetterYes} /></Col>
                               <Col sm={3} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="No" name="rdoRadioLetter" id="rdoRadioLetterNo" onChange={(e)=>this.handleOptionChange(e)} value="N" defaultChecked={this.state.optionfields.rdoRadioLetterNo} checked={this.state.optionfields.rdoRadioLetterNo} /></Col>
                               <Col sm={6} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Not Radio Related" name="rdoRadioLetter" id="rdoRadioLetterNA" onChange={(e)=>this.handleOptionChange(e)} value="N/A" defaultChecked={this.state.optionfields.rdoRadioLetterNA} checked={this.state.optionfields.rdoRadioLetterNA} /></Col>
                           </Row>
                       </Form.Group>   
                   </Col>
               </Row>
               {(this.state.optionfields.rdoRadioLetterNo)?
                <Row id="trOverride" style={{marginTop:"-15px"}}>
                    <Col md={12} style={{alignSelf: "flex-end"}}>
                        <label>Override Received from Compliance Officer</label>
                        <Form.Group>
                            <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                            <Col sm={4} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Yes" name="rdoOverride" id="rdoOverrideYes" onChange={(e)=>this.handleOptionChange(e)} value="1" defaultChecked={this.state.optionfields.rdoOverrideYes} checked={this.state.optionfields.rdoOverrideYes} /></Col>
                                <Col sm={4} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="No" name="rdoOverride" id="rdoOverrideNo" onChange={(e)=>this.handleOptionChange(e)} value="0" defaultChecked={this.state.optionfields.rdoOverrideNo} checked={this.state.optionfields.rdoOverrideNo} /></Col>
                                <Col sm={4}></Col>
                            </Row>
                        </Form.Group>   
                    </Col>
                </Row>:""}
               </Card.Text>
               </Card.Body>
           </Card>
        </div>
   }
  */
   resetOptions(){
        let optionfields = this.state.optionfields;
        if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER || this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL){
            optionfields["rdoOverrideYes"] = true;
            optionfields["rdoOverrideNo"] = false;
        }
        if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER){
            optionfields["rdoRadioLetterYes"] = true;
            optionfields["rdoRadioLetterNo"] = false;
            optionfields["rdoRadioLetterNA"] = false;
            //const elrdoOverride:HTMLElement = document.getElementById('trOverride') as HTMLElement;    
            //if(elrdoOverride !== undefined){
            //    elrdoOverride.style.display="";
            //}
            optionfields['rdoIssuer1'] = true;
            optionfields['rdoIssuer2'] = false;
        }
        this.setState({optionfields})
   }
   clearError(){
       let errors = this.state.errors;
       errors["firstName"]=""
       errors["lastName"]=""
       errors["reason"]=""
       errors["resv"]=""

       if(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER){
           errors["SSNoFedID"]=""
           errors["address1"]=""
           errors["countryId"] =""
           errors["city"]=""
           errors["postalCode"]=""
           errors["callLetters"]=""
           errors["stateProvinceId"]=""
       }
       if (this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL){
           errors["callLetters"] = ""
       }

       this.setState({errors});
   }
   validateForm(){
       this.clearError();

       let fields = this.state.fields;
       let errors = this.state.errors;
       let formIsValid = true;

        if (!fields["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please enter traveler's last name";
        }
        if (!fields["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please enter traveler's last name";
        }

       if (!fields["reason"]){
           formIsValid = false;
           errors["reason"] = "Please enter a reason for travel."
       }
       
       if (sessionStorage.getItem("ta_car_service_only") === "false"){
           fields["resv"] = this.state.fields.resv.toUpperCase();
           if (!fields["resv"]){
               formIsValid = false;
               errors["resv"] = "Please enter the Resv # (aka RLOC) provided in the email travel notification from CTM."
           }
           if(!this.validRLN(fields["resv"]))
           {
               formIsValid = false;
               errors["resv"] = "A valid Resv # is limited to A-Z, 0-9, must be 6 characters in length and no spaces or special characters."
           }
       }

       if (this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER){
            if ((fields["SSNo"] !== "" && fields["SSNo"].length > 0) || (fields["fedID"] !== "" && fields["fedID"].length > 0)){
                var formValidSSNo = true;
                var formValidFedID = true;
                var formatSSNo = ""
                if(fields["SSNo"] !== "" && fields["SSNo"].length > 0){
                    if(fields["SSNo"].indexOf("-") > 0){
                        formatSSNo = fields["SSNo"];
                    }
                    else
                    {
                        formatSSNo = fields["SSNo"].substring(0, 3) + '-' + fields["SSNo"].substring(5, 3) + '-' + fields["SSNo"].substring(9, 5)
                    }
                    if(!this.validSSN(formatSSNo)){
                        formValidSSNo = false;
                    }
                }
                if(fields["fedID"] !== "" && fields["fedID"].length > 0){                             
                    if(!this.validFedID(fields["fedID"]))
                    {
                        formValidFedID = false;
                    }
                }
                if(!formValidSSNo || !formValidFedID){
                        errors["SSNoFedID"]="Please enter a valid Social Security Number or a valid Federal ID.";
                        formIsValid = false;
                }
            }
            else
            {
                errors["SSNoFedID"]="Please enter a valid Social Security Number or a valid Federal ID else.";
                formIsValid = false;
            }
            if(fields["address1"] === "" || fields["address1"].length === 0)
            {
                errors["address1"] = "Please enter an address."
                formIsValid = false;	
            }
            if(this.state.selectfields.selCountryId === "-1"){
                errors["countryId"] = "Please select a Country.";
                formIsValid = false;
            }
            if(fields["city"] === "" || fields["city"].length === 0)
            {
                errors["city"]="Please enter a City.";
                formIsValid = false;	
            }
            
            if(this.state.StateProvince.length > 0){
                if(this.state.selectfields.selStateProvinceId === "-1"){
                    errors["stateProvinceId"]="Please select a State/Province.";
                    formIsValid = false;	
                }
            }
            else
            {
                if(fields["stateProvinceId"] === "" || fields["stateProvinceId"].length === 0){
                    errors["stateProvinceId"]="Please enter a State/Province.";
                    formIsValid = false;	
                }
            }

            //address1:'', address2:'', city: '', postalCode: '', phone: '', callLetters:
            //const elCountrName:HTMLInputElement = document.getElementById('selCountryID') as HTMLInputElement; 
            
            if(!this.validZip(fields["postalCode"], this.state.selectfields.selCountryId))
            {
                errors["postalCode"] = "Please enter a proper Zip/Postal Code.";
                formIsValid = false;
            }
            
            //If Issuer is Radio Station, or answer to Obtain Contest Letter is Y or N, then Call Letters is required.
            if(this.state.optionfields.rdoIssuer1 || this.state.optionfields.rdoRadioLetterYes || this.state.optionfields.rdoRadioLetterNo)
            {
                if(fields["callLetters"] === "" || fields["callLetters"].length === 0)
                {
                    errors["callLetters"]="Please enter Radio Station Call Letters.";
                    formIsValid = false;	
                }
            }
       }
       if (this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL){
            if(fields["callLetters"] === "" || fields["callLetters"].length === 0)
            {
                errors["callLetters"]="Please enter Radio Station Call Letters.";
                formIsValid = false;	
            }
       }
       
       this.setState({ errors });

       return formIsValid;
   }
   trimStr(str)
   {
       return str.replace(/^\s*|\s*$/g,"");
   } 
   validRLN(strRLN)
   {
       var regEx = new RegExp("/[^A-Z0-9]/");
       var blnValid = false;        
       //  Matches any character not in the specified range or length is not 6
       if(regEx.test(strRLN) || strRLN.length !== 6)
           blnValid = false;
       else
           blnValid = true;

       return blnValid;
   }
validFedID(strFedID)
{
    var regEx = new RegExp("[0-9]{9}$|^[0-9]{2}[-][0-9]{7}$");
	var blnValid = false;

	if(regEx.test(strFedID)) 
	{
		blnValid = true;
	}
	
	return blnValid;	
}

validSSN(strSSN)
{
    var regEx = new RegExp("[0-9]{9}$|^[0-9]{3}[-][0-9]{2}[-][0-9]{4}$");
	var blnValid = false;
		
	if(regEx.test(strSSN))
	{
		blnValid = true;
	}

	return blnValid;
}
validZip(strCode, strCountry)
{
    
    var regEx = new RegExp("[A-Za-z0-9- ]{1,10}$");
    var regExUS = new RegExp("[0-9]{5}$|^[0-9]{5}[-][0-9]{4}$");
    var regExMX = new RegExp("[0-9]{5}$|^[0-9]{5}[-][0-9]{4}$");
    var regExCA = new RegExp("[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$|^[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[ ]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$");
    var regExNL = new RegExp("[0-9]{4}[ ]{1}[A-Za-z]{2}$");

	var blnValid = false;
	
	switch(strCountry)
	{
		case "US":
			if(regExUS.test(strCode))
            {
				blnValid = true;
			}
			break;
		case "MX":
			if(regExMX.test(strCode))
			{
				blnValid = true;
			}
			break;
		case "CA":
			if(regExCA.test(strCode))
			{
				blnValid = true;
			}
			break;
		case "NL":
			if(regExNL.test(strCode))
			{
				blnValid = true;
			}
			break;
		default:
			if(regEx.test(strCode))
			{
				blnValid = true;
			}
			break;
	}
		
	return blnValid;
}
   radioStation(){
       //Traveller, Artist, Radio, ContestWinner
       return <div>
           <Card border="secondary" style={{ width: '100%',marginTop: "5px" }}>
              <Card.Header>Radio Personnel Details</Card.Header>
              <Card.Body>
              <Card.Text>
               <Row style={{marginTop: "-15px"}}>
                  <Col md={8}>
                      <FormInputs
                          properties={{id:"callLetters",label:"Radio Station Call Letters",type:"text",name:"callLetters",className:"form-control form-control-sm",maxlength:10,value:this.state.fields.callLetters,onChange:this.handleChange}}
                          fieldRequired={true}
                          errorMsg={this.state.errors.callLetters}
                      />
                  </Col>
                  <Col md={4}></Col>
              </Row>
              <Row style={{marginTop:"-15px"}}>
                  <Col md={12} style={{alignSelf: "flex-end"}}>
                      <label>Pre-approval Obtained from the UMG Compliance Officer</label>
                      <Form.Group>
                          <Row style={{alignItems: "flex-end", paddingLeft: "1rem"}}>
                              <Col sm={4} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Yes" name="rdoOverride" id="rdoOverrideYes" onChange={(e)=>this.handleOptionChange(e)} value="1" defaultChecked={this.state.optionfields.rdoOverrideYes} checked={this.state.optionfields.rdoOverrideYes} /></Col>
                              <Col sm={4} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="No" name="rdoOverride" id="rdoOverrideNo" onChange={(e)=>this.handleOptionChange(e)} value="0" defaultChecked={this.state.optionfields.rdoOverrideNo} checked={this.state.optionfields.rdoOverrideNo} /></Col>
                              <Col sm={4}></Col>
                          </Row>
                      </Form.Group>   
                  </Col>
              </Row>
              </Card.Text>
              </Card.Body>
          </Card>
       </div>
    }
    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <Modal show={this.state.modal.modalShow}
                onHide={this.handleModalClose} 
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-50w"
                centered
                >
                <Modal.Header closeButton>
                    {this.state.modal.modalTitle}
                </Modal.Header>
                <Modal.Body className="show-grid" style={{padding:'0px'}} >
                    <Container fluid style={{paddingLeft:'0px',paddingRight:'0px'}}>
                         <Card style={{ width: '100%',marginBottom: '0px' }}>
                            <Card.Body>
                                {this.displayTravelerSearch()}
                            </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{width: "100%"}}>
                        <Row>
                            <Col md={12} style={{textAlignLast: "center"}}><div className="text-dark font-size-xs small">- Click on the Traveler to select -</div></Col>
                        </Row>
                        <Row>
                        <Col md={3}></Col>
                        <Col md={6} style={{textAlign:"center"}}>
                            <Button style={{height: 'auto'}} size="sm" id="createNewTraveler" name="createNewTraveler" onClick={(e) => this.handleModalTAShow(e, '')}>Create New Traveler</Button>
                            <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="cancel" name="cancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
                        </Col>
                        <Col md={3}></Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal id="modalTA" show={this.state.modal.modalTAShow}
                onHide={this.handleModalTAClose} 
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-md"
                centered
                >
                <Modal.Header closeButton>
                    {this.state.modal.modalTATitle}
                </Modal.Header>
                <Modal.Body className="show-grid" style={{padding:'0px'}} >
                    <Container fluid style={{paddingLeft:'0px',paddingRight:'0px'}}>
                         <Card style={{ width: '100%',marginBottom: '0px' }}>
                            <Card.Body>
                                {this.showModalBody()}
                                <Row style={{marginTop:"0px"}}>
                                    <Col md={6}>
                                        <FormInputs
                                            properties={{id:"reason",label:"Reason For Travel",type:"text",name:"reason",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.reason,onChange:this.handleChange,autocomplete:'off'}}
                                            fieldRequired={true}
                                            errorMsg={this.state.errors.reason}
                                        />
                                    </Col>
                                    <Col md={6} style={{visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "hidden":"visible"}}>
                                        <FormInputs
                                            properties={{id:"resv",label:"Resv # (RLOC number)",type:"text",name:"resv",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.resv,onChange:this.handleChange,disabled:(this.state.checkfields.manualResv)?true:false,autocomplete:'off'}}
                                            fieldRequired={true}
                                            errorMsg={this.state.errors.resv}
                                        />
										<Form.Check type="checkbox" label="Is manual Reservation" name="manualResv" id="manualResv" className="text-dark font-size-xs small" onChange={this.handleCheckChangeEvent} checked={this.state.checkfields.manualResv} />
                                    </Col>
                                    <Col md={6} style={{visibility:sessionStorage.getItem("ta_car_service_only")==="true" ? "visible":"hidden"}}></Col>
                                </Row>
                                {this.showSubmitterMsg()}
                                {(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER && (sessionStorage.getItem("has_dha") === "true" && parseInt(sessionStorage.getItem("ta_expense_type_id")!) === Const.OVERHEAD_TA))?
                                            <Row style={{marginTop:"5px"}}>
                                            <Col md={12}>
                                                <Form.Check type="checkbox" label="Senior Executive Approval Required" name="chkDirectorHeadApproval" id="chkDirectorHeadApproval" className="text-dark font-size-xs small" onChange={this.handleCheckChangeEvent} checked={this.state.checkfields.chkDirectorHeadApproval} />
                                            </Col>
                                        </Row>
                                    :
                                    ""
                                }
                                {(this.state.travelerTypeId === Const.TYPE_CONTEST_WINNER)?this.contestWinner():""}
                                {(this.state.travelerTypeId === Const.TYPE_RADIO_PERSONNEL)?this.radioStation():""}
                            </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {this.showModalFooterButton(this.state.loading)}
                </Modal.Footer>
            </Modal>
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <div id="divTA">
                                    <AuthStatus/>
                                    <section style={{marginTop:"10px"}}>
                                        <div style={{textAlign:"right"}}>
                                            <SubmitButton 
                                                loading={this.state.loading} 
                                                redirect={this.props.history}
                                            />
                                        </div>
                                        {(sessionStorage.getItem("ta_car_service_only") === "true")?
                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={0} mountOnExit>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler">{this.displayTraveler()}</Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                            {this.codingTab()}
                                            {this.decisionTabTAHistoryResubmit()}
                                        </Tabs>
                                        :
                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={0} mountOnExit>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for traveler page" label="Traveler">{this.displayTraveler()}</Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for air/rail page" label="Air / Rail"></Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for hotel page" label="Hotel"></Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for rental car page" label="Rental Car"></Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for car service page" label="Car Service"></Tab>
                                            {this.codingTab()}
                                            {this.decisionTabTAHistoryResubmit()}
                                        </Tabs>
                                        }
                                    </section>
                                </div>
                            </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}