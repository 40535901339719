import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class CarServiceMaintenanceController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getCarserviceDetails (data: any){
        var url = this.variables.Url+ this.variables.Controller.carServiceMaintenance + this.variables.carServiceMaintenanceService.getCarServiceMaintenanceHistory;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncarServiceMaitenance : Array<{"carServiceCompanyId": string, "carServiceCompanyName": string,"labelName":string,"labelId":string,"expTypeName":string,"expTypeId":string
                                        "accountNumber":string,"ccAccountNumber":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceMaitenance);
                    function carServiceMaitenance(item: { car_service_company_id: any; car_service_company: any;Family:any;label_id:any;TA_Type:any;
                                         expense_type_id:any;Account_Number:any; CC_Account_Number:any;}){
                     jsoncarServiceMaitenance.push({"carServiceCompanyId": item.car_service_company_id, "carServiceCompanyName": item.car_service_company, "labelName": item.Family,
                        "labelId": item.label_id, "expTypeName": item.TA_Type, "expTypeId": item.expense_type_id, "accountNumber": item.Account_Number,"ccAccountNumber": item.CC_Account_Number})
                    }
                    return JSON.parse(JSON.stringify(jsoncarServiceMaitenance).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsoncarServiceMaitenance).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getAllLabels (){
        var url = this.variables.Url+ this.variables.Controller.labelMaintenance + this.variables.labelMaintenanceService.getAllActiveLabels;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetLabels : Array<{"id": string, "name": string,"isActive":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { Label_ID: any; Description: any;Is_Active:any; }){
                    jsonGetLabels.push({"id": item.Label_ID, "name": item.Description, "isActive": item.Is_Active })
                    }
                    return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getExpenseType (){
        var url = this.variables.Url+ this.variables.Controller.carServiceMaintenance + this.variables.carServiceMaintenanceService.getExpenseType;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetExpenseType : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(expenseType);
                    function expenseType(item: { expense_type_id: any; description: any}){
                        jsonGetExpenseType.push({"id": item.expense_type_id, "name": item.description })
                    }
                    return JSON.parse(JSON.stringify(jsonGetExpenseType).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetExpenseType).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    inserUpdateDeleteCarService(data)
    {
        var url = this.variables.Url+ this.variables.Controller.carServiceMaintenance + this.variables.carServiceMaintenanceService.inserUpdateDeleteCarServiceCode;
       
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getCarCompanyDetails (){
        var url = this.variables.Url+ this.variables.Controller.carServiceMaintenance + this.variables.carServiceMaintenanceService.getCarServiceCompany;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsoncarServiceCompany : Array<{"carServiceCompanyId": string, "carServiceCompanyName": string,"userTypeId":string,"isActive":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCompany);
                    function carServiceCompany(item: { Car_Service_Company_ID: any; Car_Service_Company: any;User_Type_ID:any;Active:any;}){
                        jsoncarServiceCompany.push({"carServiceCompanyId": item.Car_Service_Company_ID, "carServiceCompanyName": item.Car_Service_Company, "userTypeId": item.User_Type_ID,
                        "isActive": item.Active})
                    }
                    return JSON.parse(JSON.stringify(jsoncarServiceCompany).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsoncarServiceCompany).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getUserTypeCodes (){
        var url = this.variables.Url+ this.variables.Controller.carServiceMaintenance + this.variables.carServiceMaintenanceService.getCarServiceCompany;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonUserTypeCodes : Array<{"userTypeId":string}> = [];
                if(response.success){
                    response.recordset.forEach(userTypeCodes);
                    function userTypeCodes(item: {User_Type_ID:any;}){
                        jsonUserTypeCodes.push({ "userTypeId": item.User_Type_ID})
                    }
                    return JSON.parse(JSON.stringify(jsonUserTypeCodes).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonUserTypeCodes).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    inserUpdateCarCompany(data)
    {
        var url = this.variables.Url+ this.variables.Controller.carServiceMaintenance + this.variables.carServiceMaintenanceService.inserUpdateCarServiceCompany;
  
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

}
