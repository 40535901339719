import React, { Component, CSSProperties } from "react";
import { IBusinessUnitMaintenanceParameter, Istate } from "../../../Model/IBusinessUnitMaintenance";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { BusinessUnitController } from "../../../Controllers/BusinessUnitController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';

export class BusinessUnitMaintenanceView extends Component<IBusinessUnitMaintenanceParameter,Istate> {
    constructor(props:IBusinessUnitMaintenanceParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {bUnitId:'',description:'',isActive:false},
            errors:{},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:"",
            businessUnitGroups:[],            
            collBUnitId:"",
            loadComplete:false           
        }
        this.onSubmit = this.onSubmit.bind(this); 
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;      
        this.getAllBusinessUints();
        modal['modalSpinnerShow']=false;
        this.setState({ modal}); 
    }

    getAllBusinessUints(){
        let businessUnitController = new BusinessUnitController ();
        var data = {
            expenseTypeId:"1000"
        }
        businessUnitController.getAllBusinessUnits(data).then(jsonGetBunits => {
            this.setState({ businessUnitGroups: JSON.parse(JSON.stringify(jsonGetBunits))});  
            this.setState({loadComplete:true})
        }).catch(error => {
            console.error("There was an error!", error);
        })
    }

    onSubmit(e:any){
        e.preventDefault();
         let modal = this.state.modal;
         modal['modalSpinnerShow']=true;
         this.setState({errMsg:""})        
         let businessUnitController = new BusinessUnitController();
        if(this.validateBUnit())
         {
            this.updateBunit();
            this.createBunit();
            
            alert('Business Unit Updated..');        
            this.props.history.push({pathname:'/admin/home'});
         }
        modal['modalSpinnerShow']=false;
        this.setState({modal}); 
          
    }

    updateBunit(){
        let businessUnitController = new BusinessUnitController();
        this.state.collBUnitId.split(',').map(str => {
            {this.state.businessUnitGroups.filter(p => p.businessUnit == str).map(f => {
                var data = {
                    doBy: "U",
                    businessUnit:f.businessUnit,
                    expenseTypeId: "",
                    description:f.description,
                    isActive: f.isActive                            
                }
                businessUnitController.createUpdateBusinessUnit(data).then(response => { 
                }).catch(error => {
                    console.error("There was an error!", error)
                })
            })}            
        });
        this.setState({collBUnitId : ""})
    }

    createBunit(){
        let businessUnitController = new BusinessUnitController();        
        if(this.state.fields.bUnitId != "")
        {           
            var dataSys = {
                doBy: "I",
                businessUnit: this.state.fields.bUnitId,
                expenseTypeId: "1000",
                description: this.state.fields.description,
                isActive: this.state.fields.isActive      
            }
            businessUnitController.createUpdateBusinessUnit(dataSys).then(response => { 
            }).catch(error => {
                console.error("There was an error!", error)
            })            
        }
    }

    validateBUnit(){        
        let fields = this.state.fields;
        let errorMessage ="";    
        let formIsValid = true;
        if(fields["bUnitId"] == "" && fields["description"] == "" && this.state.collBUnitId == "")
        {
            formIsValid = false;  
            alert('Updated Nothing!!');
        }
        if(fields["bUnitId"] != "" && fields["description"] == "")
        {         
            formIsValid = false;    
            errorMessage ="Please enter description in add business unit"        
            this.setState({errMsg : errorMessage});
        }
        else if(fields["bUnitId"] == "" && fields["description"] != "")
        {         
            formIsValid = false;    
            errorMessage ="Please enter new business unit"        
            this.setState({errMsg : errorMessage});
        }
        else{
            {this.state.businessUnitGroups.filter(p => p.businessUnit == fields["bUnitId"]).map(f => {
                formIsValid = false;    
                errorMessage ="Business unit already exists!!"        
                this.setState({errMsg : errorMessage});
            })}  
        }        
        return formIsValid;
    }

    handleChange(e:any){
        let fields = this.state.fields;      
        var numregex = new RegExp("^[0-9\b]+$");   
        if (e.currentTarget.name === 'bUnitId'){
            if (e.currentTarget.value === '' || numregex.test(e.currentTarget.value)) {
                 fields['bUnitId'] =  e.currentTarget.value;         
            }     
        }     
        else if (e.currentTarget.name === 'description'){
            fields['description'] =  e.currentTarget.value;              
        }           
        this.setState({ fields});
    }
    

    handleCheckboxChange(e:any){
        let fields = this.state.fields;        
        if (e.currentTarget.name === 'isActive'){
            fields['isActive'] = !fields['isActive'];
        }
                 
        this.setState({ fields});
    }

    handleDescriptionChange(i, event) {
        let collMsg =this.state.collBUnitId;
         let businessUnitGroups = [...this.state.businessUnitGroups];        
         businessUnitGroups[i]["description"] = event.target.value;
         this.setState({ businessUnitGroups });
         if(collMsg != "")
         {
             if(!collMsg.includes(businessUnitGroups[i]["businessUnit"]))
             collMsg = collMsg + businessUnitGroups[i]["businessUnit"]+","
         }
         else
         collMsg = businessUnitGroups[i]["businessUnit"]+","
 
         this.setState({ collBUnitId:collMsg });
    }

    handleCheckIsActiveChange(i, event) {
        let collMsg = this.state.collBUnitId;
        let businessUnitGroups = [...this.state.businessUnitGroups];        
        businessUnitGroups[i]["isActive"] = !businessUnitGroups[i]["isActive"]
        this.setState({ businessUnitGroups });
        if(collMsg != "")
        {
            if(!collMsg.includes(businessUnitGroups[i]["businessUnit"]))
            collMsg = collMsg + businessUnitGroups[i]["businessUnit"]+","
        }
        else
        collMsg = businessUnitGroups[i]["businessUnit"]+","    
        this.setState({ collBUnitId:collMsg });
    }

    displayActiveBunit=(item)=>{
        return (<p className={(item["isActive"] === true ? "approverSmall" : "approverSmallIsActive")}>
                 {item["businessUnit"]}</p>);          
    }

    render() {
        const{ loading } = this.state;
         const tdStyle = {
            width:'60%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
        const tdStylechk ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px" 
        };        
        const thStyle ={
            width:'60%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleChk ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Sysop - Int'l Rebill Business Unit Maintenance</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row>
                                       <Col sm={12}>
                                       <div>
                                       <Table hover>
                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                    <tr style={{textAlign:"center"}}>
                                                        <th style={thStyleChk}>Business Unit</th>
                                                        <th style={thStyle}>Description</th>
                                                        <th style={thStyleChk}>Active</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.businessUnitGroups.map((prop, key) => {
                                                        return (
                                                            <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                <td style={tdStylechk}>{this.displayActiveBunit(prop)}</td>
                                                                <td style={tdStyle}><input type="text" style={{width:'70%'}} maxLength={30} value={prop["description"]||''} disabled={prop["isActive"] ? false:true} onChange={this.handleDescriptionChange.bind(this, key)}/></td>
                                                                <td style={tdStylechk}><input type="checkbox" defaultChecked={prop["isActive"]} onChange={this.handleCheckIsActiveChange.bind(this, key)}/></td>
                                                            </tr>
                                                            
                                                        )
                                                     })}
                                                     {this.state.loadComplete ? 
                                                     <tr style={{textAlign:"center"}}>
                                                            <td style={tdStylechk}><input type="text" name="bUnitId" maxLength={4} value={this.state.fields.bUnitId} onChange={this.handleChange.bind(this)} /></td>
                                                            <td style={tdStyle}><input type="text" name="description" maxLength={30} style={{width:'70%'}} value={this.state.fields.description} onChange={this.handleChange.bind(this)}/></td>
                                                            <td style={tdStylechk}><input type="checkbox" name="isActive" defaultChecked={this.state.fields.isActive} onChange={this.handleCheckboxChange.bind(this)} /></td>                                                           
                                                     </tr> : ""}
                                                </tbody>
                                        </Table>                                                        
                                        </div>
                                        <div style={{textAlign:"center"}}>
                                        {this.state.loadComplete ? <p style={{textAlign:"left",color:"red",fontSize:"10px"}}>
                                         *Please enter business unit and description to add new business unit</p>:""}
                                        <Button id="submitBUnit" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}  variant="success">
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Save</span>}
                                            </Button>
                                        </div>

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                    <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>                                                                                                        
                                            </div>
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}