import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';
import { stringify } from 'querystring';

export class CreateTAController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    
    getTravelAgent (){
        var url = this.variables.Url+ this.variables.Controller.createTA + this.variables.createTAService.getTravelAgent;
        var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonTravelAgent : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(travelAgent);
                    function travelAgent(item: { User_ID: any; Name: any }){
                        jsonTravelAgent.push({"id": item.User_ID, "name": item.Name})
                    }
                    return JSON.parse(JSON.stringify(jsonTravelAgent).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonTravelAgent).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getCarServiceAgent (carServiceCompanyId:string){
        var url = this.variables.Url+ this.variables.Controller.createTA + this.variables.createTAService.getCarServiceAgent;
        var data = {
            carServiceCompanyId : carServiceCompanyId
        };
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonCarServiceAgent : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceAgent);
                    function carServiceAgent(item: { user_id: any; name: any }){
                        jsonCarServiceAgent.push({"id": item.user_id, "name": item.name})
                    }
                    return JSON.parse(JSON.stringify(jsonCarServiceAgent).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonCarServiceAgent).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getAuthorizationType (userId: string){
        var url = this.variables.Url+ this.variables.Controller.createTA + this.variables.createTAService.getAuthorizationType;
        var data = {
            userId: userId
        };
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonExpenseType : Array<{"id": string, "name": string}> = [];
                if(response.success){
                    response.recordset.forEach(expenseType);
                    function expenseType(item: { Expense_Type_ID: any; Description: any }){
                        jsonExpenseType.push({"id": item.Expense_Type_ID, "name": item.Description})
                    }
                    return JSON.parse(JSON.stringify(jsonExpenseType).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonExpenseType).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    createTA (data){
        var url = this.variables.Url+ this.variables.Controller.createTA + this.variables.createTAService.createTA;
        var data1 = {
            departmentId: data.departmentId,
            labelId: data.labelId,
            expenseTypeId: data.expenseTypeId,
            weight: data.weight,
            artistProjectName: data.artistProjectName,
            artistProjectNumber: data.artistProjectNumber,
            artistName: data.artistName,
            businessUnit: data.businessUnit,
            sb2: data.sb2,
            travelAgentName: data.travelAgentName,
            isCorporateCard: data.isCorporateCard,
            carServiceOnly: data.carServiceOnly,
            carServiceCompanyId: data.carServiceCompanyId,
            inPolicy: data.inPolicy,
            createdBy: data.createdBy,
            updatedBy: data.updatedBy
        }
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

}