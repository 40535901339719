import React, { Component, CSSProperties } from "react";
import { ICarServiveMainAddEditParameter, Istate } from "../../../Model/ICarServiceMainAddEdit";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CarServiceMaintenanceController } from "../../../Controllers/CarServiceMaintenanceController";
import { ApprovalDiagramController } from "../../../Controllers/ApprovalDiagramController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { isNull } from "util";

export class CarServiceMainAddEdit extends Component<ICarServiveMainAddEditParameter,Istate> {
    constructor(props:ICarServiveMainAddEditParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {carServiceCompanyId:'',isInsert:false,isUpdate:false,isDelete:false,labelId:'',labelName:'',expTypeId:'',expenseTypeName:'',accNo:'',ccAccNo:''},
            selectfields:{labelId:'',labelName:'',expenseTypeId:'',expenseTypeName:''},
            errors:{labelName:'',expenseTypeName:'',accNo:'',ccAccNo:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            carServiceGroups:[],
            labelGroups:[],
            expenseTypeGroups:[],
            labelDesc:"",
            labelId:"",
            carServiceCompanyName:"",
            carServiceCompanyId:"",
            loadComplete:false           
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSaveUpdate = this.onSaveUpdate.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;      
        this.getCarServiceMaintenance();
        this.getLabel();
        this.getExpenseType();       
        this.setState({ modal,}); 
    }

    getCarServiceMaintenance(){
        let modal = this.state.modal; 
        modal['modalSpinnerShow']=true;
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        var data={
            labelId:this.state.selectfields.labelId,
            expenseTypeId:this.state.selectfields.expenseTypeId,
            companyId:this.props.location.state.companyId
        }
        carServiceMaintenanceController.getCarserviceDetails(data).then(jsoncarServiceMaitenance => {
            this.setState({ carServiceGroups: JSON.parse(JSON.stringify(jsoncarServiceMaitenance))});             
            //console.log(this.state.carServiceGroups)            
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })
        this.setState({modal})
    }
    
    getLabel(){
        let modal = this.state.modal;
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        carServiceMaintenanceController.getAllLabels().then(jsonGetLabels => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonGetLabels))});             
            //console.log(this.state.labelGroups) 
            this.setState({loadComplete:true})
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })
        this.setState({modal})
    }

    getExpenseType(){
        let modal = this.state.modal;
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        carServiceMaintenanceController.getExpenseType().then(jsonGetExpenseType => {
            this.setState({ expenseTypeGroups: JSON.parse(JSON.stringify(jsonGetExpenseType))});             
            //console.log(this.state.expenseTypeGroups)            
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })
        modal['modalSpinnerShow']=false;
        this.setState({modal})
    }

    handleSelectChange(e:any){  
        let modal = this.state.modal;       
        modal['modalSpinnerShow']=true;      
        let selectfields = this.state.selectfields;
        if (e.currentTarget.id === 'labelId'){
            if(e.currentTarget.value != "All")
            {
                selectfields["labelId"] = e.currentTarget.value;
                {this.state.labelGroups.filter(p => p.id == e.currentTarget.value).map(f => {
                    selectfields["labelName"] = f.name;
                    //console.log(f.name)
                })}                
                this.setState({labelId:e.currentTarget.value})
            }
            else
            {
                selectfields["labelId"] =""
                selectfields["labelName"] = "";
            }
        }  
        if (e.currentTarget.id === "expenseTypeId") {
            if(e.currentTarget.value != "All")
            {
                selectfields[e.currentTarget.id] = e.currentTarget.value;   
                {this.state.expenseTypeGroups.filter(p => p.id == e.currentTarget.value).map(f => {
                    selectfields[e.currentTarget.name] = f.name;
                    //console.log(f.name)
                })}        
           
            }     
            else{
                selectfields[e.currentTarget.id] = "";
                selectfields[e.currentTarget.name] ="";
            }    
          }     
        this.setState({selectfields});
        this.clearText()
        this.getCarServiceMaintenance()         
        modal['modalSpinnerShow']=false;
    }

    handleTextChange(e:any){  
        let fields = this.state.fields;
        var numregex = new RegExp("^[0-9\b]+$");
        if (e.currentTarget.value === '' || numregex.test(e.currentTarget.value)) {
            if (e.currentTarget.id === "accNo"){
                fields[e.currentTarget.id] = e.currentTarget.value;
                
            }  
            if (e.currentTarget.id === "ccAccNo") {
                fields[e.currentTarget.id] = e.currentTarget.value; 
            } 
        }    
        this.setState({fields});       
    }

    onEdit = (e:any,item) => {
        e.preventDefault();
         let modal = this.state.modal;
         let fields = this.state.fields;
         modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})        
        
        if(item["carServiceCompanyId"] === "" || item["carServiceCompanyId"] === null){
            fields["isInsert"] = true;
            fields["isUpdate"] = false;
        }       
        else{
            fields["isUpdate"] = true;
            fields["isInsert"] = false;
        }
            
        if(item["labelName"] === "" || item["labelName"] === null){
            fields["labelName"] = this.state.selectfields.labelName
            fields["labelId"] = this.state.selectfields.labelId
        }
        else{
            fields["labelName"] = item["labelName"]
            fields["labelId"] = item["labelId"]
        }
           
       
        if(item["accountNumber"] === "" || item["accountNumber"] === null){
            fields["accNo"]  = ""
        }       
        else{
            fields["accNo"]  = item["accountNumber"]
        }
        if(item["ccAccountNumber"] === "" || item["ccAccountNumber"] === null){
            fields["ccAccNo"]  = ""
        }       
        else{
            fields["ccAccNo"]  = item["ccAccountNumber"]
        }
        fields["expenseTypeName"] = item["expTypeName"]   
        fields["expTypeId"] = item["expTypeId"]       
        modal['modalSpinnerShow']= false;
        this.setState({modal,fields}); 
          
    }

    onDelete = (e:any,item) => {
        e.preventDefault();
        let modal = this.state.modal;        
        modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})        
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        var data={
            doBy : "D",
            carServiceCompanyId:this.props.location.state.companyId,
            labelId : item["labelId"],
            expenseTypeId :  item["expTypeId"],
            accNo : "",
            cCAccNo : ""
        }
        carServiceMaintenanceController.inserUpdateDeleteCarService(data).then(response => { 
            alert('Car Service Maintenance Deleted!!!');
        }).catch(error => {
            console.error("There was an error!", error)
            modal['modalSpinnerShow']=false;
        })
        this.getCarServiceMaintenance()
        modal['modalSpinnerShow']= false;
        this.setState({modal});           
    }

    onSaveUpdate = (e:any) => {
        e.preventDefault();
         let modal = this.state.modal;
         let fields = this.state.fields;
         modal['modalSpinnerShow']=true;
        this.setState({errMsg:""})        
        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        if(this.validateSave())
        {
             if(fields["isInsert"])
            {
                var data={
                    doBy : "I",
                    carServiceCompanyId:this.props.location.state.companyId,
                    labelId : fields["labelId"],
                    expenseTypeId : fields["expTypeId"],
                    accNo : fields["accNo"],
                    cCAccNo : fields["ccAccNo"]
                }
                carServiceMaintenanceController.inserUpdateDeleteCarService(data).then(response => { 
                    alert('Car Service Maintenance Inserted!!!');
                }).catch(error => {
                    console.error("There was an error!", error)
                    modal['modalSpinnerShow']=false;
                })
            }
            else if(fields["isUpdate"])
            {
                var data={
                    doBy : "U",
                    carServiceCompanyId:this.props.location.state.companyId,
                    labelId : fields["labelId"],
                    expenseTypeId : fields["expTypeId"],
                    accNo : fields["accNo"],
                    cCAccNo : fields["ccAccNo"]
                }
                carServiceMaintenanceController.inserUpdateDeleteCarService(data).then(response => { 
                    alert('Car Service Maintenance Updated!!!');
                }).catch(error => {
                    console.error("There was an error!", error)
                    modal['modalSpinnerShow']=false;
                })
            }
            this.getCarServiceMaintenance()
            this.clearText()       
        }  
        modal['modalSpinnerShow']=false;      
    }
    validateSave(){
        let fields = this.state.fields;
        let formIsValid = true;
        if(fields["labelName"] === "")
        {
            alert('Please edit..');
            formIsValid=false;
        }
        else
        {
            if(fields["accNo"] === "" && fields["ccAccNo"] === "")
            {
                alert('Please Account No or CC Account No');
                formIsValid=false;
            }
        }
        return formIsValid;
    }


    clearText(){
        let fields = this.state.fields;
        fields["isInsert"]=false
        fields["isUpdate"]=false
        fields["isDelete"]=false
        fields["labelId"]=""
        fields["labelName"]=""
        fields["expTypeId"]=""
        fields["expenseTypeName"]=""
        fields["accNo"]=""
        fields["ccAccNo"]=""
        this.setState({fields})    
    }

    displayActiveLabel=(item)=>{
        return (<p className={((item["carServiceCompanyId"] === "" || item["carServiceCompanyId"] === null) ? "approverSmallIsActive" : "approverSmall")}>
                 {((item["labelName"] === "" || item["labelName"] === null) ? this.state.selectfields.labelName : item["labelName"])}</p>);          
     }

     displayActiveExpType=(item)=>{
        return (<p className={((item["carServiceCompanyId"] === "" || item["carServiceCompanyId"] === null) ? "approverSmallIsActive" : "approverSmall")}>
                 {item["expTypeName"]}</p>);          
     }

     formLabel = () => {
        return <Form.Group>
          <Form.Label>Form Label</Form.Label>
          <Form.Control as="select" className="form-control form-control-sm" id="labelId" name="labelName" defaultValue="All" value={this.state.selectfields.labelId} onChange={this.handleSelectChange}>
            <option value="All">All</option>
            {this.state.labelGroups.map((prop, key) => {
              if (prop["isActive"]) {
                return (
                  <option value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else {
                return (
                  <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                )
              }
            })}
          </Form.Control>
        </Form.Group>
      }

    render() {
        const{ loading } = this.state;
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
        const tdStylechk ={
            width:'3%',            
            border: "2px solid #eee",
            padding:"2px" 
        };        
        const thStyle ={
            width:'50%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleChk ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Car Service Maintenance - {this.props.location.state.companyName}</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row>
                                       <Col sm={4}>
                                            {this.formLabel()}
                                        </Col>
                                        <Col sm={2}>
                                        <FormSelect
                                                properties={{ label: "Select Expense Type", id: "expenseTypeId", name: "expenseTypeName", value: this.state.selectfields.expenseTypeId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                                fieldRequired={false}
                                                errorMsg={this.state.errors.labelId}
                                                defaultOption="All"
                                                fillOption={this.state.expenseTypeGroups}
                                            />
                                        </Col>                                    
                                    </Row>
                                    <Row>
                                        <Col sm={2}>
                                            <FormInputs
                                                properties={{id:"labelName",label:"Label",type:"text",name:"labelName",value:this.state.fields.labelName,className:"form-control input-sm" }}
                                                errorMsg={this.state.errors.labelName}
                                                fieldRequired={false}
                                                disbled={true}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <FormInputs
                                                properties={{id:"expenseTypeName",label:"Expense Type",type:"text",name:"expenseTypeName",value:this.state.fields.expenseTypeName,className:"form-control input-sm" }}
                                                errorMsg={this.state.errors.expenseTypeName}
                                                fieldRequired={false}
                                                disbled={true}                                                
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <FormInputs
                                                properties={{id:"accNo",label:"Account No",type:"text",name:"accNo",value:this.state.fields.accNo,className:"form-control input-sm",maxlength:4,onChange: this.handleTextChange }}
                                                errorMsg={this.state.errors.accNo}
                                                fieldRequired={false} 
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <FormInputs
                                                properties={{id:"ccAccNo",label:"CC Acct No",type:"text",name:"ccAccNo",value:this.state.fields.ccAccNo,className:"form-control input-sm",maxlength:4,onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.ccAccNo}
                                                fieldRequired={false}    
                                            />
                                        </Col>
                                        <Col sm={2} style={{paddingTop:"2.5%"}}>
                                            <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onSaveUpdate(e)} variant="success" >
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Add/Update</span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>                                        
                                       <Col sm={12}>
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th style={thStyleChk}>Family</th>
                                                                <th style={thStyleChk}>TA Type</th>
                                                                <th style={thStyleChk}>Account Number</th>  
                                                                <th style={thStyleChk}>CC Acct Number</th> 
                                                                <th style={thStyleChk}>Process</th>                                                      
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.carServiceGroups.map((prop, key) => {
                                                                return (
                                                                    <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                       <td style={tdStyle}>{this.displayActiveLabel(prop)}</td>
                                                                       <td style={tdStyle}>{this.displayActiveExpType(prop)}</td>
                                                                       <td style={tdStyle}>{prop["accountNumber"]}</td>
                                                                       <td style={tdStyle}>{prop["ccAccountNumber"]}</td>
                                                                       <td style={tdStyle}>
                                                                        <tr>
                                                                            <td style={{borderTop:"0px"}}>
                                                                                <Button id="edit" type="button" size="sm" disabled={loading} onClick={(e) => this.onEdit(e,prop)} >
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Edit</span>}
                                                                                </Button>
                                                                            </td>
                                                                            {(prop["carServiceCompanyId"] === "" || prop["carServiceCompanyId"] === null) ? "" :
                                                                            <td style={{paddingLeft:"1px",borderTop:"0px"}}>
                                                                                <Button id="delete" type="button" size="sm" disabled={loading} onClick={(e) => this.onDelete(e,prop)}  variant="danger">
                                                                                    {loading && (
                                                                                    <i
                                                                                        className="fa fa-refresh fa-spin"
                                                                                        style={{ marginRight: "5px" , color:"#3472F7",paddingLeft:"5px" }}
                                                                                    />
                                                                                    )}
                                                                                    {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                                    {!loading && <span>Delete</span>}
                                                                                </Button>          
                                                                            </td>}
                                                                        </tr>
                                                                       </td>
                                                                    </tr>
                                                                    
                                                                )
                                                            })}                                                            
                                                        </tbody>
                                                </Table>                                                        
                                            </div>
                                        

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}
