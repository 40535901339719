import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class ReportController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getLabels(){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getLabels;
        //console.log(url)
        var data = {                   
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string;isChecked:boolean,isActive:boolean}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { Label_ID: string; Description: string,Is_Active:any; }, _index: number){
                    jsonLabel.push({"id": item.Label_ID, "name": item.Description,"isChecked":false,"isActive":item.Is_Active})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getActiveLabels(){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getActiveLabels;
        //console.log(url)
        var data = {                   
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonLabel : Array<{"id": string; "name": string;isChecked:boolean,isActive:boolean}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { Label_ID: string; Description: string,Is_Active:any; }, _index: number){
                    jsonLabel.push({"id": item.Label_ID, "name": item.Description,"isChecked":false,"isActive":item.Is_Active})
                }
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getCtmReportsHfm(datas){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getCtmReportsHfm;
        return this.serviceProxy.get(url,datas)
        .then(response => {
            return response
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getStatusTypeCodesList(){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getStatusTypeCodesList;
        //console.log(url)
        var data = {                   
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonStatusCode : Array<{"id": string; "name": string;"isChecked":boolean}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { Status_ID: string; Description: string }, _index: number){
                    jsonStatusCode.push({"id": item.Status_ID, "name": item.Description,"isChecked":false})
                }
                return JSON.parse(JSON.stringify(jsonStatusCode).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonStatusCode).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getCallLetters(){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getCallLetters;
        //console.log(url)
        var data = {                   
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonCallLetters : Array<{"id": string;"isChecked":boolean}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { Call_Letters: string }, _index: number){
                    jsonCallLetters.push({"id": item.Call_Letters,"isChecked":false})
                }
                return JSON.parse(JSON.stringify(jsonCallLetters).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonCallLetters).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getDepartments(){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getDepartments;
        //console.log(url)
        var data = {                   
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonDept : Array<{"id": string; "name": string;"labelId":string;isChecked:boolean}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { Department_ID: string; Description: string;Label_ID:string }, _index: number){
                    jsonDept.push({"id": item.Department_ID, "name": item.Description,isChecked:false,"labelId":item.Label_ID})
                }
                return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonDept).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }

    getYears(){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.getYears;
        //console.log(url)
        var data = {                   
        }
        return this.serviceProxy.get(url,data)
        .then(response => {
            var jsonYear : Array<{"id": string; "name": string}> = [];
            if(response.success){
                response.recordset.forEach(label);
                function label(item: { travel_date: string; }, _index: number){
                    jsonYear.push({"id": item.travel_date, "name": item.travel_date})
                }
                return JSON.parse(JSON.stringify(jsonYear).replace(/"\s+|\s+"/g, '"'));
            }
            else{
                return JSON.parse(JSON.stringify(jsonYear).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
    
    sendLabels(data){
        var url = this.variables.Url + this.variables.Controller.report + this.variables.reportService.sendLabels;
        //console.log(data,"data serc")
        
        return this.serviceProxy.post(url,data)
        .then(response => {
            // var jsonLabel : Array<{"id": string; "name": string;isChecked:boolean,isActive:boolean}> = [];
            if(response.success){
                
                return response
            }
            else{
                // return JSON.parse(JSON.stringify(jsonLabel).replace(/"\s+|\s+"/g, '"'));
                console.error('There was an error!')
            }
        }).catch(error => {
            console.error('There was an error!', error);
        }); 
    }
}