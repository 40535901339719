import React, { Component } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import { NavLink } from 'react-router-dom'
import {FormInputs} from '../../CustomComponents/FormInput'
import {CommonController} from "../../../Controllers/CommonController"
import {MenuController} from "../../../Controllers/MenuController"
import { UserDetailsController } from "../../../Controllers/UserDetailsController";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';
import { IUserParameter, Istate } from "../../../Model/IUser";

export class User extends Component<IUserParameter,Istate> {
  constructor(props:IUserParameter){
    super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin === null){
            loggedIn = false;
        }
        this.state={
            fields: {search:'*',title:'',userprocess:''},
            checkfields: {},
            errors: {search:''},
            errMsg: "",
            users: [],
            loading: false,
            loggedIn: false,
            modal: {modalSpinnerShow:false}
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onUser = this.onUser.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        let fields = this.state.fields;
        fields["title"]="Welcome to eTA New User Registration";
        modal['modalSpinnerShow']=true;
        modal['modalSpinnerShow']=false;
        this.setState({ modal,fields })
    }

    handleChange(e:any){
        let fields= this.state.fields;
        fields[e.currentTarget.name] = e.currentTarget.value.trim();
        this.setState({ fields });
    }

    ValidateProfile(){
        return true;
    }

    onSubmit(e:any){
        this.setState({ loading: true });
        e.preventDefault();
        if (this.ValidateProfile()) {
            var searchValue = this.state.fields.search.replace('*','%');
            var type = '';
            if(sessionStorage.getItem('sysop') !== 'sysAdmin' && sessionStorage.getItem('shadow') !== 'sysAdmin'){
                type = 'notSysAdmin';
            }    
            this.setState({ errMsg: "" })
            let userDetailsController = new UserDetailsController();
            var dataSearch={
                searchValue:searchValue,
                type:type
            }
            userDetailsController.getUserSearch(dataSearch).then(resUsers => {
                
                if(resUsers.recordset.length === 0){
                    this.setState({ errMsg: "No User Found", users: [] });
                    this.setState({ loading: false });
                }
                else{
                    var jsonUsers : Array<{"userId": string, "name": string, "role": string, "label": string, "department": string}> = [];
                    resUsers.recordset.forEach(users);
                    function users(item: { user_id: any; Name: any; role: any; family: any; department: any }){
                        jsonUsers.push({"userId": item.user_id, "name": item.Name, "role": item.role, "label": item.family, "department": item.department})
                    }
                    this.setState({users: JSON.parse(JSON.stringify(jsonUsers))});
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.error("There was an error!", error);
            })
        }
    }

    onUser(action, userId){
        if (action === 'N'){
            this.props.history.push({pathname:'/admin/usrmain',state:{useraction:"New",userId:''}});
        }else{
            this.props.history.push({pathname:'/admin/usrmain',state:{useraction:"Edit",userId: userId}});
        }
    }
    cancelButton(){
        
        let fields = this.state.fields;
        fields['search'] = '*';
        this.state.users.splice(0,this.state.users.length);
        this.setState(fields)
    }
    render() {
        if (this.state.loggedIn === false){
            //return <Redirect to="/" />
        }
        const { loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">{this.state.fields.title}</h4><p className="category"></p></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Form onSubmit = {this.onSubmit}>
                                    {(this.state.fields.userprocess === '')?
                                        <>
                                        <Row>
                                            <Col sm={4}></Col>
                                            <Col sm={4}>
                                                <FormInputs
                                                    properties={{id:"search",label:"Search (Global Id/Name):",type:"text",name:"search",value:this.state.fields.search,className:"form-control input-sm",maxlength:50,onChange:this.handleChange}}
                                                    errorMsg={this.state.errors.search}
                                                    fieldRequired={true}
                                                />
                                            </Col>
                                            <Col sm={4}></Col>
                                        </Row>
                                        <Row>
                                            <Col sm={5}></Col>
                                            <Col sm={1}>
                                                <Button type="button" size="sm" style={{float:"right"}} onClick={() => {this.onUser('N','')}}>
                                                   New User
                                                </Button>
                                            </Col>
                                            <Col sm={1}>
                                                <Button type="submit" size="sm" disabled={loading} style={{marginRight: "10px", float:"right"}}>
                                                    {loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" , color:"#487afa" }}
                                                    />
                                                    )}
                                                    {loading && <span style={{color:"#487afa"}} >wait...</span>}
                                                    {!loading && <span>Search</span>}
                                                </Button>
                                            </Col>
                                            <Col sm={1}>
                                                <Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.cancelButton()}>Cancel</Button>
                                            </Col>
                                            <Col sm={4}></Col>
                                        </Row>
                                        </>
                                        :
                                        ''
                                    }
                                </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
                {Object.keys(this.state.users).length === 0 ?
                <></>
                :
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Select User Profile</h4><p className="category"></p></div>
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                    <div className="scroll" style={{width: '100%'}}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>User Id</th>
                                                    <th>Name</th>
                                                    <th>User Type</th>
                                                    <th>Label</th>
                                                    <th>Department</th>
													<th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.users.map((prop, key) => {
                                                    return(
                                                        <tr key={key}>
                                                            <td>{prop["userId"]}</td>
                                                            <td>{prop["name"]}</td>
                                                            <td>{prop["role"]}</td>
                                                            <td>{prop["label"]}</td>
                                                            <td>{prop["department"]}</td>
                                                            <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={() => {this.onUser('E',prop["userId"])}}>Edit</Button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
            </Container>
        </div>)
    }
}