import React, { Component } from "react";
import {History, LocationState} from "History";
import * as Utils from '../../../js/Utils.js';
import { Row, Col } from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css';
import { CommonController } from "../../../Controllers/CommonController";
import { TAInclude } from "../../../Common/TAInclude";
import { TravellerController } from "../../../Controllers/TravellerController";
import * as Constants from "../../../js/Constants";

export class TASummary extends Component<IProperties, Istate> {
    constructor(props:IProperties){
        super(props)
        this.state={
            fields:{taCoding:true, taCodingOK:false, bAirOK:true, bAspOK:true, bHtlOK:true, bHspOK:true, bCarOK:true, bCspOK:true, bSvcOK:true, bSspOK:true, bAir:false,bHotel:false,bRentalCar:false,bCarService:false,bTravelerInfoFormRequired:false},
            modal: {modalSpinnerShow: false},
            assignedTATraveler:[],
            unassignedTATraveler:[],
            errMsg:"",
            loading:false
        }
    }

    async componentWillMount(){
        
        //console.log("child component will mount")
        let fields = this.state.fields;
        let modal = this.state.modal;
        modal["modalSpinnerShow"]=true;
        this.setState({modal});
        //console.log("count:","1")
        let taInclude = new TAInclude();
        let ta = await this.getTA();
        //console.log("count:","2")
        fields['taCoding'] = await taInclude.taCoding("");
        //console.log("count:","3")
        fields['bAirOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","AIR",Constants.airBooking);
        //console.log("count:","4")
        fields['bAspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","ASP",Constants.airBooking);
        //console.log("count:","5")
        fields['bHtlOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","HTL",Constants.hotelBooking);
        //console.log("count:","6")
        fields['bHspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","HSP",Constants.hotelBooking);
        //console.log("count:","7")
        fields['bCarOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","CAR",Constants.rentalCarBooking);
        //console.log("count:","8")
        fields['bCspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","CSP",Constants.rentalCarBooking);
        //console.log("count:","9")
        fields['bSvcOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","SVC",Constants.carServiceBooking);
        //console.log("count:","10")
        fields['bSspOK'] =  await taInclude.taCodingOKRequest(sessionStorage.getItem('ta')!,"","SSP",Constants.carServiceBooking);
        //console.log("count:","11")
        this.setState({fields});
        if (!this.state.fields.taCoding){
            fields['bAir'] = true;
            fields['bHotel'] = true;
            fields['bRentalCar'] = true;
            fields['bCarService'] = true;
        }
        else{
            fields['bAir'] = (this.state.fields.bAirOK && this.state.fields.bAspOK) ? true : false;
            fields['bHotel'] = (this.state.fields.bHtlOK && this.state.fields.bHspOK) ? true : false;
            fields['bRentalCar'] = (this.state.fields.bCarOK && this.state.fields.bCspOK) ? true : false;
            fields['bCarService'] = (this.state.fields.bSvcOK && this.state.fields.bSspOK) ? true : false;
        }
        fields['bTravelerInfoFormRequired'] = await this.travelerInfoFormRequired(sessionStorage.getItem('ta'));
        fields["taCodingOK"] = this.taCodingOK();
        this.tatraveler();
        this.setState({fields});
        //console.log("TASummary - fields", this.state.fields);
        //console.log("TASummary - taCodingOk", this.state.fields.taCodingOK);
        this.props.parentCallback(this.state.fields.taCodingOK);
        modal["modalSpinnerShow"]=false;
        this.setState({modal});
        //console.log("child component will mount");
    }

    async travelerInfoFormRequired(authReqId){
        let commonController = new CommonController();
        return await commonController.getTravelerInfoFormRequired(authReqId).then(response => {
            
            return response.Travel_Form;
        }).catch(error => {
            console.error("There was an error!", error)
        });
    }

    tatraveler(){
        let commonController = new CommonController();
        var data={};
        data={
            id:'',
            view:'assigned',
            bInner:'false',
            sessionTA:sessionStorage.getItem("ta")
        }
        commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                assignedTATraveler : JSON.parse(JSON.stringify(jsonTravellers)),
            });
            //console.log("assignedTATraveler, ",this.state.assignedTATraveler)
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
        data={
            id:'',
            view:'unassigned',
            bInner:'false',
            sessionTA:sessionStorage.getItem("ta")
        }
        commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                unassignedTATraveler : JSON.parse(JSON.stringify(jsonTravellers))
            });
            //console.log("unassignedTATraveler, ",this.state.unassignedTATraveler)
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
    }

    formatCurrency(amount){
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    travelerView(prop,key,tastatus){
        
            return <tr style={{border:"1px solid #000000"}}>
            {(key === 0)?
                <td style={{backgroundColor:"LightCyan",padding:"2px", textAlign:"left", border:"1px solid #000000"}}>{tastatus +':'}</td>
            :
                <td style={{backgroundColor:"LightCyan"}}>&nbsp;</td>
            }
            <td title={this.tdTitle(prop)} style={{textAlign:"left", border:"1px solid #000000"}}>
                {prop.name}&nbsp;&nbsp;{prop.RLN}
                {(prop.isDH)?<small>(flagged for Sr. Exec. Approval)</small>:<></>}
            </td>
            {(this.state.fields.bTravelerInfoFormRequired)? 
                (prop.fromOnFile)? 
                <td style={{padding:"2px",textAlign:"center"}}>Y</td>
                    :
                (!prop.fromOnFile)?
                    <td style={{color:"red",padding:"2px",textAlign:"center"}}>N</td>
                        :
                    <td style={{padding:"2px",textAlign:"center"}}>&nbsp;</td>
                :
                <></>
            }
            {(this.state.fields.bTravelerInfoFormRequired)? 
                (prop.travelRestrictions === 1)? 
                    <td style={{padding:"2px",textAlign:"center"}}>Y</td>
                :
                    (prop.travelRestrictions === 0)?
                        <td style={{padding:"2px",textAlign:"center"}}>N</td>
                    :
                        (prop.travelRestrictions === 2)?
                            <td style={{padding:"2px",textAlign:"center"}}>N</td>
                        :
                            <td style={{padding:"2px",textAlign:"center"}}>&nbsp;</td>
            :
                <></>
            }
        </tr>
    }

    tdTitle(prop){  
        //2-Contest Winner ,4-Radio Personnel
        return (prop.type === 2)?
            "Reason For Travel:" + prop.travelPurpose + ' ' +
            "Addr: " + prop.address + ' ' +
            "City: " + prop.city + ' ' +
            "State/Zip: " + prop.stateProvinceId + ' ' + prop.zip + ' ' +
            ((prop.ssn === "")? "Federal ID:" + prop.fedId : "SSN:" + prop.fedId) + ' ' +
            "Phone: " + prop.phone + ' ' +
            "Call Letters: " + prop.callLetters + ' ' +
            "1099 Issuer: "+ ((prop.issuer !== null)?prop.issuer:'') + ' ' +
            "Contest Letter Received: " + prop.radioLetterReceived + ' ' +
            ((prop.radioLetterReceived === "N")? "Override Received: " + prop.overrideReceived : "")
            :
            (prop.type === 4) ?
           "Reason For Travel: " + prop.travelPurpose + ' ' +
             "Call Letters: " + prop.callLetters + ' ' +
             "Compliance Officer Approval: " + prop.preapprovalReceived : ""
    }

    async getTA(){
        //console.log("AuthReqId:",sessionStorage.getItem("ta"));
        //
        let travellerController = new TravellerController()
        return await travellerController.getTA(sessionStorage.getItem("ta")!).then(response => {
            //console.log("get TA")
            return response;
        }).catch(error => {
            console.error("There was an error!", error)
        }) 
    }
    
    taCodingOK(){
        if (sessionStorage.getItem("ta_is_corporate_card") === 'true' || !this.state.fields.taCoding){
            return true;
        }
        else{
            if (this.state.fields.bAirOK && this.state.fields.bAspOK && this.state.fields.bHtlOK && this.state.fields.bHspOK && this.state.fields.bCarOK && this.state.fields.bCspOK && this.state.fields.bSvcOK && this.state.fields.bSspOK){
                return true;
            }
            else{
                return false;
            }
        }
    }

    render() {
        //console.log("child render")
        return (
        <Row className="text-dark font-size-xs small">
            <Col sm={4} style={{textAlign:"center"}}></Col>
            <Col sm={4} style={{textAlign:"center"}}>
            <div style={{marginTop:"20px"}}></div>
            <table style={{width:"100%"}}>
            <tr style={{backgroundColor:"PowderBlue", border:"1px solid #000000"}}>
                <th style={{padding:"2px",width:"25%", border:"1px solid #000000"}}></th>
                <th style={{padding:"2px",width:"25%", border:"1px solid #000000"}}>TA</th>
                {(sessionStorage.getItem("ta_is_corporate_card")==='false')?<th style={{padding:"2px",width:"25%", border:"1px solid #000000"}}>Coding</th>:""}
                <th style={{padding:"2px",width:"25%", border:"1px solid #000000"}}>Travelers</th>
            </tr>
            {(sessionStorage.getItem("ta_has_air")==='true')?
            <tr>
                <td style={{backgroundColor:"LightCyan",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>Air</td>
                <td style={{padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{this.formatCurrency(sessionStorage.getItem("ta_air_total"))}</td>
                {(sessionStorage.getItem("ta_is_corporate_card") === 'false')?
                    <td style={{color: (!this.state.fields.bAir)? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>
                    {this.formatCurrency(sessionStorage.getItem("ta_air_coding"))}</td>
                    :
                    <></>
                    }
                    <td style={{color: (sessionStorage.getItem("ta_air_missing_traveler") === 'true')? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{sessionStorage.getItem("ta_air_traveler_count")}</td>
                <></>
            </tr>
            :
            <></>}
            {(sessionStorage.getItem("ta_has_hotel")==='true')?
            <tr>
                <td style={{backgroundColor:"LightCyan",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>Hotel</td>
                <td style={{padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{this.formatCurrency(sessionStorage.getItem("ta_hotel_total"))}</td>
                {(sessionStorage.getItem("ta_is_corporate_card") === 'false')?
                    <td style={{color: (!this.state.fields.bAir)? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>
                    {this.formatCurrency(sessionStorage.getItem("ta_hotel_coding"))}</td>
                    :
                    <></>
                    }
                    <td style={{color: (sessionStorage.getItem("ta_hotel_missing_traveler") === 'true')? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{sessionStorage.getItem("ta_hotel_traveler_count")}</td>
                <></>
            </tr>
            :
            <></>}
            {(sessionStorage.getItem("ta_has_rental_car")==='true')?
            <tr>
                <td style={{backgroundColor:"LightCyan",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>Rental Car</td>
                <td style={{padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{this.formatCurrency(sessionStorage.getItem("ta_rental_car_total"))}</td>
                {(sessionStorage.getItem("ta_is_corporate_card") === 'false')?
                    <td style={{color: (!this.state.fields.bAir)? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>
                    {this.formatCurrency(sessionStorage.getItem("ta_rental_car_coding"))}</td>
                    :
                    <></>
                    }
                    <td style={{color: (sessionStorage.getItem("ta_rental_car_missing_traveler") === 'true')? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{sessionStorage.getItem("ta_rental_car_traveler_count")}</td>
                <></>
            </tr>
            :
            <></>}
            {(sessionStorage.getItem("ta_has_car_service")==='true')?
            <tr>
                <td style={{backgroundColor:"LightCyan",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>Car Service</td>
                <td style={{padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{this.formatCurrency(sessionStorage.getItem("ta_car_service_total"))}</td>
                {(sessionStorage.getItem("ta_is_corporate_card") === 'false')?
                    <td style={{color: (!this.state.fields.bAir)? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>
                    {this.formatCurrency(sessionStorage.getItem("ta_car_service_coding"))}</td>
                    :
                    <></>
                    }
                    <td style={{color: (sessionStorage.getItem("ta_car_service_missing_traveler") === 'true')? "red":"black",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{sessionStorage.getItem("ta_car_service_traveler_count")}</td>
                <></>
            </tr>
            :
            <></>}
            <tr>
                <td style={{backgroundColor:"White",padding:"2px", textAlign:"right", border:"1px solid #000000"}}>Total</td>
                <td style={{padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{this.formatCurrency(sessionStorage.getItem("ta_total"))}</td>
                {(sessionStorage.getItem("ta_is_corporate_card") === 'false')?
                    <td  style={{padding:"2px", textAlign:"right", border:"1px solid #000000"}}>{this.formatCurrency(sessionStorage.getItem("ta_coding_total"))}</td>
                :
                    <></>
                }
                <td style={{border:"1px solid #000000"}}>&nbsp;</td>
            </tr>
            </table>
            {(!this.state.fields.taCodingOK)?
                <table style={{width:"100%"}}>
                    <tr><td colSpan={2} style={{color:"red", padding:"2px",textAlign: "center"}}>Coding is incomplete</td></tr>
                </table>
                :
                <></>
            }
            {(sessionStorage.getItem("ta_air_missing_traveler")==='true' || sessionStorage.getItem("ta_hotel_missing_traveler")==='true' || sessionStorage.getItem("ta_rental_car_missing_traveler")==='true' || sessionStorage.getItem("ta_car_service_missing_traveler")==='true')?
                    <table style={{width:"100%"}}>
                    <tr><td colSpan={2} style={{color:"red", textAlign: "center"}}>Traveler(s) have not been assigned</td></tr>
                    </table>
                :
                <></>
            }
            {(sessionStorage.getItem("ta_non_disputable_hotel")==='true')?
                    <table style={{width:"100%"}}>
                    <tr><td colSpan={2} style={{color:"red", textAlign: "center"}}>TA includes Non-Disputable Hotel</td></tr>
                    </table>
                :
                <></>
            }
            <table style={{border:"1px Navy solid",  width:"100%", marginTop:"15px"}}>
            {(Number(sessionStorage.getItem("ta_traveler_count")) > 0)?
                    ((this.state.fields.bTravelerInfoFormRequired)?
                        <div>
                        <tr style={{backgroundColor:"PowderBlue"}}>
                            <th style={{padding:"2px"}} colSpan={2} rowSpan={2}>Travelers</th>
                            <th style={{padding:"2px"}}>Form On File?</th>
                            <th style={{padding:"2px"}}>Restrictions?</th>
                        </tr>
                        <tr style={{backgroundColor:"PowderBlue"}}>
                            <td style={{fontWeight:"normal",textAlign:"center"}} colSpan={2}>Y=Yes, N=No, A=Awaiting Form</td>
                        </tr>
                        </div>
                    :
                        <tr style={{backgroundColor:"PowderBlue"}}>
                            <th style={{padding:"2px"}} colSpan={2}>Travelers</th>
                        </tr>
                    )
                :
                    <tr><td colSpan={2} style={{color:"red", textAlign:"center"}}>No Travelers</td></tr>
                }
                {(Number(sessionStorage.getItem("ta_traveler_count")) > 0)?
                    this.state.assignedTATraveler?.map((prop, key) => {
                        
                        return this.travelerView(prop,key,"Assigned")
                    }):<></>   
                }
                {(Number(sessionStorage.getItem("ta_traveler_count")) > 0)?
                    this.state.unassignedTATraveler?.map((prop, key) => {
                        
                        return this.travelerView(prop,key,"Unassigned")
                    }):<></>  
                }
            </table>
            <div style={{marginTop:"20px"}}></div>
            <table style={{width:"100%"}}>
                <tr>
                    <td style={{width:"47%", textAlign:"left"}}>Creation Date:</td>
                    <td style={{width:"53%", textAlign:"left"}}>{Utils.FormatDateTime(sessionStorage.getItem("ta_created_date"))}</td>
                </tr>
                <tr>
                    <td style={{textAlign:"left"}}>{(sessionStorage.getItem("ta_car_service_only") === "true" ?"Car Service Agent:":"Travel Agent:")}</td>
                    <td style={{textAlign:"left"}}>{sessionStorage.getItem("ta_travel_agent_name")}</td>
                </tr>
            </table>
            </Col>
            <Col sm={4} style={{textAlign:"center"}}></Col>
        </Row>       
        )
    }
}

interface IProperties{
    userId?:string;
    password?:string;
    history?:History<LocationState>;
    parentCallback?:any
}

interface Istate{
    fields:any;
    modal?:any;
    assignedTATraveler?:[];
    unassignedTATraveler?:[];
    errMsg?:string;
    loading?:boolean;
}