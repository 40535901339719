import React, { Component } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import { NavLink } from 'react-router-dom'
import {FormInputs} from '../../CustomComponents/FormInput'
import {CommonController} from "../../../Controllers/CommonController"
import {MenuController} from "../../../Controllers/MenuController"
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';
import { IShawdowParameter, Istate } from "../../../Model/IShawdow";

export class Shawdow extends Component<IShawdowParameter,Istate> {
  constructor(props:IShawdowParameter){
    super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {search:'*'},
            errors: {search:''},
            errMsg: "",
            users: [],
            loading: false,
            loggedIn: false,
            modal: {modalSpinnerShow:false}
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onShadow = this.onShadow.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        
        modal['modalSpinnerShow']=false;
        this.setState({ modal })
    }

    handleChange(e:any){
        let fields= this.state.fields;
        fields[e.currentTarget.name] = e.currentTarget.value.trim();
        this.setState({ fields });
    }

    ValidateProfile(){
        return true;
    }

    onSubmit(e:any){
        this.setState({ loading: true });
        e.preventDefault();
        if (this.ValidateProfile()) {
            var searchValue = this.state.fields.search.replace('*','%');
            var type = '';
            if(sessionStorage.getItem('sysop') != 'sysAdmin' && sessionStorage.getItem('shadow') != 'sysAdmin'){
                type = 'notSysAdmin';
            }    
            let menuController = new MenuController();
            menuController.searchUserDirect(searchValue,type).then(resUser => {
                if(resUser.rowsAffected[0] === 0){
                    menuController.searchUser(searchValue,type).then(resUsers => {
                        if(resUsers.recordset.length === 0){
                            this.setState({ errMsg: "No User Found", users: [] });
                            this.setState({ loading: false });
                        }
                        else{
                            this.setState({ errMsg: ""});
                            var jsonUsers : Array<{"userId": string, "name": string, "role": string, "label": string, "department": string}> = [];
                            resUsers.recordset.forEach(users);
                            function users(item: { user_id: any; Name: any; role: any; family: any; department: any }){
                                jsonUsers.push({"userId": item.user_id, "name": item.Name, "role": item.role, "label": item.family, "department": item.department})
                            }
                            this.setState({users: JSON.parse(JSON.stringify(jsonUsers))});
                            this.setState({ loading: false });
                        }
                    }).catch(error => {
                        console.error("There was an error!", error);
                    })
                }
                else if(resUser.rowsAffected[0] == 1){
                    this.onShadow(resUser.recordset[0].User_ID);                   
                }
                else if(resUser.rowsAffected[0] > 1){
                    var jsonUsers : Array<{"userId": string, "name": string, "role": string, "label": string, "department": string}> = [];
                    resUser.recordset.forEach(users);
                    function users(item: { user_id: any; Name: any; role: any; family: any; department: any }){
                        jsonUsers.push({"userId": item.user_id, "name": item.Name, "role": item.role, "label": item.family, "department": item.department})
                    }
                    this.setState({users: JSON.parse(JSON.stringify(jsonUsers))});
                    this.setState({ loading: false });
                }
                else{
                    this.setState({ errMsg: "No User Found",users: [] });
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.error("There was an error!", error);
            })
            
        }
    }

    onShadow(userId: string){
        sessionStorage.setItem('shadow',sessionStorage.getItem('uid')!);
        let commonController = new CommonController();
        commonController.getUser(userId).then(response => {
            if(sessionStorage.getItem("user_type_id") === "R"){
                this.props.history.push('/admin/trackinggrid');
            }
            else if(sessionStorage.getItem("user_type_id") === "A"){
                this.props.history.push('/admin/approverinbox');  
            }
            else if(sessionStorage.getItem("user_type_id") === "W"){
                sessionStorage.setItem('ShadowAdmin', '1' ) 
                this.props.history.push('/admin/wtpincomingta');
            }
            else if(sessionStorage.getItem("user_type_id") === "CT"){
                sessionStorage.setItem('ShadowAdmin', '1' ) 
                this.props.history.push('/admin/wtpincomingta');
            }
            else{
                this.props.history.push('/admin/home');
            }
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error!", error);
        })
    }
    cancelButton(){
        let fields = this.state.fields;
        fields['search'] = '*';
        this.state.users.splice(0,this.state.users.length);
        this.setState({ errMsg: "", fields});
    }
    render() {
        if (this.state.loggedIn === false){
            //return <Redirect to="/" />
        }
        const { loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">eTA - Shadow User Profile</h4><p className="category"></p></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Form onSubmit = {this.onSubmit}>
                                    <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={4}>
                                            <FormInputs
                                                properties={{id:"search",label:"Search (Global Id/Name):",type:"text",name:"search",value:this.state.fields.search,className:"form-control input-sm",maxlength:25,onChange:this.handleChange}}
                                                errorMsg={this.state.errors.search}
                                                fieldRequired={true}
                                            />
                                        </Col>
                                        <Col sm={4}></Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}></Col>
                                        <Col sm={3}>
                                            <Button type="submit" size="sm" disabled={loading} style={{float:"right"}}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#487afa" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#487afa"}} >wait...</span>}
                                                {!loading && <span>Ok</span>}
                                            </Button>
                                        </Col>
                                        <Col sm={3}>
                                            <Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={(e) => this.cancelButton()}>Cancel</Button>
                                        </Col>
                                        <Col sm={4}></Col>
                                    </Row>
                                </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
                {Object.keys(this.state.users).length === 0 ?
                <></>
                :
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Select User Profile</h4><p className="category"></p></div>
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                    <div className="scroll" style={{width: '100%'}}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>User Id</th>
                                                    <th>Name</th>
                                                    <th>User Type</th>
                                                    <th>Label</th>
                                                    <th>Department</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.users.map((prop, key) => {
                                                    return(
                                                        <tr key={key}>
                                                            <td><Button type="button" size="sm" style={{ marginRight: "5px" }} onClick={() => {this.onShadow(prop["userId"])}}>Shadow</Button></td>
                                                            <td>{prop["userId"]}</td>
                                                            <td>{prop["name"]}</td>
                                                            <td>{prop["role"]}</td>
                                                            <td>{prop["label"]}</td>
                                                            <td>{prop["department"]}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
            </Container>
        </div>)
    }
}