import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class ContentwinnerController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getContentWinnerList(data: any){
        var url = this.variables.Url+ this.variables.Controller.contentWinner + this.variables.contentWinnerService.getContentWinnerList;
      //  var data = {};
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonContentWinnerlist : Array<{"authReqId": string, "travelDate": string,"traveler":string,"createdDate":string,"travelPurpose":string,"ssn":string,
            "fedId":string,"country":string,"address1":string,"address2":string,"city":string,"state":string,"zip":string,"airTotal":string,"hotelTtotal":string
            ,"phone":string,"labelId":string,"artistProjectNumber":string,"artistProjectName":string,"artistName":string,"rentalCarTotal":string,"carServiceTotal":string,
            "carService2Total":string,"taTotal":string}> = [];
                if(response.success){
                    response.recordset.forEach(carServiceCAlist);
                    function carServiceCAlist(item: { authreq_id: any; created_date: any;travel_purpose:any;traveler:any;ssn:any;
                        fed_id:any;country:any;address1:any;address2:any;city:any;state_province:any;zip:any;air_total:any;hotel_total:any
                        ;phone:any;label_id:any;artist_project_number:any;artist_project_name:any;artist_name:any;travel_date:any
                        ;rental_car_total:any;car_service_total:any;car_service2_total:any;ta_total:any}){
                            jsonContentWinnerlist.push({"authReqId": item.authreq_id, "travelDate": item.travel_date,"traveler":item.traveler,"createdDate":item.created_date,"travelPurpose":item.travel_purpose,"ssn":item.ssn,
                     "fedId":item.fed_id,"country":item.country,"address1":item.address1,"address2":item.address2,"city":item.city,"state":item.state_province,"zip":item.zip,"airTotal":item.air_total,"hotelTtotal":item.hotel_total
                     ,"phone":item.phone,"labelId":item.label_id,"artistProjectNumber":item.artist_project_number,"artistProjectName":item.artist_project_name,"artistName":item.artist_name,"rentalCarTotal":item.rental_car_total
                     ,"carServiceTotal":item.car_service_total,"carService2Total":item.car_service2_total,"taTotal":item.ta_total})
                    }
                    return JSON.parse(JSON.stringify(jsonContentWinnerlist).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonContentWinnerlist).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}