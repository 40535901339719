import React, { Component } from "react";
import { IApproverInboxParameter, Istate } from "../../../Model/IApprover";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "../../../../node_modules/react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { ApproverController } from "../../../Controllers/ApproverController";
//import '../../../assets/css/Blink.css'
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import * as Utils from '../../../js/Utils.js';
import { TravellerController } from "../../../Controllers/TravellerController";
import addSubtractDate from 'add-subtract-date';
import * as Constants from "../../../js/Constants";
import { CommonController } from "../../../Controllers/CommonController";

export class ApproverInbox extends Component<IApproverInboxParameter, Istate> {
    constructor(props: IApproverInboxParameter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: {},
            errors: {},
            errMsg: "",
            loggedIn,
            apprIncoming: [],
            currentSort: 'down',
            currentColumn: 'authreq_Id',
            loading: false,
            modal: { modalSpinnerShow: false }
        }
    }

    componentWillMount(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({ modal});
        sessionStorage.setItem("frompage", Constants.approverinbox);
        let approverController = new ApproverController();
        var approverInboxData = {
            userId: sessionStorage.getItem("uid"),
            sysop: sessionStorage.getItem("sysop"),
            labelId: sessionStorage.getItem("label_id"),
            approverWeight: sessionStorage.getItem("approver_weight"),
            approverTierId: sessionStorage.getItem("approver_tier_id"),
            isDha: sessionStorage.getItem("is_dha"),
            taCarService2: sessionStorage.getItem("ta_car_service2") == null ? "" : sessionStorage.getItem("ta_car_service2")
        }
        approverController.getApproverInbox(approverInboxData).then(jsonApproverIncoming => {
            this.setState({
                apprIncoming: JSON.parse(JSON.stringify(jsonApproverIncoming))
            });
            modal['modalSpinnerShow']=false;
            this.setState({ modal});
        }).catch(error => {
            console.error("There was an error...," + error)
        });


          /* UnLocking TA for the current users */
        
          let comController = new CommonController();
          var data = {
              authreqid: sessionStorage.getItem("ta"),
              userid: sessionStorage.getItem("uid"),
              shadowid: sessionStorage.getItem("shadow"),
              check: ""
          }
        //   console.log(data,"data,check")
          comController.updateUnLockAuthreq(data).then(response => {
          }).catch(error => {
              console.error("There was an error!", error)
          })
    }

    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = this.state.apprIncoming;
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            apprIncoming: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    onSelect(authReqId){
        /* Check TA is locked or not for the current users */
        
        let comController = new CommonController();
        var datacheck = {
            authreqid: authReqId,
            userid: sessionStorage.getItem("uid"),
            shadowid: sessionStorage.getItem('shadow')
        }
        comController.getAuthlockedCount(datacheck).then(response => {
            
            var count = response[0].count || "";
            if (count > 0) {
                //alert("AuthReq# :" + response['AuthReq_ID'] + "\r\n" + "Is currently being edited by : " + response['Name'] + "\r\n" + response['Email'] + "\r\n" + response['Business_Phone'] +"\r\n")
                let travellerController = new TravellerController();
                travellerController.getTA(authReqId).then(response => {
                    this.props.history.push({pathname:'/admin/codingsummary',state:{from:"approverinbox"}});
                    this.setState({ loading: false });
                }).catch(error => {
                    console.error("There was an error!", error)
                })
            }
            else {
                var data = {
                    authreqid: authReqId,
                    userid: sessionStorage.getItem("uid"),
                    shadowid: sessionStorage.getItem('shadow')
                }
                comController.updateLockAuthreq(data).then(response => {
                    let travellerController = new TravellerController();
                    travellerController.getTA(authReqId).then(response => {
                        this.props.history.push({ pathname: '/admin/codingsummary', state: { from: "approverinbox" } });
                        this.setState({ loading: false });
                    }).catch(error => {
                        console.error("There was an error!", error)
                    })


                }).catch(error => {
                    console.error("There was an error!", error)
                })

            }
        }).catch(error => {
            console.error("There was an error!", error)
        })
        
    }

    render() {
        var prevAuthReqId = 0;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Approver Incoming TA's</h4><p className="category"></p></div>
                            <Row>
                                <Col sm={6}></Col>
                                <Col sm={6}>
                                    <div style={{ width: '100%' }} className="alert alert-primary alert-cus-info">
                                        <div><h6 className="text-dark">Legend for Incoming TA's</h6>
                                            <div className="text-dark font-size-xs"><span className="badge badge-blue">(1 - day)</span> TA indicates travel is occuring within 1 day.</div>                                    
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                {(sessionStorage.getItem("adID") !== null) ?
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{ width: '100%', height: "auto" }}>
                                                <Table hover striped bordered>
                                                    <thead>
                                                        <tr>
                                                            <th onClick={() => this.onSortChange('authReqId')}>Auth Req <i className={this.setArrow('authReqId')}></i></th>
                                                            <th onClick={() => this.onSortChange('createdDate')}>Submitted <i className={this.setArrow('createdDate')}></i></th>
                                                            <th onClick={() => this.onSortChange('createdBy')}>Submitter <i className={this.setArrow('createdBy')}></i></th>
                                                            <th onClick={() => this.onSortChange('artistProjectName')}>Project <i className={this.setArrow('artistProjectName')}></i></th>
                                                            <th onClick={() => this.onSortChange('artistName')}>Artist <i className={this.setArrow('artistName')}></i></th>
                                                            <th onClick={() => this.onSortChange('statusDesc')}>Status <i className={this.setArrow('statusDesc')}></i></th>
                                                            <th onClick={() => this.onSortChange('expenseTypeDesc')}>Expense Type <i className={this.setArrow('expenseTypeDesc')}></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.apprIncoming.map((prop, key) => {
                                                            if(prevAuthReqId != prop["authReqId"]){
                                                                prevAuthReqId = prop["authReqId"] 
                                                                var bBlink = false;
                                                                var blinkDate = Utils.FormatDate(addSubtractDate.add(new Date(Date.now()),1,"day"))
                                                                if(prop["travelDate"] != null && new Date(prop["travelDate"]) <= new Date(blinkDate)){
                                                                    bBlink = true;
                                                                }
                                                                else if(prop["rentalDate"] != null && new Date(prop["rentalDate"]) <= new Date(blinkDate)){
                                                                    bBlink = true;
                                                                }
                                                                else if(prop["carDate"] != null && new Date(prop["carDate"]) <= new Date(blinkDate)){
                                                                    bBlink = true;
                                                                }
                                                                else if(prop["inDate"] != null && new Date(prop["inDate"]) <= new Date(blinkDate)){
                                                                    bBlink = true;
                                                                }
                                                                else{
                                                                    bBlink = false;
                                                                }
                                                                var color = "", blinkColor = "";
                                                                if(bBlink){
                                                                    if(new Date(prop["createdDate"]) < new Date(addSubtractDate.add(new Date(Date.now()),-1,"day"))){
                                                                        blinkColor = "#FF0000";
                                                                    }
                                                                    else{
                                                                        blinkColor = "";
                                                                    }
                                                                }
                                                                else{
                                                                    if(new Date(prop["createdDate"]) < new Date(addSubtractDate.add(new Date(Date.now()),-1,"day")) && !bBlink){
                                                                        color = "#FF0000";
                                                                    }
                                                                    else{
                                                                        color = "";
                                                                    }        
                                                                }
                                                                return (
                                                                    <tr key={key} onClick={() => this.onSelect(prop["authReqId"])} style={{cursor:"pointer"}}>
                                                                        {bBlink ? <td>{prop["carServiceOnly"] ? 'CA' + prop["authReqId"]:prop["authReqId"]}<span className="badge badge-blue " style={{fontWeight: "bold", fontSize: '85%'}}> (1 - day)</span></td>
                                                                        :
                                                                            <td>{prop["carServiceOnly"] ? 'CA' + prop["authReqId"] :prop["authReqId"]}</td>
                                                                        }
                                                                        <td>{Utils.FormatDateTime(prop["createdDate"])}</td>
                                                                        <td>{prop["createdBy"]}</td>
                                                                        <td>{prop["artistProjectName"]}</td>
                                                                        <td>{prop["artistName"]}</td>
                                                                        <td>{prop["statusDesc"]}</td>
                                                                        <td>{prop["expenseTypeDesc"]}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col sm={12}>
                                            {"For security reason, this page is not displayed.<br>Please try to login again."}
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }

}