import React, { Component } from "react";
import { IReportParameter, Istate } from "../../../Model/IReport";
import {Container,Row,Col,Form,Alert,Button,Card} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { ReportController } from "../../../Controllers/ReportController";
import '../../../assets/css/Style.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Variables } from "../../../Variables/Variables";

export class ContentWinnerReports extends Component<IReportParameter,Istate> {
    constructor(props:IReportParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
       
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            percentagesBeforeNoon: 0,
            percentagesNoon: 0,
            percentagesOneOClock: 0,
            percentagesTwoOClock: 0,
            percentagesThreeOClock: 0,
            percentagesFourOClock: 0,
            percentagesFiveOClock: 0,
            percentagesSixOClock: 0,
            percentagesSevenOClock: 0,
            sumBeforeNoon: 0,
            sumNoon: 0,
            sumOneOClock: 0,
            sumTwoOClock: 0,
            sumThreeOClock: 0,
            sumFourOClock: 0,
            sumFiveOClock: 0,
            sumSixOClock: 0,
            sumSevenOClock: 0,
            sumofTotal:0,
            fields: {startDate:'',endDate:'',isLabelAllChked:false,isCallLettersAllChked:false,isStatusAllChked:false,isLabelAll:false,isCallLettersAll:false,isStatusAll:false},
            selectfields:{labels:'',contestLetter:'',callLetters:'',complianceOfficer:'',status:'',sortOrder1:'',sortOrder2:'',selectedFileFormat:''},
            optionfields : {rdoRadioLetterYes:false,rdoRadioLetterNo:false,rdoRadioLetterNA:false,rdoRadioLetterAll:true,
                             rdoComplianceOfficerYes:false,rdoComplianceOfficerNo:false,rdoComplianceOfficerAll:true,
                             rdoFormatPDF:false,rdoFormatExcel:true},
            errors:{startDate:'',endDate:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            labelGroups:[],
            deptGroups:[],
            statusTypeGroups:[],
            callLetterGroup:[],
            ctmReport:[],
        }    
        this.onClear = this.onClear.bind(this);     
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})  
        modal['modalSpinnerShow']=true;       
        this.setDefault();
        this.getData();
    }

    setDefault =()=>{
        let today     =new Date();      
        let startDate =new Date(today.getTime() - (15 * 24 * 60 * 60 * 1000));
        let fields = this.state.fields;
        let selectfields = this.state.selectfields;      
        let optionfields = this.state.optionfields  
        fields["startDate"]= startDate;
        fields["endDate"]= today;
        fields["isLabelAllChked"]= false;
        fields["isCallLettersAllChked"]= false;
        fields["isStatusAllChked"]= false;
        fields["isLabelAll"]= false;
        fields["isCallLettersAll"]= false;
        fields["isStatusAll"]= false;
        selectfields["sortOrder1"]="Call_Letters";
        selectfields["sortOrder2"]="AuthReq_id";  
        selectfields["selectedFileFormat"]="Excel"; 
        selectfields["contestLetter"]="ALL";
        selectfields["complianceOfficer"]="ALL";
        optionfields["rdoRadioLetterYes"]= false;     
        optionfields["rdoRadioLetterYes"]= false;  
        optionfields["rdoRadioLetterNo"]= false;  
        optionfields["rdoRadioLetterNA"]= false;  
        optionfields["rdoRadioLetterAll"]= true;  
        optionfields["rdoComplianceOfficerYes"]= false;  
        optionfields["rdoComplianceOfficerNo"]= false;  
        optionfields["rdoComplianceOfficerAll"]= true;  
        optionfields["rdoFormatPDF"]= false;  
        optionfields["rdoFormatExcel"]= true;       
        this.setState({fields,selectfields,optionfields})
    }

    getData =()=>{
        let modal = this.state.modal; 
        let reportController = new ReportController();
        
        reportController.getActiveLabels().then(jsonLabel => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonLabel))});             
            //console.log(this.state.labelGroups)
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })

        reportController.getStatusTypeCodesList().then(jsonStatusCode => {
            this.setState({ statusTypeGroups: JSON.parse(JSON.stringify(jsonStatusCode))});             
            //console.log(this.state.statusTypeGroups)
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })

        reportController.getCallLetters().then(jsonCallLetters => {
            this.setState({ callLetterGroup: JSON.parse(JSON.stringify(jsonCallLetters))});             
            //console.log(this.state.callLetterGroup) 
            modal['modalSpinnerShow']=false;           
            this.setState({modal})  
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })
    }

    onClear(e:any)
    {
        e.preventDefault();
        let modal = this.state.modal; 
        modal['modalSpinnerShow']=true; 
        this.setDefault();
        this.getData();
    }

    onSubmit(e:any){
        e.preventDefault();
        let fields = this.state.fields;
        let modal = this.state.modal; 
        //modal['modalSpinnerShow']=true; 
        if (this.ValidateValues())
        {
            var bulidlink = "";
            var reportURL= new Variables().ReportPath + "eTA Contest Winnner Report";
            let selectfields = this.state.selectfields;
            if(fields["isCallLettersAll"])
                bulidlink = bulidlink + '&callLetterALL=ALL&callLetter=ALL'      
            else{
                this.state.callLetterGroup.map(item =>{
                    if(item["isChecked"])
                    bulidlink = bulidlink + '&callLetter=' + item["id"]
                })
                bulidlink = bulidlink + '&callLetterALL=  '
               
            }      
            if(fields["isLabelAll"])
                bulidlink = bulidlink + '&opCoALL=ALL&opCo=ALL'
            else{
                this.state.labelGroups.map(item =>{
                    if(item["isChecked"])
                    bulidlink = bulidlink + '&opCo=' + item["id"]
                })
                bulidlink = bulidlink + '&opCoALL=  '               
            }
            if(fields["isStatusAll"])
                bulidlink = bulidlink + '&statusALL=ALL&status=-1'
            else{
                this.state.statusTypeGroups.map(item =>{
                    if(item["isChecked"])
                    bulidlink = bulidlink + '&status=' + item["id"]
                })
                bulidlink = bulidlink + '&statusALL=  '
               
            }
            if(fields["startDate"] != "" ||  fields["startDate"] != null)
            {
                var sD = new Date (fields["startDate"]);
                var curr_date = sD.getDate();
                var curr_month = sD.getMonth() + 1; //Months are zero based
                var curr_year = sD.getFullYear();
                var startDate = curr_month + "/" + curr_date + "/" + curr_year;
                bulidlink = bulidlink + '&startDate=' + startDate;
            }
            if(fields["endDate"] != "" ||  fields["endDate"] != null)
            {
                var eD = new Date (fields["endDate"]);
                var curr_date = eD.getDate();
                var curr_month = eD.getMonth() + 1; //Months are zero based
                var curr_year = eD.getFullYear();
                var endDate = curr_month + "/" + curr_date + "/" + curr_year;
                bulidlink = bulidlink + '&endDate=' + endDate;
            }           
            bulidlink = bulidlink + '&policyLetter=' + selectfields["contestLetter"];
            bulidlink = bulidlink + '&ovrRcvCmpOfficer=' + selectfields["complianceOfficer"];
            bulidlink = bulidlink + '&sortOrder1=' + selectfields["sortOrder1"];
            bulidlink = bulidlink + '&sortOrder2=' + selectfields["sortOrder2"];
            bulidlink = bulidlink + '&rs:Format=' + selectfields["selectedFileFormat"];
            bulidlink = bulidlink + '&rs:ClearSession=true';
            //console.log(reportURL+bulidlink);
            window.open(reportURL+bulidlink);
            /*this.setDefault();
            this.getData();
            this.props.history.push('/admin/rsvltcontest');*/
        }
    }

    ValidateValues(){        
        let fields = this.state.fields;
        let errors = this.state.errors;
        let modal = this.state.modal;     
        let labelCount = 0;   
        let callLetterCount = 0;
        let statusCount =  0;
        let formIsValid = true;             
        if (fields["startDate"] === "" || fields["startDate"] === null) {
        formIsValid = false;
        errors["startDate"] = "Enter Start Date";   
        modal['modalSpinnerShow']=false; 
        }         
        if (fields["endDate"] === "" || fields["endDate"] === null) {
        formIsValid = false;
        errors["endDate"] = "Enter End Date";   
        modal['modalSpinnerShow']=false; 
        }
        this.state.callLetterGroup.map(item =>{
            if(item["isChecked"])
                callLetterCount++;          
        })      
        this.state.labelGroups.map(item =>{
            if(item["isChecked"])
                labelCount++;           
        })
        this.state.statusTypeGroups.map(item =>{
            if(item["isChecked"])
                 statusCount++;           
        })
        if((callLetterCount === 0) || fields["isCallLettersAllChked"])
          fields["isCallLettersAll"] = true;
        
        if((labelCount === 0) || fields["isLabelAllChked"])
          fields["isLabelAll"] = true;
       
        if((statusCount === 0) || fields["isStatusAllChked"])
          fields["isStatusAll"] = true;
        
                
        this.setState({errors,modal,fields});
        return formIsValid;
    }

    handleChangeStartDate = date => {
        let fields = this.state.fields;
        fields["startDate"]= date        
        this.setState({fields})
    }
    
    handleChangeEndDate = date => {
        
        let fields = this.state.fields;
        fields["endDate"]= date        
        this.setState({fields })
    }

    handleOptionChange(e:any){
        
        let optionfields = this.state.optionfields;
        let selectfields = this.state.selectfields        
        let currentTargetId = e.currentTarget.id;
        if(e.currentTarget.name === "rdoRadioLetter")
        {
            if (currentTargetId === 'rdoRadioLetterYes'){
                optionfields["rdoRadioLetterYes"] = true;
                optionfields["rdoRadioLetterNo"] = false;
                optionfields["rdoRadioLetterNA"] = false;
                optionfields["rdoRadioLetterAll"] = false;            
            }
            if (currentTargetId === 'rdoRadioLetterNo'){
                optionfields["rdoRadioLetterNo"] = true;
                optionfields["rdoRadioLetterYes"] = false;
                optionfields["rdoRadioLetterNA"] = false
                optionfields["rdoRadioLetterAll"] = false;
            }
            if (currentTargetId === 'rdoRadioLetterNA'){
                optionfields["rdoRadioLetterNA"] = true;
                optionfields["rdoRadioLetterNo"] = false;
                optionfields["rdoRadioLetterYes"] = false;
                optionfields["rdoRadioLetterAll"] = false;
            }
            if (currentTargetId === 'rdoRadioLetterAll'){
                optionfields["rdoRadioLetterAll"] = true;
                optionfields["rdoRadioLetterNo"] = false;
                optionfields["rdoRadioLetterYes"] = false;
                optionfields["rdoRadioLetterNA"] = false;
            } 
            selectfields["contestLetter"] = e.currentTarget.value;   
        } 
        if(e.currentTarget.name === "rdoComplianceOfficer")
        { 
            if (currentTargetId === 'rdoComplianceOfficerYes'){
                optionfields["rdoComplianceOfficerYes"] = true;
                optionfields["rdoComplianceOfficerNo"] = false;
                optionfields["rdoComplianceOfficerAll"] = false;            
            }
            if (currentTargetId === 'rdoComplianceOfficerNo'){
                optionfields["rdoComplianceOfficerNo"] = true;
                optionfields["rdoComplianceOfficerYes"] = false;
                optionfields["rdoComplianceOfficerAll"] = false;            
            }
            if (currentTargetId === 'rdoComplianceOfficerAll'){
                optionfields["rdoComplianceOfficerAll"] = true;
                optionfields["rdoComplianceOfficerNo"] = false;
                optionfields["rdoComplianceOfficerYes"] = false;            
            }
            selectfields["complianceOfficer"] = e.currentTarget.value;
        }
        if(e.currentTarget.name === "rdoFileFormat")
        {
            if (currentTargetId === 'rdoFormatPDF'){
                optionfields["rdoFormatPDF"] = true;
                optionfields["rdoFormatExcel"] = false;           
            }
            if (currentTargetId === 'rdoFormatExcel'){
                optionfields["rdoFormatExcel"] = true;
                optionfields["rdoFormatPDF"] = false;          
            }
            selectfields["selectedFileFormat"] = e.currentTarget.value;
        }
        this.setState({ optionfields, selectfields});
        //console.log("Option Events: ",this.state.optionfields)
    }

    handleSelectChange = (e: any) => {
               
        let selectfields = this.state.selectfields;
        let currentTargetName = e.currentTarget.name;
            if (currentTargetName === "sortOrder1") {                
                selectfields[e.currentTarget.name] = e.currentTarget.value;
                this.setState({ selectfields });
            }  
            else {
                selectfields[e.currentTarget.name] = e.currentTarget.value;
                this.setState({ selectfields});
            }
        }

        handleCheckChange = (i,e) => {
            let fields = this.state.fields; 
            let labelGroups = [...this.state.labelGroups];
            if(fields["isLabelAllChked"] === true && !labelGroups[i]["isChecked"] === false)  
            {
                fields["isLabelAllChked"] = false;
            }
            labelGroups[i]["isChecked"] = !labelGroups[i]["isChecked"] 
            this.setState({ labelGroups,fields });
        }

        handleCallLetterCheckChange = (i,e) => {
            let fields = this.state.fields;           
           
            let callLetterGroup = [...this.state.callLetterGroup];
            if(fields["isCallLettersAllChked"] === true && !callLetterGroup[i]["isChecked"] === false)  
            {
                fields["isCallLettersAllChked"] = false;
            }  
            callLetterGroup[i]["isChecked"] = !callLetterGroup[i]["isChecked"] 
            this.setState({ callLetterGroup,fields });
        }

        handleStatusCheckChange = (i,e) => {
            
            let fields = this.state.fields;           
            //let allChecked = this.state.allChecked;
            
            let statusTypeGroups = [...this.state.statusTypeGroups];
            if(fields["isStatusAllChked"] === true && !statusTypeGroups[i]["isChecked"] === false)  
            {
                fields["isStatusAllChked"] = false;
            } 
            statusTypeGroups[i]["isChecked"] = !statusTypeGroups[i]["isChecked"]
            this.setState({ statusTypeGroups,fields });
        }

        handleCheckChangeAll = (e) => {
            if(e.currentTarget.id === "label")
            {
                let labelGroups = [...this.state.labelGroups];
                let fields = this.state.fields;
                labelGroups.forEach(item => {
                    item.isChecked = e.target.checked;                   
                });
                fields["isLabelAllChked"] = e.target.checked;
                this.setState({ labelGroups,fields });
            }
            if(e.currentTarget.id === "callLetters")
            {
                let callLetterGroup = [...this.state.callLetterGroup];
                let fields = this.state.fields;
                callLetterGroup.forEach(item => {
                    item.isChecked = e.target.checked;                    
                });
                fields["isCallLettersAllChked"] = e.target.checked;
                this.setState({ callLetterGroup,fields });
            }
            if(e.currentTarget.id === "statusType")
            {
                let statusTypeGroups = [...this.state.statusTypeGroups];
                let fields = this.state.fields;
                statusTypeGroups.forEach(item => {
                    item.isChecked = e.target.checked;                    
                });
                fields["isStatusAllChked"] = e.target.checked;;
                this.setState({ statusTypeGroups,fields });
            }
           
        }

        renderList = () => {
            return this.state.labelGroups.map((item,key) =>
                <div>
                    <input key={item["id"]} type="checkbox" name={item["name"]} value={item["name"]} checked={item["isChecked"]} onChange={this.handleCheckChange.bind(this, key)} />
                    &nbsp;<label>{item["name"]}</label>
                </div>
            )
        }

        renderCallletterList = () => {
            return this.state.callLetterGroup.map((item,key) =>
                <div>
                    <input key={item["id"]} type="checkbox" name={item["id"]} value={item["id"]} checked={item["isChecked"]} onChange={this.handleCallLetterCheckChange.bind(this, key)} />
                    &nbsp;<label>{item["id"]}</label>
                </div>
            )
        }

        renderStatusList = () => {
            return this.state.statusTypeGroups.map((item,key) =>
                <div>
                    <input key={item["id"]} type="checkbox" name={item["name"]} value={item["name"]} checked={item["isChecked"]} onChange={this.handleStatusCheckChange.bind(this, key)} />
                    &nbsp;<label>{item["name"]}</label>
                </div>
            )
        }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'20%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">On Demand Contest Winner Report</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                    <Col md={4} style={{}}><label>Date Range</label></Col>
                                    <Col sm={2}>
                                        <Form.Group>
                                            <div>                    
                                                <Form.Label>Start Date</Form.Label>
                                                <DatePicker style={{ fontSize: "12px" }}
                                                Id="startDate"
                                                selected={this.state.fields.startDate}
                                                onChange={this.handleChangeStartDate}                                                
                                                showMonthDropdown
                                                showYearDropdown
                                                maxDate={new Date()}
                                                className="dateStyle" />
                                                {this.state.errors.startDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.startDate}</div> : <></> }                   
                                            </div>
                                        </Form.Group>
                                    </Col>                  
                                    <Col sm={2}>
                                        <Form.Group>
                                            <div>                    
                                                <Form.Label>End Date</Form.Label>
                                                <DatePicker style={{ fontSize: "12px" }}
                                                Id="endDate"
                                                selected={this.state.fields.endDate}
                                                onChange={this.handleChangeEndDate}                                                
                                                showMonthDropdown
                                                showYearDropdown
                                                maxDate={new Date()}
                                                className="dateStyle" />
                                                {this.state.errors.endDate != "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors.endDate}</div> : <></> } 
                                            </div>
                                        </Form.Group>                    
                                    </Col>                                                                          
                                </Row>                                                                 
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label>Obtained Contest Letter</label></Col>
                                    <Col md={8} style={{}}>
                                      <Form.Group>
                                            <Row >
                                                <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Yes" name="rdoRadioLetter" id="rdoRadioLetterYes" onChange={(e)=>this.handleOptionChange(e)} value="Y"  checked={this.state.optionfields.rdoRadioLetterYes} /></Col>
                                                <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="No" name="rdoRadioLetter" id="rdoRadioLetterNo" onChange={(e)=>this.handleOptionChange(e)} value="N"  checked={this.state.optionfields.rdoRadioLetterNo} /></Col>
                                                <Col sm={3} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Not Radio Related" name="rdoRadioLetter" id="rdoRadioLetterNA" onChange={(e)=>this.handleOptionChange(e)} value="N/A"  checked={this.state.optionfields.rdoRadioLetterNA} /></Col>
                                                <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="All" name="rdoRadioLetter" id="rdoRadioLetterAll" onChange={(e)=>this.handleOptionChange(e)} value="ALL"  checked={this.state.optionfields.rdoRadioLetterAll} /></Col>
                                            </Row>
                                        </Form.Group>   
                                    </Col>
                                </Row>  
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label>Override Received from Compliance Officer</label></Col>
                                    <Col md={8} style={{}}>
                                      <Form.Group>
                                            <Row >
                                                <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Yes" name="rdoComplianceOfficer" id="rdoComplianceOfficerYes" onChange={(e)=>this.handleOptionChange(e)} value="Y"  checked={this.state.optionfields.rdoComplianceOfficerYes} /></Col>
                                                <Col sm={2} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="No"  name="rdoComplianceOfficer" id="rdoComplianceOfficerNo" onChange={(e)=>this.handleOptionChange(e)} value="N"  checked={this.state.optionfields.rdoComplianceOfficerNo} /></Col>
                                                <Col sm={3} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="All" name="rdoComplianceOfficer" id="rdoComplianceOfficerAll" onChange={(e)=>this.handleOptionChange(e)} value="ALL"  checked={this.state.optionfields.rdoComplianceOfficerAll} /></Col>
                                            </Row>
                                        </Form.Group>   
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label>HFM Entity</label></Col>
                                    <Col md={8} style={{}}>
                                    <div style={{width:"50%",height:"100px",overflowY:"auto",overflowX:"auto",border:"groove",padding:"1%"}}>
                                        <input type="checkbox" 
                                        id="label"
                                        value="checkAll" 
                                        checked={this.state.fields.isLabelAllChked} 
                                        onChange={this.handleCheckChangeAll} />&nbsp;<label>Check All</label>
                                        <br/>
                                        {this.renderList()}
                                    </div>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label>Radio Station Call Letters</label></Col>
                                    <Col md={8} style={{}}>
                                    <div style={{width:"50%",height:"100px",overflowY:"auto",overflowX:"auto",border:"groove",padding:"1%"}}>
                                        <input type="checkbox" 
                                        id="callLetters"
                                        value="checkAll" 
                                        checked={this.state.fields.isCallLettersAllChked} 
                                        onChange={this.handleCheckChangeAll} />&nbsp;<label>Check All</label>
                                        <br/>
                                        {this.renderCallletterList()}
                                    </div>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label>Status</label></Col>
                                    <Col md={8} style={{}}>
                                    <div style={{width:"50%",height:"100px",overflowY:"auto",overflowX:"auto",border:"groove",padding:"1%"}}>
                                        <input type="checkbox" 
                                        id="statusType"
                                        value="checkAll" 
                                        checked={this.state.fields.isStatusAllChked} 
                                        onChange={this.handleCheckChangeAll} />&nbsp;<label>Check All</label>
                                        <br/>
                                        {this.renderStatusList()}
                                    </div>
                                    </Col>
                                </Row>
                                <Row style={{padding:"5px"}}><Col style={{height:"1%",backgroundColor:"navy",color:"white",fontSize:"12px"}}>&nbsp;</Col></Row>                                                              
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label>Select Sort Order</label></Col>
                                    <Col md={2} style={{}}>
                                    <Form.Group>                                       
                                        <div><Form.Control as="select" name="sortOrder1" className="form-control-sm" value={this.state.selectfields.sortOrder1} onChange={this.handleSelectChange}>
                                            <option value="Call_Letters">Radio Station (Call Letter)</option>
                                            <option value="arCreated_Date">Submit Date</option>
                                            <option value="AuthReq_id">TA #</option>                                            
                                        </Form.Control></div>
                                    </Form.Group>  
                                    </Col>
                                    <Col md={2} style={{}}>
                                    <Form.Group>                                       
                                        <div><Form.Control as="select" name="sortOrder2" className="form-control-sm" value={this.state.selectfields.sortOrder2} onChange={this.handleSelectChange}>
                                            <option value="Call_Letters">Radio Station (Call Letter)</option>
                                            <option value="arCreated_Date">Submit Date</option>
                                            <option value="AuthReq_id">TA #</option>                                            
                                        </Form.Control></div>
                                    </Form.Group>  
                                    </Col>
                                </Row>
                                <Row style={{marginTop:"1%"}}>
                                <Col md={4} style={{}}><label></label></Col>
                                    <Col md={8} style={{}}>
                                      <Form.Group>
                                            <Row >
                                                <Col sm={1} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="PDF" name="rdoFileFormat" id="rdoFormatPDF" onChange={(e)=>this.handleOptionChange(e)} value="PDF"  checked={this.state.optionfields.rdoFormatPDF} /></Col>
                                                <Col sm={1} style={{paddingRight: "1rem"}}><Form.Check type="radio" label="Excel"  name="rdoFileFormat" id="rdoFormatExcel" onChange={(e)=>this.handleOptionChange(e)} value="Excel"  checked={this.state.optionfields.rdoFormatExcel} /></Col>                                                
                                            </Row>
                                        </Form.Group>   
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} style={{}}><label></label></Col>
                                    <Col sm={1.5} style={{marginTop:"2%"}}>
                                        <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSubmit(e)}>
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                            {!loading && <span>Get Report</span>}
                                        </Button>
                                    </Col> 
                                    <Col sm={1} style={{marginTop:"2%",marginLeft:"2%"}}>
                                        <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onClear(e)}>
                                            {loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                            />
                                            )}
                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                            {!loading && <span>Clear</span>}
                                        </Button>                                            
                                    </Col> 
                                </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}