import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class TravellerController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    getTA(authreqId : string){
        var data = {
            authreqId: authreqId,
        };
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getTA;
        return this.serviceProxy.get(url,data).then(response =>{
            if(response.success){
                var res = JSON.parse(JSON.stringify(response).replace(/"\s+|\s+"/g, '"'));
                sessionStorage.setItem("ta",res.authreq_id);
                sessionStorage.setItem("ta_is_corporate_card",res.is_corporate_card)
                sessionStorage.setItem("ta_traveler_count",res.traveler_count);
                sessionStorage.setItem("ta_is_dh",res.is_dh);
                sessionStorage.setItem("ta_tier_code",res.tier_code);
                sessionStorage.setItem("ta_max_approvers",res.max_approvers);
                sessionStorage.setItem("ta_created_by",res.name);
                sessionStorage.setItem("ta_email",res.email);
                sessionStorage.setItem("ta_business_phone",res.business_phone);
                sessionStorage.setItem("ta_created_date",res.created_date);
                sessionStorage.setItem("ta_updated_date",res.updated_date);
                sessionStorage.setItem("ta_updated_by",res.updated_by);
                sessionStorage.setItem("ta_last_comments_to_requestor",res.comments_to_requestor);
                sessionStorage.setItem("ta_last_comments_to_approver",res.comments_to_approver);
                sessionStorage.setItem("ta_weight",res.weight);
                sessionStorage.setItem("ta_status_id",res.status_id);
                sessionStorage.setItem("ta_status_desc",res.status_desc);
                sessionStorage.setItem("ta_op_code",res.op_code);
                sessionStorage.setItem("ta_label_id",res.label_id);
                sessionStorage.setItem("ta_label_desc",res.label_desc);
                sessionStorage.setItem("ta_department_id",res.department_id);
                sessionStorage.setItem("ta_department_desc",res.department_desc);
                sessionStorage.setItem("ta_expense_type_id",res.expense_type_id);
                sessionStorage.setItem("ta_expense_type_desc",res.expense_type_desc);
                sessionStorage.setItem("ta_artist_name",res.artist_name);
                sessionStorage.setItem("ta_business_unit",res.business_unit);
                sessionStorage.setItem("ta_artist_project_name",res.artist_project_name);
                sessionStorage.setItem("ta_artist_project_number",res.artist_project_number);
                sessionStorage.setItem("ta_sound_biz_2",res.sound_biz_2);
                sessionStorage.setItem("ta_in_policy",res.in_policy);
                sessionStorage.setItem("ta_car_service_only",res.car_service_only);
                sessionStorage.setItem("ta_travel_agent_name",res.travel_agent_name);
                sessionStorage.setItem("ta_has_air",res.has_air);
                sessionStorage.setItem("ta_air_missing_traveler",res.air_missing_traveler);
                sessionStorage.setItem("ta_air_traveler_count",res.air_traveler_count);
                sessionStorage.setItem("ta_air_total",res.air_total);
                sessionStorage.setItem("ta_air_coding",res.air_coding);
                sessionStorage.setItem("ta_has_rental_car",res.has_rental_car);
                sessionStorage.setItem("ta_rental_car_missing_traveler",res.rental_car_missing_traveler);
                sessionStorage.setItem("ta_rental_car_traveler_count",res.rental_car_traveler_count);
                sessionStorage.setItem("ta_rental_car_total",res.rental_car_total);
                sessionStorage.setItem("ta_rental_car_coding",res.rental_car_coding);
                sessionStorage.setItem("ta_has_car_service",res.has_car_service);
                sessionStorage.setItem("ta_car_service_missing_traveler",res.car_service_missing_traveler);
                sessionStorage.setItem("ta_car_service_traveler_count",res.car_service_traveler_count);
                sessionStorage.setItem("ta_car_service_total",res.car_service_total);
                sessionStorage.setItem("ta_car_service_coding",res.car_service_coding);
                sessionStorage.setItem("ta_has_hotel",res.has_hotel);
                sessionStorage.setItem("ta_hotel_missing_traveler",res.hotel_missing_traveler);
                sessionStorage.setItem("ta_hotel_traveler_count",res.hotel_traveler_count);
                sessionStorage.setItem("ta_hotel_total",res.hotel_total);
                sessionStorage.setItem("ta_hotel_coding",res.hotel_coding);
                sessionStorage.setItem("ta_non_disputable_hotel",res.non_disputable_hotel);
                sessionStorage.setItem("ta_total", (res.air_total + res.rental_car_total + res.car_service_total + res.hotel_total))
                sessionStorage.setItem("ta_coding_total", (res.air_coding + res.rental_car_coding + res.car_service_coding + res.hotel_coding))
                sessionStorage.setItem("ta_car_service2",res.car_service2);
                sessionStorage.setItem("ta_car_service_company_id",res.car_service_company_id);
                sessionStorage.setItem("ta_car_service_company",res.car_service_company);
                return response;
            }
            else {
                sessionStorage.setItem("ta","");
                sessionStorage.setItem("ta_is_corporate_card","");
                sessionStorage.setItem("ta_traveler_count","");
                sessionStorage.setItem("ta_is_dh","");
                sessionStorage.setItem("ta_tier_code","");
                sessionStorage.setItem("ta_max_approvers","");
                sessionStorage.setItem("ta_created_by","");
                sessionStorage.setItem("ta_email","");
                sessionStorage.setItem("ta_business_phone","");
                sessionStorage.setItem("ta_created_date","");
                sessionStorage.setItem("ta_updated_date","");
                sessionStorage.setItem("ta_updated_by","");
                sessionStorage.setItem("ta_last_comments_to_requestor","");
                sessionStorage.setItem("ta_last_comments_to_approver","");
                sessionStorage.setItem("ta_weight","");
                sessionStorage.setItem("ta_status_id","");
                sessionStorage.setItem("ta_status_desc","");
                sessionStorage.setItem("ta_op_code","");
                sessionStorage.setItem("ta_label_id","");
                sessionStorage.setItem("ta_label_desc","");
                sessionStorage.setItem("ta_department_id","");
                sessionStorage.setItem("ta_department_desc","");
                sessionStorage.setItem("ta_expense_type_id","");
                sessionStorage.setItem("ta_expense_type_desc","");
                sessionStorage.setItem("ta_artist_name","");
                sessionStorage.setItem("ta_business_unit","");
                sessionStorage.setItem("ta_artist_project_name","");
                sessionStorage.setItem("ta_artist_project_number","");
                sessionStorage.setItem("ta_sound_biz_2","");
                sessionStorage.setItem("ta_in_policy","");
                sessionStorage.setItem("ta_car_service_only","");
                sessionStorage.setItem("ta_travel_agent_name","");
                sessionStorage.setItem("ta_has_air","");
                sessionStorage.setItem("ta_air_missing_traveler","");
                sessionStorage.setItem("ta_air_traveler_count","");
                sessionStorage.setItem("ta_air_total","");
                sessionStorage.setItem("ta_air_coding","");
                sessionStorage.setItem("ta_has_rental_car","");
                sessionStorage.setItem("ta_rental_car_missing_traveler","");
                sessionStorage.setItem("ta_rental_car_traveler_count","");
                sessionStorage.setItem("ta_rental_car_total","");
                sessionStorage.setItem("ta_rental_car_coding","");
                sessionStorage.setItem("ta_has_car_service","");
                sessionStorage.setItem("ta_car_service_missing_traveler","");
                sessionStorage.setItem("ta_car_service_traveler_count","");
                sessionStorage.setItem("ta_car_service_total","");
                sessionStorage.setItem("ta_car_service_coding","");
                sessionStorage.setItem("ta_has_hotel","");
                sessionStorage.setItem("ta_hotel_missing_traveler","");
                sessionStorage.setItem("ta_hotel_traveler_count","");
                sessionStorage.setItem("ta_hotel_total","");
                sessionStorage.setItem("ta_hotel_coding","");
                sessionStorage.setItem("ta_non_disputable_hotel","");
                sessionStorage.setItem("ta_total","");
                sessionStorage.setItem("ta_coding_total","")
                sessionStorage.setItem("ta_car_service2","")
                this.response.msg = "";
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    
    etaTravelerSearch (firstName: any, lastName: any, lastNameStartsWith: any, type: any, taid: string){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.etaTravelerSearch;
        var data = {
            firstName: firstName,
            lastName:lastName,
            lastNameStartsWith: lastNameStartsWith,
            type: type,
            taid: taid
        }
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response=>{
            var jsonTraveller : Array<{"id":number, "name": string, "formOnFile": string, "type": string, "restrictions": any, "opCo": any, "comments": any, "submitter": any, "travelFormOnFile":any,"travelRestrictions":any}> = [];
                if(response.success){
                    response.recordset.forEach(traveller);
                    function traveller(item: { ID: any, TravelerName: any, FormOnFile: any, Type: any, Restrictions: any, OpCo: any, Comments: any, Submitter: any, TravelFormOnFile:any,TravelRestrictions:any}){
                        jsonTraveller.push({"id": item.ID, "name": item.TravelerName, "formOnFile": item.FormOnFile, "type": item.Type, "restrictions": item.Restrictions, "opCo": item.OpCo, "comments": item.Comments, "submitter": item.Submitter, "travelFormOnFile":item.TravelFormOnFile,"travelRestrictions":item.TravelRestrictions})
                    }
                    return JSON.parse(JSON.stringify(jsonTraveller).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonTraveller).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    addTravellerDetails(data){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.addTravellerDetails;
        
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertTravelerOnTheFly (data){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertTravelerOnTheFly;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getTravellerInfo (id){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getTravellerInfo;
        var data = {
            id : id
        }
        return this.serviceProxy.get(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getTravellersForTA (authReqId){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getTravellersForTA;
        var data = {
            authReqId: authReqId,
        }
        //console.log(data);
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonTraveller : Array<{"travellerId":number, "travellerName": string, "formOnFile": string, "type": string, "restrictions": any, "reason": any, "contestWinner": any, "DHA": any, "comments": any, "RLN": any, "authReqId": any, "BCDResults": any, "BCDAcceptName": any, "BCDNoRetrieve": any, "TravelerType": any, "FirstName":any, "LastName":any, "Name":any, "SeniorExecApproval":any, "TravelContestWinner":any, "SSN":any, "FedId":any, "CountryId":any,"Address1":any,"Address2":any,"City":any,"StateProvinceId":any,"StateProvince":any,"Zip":any,"Phone":any,"TravelFormOnFile":any,"TravelRestrictions":any,"Submitter":any,"OpCo":any,"Issuer":any,"CallLetters":any,"RadioLetterReceived":any,"OverrideReceived":any,"PreapprovalReceived":any}> = [];
                if(response.success){
                    response.recordset.forEach(traveller);
                    function traveller(item: { TravelerID: any, TravelerName: any, FormOnFile: any, Type: any, Restrictions: any, Reason: any, ContestWinner: any, DHA: any, Comments: any, RLN: any, AuthReqID: any, BCD_results: any, BCD_acceptName: any, BCD_noretrieve: any, FK_Traveler_Type: any, First_Name:any, Last_Name:any, Name:any, Is_DH:any, TravelContestWinner:any, SSN:any, Fed_ID:any,Country_ID:any,Address1:any,Address2:any,City:any,State_Province_ID:any,State_Province:any,Zip:any,Phone:any,TravelFormOnFile:any,TravelRestrictions:any,Submitter:any,OpCo:any,Issuer:any,Call_Letters:any,Radio_Letter_Received:any,Override_Received:any,Preapproval_Received:any}){
                        jsonTraveller.push({"travellerId": item.TravelerID, "travellerName": item.TravelerName, "formOnFile": item.FormOnFile, "type": item.Type, "restrictions": item.Restrictions, "reason": item.Reason, "contestWinner": item.ContestWinner, "DHA": item.DHA, "comments": item.Comments, "RLN": item.RLN, "authReqId": item.AuthReqID, "BCDResults": item.BCD_results, "BCDAcceptName": item.BCD_acceptName, "BCDNoRetrieve": item.BCD_noretrieve, "TravelerType": item.FK_Traveler_Type, "FirstName": item.First_Name, "LastName": item.Last_Name, "Name": item.Name, "SeniorExecApproval": item.Is_DH, "TravelContestWinner": item.TravelContestWinner,"SSN":item.SSN,"FedId": item.Fed_ID, "CountryId":item.Country_ID,"Address1":item.Address1,"Address2":item.Address2,"City":item.City,"StateProvinceId":item.State_Province_ID,"StateProvince":item.State_Province,"Zip":item.Zip,"Phone":item.Phone,"TravelFormOnFile":item.TravelFormOnFile,"TravelRestrictions":item.TravelRestrictions,"Submitter":item.Submitter,"OpCo":item.OpCo,"Issuer":item.Issuer,"CallLetters":item.Call_Letters,"RadioLetterReceived":item.Radio_Letter_Received,"OverrideReceived":item.Override_Received,"PreapprovalReceived":item.Preapproval_Received})
                    }
                    return JSON.parse(JSON.stringify(jsonTraveller).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonTraveller).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getTravellerDetailsByRLN(RLN:string){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getTravellerDetailsByRLN;
        var data = {
            RLN: RLN
        }
        //console.log(data);
        return this.serviceProxy.get(url,data)
        .then(response => {
            if(response.success){
                return response;
            }
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 

    }

    getBCDGeneratedTravel(authReqId:string){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getBCDGeneratedTravel;
        var data = {
            authReqId:authReqId
        }
        //console.log(data);
        return this.serviceProxy.get(url,data)
        .then(response => {
            //
            //if(response.success){
                return response;
            //}
        }).catch(error =>{
            console.error('There was an error!', error);
        }); 
    }
    updateTravellerDetailsBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.updateTravellerDetailsBCD;
        var data1 = {
            authReqId: data.authReqId,
            travelerId: data.travelerId,
            bcdResults: data.bcdResults,
            acceptName: data.acceptName,
            noretrieve: data.noretrieve
        };
        return this.serviceProxy.put(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateTravellerDetailsBCDRecLoc (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.updateTravellerDetailsBCDRecLoc;
        var data1 = {
            authReqId: data.authReqId,
            travelerId: data.travelerId,
            bcdResults: data.bcdResults,
            acceptName: data.acceptName,
            noretrieve: data.noretrieve,
            pnrJson: data.pnrJson,
            recordLocator: data.recordLocator
        };
        return this.serviceProxy.put(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    async getPNRByXML (data:any){
        
        var jsonResult;
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.getPNRByXML;
        //jsonResult = await this.serviceProxy.asyncPost(url,data);
        jsonResult = await this.serviceProxy.asyncGetOne(url,data);
        
        return jsonResult;
    }

    insertAirBookingsBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertAirBookingsBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    
    insertAirMapBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertAirMapBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertAirLegBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertAirLegBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertHotelBookingsBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertHotelBookingsBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertHotelMapBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertHotelMapBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertCarRentalsBookingsBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertCarRentalsBookingsBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    insertCarRentMapBCD (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertCarRentMapBCD;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    
    deleteTraveller (travelerId){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.commonService.deleteTraveller;
        var data = {
            travelerId: travelerId,
        }
        return this.serviceProxy.delete(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    
    etaTravelerSearchAdmin (firstName: any, lastName: any, lastNameStartsWith: any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.etaTravelerSearchAdmin;
        var data = {
            firstName: firstName,
            lastName:lastName,
            lastNameStartsWith: lastNameStartsWith
        }
        //console.log(data);
        return this.serviceProxy.post(url,data).then(response=>{
            var jsonTraveller : Array<{"id":number, "name": string, "formOnFile": string, "type": string, "restrictions": any, "opCo": any, "comments": any, "submitter": any, "travelFormOnFile":any,"travelRestrictions":any}> = [];
                if(response.success){
                    response.recordset.forEach(traveller);
                    function traveller(item: { ID: any, TravelerName: any, FormOnFile: any, Type: any, Restrictions: any, OpCo: any, Comments: any, Submitter: any, TravelFormOnFile:any,TravelRestrictions:any}){
                        jsonTraveller.push({"id": item.ID, "name": item.TravelerName, "formOnFile": item.FormOnFile, "type": item.Type, "restrictions": item.Restrictions, "opCo": item.OpCo, "comments": item.Comments, "submitter": item.Submitter, "travelFormOnFile":item.TravelFormOnFile,"travelRestrictions":item.TravelRestrictions})
                    }
                    return JSON.parse(JSON.stringify(jsonTraveller).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonTraveller).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    insertTraveler (data){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.insertTraveler;
        return this.serviceProxy.post(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    updateTravelerInfo (data:any){
        var url = this.variables.Url+ this.variables.Controller.traveller + this.variables.travellerService.updateTravelerInfo;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
}
