import React, { Component } from "react";
import { IApproverHistoryParameter, Istate } from "../../../Model/IApproverHistory";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "react-bootstrap";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import * as Utils from '../../../js/Utils.js';
import { TravellerController } from "../../../Controllers/TravellerController";
import { SubmitterController } from "../../../Controllers/SubmitterController";
import addSubtractDate from 'add-subtract-date';
import { SearchBox } from "../../CustomComponents/SearchBox";
import { ApproverController } from "../../../Controllers/ApproverController";
import * as Constants from "../../../js/Constants";
import parse from 'html-react-parser';
import { CommonController } from "../../../Controllers/CommonController";

export class ApproverHistory extends Component<IApproverHistoryParameter, Istate> {
    constructor(props: IApproverHistoryParameter) {
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null) {
            loggedIn = false;
        }
        this.state = {
            fields: {searchDays: "7", startDate: "", endDate: new Date(), searchString: "*", searchScope: "ar.authreq_id"},
            errors: {},
            errMsg: "",
            loggedIn,
            approverHistory: [],
            currentSort: 'down',
            currentColumn: 'authReqId',
            loading: false,
            modal: { modalSpinnerShow: false }
        }
    }

    componentWillMount() {
        let fields = this.state.fields;
        var date = new Date();
        date.setDate(date.getDate() - this.state.fields.searchDays);
        fields["startDate"] = date;
        this.setState({ fields })
        this.pageLoad();
    }

    pageLoad(){
        /* UnLocking TA for the current users */
        
        let comController = new CommonController();
        var data = {
            authreqid: sessionStorage.getItem("ta"),
            userid: sessionStorage.getItem("uid"),
            shadowid: sessionStorage.getItem("shadow"),
            check: ""
        }
        comController.updateUnLockAuthreq(data).then(response => {

        }).catch(error => {
            console.error("There was an error!", error)
        })
        
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        this.setState({modal});
        sessionStorage.setItem("frompage", Constants.approverhistory);
        let approverController = new ApproverController();
        var searchStringLike = "";
        var searchString = "";
        if (
            !this.state.fields.searchString.includes("*") &&
            this.state.fields.searchString === ""
          ) {
            let addStar = "*";
            let fields = this.state.fields;
            fields["searchString"] = addStar.concat(fields["searchString"]);
            this.setState({ fields });
          }
        if(this.state.fields.searchString.indexOf("*") != -1){
            searchStringLike = this.state.fields.searchString.replace("*","%");
            searchString = "";
        }
        else{
            searchString = this.state.fields.searchString;
        }
        var approverHistoryData = {
            labelId: sessionStorage.getItem("label_id"),
            departmentId: sessionStorage.getItem("department_id"),
            uid: sessionStorage.getItem("uid"),
            sysop: sessionStorage.getItem("sysop"),
            userTypeId: sessionStorage.getItem("user_type_id"),
            approverTierId: sessionStorage.getItem("approver_tier_id") == "null" ? 0 : sessionStorage.getItem("approver_tier_id"),
            startDate: Utils.FormatDate(this.state.fields.startDate),
            endDate: Utils.FormatDate(addSubtractDate.add(this.state.fields.endDate,1,"day")),
            searchScope: this.state.fields.searchScope,
            searchString: searchString,
            searchStringLike: searchStringLike,
            isDHA: sessionStorage.getItem("is_dha")
        }
        approverController.getApproverHistory(approverHistoryData).then(jsonApproverHistory => {
            this.setState({
                approverHistory: JSON.parse(JSON.stringify(jsonApproverHistory))
            });
            modal['modalSpinnerShow']=false;
            this.setState({modal});
        }).catch(error => {
            console.error("There was an error...," + error)
        });
    }

    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };

    onSortChange = (col) => {
        
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';

        let arrayCopy: []
        arrayCopy = this.state.approverHistory;
        arrayCopy.sort(this.compareBySort(col, nextSort));

        this.setState({
            approverHistory: arrayCopy
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    handleCallback = (searchDays, startDate, endDate, searchString, searchScope) => {
        let fields = this.state.fields;
        fields["searchDays"] = searchDays;
        fields["startDate"] = startDate;
        fields["endDate"] = endDate;
        fields["searchString"] = searchString;
        fields["searchScope"] = searchScope;
        this.setState({ fields });
        this.pageLoad();
    }

    onSelect(authReqId){
        let travellerController = new TravellerController();
        sessionStorage.setItem("page","approver");
        travellerController.getTA(authReqId).then(response => {
            this.props.history.push({pathname:'/admin/decision',state:{from:"approverinbox"}});
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }

    render() {
        var prevAuthReqId = 0;
        return (
            <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Approver TA History</h4><p className="category"></p></div>
                            <Card.Body style={{marginBottom:"150px"}}>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>
                                <SearchBox parentCallback = {this.handleCallback} from = "approver"/>
                                <Row>
                                    <Col sm={12}>
                                        <div style={{ width: '100%', height: "auto" }}>
                                            <Table hover striped bordered >
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => this.onSortChange('authReqId')}>Auth Req<i className={this.setArrow('authReqId')}></i></th>
                                                        <th onClick={() => this.onSortChange('traveler')}>Traveler<i className={this.setArrow('traveler')}></i></th>
                                                        <th onClick={() => this.onSortChange('createdDate')}>Submitted<i className={this.setArrow('createdDate')}></i></th>
                                                        <th onClick={() => this.onSortChange('createdBy')}>Submitter<i className={this.setArrow('createdBy')}></i></th>
                                                        <th onClick={() => this.onSortChange('artistProjectNumber')}>Number<i className={this.setArrow('artistProjectNumber')}></i></th>
                                                        <th onClick={() => this.onSortChange('artistProjectName')}>Project<i className={this.setArrow('artistProjectName')}></i></th>
                                                        <th onClick={() => this.onSortChange('artistName')}>Artist<i className={this.setArrow('artistName')}></i></th>
                                                        <th onClick={() => this.onSortChange('statusDesc')}>Status<i className={this.setArrow('statusDesc')}></i></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.approverHistory.map((prop, key) => {
                                                        if(prevAuthReqId != prop["authReqId"]){
                                                            prevAuthReqId = prop["authReqId"] 
                                                            var i=0;
                                                            var concateTravelName='';
                                                            var filterTravelName = this.state.approverHistory.filter(x => x.authReqId == prop["authReqId"]).map(m=> m.traveler)
                                                            for(let travelName of filterTravelName){
                                                                if(i===0){
                                                                    concateTravelName = travelName;
                                                                }else
                                                                {
                                                                    concateTravelName = concateTravelName + "<br>" + travelName;
                                                                }
                                                                i=i+1;
                                                            }
                                                            return (
                                                                <tr key={key} onClick={() => this.onSelect(prop["authReqId"])} style={{cursor:"pointer"}}>
                                                                    <td>{prop["carServiceOnly"] ? 'CA' + prop["authReqId"] : prop["authReqId"]}</td>
                                                                    <td>{parse(concateTravelName)}</td>
                                                                    <td>{Utils.FormatDate(prop["createdDate"])}</td>
                                                                    <td>{prop["createdBy"]}</td>
                                                                    <td>{prop["artistProjectNumber"]}</td>
                                                                    <td>{prop["artistProjectName"]}</td>
                                                                    <td>{prop["artistName"]}</td>
                                                                    <td>{prop["statusDesc"]}</td>
                                                                </tr>
                                                            );
                                                        }
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
}