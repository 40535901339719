import React, { Component, CSSProperties } from "react";
import { ICarServiceDecisionParameter, Istate } from "../../../Model/ICarServiceDecision";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { CarServiceIncomingCAController } from "../../../Controllers/CarServiceIncomingCAController";
import { CommonController } from "../../../Controllers/CommonController";
import '../../../assets/css/Style.css'
import * as Utils from '../../../js/Utils.js';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { EmailController } from "../../../Controllers/EmailController";
import { alert, confirm } from 'react-bootstrap-confirmation';
import { AuthStatus } from "../Common/AuthStatus";

export class CarServiceDecision extends Component<ICarServiceDecisionParameter,Istate> {
    constructor(props:ICarServiceDecisionParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {sessionTA: sessionStorage.getItem('ta'), commentsToRequestor:'',statusId: ''},
            fieldsTA:{authreqId:'',soundBiz2:'',inPolicy:false,traveler_Count:'',isDh:false,hasAir:false , airMissingTraveler:'',airTravelerCount:'',airTotal:'',airCoding:'',
                        hasHotel:false,hotelMissingTraveler:'',hotelTravelerCount:'',hotelTotal:'',hotelCoding:'',nonDisputableHotel:'',hasRentalCar:false,rentalCarMissingTraveler:'',
                        rentalCarTravelerCount:'',rentalCarTotal:'',rentalCarCoding:'',hasCarService:false,carServiceMmissingTraveler:'',carServiceTravelerCount:'',
                        carServiceTotal:'',carServiceCoding:'',carService2:'',isCorporateCard:false,labelId:'',taStatusId:'',weight:'',createdDate:'',artistName:'',businessUnit:'', 
                        travelAgentName:'',expenseTypeId:'',artistProjectName:'',artistProjectNumber:'',name:'',email:'',businessPhone:'',opCode:'',labelDesc:'',statusDesc:'', 
                        expTypeDesc:'',departmentId:'',departmentDesc:'',carServiceOnly:'',carServiceCompanyId:'',carServiceCompany:'',maxApprovers:'',tierCode:'', 
                        updatedDate:'',updatedBy:'',lastCmtToRequestor:'',commentsToApprover:'' },           
            errors:{},
            optionfields:{reSubmit:false, terminate:false},
            errMsg:"",
            loading:false,            
            authReqId:'',  
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),                     
            loadComplete:false,                                  
            userTypeId:sessionStorage.getItem("user_type_id"),   
            travellerGrp:[],
            assignedTATraveler:[]
        } 
        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount(){
        this.setState({userId:sessionStorage.getItem("uid")})
         this.getAllDetails();
         this.tatraveler();
    }

    getAllDetails =() =>{        
        let modal = this.state.modal;    
        modal['modalSpinnerShow']=true;  
        let fieldsTA = this.state.fieldsTA;   
        let fields = this.state.fields;
        let carServiceIncomingCA= new CarServiceIncomingCAController();
        var data={
            authReqId: this.state.fields.sessionTA
        }
        //console.log(data);
        carServiceIncomingCA.getTA(data).then(response => {            
            fieldsTA["name"] = response[0].name || "";
            fieldsTA["businessPhone"] = response[0].business_phone || ""; 
            fieldsTA["expTypeDesc"] = response[0].expense_type_desc || "" ;
            fieldsTA["updatedDate"] = response[0].updated_date || "" ;
            fieldsTA["updatedBy"] = response[0].updated_by || "" ;
            fieldsTA["carService2"] = response[0].car_service2 || "" ;
            fieldsTA["taStatusId"] = response[0].status_id || "" ;  
            fieldsTA["lastCmtToRequestor"] = response[0].comments_to_requestor || "" ; 
            fieldsTA["isCorporateCard"] = Boolean(Number(response[0].is_corporate_card)); 
            fieldsTA["labelDesc"] = response[0].label_desc || "" ; 
            fieldsTA["opCode"] = response[0].op_code || "" ;             
            fieldsTA["soundBiz2"] = response[0].sound_biz_2 || "" ; 
            fieldsTA["inPolicy"] = Boolean(Number(response[0].in_policy)); 
            fieldsTA["traveler_Count"] = response[0].traveler_count || "" ; 
            fieldsTA["isDh"] = Boolean(Number(response[0].is_dh)); 
            fieldsTA["hasAir"] = Boolean(Number(response[0].has_air)); 
            fieldsTA["airMissingTraveler"] = response[0].air_missing_traveler || "" ; 
            fieldsTA["airTravelerCount"] = response[0].air_traveler_count || "" ; 
            fieldsTA["airTotal"] = response[0].air_total || "" ; 
            fieldsTA["airCoding"] = response[0].air_coding || "" ; 
            fieldsTA["hasHotel"] = Boolean(Number(response[0].has_hotel)); 
            fieldsTA["hotelMissingTraveler"] = response[0].hotel_missing_traveler || "" ; 
            fieldsTA["hotelTravelerCount"] = response[0].hotel_traveler_count || "" ; 
            fieldsTA["hotelTotal"] = response[0].hotel_total || "" ; 
            fieldsTA["hotelCoding"] = response[0].hotel_coding || "" ; 
            fieldsTA["nonDisputableHotel"] = response[0].non_disputable_hotel || "" ; 
            fieldsTA["hasRentalCar"] = Boolean(Number(response[0].has_rental_car)); 
            fieldsTA["rentalCarMissingTraveler"] = response[0].rental_car_missing_traveler || "" ; 
            fieldsTA["rentalCarTravelerCount"] = response[0].rental_car_traveler_count || "" ; 
            fieldsTA["rentalCarTotal"] = response[0].rental_car_total || "" ; 
            fieldsTA["rentalCarCoding"] = response[0].rental_car_coding || "" ; 
            fieldsTA["hasCarService"] = Boolean(Number(response[0].has_car_service)); 
            fieldsTA["carServiceMmissingTraveler"] = response[0].car_service_missing_traveler || "" ; 
            fieldsTA["carServiceTravelerCount"] = response[0].car_service_traveler_count || "" ; 
            fieldsTA["carServiceTotal"] = response[0].car_service_total || "0" ; 
            fieldsTA["carServiceCoding"] = response[0].car_service_coding || "0" ;           
            fieldsTA["labelId"] = response[0].label_id || "" ; 
            fieldsTA["weight"] = response[0].weight || "" ; 
            fieldsTA["createdDate"] = response[0].created_date || "" ; 
            fieldsTA["artistName"] = response[0].artist_name || "" ; 
            fieldsTA["businessUnit"] = response[0].business_unit || "" ;  
            fieldsTA["travelAgentName"] = response[0].travel_agent_name || "" ; 
            fieldsTA["expenseTypeId"] = response[0].expense_type_id || "" ; 
            fieldsTA["artistProjectName"] = response[0].artist_project_name || "" ; 
            fieldsTA["artistProjectNumber"] = response[0].artist_project_number || "" ; 
            fieldsTA["email"] = response[0].email || "" ; 
            fieldsTA["statusDesc"] = response[0].status_desc || "" ; 
            fieldsTA["departmentId"] = response[0].department_id || "" ; 
            fieldsTA["departmentDesc"] = response[0].department_desc || "" ; 
            fieldsTA["carServiceOnly"] = response[0].car_service_only || "" ; 
            fieldsTA["carServiceCompanyId"] = response[0].car_service_company_id || "" ; 
            fieldsTA["carServiceCompany"] = response[0].car_service_company || "" ; 
            fieldsTA["maxApprovers"] = response[0].max_approvers || "" ; 
            fieldsTA["tierCode"] = response[0].tier_code || "" ;  
            fieldsTA["commentsToApprover"] = response[0].comments_to_approver || "" ;  
            this.setState({ fieldsTA});    
            modal['modalSpinnerShow']=false;  
            this.setState({modal})        
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        }) 
        //modal['modalSpinnerShow']=false;
        this.setState({modal});       
    }

    handleChange(e){
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0){
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else{
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
        }
        this.setState({ fields });
    }

    handleOptionChange(e){
        let optionfields = this.state.optionfields;
        let fields = this.state.fields;
        let currentTargetId = e.currentTarget.id;
        //console.log(e.currentTarget.value);
        fields["statusId"] = e.currentTarget.value;
        if (currentTargetId === "reSubmit") {
            optionfields['reSubmit'] = true;
            optionfields['terminate'] = false;
            
        }
        else {
            optionfields['reSubmit'] = false;
            optionfields['terminate'] = true;
            
        }        
        this.setState({ optionfields, fields });
    }

    onSubmit(e){
       
        e.preventDefault();
        let modal = this.state.modal;    
        modal['modalSpinnerShow']=true;  
        if(this.state.fields.statusId != ''){
            let commonController = new CommonController();
            var taHistoryData = {
                authReqId: this.state.fields.sessionTA,
                userId: sessionStorage.getItem("uid"),
                statusId: this.state.fields.statusId,
                taIsDH: this.state.fieldsTA.isDh,
                taWeight: this.state.fieldsTA.weight,
                isDHA: sessionStorage.getItem("is_dha"),
                approverTierId: sessionStorage.getItem("approver_tier_id"),
                commentsToRequestor: this.state.fields.commentsToRequestor,
                commentsToApprover: "",
            }
            //console.log("update TA History data",taHistoryData)
            commonController.UpdateTAHistory(taHistoryData).then(async response => {                
                //console.log("update TA History", response);
                var iWeight =0, statusId = this.state.fields.statusId, origStatusId;
                //console.log("statusId", statusId)                
                origStatusId = statusId;
                /*if(iWeight == 0 && statusId == "5" && sessionStorage.getItem("ta_in_policy") == "true"){
                    if(sessionStorage.getItem("ta_car_service_only") == "true"){
                        statusId = "9";
                    }
                    else{
                        statusId = "6";
                    }
                }*/
                let commonController = new CommonController()
                var taMoveData={
                    userName: sessionStorage.getItem("uid"),
                    weight: iWeight,
                    statusId: statusId,
                    authreqId: this.state.fields.sessionTA
                }
                //console.log("taMove", taMoveData);
                commonController.UpdateTaMove(taMoveData).then(response => {
                    //console.log("UpdateTaMove", response)
                    //email submitter
                    var to = this.state.fieldsTA.email!;
                    var subject = "AuthReq #" + this.state.fields.sessionTA + " has been"; 
                    switch(statusId.toString()){
                        case "3":
                            subject = subject + " returned for changes and resubmission"
                            break;
                        case "7":
                            subject = subject + " terminated"                        
                            break;
                    }
                    let commonController = new CommonController();
                    var travellerData={
                        id:'',
                        view:'assigned',
                        bInner:'false',
                        sessionTA:this.state.fields.sessionTA
                    }
                    commonController.getTATraveler(travellerData).then(jsonTravellers => {
                        var traveller = '';
                        jsonTravellers.map(prop => {
                            if(traveller === ''){
                                traveller = prop["name"];
                            }
                            else{
                                traveller = traveller + ' ,' + prop["name"];
                            }
                        })
                        subject = subject + " for travelers: " + traveller
                        var body = subject + ".<br>"
                        switch(statusId.toString()){
                            case "3": 
                            case "7":
                                body = body + "<br> <br> Please check the Decision tab for details.<br> Explanation: " + this.state.fields.commentsToRequestor
                        }
                        body = body + "<br> <br> Logon to eTA at " + Utils.getSiteCollectionUrl() + " for more information. <br> <br>Thank you for using eTA!" 
                        let emailController = new EmailController();
                        emailController.sendEmail('',to,subject,body);
                    }).catch(error => {
                        console.error("There was an error loading Traveler..., " + error)
                    });
                    //unlockTA
                    /*if(sessionStorage.getItem("page") === "approver" && iWeight == 0 && origStatusId == "5"){
                        //AddBCDRemarks taNumber
                    }
                    
                    //if resubmit by and BCD tier approver, convert BCD generated reservations to manual
                    if(statusId == "3" && sessionStorage.getItem("approver_tier_id") == "0"){
                        //ConvertBCDResvToManual taNumber
                    }*/

                    switch(statusId.toString()){
                        case "3": 
                            alert("AuthReq #" + this.state.fields.sessionTA + " has been ReSubmited");
                            break;
                        case "7":
                            alert("AuthReq #" + this.state.fields.sessionTA + " has been Terminated");
                            break;
                    }                    
                    
                    this.props.history.push("/admin/incomingca");
                    modal['modalSpinnerShow']=false;
                    this.setState({modal})  
                }).catch(error => {
                    console.error("There was an error!", error)
                })	    
            }).catch(error => {
                //console.log("There was an error...", error)
            })
        }
    }

    tatraveler(){
        let commonController = new CommonController();
        var data={};
        data={
            id:'',
            view:'assigned',
            bInner:'false',
            sessionTA:this.state.fields.sessionTA
        }
        commonController.getTATraveler(data).then(jsonTravellers => {
            this.setState({                           
                assignedTATraveler : JSON.parse(JSON.stringify(jsonTravellers)),
            });
            //console.log("assignedTATraveler, ",this.state.assignedTATraveler)
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });       
    }

    redirectToPage(tabLabel){
        switch (tabLabel) {
            case 'Confirmation':
                this.props.history.push({pathname:'/admin/carserviceconfirmation',state:{from:this.props.location.state.from}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/carservicedecision',state:{from:this.props.location.state.from}});
                break;
        }
    }

    formatCurrency = (amount) => { 
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    applyDecision(loading)
    {
        return (<Row>               
                <Col sm={12}>
                    <Form onSubmit = {this.onSubmit}>
                        <table style={{border:"1px white solid",backgroundColor:"silver"}}>  
                            <tr><td colSpan={2} style={{textAlign:"center",border:"1px white solid",backgroundColor:"silver"}}>Submitter Request</td></tr>                              
                            <tr style={{border:"1px white solid"}}>
                                <td style={{width:"25%", backgroundColor:"silver", textAlign:"right",border:"1px white solid"}}>Select Status:</td>                                
                                <tr>
                                    <td style={{width:"75%"}}><Form.Check type="radio" label="ReSubmit (return to Submitter for changes)" name="statusId"  id="reSubmit" onChange={(e)=>this.handleOptionChange(e)} title="ReSubmit" checked={this.state.optionfields.reSubmit} value="3"/></td>
                                </tr>
                                <tr>
                                    <td style={{width:"75%"}}><Form.Check type="radio" label="Terminate (cancel/reject - no changes can be made)" name="statusId" id="terminate" onChange={(e)=>this.handleOptionChange(e)} title="Terminate" checked={this.state.optionfields.terminate} value="7"/></td>
                                </tr>
                            </tr>
                            <tr>
                                <td style={{backgroundColor:"silver", textAlign:"right",border:"1px white solid"}}>Comments to Submitter:</td>
                                <td>
                                    <FormControl size="sm" id="commentsToRequestor" style={{width:"80%"}} as="textarea" name="commentsToRequestor" className="form-control input-sm" value={this.state.fields.commentsToRequestor} onChange={(e) => this.handleChange(e)} ></FormControl>
                                </td>
                            </tr>                            
                            <tr style={{height:"30px"}}>
                                <td style={{textAlign:"center"}} colSpan={2}>
                                    <Button id="applyDecision1" type="button" size="sm" disabled={loading} onClick={(e) => this.onSubmit(e)}>
                                        {loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" , color:"#487afa" }}
                                        />
                                        )}
                                        {loading && <span style={{color:"#487afa"}} >wait...</span>}
                                        {!loading && <span>Apply Decision</span>}
                                    </Button>
                                </td>
                            </tr>
                        </table>
                    </Form>
                </Col>               
            </Row>)
            
        }

    displayDecision=()=>{
        const{ loading } = this.state;
        let   carSerId = "";
        let i=0;
        return(<div>
                <Row className="text-dark font-size-xs small">   
                    <Col sm={2}></Col>
                    <Col sm={8} style={{alignSelf:"center"}}>
                        <div style={{marginTop:"20px"}}></div>
                        <table style={{border:"1px black solid",marginLeft:"15%",width:"50%"}}>
                            <tr style={{backgroundColor:"PowderBlue",border:"1px black solid"}}>
                                <th style={{padding:"2px",width:"25%",border:"1px black solid"}}></th>
                                <th style={{padding:"2px",width:"25%",border:"1px black solid"}}>TA</th>
                                <th style={{padding:"2px",width:"25%",border:"1px black solid"}}>Coding</th>
                                <th style={{padding:"2px",width:"25%",border:"1px black solid"}}>Travelers</th>
                            </tr>              
                            <tr style={{border:"1px black solid"}}>
                                <td style={{backgroundColor:"LightCyan",padding:"1px", alignSelf:"right",border:"1px black solid"}}>Car Service</td>
                                <td style={{padding:"2px", textAlign:"right",border:"1px black solid"}}>{this.formatCurrency(this.state.fieldsTA.carServiceTotal)}</td>
                                <td style={{padding:"2px", textAlign:"right",border:"1px black solid"}}>{this.formatCurrency(this.state.fieldsTA.carServiceCoding)}</td>
                                <td style={{padding:"2px", textAlign:"right",border:"1px black solid"}}>{this.state.fieldsTA.carServiceTravelerCount}</td>
                            </tr>
                            <tr style={{border:"1px black solid"}}>
                                <td style={{padding:"2px", alignSelf:"right",border:"1px black solid"}}>Total</td>
                                <td style={{padding:"2px", textAlign:"right",border:"1px black solid"}}>{this.formatCurrency(this.state.fieldsTA.carServiceTotal)}</td>
                                <td style={{padding:"2px", textAlign:"right",border:"1px black solid"}}>{this.formatCurrency(this.state.fieldsTA.carServiceCoding)}</td>
                                <td style={{padding:"2px", textAlign:"right",border:"1px black solid"}}></td>
                            </tr>                    
                        </table>

                        <div style={{marginTop:"20px"}}></div>                       

                        <table style={{border:"1px black solid",width:"50%",marginLeft:"15%"}}>
                            <tr style={{backgroundColor:"PowderBlue"}}>                                                            
                                <th style={{padding:"2px",width:"20%",textAlign:"center"}} colSpan={2}>Travelers</th>
                            </tr>              
                            <tr>
                                <td style={{backgroundColor:"LightCyan",padding:"1px", alignSelf:"right",border:"1px black solid"}}>Assigned:</td>
                                <td style={{padding:"1px", textAlign:"right",border:"1px black solid"}}>
                                    {this.state.assignedTATraveler.map((prop, key) => {
                                            return (<tr key={key}><td>{prop["name"]}</td></tr>)
                                    })}
                                </td>                                
                            </tr>                                               
                        </table>

                        <div style={{marginTop:"20px"}}></div>  

                        <table style={{marginLeft:"20%"}}>
                            <tr>   
                                <td style={{width:"47%", alignSelf:"right"}}>Creation Date:</td>                                                         
                                <td style={{width:"53%"}}>{Utils.FormatDateTime(this.state.fieldsTA.createdDate)}</td> 
                            </tr>              
                            <tr>
                                <td style={{alignSelf:"right"}}>Car Service Agent: &nbsp;</td> 
                                <td>{this.state.fieldsTA.travelAgentName}</td>                                 
                            </tr>                                               
                        </table>

                        <div style={{marginTop:"20px"}}></div>

                        {this.applyDecision(loading)}            

                    </Col>
                    <Col sm={2}></Col>                   
                </Row>
            </div>)
    }


    render() {               
        const tdStyle = {
            width:'15%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };  
         const divStyle1 = {
          width: '100%',                   
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };
        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Car Service Confirmation</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <AuthStatus/>
                                <section style={{marginTop:"10px"}}>
                                    <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={1} mountOnExit>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation"></Tab>
                                        <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision">{this.displayDecision()}</Tab>
                                    </Tabs>
                                </section>
                                <Row className="mt-3">
                                    <Col sm={12} style={{ paddingBottom: "15px"}}>
                                        
                                    </Col>
                                </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}
