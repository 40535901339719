import {FormControl,FormLabel,FormGroup} from "../../../node_modules/react-bootstrap";
import React,{ Component } from 'react';

export class FormInputs extends Component<IFormInput> {
    render() { 
      const {errorMsg,fieldRequired} = this.props;
      const {id,label,isDoller} = this.props.properties;

      let GetMandatoryGroupField=(fldReqd)=>{
        if(fldReqd===true) 
        {return (<span className="text-danger">*</span>)}
        else
         return (<span></span>);
      }
      
      const DisplayErrorMessageGroupField=(errMsg)=>{
        if(errMsg!=='') 
        {return <div className="text-danger font-size-xs small font-weight-bold">{errMsg}</div>}
        else
        return (<></>);
      }

      //debugger;
      return(<div id={"div"+id} >
        <FormGroup>
          <FormLabel>{label}</FormLabel>
          {GetMandatoryGroupField(fieldRequired)}
          {(isDoller)?
          <div><span style={{display: "inline-flex"}}>$<FormControl size="sm" {...this.props.properties}/></span></div>
          :
          <FormControl size="sm" {...this.props.properties}/>}
          {DisplayErrorMessageGroupField(errorMsg)}
        </FormGroup>
        </div>)
      }
    }

  interface IFormInput
  {
      properties:IProperties;
      errorMsg?:string;
      fieldRequired:boolean;
      disbled?:boolean;
  }
  interface IProperties
  {
      id:string;
      label:string;
      type:string;
      name:string;
      value?:string;
      className?:string;
      maxlength?:number;
      placeholder?:string;
      onChange?:any;
      disabled?:boolean;
      onBlur?:any;
      ref?:any;
      onKeyUp?:any;
      isDoller?:boolean;
      style?:any;
      autocomplete?:string;
  }
  interface Istate
  {

  }