import {FormControl,FormLabel,FormGroup} from "../../../node_modules/react-bootstrap";
import React,{ Component } from 'react';

export class FormSelect extends Component<IFormSelect> {

    render() { 
      const {errorMsg,fieldRequired,defaultOption,fillOption} = this.props;
      const {id,label} = this.props.properties;

      //debugger;
      let GetMandatoryGroupField=(fldReqd)=>{
        if(fldReqd===true) 
        {return (<span className="text-danger">*</span>)}
        else
         return (<span></span>);
      }
      
      const DisplayErrorMessageGroupField=(errMsg)=>{
        if(errMsg!=='') 
        {return <div className="text-danger font-size-xs small font-weight-bold">{errMsg}</div>}
        else
        return (<></>);
      }

     const DefaultValueOption=(defOption)=>{
        if(defOption!=='') return <option>{defOption}</option>
      }

     let renderOptions = fillOption.map((data) =>
                <option key={data.id} value={data.id}>{data.name}</option>
          );

      //debugger;
         return(<div id={"div"+id} >
           <FormGroup>
            <FormLabel>{label}</FormLabel>
            {GetMandatoryGroupField(fieldRequired)}
            <FormControl as="select" {...this.props.properties}>
              {DefaultValueOption(defaultOption)}
              {renderOptions}
            </FormControl>
            {DisplayErrorMessageGroupField(errorMsg)}
          </FormGroup>
          </div>)
      }
    }

  interface IFormSelect
  {
    properties:IProperties;
    errorMsg?:string;
    fieldRequired?:boolean;
    defaultOption:string;
    fillOption:any;
  }
  interface IProperties
  {
    id:string;
    label:string;
    type?:any;
    name:string;
    className?:string;
    onChange:any;
    value?:string;
    disabled?:boolean;
  }

  interface Istate
  {

  }