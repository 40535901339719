import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class EmailController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }
    
    sendEmail(from: string, to: string, subject: string, html: string) {
        var url = this.variables.Url+ this.variables.Controller.email + this.variables.emailService.sendEmail;
        if(from === ""){
            from = "eta@umusic.com";
        }
        if(subject === ""){
            subject = "eTA Test Email"; 
        }
        if(html === ""){
            html = "Testing 123";
        }
        var data = {
            from: from,
            to: to,
            subject: subject,
            html: html
        }
        return this.serviceProxy.post(url,data).then(response => {
            if(response.success){
                return;
            }
            else{
                console.error("Error in sending email...," + response.message)
                return;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    
    sendTechSupportEmailAPI(type: string, subject: string, html: string) {
        var url = this.variables.Url+ this.variables.Controller.email + this.variables.emailService.sendTechSupportEmailAPI;
        var data = {
            type: type,
            subject: subject,
            html: html
        }
        return this.serviceProxy.post(url,data).then(response => {
            if(response.success){
                return;
            }
            else{
                console.error("Error in sending email...," + response.message)
                return;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}
