import React, { Component } from "react";
import { IBCDCodingParameter, Istate } from "../../../Model/IBCDCoding";
import {Container,Row,Col,Form,Alert,Button,Card,Table} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormInputs } from "../../CustomComponents/FormInput";
import { AddBCDCodingController } from "../../../Controllers/AddBCDCodingController";
import '../../../assets/css/Style.css'
import * as Utils from '../../../js/Utils.js';
import { alert, confirm  } from 'react-bootstrap-confirmation';
import ReactPaginate from 'react-paginate';
import { NavLink } from 'react-router-dom'

export class AddBCDCoding extends Component<IBCDCodingParameter,Istate> {
    constructor(props:IBCDCodingParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {authId:''},
            selectfields:{status:'5',cStatus:'1'},
            errors:{authId:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            bcdCodingGroups:[],
            isListAvl:false,            
            isListAvlMsg:"No record(s) found",
            offset: 0,
            tableData: [],                  
            perPage: 5,
            currentPage: 0,
            pageCount:0                 
                       
        } 
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);  
        this.onClear = this.onClear.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onViewXml = this.onViewXml.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;        
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true; 
        this.getData();
        modal['modalSpinnerShow']=false;
        this.setState({ modal});        
    }

    setDefault(){
        let selectfields = this.state.selectfields;  
        let fields = this.state.fields;
        selectfields["status"] = "5"
        selectfields["cStatus"] = "1"
        fields["authId"]=""
        this.setState({ selectfields,fields,isListAvl:false});  
    }

    getData(){
        let modal = this.state.modal;         
        let addBCDCodingController = new AddBCDCodingController();
        var data={
            status : this.state.selectfields.status,
            cStatus: this.state.selectfields.cStatus,
            authReqId:this.state.fields.authId
        }
        
        addBCDCodingController.GetBCDAuthorizationRequest(data).then(jsonBCDAuthorizationRequest => {
            this.setState({ bcdCodingGroups: JSON.parse(JSON.stringify(jsonBCDAuthorizationRequest))});  
            modal['modalSpinnerShow']=false;  
            this.setState({modal})   
            if(this.state.bcdCodingGroups.length > 0)
                this.setState({isListAvl:true})
            else
             this.setState({isListAvl:false})

            var data = this.state.bcdCodingGroups;				
            var slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)               
                this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage),                   
                tableData:slice
            })  
            
            
                
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
            this.setState({modal})
        })  
    }

    onClear(e:any)
    {
        e.preventDefault();
        
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    loadMoreData() {
		const data = this.state.bcdCodingGroups;
		
		const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
		this.setState({
			pageCount: Math.ceil(data.length / this.state.perPage),
			tableData:slice
		})
    }

    onViewXml=(e:any,data)=>{
        e.preventDefault();
        let modal = this.state.modal;   
        var result='';   // return default value       
        let addBCDCodingController = new AddBCDCodingController();
        var data1={
            authreqId : data,            
        }
        
        addBCDCodingController.GetValidatePNRXML(data1).then(response => {                      
            if((response[0].result === "") || (response[0].result === null)) 
            {               
                alert('No XML Data Found')
            }
            else{                
                result = "<Traveler name=\"" + response[0].Name +  "\" RLN=\"" + response[0].RLN + "\">" + response[0].BCD_pnrXml + "</Traveler>";
                result = "<Pnr>" + result.replace("<?xml version=\"1.0\" encoding=\"utf-8\"?>", "") + "</Pnr>";
                window.open('/viewpnrxml?authReqId='+ data, '_blank', 'height=570,width=620, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no');
            }
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })         
    }

    async onAddBCDRemark (data) {
        var flag = await confirm("Are you sure want to add CTM remarks for TA# " + data ,{title:"Confirm Add Remarks", okText:"Yes", cancelText:"No"})
        if(flag)
            this.props.history.push({pathname: '/admin/addremarks',state: { authReqId:data }})
        
    }

    onSearch(e:any){
        e.preventDefault();
        this.setState({offset:0}) 
        this.getData();
    }

    handleSelectChange = (e: any) => {
        let selectfields = this.state.selectfields;       
        selectfields[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ selectfields});        
    }

    handleTextChange(e:any){ 
        let fields = this.state.fields; 
        fields[e.currentTarget.id] = e.currentTarget.value; 
        this.setState({fields});       
    }

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
            width:'7%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
        const tdStyle1 = {
            width:'10%',            
            border: "2px solid #eee",
             padding:"2px"           
        };
             
        const thStyle ={
            width:'7%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Travel Class Codes</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                    <Col style={{marginTop:"2%"}} sm={.5}><div className="text-dark font-size-xs small">Status:</div></Col> 
                                    <Col style={{marginTop:"2%"}} sm={2}>
                                        <Form.Group>
                                            <Form.Control as="select" name="status" id="status" className="form-control-sm" value={this.state.selectfields.status} onChange={this.handleSelectChange}>
                                            <option value="1">Pending</option>
                                            <option value="10">Expired</option>
                                            <option value="2">New</option>
                                            <option value="3">Re-Submit</option>
                                            <option value="4">Declined</option>
                                            <option value="5">Approved</option>
                                            <option value="6">Complete</option>
                                            <option value="7">Terminated</option>
                                            <option value="8">Sr Exec Approval</option>
                                            <option value="9">Car Service Complete</option>
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>
                                        <Col style={{marginTop:"2%"}} sm={1.5}><div className="text-dark font-size-xs small">Coding Status:</div></Col> 
                                        <Col style={{marginTop:"2%"}} sm={2}>
                                        <Form.Group>
                                            <Form.Control as="select" name="cStatus" id="cStatus" className="form-control-sm" value={this.state.selectfields.cStatus} onChange={this.handleSelectChange}>
                                            <option value="all">All</option>
                                            <option value="1">Ready</option>
                                            <option value="snt">Sent</option>                                            
                                            </Form.Control>
                                        </Form.Group>
                                        </Col>      
                                        <Col style={{marginTop:"2%"}} sm={.5}><div className="text-dark font-size-xs small">TA#:</div></Col>                               
                                        <Col sm={2}>
                                            <FormInputs
                                                properties={{id:"authId",label:"",type:"text",name:"authId",value:this.state.fields.authId,className:"form-control input-sm", onChange: this.handleTextChange}}
                                                errorMsg={this.state.errors.authId}
                                                fieldRequired={false}
                                                disbled={true}
                                            />
                                        </Col>                                         
                                       <Col style={{marginTop:"2%"}} sm={1}>
                                            <Button id="save" type="button" size="sm" disabled={loading} variant="success" onClick={(e) => this.onSearch(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Search</span>}
                                            </Button>
                                        </Col> 
                                        <Col sm={1} style={{marginTop:"2%",marginLeft:"1%"}}>
                                            <Button id="save" type="button" size="sm" disabled={loading} onClick={(e) => this.onClear(e)}>
                                                {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" , color:"#3472F7" }}
                                                />
                                                )}
                                                {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                {!loading && <span>Clear</span>}
                                            </Button>                                            
                                        </Col>                                                                          
                                    </Row>                                                                 
                                    <Row style={{marginBottom:"2%"}}>                                    
                                    </Row>                                    
                                    <Row>                                        
                                       <Col sm={12}>                                        
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th style={thStyle}>TA#</th>
                                                                <th style={thStyle}>Status</th>  
                                                                <th style={thStyle}>Coding RDY</th>
                                                                <th style={thStyle}>RDY Date</th>
                                                                <th style={thStyle}>Coding Sent</th>
                                                                <th style={thStyle}>Sent Date</th>
                                                                <th style={thStyle}>Project Name</th>
                                                                <th style={thStyle}>Project Number</th>
                                                                <th style={thStyle}>Artist</th>                                                                
                                                                <th style={thStyle}>Travel Agent</th>        
                                                                <th style={thStyle}>Created By</th>
                                                                <th style={thStyle}>Expense Type</th>                                                                
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody> 
                                                        {this.state.isListAvl? 
                                                        this.state.tableData.map((prop, key) => {
                                                            return (
                                                                <tr style={{textAlign:"center",cursor:"pointer"}}  key={key} > 
                                                                    <td style={tdStyle}>{prop["authReqId"]}</td>                                                                                                                         
                                                                    <td style={tdStyle}>{prop["statusDesc"]}</td>  
                                                                    <td style={tdStyle}>{prop["codingRdy"] ? "True" : "False"}</td>
                                                                    <td style={tdStyle}>{Utils.FormatDateTime(prop["rdyDate"])}</td>
                                                                    <td style={tdStyle}>{prop["codingSent"]? "True" : "False"}</td>
                                                                    <td style={tdStyle}>{Utils.FormatDateTime(prop["codingSentDate"])}</td>
                                                                    <td style={tdStyle}>{prop["projName"]}</td>
                                                                    <td style={tdStyle}>{prop["projNo"]}</td>
                                                                    <td style={tdStyle}>{prop["artist"]}</td>
                                                                    <td style={tdStyle}>{prop["travelAgent"]}</td>
                                                                    <td style={tdStyle}>{prop["createdBy"]}</td>  
                                                                    <td style={tdStyle}>{prop["expenseType"]}</td>                                            
                                                                    <td style={tdStyle1}>
                                                                    {/*<Button id="view" type="button" size="sm" disabled={loading} onClick={() => {this.props.history.push({pathname:"/admin/viewremarks",state:{authReqId:prop["authReqId"]}})}}>
                                                                            {loading && (
                                                                            <i
                                                                                className="fa fa-refresh fa-spin"
                                                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                            />
                                                                            )}
                                                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                            {!loading && <span>View BCD Remarks</span>}
                                                                        </Button>
                                                                        <Button id="edit" type="button" size="sm" disabled={loading} onClick={(e) => this.onViewXml(e,prop["authReqId"])}>
                                                                            {loading && (
                                                                            <i
                                                                                className="fa fa-refresh fa-spin"
                                                                                style={{ marginRight: "5px" , color:"#3472F7" }}
                                                                            />
                                                                            )}
                                                                            {loading && <span style={{color:"#3472F7"}} >wait...</span>}
                                                                            {!loading && <span>View Pnr Xml</span>}
                                                                        </Button>*/}                                                                       
                                                                        <NavLink 
                                                                            to={{
                                                                                pathname:'/admin/viewremarks',
                                                                                state:{
                                                                                    authReqId:prop["authReqId"]          
                                                                                }
                                                                            }}                                 
                                                                            activeClassName="active">View CTM Remarks</NavLink>
                                                                       
                                                                            <br />
                                                                        <NavLink 
                                                                            to={{
                                                                                pathname:'',                                                                                
                                                                            }}    
                                                                            onClick={(e) => this.onAddBCDRemark(prop["authReqId"])}                             
                                                                            activeClassName="active">Add CTM Remarks</NavLink>
                                                                       
                                                                            <br />
                                                                        <NavLink
                                                                        to={{
                                                                            pathname:'',                                                                           
                                                                          }}
                                                                           onClick={(e) => this.onViewXml(e,prop["authReqId"])}                                     
                                                                            activeClassName="active">View Pnr Xml</NavLink>
                                                                    </td>
                                                                </tr>                                                                    
                                                            )
                                                            })
                                                            :
                                                            <tr style={{textAlign:"center",cursor:"pointer"}} > 
                                                                <td>{this.state.isListAvlMsg}</td>
                                                            </tr>
                                                        }                                                                                                                                                                          
                                                        </tbody>
                                                </Table>                                                                                                        
                                            </div> 
                                       </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}></Col>
                                         <Col><ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}/> </Col>                   
                                    </Row>                                                                   
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}
    