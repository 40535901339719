import React, { Component, CSSProperties } from "react";
import { IApprovalDiagramParameter, Istate } from "../../../Model/IApprovalDiagram";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "../../../../node_modules/react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { ApprovalDiagramController } from "../../../Controllers/ApprovalDiagramController";
import { CommonController } from "../../../Controllers/CommonController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import { Variables } from '../../../Variables/Variables';
import { createKeywordTypeNode } from "typescript";
///added css in style line 279 Very inportant
export class ApprovalDiagram extends Component<IApprovalDiagramParameter,Istate> {
    constructor(props:IApprovalDiagramParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={           
            selectfields: {labelId:'',labelName:''},
            errors:{labelId:''},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:"",
            labelGroups:[],
            lstMarketingDetails:[],
            lstMarketingbilling:[],
            lstMarketingAuthorizer:[],
            lstRecordingDetails:[],            
            lstRecordingbilling:[],
            lstRecordingAuthorizer:[],
            lstOverheadDetails:[],
            lstOverheadbilling:[],
            lstOverheadAuthorizer:[],
            lstInternationalDetails:[],
            lstInternationalbilling:[],
            lstInternationalAuthorizer:[],
            lstUltraRecordingDetails:[],
            lstUltraRecordingbilling:[],
            lstUltraRecordingAuthorizer:[],
            lstUltraMarketingDetails:[],
            lstUltraMarketingbilling:[],
            lstUltraMarketingAuthorizer:[],
            lstSrExecutives:[],
            getSrExecutives:[],
            labelId:"",
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentWillMount(){
        let modal = this.state.modal;        
        let selectfields= this.state.selectfields;
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;      
        
        var userId = {
            userId: sessionStorage.getItem("uid"),            
        }
        
        let approvalDiagramController = new ApprovalDiagramController();
        approvalDiagramController.GetUserLabelGroups(userId).then(jsonGetLabels => {
            this.setState({ labelGroups: JSON.parse(JSON.stringify(jsonGetLabels))});  
               
            var getLabelVal = this.state.labelGroups[0]; 
            selectfields["labelId"] =getLabelVal.id;
           
            this.getAllDetails() 
        }).catch(error => {
            console.error("There was an error!", error);
        })        
       // selectfields["labelId"] = getLabelVal.id;
        /* UnLocking TA for the current users */
       let comController = new CommonController();
       var data = {
           authreqid: sessionStorage.getItem("ta"),
           userid: sessionStorage.getItem("uid"),
           shadowid: sessionStorage.getItem("shadow"),
           check: ""
       }
       comController.updateUnLockAuthreq(data).then(response => {
       }).catch(error => {
           console.error("There was an error!", error)
       })
         
    }

    handleSelectChange(e:any){  
        let modal = this.state.modal;       
        modal['modalSpinnerShow']=true;      
        let selectfields = this.state.selectfields;
        if (e.currentTarget.id === 'labelId'){
            selectfields["labelId"] = e.currentTarget.value;
            this.setState({labelId:e.currentTarget.value})
        }       
        this.setState({selectfields});
        this.getAllDetails() 
    }

    getAllDetails()
    {       
        this.getMarketingDetails(); 
        this.getRecordingDetails();         
        this.getOverheadDetails();
        this.getInternationalDetails();
        this.getUltraRecordingDetails();
        this.getUltraMarketingDetails();

    }
    getMarketingDetails()
    {
        let modal = this.state.modal; 
        let approvalDiagramController = new ApprovalDiagramController();
        var details = {
             labelId : this.state.selectfields.labelId,
             expenseTypeId:"1",
        } 
        var billingdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"1",
            approverTier:"2",
        } 
        var Authorizerdetails = {
        labelId : this.state.selectfields.labelId,
        expenseTypeId:"1",
        approverTier:"1",
        }           
        approvalDiagramController.GetApprovalChain(details).then(jsonGetLabels => {
        this.setState({ lstMarketingDetails: JSON.parse(JSON.stringify(jsonGetLabels))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(billingdetails).then(jsonBillingApprovers => {
        this.setState({ lstMarketingbilling: JSON.parse(JSON.stringify(jsonBillingApprovers))});    
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(Authorizerdetails).then(jsonBillingApprovers => {
        this.setState({ lstMarketingAuthorizer: JSON.parse(JSON.stringify(jsonBillingApprovers))});    
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        this.setState({ modal});    
    }
    getRecordingDetails()
    {
        let modal = this.state.modal; 
        let approvalDiagramController = new ApprovalDiagramController();        
        var details = {
             labelId : this.state.selectfields.labelId,
             expenseTypeId:"10",
        }
        var billingdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"10",
            approverTier:"2",
        } 
        var Authorizerdetails = {
        labelId : this.state.selectfields.labelId,
        expenseTypeId:"10",
        approverTier:"1",
        }  
        approvalDiagramController.GetApprovalChain(details).then(jsonGetLabels => {
        this.setState({ lstRecordingDetails: JSON.parse(JSON.stringify(jsonGetLabels))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(billingdetails).then(jsonBillingApprovers => {
        this.setState({ lstRecordingbilling: JSON.parse(JSON.stringify(jsonBillingApprovers))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(Authorizerdetails).then(jsonBillingApprovers => {
        this.setState({ lstRecordingAuthorizer: JSON.parse(JSON.stringify(jsonBillingApprovers))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        this.setState({ modal});    
    }
    getOverheadDetails()
    {
        let modal = this.state.modal; 
        let approvalDiagramController = new ApprovalDiagramController();
        var chkVal="";
        var dataArray = new Array();
        var details = {
             labelId : this.state.selectfields.labelId,
             expenseTypeId:"100",
        } 
        var billingdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"100",
            approverTier:"2",
        } 
        var Authorizerdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"100",
            approverTier:"1",
        } 
        approvalDiagramController.GetApprovalChain(details).then(jsonGetLabels => {
        this.setState({ lstOverheadDetails: JSON.parse(JSON.stringify(jsonGetLabels))});  
        /* code for retriving sr Executive */
        this.state.lstOverheadDetails.map(item => {
        if (chkVal != item.departmentId) {
            chkVal = item.departmentId
            var srExdetails = {
                labelId : this.state.selectfields.labelId,
                expenseTypeId:"100",
                departmentID:item.departmentId,
            } 
            approvalDiagramController.getSrExecutives(srExdetails).then(jsonSrExecutives => {
            this.setState({getSrExecutives : jsonSrExecutives});
            this.state.getSrExecutives.map(item => {
                dataArray.push({"name":item["name"],"userId":item["userId"],"departmentId":item["departmentId"],"isActive":item["isActive"]})
            })
            }).catch(error => {
                modal['modalSpinnerShow']=false; 
                console.error("There was an error!", error);
            })
          }              
        });
        this.setState({ lstSrExecutives:dataArray})  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(billingdetails).then(jsonBillingApprovers => {
        this.setState({ lstOverheadbilling: JSON.parse(JSON.stringify(jsonBillingApprovers))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(Authorizerdetails).then(jsonBillingApprovers => {
        this.setState({ lstOverheadAuthorizer: JSON.parse(JSON.stringify(jsonBillingApprovers))});    
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })  
        this.setState({ modal});          
    }
    getInternationalDetails()
    {
        let modal = this.state.modal; 
        let approvalDiagramController = new ApprovalDiagramController();
        var details = {
             labelId : this.state.selectfields.labelId,
             expenseTypeId:"1000",
        }
        var billingdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"1000",
            approverTier:"2",
        } 
        var Authorizerdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"1000",
            approverTier:"1",
        } 
        approvalDiagramController.GetApprovalChain(details).then(jsonGetLabels => {
        this.setState({ lstInternationalDetails: JSON.parse(JSON.stringify(jsonGetLabels))}); 
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(billingdetails).then(jsonBillingApprovers => {
        this.setState({ lstInternationalbilling: JSON.parse(JSON.stringify(jsonBillingApprovers))});    
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(Authorizerdetails).then(jsonBillingApprovers => {
        this.setState({ lstInternationalAuthorizer: JSON.parse(JSON.stringify(jsonBillingApprovers))});    
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        this.setState({ modal});    
    }
    getUltraRecordingDetails()
    {
        let modal = this.state.modal; 
        let approvalDiagramController = new ApprovalDiagramController();
        var details = {
             labelId : this.state.selectfields.labelId,
             expenseTypeId:"11",
        }
        var billingdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"11",
            approverTier:"2",
        } 
        var Authorizerdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"11",
            approverTier:"1",
        }  
        approvalDiagramController.GetApprovalChain(details).then(jsonGetLabels => {
        this.setState({ lstUltraRecordingDetails: JSON.parse(JSON.stringify(jsonGetLabels))});   
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(billingdetails).then(jsonBillingApprovers => {
        this.setState({ lstUltraRecordingbilling: JSON.parse(JSON.stringify(jsonBillingApprovers))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(Authorizerdetails).then(jsonBillingApprovers => {
        this.setState({ lstUltraRecordingAuthorizer: JSON.parse(JSON.stringify(jsonBillingApprovers))});    
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        this.setState({ modal});    
    }
    getUltraMarketingDetails()
    {
        let approvalDiagramController = new ApprovalDiagramController();
        let modal = this.state.modal;
        var details = {
             labelId : this.state.selectfields.labelId,
             expenseTypeId:"2",
        }
        var billingdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"2",
            approverTier:"2",
        } 
        var Authorizerdetails = {
            labelId : this.state.selectfields.labelId,
            expenseTypeId:"2",
            approverTier:"1",
        }
        approvalDiagramController.getAuthorizerAndBillingApprovers(billingdetails).then(jsonBillingApprovers => {
        this.setState({ lstUltraMarketingbilling: JSON.parse(JSON.stringify(jsonBillingApprovers))});   
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
            console.error("There was an error!", error);
        })
        approvalDiagramController.getAuthorizerAndBillingApprovers(Authorizerdetails).then(jsonBillingApprovers => {
        this.setState({ lstUltraMarketingAuthorizer: JSON.parse(JSON.stringify(jsonBillingApprovers))});  
        }).catch(error => {
            modal['modalSpinnerShow']=false; 
           console.error("There was an error!", error);
        }) 
        approvalDiagramController.GetApprovalChain(details).then(jsonGetLabels => {
        this.setState({ lstUltraMarketingDetails: JSON.parse(JSON.stringify(jsonGetLabels))});    
        modal['modalSpinnerShow']=false;  
        }).catch(error => {
            modal['modalSpinnerShow']=false;  
            console.error("There was an error!", error);
        })
        this.setState({ modal});      
    }

    displayMarketingApprover=(item)=>{
        var retVal="";
        {this.state.lstMarketingDetails.filter(p => p.departmentId == item).map(filteredsrEx => (
            retVal = retVal + (filteredsrEx.name === null ? "" : filteredsrEx.name+"|"+filteredsrEx.isActive) + ","
        ))} 
        
        return retVal.split(',').map(str => {
            const splitsVal = str.split('|')
            return <p className={(splitsVal[1] === "true" ? "approverSmall" : "approverSmallIsActive")}>
                {splitsVal[0]}</p>;           
        });        
        
    }
    displayRecordingApprover=(item)=>{
        var retVal="";
        {this.state.lstRecordingDetails.filter(p => p.departmentId == item).map(filteredsrEx => (
            retVal = retVal + (filteredsrEx.name === null ? "" : filteredsrEx.name+"|"+filteredsrEx.isActive) + ","
        ))} 
        
        return retVal.split(',').map(str => {
            const splitsVal = str.split('|')
            return <p className={(splitsVal[1] === "true" ? "approverSmall" : "approverSmallIsActive")}>
                {splitsVal[0]}</p>;           
        });
    }
    displayOverheadApprover=(item)=>{
        var retVal="";
        {this.state.lstOverheadDetails.filter(p => p.departmentId == item).map(filteredsrEx => (
            retVal = retVal +(filteredsrEx.name === null ? "" : filteredsrEx.name+"|"+filteredsrEx.isActive)+ ","
        ))} 
        
        return retVal.split(',').map(str => {
            const splitsVal = str.split('|')
            return <p className={(splitsVal[1] === "true" ? "approverSmall" : "approverSmallIsActive")}>
                {splitsVal[0]}</p>;           
        });
    }
    displayInternationalApprover=(item)=>{ 
        var retVal="";
        {this.state.lstInternationalDetails.filter(p => p.departmentId == item).map(filteredsrEx => (
            retVal = retVal + (filteredsrEx.name === null ? "" : filteredsrEx.name+"|"+filteredsrEx.isActive) + ","
        ))} 
        return retVal.split(',').map(str => {
           const splitsVal = str.split('|')       
         return <p className={splitsVal[1] === "true"? "approverSmall" :"approverSmallIsActive"} >
             {splitsVal[0]}</p>
         });
    }
    displayUltraRecordingApprover=(item)=>{
        var retVal="";
        {this.state.lstUltraRecordingDetails.filter(p => p.departmentId == item).map(filteredsrEx => (
            retVal = retVal + (filteredsrEx.name === null ? "" : filteredsrEx.name+"|"+filteredsrEx.isActive) + ","
        ))}    

       //return retVal.split(',').map(str => <p>{str}</p>);
       return retVal.split(',').map(str => {
        const splitsVal = str.split('|')
        return <p className={(splitsVal[1] === "true" ? "approverSmall" : "approverSmallIsActive")}>
            {splitsVal[0]}</p>;           
        });
    }
    displayUltraMarketingApprover=(item)=>{
        var retVal="";
        {this.state.lstUltraMarketingDetails.filter(p => p.departmentId == item).map(filteredsrEx => (
            retVal = retVal + (filteredsrEx.name === null ? "" : filteredsrEx.name+"|"+filteredsrEx.isActive) + ","
        ))}         
        //return retVal.split(',').map(str => <p>{str}</p>);
        return retVal.split(',').map(str => {
            const splitsVal = str.split('|')
            return <p className={(splitsVal[1] === "true" ? "approverSmall" : "approverSmallIsActive")}>
                {splitsVal[0]}</p>;           
        });
    }
    displaySrExecutive=(depId)=>{
        var retVal="";
        {this.state.lstSrExecutives.filter(p => p.departmentId == depId).map(filteredsrEx => (
            retVal = retVal + (filteredsrEx.name === null ? "" : filteredsrEx.name +"|"+ filteredsrEx.isActive) + ","
        ))}  
       // return retVal.split(',').map(str => <p style={{fontSize: '10px',padding:"2px"}}>{str}</p>);  
       return retVal.split(',').map(str => {
        const splitsVal = str.split('|')
        return <p className={(splitsVal[1] === "true" ? "approverSmall" : "approverSmallIsActive")}>
            {splitsVal[0]}</p>;           
    });   
    }
    

    render() {
        const{ loading } = this.state;
        const alignContentTd = {
            width:'16%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const alignContentTdbilling = {
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const alignContentTdApprover = {
            width:'26%',            
            border: "2px solid #eee",
            fontSize: '8px', 
            padding:"2px"           
        };
        const alignContentTdSrExe = {
            width:'16%',            
            border: "2px solid #eee",
            fontSize: '8px', 
            padding:"2px"           
        };
        const thStyle ={
            width:'16%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStyleApprover ={
            width:'32%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        const thStylebilling ={
            width:'10%',            
            border: "2px solid #eee",
            padding:"2px" 
        };
        let CkhValue="";
        let CkhRecValue="";
        let CkhOverheadValue="";
        let CkhIntnatValue="";
        let CkhUltRecValue="";
        let CkhUltMarketingValue="";
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Approval Process</h4></div>
                            <Card.Body>
                                 <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                    <Row>
                                        <Col sm={12} style={{textAlign:"center"}}>
                                            <Button size="sm" disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Refresh </span>}
                                            </Button>
                                            <Button size="sm" disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                                {this.state.loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px", color: "#3472F7" }}
                                                />
                                                )}
                                                {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                                {!this.state.loading && <span>Print </span>}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                       <Col sm={4}>
                                             <FormSelect
                                                properties={{ label: "Select Label:", id: "labelId", name: "labelName", value: this.state.selectfields.labelId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                                fieldRequired={true}
                                                errorMsg={this.state.errors.labelId}
                                                defaultOption=""
                                                fillOption={this.state.labelGroups}
                                            />
                                        </Col>
                                        <Col sm={8}></Col>                                    
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            <div style={{ width: '100%', height: 'auto' }} >
                                            <Table>
                                                <tr>
                                                    <td style={{textAlign:"center" ,width:"20%",fontSize:"150%",fontWeight:"bold",color:"navy"}}>
                                                    Marketing</td>
                                                    <td style={{ width: '80%'}}>
                                                        <div>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px" }}>Billing Approver</th>
                                                                     {this.state.lstMarketingbilling.map((prop, key) => {
                                                                     return (
                                                                      <tr style={{textAlign:"left",padding:"2px"}} key={key} >    
                                                                      <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>                                                                                                                   
                                                                    </tr>
                                                                     )                                                               
                                                                     })}
                                                                </td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px"}}>Authorizer</th>
                                                                     {this.state.lstMarketingAuthorizer.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} > 
                                                                       <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                            </tr>
                                                        </div>
                                                        <div>
                                                        <Table hover>
                                                            <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                                <tr style={{textAlign:"center"}}>
                                                                    <th style={thStyle}>Department</th>
                                                                    <th style={thStyleApprover}>Dept Approver</th>
                                                                    <th style={thStylebilling}>Billing</th>
                                                                    <th style={thStylebilling}>Authorizer</th>
                                                                    <th style={thStyle}>Sr Executive</th>
                                                                    <th style={thStyle}>Coding</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.lstMarketingDetails.map((prop, key) => {
                                                                if(CkhValue != prop["departmentId"]) 
                                                                { 
                                                                    CkhValue= prop["departmentId"];
                                                                    return (
                                                                        <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                            <td style={alignContentTd}>{prop["departmentName"]}</td>
                                                                            <td style={alignContentTdApprover}>{this.displayMarketingApprover(prop["departmentId"])}</td>
                                                                            <td style={alignContentTdbilling}>{prop["hasBilling"]}</td>
                                                                            <td style={alignContentTdbilling}>{prop["hasAuthorizer"]}</td>
                                                                            <td style={alignContentTd}></td>
                                                                            <td style={alignContentTd}>{prop["coding"]}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                
                                                                })}
                                                                </tbody>
                                                            </Table>                                                            
                                                         </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                   <td style={{textAlign:"center" ,width:"20%",fontSize:"150%",fontWeight:"bold",color:"navy"}}>
                                                    Recording</td>
                                                    <td style={{ width: '80%' }}>
                                                        <div>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px" }}>Billing Approver</th>
                                                                     {this.state.lstRecordingbilling.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px"}}>Authorizer</th>
                                                                     {this.state.lstRecordingAuthorizer.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                            </tr>
                                                        </div>
                                                        <div>
                                                        <Table hover>
                                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                                    <tr style={{textAlign:"center"}}>
                                                                        <th style={thStyle}>Department</th>
                                                                        <th style={thStyleApprover}>Dept Approver</th>
                                                                        <th style={thStylebilling}>Billing</th>
                                                                        <th style={thStylebilling}>Authorizer</th>
                                                                        <th style={thStyle}>Sr Executive</th>
                                                                        <th style={thStyle}>Coding</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.lstRecordingDetails.map((prop, key) => {
                                                                    if(CkhRecValue != prop["departmentId"]) 
                                                                    { 
                                                                        CkhRecValue= prop["departmentId"];
                                                                        return (
                                                                            <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                                <td style={alignContentTd}>{prop["departmentName"]}</td>
                                                                                <td style={alignContentTdApprover}>{this.displayRecordingApprover(prop["departmentId"])}</td>
                                                                                <td style={alignContentTdbilling}>{prop["hasBilling"]}</td>
                                                                                <td style={alignContentTdbilling}>{prop["hasAuthorizer"]}</td>
                                                                                <td style={alignContentTd}></td>
                                                                                <td style={alignContentTd}>{prop["coding"]}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })} 
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                   <td style={{textAlign:"center" ,width:"20%",fontSize:"150%",fontWeight:"bold",color:"navy"}}>
                                                   Overhead</td>
                                                    <td style={{ width: '80%' }}>
                                                        <div>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px" }}>Billing Approver</th>
                                                                     {this.state.lstOverheadbilling.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px"}}>Authorizer</th>
                                                                     {this.state.lstOverheadAuthorizer.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                            </tr>
                                                        </div>
                                                        <div>
                                                        <Table hover>
                                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                                    <tr style={{textAlign:"center"}}>
                                                                        <th style={thStyle}>Department</th>
                                                                        <th style={thStyleApprover}>Dept Approver</th>
                                                                        <th style={thStylebilling}>Billing</th>
                                                                        <th style={thStylebilling}>Authorizer</th>
                                                                        <th style={thStyle}>Sr Executive</th>
                                                                        <th style={thStyle}>Coding</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.lstOverheadDetails.map((prop, key) => {
                                                                 if(CkhOverheadValue != prop["departmentId"]) 
                                                                 { 
                                                                    CkhOverheadValue= prop["departmentId"];
                                                                    return (
                                                                        <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                            <td style={alignContentTd}>{prop["departmentName"]}</td>
                                                                            <td style={alignContentTdApprover}>{this.displayOverheadApprover(prop["departmentId"])}</td>
                                                                            <td style={alignContentTdbilling}>{prop["hasBilling"]}</td>
                                                                            <td style={alignContentTdbilling}>{prop["hasAuthorizer"]}</td>
                                                                            <td style={alignContentTdSrExe}>{this.displaySrExecutive(prop["departmentId"])}</td>
                                                                            <td style={alignContentTd}>{prop["coding"]}</td>
                                                                        </tr>
                                                                    )
                                                                 }
                                                                })} 
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                   <td style={{textAlign:"center" ,width:"20%",fontSize:"150%",fontWeight:"bold",color:"navy"}}>
                                                   International ReBill</td>
                                                    <td style={{ width: '80%' }}>
                                                        <div>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px" }}>Billing Approver</th>
                                                                     {this.state.lstInternationalbilling.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px"}}>Authorizer</th>
                                                                     {this.state.lstInternationalAuthorizer.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                            </tr>
                                                        </div>
                                                        <div>
                                                            <Table hover>
                                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                                    <tr style={{textAlign:"center"}}>
                                                                        <th style={thStyle}>Department</th>
                                                                        <th style={thStyleApprover}>Dept Approver</th>
                                                                        <th style={thStylebilling}>Billing</th>
                                                                        <th style={thStylebilling}>Authorizer</th>
                                                                        <th style={thStyle}>Sr Executive</th>
                                                                        <th style={thStyle}>Coding</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.lstInternationalDetails.map((prop, key) => {
                                                                        if(CkhIntnatValue != prop["departmentId"]) 
                                                                        { 
                                                                            CkhIntnatValue= prop["departmentId"];
                                                                            return (
                                                                                <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                                    <td style={alignContentTd}>{prop["departmentName"]}</td>
                                                                                    <td style={alignContentTdApprover}>{this.displayInternationalApprover(prop["departmentId"])}</td>
                                                                                    <td style={alignContentTdbilling}>{prop["hasBilling"]}</td>
                                                                                    <td style={alignContentTdbilling}>{prop["hasAuthorizer"]}</td>
                                                                                    <td style={alignContentTd}></td>
                                                                                    <td style={alignContentTd}>{prop["coding"]}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })} 
                                                                </tbody>
                                                            </Table>                                                                                                                    
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                   <td style={{textAlign:"center" ,width:"20%",fontSize:"150%",fontWeight:"bold",color:"navy"}}>
                                                   Ultra Recording</td>
                                                    <td style={{ width: '80%' }}>
                                                        <div>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px" }}>Billing Approver</th>
                                                                     {this.state.lstUltraRecordingbilling.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px"}}>Authorizer</th>
                                                                     {this.state.lstUltraRecordingAuthorizer.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                            </tr>
                                                        </div>
                                                        <div>
                                                            <Table hover>
                                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                                    <tr style={{textAlign:"center"}}>
                                                                        <th style={thStyle}>Department</th>
                                                                        <th style={thStyleApprover}>Dept Approver</th>
                                                                        <th style={thStylebilling}>Billing</th>
                                                                        <th style={thStylebilling}>Authorizer</th>
                                                                        <th style={thStyle}>Sr Executive</th>
                                                                        <th style={thStyle}>Coding</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.lstUltraRecordingDetails.map((prop, key) => {
                                                                        if(CkhUltRecValue != prop["departmentId"]) 
                                                                        { 
                                                                            CkhUltRecValue= prop["departmentId"];
                                                                            return (
                                                                                <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                                    <td style={alignContentTd}>{prop["departmentName"]}</td>
                                                                                    <td style={alignContentTdApprover}>{this.displayUltraRecordingApprover(prop["departmentId"])}</td>
                                                                                    <td style={alignContentTdbilling}>{prop["hasBilling"]}</td>
                                                                                    <td style={alignContentTdbilling}>{prop["hasAuthorizer"]}</td>
                                                                                    <td style={alignContentTd}></td>
                                                                                    <td style={alignContentTd}>{prop["coding"]}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })}  
                                                                </tbody>
                                                            </Table> 
                                                        </div>
                                                    </td>
                                                </tr>  */}
                                                {/* <tr>
                                                   <td style={{textAlign:"center" ,width:"20%",fontSize:"150%",fontWeight:"bold",color:"navy"}}>
                                                   Ultra Marketing</td>
                                                    <td style={{ width: '80%' }}>
                                                        <div>
                                                            <tr style={{textAlign:"center"}}>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px" }}>Billing Approver</th>
                                                                     {this.state.lstUltraMarketingbilling.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                       <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}></td>
                                                                <td style={{ width: '25%',borderTop:"0px"}}>
                                                                     <th style={{borderTop:"0px",padding:"2px"}}>Authorizer</th>
                                                                     {this.state.lstUltraMarketingAuthorizer.map((prop, key) => {
                                                                     return (
                                                                     <tr style={{textAlign:"left",padding:"2px"}} key={key} >                                                                                                                          
                                                                        <td style={{border: "2px solid #eee",padding:"2px",color:(prop["isActive"] ? "":"red")}}>{prop["name"]}</td>
                                                                    </tr>
                                                                    )                                                               
                                                                     })}
                                                                </td>
                                                            </tr>
                                                        </div>
                                                        <div>
                                                        <Table hover>
                                                                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                                    <tr style={{textAlign:"center"}}>
                                                                        <th style={thStyle}>Department</th>
                                                                        <th style={thStyleApprover}>Dept Approver</th>
                                                                        <th style={thStylebilling}>Billing</th>
                                                                        <th style={thStylebilling}>Authorizer</th>
                                                                        <th style={thStyle}>Sr Executive</th>
                                                                        <th style={thStyle}>Coding</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.lstUltraMarketingDetails.map((prop, key) => {
                                                                        if(CkhUltMarketingValue != prop["departmentId"]) 
                                                                        { 
                                                                            CkhUltMarketingValue= prop["departmentId"];
                                                                            return (
                                                                                <tr style={{textAlign:"center"}} key={key} >                                                                                                                          
                                                                                    <td style={alignContentTd}>{prop["departmentName"]}</td>
                                                                                    <td style={alignContentTdApprover}>{this.displayUltraMarketingApprover(prop["departmentId"])}</td>
                                                                                    <td style={alignContentTdbilling}>{prop["hasBilling"]}</td>
                                                                                    <td style={alignContentTdbilling}>{prop["hasAuthorizer"]}</td>
                                                                                    <td style={alignContentTd}></td>
                                                                                    <td style={alignContentTd}>{prop["coding"]}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })} 
                                                                </tbody>
                                                            </Table>                                                                                                                  
                                                        </div>
                                                    </td>
                                                </tr>                                                  */}
                                            </Table>
                                            </div>
                                        </Col>
                                    </Row>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
   
}