import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class ApprovalDiagramController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    GetUserLabelGroups (data: any){
        var url = this.variables.Url+ this.variables.Controller.approvalDiagram + this.variables.approvalDiagramService.getUserLabelGroups;
          return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetLabels : Array<{"id": string, "name": string, "opCode": string, "isActive": string, "hasDHA": string}> = [];
                if(response.success){    
                    response.recordset.forEach(Labels);
                    function Labels(item: { Label_ID: any; Description: any; Op_Code:any; Is_Active:any; Has_DHA:any}){
                        jsonGetLabels.push({"id": item.Label_ID, "name": item.Description, "opCode": item.Op_Code
                                             , "isActive": item.Is_Active, "hasDHA": item.Has_DHA})
                    }
                    return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    
                    return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    GetApprovalChain (data: any){
        var url = this.variables.Url+ this.variables.Controller.approvalDiagram + this.variables.approvalDiagramService.getApprovalChain;
          return this.serviceProxy.get(url,data).then(response=>{
            var jsonApprovalChain : Array<{"departmentId": string, "departmentName": string, "hasAuthorizer": string, "hasBilling": string,
                                 "coding": string,"name": string,"userId": string,"isActive": string}> = [];
                if(response.success){    
                    response.recordset.forEach(Labels);
                    function Labels(item: { Department_ID: any; Department: any; HasAuthorizer:any; HasBilling:any; Coding:any;
                                            name:any; user_ID:any; is_Active:any }){
                    jsonApprovalChain.push({"departmentId": item.Department_ID, "departmentName": item.Department, "hasAuthorizer": item.HasAuthorizer,
                                        "hasBilling": item.HasBilling, "coding": item.Coding, "name": item.name, "userId": item.user_ID,"isActive": item.is_Active})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovalChain).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    
                    return JSON.parse(JSON.stringify(jsonApprovalChain).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    getAuthorizerAndBillingApprovers (data: any){
        var url = this.variables.Url+ this.variables.Controller.approvalDiagram + this.variables.approvalDiagramService.getLabelAndBillingApprovers;
          return this.serviceProxy.get(url,data).then(response=>{
            var jsonBillingApprovers : Array<{"name": string,"userId": string,"isActive":string}> = [];
                if(response.success){    
                    response.recordset.forEach(Labels);
                    function Labels(item: { Name:any; User_ID:any;is_Active:any}){
                        jsonBillingApprovers.push({"name": item.Name, "userId": item.User_ID,"isActive": item.is_Active})
                    }
                    return JSON.parse(JSON.stringify(jsonBillingApprovers).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    
                    return JSON.parse(JSON.stringify(jsonBillingApprovers).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getSrExecutives (data: any){
        var url = this.variables.Url+ this.variables.Controller.approvalDiagram + this.variables.approvalDiagramService.getSrExecutives;
          return this.serviceProxy.get(url,data).then(response=>{
            var jsonSrExecutives : Array<{"name": string,"userId": string,"departmentId": string,"isActive":string}> = [];
                if(response.success){    
                    response.recordset.forEach(Labels);
                    function Labels(item: { Name:any; User_ID:any;DepartmentID:any;is_Active:any}){
                        jsonSrExecutives.push({"name": item.Name, "userId": item.User_ID, "departmentId": item.DepartmentID,"isActive": item.is_Active})
                    }
                    //return JSON.parse(JSON.stringify(jsonSrExecutives).replace(/"\s+|\s+"/g, '"'));
                    return jsonSrExecutives;
                }
                else {
                    
                    //return JSON.parse(JSON.stringify(jsonSrExecutives).replace(/"\s+|\s+"/g, '"'));
                    return jsonSrExecutives;
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }


}