import React, { Component, CSSProperties } from "react";
import { ICarServiveIncomingCAParameter, Istate } from "../../../Model/ICarServiceIncomingCA";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CarServiceIncomingCAController } from "../../../Controllers/CarServiceIncomingCAController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import { alert, confirm } from 'react-bootstrap-confirmation';
import * as Utils from '../../../js/Utils.js';
import addSubtractDate from 'add-subtract-date'
import { TravellerController } from "../../../Controllers/TravellerController";
import parse from 'html-react-parser';

export class CarServiceIncomingCAList extends Component<ICarServiveIncomingCAParameter,Istate> {
    constructor(props:ICarServiveIncomingCAParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {},
            selectfields:{},
            errors:{},
            errMsg:"",
            loading:false,    
            authReqId:'',  
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),
            inComingCAGroups:[],            
            loadComplete:false,
            currentSort: 'down',
            currentColumn: 'authReqId',
            userTypeId:sessionStorage.getItem("user_type_id"),      
        }       
    }

    componentWillMount(){
        let modal = this.state.modal;
        this.setState({userId:sessionStorage.getItem("uid")})
        modal['modalSpinnerShow']=true;     
        this.getCAList();
    }

    getCAList =()=>{
        
        let modal = this.state.modal;         
        let carServiceIncomingCA= new CarServiceIncomingCAController();
        var data={
            userType: sessionStorage.getItem("user_type_id")
        }
        carServiceIncomingCA.getCarserviceIncomingCADetails(data).then(jsoncarCAlist => {
            this.setState({ inComingCAGroups: JSON.parse(JSON.stringify(jsoncarCAlist))});             
            //console.log(this.state.inComingCAGroups)  
            modal['modalSpinnerShow']=false;        
            this.setState({modal});  
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })        
        
    }

    covertCreatedDate = (val:any) => {
      var dateString = val;
      var date = new Date(dateString);
     
      var curr_date = date.getDate();
      var curr_month = date.getMonth() + 1; //Months are zero based
      var curr_year = date.getFullYear();
      var reqDate = curr_month + "/" + curr_date + "/" + curr_year;

      return reqDate
    }

    onSortChange = (col) => {
        
        const { currentSort } = this.state;
        const { currentColumn } = this.state;
        let nextSort;

        if (currentSort === 'down') nextSort = 'up';
        else if (currentSort === 'up') nextSort = 'default';
        else if (currentSort === 'default') nextSort = 'down';
        
        let arrayCopy1 = [...this.state.inComingCAGroups];
        arrayCopy1.sort(this.compareBySort(col, nextSort));

        this.setState({
            inComingCAGroups: arrayCopy1
        });

        this.setState({
            currentSort: nextSort, currentColumn: col
        });
    }

    compareBySort(key, sort) {
        return function (a, b) {
            if (sort === 'up') {
                if (a[key] < b[key]) return -1;
            }
            else if (sort === 'down') {
                if (a[key] > b[key]) return -1;
            }
            return 0;
        }
    }

    setArrow = (column) => {
        let { currentSort } = this.state;
        let { currentColumn } = this.state;
        let className = "";

        if (column === currentColumn) {
            if (currentSort === "up") {
                className = 'fa fa-fw fa-sort-up';
            }
            else if (currentSort === "down") {
                className = 'fa fa-fw fa-sort-down';
            }
            else {
                className = 'fa fa-fw fa-sort';
            }
        }
        else {
            className = 'fa fa-fw fa-sort';
        }

        return className;
    };
    onSelect(authReqId){
        let travellerController = new TravellerController();
        travellerController.getTA(authReqId).then(response => {
            this.props.history.push({pathname:'/admin/carserviceconfirmation',state:{from:"incoming",isView:false}});
            this.setState({ loading: false });
        }).catch(error => {
            console.error("There was an error!", error)
        })
    }
    render() {
        var prevAuthReqId = 0;
        const{ loading } = this.state;
         
        const tdStyle = {
            width:'15%',            
            border: "2px solid #eee",            
            padding:"2px"           
        };  
         const divStyle1 = {
          width: '100%',                   
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        };
              
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Car Service Incoming CA's</h4></div>
                            <Row>
                                <Col sm={6}></Col>
                                <Col sm={6}>
                                    <div style={{ width: '100%' }} className="alert alert-primary alert-cus-info">
                                        <div><h6 className="text-dark">Legend for Incoming CA's</h6>
                                            <div className="text-dark font-size-xs"><span className="badge badge-blue">(1 - day)</span> indicates travel is occuring within 1 day.</div>                                    
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Card.Body>
                                <Row>
                                    <Col sm={12} style={{textAlign:"center", marginBottom:"15px"}}>
                                        <Button disabled={this.state.loading} name='btnRefresh' style={{ marginRight: "5px"}} onClick={() => window.location.reload(false)} title="Click to Refresh the Screen">
                                            {this.state.loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", color: "#3472F7" }}
                                            />
                                            )}
                                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                            {!this.state.loading && <span>Refresh </span>}
                                        </Button>
                                        <Button disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                            {this.state.loading && (
                                            <i
                                                className="fa fa-refresh fa-spin"
                                                style={{ marginRight: "5px", color: "#3472F7" }}
                                            />
                                            )}
                                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                            {!this.state.loading && <span>Print </span>}
                                        </Button>
                                    </Col>
                                </Row>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                   <Row>                                        
                                       <Col sm={12}>
                                        <div>
                                            <Table hover>
                                                        <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                                                            <tr style={{textAlign:"center"}}>
                                                                <th onClick={() => this.onSortChange('traveler')}>Traveler<i className={this.setArrow('traveler')}></i></th>
                                                                <th onClick={() => this.onSortChange('travelDate')}>Traveler Date<i className={this.setArrow('travelDate')}></i></th>
                                                                <th onClick={() => this.onSortChange('authReqId')}>Auth Req<i className={this.setArrow('authReqId')}></i></th>
                                                                <th onClick={() => this.onSortChange('createdBy')}>Submitter<i className={this.setArrow('createdBy')}></i></th>
                                                                <th onClick={() => this.onSortChange('travelerAgent')}>Agent Name<i className={this.setArrow('travelerAgent')}></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.inComingCAGroups.map((prop, key) => {
                                                                if(prevAuthReqId != prop["authReqId"]){
                                                                    prevAuthReqId = prop["authReqId"] 
                                                                    var concateTravelName='';
                                                                    var i=0;
                                                                    concateTravelName = this.state.inComingCAGroups.filter(x => x.authReqId == prop["authReqId"]).map(m=> m.traveler)
                                                                    for(let travelName of concateTravelName){
                                                                        //console.log("travelDate:" + i, travelName);
                                                                        if(i===0){
                                                                            concateTravelName = travelName;
                                                                        }else
                                                                        {
                                                                            concateTravelName = concateTravelName + "<br>" + travelName;
                                                                        }
                                                                        i=i+1;
                                                                    }
                                                                    var bBlink = false;
                                                                    var blinkDate = Utils.FormatDate(addSubtractDate.add(new Date(Date.now()),1,"day"))
                                                                    if(prop["travelDate"] != null && new Date(prop["travelDate"]) <= new Date(blinkDate)){
                                                                        bBlink = true;
                                                                    }                                                                    
                                                                    else{
                                                                        bBlink = false;
                                                                    }                                                                   
                                                                    return (
                                                                        <tr style={{textAlign:"center",cursor:"pointer"}} key={key} onClick={() => this.onSelect(prop["authReqId"])}>                                                                  
                                                                        <td>{parse(concateTravelName)}</td>                                                                                                                         
                                                                        <td>{this.covertCreatedDate(prop["travelDate"])}</td>
                                                                        {bBlink ? <td>{prop["carServiceOnly"] ? 'CA' + prop["authReqId"]:prop["authReqId"]}<span className="badge badge-blue" style={{fontWeight: "bold", fontSize: '85%'}}> (1 - day)</span></td>
                                                                            :
                                                                                <td>{prop["authReqId"]}</td>
                                                                            }
                                                                        <td>{prop["createdBy"]}</td>
                                                                        <td>{prop["travelerAgent"]}</td>
                                                                        </tr>                                                                    
                                                                    )
                                                                }
                                                            })}                                                            
                                                        </tbody>
                                                </Table>                                                        
                                            </div>
                                        

                                       </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col sm={12} style={{ paddingBottom: "15px"}}>
                                            
                                        </Col>
                                    </Row>
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}