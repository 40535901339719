import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class DirectBillReportController {
    response: any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor() {
        this.response = { msg: '', success: true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getdirectBillSearchReport(data: any) {
        // 
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.getdirectBillSearchReport;
        return this.serviceProxy.get(url, data).then(response => {
            var jsoncallfoliolog: Array<{ "authID": string, "traveller": string, "company": string, "phone": string, "fax": string, "servicedate": string, "called": string, "foliocheck": boolean, "notfoliocheck": boolean, "calledcheck": boolean, "usrid": string }> = [];
            if (response) {
                response.recordset.forEach(dbcallfoliolog)
                function dbcallfoliolog(item: { AuthReq_ID: any; Traveller: any; Company: any; Phone: any; Fax: any; Service_end_date: any; Called: any; usrid: any }) {
                    jsoncallfoliolog.push({ "authID": item.AuthReq_ID, "traveller": item.Traveller, "company": item.Company, "phone": item.Phone, "fax": item.Fax, "servicedate": item.Service_end_date, "called": item.Called, "foliocheck": false, "notfoliocheck": false, "calledcheck": false, "usrid": item.usrid })

                }
                return JSON.parse(JSON.stringify(jsoncallfoliolog).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsoncallfoliolog).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }


    updateCallfolioLog(data) {
        //
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.directBillGotFolioUpdate;
        //console.log(data);
        return this.serviceProxy.post(url, data).then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getdirectBillReportNotFolioSearch(data: any) {
        //
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.getdirectBillReportNotFolio;
        return this.serviceProxy.get(url, data).then(response => {
            var jsoncallfoliolog: Array<{ "authID": string, "traveller": string, "company": string, "phone": string, "fax": string, "servicedate": string, "foliocheck": boolean, "calledcheck": boolean, "usrid": string, "called": string }> = [];
            if (response) {
                response.recordset.forEach(dbcallfoliolog)
                function dbcallfoliolog(item: { AuthReq_ID: any; Traveller: any; Company: any; Phone: any; Fax: any; Service_end_date: any; usrid: any; Called: any }) {
                    jsoncallfoliolog.push({ "authID": item.AuthReq_ID, "traveller": item.Traveller, "company": item.Company, "phone": item.Phone, "fax": item.Fax, "servicedate": item.Service_end_date, "foliocheck": false, "calledcheck": false, "usrid": item.usrid, "called": item.Called })

                }
                return JSON.parse(JSON.stringify(jsoncallfoliolog).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsoncallfoliolog).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    updateCallNotfolioLog(data) {
        // 
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.directBillReportNotFolioUpdate;
        //console.log(data);
        return this.serviceProxy.post(url, data).then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getDirectBillReportGuestArrival(data: any) {
        //
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.DirectBillReportGuestArrival;
        return this.serviceProxy.get(url, data).then(response => {
            var jsoncallfoliolog: Array<{ "authID": string, "hotel": string, "traveller": string, "indate": string, "yesno": string }> = [];
            if (response) {
                response.recordset.forEach(dbcallfoliolog)
                function dbcallfoliolog(item: { AuthReq_ID: any; Hotel: any; Traveller: any; In_date: any; Yes_No: any }) {
                    jsoncallfoliolog.push({ "authID": item.AuthReq_ID, "hotel": item.Hotel, "traveller": item.Traveller, "indate": item.In_date, "yesno": item.Yes_No })

                }
                return JSON.parse(JSON.stringify(jsoncallfoliolog).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsoncallfoliolog).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getDirectBillReportVendorPayment(data) {
        //
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.DirectBillReportVendorPayment;
        return this.serviceProxy.get(url, data).then(response => {
            var jsonvendorpayment: Array<{ "traveller": string, "company": string, "checkindate": string, "authReq": string, "logged": string, "submitted": string, "amount": string, "labelid": string, "expensetypeid": string, "labeldesc": string, "expensetypedesc": string }> = [];
            if (response) {
                response.recordset.forEach(dbvendorPayment)
                function dbvendorPayment(item: { traveller: any; company: any; End_date: any; AuthReq_ID: any; submitted_to_log_date: any; submitted_payment_date: any; Amount: any; Label_ID: any; Expense_Type_ID: any; label_desc: any; expense_type_desc: any }) {
                    jsonvendorpayment.push({ "traveller": item.traveller, "company": item.company, "checkindate": item.End_date, "authReq": item.AuthReq_ID, "logged": item.submitted_to_log_date, "submitted": item.submitted_payment_date, "amount": item.Amount, "labelid": item.Label_ID, "expensetypeid": item.Expense_Type_ID, "labeldesc": item.label_desc, "expensetypedesc": item.expense_type_desc })

                }
                return JSON.parse(JSON.stringify(jsonvendorpayment).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsonvendorpayment).replace(/"\s+|\s+"/g, '"'));
            }


        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getAllLabels() {
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.getAllLabels;
        var data = {};
        return this.serviceProxy.get(url, data).then(response => {
            var jsonGetLabels: Array<{ "id": string, "name": string, "isActive": boolean }> = [];
            if (response.success) {
                response.recordset.forEach(carServiceCompany);
                function carServiceCompany(item: { Label_ID: any; Description: any; Is_Active: any; }) {
                    jsonGetLabels.push({ "id": item.Label_ID, "name": item.Description, "isActive": item.Is_Active })
                }
                return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsonGetLabels).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getExpenseType() {
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.getExpenseType;
        var data = {};
        return this.serviceProxy.get(url, data).then(response => {
            var jsonGetExpenseType: Array<{ "id": string, "name": string }> = [];
            if (response.success) {
                response.recordset.forEach(expenseType);
                function expenseType(item: { expense_type_id: any; description: any }) {
                    jsonGetExpenseType.push({ "id": item.expense_type_id, "name": item.description })
                }
                return JSON.parse(JSON.stringify(jsonGetExpenseType).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsonGetExpenseType).replace(/"\s+|\s+"/g, '"'));
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getDirectBillReportSubmittedPayment(data) {
        //
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.DirectBillReportsubmittedayment;
        return this.serviceProxy.get(url, data).then(response => {
            var jsonsubmittedpayment: Array<{ "traveller": string, "company": string, "checkindate": string, "authReq": string, "amount": string, "labelid": string, "expensetypeid": string, "labeldesc": string, "expensetypedesc": string }> = [];
            if (response) {
                response.recordset.forEach(dbsubmittedPayment)
                function dbsubmittedPayment(item: { traveller: any; company: any; End_date: any; AuthReq_ID: any; Amount: any; Label_ID: any; Expense_Type_ID: any; label_desc: any; expense_type_desc: any }) {
                    jsonsubmittedpayment.push({ "traveller": item.traveller, "company": item.company, "checkindate": item.End_date, "authReq": item.AuthReq_ID, "amount": item.Amount, "labelid": item.Label_ID, "expensetypeid": item.Expense_Type_ID, "labeldesc": item.label_desc, "expensetypedesc": item.expense_type_desc })

                }
                return JSON.parse(JSON.stringify(jsonsubmittedpayment).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsonsubmittedpayment).replace(/"\s+|\s+"/g, '"'));
            }


        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getDirectBillReportSubmitPayment(data) {
        // 
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.DirectBillReportsubmitPayment;
        return this.serviceProxy.get(url, data).then(response => {
            var jsonsubmitpayment: Array<{ "vendornumber": string, "traveller": string, "company": string, "checkindate": string, "checkoutdate": string, "invoicenumber": string, "authReq": string, "amount": string, "comments": string, "labelid": string, "expensetypeid": string, "labeldesc": string, "expensetypedesc": string, "directbillid": string, "checkdirectbillid": string }> = [];
            if (response) {
                response.recordset.forEach(dbsubmitPayment)
                function dbsubmitPayment(item: { Vendor_Number: any; traveller: any; company: any; Begin_Date: any; End_date: any; Invoice_Number: any; AuthReq_ID: any; Amount: any; Comments: any; Label_ID: any; Expense_Type_ID: any; label_desc: any; expense_type_desc: any; Direct_Bill_ID: any; }) {
                    jsonsubmitpayment.push({ "vendornumber": item.Vendor_Number, "traveller": item.traveller, "company": item.company, "checkindate": item.Begin_Date, "checkoutdate": item.End_date, "invoicenumber": item.Invoice_Number, "authReq": item.AuthReq_ID, "amount": item.Amount, "comments": item.Comments, "labelid": item.Label_ID, "expensetypeid": item.Expense_Type_ID, "labeldesc": item.label_desc, "expensetypedesc": item.expense_type_desc, "directbillid": item.Direct_Bill_ID, checkdirectbillid: "" })

                }
                return JSON.parse(JSON.stringify(jsonsubmitpayment).replace(/"\s+|\s+"/g, '"'));
            }
            else {
                return JSON.parse(JSON.stringify(jsonsubmitpayment).replace(/"\s+|\s+"/g, '"'));
            }


        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    updatesubmitpayment(data) {
        // 
        var url = this.variables.Url + this.variables.Controller.directbillReport + this.variables.directBillAllReport.DirectBillReportsubmitPaymentUpdate;
        //console.log(data);
        return this.serviceProxy.post(url, data).then(response => {
            return response;
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }


}