import React, { Component, CSSProperties } from "react";
import { IUserDetailsParameter, Istate } from "../../../Model/IUserDetails";
import {FormLabel,Container,Row,Col,Form,Alert,Button,Card,NavLink,FormControl,Table,InputGroup,FormGroup} from "react-bootstrap";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { FormSelect } from "../../CustomComponents/FormSelect";
import { FormInputs } from "../../CustomComponents/FormInput";
import { UserDetailsController } from "../../../Controllers/UserDetailsController";
import { CommonController } from "../../../Controllers/CommonController";
import '../../../assets/css/Style.css'
import { format } from 'date-fns'
import ReactPaginate from 'react-paginate';
import { MultilineDropdown } from "react-multiline-dropdown";
import { alert, confirm } from 'react-bootstrap-confirmation';


export class UserDetails extends Component<IUserDetailsParameter,Istate> {
    constructor(props:IUserDetailsParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={  
            fields: {loginId:'',fname:'',lname:'',address1:'',address2:'',label:'',dept:'',city:'',state:'',zip:'',email:'',phone:'',altPhone:'',fax:''},
            selectfields:{},
            errors:{},
            errMsg:"",
            loading:false,            
            modal: {modalSpinnerShow: false},           
            userId:sessionStorage.getItem("uid"),             
        }        
    }

    

    componentWillMount(){
        let modal = this.state.modal;     
        let fields = this.state.fields;    
        this.setState({userId:sessionStorage.getItem("uid")})
        let userDetailsController = new UserDetailsController();
        modal['modalSpinnerShow']=true;      
        
        userDetailsController.getUser(this.state.userId).then(response => {          
            fields["loginId"] = response.AD_User_ID || "";
            const splitsname = response.Name.split(' ');
            fields["fname"] = splitsname[0] || "";  
            fields["lname"] = splitsname[1] || "";           
            fields["label"] = response.label_desc || "";
            fields["dept"] = response.dept_desc || "";
            fields["address1"] = response.AddressLineOne || "";
            fields["address2"] = response.AddressLineTwo || "";
            fields["city"] = response.City || "";
            fields["state"] = response.State || "";
            fields["zip"] = response.Zip || "";
            fields["email"] = response.Email || "";
            fields["phone"] = response.Business_Phone || "";
            fields["altPhone"] = response.Alt_Phone || "";
            fields["fax"] = response.Fax_Phone || "";
            modal['modalSpinnerShow']=false; 
            this.setState({fields,modal})
            }).catch(error => {
                console.error("There was an error!", error);            
            })
             /* UnLocking TA for the current users */
            let comController = new CommonController();
        var data = {
            authreqid: sessionStorage.getItem("ta"),
            userid: sessionStorage.getItem("uid"),
            shadowid: sessionStorage.getItem("shadow"),
            check: ""
        }
        comController.updateUnLockAuthreq(data).then(response => {
        }).catch(error => {
            console.error("There was an error!", error)
        })  
    }    
   

    render() {
        const{ loading } = this.state; 
                     
         const tdStyle = {
              textAlign:"right", 
              fontWeight:"bolder"          
        };
             
        const thStyle ={
            width:'20%',            
            border: "2px solid #eee",
            padding:"2px"                        
        };        
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <div className="header"><h4 className="title">Your User Information</h4></div>
                            <Card.Body>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Login ID:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.loginId}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>  
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Role  ID:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.userId}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>First Name:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.fname}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Last Name:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.lname}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>HFM Entity:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.label}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Department:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.dept}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>   
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Business Address:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.address1}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Business Address (cont.):</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.address2}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>City:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.city}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>State:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.state}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row> 
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Zip:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.zip}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Business Email:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.email}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Business Phone:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.phone}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Alternate Phone:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.altPhone}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>   
                                <Row>
                                       <Col sm={2}></Col>
                                       <Col sm={4} style={{textAlign:"right",fontWeight:"bolder"}}><Form.Label>Fax:</Form.Label></Col>
                                       <Col sm={4}><Form.Label>{this.state.fields.fax}</Form.Label></Col>                                         
                                       <Col sm={2}></Col>                                               
                                </Row>
                                <Row>
                                   <Col sm={2}>&nbsp;</Col>                                                                               
                                </Row> 
                                <Row>
                                   <Col sm={2}>&nbsp;</Col>                                                                               
                                </Row>    
                                <Row>
                                   <Col sm={2}>&nbsp;</Col>                                                                               
                                </Row>                                                                                      
                           </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
                <Row>
                     <Col sm={2} style={{height:"20%"}}></Col>                                                                               
              </Row> 
            </Container>
        </div>)
    }
}