import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import * as Utils from '../js/Utils.js';

export class ApproverController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    getApproverInbox (data: any){        
        var url = this.variables.Url+ this.variables.Controller.approver + this.variables.approverService.getApproverInbox;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonApproverIncoming : Array<{"authReqId": string, "createdDate": string, "createdBy": string, "artistProjectName": string, "artistName": string, "expenseTypeDesc": string, "expenseTypeId": string; "statusDesc": string, "travelDate": string, "rentalDate":string, "carDate": string, "inDate": string, "carServiceOnly": boolean}> = [];
                if(response.success){
                    response.recordset.forEach(ApproverIncoming);
                    function ApproverIncoming(item: { authreq_id: any; created_date: any; created_by: any; artist_project_name: any ; artist_name: any; expense_type_desc: any; expense_type_id: any; status_desc: any; travel_date: any; rental_date: any; car_date: any; in_date: any; car_service_only: boolean}){
                        jsonApproverIncoming.push({"authReqId": item.authreq_id, "createdDate":  item.created_date,  "createdBy":item.created_by, "artistProjectName":item.artist_project_name, "artistName": item.artist_name, "expenseTypeDesc": item.expense_type_desc, "expenseTypeId": item.expense_type_id, "statusDesc": item.status_desc, "travelDate": item.travel_date, "rentalDate": item.rental_date, "carDate": item.car_date, "inDate": item.in_date, "carServiceOnly": item.car_service_only})
                    }
                    return JSON.parse(JSON.stringify(jsonApproverIncoming).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApproverIncoming).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getApprovalHistory (data: any){        
        var url = this.variables.Url+ this.variables.Controller.approver + this.variables.approverService.getApprovalHistory;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonApprovalHistory : Array<{"name": string, "statusTypeDesc": string, "updatedDate": string, "approverTierDesc": string, "businessPhone": string, "commentsToRequestor": string, "commentsToApprover": string}> = [];
                if(response.success){
                    response.recordset.forEach(ApprovalHistory);
                    function ApprovalHistory(item: { name: any; status_type_desc: any; updated_date: any; approver_tier_desc: any ; business_phone: any; comments_to_requestor: any; comments_to_approver: any;}){
                        jsonApprovalHistory.push({"name": item.name, "statusTypeDesc": item.status_type_desc,  "updatedDate":item.updated_date, "approverTierDesc":item.approver_tier_desc, "businessPhone": item.business_phone, "commentsToRequestor": item.comments_to_requestor, "commentsToApprover": item.comments_to_approver})
                    }
                    return JSON.parse(JSON.stringify(jsonApprovalHistory).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApprovalHistory).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getLabelApprovers (data: any){        
        var url = this.variables.Url+ this.variables.Controller.approver + this.variables.approverService.getLabelApprovers;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonLabelApprovers : Array<{"userId": string}> = [];
                if(response.success){
                    response.recordset.forEach(LabelApprovers);
                    function LabelApprovers(item: { User_ID: any}){
                        jsonLabelApprovers.push({"userId": item.User_ID})
                    }
                    return JSON.parse(JSON.stringify(jsonLabelApprovers).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonLabelApprovers).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    getApproverHistory (data: any){        
        var url = this.variables.Url+ this.variables.Controller.approver + this.variables.approverService.getApproverHistory;
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonApproverHistory : Array<{"authReqId": string, "createdDate": string, "createdBy": string, "artistProjectName": string, "artistName": string, "artistProjectNumber": string, "statusDesc": string, "carServiceOnly": boolean, "traveler": string}> = [];
                if(response.success){
                    response.recordset.forEach(ApproverHistory);
                    function ApproverHistory(item: { authreq_id: any; created_date: any; created_by: any; artist_project_name: any ; artist_name: any; artist_project_number: any; status_desc: any; car_service_only: boolean; traveler: any}){
                        jsonApproverHistory.push({"authReqId": item.authreq_id, "createdDate": item.created_date,  "createdBy":item.created_by, "artistProjectName":item.artist_project_name, "artistName": item.artist_name, "artistProjectNumber": item.artist_project_number, "statusDesc": item.status_desc, "carServiceOnly": item.car_service_only, "traveler": item.traveler})
                    }
                    return JSON.parse(JSON.stringify(jsonApproverHistory).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonApproverHistory).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
}