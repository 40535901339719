import React, { Component } from "react";
import { ISubmitterParameter, Istate } from "../../../Model/ISubmitter";
import {
  FormLabel,
  FormGroup,
  Container,
  Row,
  Col,
  Form,
  Navbar,
  Nav,
  Alert,
  Button,
  Card,
  CardGroup,
  FormControl,
  Modal,
  Table,
} from "react-bootstrap";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
import * as Utils from "../../../js/Utils.js";
import { TravellerController } from "../../../Controllers/TravellerController";
import { SubmitterController } from "../../../Controllers/SubmitterController";
import addSubtractDate from "add-subtract-date";
import { SearchBox } from "../../CustomComponents/SearchBox";
import { constants } from "buffer";
import * as Constants from "../../../js/Constants";
import parse from "html-react-parser";

export class TAHistory extends Component<ISubmitterParameter, Istate> {
  constructor(props: ISubmitterParameter) {
    super(props);
    const userLogin = sessionStorage.getItem("userLogin");
    let loggedIn = true;
    if (userLogin == null) {
      loggedIn = false;
    }
    this.state = {
      fields: {
        searchDays: "14",
        startDate: "",
        endDate: new Date(),
        searchString: "*",
        searchScope: "ar.authreq_id",
      },
      errors: {},
      errMsg: "",
      loggedIn,
      unsubmittedTA: [],
      taHistory: [],
      currentSort: "down",
      currentColumn: "authReqId",
      loading: false,
      modal: { modalSpinnerShow: false },
    };
  }

  componentWillMount() {
    let fields = this.state.fields;
    var date = new Date();
    date.setDate(date.getDate() - this.state.fields.searchDays);
    fields["startDate"] = date;
    this.setState({ fields });
    this.pageLoad();
  }

  pageLoad() {
    let modal = this.state.modal;

    modal["modalSpinnerShow"] = true;
    this.setState({ modal });
    sessionStorage.setItem("frompage", Constants.tahistory);
    let submitterController = new SubmitterController();
    var searchStringLike = "";
    var searchString = "";
    //console.log(this.state.fields.searchString, "taHistoryData1");
    if (
      !this.state.fields.searchString.includes("*") &&
      this.state.fields.searchString === ""
    ) {
      let addStar = "*";
      let fields = this.state.fields;
      fields["searchString"] = addStar.concat(fields["searchString"]);
      this.setState({ fields });
    }

    if (this.state.fields.searchString.indexOf("*") != -1) {
      searchStringLike = this.state.fields.searchString.replace("*", "%");
      searchString = "";
    } else {
      searchString = this.state.fields.searchString;
    }
    let endDate: string = Utils.FormatDate(
      addSubtractDate.add(new Date(this.state.fields.endDate), 1, "day")
    );
    let startDate: string = Utils.FormatDate(this.state.fields.startDate);
    //console.log("startDate:", startDate);
    //console.log("endDate:", endDate);
    var taHistoryData = {
      labelId: sessionStorage.getItem("label_id"),
      uid: sessionStorage.getItem("uid"),
      sysop: sessionStorage.getItem("sysop"),
      startDate: startDate,
      endDate: endDate,
      searchScope: this.state.fields.searchScope,
      searchString: searchString,
      searchStringLike: searchStringLike,
    };
    //console.log("taHistoryData:", taHistoryData);
    submitterController
      .getTAHistory(taHistoryData)
      .then((jsonTAHistory) => {
        this.setState({
          taHistory: JSON.parse(JSON.stringify(jsonTAHistory)),
        });
        //console.log("taHistory:", this.state.taHistory);
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      })
      .catch((error) => {
        console.error("There was an error...," + error);
      });
  }

  setArrow = (column) => {
    let { currentSort } = this.state;
    let { currentColumn } = this.state;
    let className = "";

    if (column === currentColumn) {
      if (currentSort === "up") {
        className = "fa fa-fw fa-sort-up";
      } else if (currentSort === "down") {
        className = "fa fa-fw fa-sort-down";
      } else {
        className = "fa fa-fw fa-sort";
      }
    } else {
      className = "fa fa-fw fa-sort";
    }

    return className;
  };

  onSortChange = (col) => {
    debugger;
    const { currentSort } = this.state;
    const { currentColumn } = this.state;
    let nextSort;

    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    let arrayCopy: [];
    //arrayCopy = [...this.state.taHistory];
    arrayCopy = this.state.taHistory;
    arrayCopy.sort(this.compareBySort(col, nextSort));

    this.setState({
      taHistory: arrayCopy,
    });

    this.setState({
      currentSort: nextSort,
      currentColumn: col,
    });

    //console.log("onSortChange - sort :", currentSort);
    //console.log("onSortChange - column :", currentColumn);
  };

  compareBySort(key, sort) {
    return function (a, b) {
      if (sort === "up") {
        if (a[key] < b[key]) return -1;
      } else if (sort === "down") {
        if (a[key] > b[key]) return -1;
      }
      return 0;
    };
  }

  handleCallback = (
    searchDays,
    startDate,
    endDate,
    searchString,
    searchScope
  ) => {
    let fields = this.state.fields;
    fields["searchDays"] = searchDays;
    fields["startDate"] = startDate;
    fields["endDate"] = endDate;
    fields["searchString"] = searchString;
    fields["searchScope"] = searchScope;
    this.setState({ fields });
    this.pageLoad();
  };

  onSelect(authReqId, status) {
    let travellerController = new TravellerController();
    travellerController
      .getTA(authReqId)
      .then((response) => {
        sessionStorage.setItem("page", "approver");
        if (status === "New" || status === "Car Service Complete") {
          this.props.history.push({
            pathname: "/admin/decision",
            state: { from: "tahistory" },
          });
        } else {
          this.props.history.push({
            pathname: "/admin/codingsummary",
            state: { from: "tahistory" },
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  render() {
    var prevAuthReqId = 0;
    return (
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: "100%" }}>
                <div className="header">
                  <h4 className="title">TA History</h4>
                  <p className="category"></p>
                </div>
                <Card.Body>
                  <Alert
                    variant="danger"
                    style={{ display: this.state.errMsg ? "inherit" : "none" }}
                  >
                    {this.state.errMsg}
                  </Alert>
                  <SearchBox
                    parentCallback={this.handleCallback}
                    from="submitter"
                  />
                  <Row>
                    <Col sm={12}>
                      <div style={{ width: "100%", height: "auto" }}>
                        <Table hover striped bordered>
                          <thead>
                            <tr>
                              <th
                                onClick={() => this.onSortChange("authReqId")}
                              >
                                Auth Req
                                <i className={this.setArrow("authReqId")}></i>
                              </th>
                              <th onClick={() => this.onSortChange("traveler")}>
                                Traveler
                                <i className={this.setArrow("traveler")}></i>
                              </th>
                              <th
                                onClick={() => this.onSortChange("createdDate")}
                              >
                                Submitted
                                <i className={this.setArrow("createdDate")}></i>
                              </th>
                              <th
                                onClick={() =>
                                  this.onSortChange("artistProjectNumber")
                                }
                              >
                                Number
                                <i
                                  className={this.setArrow(
                                    "artistProjectNumber"
                                  )}
                                ></i>
                              </th>
                              <th
                                onClick={() =>
                                  this.onSortChange("artistProjectName")
                                }
                              >
                                Project
                                <i
                                  className={this.setArrow("artistProjectName")}
                                ></i>
                              </th>
                              <th
                                onClick={() => this.onSortChange("artistName")}
                              >
                                Artist
                                <i className={this.setArrow("artistName")}></i>
                              </th>
                              <th onClick={() => this.onSortChange("inbox")}>
                                Inbox<i className={this.setArrow("inbox")}></i>
                              </th>
                              <th
                                onClick={() => this.onSortChange("statusDesc")}
                              >
                                Status
                                <i className={this.setArrow("statusDesc")}></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.taHistory.map((prop, key) => {
                              if (prevAuthReqId != prop["authReqId"]) {
                                prevAuthReqId = prop["authReqId"];
                                var concateTraveller = "";
                                var filterTraveller = this.state.taHistory
                                  .filter(
                                    (x) => x.authReqId == prop["authReqId"]
                                  )
                                  .map((m) => m.traveler);
                                ////console.log("filterTraveller:",filterTraveller);
                                var i = 0;
                                for (let traveler of filterTraveller) {
                                  ////console.log("traveler:" + i, traveler);
                                  if (i === 0) {
                                    concateTraveller = traveler;
                                  } else {
                                    concateTraveller =
                                      concateTraveller + "<br>" + traveler;
                                  }
                                  i = i + 1;
                                }
                                ////console.log("concateTraveller:",concateTraveller);

                                return (
                                  <tr
                                    key={key}
                                    onClick={() =>
                                      this.onSelect(
                                        prop["authReqId"],
                                        prop["statusDesc"]
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>
                                      {prop["carServiceOnly"]
                                        ? "CA" + prop["authReqId"]
                                        : prop["authReqId"]}
                                    </td>
                                    <td>{parse(concateTraveller)}</td>
                                    <td>
                                      {Utils.FormatDate(prop["createdDate"])}
                                    </td>
                                    <td>{prop["artistProjectNumber"]}</td>
                                    <td>{prop["artistProjectName"]}</td>
                                    <td>{prop["artistName"]}</td>
                                    <td>{prop["inbox"]}</td>
                                    <td>{prop["statusDesc"]}</td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
