import React, { Component } from "react";
import { FormLabel, FormGroup, Container, Row, Col, Form, Navbar, Nav, Alert, Button, Card, CardGroup, FormControl, Modal, Table } from "../../../../node_modules/react-bootstrap";
import { NavLink } from 'react-router-dom';
import { FormInputs } from '../../CustomComponents/FormInput';
import { FormSelect } from '../../CustomComponents/FormSelect';
import { IDirectBillUpdateParameter, Istate } from "../../../Model/IDirectBillUpdate";
import { CommonController } from "../../../Controllers/CommonController";
import { DirectBillActivityController } from '../../../Controllers/DirectBillActivityController';
import { MenuController } from "../../../Controllers/MenuController";
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { Variables } from '../../../Variables/Variables';
import * as Utils from '../../../js/Utils.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';
import { createLogicalOr } from "typescript";
import { AuthStatus } from "../Common/AuthStatus"
import { TravellerController } from "../../../Controllers/TravellerController";
import { FormCheckBox } from "../../CustomComponents/FormCheckBox";
import { addYears } from "date-fns";


export class DirectBillUpdate extends Component<IDirectBillUpdateParameter, Istate> {
  constructor(props: IDirectBillUpdateParameter) {
    super(props)
    this.state = {
      fields: {
        label_id: sessionStorage.getItem("label_id"), authreq_id: '', submitter: '', expense_type_id: '', weight: '', tier_code: '', startDate: '', find: '*', searchScope: '', deptApp: [],
        pdb_name: '', pcity: '', pattn: '', pdept: '', printed_date: '', pphone: '', pagent: '', pfax: '', ptraveler: '', palias: '', pin_date: '', pout_date: '',
        prate: '', pconfirmation_number: '', pauthreq_id: '', pincidentals: '', psc_amount: '', billingApp: [], authApp: [], srexecApp: [], agentApp: [], searchValue: '',
        db: false, bSaveChange: false, btnCantit: '', sc_amount: '', btnCandis: false, sub_log: '', canceled: false, btnCanVal: '', sub_payment: '', check_number: '', sortClicked: false,
        btnFoliodis: false, got_folio: false, btnInvoicedis: false, btnFolioTitle: '', btnInvoiceTitle: '', btnSubPaydis: false, btnSubTitle: '', btnSubLogdis: false, styleSubLog: '', styleSubPay: '',
        btnChecknbrdis: false, btnChecknbrTitle: '', styleChecknbr: false, btnSubLogTitle: '', btnStyle: '', direct_bill_id: '', contact_id: [], traveler_id: '', sortBy: '', orderBy: '', appCnt: 0,
        alias: '', vendor_number: '', comments: '', travel_id: '', txtSearch: '', invoice_number: '', begin_date: '', end_date: '', invoice_amount: '', cancelled: '', contact_name: [], centrally_billed: '',
        travel_type: '', dropContactdis: true, dropContactdis1: [], txtAlias: '', txtInvoice: '', txtCheckin: '', txtCheckout: '', txtTotAmt: '', txtVendor: '', txtComments: '', txtCheckNo: '', btnDBStyle: '', btnDBdis: true, printed: false,
        btnPrintClr: '', btnSaveDBdis: false, btnSaveDBStyle: '', btnSearchdis: false, btnContactdis: false, btnRefreshdis: false, btnSearchstyle: '', btnContactstyle: '', btnRefreshstyle: '', bookingid: '',
        prevbox: '', invoice_id: '', maxlen: 1, modalkeyval: '',
        booking_id1: [], traveler_id1: [], travel_id1: [], alias1: [], direct_bill_id1: [], txtAlias11: [], txtInvoice1: [], txtTotAmt1: [], txtVendor1: [], txtComments1: [], txtCheckNo1: [], cancelled1: [], contact_id1: [], contact_id2: [],
        vendor_number1: [], comments1: [], invoice_number1: [], invoice_amount1: [], btnDBdis1: [], btnDBStyle1: [], printed1: [], btnPrintClr1: [], sub_log1: [], sub_payment1: [], check_number1: [], invoice_id1: [],
        btnSubLogdis1: [], styleSubLog1: [], btnSubLogTitle1: [], btnSubPaydis1: [], styleSubPay1: [], db1: [], btnChecknbrdis1: [], styleCheck1: [], btnChecknbrTitle1: [], btnCanVal1: [], btnCantit1: [], btnCandis1: [],
        btnStyle1: [], btnInvoiceTitle1: [], btnFolioTitle1: [], btnFoliodis1: [], btnInvoicedis1: [], btnSaveDBdis1: [], btnSaveDBStyle1: [], btnSearchdis1: [], btnSearchstyle1: [], btnContactdis1: [], btnContactstyle1: [],
        btnRefreshdis1: [], btnRefreshstyle1: [], bSaveChange1: [], got_folio1: [], btnSubPayTitle1: [], sc_amount1: [], txtSearch1:[]
      },
      selectfields: { tracking_grid: '', label_id: '', searchString: '', formLabel: '', contactListid: '',contactListid1:[] },
      errors: { invoice: false, invoiceh: true, vendor: false, vendorh: true, checkno: false, checknoh: true },
      errMsg: "",
      loading: false,
      modal: { modalShow: false, modalSearchShow: false, modalTitle: '', modalSearchTitle: '', modalBody: false, modalAlertShow: false, modalAlertTitle: '', modalAlertErrorMsg: '', modalSpinnerShow: false },
      startDate: new Date(),
      endDate: new Date(),
      FormLabel: [],
      HotelView: [],
      TAView: [],
      Printgrid: [],
      Approver: [],
      ConView: [],
      ConListView: [],
      uniquefields: { uniqueHotelView: [] }
    }

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleContactList = this.handleContactList.bind(this);
    this.handleSaveDBPrint = this.handleSaveDBPrint.bind(this);
    this.handleSaveInvoice = this.handleSaveInvoice.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.handleModalSearchShow = this.handleModalSearchShow.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSearchClose = this.handleModalSearchClose.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange1 = this.handleSelectChange1.bind(this); 
    this.handleSelectChange = this.handleSelectChange.bind(this);    
    this.handlebtnInvoiceCancel = this.handlebtnInvoiceCancel.bind(this);
    this.handlebtnDone = this.handlebtnDone.bind(this);
    this.handlebtnRefresh = this.handlebtnRefresh.bind(this);

  }

  componentWillMount() {
    let modal = this.state.modal;
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    let errors = this.state.errors;
    //Declaration begins
    fields["searchValue"] = "ar.authreq_id";
    fields["find"] = "*";
    // fields["bSaveChange"] = true;
    selectfields["searchString"] = "AuthReq #";
    selectfields['tracking_grid'] = 'In-Progress';
    selectfields['searchDays'] = "3";

    // this.state.fields.btnSubPaydis = false;

    //alert(this.props.location.state.authReqId)
    //alert(this.props.location.state.booking_id)

    //modal['modalSpinnerShow']=true;

    // this.sortByCol();
    // this.loadTrakingGrid();

    fields["bookingid"] = this.props.location.state.booking_id;
    fields['prevbox'] = "0"
    fields["dropContactdis1"][0] = true;
    fields["dropContactdis1"][1] = true;
    fields["dropContactdis1"][2] = true;
    fields["dropContactdis1"][3] = true;
    fields["dropContactdis1"][4] = true;
    fields["dropContactdis1"][5] = true;
    fields["dropContactdis1"][6] = true;
    fields["dropContactdis1"][7] = true;
    fields["dropContactdis1"][8] = true;


    this.setState({ fields });

    errors["invoice"] = false;
    errors["vendor"] = false;
    errors["checkno"] = false;
    errors["invoiceh"] = true;
    errors["vendorh"] = true;
    errors["checknoh"] = true;
    this.setState({ errors });

    sessionStorage.setItem('SearchDataView', '1')
    this.loadTA();
    this.loadSearchHotelGrid();

    let sbookings = this.props.location.state.sbookings;

    if (sbookings == "Hotel") {
      fields["travel_type"] = 'HTL'
    }
    else {
      fields["travel_type"] = 'CAR'
    }

    fields["txtSearch"] = "*";
    //fields["endDate"] = "12/16/2020"
    this.setState({ fields });

  }

  setFocus(target: any) {
    setTimeout(function () { target.focus(); }, 5);
  }

  handleChange(e: any, key) {
    let fields = this.state.fields;
    var regex = new RegExp("^-?[0-9]*\.?[0-9]*$");
    const re = /^-?[0-9]*\.?[0-9]*$/;
    const target = e.currentTarget;


    if (e.currentTarget.name === 'txtAlias') {
      fields[e.currentTarget.name] = e.currentTarget.value;
      fields["txtAlias11"][key] = e.currentTarget.value
      this.setState({ fields })
    }
    else if (e.currentTarget.name === 'txtInvoice') {
      fields[e.currentTarget.name] = e.currentTarget.value;
      fields["txtInvoice1"][key] = e.currentTarget.value
      this.setState({ fields })     
    }
    
    /*else if (e.currentTarget.name === 'txtInvoice') {

      if (!re.test(e.currentTarget.value)) {
        alert("You have entered an invalid value.");
        e.currentTarget.value = "";
        fields["txtInvoice1"][key] = "";
        this.setFocus(target);
        return false;
      }
      fields["txtInvoice1"][key] = e.currentTarget.value
    }*/
    else if (e.currentTarget.name === 'txtTotAmt') {
      fields[e.currentTarget.name] = e.currentTarget.value;
      if (!re.test(e.currentTarget.value)) {
        alert("You have entered an invalid value.");
        e.currentTarget.value = "";
        fields["txtTotAmt1"][key] = "";
        this.setFocus(target);
        return false;
      }
      fields["txtTotAmt1"][key] = e.currentTarget.value
    }
    else if (e.currentTarget.name === 'txtVendor') {
      fields[e.currentTarget.name] = e.currentTarget.value;
      fields["txtVendor1"][key] = e.currentTarget.value;
      this.setState({ fields }) 
      // if (!regex.test(e.currentTarget.value)) {
     // if (!re.test(e.currentTarget.value)) {
       // alert("You have entered an invalid value.");
       // e.currentTarget.value = "";
        //fields["txtVendor1"][key] = "";
       // this.setFocus(target);
       // return false;
      //}
      
    }
    else if (e.currentTarget.name === 'txtComments') {
      fields[e.currentTarget.name] = e.currentTarget.value;
      fields["txtComments1"][key] = e.currentTarget.value
    }
    else if (e.currentTarget.name === 'txtSearch'){      
      fields[e.currentTarget.name] = e.currentTarget.value;
      fields["txtSearch1"][key] = e.currentTarget.value
    }
    else if (e.currentTarget.name === 'txtCheckNo') {
      fields[e.currentTarget.name] = e.currentTarget.value;
      if (!re.test(e.currentTarget.value)) {
        alert("You have entered an invalid value.");
        e.currentTarget.value = "";
        fields["txtCheckNo1"][key] = "";
        this.setFocus(target);
        return false;
      }
      fields["txtCheckNo1"][key] = e.currentTarget.value
    }

    this.setState({ fields })

  }

  handleChangeStartDate = date => {
    
    this.setState({
      startDate: date
    });
  };
  handleChangeEndDate = date => {
    
    let selectfields = this.state.selectfields;
    selectfields['searchDays'] = "other";
    this.setState({
      endDate: date, selectfields
    });
  };

  handleSelectChange1 = (e: any,key) => {    
    //alert(e.currentTarget.options[e.currentTarget.selectedIndex].value)
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    //item["Ship_Method"] = e.currentTarget.value
    // selectfields['contact_id'] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
    fields["contact_id2"][key] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
    selectfields["contactListid1"][key] = e.currentTarget.options[e.currentTarget.selectedIndex].text;

    this.setState({
      fields, selectfields
    })

  }
  handleSelectChange = (e: any) => {
    
    let fields = this.state.fields;
    let selectfields = this.state.selectfields;
    //item["Ship_Method"] = e.currentTarget.value
    // selectfields['contact_id'] = e.currentTarget.options[e.currentTarget.selectedIndex].text;
    fields["contact_id"] = e.currentTarget.options[e.currentTarget.selectedIndex].value;
    selectfields["contactListid"] = e.currentTarget.options[e.currentTarget.selectedIndex].text;

    this.setState({
      fields, selectfields
    })

  }

  handleContactList = (e: any, currentTargetName, key) => {
    let fields = this.state.fields;
    let modal = this.state.modal;
    let sbookings = this.props.location.state.sbookings;
    let contact_type = this.state.fields.travel_type;
    let searchstring;
    let contact_id = this.state.fields.contact_id;

    fields["dropContactdis1"][0] = true;
    fields["dropContactdis1"][1] = true;

    this.setState({ fields });


    if (!this.state.fields.txtSearch1[key]) {
      searchstring = '*'
    }
    else {
      searchstring = this.state.fields.txtSearch1[key];
    }
    modal['modalSpinnerShow'] = true;
    this.setState({ modal });

    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      contact_id: contact_id,
      contact_type: contact_type,
      searchstring: searchstring
    }
    hotelGridController.getDirectBillContactList(data).then(response => {
      //
      if (response.success) {
        this.setState({
          ConListView: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }

        modal['modalSpinnerShow'] = true;
        modal['modalSearchShow'] = false;
        fields["dropContactdis"] = false;

        fields["dropContactdis1"][key] = false;
        // fields["dropContactdis1"][1]= true;

        this.setState({ modal, fields });

        // alert(this.state.fields.dropContactdis1);

      }

      modal['modalSpinnerShow'] = false;
      this.setState({ modal, fields });

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });


  }

  loadDirectBillContactId() {
    let fields = this.state.fields;
    let modal = this.state.modal;
    let sbookings = this.props.location.state.sbookings;
    let contact_type = this.state.fields.travel_type;
    let searchstring = '*'
    let contact_id = this.state.fields.contact_id;

    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      contact_id: contact_id,
      contact_type: contact_type,
      searchstring: searchstring
    }
    hotelGridController.getDirectBillContactId(data).then(response => {
      //
      if (response.success) {
        this.setState({
          ConView: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }
        else {
          let usertype_id = sessionStorage.getItem("user_type_id");
          let sysop = sessionStorage.getItem("sysop");

          this.state.ConView.map((prop, key) => {
            fields["contact_id"] = prop["contact_id"] || ''

            this.setState({ fields });
          })

        }

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });

      }


    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

  }

  validateInvoiceDetails(key) {
    let fields = this.state.fields;
    let vendor_number = this.state.fields.txtVendor1[key];
    let invoice_number = this.state.fields.txtInvoice1[key];
    let begin_date: string = Utils.FormatDate(this.state.startDate);// this.state.fields.txtCheckin;
    let end_date: string = Utils.FormatDate(this.state.endDate);//this.state.fields.txtCheckout;
    let invoice_amount = this.state.fields.txtTotAmt1[key];

    if ((invoice_number) && (begin_date) && (end_date) && (vendor_number)) {
      return 0;
    }
    else if (invoice_number == "" || invoice_number === undefined) {
      return 1;
    }
    else if (begin_date == "" || begin_date === undefined) {
      return 2;
    }
    else if (end_date == "" || end_date === undefined) {
      return 3;
    }
    else if (vendor_number == "" || vendor_number === undefined) {
      return 4;
    }

  }

  hasError(key) {
    return this.state.errors.indexOf(key) !== -1;
  }

  handleSaveInvoice = (e: any, currentTargetName, key) => {
    let modal = this.state.modal;
    let fields = this.state.fields;
    let errors = this.state.errors;
    let sbookings = this.props.location.state.sbookings;


    let direct_bill_id = this.state.fields.direct_bill_id1[key];
    let user_id = sessionStorage.getItem("uid");
    let travel_type = this.state.fields.travel_type;
    let traveler_id = this.state.fields.traveler_id1[key];
    let alias = this.state.fields.txtAlias11[key];
    let vendor_number = this.state.fields.txtVendor1[key];
    let comments = this.state.fields.txtComments1[key];
    let invoice_number = this.state.fields.txtInvoice1[key];
    let begin_date: string = Utils.FormatDate(this.state.startDate); //this.state.fields.txtCheckin;
    let end_date: string = Utils.FormatDate(this.state.endDate);//this.state.fields.txtCheckout;
    let invoice_amount = this.state.fields.txtTotAmt1[key];
    let canceled = this.state.fields.cancelled1[key]


    let hotelGridController = new DirectBillActivityController();


    if (this.validateInvoiceDetails(key) == 1) {
      // alert("Please enter Invoice/Folio #/Folio Check In/Folio Check Out")
      // alert("Please enter a Invoice/Folio #")
      errors["invoice"] = true;
      errors["invoiceh"] = false;
      errors["vendor"] = false;
      errors["vendorh"] = true;
      this.setState({ errors });
    }
    else if (this.validateInvoiceDetails(key) == 2) {
      alert("Please enter a Folio Date")

    }
    else if (this.validateInvoiceDetails(key) == 3) {
      alert("Please enter a Folio Date")
    }
    else if (this.validateInvoiceDetails(key) == 4) {
      // alert("Please enter a Vendor Number")   
      errors["vendor"] = true;
      errors["vendorh"] = false;
      errors["invoice"] = false;
      errors["invoiceh"] = true;
      this.setState({ errors });
    }
    else {
      var data = {};
      data = {
        direct_bill_id: direct_bill_id,
        travel_type: travel_type,
        traveler_id: traveler_id,
        alias: alias,
        vendor_number: vendor_number,
        comments: comments,
        invoice_number: invoice_number,
        begin_date: begin_date,
        end_date: end_date,
        invoice_amount: invoice_amount,
        canceled: canceled,
        user_id: user_id
      }
      hotelGridController.directBillSaveInvoice(data).then(response => {
        //
        //        
        //modal['modalSpinnerShow'] = true;
        this.setState({ errors });
        this.loadSearchHotelGrid();

        //alert("Invoice Submitted...")

      }).catch(error => {
        console.error("There was an error loading ..., " + error)
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      });

      modal['modalSpinnerShow'] = true;
      this.setState({ modal });
      this.loadSearchHotelGrid();
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
      window.location.reload();

    }
  }

  checkPrevPageState = () => {
    let fields = this.state.fields;
    let prevpage = this.props.location.state.from;

    if (prevpage == 'directbillincoming') {
      fields['prevbox'] = "1"
    }
    else {
      fields['prevbox'] = "0"
    }
    this.setState({ fields });

    // this.loadAfterSaveSearchHotelGrid();
    this.loadSearchHotelGrid();


  }

  handleSaveDBPrint = (e: any, currentTargetName, key) => {
    let modal = this.state.modal;
    let fields = this.state.fields;
    let authreq_id: string = this.props.location.state.authReqId;
    let bookings;
    let booking_id = this.props.location.state.booking_id;
    let travel_type = this.state.fields.travel_type;
    let traveler_id = fields["traveler_id"];
    let sbookings = this.props.location.state.sbookings;
    //let contact_id = this.state.fields.contact_id; // fields["contact_id"];
    let centrally_billed = '0'
    let user_id = sessionStorage.getItem("uid");
    let alias = fields["alias"];
    let travel_id = fields["travel_id"];

    let traveler_id1 = this.state.fields.traveler_id1[key];
    let travel_id1 = this.state.fields.travel_id1[key];
    let alias1 = this.state.fields.alias1[key];
    let contact_id = this.state.fields.contact_id2[key];

    if (this.state.ConListView.length == 0) {
      if (travel_type == 'HTL') {
        alert('Please Select a Hotel')
      }
      else {
        alert('Please Select a Rental Car')
      }
    }
    else {
      let hotelGridController = new DirectBillActivityController();
      //return sWhere; 
      var data = {};
      data = {
        authreq_id: authreq_id,
        booking_id: booking_id,
        contact_id: contact_id,
        traveler_id: traveler_id1,//traveler_id,
        travel_type: travel_type,
        centrally_billed: centrally_billed,
        user_id: user_id,
        alias: alias1, // alias,
        travel_id: travel_id1 // travel_id        
      }

      hotelGridController.directBillSaveDbPrint(data).then(response => {
        //
        if (response.success) {
          let fields = this.state.fields;

          modal['modalSpinnerShow'] = false;
          this.setState({ modal });

        }

      }).catch(error => {
        console.error("There was an error loading ..., " + error)
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      });


      alert('Contact Saved Successfully')

      modal['modalSpinnerShow'] = true;
      this.setState({ modal });
      //this.loadAfterSaveSearchHotelGrid();
      this.checkPrevPageState();
     // window.location.reload();
    }

    //window.location.reload(false);

  }

  handleOnSubmit = (e: any, currentTargetName, key) => {
    let modal = this.state.modal;
    let fields = this.state.fields;
    let errors = this.state.errors;
    let sbookings = this.props.location.state.sbookings;
    let processType = currentTargetName;
    let direct_bill_id = this.state.fields.direct_bill_id1[key]
    let user_id = sessionStorage.getItem("uid");
    let check_number = this.state.fields.txtCheckNo1[key]
    let contact_id = this.state.fields.contact_id1[key]; // fields["contact_id"];
    let searchval = "*";
    let travel_type = this.state.fields.travel_type[key];
    let traveler_id = this.state.fields.traveler_id1[key];
    let alias = this.state.fields.alias1[key];
    let vendor_number = this.state.fields.vendor_number1[key];
    let comments = this.state.fields.comments1[key];
    let invoice_number = this.state.fields.invoice_number1[key];
    let begin_date = fields["begin_date"];
    let end_date = fields["end_date"];
    let invoice_amount = this.state.fields.invoice_amount1[key];
    let canceled = this.state.fields.cancelled1[key];


    if (processType == "saveCheckNumber") {

      if (!check_number) {
      //  alert('Please enter a Check Number')
        errors["checkno"] = true;
        errors["checknoh"] = false;
        this.setState({errors});
      }
      else {
        if (this.state.fields.check_number1[key]) {
          check_number = "";
          errors["checkno"] = false;
          errors["checknoh"] = true;
          this.setState({ errors });
        }
        //
        let hotelGridController = new DirectBillActivityController();
        //return sWhere; 
        var data = {};
        data = {
          processType: processType,
          direct_bill_id: direct_bill_id,
          user_id: user_id,
          check_number: check_number,
          contact_id: contact_id,
          searchval: searchval,
          travel_type: travel_type,
          traveler_id: traveler_id,
          alias: alias,
          vendor_number: vendor_number,
          comments: comments,
          invoice_number: invoice_number,
          begin_date: begin_date,
          end_date: end_date,
          invoice_amount: invoice_amount,
          canceled: canceled
        }

        hotelGridController.directBillUserProcessUpdate(data).then(response => {

          modal['modalSpinnerShow'] = true;
          this.setState({ modal });
          this.loadSearchHotelGrid();
          window.location.reload();


        }).catch(error => {
          console.error("There was an error loading ..., " + error)
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        });

      }

    }
    else {

      //
      let hotelGridController = new DirectBillActivityController();
      //return sWhere; 
      var data = {};
      data = {
        processType: processType,
        direct_bill_id: direct_bill_id,
        user_id: user_id,
        check_number: check_number,
        contact_id: contact_id,
        searchval: searchval,
        travel_type: travel_type,
        traveler_id: traveler_id,
        alias: alias,
        vendor_number: vendor_number,
        comments: comments,
        invoice_number: invoice_number,
        begin_date: begin_date,
        end_date: end_date,
        invoice_amount: invoice_amount,
        canceled: canceled
      }

      hotelGridController.directBillUserProcessUpdate(data).then(response => {

        modal['modalSpinnerShow'] = true;
        this.setState({ modal });
        this.loadSearchHotelGrid();
        window.location.reload();


      }).catch(error => {
        console.error("There was an error loading ..., " + error)
        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      });

    }
   // modal['modalSpinnerShow'] = true;
  //  this.setState({ modal });
  //  this.loadSearchHotelGrid();
  //  window.location.reload(false);

  }

  handleModalShow = (e: any, key) => {
    
    let modal = this.state.modal;
    modal['modalTitle'] = 'eTA - Print Direct Bill Activity';
    modal['modalShow'] = true;
    this.setState({ modal });
    this.printDirectBill(key);
  }

  handleModalSearchShow = (e: any) => {
    
    let modal = this.state.modal;
    modal['modalSearchTitle'] = 'eTA - Search';
    modal['modalSearchShow'] = true;
    this.setState({ modal });

  }

  handleModalSearchShow1 = (e: any, key) => {
    
    let modal = this.state.modal;
    let fields = this.state.fields;
    modal['modalSearchTitle'] = 'eTA - Search';
    fields['modalkeyval'] = key;
    modal['modalSearchShow'] = true;
    fields['txtSearch1'][key] = '*'
    this.setState({ modal, fields });

  }

  loadTA = () => {
    let travellerController = new TravellerController();
    travellerController.getTA(this.props.location.state.authReqId).then(response => {
      this.setState({ loading: false });
      let fields = this.state.fields;
      //fields["divCreateTA"] = "none";
      fields["divTA"] = "block";
      this.setState({ fields });
      // this.printDirectBill();
    }).catch(error => {
      console.error("There was an error!", error)
    })
  }

  displayProject = () => {
    switch (sessionStorage.getItem("ta_expense_type_id")) {
      case '1' || '2' || '10' || '11': {
        return sessionStorage.getItem("ta_artist_project_number") + ' / ' + sessionStorage.getItem("ta_artist_project_name") + ' / ' + sessionStorage.getItem("ta_artist_name");
      }
      case '100' || '1000': {
        if (sessionStorage.getItem("ta_artist_project_name") != "") {
          return sessionStorage.getItem("ta_artist_project_number") + ' / ' + sessionStorage.getItem("ta_artist_project_name")
        } else {
          return sessionStorage.getItem("ta_artist_project_number")
        }
      }
      default: {
        return '';
      }
    }
    return
  }

  loadTACodingGrid = () => {
    let modal = this.state.modal;

    let authreq_id: string = this.props.location.state.authReqId;
    let bookings;
    let traveler_id = this.props.location.state.booking_id;
    let traveler_type = '';
    let sbookings = this.props.location.state.sbookings;
    let prevpage = this.props.location.state.from;
    let box: string

    if (prevpage == 'directbillincoming') {
      if (this.state.fields.prevbox == '1') {
        box = '';
      }
      else {
        box = 'incoming';
      }
    }

    else {
      box = '';
    }

    if (sbookings == "Hotel") {
      bookings = 'Hotel'
    }
    else {
      bookings = 'Rental Car'
    }

    var data = {};
    data = {
      authreq_id: authreq_id,
      bookings: bookings,
      traveler_id: traveler_id,
      traveler_type: traveler_type,
      box: box
    }

    let TAGridController = new DirectBillActivityController();
    TAGridController.getDirectBillTACoding(data).then(response => {
      //
      if (response.success) {
        let fields = this.state.fields;
        this.setState({
          TAView: JSON.parse(JSON.stringify(response.recordset)), fields
        });
        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });

      }


    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });



  }
  handleModalClose = (e: any) => {
    let modal = this.state.modal;
    let loading = this.state.loading;
    modal['modalShow'] = false;
    modal['loading'] = false;
    this.setState({ modal, loading })
  }

  handleModalSearchClose = (e: any) => {
    let modal = this.state.modal;
    let loading = this.state.loading;
    modal['modalSearchShow'] = false;
    modal['loading'] = false;
    this.setState({ modal, loading })
  }


  loadSearchHotelGrid = () => {
    //
    let selectfields = this.state.selectfields;
    let modal = this.state.modal;
    let fields = this.state.fields;

    let prevpage = this.props.location.state.from;
    let box: string

    if (prevpage == 'directbillincoming') {
      if (this.state.fields.prevbox == '1') {
        box = '';
      }
      else {
        box = 'incoming';
      }
    }

    else {
      box = '';
    }

    let dbview: string = '';
    let authreq_id: string = this.props.location.state.authReqId;
    let booking_id = this.state.fields.bookingid; //this.props.location.state.booking_id;
    let sbookings = this.props.location.state.sbookings;

    if (sbookings == "Hotel") {
      dbview = 'HTL'
    }
    else {
      dbview = 'CAR'
    }

    //
    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      box: box,
      dbview: dbview,
      authreq_id: authreq_id,
      booking_id: booking_id
    }
    hotelGridController.getDirectBillActivityView(data).then(response => {
      //
      if (response.success) {
        this.setState({
          HotelView: JSON.parse(JSON.stringify(response.recordset)), fields
        });

        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }
        else {
          modal['modalSpinnerShow'] = true;
          this.setState({ modal });
          let usertype_id = sessionStorage.getItem("user_type_id");
          let sysop = sessionStorage.getItem("sysop");

        }

        this.loadArraySearchHotelGrid();

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });

    //this.loadTACodingGrid();

    modal['modalSpinnerShow'] = false;
    this.setState({ modal });
  }


  loadArraySearchHotelGrid = () => {

    let fields = this.state.fields;
    let usertype_id = sessionStorage.getItem("user_type_id");
    let sysop = sessionStorage.getItem("sysop");

    this.state.HotelView.map((prop, key) => {
      /*
            fields["db"] = prop["db"] || ''
            fields["sub_log"] = prop["submitted_to_log"] || ''
            fields["sub_payment"] = prop["submitted_payment"] || ''
            fields["check_number"] = prop["check_number"] || ''
            fields["txtCheckNo"] = prop["check_number"] || ''
            fields["canceled"] = prop["canceled"] || ''
            fields["sc_amount"] = prop["sc_amount"] || ''
            fields["got_folio"] = prop["got_folio"] || ''
            fields["direct_bill_id"] = prop["direct_bill_id"] || ''
            fields["contact_id"] = prop["contact_id"] || ''
            fields["traveler_id"] = prop["traveller_id"] || ''            
            fields["alias"] = prop["alias"] || ''
            fields["txtAlias"] = prop["alias"] || ''
            fields["vendor_number"] = prop["vendor_number"] || ''
            fields["txtVendor"] = prop["vendor_number"] || ''
            fields["comments"] = prop["comments"] || ''
            fields["txtComments"] = prop["comments"] || ''
            fields["invoice_number"] = prop["invoice_number"] || ''
            fields["txtInvoice"] = prop["invoice_number"] || ''
            fields["begin_date"] = prop["begin_date"] || ''
            fields["txtCheckin"] = prop["begin_date"] || ''
            fields["end_date"] = prop["end_date"] || ''
            fields["txtCheckout"] = prop["end_date"] || ''
            fields["invoice_amount"] = prop["invoice_amount"] || ''
            fields["txtTotAmt"] = prop["invoice_amount"] || ''
            fields["cancelled"] = prop["canceled"] || ''
            fields["travel_id"] = prop["travel_id"] || ''
            fields["printed"] = prop['printed'] || ''
            fields["invoice_id"] = prop['invoice_id']
            */

      fields["traveler_id1"][key] = prop["traveller_id"] || ''
      fields["travel_id1"][key] = prop["travel_id"] || ''
      fields["alias1"][key] = prop["alias"] || ''
      fields["txtAlias11"][key] = prop["alias"] || ''
      fields["direct_bill_id1"][key] = prop["direct_bill_id"] || ''
      fields["cancelled1"][key] = prop["canceled"] || ''
      fields["contact_id1"][key] = prop["contact_id"] || ''
      fields["vendor_number1"][key] = prop["vendor_number"] || ''
      fields["comments1"][key] = prop["comments"] || ''
      fields["invoice_number1"][key] = prop["invoice_number"] || ''
      fields["invoice_amount1"][key] = prop["invoice_amount"] || ''
      fields["txtTotAmt1"][key] =  prop["invoice_amount"] || ''
      fields["sub_log1"][key] = prop["submitted_to_log"] || ''
      fields["sub_payment1"][key] = prop["submitted_payment"] || ''
      fields["check_number1"][key] = prop["check_number"] || ''
      fields["invoice_id1"][key] = prop['invoice_id']
      fields["db1"][key] = prop["db"] || ''
      fields["got_folio1"][key] = prop["got_folio"] || ''
      fields["txtInvoice1"][key] = prop["invoice_number"] || ''
      fields["txtVendor1"][key] = prop["vendor_number"] || ''
      fields["txtComments1"][key] = prop["comments"] || ''
      fields["txtCheckNo1"][key] = prop["check_number"] || ''
      fields["printed1"][key] = prop['printed'] || ''
      fields["sc_amount1"][key] = prop["sc_amount"] || ''

      if (!fields["contact_id1"][key]) {
        this.loadDirectBillContactId();
      }

      if (fields["direct_bill_id1"][key]) {
        fields["btnDBdis1"][key] = false;
        fields["btnDBStyle1"][key] = "";
        if (fields["printed1"][key]) {
          fields["btnPrintClr1"][key] = "";
        }
        else {
          fields["btnPrintClr1"][key] = "red";
        }
      }
      else {
        fields["btnDBStyle1"][key] = "black";
        fields["btnDBdis1"][key] = true;
      }


      if ((usertype_id != 'DB' && sysop == '') || fields["sub_log1"][key] == true || (fields["sub_payment1"][key]) || (fields["check_number1"][key] != '')) {
        fields["bSaveChange1"][key] = false;
        // fields["btnCandis"] = true;       
      }
      else {
        fields["bSaveChange1"][key] = true;
        //fields["btnCandis"] = false;
      }

      if ((usertype_id != 'DB' && sysop == '') || (!fields["invoice_id1"][key]) || ((!fields["sub_log1"][key]) && (!fields["got_folio1"][key])) || (fields["sub_payment1"][key]) || (fields["check_number1"][key] != '')) {
        fields["btnSubLogdis1"][key] = true;
        fields["styleSubLog1"][key] = "black"
      }
      else {
        if (fields["sub_log1"][key]) {
          fields["btnSubLogTitle1"][key] = "Click to Remove from Submit for Payment Report";
        }
        else {
          fields["btnSubLogTitle1"][key] = "Click to Add to Submit for Payment Report";
        }
      }


      if ((usertype_id != 'DB' && sysop == '') || ((!fields["sub_payment1"][key]) && (!fields["got_folio1"][key])) || ((!fields["sub_payment1"][key]) && (!fields["sub_log1"][key])) || (fields["check_number1"][key] != '')) {
        fields["btnSubPaydis1"][key] = true;
        fields["styleSubPay1"][key] = "black";
      }
      else {
        if (fields["sub_payment1"][key]) {
          fields["btnSubPayTitle1"][key] = "Click to Remove Submit for Payment";
        }
        else {
          fields["btnSubPayTitle1"][key] = "Click to Submit for Payment";
        }
      }

      if ((usertype_id != 'DB' && sysop == '') || ((fields["check_number1"][key] == '') && (!fields["got_folio1"][key])) || ((fields["check_number1"][key] == '') && (!fields["sub_log1"][key])) || ((fields["check_number1"][key] == '') && (!fields["sub_payment1"][key]))) {
        fields["btnChecknbrdis1"][key] = true;
        fields["styleCheck1"][key] = "black";
      }
      else {
        if (fields["check_number1"][key] != '') {
          fields["btnChecknbrTitle1"][key] = "Click to Remove the Check Number";
        }
        else {
          fields["btnChecknbrTitle1"][key] = "Click to Save the Check Number";
        }
      }


      if (fields["db1"][key]) {

        if (fields["cancelled1"][key]) {
          fields["btnCanVal1"][key] = "Uncancel";
        }
        else {

          fields["btnCanVal1"][key] = "Cancel";
        }

        if (fields["bSaveChange1"][key]) {
          if (fields["cancelled1"][key]) {
            fields["btnCantit1"][key] = "Click to Add this Traveler to Reservation";
          }
          else {
            fields["btnCantit1"][key] = "Click to Cancel this Traveler Reservation";
          }
        }
        else {
          fields["btnCandis1"][key] = true;
        }
      }

      if (fields["bSaveChange1"][key]) {
        fields["btnStyle1"][key] = "";
        fields["btnInvoiceTitle1"][key] = "Click to Save Invoice Information";
        if (fields["got_folio1"][key]) {
          fields["btnFolioTitle1"][key] = "Click to Remove from Got Folio Report";
        }
        else {
          fields["btnFolioTitle1"][key] = "Click to Add to Got Folio Report";
        }
      }
      else {
        fields["btnFoliodis1"][key] = true;
        fields["btnStyle1"][key] = "black";
        fields["btnInvoicedis1"][key] = true;
      }

      if (fields["btnFoliodis1"][key]) {
        fields["btnFolioTitle1"][key] = ""
      }

      if (!fields["bSaveChange1"][key]) {
        fields["btnSaveDBdis1"][key] = true;
        fields["btnSaveDBStyle1"][key] = "black";
      }
      if (!fields["bSaveChange1"][key]) {
        fields["btnSearchdis1"][key] = true;
        fields["btnSearchstyle1"][key] = "black";
        fields["btnContactdis1"][key] = true;
        fields["btnContactstyle1"][key] = "black";
        fields["btnRefreshdis1"][key] = true;
        fields["btnRefreshstyle1"][key] = "black";
      }

      this.setState({ fields });
    })

    this.loadTACodingGrid();
    // window.location.reload();
  }


  loadAfterSaveSearchHotelGrid = () => {
    //
    let selectfields = this.state.selectfields;
    let modal = this.state.modal;
    let fields = this.state.fields;
    let prevpage = this.props.location.state.from;
    let box: string
    box = '';

    let dbview: string = '';
    let authreq_id: string = this.props.location.state.authReqId;
    let booking_id = this.state.fields.bookingid; //this.props.location.state.booking_id;
    let sbookings = this.props.location.state.sbookings;

    if (sbookings == "Hotel") {
      dbview = 'HTL'
    }
    else {
      dbview = 'CAR'
    }

    //
    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      box: box,
      dbview: dbview,
      authreq_id: authreq_id,
      booking_id: booking_id
    }
    hotelGridController.getDirectBillActivityView(data).then(response => {
      //
      if (response.success) {
        this.setState({
          HotelView: JSON.parse(JSON.stringify(response.recordset)), fields
        });

        if (response.recordset.length === 0) {
          modal['modalSpinnerShow'] = false;
          this.setState({ modal });
        }
        else {
          modal['modalSpinnerShow'] = true;
          this.setState({ modal });
          let usertype_id = sessionStorage.getItem("user_type_id");
          let sysop = sessionStorage.getItem("sysop");

          this.state.HotelView.map((prop, key) => {
            fields["db"] = prop["db"] || ''
            fields["sub_log"] = prop["submitted_to_log"] || ''
            fields["sub_payment"] = prop["submitted_payment"] || ''
            fields["check_number"] = prop["check_number"] || ''
            fields["txtCheckNo"] = prop["check_number"] || ''
            fields["canceled"] = prop["canceled"] || ''
            fields["sc_amount"] = prop["sc_amount"] || ''
            fields["got_folio"] = prop["got_folio"] || ''
            fields["direct_bill_id"] = prop["direct_bill_id"] || ''
            fields["contact_id"] = prop["contact_id"] || ''
            fields["traveler_id"] = prop["traveller_id"] || ''
            fields["alias"] = prop["alias"] || ''
            fields["txtAlias"] = prop["alias"] || ''
            fields["vendor_number"] = prop["vendor_number"] || ''
            fields["txtVendor"] = prop["vendor_number"] || ''
            fields["comments"] = prop["comments"] || ''
            fields["txtComments"] = prop["comments"] || ''
            fields["invoice_number"] = prop["invoice_number"] || ''
            fields["txtInvoice"] = prop["invoice_number"] || ''
            fields["begin_date"] = prop["begin_date"] || ''
            fields["txtCheckin"] = prop["begin_date"] || ''
            fields["end_date"] = prop["end_date"] || ''
            fields["txtCheckout"] = prop["end_date"] || ''
            fields["invoice_amount"] = prop["invoice_amount"] || ''
            fields["txtTotAmt"] = prop["invoice_amount"] || ''
            fields["cancelled"] = prop["canceled"] || ''
            fields["travel_id"] = prop["travel_id"] || ''
            fields["printed"] = prop['printed'] || ''

            fields["traveler_id1"][key] = prop["traveller_id"] || ''
            fields["travel_id1"][key] = prop["travel_id"] || ''
            fields["alias1"][key] = prop["alias"] || ''
            fields["txtAlias11"][key] = prop["alias"] || ''
            fields["direct_bill_id1"][key] = prop["direct_bill_id"] || ''
            fields["cancelled1"][key] = prop["canceled"] || ''
            fields["contact_id1"][key] = prop["contact_id"] || ''
            fields["vendor_number1"][key] = prop["vendor_number"] || ''
            fields["comments1"][key] = prop["comments"] || ''
            fields["invoice_number1"][key] = prop["invoice_number"] || ''
            fields["invoice_amount1"][key] = prop["invoice_amount"] || ''

            if (!fields["contact_id"]) {
              this.loadDirectBillContactId();
            }


            if (fields["direct_bill_id"]) {
              fields["btnDBdis"] = false;
              fields["btnDBStyle"] = "";

              if (!fields["printed"]) {
                fields["btnPrintClr"] = "red";
              }
              else {
                fields["btnPrintClr"] = "";
              }
            }
            else {
              fields["btnDBStyle"] = "black";
            }

            if ((usertype_id != 'DB' && sysop == '') || fields["sub_log"] == true || (fields["sub_payment"]) || (fields["check_number"] != '')) {
              fields["bSaveChange"] = false;
              // fields["btnCandis"] = true;
            }
            else {
              fields["bSaveChange"] = true;
              //fields["btnCandis"] = false;
            }

            if ((usertype_id != 'DB' && sysop == '') || ((!fields["sub_log"]) && (!fields["got_folio"])) || (fields["sub_payment"]) || (fields["check_number"] != '')) {
              fields["btnSubLogdis"] = true;
              fields["styleSubLog"] = "black"
            }
            else {
              if (fields["sub_log"]) {
                fields["btnSubLogTitle"] = "Click to Remove from Submit for Payment Report";
              }
              else {
                fields["btnSubLogTitle"] = "Click to Add to Submit for Payment Report";
              }
            }

            if ((usertype_id != 'DB' && sysop == '') || ((!fields["sub_payment"]) && (!fields["got_folio"])) || ((!fields["sub_payment"]) && (!fields["sub_log"])) || (fields["check_number"] != '')) {
              fields["btnSubPaydis"] = true;
              fields["styleSubPay"] = "black";
            }
            else {
              if (fields["sub_payment"]) {
                fields["btnSubPayTitle"] = "Click to Remove Submit for Payment";
              }
              else {
                fields["btnSubPayTitle"] = "Click to Submit for Payment";
              }

            }

            if ((usertype_id != 'DB' && sysop == '') || ((fields["check_number"] == '') && (!fields["got_folio"])) || ((fields["check_number"] == '') && (!fields["sub_log"])) || ((fields["check_number"] == '') && (!fields["sub_payment"]))) {
              fields["btnChecknbrdis"] = true;
              fields["styleCheck"] = "black";
            }
            else {
              if (fields["check_number"] != '') {
                fields["btnChecknbrTitle"] = "Click to Remove the Check Number";
              }
              else {
                fields["btnChecknbrTitle"] = "Click to Save the Check Number";
              }
            }

            if (fields["db"]) {

              if (fields["canceled"]) {
                fields["btnCanVal"] = "Uncancel";
              }
              else {
                fields["btnCanVal"] = "Cancel";
              }

              if (fields["bSaveChange"]) {
                if (fields["canceled"]) {
                  fields["btnCantit"] = "Click to Add this Traveler to Reservation";
                }
                else {
                  fields["btnCantit"] = "Click to Cancel this Traveler Reservation";
                }
              }
              else {
                fields["btnCandis"] = true;
              }

            }

            if (fields["bSaveChange"]) {
              fields["btnStyle"] = "";
              fields["btnInvoiceTitle"] = "Click to Save Invoice Information";
              if (fields["got_folio"]) {
                fields["btnFolioTitle"] = "Click to Remove from Got Folio Report";
              }
              else {
                fields["btnFolioTitle"] = "Click to Add to Got Folio Report";
              }
            }
            else {
              fields["btnFoliodis"] = true;
              fields["btnStyle"] = "black";
              fields["btnInvoicedis"] = true;
            }

            if (fields["btnFoliodis"]) {
              fields["btnFolioTitle"] = ""
            }

            if (!fields["bSaveChange"]) {
              fields["btnSaveDBdis"] = true;
              fields["btnSaveDBStyle"] = "black";
            }
            if (!fields["bSaveChange"]) {
              fields["btnSearchdis"] = true;
              fields["btnSearchstyle"] = "black";
              fields["btnContactdis"] = true;
              fields["btnContactstyle"] = "black";
              fields["btnRefreshdis"] = true;
              fields["btnRefreshstyle"] = "black";
            }


            this.setState({ fields });
          })

        }

        modal['modalSpinnerShow'] = false;
        this.setState({ modal });
      }

    }).catch(error => {
      console.error("There was an error loading Travller Search..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });


    this.loadTACodingGrid();

    modal['modalSpinnerShow'] = false;
    this.setState({ modal });

  }


  handlebtnDone = (e: any) => {
    let fields = this.state.fields;
    let prevpage = this.props.location.state.from;

    if (this.state.fields.db1[0]) {
      if ((prevpage == 'directbillincoming') && (this.state.fields.printed1[0])) {
        this.props.history.goBack();
      }
      else if (this.state.fields.printed1[0]) {
        this.props.history.goBack();
      }
      else if ((prevpage == 'directbillincoming') || (!this.state.fields.printed1[0])) {
        const r = window.confirm("Not all Direct Bills have been printed are you sure you wish to quit?")
        if (r == true) {
          this.props.history.goBack();
        }
      }
      else {
        alert('The Direct Bill is now In Progress if you wish to print it')
      }
    }
    else {
      if (this.state.fields.prevbox == '1') {
        alert('Not all Direct Bills have complete Contact and Invoice/Folio information')
      }
      else {
        alert('Not all Direct Bills have complete Contact information')
      }
    }

  }

  handlebtnRefresh = (e: any) => {
    this.loadSearchHotelGrid();
  }

  handlebtnInvoiceCancel = (e: any) => {

    let fields = this.state.fields;

    if (!this.state.fields.canceled) {
      var today = new Date(),
        date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
      fields["canceled"] = true;
      fields["btnCanVal"] = "Uncancel";
      fields["txtInvoice"] = "CANCELED/NO SHOW";
      fields["txtTotAmt"] = "$0.00";
      fields["txtVendor"] = "None";
      fields["txtCheckin"] = date;
      fields["txtCheckout"] = date;
    }
    else {
      fields["canceled"] = false;
      fields["btnCanVal"] = "Cancel";
      fields["txtInvoice"] = "";
      fields["txtTotAmt"] = "$0.00";
      fields["txtVendor"] = "";
      fields["txtCheckin"] = "";
      fields["txtCheckout"] = "";
    }

    this.setState({ fields });

  }

  printDirectBill = (key) => {
    //
    let selectfields = this.state.selectfields;
    let modal = this.state.modal;
    let fields = this.state.fields;

    let travel_type = this.state.fields.travel_type;
    let direct_bill_id = this.state.fields.direct_bill_id1[key];
    let user_id = sessionStorage.getItem("uid");
    let travel_id = this.state.fields.travel_id1[key];
    let authreq_id: string = this.props.location.state.authReqId;

    //
    let hotelGridController = new DirectBillActivityController();
    //return sWhere; 
    var data = {};
    data = {
      travel_type: travel_type,
      travel_id: travel_id,
      authreq_id: authreq_id,
      direct_bill_id: direct_bill_id,
      user_id: user_id

    }
    hotelGridController.getDirectBillPrint(data).then(printDirectBilldata => {
      //

      let fields = this.state.fields;

      fields["pdb_name"] = printDirectBilldata.db_name || "";
      fields["pcity"] = printDirectBilldata.city || "";
      fields["pattn"] = printDirectBilldata.attn || "";
      fields["pdept"] = printDirectBilldata.dept || "";
      fields["printed_date"] = printDirectBilldata.printed_date || "";
      fields["pphone"] = printDirectBilldata.phone || "";
      fields["pagent"] = printDirectBilldata.agent || "";
      fields["pfax"] = printDirectBilldata.fax || "";
      fields["ptraveler"] = printDirectBilldata.traveler || "";
      fields["palias"] = printDirectBilldata.alias || "";
      fields["pin_date"] = printDirectBilldata.in_date || "";
      fields["pout_date"] = printDirectBilldata.out_date || "";
      fields["prate"] = printDirectBilldata.rate || "";
      fields["pconfirmation_number"] = printDirectBilldata.confirmation_number || "";
      fields["pauthreq_id"] = printDirectBilldata.authreq_id || "";
      fields["pincidentals"] = printDirectBilldata.incidentals || "";
      fields["psc_amount"] = printDirectBilldata.sc_amount || "";

      this.setState({ fields });


      modal['modalSpinnerShow'] = false;
      this.setState({ modal });

      this.loadSearchHotelGrid();


    }).catch(error => {
      console.error("There was an error..., " + error)
      modal['modalSpinnerShow'] = false;
      this.setState({ modal });
    });


  }


  displayPrintDirectBill = () => {
    return <div id="divDirectBillPrint">
      <Row className="justify-content-md-center">
        <Col sm={9}>
          <Form.Label style={{ marginRight: "250px" }}><h4>Universal Music Group Authorization</h4></Form.Label>
        </Col>

        <Col sm={3}>
          <Button disabled={this.state.loading} name='print' style={{ marginRight: "5px" }} onClick={(e) => window.print()} title="Click to Print">
            {this.state.loading && (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", color: "#3472F7" }}
              />
            )}
            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
            {!this.state.loading && <span>Print</span>}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={4}>
          <b> Hotel:</b> {this.state.fields.pdb_name}
        </Col>
        <Col sm={4}>
        </Col>
        <Col sm={2}>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={4}>
          <b> City:</b> {this.state.fields.pcity}
        </Col>
        <Col sm={4}>
        </Col>
        <Col sm={2}>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={4}>
          <b> Attn: </b> {this.state.fields.pattn}
        </Col>
        <Col sm={4}>
        </Col>
        <Col sm={2}>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={4}>
          <b> Dept: </b> {this.state.fields.pdept}
        </Col>
        <Col sm={4}>
          <b> Date: </b> {Utils.FormatDate(this.state.fields.printed_date)}
        </Col>
        <Col sm={2}>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={4}>
          <b> Phone: </b> {this.state.fields.pphone}
        </Col>
        <Col sm={4}>
          <b> Agent: </b> {this.state.fields.agent}
        </Col>
        <Col sm={2}>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={4}>
          <b> Fax: </b> {this.state.fields.pfax}
        </Col>
        <Col sm={4}>
          <b> Phone: </b> (888) 890-9096, option 4
        </Col>
        <Col sm={2}>
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={2}>
        </Col>
        <Col sm={8}>
          This is your authorization to bill <b>ONLY CHARGES AS NOTED BELOW</b> for the following reservation(s).
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={3}><b>NAME</b> </Col>
        <Col sm={1}><b>ALIAS</b> </Col>
        <Col sm={2}><b>ARRIVE</b> </Col>
        <Col sm={2}><b>DEPART</b> </Col>
        <Col sm={2}><b>RATE</b> </Col>
        <Col sm={1}><b>CONF. #</b> </Col>
        <Col sm={1}><b>TA</b> </Col>
      </Row>
      <Row>
        <Col sm={3}>{this.state.fields.ptraveler} </Col>
        <Col sm={1}>{this.state.fields.palias} </Col>
        <Col sm={2}>{Utils.FormatDate(this.state.fields.pin_date)} </Col>
        <Col sm={2}>{Utils.FormatDate(this.state.fields.pout_date)} </Col>
        <Col sm={2}>{this.state.fields.prate} </Col>
        <Col sm={1}>{this.state.fields.pconfirmation_number} </Col>
        <Col sm={1}>{this.state.fields.pauthreq_id} </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={4}><b>AUTHORIZED AMOUNT FOR <u>ROOM &amp; TAX:</u></b></Col>
        <Col sm={2}></Col>
        <Col sm={3}></Col>
        <Col sm={3}>{this.state.fields.pincidentals}</Col>
      </Row>
      <Row>
        <Col sm={4}><b>AUTHORIZED AMOUNT FOR <u>INCIDENTALS</u></b></Col>
        <Col sm={2}></Col>
        <Col sm={3}></Col>
        <Col sm={3}>NONE</Col>
      </Row>
      <Row>
        <Col sm={4}><b>AUTHORIZED AMOUNT FOR <u>SPECIAL CHARGES</u>:</b></Col>
        <Col sm={2}></Col>
        <Col sm={3}></Col>
        <Col sm={3}>NONE</Col>
      </Row>
      <Row>
        <Col sm={4}><b>CHARGE TO - ATTACHED CARD</b></Col>
        <Col sm={2}></Col>
        <Col sm={3}></Col>
        <Col sm={3}></Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={12}>
          If incidentals or special charges are not authorized on this form, it will be the hotel's responsibility to collect and charge the guest's credit card upon check out.  Universal Music Group will <u>NOT</u> be responsible for any unauthorized charges. <p> <b>To ensure payment, without dispute, email or fax a copy of the folio and this authorization form upon guest check out.</b> </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          Any billing inquiries may also be directed to either of the two designated contacts below:
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={3}></Col>
        <Col sm={9}>
          <div>
            Caroline.Barillas@ctmtravel.com <b>OR</b> Carolyn.Pham@ctmtravel.com.
          </div>
          <div>
            Caroline Barillas - (818) 260-6582 or fax to (770) 901 3808
          </div>
          <div>
            Carolyn Pham- (818) 260-6536 or fax to (770) 901-3819
          </div>
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={3}></Col>
        <Col sm={9}>
          All <b>"reservation"</b> inquiries should be redirected to <b>CTM Travel</b> at (888)890-9096 option #4.
        </Col>
      </Row>
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <Col sm={3}></Col>
        <Col sm={9}>
          <div>
            Card Billing Information:<br /><br />
            Universal Music Group<br />
            Pamela A. Witherspoon<br />
            Global Head of Travel<br />
            21301 Burbank Blvd<br />
            Woodland Hills, 91367<br /><br />
            (see attached page 2 for signatures)<br /><br />
          </div>
        </Col>
      </Row>

    </div>


  }


  formLabel = () => {
    return <Form.Group>
      <Form.Label>Form Label</Form.Label>
      <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} >
        {this.state.FormLabel.map((prop, key) => {
          if (prop["is_active"] === "Yes") {
            return (
              <option value={prop["id"]}>{prop["name"]}</option>
            )
          }
          else {
            return (
              <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
            )
          }
        })}
      </Form.Control>
    </Form.Group>
  }


  loadLoopBasedMainTable = () => {
    // let generateTravelerHtml;
    let htmlmodal1, htmlmodal2, htmlmodal3
    let htmlmainbuttons1, htmlmainbuttons2, htmlmainbuttons3, htmlmainbuttons4, htmlmainbuttons5,htmlmainbuttons6,htmlmainbuttons7,htmlmainbuttons8,htmlmainbuttons9;
    let htmlcontent11, htmlcontent12, htmlcontent13, htmlcontent14, htmlcontent15,htmlcontent16,htmlcontent17,htmlcontent18,htmlcontent19;
    let htmlcontent21, htmlcontent22, htmlcontent23, htmlcontent24, htmlcontent25,htmlcontent26,htmlcontent27,htmlcontent28,htmlcontent29;
    let htmlcontent31, htmlcontent32, htmlcontent33, htmlcontent34, htmlcontent35,htmlcontent36,htmlcontent37,htmlcontent38,htmlcontent39;
    let htmlcontent41, htmlcontent42, htmlcontent43, htmlcontent44, htmlcontent45,htmlcontent46,htmlcontent47,htmlcontent48,htmlcontent49;
    let htmlcontent51, htmlcontent52, htmlcontent53, htmlcontent54, htmlcontent55,htmlcontent56,htmlcontent57,htmlcontent58,htmlcontent59;
    let htmlcontent61, htmlcontent62, htmlcontent63, htmlcontent64, htmlcontent65,htmlcontent66,htmlcontent67,htmlcontent68,htmlcontent69;
    let htmlbutton11, htmlbutton12, htmlbutton13, htmlbutton14, htmlbutton15,htmlbutton16,htmlbutton17,htmlbutton18,htmlbutton19;
    let htmlcomments71, htmlcomments72, htmlcomments73, htmlcomments74, htmlcomments75,htmlcomments76,htmlcomments77,htmlcomments78,htmlcomments79;
    let htmlcontent81, htmlcontent82, htmlcontent83, htmlcontent84, htmlcontent85,htmlcontent86,htmlcontent87,htmlcontent88,htmlcontent89;
    let htmlsearchcontact1, htmlsearchcontact2, htmlsearchcontact3, htmlsearchcontact4, htmlsearchcontact5,htmlsearchcontact6,htmlsearchcontact7,htmlsearchcontact8,htmlsearchcontact9;
    let htmlcontent91, htmlcontent92, htmlcontent93, htmlcontent94, htmlcontent95,htmlcontent96,htmlcontent97,htmlcontent98,htmlcontent99;
    let htmlcontent101, htmlcontent102, htmlcontent103, htmlcontent104, htmlcontent105,htmlcontent106,htmlcontent107,htmlcontent108,htmlcontent109;
    let htmllineseparator1,htmllineseparator2,htmllineseparator3,htmllineseparator4,htmllineseparator5,htmllineseparator6,htmllineseparator7,htmllineseparator8,htmllineseparator9;

    let generateTravelerHtml: [];

    let getHotelView1 = this.state.HotelView.filter(f => f.RowId == 1);
    let getHotelView2 = this.state.HotelView.filter(f => f.RowId == 2);
    let getHotelView3 = this.state.HotelView.filter(f => f.RowId == 3);
    let getHotelView4 = this.state.HotelView.filter(f => f.RowId == 4);
    let getHotelView5 = this.state.HotelView.filter(f => f.RowId == 5);
    let getHotelView6 = this.state.HotelView.filter(f => f.RowId == 6);
    let getHotelView7 = this.state.HotelView.filter(f => f.RowId == 7);
    let getHotelView8 = this.state.HotelView.filter(f => f.RowId == 8);
    let getHotelView9 = this.state.HotelView.filter(f => f.RowId == 9);

    let getTAView1 = this.state.TAView.filter(f => f.RowId == 1);
    let getTAView2 = this.state.TAView.filter(f => f.RowId == 2);
    let getTAView3 = this.state.TAView.filter(f => f.RowId == 3);
    let getTAView4 = this.state.TAView.filter(f => f.RowId == 4);
    let getTAView5 = this.state.TAView.filter(f => f.RowId == 5);
    let getTAView6 = this.state.TAView.filter(f => f.RowId == 6);
    let getTAView7 = this.state.TAView.filter(f => f.RowId == 7);
    let getTAView8 = this.state.TAView.filter(f => f.RowId == 8);
    let getTAView9 = this.state.TAView.filter(f => f.RowId == 9);
    // //console.log("getHotelView1 :", getHotelView1)
    ////console.log("getHotelView2 :", getHotelView2)

    {
      getHotelView1.map((prop1, key1) => {

        htmlmodal1 = this.hotelRentalCarModal(prop1, 0);
        htmlmainbuttons1 = this.hotelRentalCarMainButton(prop1, 0);
        htmlcontent11 = this.hotelRentalCarTable11(prop1, 0)
        htmlcontent21 = this.hotelRentalCarTable21(prop1, 0);
        htmlcontent31 = this.hotelRentalCarTable31(prop1, 0);
        htmlcontent41 = this.hotelRentalCarTable41(prop1, 0);
        htmlcontent51 = this.hotelRentalCarTable51(prop1, 0);
        htmlcontent61 = this.hotelRentalCarTable61(prop1, 0);
        htmlbutton11 = this.hotelRentalButtons1(prop1, 0);
        htmlcomments71 = this.hotelRentalCarComments71(prop1, 0);
        htmlsearchcontact1 = this.contactSearchButtons1(prop1, 0);
        htmlcontent91 = this.hotelRentalCarTable91(prop1, 0);
        htmlcontent101 = this.hotelRentalCarTable101(prop1, 0);
        htmllineseparator1 = this.loadLineSeparator();

      })
    }

    {
      getHotelView2.map((prop2, key2) => {

        htmlmodal2 = this.hotelRentalCarModal(prop2, 1);
        htmlmainbuttons2 = this.hotelRentalCarMainButton(prop2, 1);
        htmlcontent12 = this.hotelRentalCarTable11(prop2, 1);
        htmlcontent22 = this.hotelRentalCarTable21(prop2, 1);
        htmlcontent32 = this.hotelRentalCarTable31(prop2, 1);
        htmlcontent42 = this.hotelRentalCarTable41(prop2, 1);
        htmlcontent52 = this.hotelRentalCarTable51(prop2, 1);
        htmlcontent62 = this.hotelRentalCarTable61(prop2, 1);
        htmlbutton12 = this.hotelRentalButtons1(prop2, 1);
        htmlcomments72 = this.hotelRentalCarComments71(prop2, 1);
        htmlsearchcontact2 = this.contactSearchButtons1(prop2, 1);
        htmlcontent92 = this.hotelRentalCarTable91(prop2, 1);
        htmlcontent102 = this.hotelRentalCarTable101(prop2, 1);
        htmllineseparator2 = this.loadLineSeparator();


      })
    }

    {
      getHotelView3.map((prop3, key3) => {

        htmlmodal3 = this.hotelRentalCarModal(prop3, 2);
        htmlmainbuttons3 = this.hotelRentalCarMainButton(prop3, 2);
        htmlcontent13 = this.hotelRentalCarTable11(prop3, 2);
        htmlcontent23 = this.hotelRentalCarTable21(prop3, 2);
        htmlcontent33 = this.hotelRentalCarTable31(prop3, 2);
        htmlcontent43 = this.hotelRentalCarTable41(prop3, 2);
        htmlcontent53 = this.hotelRentalCarTable51(prop3, 2);
        htmlcontent63 = this.hotelRentalCarTable61(prop3, 2);
        htmlbutton13 = this.hotelRentalButtons1(prop3, 2);
        htmlcomments73 = this.hotelRentalCarComments71(prop3, 2);
        htmlsearchcontact3 = this.contactSearchButtons1(prop3, 2);
        htmlcontent93 = this.hotelRentalCarTable91(prop3, 2);
        htmlcontent103 = this.hotelRentalCarTable101(prop3, 2);
        htmllineseparator3 = this.loadLineSeparator();

      })
    }

    {
      getHotelView4.map((prop4, key4) => {

        htmlmainbuttons4 = this.hotelRentalCarMainButton(prop4, 3);
        htmlcontent14 = this.hotelRentalCarTable11(prop4, 3);
        htmlcontent24 = this.hotelRentalCarTable21(prop4, 3);
        htmlcontent34 = this.hotelRentalCarTable31(prop4, 3);
        htmlcontent44 = this.hotelRentalCarTable41(prop4, 3);
        htmlcontent54 = this.hotelRentalCarTable51(prop4, 3);
        htmlcontent64 = this.hotelRentalCarTable61(prop4, 3);
        htmlbutton14 = this.hotelRentalButtons1(prop4, 3);
        htmlcomments74 = this.hotelRentalCarComments71(prop4, 3);
        htmlsearchcontact4 = this.contactSearchButtons1(prop4, 3);
        htmlcontent94 = this.hotelRentalCarTable91(prop4, 3);
        htmlcontent104 = this.hotelRentalCarTable101(prop4, 3);
        htmllineseparator4 = this.loadLineSeparator();
      })
    }

    {
      getHotelView5.map((prop5, key5) => {

        htmlmainbuttons5 = this.hotelRentalCarMainButton(prop5, 4);
        htmlcontent15 = this.hotelRentalCarTable11(prop5, 4);
        htmlcontent25 = this.hotelRentalCarTable21(prop5, 4);
        htmlcontent35 = this.hotelRentalCarTable31(prop5, 4);
        htmlcontent45 = this.hotelRentalCarTable41(prop5, 4);
        htmlcontent55 = this.hotelRentalCarTable51(prop5, 4);
        htmlcontent65 = this.hotelRentalCarTable61(prop5, 4);
        htmlbutton15 = this.hotelRentalButtons1(prop5, 4);
        htmlcomments75 = this.hotelRentalCarComments71(prop5, 4);
        htmlsearchcontact5 = this.contactSearchButtons1(prop5, 4);
        htmlcontent95 = this.hotelRentalCarTable91(prop5, 4);
        htmlcontent105 = this.hotelRentalCarTable101(prop5, 4);
        htmllineseparator5 = this.loadLineSeparator();
      })
    }

    {
      getHotelView6.map((prop6, key6) => {

        htmlmainbuttons6 = this.hotelRentalCarMainButton(prop6, 5);
        htmlcontent16 = this.hotelRentalCarTable11(prop6, 5);
        htmlcontent26 = this.hotelRentalCarTable21(prop6, 5);
        htmlcontent36 = this.hotelRentalCarTable31(prop6, 5);
        htmlcontent46 = this.hotelRentalCarTable41(prop6, 5);
        htmlcontent56 = this.hotelRentalCarTable51(prop6, 5);
        htmlcontent66 = this.hotelRentalCarTable61(prop6, 5);
        htmlbutton16 = this.hotelRentalButtons1(prop6, 5);
        htmlcomments76 = this.hotelRentalCarComments71(prop6, 5);
        htmlsearchcontact6 = this.contactSearchButtons1(prop6, 5);
        htmlcontent96 = this.hotelRentalCarTable91(prop6, 5);
        htmlcontent106 = this.hotelRentalCarTable101(prop6, 5);
        htmllineseparator6 = this.loadLineSeparator();
      })
    }
    {
      getHotelView7.map((prop7, key7) => {

        htmlmainbuttons7 = this.hotelRentalCarMainButton(prop7, 6);
        htmlcontent17 = this.hotelRentalCarTable11(prop7, 6);
        htmlcontent27 = this.hotelRentalCarTable21(prop7, 6);
        htmlcontent37 = this.hotelRentalCarTable31(prop7, 6);
        htmlcontent47 = this.hotelRentalCarTable41(prop7, 6);
        htmlcontent57 = this.hotelRentalCarTable51(prop7, 6);
        htmlcontent67 = this.hotelRentalCarTable61(prop7, 6);
        htmlbutton17 = this.hotelRentalButtons1(prop7, 6);
        htmlcomments77 = this.hotelRentalCarComments71(prop7, 6);
        htmlsearchcontact7 = this.contactSearchButtons1(prop7, 6);
        htmlcontent97 = this.hotelRentalCarTable91(prop7, 6);
        htmlcontent107 = this.hotelRentalCarTable101(prop7, 6);
        htmllineseparator7 = this.loadLineSeparator();
      })
    }
    {
      getHotelView8.map((prop8, key8) => {

        htmlmainbuttons8 = this.hotelRentalCarMainButton(prop8, 7);
        htmlcontent18 = this.hotelRentalCarTable11(prop8, 7);
        htmlcontent28 = this.hotelRentalCarTable21(prop8, 7);
        htmlcontent38 = this.hotelRentalCarTable31(prop8, 7);
        htmlcontent48 = this.hotelRentalCarTable41(prop8, 7);
        htmlcontent58 = this.hotelRentalCarTable51(prop8, 7);
        htmlcontent68 = this.hotelRentalCarTable61(prop8, 7);
        htmlbutton18 = this.hotelRentalButtons1(prop8, 7);
        htmlcomments78 = this.hotelRentalCarComments71(prop8, 7);
        htmlsearchcontact8 = this.contactSearchButtons1(prop8, 7);
        htmlcontent98 = this.hotelRentalCarTable91(prop8, 7);
        htmlcontent108 = this.hotelRentalCarTable101(prop8, 7);
        htmllineseparator8 = this.loadLineSeparator();
      })
    }
    {
      getHotelView9.map((prop9, key9) => {

        htmlmainbuttons9 = this.hotelRentalCarMainButton(prop9, 8);
        htmlcontent19 = this.hotelRentalCarTable11(prop9, 8);
        htmlcontent29 = this.hotelRentalCarTable21(prop9, 8);
        htmlcontent39 = this.hotelRentalCarTable31(prop9, 8);
        htmlcontent49 = this.hotelRentalCarTable41(prop9, 8);
        htmlcontent59 = this.hotelRentalCarTable51(prop9, 8);
        htmlcontent69 = this.hotelRentalCarTable61(prop9, 8);
        htmlbutton19 = this.hotelRentalButtons1(prop9, 8);
        htmlcomments79 = this.hotelRentalCarComments71(prop9, 8);
        htmlsearchcontact9 = this.contactSearchButtons1(prop9, 8);
        htmlcontent99 = this.hotelRentalCarTable91(prop9, 8);
        htmlcontent109 = this.hotelRentalCarTable101(prop9, 8);
        htmllineseparator9 = this.loadLineSeparator();
      })
    }


    let prevpage = this.props.location.state.from;
    let htmlcontent;

    if (prevpage == 'directbillincoming') {
      {
        getTAView1.map((prop1, key1) => {

          htmlcontent81 = this.hotelRentalCarTable81(prop1, key1);

        })
      }
      {
        getTAView2.map((prop2, key2) => {

          htmlcontent82 = this.hotelRentalCarTable81(prop2, key2);

        })
      }
      {
        getTAView3.map((prop3, key3) => {

          htmlcontent83 = this.hotelRentalCarTable81(prop3, key3);

        })
      }
      {
        getTAView4.map((prop4, key4) => {

          htmlcontent84 = this.hotelRentalCarTable81(prop4, key4);

        })
      }
      {
        getTAView5.map((prop5, key5) => {

          htmlcontent85 = this.hotelRentalCarTable81(prop5, key5);

        })
      }
      {
        getTAView6.map((prop6, key6) => {

          htmlcontent86 = this.hotelRentalCarTable81(prop6, key6);

        })
      }
      {
        getTAView7.map((prop7, key7) => {

          htmlcontent87 = this.hotelRentalCarTable81(prop7, key7);

        })
      }
      {
        getTAView8.map((prop8, key8) => {

          htmlcontent88 = this.hotelRentalCarTable81(prop8, key8);

        })
      }
      {
        getTAView9.map((prop9, key9) => {

          htmlcontent89 = this.hotelRentalCarTable81(prop9, key9);

        })
      }
    }
    else {
      htmlcontent = this.hotelRentalCarTable8();
    }
   
   // if (prevpage == 'directbillincoming') {
    return ([htmlmodal1, htmlmodal2, htmlmodal3, htmlmainbuttons1, htmlcontent11, htmlcontent21, htmlcontent31, htmlcontent41, htmlcontent51, htmlcontent61, htmlbutton11, htmlcomments71, htmlcontent81, htmlcontent, htmlsearchcontact1, htmlcontent91, htmlcontent101,htmllineseparator1,
             htmlmainbuttons2, htmlcontent12, htmlcontent22, htmlcontent32, htmlcontent42, htmlcontent52, htmlcontent62, htmlbutton12, htmlcomments72, htmlcontent82, htmlsearchcontact2, htmlcontent92, htmlcontent102,htmllineseparator2,
             htmlmainbuttons3, htmlcontent13, htmlcontent23, htmlcontent33, htmlcontent43, htmlcontent53, htmlcontent63, htmlbutton13, htmlcomments73, htmlcontent83, htmlsearchcontact3, htmlcontent93, htmlcontent103,htmllineseparator3,
             htmlmainbuttons4, htmlcontent14, htmlcontent24, htmlcontent34, htmlcontent44, htmlcontent54, htmlcontent64, htmlbutton14, htmlcomments74, htmlcontent84, htmlsearchcontact4, htmlcontent94, htmlcontent104,htmllineseparator4,
             htmlmainbuttons5, htmlcontent15, htmlcontent25, htmlcontent35, htmlcontent45, htmlcontent55, htmlcontent65, htmlbutton15, htmlcomments75, htmlcontent85, htmlsearchcontact5, htmlcontent95, htmlcontent105,htmllineseparator5,
             htmlmainbuttons6, htmlcontent16, htmlcontent26, htmlcontent36, htmlcontent46, htmlcontent56, htmlcontent66, htmlbutton16, htmlcomments76, htmlcontent86, htmlsearchcontact6, htmlcontent96, htmlcontent106 ,htmllineseparator6,
             htmlmainbuttons7, htmlcontent17, htmlcontent27, htmlcontent37, htmlcontent47, htmlcontent57, htmlcontent67, htmlbutton17, htmlcomments77, htmlcontent87, htmlsearchcontact7, htmlcontent97, htmlcontent107 ,htmllineseparator7,
             htmlmainbuttons8, htmlcontent18, htmlcontent28, htmlcontent38, htmlcontent48, htmlcontent58, htmlcontent68, htmlbutton18, htmlcomments78, htmlcontent88, htmlsearchcontact8, htmlcontent98, htmlcontent108,htmllineseparator8,
             htmlmainbuttons9, htmlcontent19, htmlcontent29, htmlcontent39, htmlcontent49, htmlcontent59, htmlcontent69, htmlbutton19, htmlcomments79, htmlcontent89, htmlsearchcontact9, htmlcontent99, htmlcontent109,htmllineseparator9,
            ]);
         /* }
          else
          {
            return ([htmlmodal1, htmlmodal2, htmlmodal3, htmlmainbuttons1, htmlcontent11, htmlcontent21, htmlcontent31, htmlcontent41, htmlcontent51, htmlcontent61, htmlbutton11, htmlcomments71, htmlcontent, htmlcontent, htmlsearchcontact1, htmlcontent91, htmlcontent101,htmllineseparator1
              ]);
          }*/

  }

  hotelRentalCarTable21 = (prop, key) => {
    let sbookings = this.props.location.state.sbookings;
    return <Row>
      <Col sm={3}>
      </Col>
      <Col sm={9}>
        <div >
          <Table>
            <thead >
              <tr>
                <th style={{ backgroundColor: '#ffd51d' }}>  </th>
                {sbookings === 'Hotel' ?
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Check In </th>
                  :
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Pick Up </th>
                }
                {sbookings === 'Hotel' ?
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Check Out </th>
                  :
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Return </th>
                }

                <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}>Special Charges</th>
                <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}>Special Charges Amount</th>
                <th style={{ backgroundColor: '#ffd51d' }}></th>
              </tr>
            </thead>
            <tbody>
              <tr key={key}>
                <td> </td>
                <td> {Utils.FormatDate(prop["in_date"])}</td>

                <td> {Utils.FormatDate(prop["out_date"])}</td>
                <td> {prop["sc_description"]}</td>
                {
                  (this.state.fields["sc_amount"][key] > 0)
                    ?
                    <td> ${prop["sc_amount"][key]} </td>
                    :
                    <td> None </td>
                }

                <td> </td>
              </tr>

            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  loadLineSeparator = () => {
    return <Row>
       <Col sm={12}>
       <div style={{ marginBottom: "15px", borderTop: "4px solid #4169E1 ", marginLeft: 1, marginRight: 1 }}></div>
       </Col>
      </Row>
   
  } 

  hotelRentalCarTable2 = () => {
    let sbookings = this.props.location.state.sbookings;
    return <Row>
      <Col sm={3}>
      </Col>
      <Col sm={9}>
        <div >
          <Table>
            <thead >
              <tr>
                <th style={{ backgroundColor: '#ffd51d' }}>  </th>
                {sbookings === 'Hotel' ?
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Check In </th>
                  :
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Pick Up </th>
                }
                {sbookings === 'Hotel' ?
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Check Out </th>
                  :
                  <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}> Return </th>
                }

                <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}>Special Charges</th>
                <th style={{ backgroundColor: '#ffd51d', color: '#000000' }}>Special Charges Amount</th>
                <th style={{ backgroundColor: '#ffd51d' }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.HotelView.map((prop, key) => {
                //
                return (
                  <tr key={key}>
                    <td> </td>
                    <td> {Utils.FormatDate(prop["in_date"])}</td>

                    <td> {Utils.FormatDate(prop["out_date"])}</td>
                    <td> {prop["sc_description"]}</td>
                    {
                      (this.state.fields["sc_amount"] > 0)
                        ?
                        <td> ${prop["sc_amount"]} </td>
                        :
                        <td> None </td>
                    }

                    <td> </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarTable31 = (prop, key) => {
    return <Row>
      <Col sm={3}>
      </Col>
      <Col sm={9}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
              <tr>
                <th>  </th>
                <th> Traveler </th>
                <th> Alias </th>
                <th>Confirmation </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr key={key}>
                <td> </td>
                <td> {prop["traveler"]}</td>
                {
                  (this.state.fields['bSaveChange1'][key])
                    ?
                    <td>
                      <Form.Control type="text" id="txtAlias" name="txtAlias" maxLength={50} size="sm" className="form-control-sm" value={this.state.fields.txtAlias11[key]} onChange={(e) => this.handleChange(e, key)} >
                      </Form.Control>
                    </td>
                    :
                    <td>
                      {prop["alias"]}
                    </td>
                }
                <td> {prop["confirmation_number"]}</td>
                {
                  (this.state.fields['db1'][key])
                    ?
                    <td>
                      <Button disabled={this.state.fields.btnCandis1[key]} name='cancel' style={{ marginRight: "5px", color: this.state.fields.btnStyle1[key] }} onClick={(e) => this.handlebtnInvoiceCancel(e)} title={this.state.fields.btnCantit1[key]}>
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span> {this.state.fields["btnCanVal1"][key]}  </span>}
                      </Button>
                    </td>
                    :
                    <td> </td>
                }
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>


  }

  hotelRentalCarTable3 = () => {
    return <Row>
      <Col sm={3}>
      </Col>
      <Col sm={9}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
              <tr>
                <th>  </th>
                <th> Traveler </th>
                <th> Alias </th>
                <th>Confirmation </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.HotelView.map((prop, key) => {
                //
                return (
                  <tr key={key}>
                    <td> </td>
                    <td> {prop["traveler"]}</td>
                    {
                      (this.state.fields['bSaveChange'])
                        ?
                        <td>
                          <Form.Control type="text" id="txtAlias" name="txtAlias" maxLength={50} size="sm" className="form-control-sm" value={this.state.fields.txtAlias} onChange={(e) => this.handleChange(e, key)}  >
                          </Form.Control>
                        </td>
                        :
                        <td>
                          {prop["alias"]}
                        </td>
                    }
                    <td> {prop["confirmation_number"]}</td>
                    {
                      (this.state.fields['db'])
                        ?
                        <td>
                          <Button disabled={this.state.fields.btnCandis} name='cancel' style={{ marginRight: "5px", color: this.state.fields.btnStyle }} onClick={(e) => this.handlebtnInvoiceCancel(e)} title={this.state.fields.btnCantit}>
                            {this.state.loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color: "#3472F7" }}
                              />
                            )}
                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!this.state.loading && <span> {this.state.fields["btnCanVal"]}  </span>}
                          </Button>
                        </td>
                        :
                        <td> </td>
                    }
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>


  }

  hotelRentalCarTable91 = (prop, key) => {
    let sbookings = this.props.location.state.sbookings;
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: '#00B0F0', fontSize: 'large' }}>
              <tr>
                {sbookings === 'Hotel' ?
                  <th> Hotel </th>
                  :
                  <th> Car Company </th>
                }
                <th> City </th>
                <th> Dept </th>
                <th>Attn</th>
                <th>Agent</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr key={key} >
                <td> {prop["name"]}</td>
                <td> {prop["db_city"]}</td>
                <td> {prop["dept"]}</td>
                <td> {prop["attn"]}</td>
                <td> {prop["agent"]} </td>
                <td> {(prop["updated_date"]) ? Utils.FormatDate(prop["updated_date"]) : ''}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarTable9 = () => {
    let sbookings = this.props.location.state.sbookings;
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: '#00B0F0', fontSize: 'large' }}>
              <tr>
                {sbookings === 'Hotel' ?
                  <th> Hotel </th>
                  :
                  <th> Car Company </th>
                }
                <th> City </th>
                <th> Dept </th>
                <th>Attn</th>
                <th>Agent</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {this.state.HotelView.map((prop, key) => {
                //
                return (
                  <tr key={key} >
                    <td> {prop["name"]}</td>
                    <td> {prop["db_city"]}</td>
                    <td> {prop["dept"]}</td>
                    <td> {prop["attn"]}</td>
                    <td> {prop["agent"]} </td>
                    <td> {(prop["updated_date"]) ? Utils.FormatDate(prop["updated_date"]) : ''}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarTable101 = (prop, key) => {
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: '#00B0F0' }}>
              <tr>
                <th> Phone </th>
                <th> Fax </th>
                <th>   <FormCheckBox properties={{ label: "Centrally Billed", id: "prepaid", name: "prepaid", type: "checkbox", onChange: "", checked: true }} /> </th>
                <th> </th>
                <th> </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <tr key={key} >
                <td> {prop["phone"]}</td>
                <td> {prop["fax"]}</td>
                <td> </td>
                <td> </td>
                <td>  </td>
                <td> </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>      
    </Row>
    
  }

  hotelRentalCarTable10 = () => {
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: '#00B0F0' }}>
              <tr>
                <th> Phone </th>
                <th> Fax </th>
                <th>   <FormCheckBox properties={{ label: "Centrally Billed", id: "prepaid", name: "prepaid", type: "checkbox", onChange: "", checked: true }} /> </th>
                <th> </th>
                <th> </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {this.state.HotelView.map((prop, key) => {
                //
                return (
                  <tr key={key} >
                    <td> {prop["phone"]}</td>
                    <td> {prop["fax"]}</td>
                    <td> </td>
                    <td> </td>
                    <td>  </td>
                    <td> </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarTable41 = (prop, key) => {
    //console.log(this.state.fields["db"])
    let sbookings = this.props.location.state.sbookings;
    let prevpage = this.props.location.state.from;
    if (prevpage != 'directbillincoming')
    //if (this.state.fields.prevbox == "0")
    {
      if (this.state.fields["db1"][key]) {
        return <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>
            <div style={{ width: '100%' }}>
              <Table hover>
                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                  <tr>
                    <th></th>
                    <th> Invoice/Folio # </th>
                    {sbookings === 'Hotel' ?
                      <th> Folio Check In </th>
                      :
                      <th> Folio Pick Up </th>
                    }
                    {sbookings === 'Hotel' ?
                      <th>Folio Check Out </th>
                      :
                      <th>Folio Return </th>
                    }
                    <th>Total Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={key}>
                    <td> </td>
                    {
                      (this.state.fields['bSaveChange1'][key])
                        ?
                        <td>
                          <Form.Control type="text" id="txtInvoice" name="txtInvoice" maxLength={50} size="sm" className={(this.state.errors.invoice)
                            ? "form-control is-invalid"
                            : "form-control"
                          } value={this.state.fields.txtInvoice1[key]} onChange={(e) => this.handleChange(e, key)} >
                          </Form.Control>
                          <div className={(this.state.errors.invoiceh) ? "d-lg-none" : "text-danger"}>
                            Please enter Invoice/Folio #
                          </div>
                        </td>
                        :
                        <td> {prop["invoice_number"]}</td>
                    }
                    {
                      (this.state.fields['bSaveChange1'][key])
                        ?
                        <td>
                          <DatePicker style={{ fontSize: "12px" }}
                            Id="startDate"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStartDate}
                            isClearable
                            showMonthDropdown
                            showYearDropdown
                            className="datePicker" />
                        </td>
                        :
                        <td> {Utils.FormatDate(prop["begin_date"])}</td>
                    }
                    {
                      (this.state.fields['bSaveChange1'][key])
                        ?
                        <td>
                          <DatePicker style={{ fontSize: "12px" }}
                            Id="endDate"
                            selected={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                            isClearable
                            showMonthDropdown
                            showYearDropdown
                            className="datePicker" />

                        </td>
                        :
                        <td> {Utils.FormatDate(prop["end_date"])}</td>
                    }
                    {
                      (this.state.fields['bSaveChange1'][key])
                        ?
                        <td>
                          <Form.Control type="text" id="txtTotAmt" name="txtTotAmt" size="sm" maxLength={10} className="form-control-sm" value={this.state.fields.txtTotAmt1[key]} onChange={(e) => this.handleChange(e, key)} >
                          </Form.Control>
                        </td>
                        :
                        <td> ${prop["invoice_amount"][key]} </td>
                    }

                    <td> </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      }
    }

  }
  hotelRentalCarTable4 = () => {
    //console.log(this.state.fields["db"])
    let sbookings = this.props.location.state.sbookings;
    if (this.state.fields.prevbox == "0") {
      if (this.state.fields["db"]) {
        return <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>
            <div style={{ width: '100%' }}>
              <Table hover>
                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                  <tr>
                    <th></th>
                    <th> Invoice/Folio # </th>
                    {sbookings === 'Hotel' ?
                      <th> Folio Check In </th>
                      :
                      <th> Folio Pick Up </th>
                    }
                    {sbookings === 'Hotel' ?
                      <th>Folio Check Out </th>
                      :
                      <th>Folio Return </th>
                    }
                    <th>Total Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.HotelView.map((prop, key) => {
                    //
                    return (
                      <tr key={key}>
                        <td> </td>
                        {
                          (this.state.fields['bSaveChange'])
                            ?
                            <td>
                              <Form.Control type="text" id="txtInvoice" name="txtInvoice" size="sm" maxLength={50} className={(this.state.errors.invoice)
                                ? "form-control is-invalid"
                                : "form-control"
                              } value={this.state.fields.txtInvoice} onChange={(e) => this.handleChange(e, key)}  >
                              </Form.Control>
                              <div className={(this.state.errors.invoiceh) ? "d-lg-none" : "text-danger"}          >
                                Please enter Invoice/Folio #
                              </div>
                            </td>
                            :
                            <td> {prop["invoice_number"]}</td>
                        }
                        {
                          (this.state.fields['bSaveChange'])
                            ?
                            <td>
                              <DatePicker style={{ fontSize: "12px" }}
                                Id="startDate"
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown
                                className="datePicker" />
                            </td>
                            :
                            <td> {Utils.FormatDate(prop["begin_date"])}</td>
                        }
                        {
                          (this.state.fields['bSaveChange'])
                            ?
                            <td>
                              <DatePicker style={{ fontSize: "12px" }}
                                Id="endDate"
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                isClearable
                                showMonthDropdown
                                showYearDropdown
                                className="datePicker" />

                            </td>
                            :
                            <td> {Utils.FormatDate(prop["end_date"])}</td>
                        }
                        {
                          (this.state.fields['bSaveChange'])
                            ?
                            <td>
                              <Form.Control type="text" id="txtTotAmt" name="txtTotAmt" size="sm" maxLength={10} className="form-control-sm" value={this.state.fields.txtTotAmt} onChange={(e) => this.handleChange(e, key)}  >
                              </Form.Control>
                            </td>
                            :
                            <td> ${prop["invoice_amount"]} </td>
                        }

                        <td> </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      }
    }

  }
  hotelRentalCarTable51 = (prop, key) => {
    let prevpage = this.props.location.state.from;
    if (prevpage != 'directbillincoming')
    //if (this.state.fields.prevbox == "0")
    {
      if (this.state.fields["db1"][key]) {
        return <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>
            <div style={{ width: '100%' }}>
              <Table >
                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                  <tr>

                    <th style={{ paddingLeft: "40px" }}> Vendor # </th>
                    <th> Comments </th>
                    <th colSpan={4}></th>

                  </tr>
                </thead>
                <tbody>
                  <tr key={key}>
                    {
                      (this.state.fields['bSaveChange1'][key])
                        ? <td>
                          <Form.Control type="text" id="txtVendor" name="txtVendor" size="sm" maxLength={20} className={(this.state.errors.vendor)
                            ? "form-control is-invalid"
                            : "form-control"
                          }
                            value={this.state.fields.txtVendor1[key]} onChange={(e) => this.handleChange(e, key)}>
                          </Form.Control>
                          <div className={(this.state.errors.vendorh) ? "d-lg-none" : "text-danger"}>
                            Please enter Vendor #
                          </div>
                        </td>
                        :
                        <td style={{ paddingLeft: "40px" }}> {prop["vendor_number"]}</td>
                    }

                    {
                      (this.state.fields['bSaveChange1'][key])
                        ?
                        <td><Form.Control type="textarea" id="txtComments" name="txtComments" size="sm" maxLength={300} className="form-control-sm" value={this.state.fields.txtComments1[key]} onChange={(e) => this.handleChange(e, key)}>
                        </Form.Control></td>
                        :
                        <td> {prop["comments"]} </td>
                    }
                    <td colSpan={3}> </td>
                    <td style={{ color: 'purple' }}> Total: ${prop["invoice_amount"]} </td>
                  </tr>

                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

      }
    }
  }
  hotelRentalCarTable5 = () => {
    if (this.state.fields.prevbox == "0") {
      if (this.state.fields["db"]) {
        return <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>
            <div style={{ width: '100%' }}>
              <Table >
                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                  <tr>

                    <th style={{ paddingLeft: "40px" }}> Vendor # </th>
                    <th> Comments </th>
                    <th colSpan={4}></th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.HotelView.map((prop, key) => {
                    //
                    return (
                      <tr key={key}>
                        {
                          (this.state.fields['bSaveChange'])
                            ? <td>
                              <Form.Control type="text" id="txtVendor" name="txtVendor" size="sm" maxLength={20} className="form-control-sm" value={this.state.fields.txtVendor} onChange={(e) => this.handleChange(e, key)} >
                              </Form.Control></td>
                            :
                            <td style={{ paddingLeft: "40px" }}> {prop["vendor_number"]}</td>
                        }

                        {
                          (this.state.fields['bSaveChange'])
                            ?
                            <td><Form.Control type="textarea" id="txtComments" name="txtComments" maxLength={300} size="sm" className="form-control-sm" value={this.state.fields.txtComments} onChange={(e) => this.handleChange(e, key)} >
                            </Form.Control></td>
                            :
                            <td> {prop["comments"]} </td>
                        }
                        <td colSpan={3}> </td>
                        <td style={{ color: 'purple' }}> Total: ${prop["invoice_amount"]} </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

      }
    }
  }
  hotelRentalCarTable61 = (prop, key) => {
    let prevpage = this.props.location.state.from;
    if (prevpage != 'directbillincoming')
    //if (this.state.fields.prevbox == "0")
    {
      if (this.state.fields["db1"][key]) {
        return <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>
            <div style={{ width: '100%' }}>
              <Table >
                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                  <tr>
                    <th style={{ paddingLeft: "40px" }}> Check Number </th>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={key}>
                    {
                      (!this.state.fields['check_number1'][key])
                        ?
                        <td style={{ paddingLeft: "40px" }}>
                          <Form.Control type="text" maxLength={20} id="txtCheckNo" name="txtCheckNo" size="sm" style={{ width: '200px' }}
                            className={(this.state.errors.checkno)
                              ? "form-control is-invalid"
                              : "form-control"
                            } value={this.state.fields.txtCheckNo1[key]} onChange={(e) => this.handleChange(e, key)}   >
                          </Form.Control>
                          <div className={(this.state.errors.checknoh) ? "d-lg-none" : "text-danger"}>
                            Please enter Check Number #
                          </div>
                        </td>
                        :
                        <td> {prop["check_number"]} </td>
                    }
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>

                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

      }
    }
  }

  hotelRentalCarTable6 = () => {
    if (this.state.fields.prevbox == "0") {
      if (this.state.fields["db"]) {
        return <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>
            <div style={{ width: '100%' }}>
              <Table >
                <thead style={{ backgroundColor: 'yello', fontSize: 'large' }}>
                  <tr>
                    <th style={{ paddingLeft: "40px" }}> Check Number </th>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.HotelView.map((prop, key) => {
                    //
                    return (
                      <tr key={key}>
                        {
                          (!this.state.fields['check_number'])
                            ?
                            <td style={{ paddingLeft: "40px" }}>
                              <Form.Control type="text" maxLength={20} id="txtCheckNo" name="txtCheckNo"
                                size="sm" className="form-control input-sm" value={this.state.fields.txtCheckNo} onChange={(e) => this.handleChange(e, key)}  >
                              </Form.Control>
                            </td>
                            :
                            <td> {prop["check_number"]} </td>
                        }
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

      }
    }
  }

  hotelRentalCarComments71 = (prop, key) => {
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <tbody>
              <tr>
                <td>
                  {prop["save_invoice_by"] ? prop["save_invoice_by"] + ' Saved Invoice ' + Utils.FormatDateTime(prop["save_invoice_date"])+ ' PST' : ''}
                </td>
                <td>
                  {prop["submitted_to_log_by"] ? (prop["submitted_to_log"] ? prop["submitted_to_log_by"] + ' Submitted to Log ' : prop["submitted_to_log_by"] + ' UnSubmitted to Log ' )   + Utils.FormatDateTime(prop["submitted_to_log_date"]) + ' PST' : ''}
                </td>
                <td>
                  {prop["submitted_payment_by"] ? (prop["submitted_payment"] ? prop["submitted_payment_by"] + ' Submitted for Payment  ' : prop["submitted_payment_by"] + ' UnSubmitted for Payment  ' )  + Utils.FormatDateTime(prop["submitted_payment_date"]) + ' PST' : ''}
                </td>
                <td>
                  {prop["check_number_by"] ? (prop["check_number"] ? prop["check_number_by"] + ' Saved the Check Number ' : prop["check_number_by"] + ' UnSaved the Check Number ' )  + Utils.FormatDateTime(prop["check_number_date"]) + ' PST' : ''}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarComments7 = () => {
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <tbody>
              {this.state.HotelView.map((prop, key) => {
                //
                return (
                  <tr >
                    <td>
                      {prop["save_invoice_by"] ? prop["save_invoice_by"] + ' Saved Invoice ' + Utils.FormatDateTime(prop["save_invoice_date"]) : ''}
                    </td>
                    <td>
                      {prop["submitted_to_log_by"] ? prop["submitted_to_log_by"] + ' Submitted to Log ' + Utils.FormatDateTime(prop["submitted_to_log_date"]) : ''}
                    </td>
                    <td>
                      {prop["submitted_payment_by"] ? prop["submitted_payment_by"] + ' Submitted to Log ' + Utils.FormatDateTime(prop["submitted_payment_date"]) : ''}
                    </td>
                    <td>
                      {prop["check_number_by"] ? prop["check_number_by"] + ' Submitted to Log' + Utils.FormatDateTime(prop["check_number_date"]) : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }


  hotelRentalCarTable81 = (prop, key) => {
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: '#00B0F0', fontSize: 'large' }}>
              <tr>
                <th> Profit Center </th>
                <th> Cost Center </th>
                <th> GL Account # </th>
                <th>WBS </th>
                <th>Employee/Project # </th>
                <th>PO # </th>
                <th>PO Line</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr key={key} >
                <td> {prop["business_unit"]}</td>
                <td> {prop["cost_center"]}</td>
                <td> {prop["object_account"]} </td>
                <td> {prop["sub_account"]} </td>
                <td> {prop["project_emp_number"]}  </td>
                <td> {prop["cts_number"]} </td>
                <td> {prop["po_line"]} </td>
                <td> ${prop["amount"]} </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>

  }

  hotelRentalCarTable8 = () => {
    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <thead style={{ backgroundColor: '#00B0F0', fontSize: 'large' }}>
              <tr>
                <th> Profit Center </th>
                <th> Cost Center </th>
                <th> GL Account # </th>
                <th>WBS </th>
                <th>Employee/Project # </th>
                <th>PO # </th>
                <th>PO Line</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {this.state.TAView.map((prop, key) => {
                //
                return (
                  <tr key={key} >
                    <td> {prop["business_unit"]}</td>
                    <td> {prop["cost_center"]}</td>
                    <td> {prop["object_account"]} </td>
                    <td> {prop["sub_account"]} </td>
                    <td> {prop["project_emp_number"]}  </td>
                    <td> {prop["cts_number"]} </td>
                    <td> {prop["po_line"]} </td>
                    <td> ${prop["amount"]} </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>

  }

  displayContact = () => {
    let modal = this.state.modal;
    //modal['modalSpinnerShow'] = true;

    return this.displayContactList()

    modal['modalSpinnerShow'] = false;
    this.setState({ modal });


  }

  displayContact1 = (prop, key) => {
    let modal = this.state.modal;
    //modal['modalSpinnerShow'] = true;

    // this.setState({ fields }); 

   // return this.displayContactList1(prop, key)

   let fields = this.state.fields;
    //alert(key)
    // fields["dropContactdis1"][key] = true;  
    // modal['modalSpinnerShow'] = true;
    return <Form.Control as="select" size="lg" disabled={this.state.fields.dropContactdis1[key]} id={'contactList1'[key]}  name={'contactList1'[key]} value={this.state.fields.contact_id2[key]} style={{ padding: "0px" }} onChange={event => this.handleSelectChange1(event,key)} className="form-control" >
      <option value="0">Please Search for {(this.state.fields.travel_type == 'HTL') ? " Hotel ..." : " Rental Car..."}  </option>
      {this.state.ConListView.map((item) => {
        return (
          <option value={item["contact_id"]}>{item["name"]}</option>
        )
      })}

    </Form.Control> 


    modal['modalSpinnerShow'] = false;
    this.setState({ modal });
  }

  displayContactList1 = (prop, key) => {
    let modal = this.state.modal;
    let fields = this.state.fields;
    //alert(key)
    // fields["dropContactdis1"][key] = true;  
    // modal['modalSpinnerShow'] = true;
    return <Form.Control as="select" size="lg" disabled={this.state.fields.dropContactdis1[key]} id={'contactList'} name={'contactList'} value={this.state.fields.contact_id1} style={{ padding: "0px" }} onChange={event => this.handleSelectChange1(event,key)} className="form-control" >
      <option value="0">Please Search for {(this.state.fields.travel_type == 'HTL') ? " Hotel ..." : " Rental Car..."}  </option>
      {this.state.ConListView.map((item) => {
        return (
          <option value={item["contact_id"]}>{item["name"]}</option>
        )
      })}

    </Form.Control>
  }


  displayContactList = () => {
    let modal = this.state.modal;
    // modal['modalSpinnerShow'] = true;
    return <Form.Control as="select" size="lg" disabled={this.state.fields.dropContactdis} id="contactList" name="contactList" value={this.state.fields.contact_id} style={{ padding: "0px" }} onChange={this.handleSelectChange} className="form-control" >
      <option value="0">Please Search for {(this.state.fields.travel_type == 'HTL') ? " Hotel ..." : " Rental Car..."}  </option>
      {this.state.ConListView.map((item) => {
        return (
          <option value={item["contact_id"]}>{item["name"]}</option>
        )

      })}

    </Form.Control>

  }
  displayContactSearch1 = (key1) => {

    return <div id="divContactSearch" style={{ paddingLeft: '40px', width: '100%', marginBottom: '0px' }} >
      <Row>
        <Col sm={8}>
          <div>
            <Table hover>
              <tbody>
                <tr >
                  <td>
                    Search:
                  </td>
                  <td>
                    <div><Form.Control type="text" id="txtSearch" name="txtSearch" className="form-control-sm" value={this.state.fields.txtSearch1[key1]} onChange={(e) => this.handleChange(e, key1)}  >
                    </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr >
                  <td>
                    <Button disabled={this.state.loading} name='btnok' style={{ marginRight: "5px" }} onClick={(e) => this.handleContactList(e, e.currentTarget.name, key1)} title="Click to Search">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>OK</span>}
                    </Button>
                  </td>
                  <td>
                    <Button disabled={this.state.loading} name='print' style={{ marginRight: "5px" }} onClick={(e) => this.handleModalSearchClose(e)} title="Click to Print">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Cancel</span>}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  }

  displayContactSearch = () => {
    return <div id="divContactSearch" style={{ paddingLeft: '40px', width: '100%', marginBottom: '0px' }} >
      <Row>
        <Col sm={8}>
          <div>
            <Table hover>
              <tbody>
                <tr >
                  <td>
                    Search:
                  </td>
                  <td>
                    <div><Form.Control type="text" id="txtSearch" name="txtSearch" className="form-control-sm" value={this.state.fields.txtSearch}   >
                    </Form.Control>
                    </div>
                  </td>
                </tr>
                <tr >
                  <td>
                    <Button disabled={this.state.loading} name='btnok' style={{ marginRight: "5px" }} onClick={(e) => this.handleContactList(e, e.currentTarget.name, 0)} title="Click to Search">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>OK</span>}
                    </Button>
                  </td>
                  <td>
                    <Button disabled={this.state.loading} name='print' style={{ marginRight: "5px" }} onClick={(e) => this.handleModalSearchClose(e)} title="Click to Print">
                      {this.state.loading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px", color: "#3472F7" }}
                        />
                      )}
                      {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                      {!this.state.loading && <span>Cancel</span>}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  }

  contactSearchButtons1 = (prop, key) => {

    return <Row>
      <Col sm={10}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <tbody>
              <tr >
                <td>

                  {this.displayContact1(prop, key)}

                </td>
                <td>
                  <Button disabled={this.state.fields.btnSearchdis1[key]} name='btnSearch' style={{ marginRight: "5px", color: this.state.fields.btnSearchstyle1[key] }} onClick={(e) => this.handleModalSearchShow1(e, key)} title="Click to Search for a Contact">
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                    )}
                    {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                    {!this.state.loading && <span>Search</span>}
                  </Button>
                </td>
                <td>
                  <Button disabled={this.state.fields.btnContactdis1[key]} name='btnContact' style={{ marginRight: "5px", color: this.state.fields.btnContactstyle1[key] }} onClick={() => this.props.history.push({ pathname: '/admin/dbContact' })} title="Click to Edit Contact Information">
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                    )}
                    {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                    {!this.state.loading && <span>Contact </span>}
                  </Button>
                </td>
                <td>
                  <Button disabled={this.state.fields.btnRefreshdis1[key]} name='btnRefresh' style={{ marginRight: "5px", color: this.state.fields.btnRefreshstyle1[key] }} onClick={(e) => this.handlebtnRefresh(e)} title="Click to Refresh the Screen">
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                    )}
                    {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                    {!this.state.loading && <span>Refresh </span>}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }


  contactSearchButtons = () => {

    return <Row>
      <Col sm={10}>
        <div style={{ width: '100%' }}>
          <Table hover>
            <tbody>
              <tr >
                <td>

                  {this.displayContact()}

                </td>
                <td>
                  <Button disabled={this.state.fields.btnSearchdis} name='btnSearch' style={{ marginRight: "5px", color: this.state.fields.btnSearchstyle }} onClick={(e) => this.handleModalSearchShow(e)} title="Click to Search for a Contact">
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                    )}
                    {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                    {!this.state.loading && <span>Search</span>}
                  </Button>
                </td>
                <td>
                  <Button disabled={this.state.fields.btnContactdis} name='btnContact' style={{ marginRight: "5px", color: this.state.fields.btnContactstyle }} onClick={() => this.props.history.push({ pathname: '/admin/dbincomingContact' })} title="Click to Edit Contact Information">
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                    )}
                    {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                    {!this.state.loading && <span>Contact </span>}
                  </Button>
                </td>
                <td>
                  <Button disabled={this.state.fields.btnRefreshdis} name='btnRefresh' style={{ marginRight: "5px", color: this.state.fields.btnRefreshstyle }} onClick={(e) => this.handlebtnRefresh(e)} title="Click to Refresh the Screen">
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px", color: "#3472F7" }}
                      />
                    )}
                    {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                    {!this.state.loading && <span>Refresh </span>}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalButtons1 = (prop, key) => {
    let prevpage = this.props.location.state.from;

    if (prevpage != 'directbillincoming')
    // if  (this.state.fields.prevbox == "0")
    {
      if (this.state.fields.db1[key]) {
        return <Row>
          <Col sm={12}>
            <div style={{ width: '100%' }}>
              <Table hover>
                <tbody>
                  <tr >
                    <td>
                      <Button disabled={this.state.fields.btnFoliodis1[key]} name='gotFolio' style={{ marginRight: "5px", color: this.state.fields.btnStyle1[key], cursor: "" }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields.btnFolioTitle1[key]}>
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>{this.state.fields.got_folio1[key] ? "UnGot Folio" : "Got Folio"} </span>}
                      </Button>
                    </td>
                    <td>
                      <Button disabled={this.state.fields.btnInvoicedis1[key]} name='btnInvoicedis' style={{ marginRight: "5px", color: this.state.fields.btnStyle1[key] }} onClick={(e) => this.handleSaveInvoice(e, e.currentTarget.name, key)} title={this.state.fields.btnInvoiceTitle1[key]}>
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>Save Invoice</span>}
                      </Button>
                    </td>
                    <td>
                      <Button disabled={this.state.fields.btnSubLogdis1[key]} name='submitToLog' style={{ marginRight: "5px", color: this.state.fields.styleSubLog1[key] }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields.btnSubLogTitle1[key]}>
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />

                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>{this.state.fields.sub_log1[key] ? "UnSubmit to Log" : "Submit to Log"}</span>}
                      </Button>
                    </td>
                    <td>
                      <Button disabled={this.state.fields.btnSubPaydis1[key]} name='submitForPayment' style={{ marginRight: "5px", color: this.state.fields.styleSubPay1[key] }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields.btnSubPayTitle1[key]}>
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>{this.state.fields.sub_payment1[key] ? "UnSubmit for Pmt" : "Submit for Pmt"}</span>}
                      </Button>
                    </td>
                    <td>
                      <Button disabled={this.state.fields.btnChecknbrdis1[key]} name='saveCheckNumber' style={{ marginRight: "5px", color: this.state.fields.styleCheck1[key] }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields.btnChecknbrTitle1[key]}>
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>{this.state.fields.check_number1[key] != "" ? "UnSave Check Nbr" : "Save Check Nbr"}</span>}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      }
    }
  }
  hotelRentalButtons = () => {
    if (this.state.fields.prevbox == "0") {
      if (this.state.fields.db) {
        return <Row>
          <Col sm={12}>
            <div style={{ width: '100%' }}>
              <Table hover>
                <tbody>
                  {this.state.HotelView.map((prop, key) => {
                    //
                    return (
                      <tr >
                        <td>
                          <Button disabled={this.state.fields.btnFoliodis} name='gotFolio' style={{ marginRight: "5px", color: this.state.fields.btnStyle, cursor: "" }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields.btnFolioTitle}>
                            {this.state.loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color: "#3472F7" }}
                              />
                            )}
                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!this.state.loading && <span>{prop["got_folio"] ? "UnGot Folio" : "Got Folio"} </span>}
                          </Button>
                        </td>
                        <td>
                          <Button disabled={this.state.fields["btnInvoicedis"]} name='btnInvoicedis' style={{ marginRight: "5px", color: this.state.fields["btnStyle"] }} onClick={(e) => this.handleSaveInvoice(e, e.currentTarget.name, key)} title={this.state.fields.btnInvoiceTitle}>
                            {this.state.loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color: "#3472F7" }}
                              />
                            )}
                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!this.state.loading && <span>Save Invoice</span>}
                          </Button>
                        </td>
                        <td>
                          <Button disabled={this.state.fields["btnSubLogdis"]} name='submitToLog' style={{ marginRight: "5px", color: this.state.fields["styleSubLog"] }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields["btnSubLogTitle"]}>
                            {this.state.loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color: "#3472F7" }}
                              />

                            )}
                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!this.state.loading && <span>{prop["submitted_to_log"] ? "UnSubmit to Log" : "Submit to Log"}</span>}
                          </Button>
                        </td>
                        <td>
                          <Button disabled={this.state.fields["btnSubPaydis"]} name='submitForPayment' style={{ marginRight: "5px", color: this.state.fields["styleSubPay"] }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields["btnSubPayTitle"]}>
                            {this.state.loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color: "#3472F7" }}
                              />
                            )}
                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!this.state.loading && <span>{prop["submitted_payment"] ? "UnSubmit for Pmt" : "Submit for Pmt"}</span>}
                          </Button>
                        </td>
                        <td>
                          <Button disabled={this.state.fields["btnChecknbrdis"]} name='saveCheckNumber' style={{ marginRight: "5px", color: this.state.fields["styleCheck"] }} onClick={(e) => this.handleOnSubmit(e, e.currentTarget.name, key)} title={this.state.fields["btnChecknbrTitle"]}>
                            {this.state.loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", color: "#3472F7" }}
                              />
                            )}
                            {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                            {!this.state.loading && <span>{prop["check_number"] != "" ? "UnSave Check Nbr" : "Save Check Nbr"}</span>}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      }
    }
  }

  hotelRentalCarTable11 = (prop, key) => {

    let sbookings = this.props.location.state.sbookings;

    return <Row style={{ marginTop: "15px" }}>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover style={{ padding: "2px" }}>
            <thead style={{ backgroundColor: '#00B0F0', fontSize: 'large' }}>
              <tr>
                {sbookings === 'Hotel' ? <th  > Hotel </th> : <th > Rental Company </th>}
                <th > City </th>
                <th > Rate </th>
                <th >Tax</th>
                {sbookings === 'Hotel' ? <th>Incidentals</th> : <th >Other Costs</th>}
                {sbookings === 'Hotel' ? <th >Per Room Total</th> : <th > Total</th>}
              </tr>
            </thead>
            <tbody>

              <tr key={key} >
                <td style={{ color: 'red' }}> {prop["bill_to"]} {prop["non_disputable"] ? '(non-disputable)' : ''} </td>
                <td> {prop["city"]}</td>
                <td> ${prop["rate"]}</td>
                <td> ${prop["tax"]}</td>
                {prop["bill_id"] === 'ALL' ?
                  <td style={{ color: 'purple' }}> All Charges </td>
                  :
                  <td> None </td>
                }
                <td> ${prop["total"]}</td>
              </tr>

            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarTable1 = () => {

    let sbookings = this.props.location.state.sbookings;

    return <Row>
      <Col sm={12}>
        <div style={{ width: '100%' }}>
          <Table hover style={{ padding: "2px" }}>
            <thead style={{ backgroundColor: '#00B0F0', fontSize: 'large' }}>
              <tr>
                {sbookings === 'Hotel' ? <th  > Hotel </th> : <th > Rental Company </th>}
                <th > City </th>
                <th > Rate </th>
                <th >Tax</th>
                {sbookings === 'Hotel' ? <th>Incidentals</th> : <th >Other Costs</th>}
                {sbookings === 'Hotel' ? <th >Per Room Total</th> : <th > Total</th>}
              </tr>
            </thead>
            <tbody>
              {this.state.HotelView.map((prop, key) => {
                //
                return (
                  <tr key={key} >
                    <td style={{ color: 'red' }}> {prop["bill_to"]} {prop["non_disputable"] ? '(non-disputable)' : ''} </td>
                    <td> {prop["city"]}</td>
                    <td> ${prop["rate"]}</td>
                    <td> ${prop["tax"]}</td>
                    {prop["bill_id"] === 'ALL' ?
                      <td style={{ color: 'purple' }}> All Charges </td>
                      :
                      <td> None </td>
                    }
                    <td> ${prop["total"]}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  }

  hotelRentalCarMainButton = (prop, key) => {

    let sbookings = this.props.location.state.sbookings;

    return <Row style={{ marginRight: "45px" }}>
      <Col sm={2}>
      </Col>
      <Col sm={2}>
      </Col>
      <Col sm={3}>
      </Col>
      <Col sm={2.5}>
        <Button disabled={this.state.fields.btnSaveDBdis1[key]} name='btnSaveDB' style={{ marginRight: "5px", color: this.state.fields.btnSaveDBStyle1[key] }} onClick={(e) => this.handleSaveDBPrint(e, e.currentTarget.name, key)} title="Click to save Contact Information">
          {this.state.loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "15px", color: "#3472F7" }}
            />
          )}
          {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
          {!this.state.loading && <span>Save DB Letter</span>}
        </Button>
      </Col>

      <Col sm={2.5} >
        <Button disabled={this.state.fields.btnDBdis1[key]} name='btnPrintDB' style={{ marginRight: "115px", color: this.state.fields.btnDBStyle1[key], cursor: "not- allowed" }} onClick={(e) => this.handleModalShow(e, key)} title="Click to Print DB ietter">
          {this.state.loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "15px", color: "#3472F7" }}
            />
          )}
          {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
          {!this.state.loading && <span style={{ color: this.state.fields.btnPrintClr1[key] }} > Print DB Letter</span>}
        </Button>
      </Col>
    </Row>

  }

  hotelRentalCarModal = (prop, key) => {
    return <Modal show={this.state.modal.modalSearchShow}
      onHide={this.handleModalSearchClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="modal-40w"
      centered >

      <Modal.Header closeButton>
        {this.state.modal.modalSearchTitle}
      </Modal.Header>
      <Modal.Body className="show-grid" style={{ padding: '0px' }} >
        <Container fluid style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Card style={{ width: '100%', marginBottom: '0px' }}>
            <Card.Body>
              {

                this.displayContactSearch1(this.state.fields.modalkeyval)
              }
            </Card.Body>
          </Card>
        </Container>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>

  }

  render() {

    return (

      <div className="content">

        <Modal show={this.state.modal.modalShow}
          onHide={this.handleModalClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
          centered
        >
          <Modal.Header closeButton>
            {this.state.modal.modalTitle}
          </Modal.Header>
          <Modal.Body className="show-grid" style={{ padding: '0px' }} >
            <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <Card style={{ width: '100%', marginBottom: '0px' }}>
                <Card.Body>
                  {this.displayPrintDirectBill()}
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modal.modalSearchShow}
          onHide={this.handleModalSearchClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-40w"
          centered >

          <Modal.Header closeButton>
            {this.state.modal.modalSearchTitle}
          </Modal.Header>
          <Modal.Body className="show-grid" style={{ padding: '0px' }} >
            <Container fluid style={{ paddingLeft: '20px', paddingRight: '20px' }}>
              <Card style={{ width: '100%', marginBottom: '0px' }}>
                <Card.Body>
                  {this.displayContactSearch()}
                </Card.Body>
              </Card>
            </Container>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>


        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />

        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: '100%', height: '105px' }}>
                <div className="header"><h4 className="title"></h4><p className="category"></p></div>
                <Card.Body>
                  <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none" }}>{this.state.errMsg}</Alert>

                  <div style={{ backgroundColor: "#E4E6EF", border: "1px solid #222A35", padding: "5px" }}>
                    <Row>
                      <Col sm={4} style={{ textAlign: "left" }}>
                        <div className="text-dark font-size-xs small">Current{(sessionStorage.getItem("ta_car_service_only")) === "true" ? " Car Service " : " Travel "}Authorization:</div>
                        <div className="text-primary font-size-xs small">{(sessionStorage.getItem("ta_status_desc") === "Pending") ? "NEW" : "COMPLETE"}</div>
                      </Col>
                      <Col sm={4} style={{ textAlign: "center" }}>
                        <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_created_by")}&nbsp;{sessionStorage.getItem("ta_business_phone")}</div>
                        <div className="text-dark font-size-xs small">{this.displayProject()}</div>
                      </Col>
                      <Col sm={4} style={{ textAlign: "right" }}>
                        <div className="text-dark font-size-xs small">{(sessionStorage.getItem("ta_car_service_only")) === "true" ? "CA#" : "TA#"}{sessionStorage.getItem("ta")}</div>
                        <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_expense_type_desc")}</div>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>

              <Card border="light" style={{ width: '100%', height: '100px' }}>
                <Card.Body>

                  <Row style={{ marginTop: "15px" }}><Col sm={12}></Col></Row>

                  <Row style={{ marginBottom: "15px" }}>
                    <Col sm={2}>
                      <Button disabled={this.state.loading} name='back' style={{ marginRight: "5px" }} onClick={() => this.props.history.goBack()} title="Go Back">
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>Back</span>}
                      </Button>
                    </Col>
                    <Col sm={4}>
                      <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_label_desc")}</div>
                      <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_created_by")}&nbsp;{sessionStorage.getItem("ta_business_phone")}</div>
                      <div className="text-dark font-size-xs small">{this.displayProject()}</div>
                    </Col>
                    <Col sm={3}>
                      <div className="text-dark font-size-xs small">{(sessionStorage.getItem("ta_car_service_only")) === "true" ? "CA#" : "TA#"}{sessionStorage.getItem("ta")}</div>
                      <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_expense_type_desc")}</div>
                    </Col>
                    <Col sm={1}>
                      <Button disabled={this.state.loading} name='print' style={{ marginRight: "35px" }} onClick={(e) => window.print()} title="Click to Print">
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "15px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>Print</span>}
                      </Button>
                    </Col>
                    <Col sm={2}>
                      <Button disabled={this.state.loading} name='done' style={{ marginRight: "5px" }} onClick={(e) => this.handlebtnDone(e)} title="Click to done">
                        {this.state.loading && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "15px", color: "#3472F7" }}
                          />
                        )}
                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                        {!this.state.loading && <span>Done</span>}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card >
                <Card.Body>

                  {this.loadLoopBasedMainTable()}
                 
                </Card.Body>
              </Card>              
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

}