import React, { Component } from "react";
import {IVoucherPlusParameter, Istate } from "../../../Model/IVoucherPlus";
import {Container,Row,Col,Form,Alert,Button,Card,Modal} from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'
import { FormInputs } from "../../CustomComponents/FormInput";
import { FormSelect } from "../../CustomComponents/FormSelect";
import { CommonController } from  "../../../Controllers/CommonController";
import {CarServiceMaintenanceController} from  "../../../Controllers/CarServiceMaintenanceController";
import { VoucherPlusController } from "../../../Controllers/VoucherPlusContoller";
import {FormModalSpinner} from '../../CustomComponents/FormModalSpinner';
import { MultiFilter } from "../../../Common/MultiFilter";
import { timeStamp } from "console";
import { createEmitAndSemanticDiagnosticsBuilderProgram } from "typescript";

export class VoucherPlus extends Component<IVoucherPlusParameter,Istate> {
    constructor(props:IVoucherPlusParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
       
        this.state={
            fields: {expTypeId:'',labelId:'',businessunit:'',objectaccount:'',subaccount:'',searchUser:''},
            selectfields: {voucher_plus:'', expenseTypeId:'', formLabel: '',labelId:'', userId:''},
            errors:{userId:'',labelId:'',expenseTypeId:'',formLabel:''},
            errMsg:"",
            loading:false,
            CrossLabels:[],
            expenseType:[],
            expenseTypeId:[],
            listTravelers:[],
            HMEntity:[],
            Travelers:[],
            jsonTraveller:[],
            RLN: [],
            IsBCD_A: [],
            modal: {modalSpinnerShow: false,modalBody:'',modalShow:false,modalTAShow:false,modalTitle:'',modalTATitle:''},
            FlightInfo:[],
            expenseTypeGroups:[],
            travelerTypeId:0,
            Country: [],
            UsersArr: [],
            StateProvince: [],
            AirLineBCDCode:[]
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectMultipleChange = this.handleSelectMultipleChange.bind(this);
        this.handleButtonSearch = this.handleButtonSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
    }
    async componentWillMount() {
        let modal = this.state.modal;
        let selectfields = this.state.selectfields;

        selectfields['voucher_plus'] = 'User';
        selectfields['userId'] = 'None Selected';
        selectfields['labelId'] = 'None Selected';
        selectfields['formLabel'] = 'None Selected';
        modal['modalSpinnerShow'] = true;
        modal['modalTitle'] = 'Search User';
        
        this.setState({ modal, selectfields });

        let commonController = new CommonController();
        let voucherPlusController = new VoucherPlusController();
        await commonController.getLabels().then(jsonLabels => {
        this.setState({
            HMEntity: JSON.parse(JSON.stringify(jsonLabels))
        });
        //let selectfields = this.state.selectfields;
        //selectfields['formLabel'] = sessionStorage.getItem("label_id");
        //modal['modalSpinnerShow']=false;
        //this.setState({ modal });
        }).catch(error => {
            modal['modalSpinnerShow']=false;
            this.setState({ modal });
            console.error("There was an error loading Labels..., " + error)
        });   

        await voucherPlusController.getUsers().then(jsonusers => {
            this.setState({
                UsersArr: JSON.parse(JSON.stringify(jsonusers))
            });
            //console.log("UsersArr",this.state.UsersArr)
            //let selectfields = this.state.selectfields;
            //selectfields['formLabel'] = sessionStorage.getItem("label_id");
            //modal['modalSpinnerShow']=false;
            //this.setState({ modal });
            }).catch(error => {
                modal['modalSpinnerShow']=false;
                this.setState({ modal });
                console.error("There was an error loading Labels..., " + error)
            });   

        let carServiceMaintenanceController = new CarServiceMaintenanceController();
        await carServiceMaintenanceController.getExpenseType().then(jsonGetExpenseType => {
            this.setState({ expenseTypeGroups: JSON.parse(JSON.stringify(jsonGetExpenseType))});             
            //console.log(this.state.expenseTypeGroups)            
        }).catch(error => {
            console.error("There was an error!", error);
            modal['modalSpinnerShow']=false;
        })
        modal['modalSpinnerShow']=false;
        this.setState({modal})
    }
    validateFormUser(){
        let errors = this.state.errors;
        let formIsValid = true;

        if(this.state.selectfields.userId === 'None Selected'){
            formIsValid = false;
            errors["userId"] = "Please Select a User ID";
        }

        if(this.state.selectfields.labelId === 'None Selected'){
            formIsValid = false;
            errors["labelId"] = "Please Select a Label";
        }
        if(this.state.expenseTypeId.length === 0){
            formIsValid = false;
            errors["expenseTypeId"] = "Please Select atleaset one Expense Type";
    }
        this.setState({ errors });
        return formIsValid;
    }
    validateFormData(){
        let errors = this.state.errors;
        let formIsValid = true;

        if(this.state.selectfields.formLabel === 'None Selected'){
            formIsValid = false;
            errors["formLabel"] = "Please Select a Label";
        }

        if(this.state.selectfields.expenseTypeId === 'None Selected'){
            formIsValid = false;
            errors["expenseTypeId"] = "Please Select a Expense Type";
        }
        this.setState({ errors });
        return formIsValid;
    }
    handleModalShow = (e:any) => {
        let fields = this.state.fields;
        let modal = this.state.modal;
        fields['searchUser'] = "*"
        modal['modalShow']=true;
        this.setState({modal, fields});
    }
    handleModalClose = (e : any) => {
        let modal = this.state.modal;
        let loading = this.state.loading;
        modal['modalShow'] = false;
        modal['loading'] = false;
        this.setState({ modal, loading })
    }
    handleChange(e:any){
        
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0)
        {
            fields[e.currentTarget.name] = e.currentTarget.value;
        }
        else
        {
            fields[e.currentTarget.name] = e.currentTarget.value.trim();
        } 
        
        this.setState({fields})
    }
    displayUser=()=>{
        
        return <div id="displayData">
            <Row style={{marginTop:"-10px"}}> 
                <Col sm={3}></Col>
                <Col sm={3}>
                    <FormSelect
                            properties={{label:"User ID", id:"userId", name:"userId", value: this.state.selectfields.userId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                            fieldRequired={true}
                            defaultOption="None Selected"
                            fillOption={this.state.UsersArr}
                            errorMsg={this.state.errors.userId}
                        />
                </Col>   
                <Col sm={6} style={{alignSelf: "center"}}><Button size="sm" id="userSearch" name="userSearch" style={{height:'auto'}} onClick={(e) => this.handleModalShow(e)}>Search</Button></Col>
            </Row>
            <Row style={{marginTop:"-10px"}}> 
                <Col sm={3}></Col>
                <Col sm={3}>
                    <FormSelect
                            properties={{ label: "Label", id: "labelId", name: "labelId", value: this.state.selectfields.labelId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                            fieldRequired={true}
                            defaultOption="None Selected"
                            fillOption={this.state.CrossLabels}
                            errorMsg={this.state.errors.labelId}
                        />
                </Col>   
                <Col sm={6}></Col>
            </Row>
            <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                    <Form.Group>
                        <Form.Label>Expense Type<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
                        <Form.Control as="select" multiple={true} className="form-control form-control-sm" id="expenseTypeId" name="expenseTypeId" value={this.state.expenseTypeId} onChange={this.handleSelectMultipleChange}>
                        {this.state.expenseType.map((prop, key) => {
                            return (
                                <option selected={(prop["bit"])?true:false} value={prop["id"]}>{prop["name"]}</option>
                            )
                        })}
                        </Form.Control>
                        {this.state.errors["expenseTypeId"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["expenseTypeId"]}</div> : <></> }
                    </Form.Group>
                    </Col>
                    <Col sm={6}></Col>
                </Row>
            <Row  style={{marginTop:"10px"}}>
                <Col sm={3}></Col>  
                <Col md={3} style={{textAlign:"center"}}><Button  size="sm" id="userSave" name="userSave" style={{height:'auto'}} onClick={(e) => this.handleButtonSearch(e)}>Save</Button></Col>
                <Col sm={6}></Col>
            </Row>
        </div>
    }

    displayData=()=>{
        
        return <div id="displayData">
                <Row style={{marginTop:"-10px"}}>
                    <Col sm={3}></Col>               
                    <Col sm={3}>{this.formLabel()}</Col>
                    <Col sm={6}></Col>                                                                                       
                </Row>
                <Row style={{marginTop:"-10px"}}> 
                    <Col sm={3}></Col>
                    <Col sm={3}>
                        <FormSelect
                                properties={{ label: "Expense Type", id: "expenseTypeId", name: "expenseTypeId", value: this.state.selectfields.expenseTypeId, className: "form-control form-control-sm", onChange: this.handleSelectChange }}
                                fieldRequired={true}
                                defaultOption="None Selected"
                                fillOption={this.state.expenseTypeGroups}
                                errorMsg={this.state.errors.expenseTypeId}
                            />
                    </Col>
                    <Col sm={6}></Col>
                </Row>
                <Row style={{marginTop:"-10px"}}>
                    <Col sm={3}></Col>
                    <Col md={3}>
                        <FormInputs
                            properties={{id:"businessunit",label:"Business Unit",type:"text",name:"businessunit",className:"form-control form-control-sm",maxlength:12,value:this.state.fields.businessunit,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                        />
                    </Col>
                    <Col sm={6}></Col>
                </Row>
                <Row style={{marginTop:"-10px"}}>
                    <Col sm={3}></Col>
                    <Col md={3}>
                        <FormInputs
                            properties={{id:"objectaccount",label:"Object Account",type:"text",name:"objectaccount",className:"form-control form-control-sm",maxlength:5,value:this.state.fields.objectaccount,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                        />
                    </Col>
                    <Col sm={6}></Col>
                </Row>
                <Row style={{marginTop:"-10px"}}>
                    <Col sm={3}></Col>
                    <Col md={3}>
                        <FormInputs
                            properties={{id:"subaccount",label:"Sub Account",type:"text",name:"subaccount",className:"form-control form-control-sm",maxlength:4,value:this.state.fields.subaccount,onChange:this.handleChange,autocomplete:'off'}}
                            fieldRequired={false}
                        />
                    </Col>
                    <Col sm={6}></Col>
                </Row>
                <Row style={{textAlign:"center"}}>
                    <Col sm={3}></Col>  
                    <Col md={3}><Button  size="sm" id="dataSave" name="dataSave" style={{height:'auto'}} onClick={(e) => this.handleButtonSearch(e)}>Save</Button> </Col>
                    <Col md={6}></Col>
                </Row>
            </div>
    }
    clearError()
    {
        let errors = this.state.errors;
        errors['userId'] = ""
        errors['labelId'] = ""
        errors['formLabel'] = ""
        errors['expenseTypeId'] = ""
        this.setState({ errors });
    }
    handleSelectChange = (e: any) => {        
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        let selectfields = this.state.selectfields;
        let currentTargetId = e.currentTarget.id;
        let currentTargetName = e.currentTarget.name;
        let fields =this.state.fields;
       
        if (currentTargetId  === "voucher_plus") {
            selectfields['voucher_plus'] = e.currentTarget.value;
            selectfields['userId'] = "None Selected";
            selectfields['labelId'] = "None Selected";
            selectfields['formLabel'] = "None Selected";
            selectfields['expenseTypeId'] = "None Selected";
            fields['businessunit'] = '';
            fields['objectaccount'] = '';
            fields['subaccount'] = '';
            this.clearError();
            this.state.expenseType.splice(0, this.state.expenseType.length);
            this.state.expenseTypeId.splice(0, this.state.expenseTypeId.length);
            modal['modalSpinnerShow'] = false;
            this.setState({ modal });
        }
        else if (currentTargetId === "userId") {
            selectfields['userId'] = e.currentTarget.value; 
            let voucherPlusController = new VoucherPlusController();
            //console.log(e.currentTarget.value)
            voucherPlusController.getCrossLabels(e.currentTarget.value).then(jsoncrossLabel => {
                modal['modalSpinnerShow'] = false;
                this.setState({                           
                CrossLabels : JSON.parse(JSON.stringify(jsoncrossLabel), modal)
                });
                //console.log("CrossLabels:", this.state.CrossLabels)
            }).catch(error => {
                modal['modalSpinnerShow'] = false;
                this.setState({modal});
                console.error("There was an error!", error);
            })

            if (this.state.selectfields.voucher_plus === 'User' &&  this.state.selectfields.userId !== 'None Selected'  &&  this.state.selectfields.labelId !== 'None Selected'){
                //Expense Type
                voucherPlusController.getVoucherPlusExpenseTypeUser(this.state.selectfields.userId, this.state.selectfields.labelId).then(jsonExpenseType => {
                    
                    this.setState({
                        expenseType: JSON.parse(JSON.stringify(jsonExpenseType))
                    });
                    //console.log("ExpenseType: ", this.state.expenseType);
                    if (this.state.expenseType.length > 0){
                        
                        var value : string[] = [];
                        this.state.expenseType.filter(f=>f.bit == true).map((prop,key)=>{
                            value.push(prop["id"]);
                        });
                        
                        this.setState({expenseTypeId:value});
                    }
                    modal['modalSpinnerShow'] = false;
                    this.setState({ modal });
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error loading Role Type..., " + error)
                    });
            }
            else
            {
                modal['modalSpinnerShow'] = false;
            }
        }
        else if (currentTargetId === "labelId") {
            selectfields['labelId'] = e.currentTarget.value; 
            let voucherPlusController = new VoucherPlusController();
            if (this.state.selectfields.voucher_plus === 'User' &&  this.state.selectfields.userId !== 'None Selected' &&  this.state.selectfields.labelId !== 'None Selected'){
                //Expense Type
                voucherPlusController.getVoucherPlusExpenseTypeUser(this.state.selectfields.userId, this.state.selectfields.labelId).then(jsonExpenseType => {
                    
                    this.setState({
                        expenseType: JSON.parse(JSON.stringify(jsonExpenseType))
                    });
                    //console.log("ExpenseType: ", this.state.expenseType);
                    if (this.state.expenseType.length > 0){
                        
                        var value : string[] = [];
                        this.state.expenseType.filter(f=>f.bit == true).map((prop,key)=>{
                            value.push(prop["id"]);
                        });
                        
                        this.setState({expenseTypeId:value});
                    }
                    modal["modalSpinnerShow"] = false;
                    this.setState({ modal });
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error loading Role Type..., " + error)
                    });
            }
            else
            {
                modal['modalSpinnerShow'] = false;
            }
        }
        else if (currentTargetId === "formLabel" || currentTargetId === 'expenseTypeId') {
            
            selectfields[e.currentTarget.id] = e.currentTarget.value;  
            fields["businessunit"] = '';
            fields["objectaccount"] = '';
            fields["subaccount"] = '';
            let voucherPlusController = new VoucherPlusController();
            if (this.state.selectfields.voucher_plus === 'Data' &&  this.state.selectfields.formLabel !== 'None Selected' &&  this.state.selectfields.expenseTypeId !== 'None Selected'){
                var data = {
                    labelId:this.state.selectfields.formLabel,
                    expenseTypeId: this.state.selectfields.expenseTypeId
                }
                //Expense Type
                voucherPlusController.getVoucherPlusExpTypeAccountData(data).then(response => {
                    
                    //this.setState({
                    //    expenseType: JSON.parse(JSON.stringify(response))
                    //});
                    //console.log("Account Type response: ", response.recordset);
                    if (response.recordset.length > 0){
                        fields["businessunit"] = response.recordset[0].business_unit;
                        fields["objectaccount"] = response.recordset[0].object_account;
                        fields["subaccount"] = response.recordset[0].sub_account;
                    }
                    else
                    {
                        fields["businessunit"] = '';
                        fields["objectaccount"] = '';
                        fields["subaccount"] = '';
                    }
                    modal["modalSpinnerShow"] = false;
                    this.setState({ modal });
                    }).catch(error => {
                        modal["modalSpinnerShow"] = false;
                        this.setState({modal});
                        console.error("There was an error loading voucher plus account Type..., " + error)
                    });
            }
            else
            {
                modal['modalSpinnerShow'] = false;
            }
        }
        else if (currentTargetId === "expenseTypeId") {
            if(e.currentTarget.value != "None Selected")
            {
                modal['modalSpinnerShow'] = false;
                selectfields[e.currentTarget.id] = e.currentTarget.value;   
                //fields["expTypeId"] = e.currentTarget.value;
  
            }     
            modal['modalSpinnerShow'] = false; 
        }
        else
        {
            selectfields[e.currentTarget.name] = e.currentTarget.value; 
            modal['modalSpinnerShow'] = false;
        }
        
        this.setState({ selectfields, fields, modal });
    }
    handleSelectMultipleChange=(e:any)=>{
        
        var options = e.currentTarget.options;
        var value : string[] = [];
        
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({expenseTypeId: value});
        //console.log("expenseTypeId", this.state.expenseTypeId);
        
    }
    handleButtonSearch=(e:any)=>{
        let selectfields = this.state.selectfields;
        let modal = this.state.modal;
        modal['modalSpinnerShow'] = true;
        var eventCurrentTargetId = e.currentTarget.id;
        if(eventCurrentTargetId === "ok"){
            
            if (this.state.UsersArr.length > 0)
            {
                var filterUser;
                if (this.state.fields.searchUser.indexOf('*') === 0){
                    filterUser = this.state.UsersArr.filter(m=>m.name.toLowerCase().startsWith(this.state.fields.searchUser.replace('*','').toLocaleLowerCase())).map(m=>m.id);
                }
                //else if(this.state.fields.searchUser.indexOf('*') === this.state.fields.searchUser.length-1){
                //    filterUser = this.state.UsersArr.filter(m=>m.name.toLowerCase().endsWith(this.state.fields.searchUser.replace('*','').toLocaleLowerCase())).map(m=>m.id);
                //}
                else
                {
                    filterUser = this.state.UsersArr.filter(m=>m.name.toLowerCase().includes(this.state.fields.searchUser.replace('*','').toLocaleLowerCase())).map(m=>m.id);
                }
                
                
                if (filterUser.length > 0)
                {
                    selectfields['userId'] = filterUser[0];

                    let voucherPlusController = new VoucherPlusController();
                    //console.log(e.currentTarget.value)
                    voucherPlusController.getCrossLabels(this.state.selectfields.userId).then(jsoncrossLabel => {
                        modal['modalSpinnerShow'] = false;
                        this.setState({                           
                            CrossLabels : JSON.parse(JSON.stringify(jsoncrossLabel), modal)
                        });
                        this.setState({modal});
                        //console.log("CrossLabels:", this.state.CrossLabels)
                    }).catch(error => {
                        modal['modalSpinnerShow'] = false;
                        this.setState({modal});
                        console.error("There was an error!", error);
                    })
                }
                modal['modalShow'] = false;
                modal['modalSpinnerShow'] = false;
            }
        }
        else if (eventCurrentTargetId === "cancel"){
            modal["modalShow"] = false;
        }
        else if(eventCurrentTargetId === "dataSave"){
            this.clearError();
            if (this.validateFormData()){
                let voucherPlusController = new VoucherPlusController();
                var datasaverequestdetails={
                    labelId:this.state.selectfields.formLabel,
                    expenseTypeId:this.state.selectfields.expenseTypeId,
                    businessUnit:this.state.fields.businessunit,
                    objectAccount:this.state.fields.objectaccount,
                    subAccount:this.state.fields.subaccount
                }
                //console.log("datasaverequestdetails",datasaverequestdetails)
                voucherPlusController.insertUpdVoucherPlusData(datasaverequestdetails).then(response => {
                    //console.log("insertUpdVoucherPlusData-Response", response)
                    modal['modalSpinnerShow'] = false;
                    this.setState(modal);
                    this.props.history.push('/admin/home');
                }).catch(error => {
                    modal['modalSpinnerShow'] = false;
                    this.setState(modal);
                    console.error("There was an error!", error)
                })
            }
            else
            {
                modal['modalSpinnerShow'] = false;
            }
        }
        else if(eventCurrentTargetId === "userSave"){
            this.clearError();
            if (this.validateFormUser()){
                let voucherPlusController = new VoucherPlusController();
                var concatInputExpType = '';
                this.state.expenseTypeId.map(prop => {
                    if(concatInputExpType === ''){
                        concatInputExpType = prop;
                    }
                    else{
                        concatInputExpType = concatInputExpType + ',' + prop;
                    }
                });
                var usersaverequestdetails={
                    labelId:this.state.selectfields.labelId,
                    userId:this.state.selectfields.userId,
                    expenseTypeId:concatInputExpType
                }
                //console.log("usersaverequestdetails",usersaverequestdetails)
                voucherPlusController.insertUpdVoucherPlusUser(usersaverequestdetails).then(response => {
                    //console.log("insertUpdVoucherPlusUser-Response", response)
                    modal['modalSpinnerShow'] = false;
                    this.setState(modal);
                    this.props.history.push('/admin/home');
                }).catch(error => {
                    modal['modalSpinnerShow'] = false;
                    this.setState(modal);
                    console.error("There was an error!", error)
                })
            }
            else
            {
                modal['modalSpinnerShow'] = false;
            }
        }
        this.setState({modal})
    }
        
    formLabel = () => {
        return <Form.Group>
          <Form.Label>Label<span className="text-danger" style={{fontSize:'16px'}}>*</span></Form.Label>
          <Form.Control as="select" className="form-control form-control-sm" id="formLabel" name="formLabel" value={this.state.selectfields.formLabel} onChange={this.handleSelectChange}>
            <option value="None Selected">{"None Selected"}</option>
            {this.state.HMEntity.map((prop, key) => {
              if (prop["is_active"] === "Yes") {
                return (
                  <option value={prop["id"]}>{prop["name"]}</option>
                )
              }
              else {
                return (
                  <option className="text-danger" value={prop["id"]}>{prop["name"]}</option>
                )
              }
            })}
          </Form.Control>
          {this.state.errors["formLabel"] !== "" ? <div className="text-danger font-size-xs small font-weight-bold">{this.state.errors["formLabel"]}</div> : <></> }
        </Form.Group>
      }

    render() {
        return (
          <div className="content">
              <Modal show={this.state.modal.modalShow}
                onHide={this.handleModalClose} 
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-md"
                centered
                >
                <Modal.Header closeButton style={{backgroundColor:'#f8f7f7'}}>
                    {this.state.modal.modalTitle}
                </Modal.Header>
                <Modal.Body className="show-grid" style={{padding:'0px', backgroundColor:'#f8f7f7'}}>
                    <Container fluid style={{paddingLeft:'0px',paddingRight:'0px'}}>
                         <Card style={{ width: '100%',marginBottom: '0px', backgroundColor:'#f8f7f7' }}>
                            <Card.Body>
                            <Row>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <FormInputs
                                        properties={{id:"searchUser",label:"Search",type:"text",name:"searchUser",className:"form-control form-control-sm",maxlength:30,value:this.state.fields.searchUser,onChange:this.handleChange,autocomplete:'off'}}
                                        fieldRequired={false}
                                    />
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{backgroundColor:'#f8f7f7'}}>
                    <div style={{width: "100%"}}>
                        <Row>
                        <Col md={3}></Col>
                        <Col md={6} style={{textAlign:"center"}}>
                            <Button style={{height: 'auto'}} size="sm" id="ok" name="ok" onClick={(e) => this.handleButtonSearch(e)}>Ok</Button>
                            <Button style={{height: 'auto',marginLeft:"5px"}} size="sm" id="cancel" name="cancel" onClick={(e) => this.handleButtonSearch(e)}>Cancel</Button>
                        </Col>
                        <Col md={3}></Col>
                        </Row>
                    </div>
                </Modal.Footer>
            </Modal>
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                        <div className="header"><h4 className="title"></h4><p className="category"></p></div>
                            <Card.Body>
                            <Form autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <Row style={{textAlign:"right"}}>
                                    <Col sm={12}>
                                        <Button disabled={this.state.loading} name='btnPrint' style={{ marginRight: "5px"}} onClick={() => window.print()} title="Click to print page">
                                        {this.state.loading && (
                                        <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px", color: "#3472F7" }}
                                        />
                                        )}
                                        {this.state.loading && <span style={{ color: "#3472F7" }}>wait...</span>}
                                        {!this.state.loading && <span>Print </span>}
                                        </Button>
                                    </Col>
                                </Row>
                                <div style={{width: "100%"}}>
                                    <Row>
                                        <Col md={3} style={{textAlignLast: "center"}}></Col>
                                        <Col sm={3}>
                                            <Form.Group>
                                                <Form.Label>Sysop - Voucher Plus</Form.Label>
                                                <Form.Control as="select" id="voucher_plus" name="voucher_plus" className="form-control-sm" value={this.state.selectfields.voucher_plus} onChange={this.handleSelectChange}>
                                                    <option value="User" selected>User</option>
                                                    <option value="Data">Data</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Row>
                                        <Row>
                                            <Col md={12}>
                                                {(this.state.selectfields.voucher_plus ==='Data'?this.displayData():this.displayUser())}
                                            </Col>
                                        </Row>
                                </div>
                            </Form>
                            </Card.Body>                                  
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }

}