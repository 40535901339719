import React, { Component } from "react";
import {
  FormLabel,
  FormGroup,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Navbar,
  Nav,
  Alert,
  Button,
  Card,
  CardGroup,
  FormControl,
  Modal,
  Table,
} from "../../../../node_modules/react-bootstrap";
import * as Utils from "../../../js/Utils.js";
import "../../../assets/css/Style.css";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { FormModalSpinner } from "../../CustomComponents/FormModalSpinner";
import { alert, confirm } from "react-bootstrap-confirmation";
import { AuthStatus } from "../Common/AuthStatus";
import { SubmitButton } from "../Common/SubmitButton";
import { ICodingSummaryParameter, Istate } from "../../../Model/ICodingSummary";
import { CodingSummaryController } from "../../../Controllers/CodingSummaryController";
import parse from "html-react-parser";
import { CommonController } from "../../../Controllers/CommonController";
import { TAInclude } from "../../../Common/TAInclude";
import {
  getCombinedNodeFlags,
  isConstructorDeclaration,
  isFunctionTypeNode,
} from "typescript";
import * as Constants from "../../../js/Constants";
import { constants } from "buffer";
import { CreateTAController } from "../../../Controllers/CreateTAController";
import { TravellerController } from "../../../Controllers/TravellerController";

export class CodingSummary extends Component<ICodingSummaryParameter, Istate> {
  constructor(props: ICodingSummaryParameter) {
    super(props);
    const userLogin = sessionStorage.getItem("userLogin");
    let loggedIn = true;
    if (userLogin == null) {
      loggedIn = false;
    }
    this.state = {
      fields: {
        sessionTA: sessionStorage.getItem("ta"),
        taCarService: sessionStorage.getItem("ta_car_service2"),
        bCoding: false,
        bCodeAir: false,
        bCodeHotel: false,
        bCodeRentalCar: false,
        bCodeCarService: false,
        sb2: "",
        bAirOK: false,
        bAspOK: false,
        bHtlOK: false,
        bHspOK: false,
        bCarOK: false,
        bCspOK: false,
        bSvcOK: false,
        bSspOK: false,
        summaryHtml: [],
        bookingId: "",
        billTypeId: "",
        travelTypeId: "",
        poline: [],
        poNumber: [],
        airbookingTest: [],
        airbookingSample: [],
      },
      selectfields: { hotelBillId: "", hotelBillDesc: "" },
      checkfields: { nonDisputable: true },
      errors: { hotelBillId: "" },
      loading: false,
      errMsg: "",
      userProcess: "",
      travellerDetails: [],
      airBooking: [],
      hotelBooking: [],
      rentalCarBooking: [],
      carServiceBooking: [],
      modal: { modalSpinnerShow: false },
      prevBookingID: "",
      prevTravelerID: "",
      jsonPoSAPAir: [],
      jsonPoSAPHotel: [],
      jsonPoSAPRentalCar: [],
      jsonPoSAPCarService: [],
      jsonPoSAPAirSplCharges: [],
      jsonPoSAPHotelSplCharges: [],
      jsonPoSAPRentalCarSplCharges: [],
      jsonPoSAPCarServiceSplCharges: [],
      uniquefields: {
        uniqueAirBookingIds: [],
        uniqueHotelBookingIds: [],
        uniqueRentalCarBookingIds: [],
        uniqueCarServiceBookingIds: [],
      },
      poHtmlResult: {
        poAirHtml: [],
        poHotelHtml: [],
        poRentalCarHtml: [],
        poCarServiceHtml: [],
      },
    };
    this.insertCoding = this.insertCoding.bind(this);
  }
  async componentWillMount() {
    let modal = this.state.modal;
    let fields = this.state.fields;
    modal["modalSpinnerShow"] = true;
    // console.log("this.props.location.state.from :", this.props.location.state.from )
    let taInclude = new TAInclude();
    // console.log("count:","2")
    fields["bCoding"] = await taInclude.taCoding("");
    // console.log("bCoding:",fields['bCoding']);

    if (sessionStorage.getItem("ta_sound_biz_2")) {
      fields["sb2"] = "1";
    } else {
      fields["sb2"] = "0";
    }

    //;
    let codingSummaryController = new CodingSummaryController();
    await codingSummaryController
      .getTravellerDetails(this.state.fields.sessionTA!)
      .then((response) => {
        //;
        if (response !== undefined) {
          this.setState({
            travellerDetails: JSON.parse(JSON.stringify(response.recordset)),
          });
        }
        // console.log("Traveller Details:", this.state.travellerDetails);
      })
      .catch((error) => {
        console.error("There was an error", error);
      });

    await this.pageLoad("", "", "willmount");
  }
  pageLoad = async (iBooking, sBooking, status) => {

    if (status === "willmount") {
      this.setState({
        jsonPoSAPAir: this.state.jsonPoSAPAir.splice(
          0,
          this.state.jsonPoSAPAir.length
        ),
        jsonPoSAPAirSplCharges: this.state.jsonPoSAPAirSplCharges.splice(
          0,
          this.state.jsonPoSAPAirSplCharges.length
        ),
        jsonPoSAPHotel: this.state.jsonPoSAPHotel.splice(
          0,
          this.state.jsonPoSAPHotel.length
        ),
        jsonPoSAPHotelSplCharges: this.state.jsonPoSAPHotelSplCharges.splice(
          0,
          this.state.jsonPoSAPHotelSplCharges.length
        ),
        jsonPoSAPRentalCar: this.state.jsonPoSAPRentalCar.splice(
          0,
          this.state.jsonPoSAPRentalCar.length
        ),
        jsonPoSAPRentalCarSplCharges:
          this.state.jsonPoSAPRentalCarSplCharges.splice(
            0,
            this.state.jsonPoSAPRentalCarSplCharges.length
          ),
        jsonPoSAPCarService: this.state.jsonPoSAPCarService.splice(
          0,
          this.state.jsonPoSAPCarService.length
        ),
        jsonPoSAPCarServiceSplCharges:
          this.state.jsonPoSAPCarServiceSplCharges.splice(
            0,
            this.state.jsonPoSAPCarServiceSplCharges.length
          ),
      });
    } else {
      if (sBooking === Constants.airBooking) {
        //this.state.jsonPoSAPAir.splice(0, this.state.jsonPoSAPAir.length);
        //this.state.jsonPoSAPAirSplCharges.splice(0, this.state.jsonPoSAPAirSplCharges.length);
        // console.log("Before Delete jsonPoSAPAir", this.state.jsonPoSAPAir);
        // console.log("Before Delete jsonPoSAPAirSplCharges", this.state.jsonPoSAPAirSplCharges);
        if (status === "coding") {
          this.setState({
            jsonPoSAPAir: this.state.jsonPoSAPAir.filter(
              (item) => item.iBookingId != iBooking
            ),
          });
        } else {
          this.setState({
            jsonPoSAPAirSplCharges: this.state.jsonPoSAPAirSplCharges.filter(
              (item) => item.iBookingId != iBooking
            ),
          });
        }
        // jsonPoSAPAir: this.state.jsonPoSAPAir.filter(item => item.iBookingId != iBooking)
        // console.log("After Delete jsonPoSAPAir", this.state.jsonPoSAPAir);
        // console.log("After Delete jsonPoSAPAirSplCharges", this.state.jsonPoSAPAirSplCharges);
      } else if (sBooking === Constants.hotelBooking) {
        if (status === "coding") {
          this.setState({
            jsonPoSAPHotel: this.state.jsonPoSAPHotel.filter(
              (item) => item.iBookingId != iBooking
            ),
          });
        } else {
          this.setState({
            jsonPoSAPHotelSplCharges:
              this.state.jsonPoSAPHotelSplCharges.filter(
                (item) => item.iBookingId != iBooking
              ),
          });
        }
      } else if (sBooking === Constants.rentalCarBooking) {
        if (status === "coding") {
          this.setState({
            jsonPoSAPRentalCar: this.state.jsonPoSAPRentalCar.filter(
              (item) => item.iBookingId != iBooking
            ),
          });
        } else {
          this.setState({
            jsonPoSAPRentalCarSplCharges:
              this.state.jsonPoSAPRentalCarSplCharges.filter(
                (item) => item.iBookingId != iBooking
              ),
          });
        }
      } // Car Service
      else {
        if (status === "coding") {
          this.setState({
            jsonPoSAPCarService: this.state.jsonPoSAPCarService.filter(
              (item) => item.iBookingId != iBooking
            ),
          });
        } else {
          this.setState({
            jsonPoSAPCarServiceSplCharges:
              this.state.jsonPoSAPCarServiceSplCharges.filter(
                (item) => item.iBookingId != iBooking
              ),
          });
        }
      }
    }

    let codingSummaryController = new CodingSummaryController();
    let fields = this.state.fields;
    let modal = this.state.modal;
    let taInclude = new TAInclude();
    if(sessionStorage.getItem("ta_has_air")){
    var data = {
      sBooking: Constants.airBooking,
      taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
      authreqId: this.state.fields.sessionTA,
      taCarService2: sessionStorage.getItem("ta_car_service2"),
    };
    const priority1 = await codingSummaryController
      .getCodingSummary(data)
      .then(async (response) => {
    console.log(response,"response1")
        if (response !== undefined) {
          this.setState({
            airBooking: JSON.parse(JSON.stringify(response.recordset)),
          });
          // console.log("airBooking:", this.state.airBooking);

          fields["bCodeAir"] = await taInclude.taCoding(Constants.airCode);
          // console.log("bCodeAir:",fields['bCodeAir']);

          this.setState({ fields });
          //filter unique Air Booking_ids
          const filterAirBooking_ids = this.state.airBooking.map(
            (q) => q.booking_id
          );
          // console.log("filterAirBooking_ids:", filterAirBooking_ids)
          this.state.uniquefields.uniqueAirBookingIds =
            filterAirBooking_ids.filter(
              (q, idx) => filterAirBooking_ids.indexOf(q) === idx
            );
          // console.log("Unique air Booking", this.state.uniquefields.uniqueAirBookingIds)
          // console.log("****************load Air PO SAP**************")
          //await this.timeout(1000); //for 1 sec delay
          await this.loadPoSapValidationHtmlAir();

        }
      })
      .catch((error) => {
        console.error("There was an error", error);
      });}

      if(sessionStorage.getItem("ta_has_hotel")){
    var data = {
      sBooking: Constants.hotelBooking,
      taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
      authreqId: this.state.fields.sessionTA,
      taCarService2: sessionStorage.getItem("ta_car_service2"),
    };
    const priority2 = await codingSummaryController
      .getCodingSummary(data)
      .then(async (response) => {
        if (response !== undefined) {
          this.setState({
            hotelBooking: JSON.parse(JSON.stringify(response.recordset)),
          });

          // console.log("hotelBooking:", this.state.hotelBooking);
          fields["bCodeHotel"] = await taInclude.taCoding(Constants.hotelCode);
          // console.log("bCodeHotel:",fields['bCodeHotel']);
          this.setState({ fields });
          //filter unique Hotel Booking_ids
          const filterHotelBooking_ids = this.state.hotelBooking.map(
            (q) => q.booking_id
          );
          // console.log("filterHotelBooking_ids:", filterHotelBooking_ids)
          this.state.uniquefields.uniqueHotelBookingIds =
            filterHotelBooking_ids.filter(
              (q, idx) => filterHotelBooking_ids.indexOf(q) === idx
            );
          // console.log("Unique hotel Booking", this.state.uniquefields.uniqueHotelBookingIds)
          // console.log("****************load Hotel PO SAP**************")
          await this.loadPoSapValidationHtmlHotel();

          // await this.timeout(1000); //for 1 sec delay
        }
      })
      .catch((error) => {
        console.error("There was an error", error);
      });}

      if(sessionStorage.getItem("ta_has_rental_car")){
    var data = {
      sBooking: Constants.rentalCarBooking,
      taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
      authreqId: this.state.fields.sessionTA,
      taCarService2: sessionStorage.getItem("ta_car_service2"),
    };
    const priority3 = await codingSummaryController
      .getCodingSummary(data)
      .then(async (response) => {
        if (response !== undefined) {
          
          this.setState({
            rentalCarBooking: JSON.parse(JSON.stringify(response.recordset)),
          });
          // console.log("rentalCarBooking:", this.state.rentalCarBooking);

          fields["bCodeRentalCar"] = await taInclude.taCoding(
            Constants.rentalCarCode
          );
          // console.log("bCodeRentalCar:",fields['bCodeRentalCar']);

          this.setState({ fields });
          //filter unique Rental Car Booking_ids
          const filterRentalCarBooking_ids = this.state.rentalCarBooking.map(
            (q) => q.booking_id
          );
          // console.log("filterRentalCarBooking_ids:", filterRentalCarBooking_ids)
          this.state.uniquefields.uniqueRentalCarBookingIds =
            filterRentalCarBooking_ids.filter(
              (q, idx) => filterRentalCarBooking_ids.indexOf(q) === idx
            );
          // console.log("Unique retal car Booking", this.state.uniquefields.uniqueRentalCarBookingIds)

          // console.log("****************load RentalCar PO SAP**************")
          await this.loadPoSapValidationHtmlRentalCar();
          //await this.timeout(1000); //for 1 sec delay
        }
      })
      .catch((error) => {
        console.error("There was an error", error);
      });}
      if(sessionStorage.getItem("ta_has_car_service")){
        // alert("car")
    var data = {
      sBooking: Constants.carServiceBooking,
      taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
      authreqId: this.state.fields.sessionTA,
      taCarService2: sessionStorage.getItem("ta_car_service2"),
    };
    const priority4 = await codingSummaryController
      .getCodingSummary(data)
      .then(async (response) => {
        if (response !== undefined) {
          this.setState({
            carServiceBooking: JSON.parse(JSON.stringify(response.recordset)),
          });
        }
        // console.log("carServiceBooking:", this.state.carServiceBooking);

        fields["bCodeCarService"] = await taInclude.taCoding(
          Constants.carServiceCode
        );
        // console.log("bCodeCarService:",fields['bCodeCarService']);

        this.setState({ fields });

        //filter unique Car Service Booking_ids
        const filterCarServiceBooking_ids = this.state.carServiceBooking.map(
          (q) => q.booking_id
        );
        // console.log("filterCarServiceBooking_ids:", filterCarServiceBooking_ids)
        this.state.uniquefields.uniqueCarServiceBookingIds =
          filterCarServiceBooking_ids.filter(
            (q, idx) => filterCarServiceBooking_ids.indexOf(q) === idx
          );
        // console.log("Unique car service Booking", this.state.uniquefields.uniqueCarServiceBookingIds)

        //await this.timeout(1000); //for 1 sec delay

        // console.log("****************load Car Service PO SAP**************")
        await this.loadPoSapValidationHtmlCarService();

      })
      .catch((error) => {
        console.error("There was an error", error);
      });}

      
      await this.timeout(5000); //for 1 sec delay

      modal["modalSpinnerShow"] = false;
      this.setState({ modal });
    // Using .catch:
    // Promise.all([priority1, priority2, priority3, priority4])
    //   .then((values) => {
    //     console.log("Promise",values);
    //   })
    //   .catch((error) => {
    //     console.error(error.message,"checkError");

    //   });
  };
  timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async refresh() {
    this.state.jsonPoSAPAir.splice(0, this.state.jsonPoSAPAir.length);
    this.state.jsonPoSAPAirSplCharges.splice(
      0,
      this.state.jsonPoSAPAirSplCharges.length
    );
    let fields = this.state.fields;
    let taInclude = new TAInclude();
    fields["bCodeAir"] = await taInclude.taCoding(Constants.airCode);
    // console.log("bCodeAir:",fields['bCodeAir']);

    this.setState({ fields });
    await this.loadPoSapValidationHtmlAir();
  }

  async getCoding(
    iBooking,
    sBooking,
    bCoding,
    prevID,
    prevType,
    sc_amount,
    bill_type_id
  ) {
    let sType, id, sIncomplete;
    let strHtml = "";
    var exitLoop = false;
    let taAInclude = new TAInclude();
    let fields = this.state.fields;

    if (sBooking === Constants.airBooking) {
      // console.log("*****Initiate Air*************");
      fields["bCoding"] = fields["bCodeAir"];
      // console.log("getCodingAir-bCoding", fields["bCoding"]);
    } else if (sBooking === Constants.hotelBooking) {
      // console.log("*****Initiate Hotel*************");
      fields["bCoding"] = fields["bCodeHotel"];
      // console.log("getCodingHotel-bCoding", fields["bCoding"]);
    } else if (sBooking === Constants.rentalCarBooking) {
      // console.log("*****Initiate RentalCar*************");
      fields["bCoding"] = fields["bCodeRentalCar"];
      // console.log("getCodingRentalCar-bCoding", fields["bCoding"]);
    } //Car Service
    else {
      // console.log("*****Initiate Car Service*************");
      fields["bCoding"] = fields["bCodeCarService"];
      // console.log("getCodingCarService-bCoding", fields["bCoding"]);
    }
    bCoding = fields["bCoding"];
    this.setState({ fields });

    if (sessionStorage.getItem("ta_is_corporate_card") === "false") {
      const generalLoop = [1, 2];
      for (let i of generalLoop) {
        // console.log("index", i)
        if (!exitLoop) {
          if (i === 2 && sc_amount <= 0) {
            exitLoop = true;
          }
          // console.log("index: ", i);
          strHtml = "";
          if (sBooking === Constants.airBooking) {
            // console.log("*****Looping Air*************");
            if (i === 1) {
              //AIR
              sType = "AIR";
              fields["bAirOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.airBooking
              );
              sIncomplete = this.state.fields.bAirOK ? "" : "Incomplete";
              // console.log("Air getcoding:", sIncomplete);
            } //ASP
            else {
              sType = "ASP";
              fields["bAspOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.airBooking
              );
              sIncomplete = this.state.fields.bAspOK ? "" : "Incomplete";
            }
          } else if (sBooking === Constants.hotelBooking) {
            // console.log("*****Looping Hotel*************");
            if ((i = 1)) {
              //HTL
              sType = "HTL";
              fields["bHtlOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.hotelBooking
              );
              // console.log("count:","6")
              sIncomplete = this.state.fields.bHtlOK ? "" : "Incomplete";
            } //HSP
            else {
              sType = "HSP";
              fields["bHspOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.hotelBooking
              );
              // console.log("count:","7")
              sIncomplete = this.state.fields.bHspOK ? "" : "Incomplete";
            }
          } else if (sBooking === Constants.rentalCarBooking) {
            // console.log("*****Looping Rental Car*************");
            if (i === 1) {
              //CAR
              sType = "CAR";
              fields["bCarOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.rentalCarBooking
              );
              // console.log("count:","8")
              sIncomplete = this.state.fields.bCarOK ? "" : "Incomplete";
            } //CSP
            else {
              sType = "CSP";
              fields["bCspOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.rentalCarBooking
              );
              // console.log("count:","9")
              sIncomplete = this.state.fields.bCspOK ? "" : "Incomplete";
            }
          } //'Car Service'
          else {
            // console.log("*****Looping Car Service*************");
            if (i === 1) {
              //SVC
              sType = "SVC";
              fields["bSvcOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.carServiceBooking
              );
              fields["bSvcOK"] = true;
              // console.log("fields['bSvcOK']:",fields['bSvcOK'])
              // console.log("count:","10")
              sIncomplete = this.state.fields.bSvcOK ? "" : "Incomplete";
            } //SSP
            else {
              sType = "SSP";
              fields["bSspOK"] = await taAInclude.taCodingOKRequest(
                sessionStorage.getItem("ta")!,
                iBooking,
                sType,
                Constants.carServiceBooking
              );
              // console.log("fields['bSspOK']:",fields['bSspOK'])
              // console.log("count:","11")
              sIncomplete = this.state.fields.bSspOK ? "" : "Incomplete";
            }
          }
        }

        id = prevType === sType ? prevID : "";

        var str = i === 1 ? "Coding" : "Special Charges Coding";
        // console.log("Start - getPoSapHtml", i + "," + str);

        let getCodingHtml;

        if (sBooking === Constants.airBooking) {
          getCodingHtml = await this.loadtaCodingRowAir(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtmlAir", getCodingHtml, "##"+ i + "," + str);

          if (i === 1) {
            //Coding
            var index = this.state.jsonPoSAPAir.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling Air*************");
            if (index.length === 0) {
              this.state.jsonPoSAPAir.push({
                iBookingId: iBooking,
                sType: Constants.airBooking,
                sHtml: getCodingHtml,
              });
            }
          } else {
            //Special Charges
            var index = this.state.jsonPoSAPAirSplCharges.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling Air*************");
            if (index.length === 0) {
              this.state.jsonPoSAPAirSplCharges.push({
                iBookingId: iBooking,
                sType: Constants.airBooking,
                sHtml: getCodingHtml,
              });
            }
          }
        } else if (sBooking === Constants.hotelBooking) {
          getCodingHtml = await this.loadtaCodingRowHotel(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtmlHotel", getCodingHtml)
          if (i === 1) {
            //Coding
            var index = this.state.jsonPoSAPHotel.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling Hotel*************");
            if (index.length === 0) {
              this.state.jsonPoSAPHotel.push({
                iBookingId: iBooking,
                sType: Constants.hotelBooking,
                sHtml: getCodingHtml,
              });
            }
          } else {
            //Special Charges
            var index = this.state.jsonPoSAPHotelSplCharges.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling Hotel*************");
            if (index.length === 0) {
              this.state.jsonPoSAPHotelSplCharges.push({
                iBookingId: iBooking,
                sType: Constants.hotelBooking,
                sHtml: getCodingHtml,
              });
            }
            // console.log("jsonPoSAPHotelSplCharges", this.state.jsonPoSAPHotelSplCharges)
          }
        } else if (sBooking === Constants.rentalCarBooking) {
          getCodingHtml = await this.loadtaCodingRowRentalCar(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtmlRentalCar", getCodingHtml)
          if (i === 1) {
            //Coding
            var index = this.state.jsonPoSAPRentalCar.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling RentalCar*************");
            if (index.length === 0) {
              this.state.jsonPoSAPRentalCar.push({
                iBookingId: iBooking,
                sType: Constants.rentalCarBooking,
                sHtml: getCodingHtml,
              });
            }
          } else {
            //Special Charges
            var index = this.state.jsonPoSAPRentalCarSplCharges.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling RentalCar*************");
            if (index.length === 0) {
              this.state.jsonPoSAPRentalCarSplCharges.push({
                iBookingId: iBooking,
                sType: Constants.rentalCarBooking,
                sHtml: getCodingHtml,
              });
            }
            // console.log("jsonPoSAPRentalCarSplCharges", this.state.jsonPoSAPHotelSplCharges)
          }
        } else {
          getCodingHtml = await this.loadtaCodingRowCarService(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtmlCarService", getCodingHtml)
          if (i === 1) {
            //Coding
            var index = this.state.jsonPoSAPCarService.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling CarService*************");
            if (index.length === 0) {
              this.state.jsonPoSAPCarService.push({
                iBookingId: iBooking,
                sType: Constants.carServiceBooking,
                sHtml: getCodingHtml,
              });
            }
          } else {
            //Special Charges
            var index = this.state.jsonPoSAPCarServiceSplCharges.filter(
              (e) => (e.iBookingId = iBooking)
            );
            // console.log("*****Calling CarService*************");
            if (index.length === 0) {
              this.state.jsonPoSAPCarServiceSplCharges.push({
                iBookingId: iBooking,
                sType: Constants.carServiceBooking,
                sHtml: getCodingHtml,
              });
            }
          }
        }
        // console.log("JSON Result POSAP Air:", this.state.jsonPoSAPAir)
        // console.log("JSON Result POSAP AirRail jsonPoSAPAirSplCharges:", this.state.jsonPoSAPAirSplCharges)
        // console.log("JSON Result Display Air:", this.state.jsonPoSAPAir[0].sHtml)
        // console.log("JSON Result POSAP Hotel:", this.state.jsonPoSAPHotel)
        // console.log("JSON Result Display Hotel:", this.state.jsonPoSAPHotel[0].sHtml)
        // console.log("JSON Result POSAP RentalCar:", this.state.jsonPoSAPRentalCar)
        // console.log("JSON Result Display RentalCar:", this.state.jsonPoSAPRentalCar[0].sHtml)
        // console.log("JSON Result POSAP Car Service:", this.state.jsonPoSAPCarService)
        // console.log("JSON Result Display Car Service:", this.state.jsonPoSAPCarService[0].sHtml)
      } //Loop end
    }
  }

  async getCodingAir(
    iBooking,
    sBooking,
    bCoding,
    prevID,
    prevType,
    sc_amount,
    bill_type_id
  ) {
    let sType, id, sIncomplete;
    let strHtml = "";
    var exitLoop = false;
    let taAInclude = new TAInclude();
    let fields = this.state.fields;

    // console.log("*****Initiate Air*************");
    fields["bCoding"] = fields["bCodeAir"];
    // console.log("getCodingAir-bCoding", fields["bCoding"]);
    this.setState({ fields });

    if (sessionStorage.getItem("ta_is_corporate_card") === "false") {
      const generalLoop = [1, 2];
      for (let i of generalLoop) {
        // console.log("index", i)
        if (i === 2 && sc_amount <= 0) {
          exitLoop = true;
        }
        if (!exitLoop) {
          // console.log("index: ", i);
          strHtml = "";
          // console.log("*****Looping Air*************");
          if (i === 1) {
            //AIR
            sType = "AIR";
            fields["bAirOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.airBooking
            );
            sIncomplete = this.state.fields.bAirOK ? "" : "Incomplete";
            // console.log("AirOk", fields['bAirOK'])
            // console.log("Air getcoding:", sIncomplete);
          } //ASP
          else {
            sType = "ASP";
            fields["bAspOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.airBooking
            );
            sIncomplete = this.state.fields.bAspOK ? "" : "Incomplete";
          }

          id = prevType === sType ? prevID : "";

          var str = i === 1 ? "Coding" : "Special Charges Coding";
          // console.log("Start - getPoSapHtml")

          let getCodingHtml;

          getCodingHtml = await this.loadtaCodingRowAir(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtmlAir: ", getCodingHtml + "##" + str + "," + i)
          // console.log("begin ibooking Air :", iBooking)
          var index = 0;
          if (i === 1) {
            //Coding
            // console.log("json coding Air ibooking Air :", iBooking)
            // console.log("*****Calling Air*************");

            if (this.state.jsonPoSAPAir.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPAir: [
                  ...prevState.jsonPoSAPAir,
                  {
                    iBookingId: iBooking,
                    sType: Constants.airBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPAir.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPAir: [
            //         ...prevState.jsonPoSAPAir,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.airBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });
            if (this.state.jsonPoSAPAir[0].iBookingId !== iBooking) {
              this.setState((prevState) => ({
                jsonPoSAPAir: [
                  ...prevState.jsonPoSAPAir,
                  {
                    iBookingId: iBooking,
                    sType: Constants.airBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
          } //Special Charges
          else {
            // console.log("json SplCharges Air ibooking Air :", iBooking)
            if (this.state.jsonPoSAPAirSplCharges.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPAirSplCharges: [
                  ...prevState.jsonPoSAPAirSplCharges,
                  {
                    iBookingId: iBooking,
                    sType: Constants.airBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPAirSplCharges.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPAirSplCharges: [
            //         ...prevState.jsonPoSAPAirSplCharges,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.airBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });

              if (this.state.jsonPoSAPAirSplCharges[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPAirSplCharges: [
                    ...prevState.jsonPoSAPAirSplCharges,
                    {
                      iBookingId: iBooking,
                      sType: Constants.airBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
          }

          // console.log("JSON Result POSAP AirRail:", this.state.jsonPoSAPAir)
          // console.log("JSON Result POSAP AirRail jsonPoSAPAirSplCharges:", this.state.jsonPoSAPAirSplCharges)
          // console.log("JSON Result Display Air:", this.state.jsonPoSAPAir[0].sHtml)
        }
      } //Loop end
    }
  }
  async getCodingHotel(
    iBooking,
    sBooking,
    bCoding,
    prevID,
    prevType,
    sc_amount,
    bill_type_id
  ) {
    let sType, id, sIncomplete;
    let strHtml = "";
    var exitLoop = false;
    let taAInclude = new TAInclude();
    let fields = this.state.fields;

    // console.log("*****Initiate Hotel*************");
    fields["bCoding"] = fields["bCodeHotel"];
    // console.log("getCodingHotel-bCoding", fields["bCoding"]);

    bCoding = fields["bCoding"];
    this.setState({ fields });

    if (sessionStorage.getItem("ta_is_corporate_card") === "false") {
      const generalLoop = [1, 2];
      for (let i of generalLoop) {
        // console.log("index Hotel", i)
        if (i === 2 && sc_amount <= 0) {
          exitLoop = true;
        }
        if (!exitLoop) {
          // console.log("index: ", i);
          strHtml = "";

          // console.log("*****Looping Hotel*************");
          if (i === 1) {
            //HTL
            sType = "HTL";
            fields["bHtlOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.hotelBooking
            );
            // console.log("count:","6")
            sIncomplete = this.state.fields.bHtlOK ? "" : "Incomplete";
          } //HSP
          else {
            sType = "HSP";
            fields["bHspOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.hotelBooking
            );
            // console.log("count:","7")
            sIncomplete = this.state.fields.bHspOK ? "" : "Incomplete";
          }

          id = prevType === sType ? prevID : "";

          var str = i === 1 ? "Coding" : "Special Charges Coding";
          // console.log("Start - getPoSapHtml Hotel", str)

          let getCodingHtml;

          getCodingHtml = await this.loadtaCodingRowHotel(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtml", getCodingHtml)
          if (i === 1) {
            //Coding
            // console.log("*****Calling Hotel*************");
            if (this.state.jsonPoSAPHotel.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPHotel: [
                  ...prevState.jsonPoSAPHotel,
                  {
                    iBookingId: iBooking,
                    sType: Constants.hotelBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPHotel.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPHotel: [
            //         ...prevState.jsonPoSAPHotel,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.hotelBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });

              if (this.state.jsonPoSAPHotel[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPHotel: [
                    ...prevState.jsonPoSAPHotel,
                    {
                      iBookingId: iBooking,
                      sType: Constants.hotelBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
          
          

          } //Special charges
          else {
            // console.log("*****Calling Hotel SplCharges*************");
            if (this.state.jsonPoSAPHotelSplCharges.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPHotelSplCharges: [
                  ...prevState.jsonPoSAPHotelSplCharges,
                  {
                    iBookingId: iBooking,
                    sType: Constants.hotelBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPHotelSplCharges.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPHotelSplCharges: [
            //         ...prevState.jsonPoSAPHotelSplCharges,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.hotelBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });


           
              if (this.state.jsonPoSAPHotelSplCharges[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPHotelSplCharges: [
                    ...prevState.jsonPoSAPHotelSplCharges,
                    {
                      iBookingId: iBooking,
                      sType: Constants.hotelBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
          


            // console.log("jsonPoSAPHotelSplCharges", this.state.jsonPoSAPHotelSplCharges)
          }

          // console.log("JSON Result POSAP Hotel:", this.state.jsonPoSAPHotel)
          // console.log("JSON Result Display Hotel:", this.state.jsonPoSAPHotelSplCharges)
        }
      } //Loop end
    }
  }
  async getCodingRentalCar(
    iBooking,
    sBooking,
    bCoding,
    prevID,
    prevType,
    sc_amount,
    bill_type_id
  ) {
    let sType, id, sIncomplete;
    let strHtml = "";
    var exitLoop = false;
    let taAInclude = new TAInclude();
    let fields = this.state.fields;

    // console.log("*****Initiate RentalCar*************");
    fields["bCoding"] = fields["bCodeRentalCar"];
    // console.log("getCodingRentalCar-bCoding", fields["bCoding"]);

    bCoding = fields["bCoding"];
    this.setState({ fields });

    if (sessionStorage.getItem("ta_is_corporate_card") === "false") {
      const generalLoop = [1, 2];
      for (let i of generalLoop) {
        // console.log("index Rental", i)
        if (i === 2 && sc_amount <= 0) {
          exitLoop = true;
        }
        if (!exitLoop) {
          // console.log("index: ", i);
          strHtml = "";

          // console.log("*****Looping Rental Car*************");
          if (i === 1) {
            //CAR
            sType = "CAR";
            fields["bCarOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.rentalCarBooking
            );
            // console.log("count:","8")
            sIncomplete = this.state.fields.bCarOK ? "" : "Incomplete";
          } //CSP
          else {
            sType = "CSP";
            fields["bCspOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.rentalCarBooking
            );
            // console.log("count:","9")
            sIncomplete = this.state.fields.bCspOK ? "" : "Incomplete";
          }
          id = prevType === sType ? prevID : "";

          var str = i === 1 ? "Coding" : "Special Charges Coding";
          // console.log("Start - getPoSapHtml")

          let getCodingHtml;

          getCodingHtml = await this.loadtaCodingRowRentalCar(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtml", getCodingHtml)

          if (i === 1) {
            //Coding
            // console.log("*****Calling RentalCar*************");
            if (this.state.jsonPoSAPRentalCar.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPRentalCar: [
                  ...prevState.jsonPoSAPRentalCar,
                  {
                    iBookingId: iBooking,
                    sType: Constants.rentalCarBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPRentalCar.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPRentalCar: [
            //         ...prevState.jsonPoSAPRentalCar,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.rentalCarBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });

              if (this.state.jsonPoSAPRentalCar[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPRentalCar: [
                    ...prevState.jsonPoSAPRentalCar,
                    {
                      iBookingId: iBooking,
                      sType: Constants.rentalCarBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
           

          } //Spcial charges
          else {
            // console.log("*****Calling RentalCar*************");
            if (this.state.jsonPoSAPRentalCarSplCharges.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPRentalCarSplCharges: [
                  ...prevState.jsonPoSAPRentalCarSplCharges,
                  {
                    iBookingId: iBooking,
                    sType: Constants.rentalCarBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPRentalCarSplCharges.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPRentalCarSplCharges: [
            //         ...prevState.jsonPoSAPRentalCarSplCharges,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.rentalCarBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });

              if (this.state.jsonPoSAPRentalCarSplCharges[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPRentalCarSplCharges: [
                    ...prevState.jsonPoSAPRentalCarSplCharges,
                    {
                      iBookingId: iBooking,
                      sType: Constants.rentalCarBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
        

          }

          // console.log("JSON Result POSAP RentalCar:", this.state.jsonPoSAPRentalCar)
          // console.log("JSON Result Display RentalCar:", this.state.jsonPoSAPRentalCarSplCharges)
        }
      } //Loop end
    }
  }
  async getCodingCarService(
    iBooking,
    sBooking,
    bCoding,
    prevID,
    prevType,
    sc_amount,
    bill_type_id
  ) {
    let sType, id, sIncomplete;
    let strHtml = "";
    var exitLoop = false;
    let taAInclude = new TAInclude();
    let fields = this.state.fields;

    // console.log("*****Initiate Car Service*************");
    fields["bCoding"] = fields["bCodeCarService"];
    // console.log("getCodingCarService-bCoding", fields["bCoding"]);

    bCoding = fields["bCoding"];
    this.setState({ fields });

    if (sessionStorage.getItem("ta_is_corporate_card") === "false") {
      const generalLoop = [1, 2];
      for (let i of generalLoop) {
        // console.log("Car Service index", i);
        // console.log("Car Service Amount:", sc_amount);
        // console.log("Car Service exitLoop:", exitLoop);
        if (i === 2 && sc_amount <= 0) {
          exitLoop = true;
        }
        if (!exitLoop) {
          // console.log("index: ", i);
          strHtml = "";

          // console.log("*****Looping Car Service*************");
          if (i === 1) {
            //SVC
            sType = "SVC";
            fields["bSvcOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.carServiceBooking
            );
            // console.log("count:","10")
            sIncomplete = this.state.fields.bSvcOK ? "" : "Incomplete";
          } //SSP
          else {
            sType = "SSP";
            fields["bSspOK"] = await taAInclude.taCodingOKRequest(
              sessionStorage.getItem("ta")!,
              iBooking,
              sType,
              Constants.carServiceBooking
            );
            // console.log("count:","11")
            sIncomplete = this.state.fields.bSspOK ? "" : "Incomplete";
          }
          id = prevType === sType ? prevID : "";

          var str = i === 1 ? "Coding" : "Special Charges Coding";
          // console.log("Start - getPoSapHtml")

          let getCodingHtml;

          getCodingHtml = await this.loadtaCodingRowCarService(
            iBooking,
            sBooking,
            bCoding,
            sType,
            str,
            sIncomplete,
            bill_type_id,
            i,
            prevType
          );
          // console.log("End - getPoSapHtml", getCodingHtml)

          if (i === 1) {
            //Coding
            // console.log("*****Calling CarService*************");
            if (this.state.jsonPoSAPCarService.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPCarService: [
                  ...prevState.jsonPoSAPCarService,
                  {
                    iBookingId: iBooking,
                    sType: Constants.carServiceBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPCarService.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPCarService: [
            //         ...prevState.jsonPoSAPCarService,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.carServiceBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });

            // this.state.jsonPoSAPCarService.map((prop) => {
              if ( this.state.jsonPoSAPCarService[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPCarService: [
                    ...prevState.jsonPoSAPCarService,
                    {
                      iBookingId: iBooking,
                      sType: Constants.carServiceBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
            // });

          } //Spcial charges
          else {
            // console.log("*****Calling CarService*************");
            if (this.state.jsonPoSAPCarServiceSplCharges.length === 0) {
              this.setState((prevState) => ({
                jsonPoSAPCarServiceSplCharges: [
                  ...prevState.jsonPoSAPCarServiceSplCharges,
                  {
                    iBookingId: iBooking,
                    sType: Constants.carServiceBooking,
                    sHtml: getCodingHtml,
                  },
                ],
              }));
            }
            // this.state.jsonPoSAPCarServiceSplCharges.map((prop) => {
            //   if (prop.iBookingId !== iBooking) {
            //     this.setState((prevState) => ({
            //       jsonPoSAPCarServiceSplCharges: [
            //         ...prevState.jsonPoSAPCarServiceSplCharges,
            //         {
            //           iBookingId: iBooking,
            //           sType: Constants.carServiceBooking,
            //           sHtml: getCodingHtml,
            //         },
            //       ],
            //     }));
            //   }
            // });

              if ( this.state.jsonPoSAPCarServiceSplCharges[0].iBookingId !== iBooking) {
                this.setState((prevState) => ({
                  jsonPoSAPCarServiceSplCharges: [
                    ...prevState.jsonPoSAPCarServiceSplCharges,
                    {
                      iBookingId: iBooking,
                      sType: Constants.carServiceBooking,
                      sHtml: getCodingHtml,
                    },
                  ],
                }));
              }
            
          }

          // console.log("JSON Result POSAP Car Service:", this.state.jsonPoSAPCarService)
          // console.log("JSON Result Display Car Service:", this.state.jsonPoSAPCarServiceSplCharges)
        }
      } //Loop end
    }
  }
  async taCodingRow(
    iHeader,
    bCoding,
    authreq_id,
    travel_id,
    travel_type,
    sBooking,
    i
  ) {
    // console.log("this.state.fields.taCarService", this.state.fields.taCarService)
    var data = {
      authreqId: authreq_id,
      sBooking: sBooking,
      taCarService2: this.state.fields.taCarService,
      travelId: travel_id,
      travelType: travel_type,
    };
    // console.log("getTACoding begin:",data)

    let codingSummaryController = new CodingSummaryController();
    return await codingSummaryController
      .getTACoding(data)
      .then(async (response) => {
        // console.log("getTACoding - Before Response:", sBooking)
        // console.log("getTACoding - Response:", response)
        if (response !== undefined) {
          // console.log("getTACoding - :", sBooking)
          // console.log("getTACoding :", response)
          if (iHeader === 2 && response.recordset.length === 0) {
            return (
              <>
                <tr style={{ height: "20px" }}>
                  <td colSpan={8} style={{ backgroundColor: "silver" }}>
                    No Coding
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td colSpan={8}>&nbsp;</td>
                </tr>
              </>
            );
          }
          if (
            (iHeader === 1 &&
              (response.recordset.length > 0 || travel_type !== "HTR")) ||
            ((iHeader === 0 || iHeader === 2) && response.recordset.length > 0)
          ) {
            if (iHeader === 0) {
              //<tr style={{height:"20px"}}>
              //    <td colSpan={8} style={{color:"white",backgroundColor:"navy"}}>{sBooking}</td>
              //</tr>
            }

            var col2, col3, col4, col5;
            if (
              sessionStorage.getItem("ta_expense_type_id") === "1" ||
              sessionStorage.getItem("ta_expense_type_id") === "2" ||
              sessionStorage.getItem("ta_expense_type_id") === "10" ||
              sessionStorage.getItem("ta_expense_type_id") === "11" ||
              sessionStorage.getItem("ta_expense_type_id") === "1000"
            ) {
              col2 = " ";
              col3 = "GL Account #";
              col4 = "WBS Code";
              col5 = "Project #";
            } else {
              col2 = "Cost Center";
              col3 = "GL Account #";
              col4 = "WBS";
              col5 = "Employee/Project #";
            }
            return (
              <>
                <tr style={{ height: "20px" }}>
                  <td style={{ width: "10%", backgroundColor: "silver" }}>
                    Profit Center
                  </td>
                  <td style={{ width: "10%", backgroundColor: "silver" }}>
                    {col2}
                  </td>
                  <td style={{ width: "10%", backgroundColor: "silver" }}>
                    {col3}
                  </td>
                  <td style={{ width: "15%", backgroundColor: "silver" }}>
                    {col4}
                  </td>
                  <td style={{ width: "15%", backgroundColor: "silver" }}>
                    {col5}
                  </td>
                  <td style={{ width: "10%", backgroundColor: "silver" }}>
                    PO #
                  </td>
                  <td style={{ width: "10%", backgroundColor: "silver" }}>
                    PO Line
                  </td>
                  <td style={{ width: "10%", backgroundColor: "silver" }}>
                    Amount
                  </td>
                  {bCoding ? (
                    <td
                      style={{ width: "15%", backgroundColor: "silver" }}
                    ></td>
                  ) : (
                    ""
                  )}
                </tr>
                {await this.loadPoSapHtml(
                  response.recordset,
                  bCoding,
                  travel_type,
                  travel_id,
                  sBooking,
                  i
                )}
              </>
            );
          }
        } else {
          if (iHeader === 2) {
            return (
              <>
                <tr style={{ height: "20px" }}>
                  <td colSpan={8} style={{ backgroundColor: "silver" }}>
                    No Coding
                  </td>
                </tr>
                <tr style={{ height: "20px" }}>
                  <td colSpan={8}>&nbsp;</td>
                </tr>
              </>
            );
          } else {
            return <></>;
          }
        }
        // console.log("getTACoding end")
      })
      .catch((error) => {
        console.error("There was an error", error);
      });
  }
  async loadPoSapHtml(
    jsonGetTACoding,
    bCoding,
    travel_type,
    iBooking,
    sBooking,
    i
  ) {
    return await jsonGetTACoding.map((prop, key) => {
      return (
        <tr style={{ height: "20px" }}>
          <td>{prop.business_unit}</td>
          <td>
            {sessionStorage.getItem("ta_expense_type_id") === "100"
              ? prop.cost_center
              : ""}
          </td>
          <td>{prop.object_account}</td>
          <td>{prop.sub_account}</td>
          <td>{prop.project_emp_number}</td>
          <td>{prop.cts_number}</td>
          <td>{prop.po_line}</td>
          <td>{this.formatCurrency(prop.amount)}</td>
          {this.loadDeleteButton(
            iBooking,
            sBooking,
            bCoding,
            travel_type,
            prop.coding_id,
            i
          )}
        </tr>
      );
    });
  }
  loadDeleteButton(iBooking, sBooking, bCoding, travel_type, iCodingId, i) {
    if (bCoding) {
      return (
        <td align="right">
          {travel_type !== "HTR" ? (
            <Button
              variant="danger"
              style={{ height: "auto", marginRight: "5px" }}
              size="sm"
              title="Click to delete this line of coding"
              onClick={(e) =>
                this.deleteCoding(iCodingId, iBooking, sBooking, i)
              }
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
        </td>
      );
    } else {
      return <></>;
    }
  }

  async loadtaCodingRowAir(
    iBooking,
    sBooking,
    bCoding,
    sType,
    str,
    sIncomplete,
    bill_type_id,
    i,
    prevType
  ) {
    // console.log("loadtaCodingRowAir :", str + ',i:'+i);
    prevType = bCoding ? sType : "";
    return (
      <>
        <tr>
          <td>&nbsp;</td>
        </tr>
        {bCoding ? (
          <tr style={{ height: "20%" }}>
            <td colSpan={8} style={{ color: "red" }}>
              {str + " " + sIncomplete}
            </td>
          </tr>
        ) : (
          ""
        )}
        <tr>
          <td colSpan={8}>
            <table style={{ border: "0px", width: "100%", padding: "0px" }}>
              {bill_type_id === "DB" && i === 1
                ? await this.taCodingRow(
                    1,
                    bCoding,
                    sessionStorage.getItem("ta"),
                    iBooking,
                    "HTR",
                    sBooking,
                    i
                  )
                : ""}
              {await this.taCodingRow(
                1,
                bCoding,
                sessionStorage.getItem("ta"),
                iBooking,
                sType,
                sBooking,
                i
              )}
              <tr>
                <td>&nbsp;</td>
              </tr>
              {bCoding ? (
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"PONumber" + i + "_" + iBooking}
                      name={"PONumber" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"POLine" + i + "_" + iBooking}
                      name={"POLine" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <tr>
                      <td>$</td>
                      <td>
                        <Form.Control
                          type="text"
                          id={"amount" + i + "_" + iBooking}
                          name={"amount" + i + "_" + iBooking}
                          className="form-control-sm"
                          style={{ border: "1px solid #bebebe" }}
                          defaultValue="0.00"
                        ></Form.Control>
                      </td>
                    </tr>
                  </td>
                  <td align="right">
                    <Button
                      variant="primary"
                      style={{ height: "auto", marginRight: "5px" }}
                      size="sm"
                      id={"save" + i + "_" + iBooking}
                      name={"save" + i + "_" + iBooking}
                      title="Click to add a line of coding"
                      onClick={async () =>
                        await this.insertCoding(iBooking, sBooking, sType, i)
                      }
                    >
                      Apply
                    </Button>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {i === 2 ? (
                <tr>
                  <td
                    colSpan={9}
                    style={{
                      borderBottom: "2px solid navy",
                      height: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  ></td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </td>
        </tr>
      </>
    );
  }

  async loadtaCodingRowHotel(
    iBooking,
    sBooking,
    bCoding,
    sType,
    str,
    sIncomplete,
    bill_type_id,
    i,
    prevType
  ) {
    prevType = bCoding ? sType : "";
    return (
      <>
        <tr>
          <td>&nbsp;</td>
        </tr>
        {bCoding ? (
          <tr style={{ height: "20%" }}>
            <td colSpan={8} style={{ color: "red" }}>
              {str + " " + sIncomplete}
            </td>
          </tr>
        ) : (
          ""
        )}
        <tr>
          <td colSpan={8}>
            <table style={{ border: "0px", width: "100%", padding: "0px" }}>
              {bill_type_id === "DB" && i === 1
                ? await this.taCodingRow(
                    1,
                    bCoding,
                    sessionStorage.getItem("ta"),
                    iBooking,
                    "HTR",
                    sBooking,
                    i
                  )
                : ""}
              {await this.taCodingRow(
                1,
                bCoding,
                sessionStorage.getItem("ta"),
                iBooking,
                sType,
                sBooking,
                i
              )}
              <tr>
                <td>&nbsp;</td>
              </tr>
              {bCoding ? (
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"PONumber" + i + "_" + iBooking}
                      name={"PONumber" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"POLine" + i + "_" + iBooking}
                      name={"POLine" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <tr>
                      <td>$</td>
                      <td>
                        <Form.Control
                          type="text"
                          id={"amount" + i + "_" + iBooking}
                          name={"amount" + i + "_" + iBooking}
                          className="form-control-sm"
                          style={{ border: "1px solid #bebebe" }}
                          defaultValue="0.00"
                        ></Form.Control>
                      </td>
                    </tr>
                  </td>
                  <td align="right">
                    <Button
                      variant="primary"
                      style={{ height: "auto", marginRight: "5px" }}
                      size="sm"
                      id={"save" + i + "_" + iBooking}
                      name={"save" + i + "_" + iBooking}
                      title="Click to add a line of coding"
                      onClick={() =>
                        this.insertCoding(iBooking, sBooking, sType, i)
                      }
                    >
                      Apply
                    </Button>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {i === 2 ? (
                <tr>
                  <td
                    colSpan={9}
                    style={{
                      borderBottom: "2px solid navy",
                      height: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  ></td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </td>
        </tr>
      </>
    );
  }

  async loadtaCodingRowRentalCar(
    iBooking,
    sBooking,
    bCoding,
    sType,
    str,
    sIncomplete,
    bill_type_id,
    i,
    prevType
  ) {
    prevType = bCoding ? sType : "";
    return (
      <>
        <tr>
          <td>&nbsp;</td>
        </tr>
        {bCoding ? (
          <tr style={{ height: "20%" }}>
            <td colSpan={8} style={{ color: "red" }}>
              {str + " " + sIncomplete}
            </td>
          </tr>
        ) : (
          ""
        )}
        <tr>
          <td colSpan={8}>
            <table style={{ border: "0px", width: "100%", padding: "0px" }}>
              {bill_type_id === "DB" && i === 1
                ? await this.taCodingRow(
                    1,
                    bCoding,
                    sessionStorage.getItem("ta"),
                    iBooking,
                    "HTR",
                    sBooking,
                    i
                  )
                : ""}
              {await this.taCodingRow(
                1,
                bCoding,
                sessionStorage.getItem("ta"),
                iBooking,
                sType,
                sBooking,
                i
              )}
              <tr>
                <td>&nbsp;</td>
              </tr>
              {bCoding ? (
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"PONumber" + i + "_" + iBooking}
                      name={"PONumber" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"POLine" + i + "_" + iBooking}
                      name={"POLine" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <tr>
                      <td>$</td>
                      <td>
                        <Form.Control
                          type="text"
                          id={"amount" + i + "_" + iBooking}
                          name={"amount" + i + "_" + iBooking}
                          className="form-control-sm"
                          style={{ border: "1px solid #bebebe" }}
                          defaultValue="0.00"
                        ></Form.Control>
                      </td>
                    </tr>
                  </td>
                  <td align="right">
                    <Button
                      variant="primary"
                      style={{ height: "auto", marginRight: "5px" }}
                      size="sm"
                      id={"save" + i + "_" + iBooking}
                      name={"save" + i + "_" + iBooking}
                      title="Click to add a line of coding"
                      onClick={() =>
                        this.insertCoding(iBooking, sBooking, sType, i)
                      }
                    >
                      Apply
                    </Button>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {i === 2 ? (
                <tr>
                  <td
                    colSpan={9}
                    style={{
                      borderBottom: "2px solid navy",
                      height: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  ></td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </td>
        </tr>
      </>
    );
  }
  async loadtaCodingRowCarService(
    iBooking,
    sBooking,
    bCoding,
    sType,
    str,
    sIncomplete,
    bill_type_id,
    i,
    prevType
  ) {
    prevType = bCoding ? sType : "";
    return (
      <>
        <tr>
          <td>&nbsp;</td>
        </tr>
        {bCoding ? (
          <tr style={{ height: "20%" }}>
            <td colSpan={8} style={{ color: "red" }}>
              {str + " " + sIncomplete}
            </td>
          </tr>
        ) : (
          ""
        )}
        <tr>
          <td colSpan={8}>
            <table style={{ border: "0px", width: "100%", padding: "0px" }}>
              {bill_type_id === "DB" && i === 1
                ? await this.taCodingRow(
                    1,
                    bCoding,
                    sessionStorage.getItem("ta"),
                    iBooking,
                    "HTR",
                    sBooking,
                    i
                  )
                : ""}
              {await this.taCodingRow(
                1,
                bCoding,
                sessionStorage.getItem("ta"),
                iBooking,
                sType,
                sBooking,
                i
              )}
              <tr>
                <td>&nbsp;</td>
              </tr>
              {bCoding ? (
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"PONumber" + i + "_" + iBooking}
                      name={"PONumber" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      id={"POLine" + i + "_" + iBooking}
                      name={"POLine" + i + "_" + iBooking}
                      className="form-control-sm"
                      style={{ border: "1px solid #bebebe" }}
                    ></Form.Control>
                  </td>
                  <td>
                    <tr>
                      <td>$</td>
                      <td>
                        <Form.Control
                          type="text"
                          id={"amount" + i + "_" + iBooking}
                          name={"amount" + i + "_" + iBooking}
                          className="form-control-sm"
                          style={{ border: "1px solid #bebebe" }}
                          defaultValue="0.00"
                        ></Form.Control>
                      </td>
                    </tr>
                  </td>
                  <td align="right">
                    <Button
                      variant="primary"
                      style={{ height: "auto", marginRight: "5px" }}
                      size="sm"
                      id={"save" + i + "_" + iBooking}
                      name={"save" + i + "_" + iBooking}
                      title="Click to add a line of coding"
                      onClick={() =>
                        this.insertCoding(iBooking, sBooking, sType, i)
                      }
                    >
                      Apply
                    </Button>
                  </td>
                </tr>
              ) : (
                ""
              )}
              {i === 2 ? (
                <tr>
                  <td
                    colSpan={9}
                    style={{
                      borderBottom: "2px solid navy",
                      height: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  ></td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </td>
        </tr>
      </>
    );
  }

  validateApplyForm = (poline, ponumber, amount): boolean => {
    let formIsValid = true;
    poline.style.backgroundColor = "white";
    ponumber.style.backgroundColor = "white";
    amount.style.backgroundColor = "white";

    if (ponumber.value.trim() === "") {
      ponumber.style.backgroundColor = "#ffff00";
      ponumber.focus();
      formIsValid = false;
      alert("Please enter a PO Number");
    }
    if (poline.value.trim() === "" && formIsValid) {
      poline.style.backgroundColor = "#ffff00";
      poline.focus();
      formIsValid = false;
      alert("Please enter a PO Line");
    }
    var msg = Utils.validMoney(amount.value, "Please Enter a Valid Amount");
    if (msg !== "" && formIsValid) {
      amount.style.backgroundColor = "#ffff00";
      amount.focus();
      formIsValid = false;
      alert(msg);
    } else {
      if (amount.value <= 0 && formIsValid) {
        amount.style.backgroundColor = "#ffff00";
        amount.focus();
        formIsValid = false;
        alert("Amount must be greater than zero");
      }
    }
    if (!formIsValid) {
      let modal = this.state.modal;
      modal["modalSpinnerShow"] = false;
      this.setState({ modal });
    }
    return formIsValid;
  };

  insertCoding(iBooking, sBooking, sType, i) {
 
    //alert("Inside insert Coding");
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({ modal });
    const poLineObj: HTMLInputElement = document.getElementById(
      "POLine" + i + "_" + iBooking
    ) as HTMLInputElement;
    const poNumberObj: HTMLInputElement = document.getElementById(
      "PONumber" + i + "_" + iBooking
    ) as HTMLInputElement;
    const AmountObj: HTMLInputElement = document.getElementById(
      "amount" + i + "_" + iBooking
    ) as HTMLInputElement;
    var poline = poLineObj.value;
    var ponumber = poNumberObj.value;
    let amount = AmountObj.value;
    var newValue = amount;
    newValue = newValue.replace("$", "");
    newValue = newValue.replace(",", "");
    amount = newValue;
    if (this.validateApplyForm(poLineObj, poNumberObj, AmountObj)) {
      if (
        sessionStorage.getItem("ta_expense_type_id") === "1" ||
        sessionStorage.getItem("ta_expense_type_id") === "2" ||
        sessionStorage.getItem("ta_expense_type_id") === "10" ||
        sessionStorage.getItem("ta_expense_type_id") === "11" ||
        sessionStorage.getItem("ta_expense_type_id") === "100" ||
        sessionStorage.getItem("ta_expense_type_id") === "1000"
      ) {
        var data1 = {
          poline: poline,
          ponumber: ponumber,
        };
        // console.log("BEGIN call POREST API", data1);
        let commonController = new CommonController();
        commonController
          .callPORestAPI(data1)
          .then(async (response) => {
            // console.log("Call PORest API", response)
            if (response.code === 200 && response.success) {
              var msg = "";
              var result = response.recordsets;

              if (result.eta === "X") {
                if (parseFloat(amount) > parseFloat(result.poAmount)) {
                  alert("Insufficient fund.");
                  modal["modalSpinnerShow"] = false;
                  this.setState({ modal });
                } else {
                  var projectName = result.projectName;
                  var projectNumber = result.projectNumber;
                  var employeeNumber = result.employeeNumber;
                  var artist = result.artist;
                  msg = "Values retrieved from UniPort/SAP<br>";
                  if (sessionStorage.getItem("ta_expense_type_id") === "100") {
                    if (projectName !== "") {
                      msg = msg + "Project Name: " + projectName + "<br>";
                    }
                    if (projectNumber !== "") {
                      msg =
                        msg +
                        "Project/Employee Number: " +
                        projectNumber +
                        "<br>";
                    } else {
                      msg =
                        msg +
                        "Project/Employee Number: " +
                        employeeNumber +
                        "<br>";
                    }
                  } else {
                    msg = msg + "Project Name: " + projectName + "<br>";
                    msg = msg + "Project Number: " + projectNumber + "<br>";
                    msg = msg + "Artist: " + artist + "<br>";
                  }
                  msg =
                    msg +
                    "Click OK to override your previous entries, Cancel otherwise.";
                  //if (await window.confirm(msg)) {
                  // console.log("Message :", msg);
                  var flag = await confirm(parse(msg), {
                    title: "Confirm PO",
                    okText: "Yes",
                    cancelText: "No",
                  });
                  if (flag) {
                    var artistProjectName = "";
                    var artistProjectNumber = "";
                    var artistName = "";
                    if (projectName != "") {
                      //const elMsg:HTMLElement = document.getElementById('resultMsg'+key) as HTMLElement;
                      artistProjectName = projectName;
                    }
                    if (projectNumber != "") {
                      artistProjectNumber = projectNumber;
                    } else {
                      if (employeeNumber != "") {
                        artistProjectNumber = employeeNumber;
                      }
                    }
                    if (artist != "") {
                      artistName = artist;
                    }
                    let codingSummaryController = new CodingSummaryController();
                    var insertUpdateCodingData = {
                      uid: this.state.fields.sessionTA,
                      bookingId: iBooking,
                      travelType: sType,
                      businessUnit: result.profitCenter,
                      objectAccount: result.glAccount,
                      subAccount: result.wbsCode,
                      artistProjectNumber: artistProjectNumber,
                      ctsNumber: ponumber,
                      amount: amount,
                      costCenter: result.costCenterName,
                      poLine: poline,
                      authreqId: this.state.fields.sessionTA,
                      projectName: artistProjectName,
                      artistName: artistName,
                      transFee: sessionStorage.getItem("trans_fee"),
                    };
                    // console.log("insert Update Coding Data", insertUpdateCodingData)
                    codingSummaryController
                      .insertUpdateCoding(insertUpdateCodingData)
                      .then((response) => {
                        // console.log("insert Update Coding response", response)
                        if (
                          response.codingsCount === 0 ||
                          response.codingsCount === "0"
                        ) {
                          var status = i === 1 ? "coding" : "splcharges";
                          //update header after coding.
                          //sessionStorage.setItem("ta_artist_project_number", artistProjectNumber)
                          //sessionStorage.setItem("ta_artist_project_name", artistProjectName)
                          //sessionStorage.setItem("ta_artist_name", artistName)
                          let travellerController = new TravellerController();
                          travellerController
                            .getTA(this.state.fields.sessionTA)
                            .then((response) => {
                              //alert("Apply pageload")
                              this.pageLoad(iBooking, sBooking, status);
                              
                            })
                            .catch((error) => {
                              modal["modalSpinnerShow"] = false;
                              this.setState({ modal });
                              console.error("There was an error!", error);
                            });
                        } else {
                          alert(
                            "You have entered a duplicate PO for this travel element"
                          );
                          modal["modalSpinnerShow"] = false;
                          this.setState({ modal });
                        }
                      })
                      .catch((error) => {
                        console.error(
                          "There was an error in insert update coding",
                          error
                        );
                        modal["modalSpinnerShow"] = false;
                        this.setState({ modal });
                      });
                  } else {
                    console.error("Cancel clicked...");
                    modal["modalSpinnerShow"] = false;
                    this.setState({ modal });
                  }
                }
              } else {
                alert("PO is not found or not checked for ETA in Uniport.");
                modal["modalSpinnerShow"] = false;
                this.setState({ modal });
              }
            } else {
              alert(response.message);
              modal["modalSpinnerShow"] = false;
              this.setState({ modal });
            }
          })
          .catch((error) => {
            console.error("There was an error", error);
            modal["modalSpinnerShow"] = false;
            this.setState({ modal });
          });
      } else {
        console.error("");
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
      }
    } else {
      console.error("Validation failed...");
      modal["modalSpinnerShow"] = false;
      this.setState({ modal });
    }
  }

  deleteCoding(iCoding, iBooking, sBooking, i) {
    // console.log("Delete Coding:", iCoding)
    let modal = this.state.modal;
    modal["modalSpinnerShow"] = true;
    this.setState({ modal });

    let codingSummaryController = new CodingSummaryController();
    codingSummaryController
      .deleteCoding(iCoding)
      .then(async (response) => {
        if (response.code === 400) {
          throw new Error(response.message);
        }
        //Delete Coding
        var status = i === 1 ? "coding" : "splcharges";
        let travellerController = new TravellerController();
        await travellerController
          .getTA(this.state.fields.sessionTA)
          .then((response) => {
            //alert("Apply pageload")
            this.pageLoad(iBooking, sBooking, status);
          })
          .catch((error) => {
            modal["modalSpinnerShow"] = false;
            this.setState({ modal });
            console.error("There was an error!", error);
          });
      })
      .catch((error) => {
        modal["modalSpinnerShow"] = false;
        this.setState({ modal });
        console.error("There was an error", error);
      });
  }
  
async loadPoSapValidationHtmlAir() {
    let prevBookingID = "";
    let prevType;
    let getCodingHtml;

    prevType = "";
    await this.state.uniquefields.uniqueAirBookingIds.map(
      async (uprop, ukey) => {
        let getAirBooking = await this.state.airBooking.filter(
          (f) => f.booking_id === uprop
        );
        // console.log("getAirBooking :", getAirBooking)

        await getAirBooking.map(async (mprop, keyVal) => {
          if (prevBookingID !== mprop.booking_id) {
            if (mprop.booking_id === prevBookingID) {
              getCodingHtml = await this.getCodingAir(
                mprop.booking_id,
                Constants.airBooking,
                this.state.fields.bCoding,
                prevBookingID,
                prevType,
                mprop.sc_amount,
                mprop.bill_type_id
              );
            } else {
              getCodingHtml = await this.getCodingAir(
                mprop.booking_id,
                Constants.airBooking,
                this.state.fields.bCoding,
                "",
                "",
                mprop.sc_amount,
                mprop.bill_type_id
              );
            }

            prevBookingID = mprop.booking_id;
          }
        });
      }
    );
    // console.log("******************End-loadPoSapValidationHtml")
  }
  async loadPoSapValidationHtmlHotel() {
    let prevBookingID = "";
    let prevType;
    let getCodingHtml;

    prevType = "";
    await this.state.uniquefields.uniqueHotelBookingIds.map(
      async (uprop, ukey) => {
        let getHotelBooking = await this.state.hotelBooking.filter(
          (f) => f.booking_id == uprop
        );
        // console.log("getHotelBooking :", getHotelBooking)

        await getHotelBooking.map(async (mprop, keyVal) => {
          if (prevBookingID !== mprop.booking_id) {
            if (mprop.booking_id === prevBookingID) {
              getCodingHtml = await this.getCodingHotel(
                mprop.booking_id,
                Constants.hotelBooking,
                this.state.fields.bCoding,
                prevBookingID,
                prevType,
                mprop.sc_amount,
                mprop.bill_type_id
              );
            } else {
              getCodingHtml = await this.getCodingHotel(
                mprop.booking_id,
                Constants.hotelBooking,
                this.state.fields.bCoding,
                "",
                "",
                mprop.sc_amount,
                mprop.bill_type_id
              );
            }

            prevBookingID = mprop.booking_id;
          }
        });
      }
    );

    // console.log("******************End-loadPoSapValidationHtml")
  }
  async loadPoSapValidationHtmlRentalCar() {
    let prevBookingID = "";
    let prevType;
    let getCodingHtml;

    prevType = "";
    await this.state.uniquefields.uniqueRentalCarBookingIds.map(
      async (uprop, ukey) => {
        let getRentalCarBooking = await this.state.rentalCarBooking.filter(
          (f) => f.booking_id == uprop
        );
        // console.log("getRentalCarBooking :", getRentalCarBooking)

        await getRentalCarBooking.map(async (mprop, keyVal) => {
          if (prevBookingID !== mprop.booking_id) {
            if (mprop.booking_id === prevBookingID) {
              getCodingHtml = await this.getCodingRentalCar(
                mprop.booking_id,
                Constants.rentalCarBooking,
                this.state.fields.bCoding,
                prevBookingID,
                prevType,
                mprop.sc_amount,
                mprop.bill_type_id
              );
            } else {
              getCodingHtml = await this.getCodingRentalCar(
                mprop.booking_id,
                Constants.rentalCarBooking,
                this.state.fields.bCoding,
                "",
                "",
                mprop.sc_amount,
                mprop.bill_type_id
              );
            }

            prevBookingID = mprop.booking_id;
          }
        });
      }
    );

    // console.log("******************End-loadPoSapValidationHtml")
  }
  async loadPoSapValidationHtmlCarService() {
    let prevBookingID = "";
    let prevType;
    let getCodingHtml;

    prevType = "SVC";
    await this.state.uniquefields.uniqueCarServiceBookingIds.map(
      async (uprop, ukey) => {
        let getCarServiceBooking = await this.state.carServiceBooking.filter(
          (f) => f.booking_id == uprop
        );
        // console.log("getCarServiceBooking :", getCarServiceBooking)

        await getCarServiceBooking.map(async (mprop, keyVal) => {
          if (prevBookingID !== mprop.booking_id) {
            if (mprop.booking_id === prevBookingID) {
              getCodingHtml = await this.getCodingCarService(
                mprop.booking_id,
                Constants.carServiceBooking,
                this.state.fields.bCoding,
                prevBookingID,
                prevType,
                mprop.sc_amount,
                mprop.bill_type_id
              );
            } else {
              getCodingHtml = await this.getCodingCarService(
                mprop.booking_id,
                Constants.carServiceBooking,
                this.state.fields.bCoding,
                "",
                "",
                mprop.sc_amount,
                mprop.bill_type_id
              );
            }

            prevBookingID = mprop.booking_id;
          }
        });
      }
    );

    // console.log("******************End-loadPoSapValidationHtml")
  }

  async createHtml() {
    return (
      <tr>
        <td>TEST ORDER - 2200149</td>
      </tr>
    );
  }
  onSubmit(e: any) {
    //Utils.getSoap('421175');
  }
  taExpenseType = () => {
    if (
      sessionStorage.getItem("ta_expense_type_id") === "1" ||
      sessionStorage.getItem("ta_expense_type_id") === "2" ||
      sessionStorage.getItem("ta_expense_type_id") === "10" ||
      sessionStorage.getItem("ta_expense_type_id") === "11"
    ) {
      return (
        sessionStorage.getItem("ta_artist_project_number") +
        "/" +
        sessionStorage.getItem("ta_artist_project_name") +
        "/" +
        sessionStorage.getItem("ta_artist_name")
      );
    } else if (
      sessionStorage.getItem("ta_expense_type_id") === "100" ||
      sessionStorage.getItem("ta_expense_type_id") === "1000"
    ) {
      if (sessionStorage.getItem("ta_artist_project_name") !== "") {
        return (
          sessionStorage.getItem("ta_artist_project_number") +
          "/" +
          sessionStorage.getItem("ta_artist_project_name")
        );
      } else {
        sessionStorage.getItem("ta_artist_project_name");
      }
    }
  };
  taHeader = () => {
    if (sessionStorage.getItem("bCoding")) {
      return (
        <div>
          <Row style={{ alignSelf: "center", display: "none" }}>
            <Col sm={12} style={{ color: "green" }}>
              All Reservations on the TA must be Reviewed before you can Submit.
            </Col>
          </Row>
          {sessionStorage.getItem("page") === "direct_bill" ? (
            <Row>
              <Col sm={6}>{sessionStorage.getItem("ta_label_desc")}</Col>
              <Col sm={6}></Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col sm={6}>{sessionStorage.getItem("ta_created_by")}</Col>
            <Col sm={6} style={{ alignSelf: "right" }}>
              {sessionStorage.getItem("ta_car_service_only") === "true"
                ? "CA#"
                : "TA#"}{" "}
              {sessionStorage.getItem("ta")}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>{this.taExpenseType()}</Col>
            <Col sm={6} style={{ alignSelf: "right" }}>
              {sessionStorage.getItem("ta_expense_type_desc")}
            </Col>
          </Row>
        </div>
      );
    }
  };
  //CodingSummary : AirLine
  loadAirHeader(prop) {
    /*return <tr>
                <td style={{backgroundColor:"silver",width:"20%"}}>Airline {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Class</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Departure</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Departing</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Arriving</td>
                </tr>*/
    var airLine = !prop.BCD_generated ? "Airline (Manual)" : "Airline";
    return (
      '<tr><td style="backgroundColor:silver;width:20%">' +
      airLine +
      '</td><td style="backgroundColor:silver;width:20%">Class</td><td style="backgroundColor:silver;width:20%">Departure</td><td style="backgroundColor:silver;width:20%">Departing</td><td style="backgroundColor:silver;width:20%">Arriving</td></tr>'
    );
  }
  loadAirLeg = (cprop) => {
    /*return <tr>
        <td>{cprop.airline}</td>
        {(cprop.travel_class==="Select Class")?
            <td style={{width:"20%",color: "red"}}>{cprop.travel_class}</td>
        :
            <td style={{width:"20%"}}>{cprop.travel_class}</td>
        }
        <td>{Utils.FormatDateTime(cprop.travel_date)}</td>
        <td>{cprop.from_location}<br></br>{cprop.from_time}{cprop.from_am_pm}</td>
        <td>{cprop.to_location}<br></br>{cprop.to_time}{cprop.to_am_pm}</td>
    </tr>*/
    var travelClass = "";
    if (cprop.travel_class === "Select Class") {
      travelClass =
        '<td style="width:20% ;color: red">' + cprop.travel_class + "</td>";
    } else {
      travelClass = '<td style="width:20%">' + cprop.travel_class + "</td>";
    }
    return (
      "<tr><td>" +
      cprop.airline +
      "</td>" +
      travelClass +
      "<td>" +
      Utils.FormatDateTime(cprop.travel_date) +
      "</td><td>" +
      cprop.from_location +
      "<br>" +
      cprop.from_time +
      " " +
      cprop.from_am_pm +
      "</td><td>" +
      cprop.to_location +
      "<br>" +
      cprop.to_time +
      " " +
      cprop.to_am_pm +
      "</td></tr>"
    );
  };
  airBookingHtml(prop) {
    var loadAirLeg = "";
    var loadAirHeader = "";
    //if more then travellers in the same booking id, take one bookid to load the summary.
    let filterAirBooking = this.state.airBooking.filter(
      (f) =>
        f.booking_id == prop.booking_id && f.traveller_id == prop.traveller_id
    );
    filterAirBooking.map((cprop, ckey) => {
      //if(ckey < Number(cprop.leg_count)){
      loadAirLeg = loadAirLeg + this.loadAirLeg(cprop);
      //}
    });
    loadAirHeader = this.loadAirHeader(prop);
    return (
      "<tbody>" +
      loadAirHeader +
      loadAirLeg +
      '<tr><td style="backgroundColor:#FFFF80;width:20%">Traveler</td><td style="backgroundColor:#FFFF80;width:20%">Purpose/Comments</td><td style="backgroundColor:#FFFF80;width:20%">Airfare</td><td style="backgroundColor:#FFFF80;width:20%"></td><td style="backgroundColor:#FFFF80;width:20%">Sub Total</td></tr></tbody>'
    );
  }
  //CodingSummary : Hotel
  loadHotelHeader(prop) {
    return (
      <tr>
        <td style={{ backgroundColor: "silver", width: "20%" }}>
          Hotel {!prop.BCD_generated ? " (Manual)" : <></>}
        </td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Billing</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>City</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Check-In</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Check-Out</td>
      </tr>
    );
  }
  hotelBookingHtml(prop) {
    return (
      <tbody>
        {this.loadHotelHeader(prop)}
        <tr>
          <td style={{ width: "20%" }}>{prop.hotel}</td>
          {prop.bill_type === "Select Billing Type" ? (
            <td style={{ width: "20%", color: "red" }}>{prop.bill_type}</td>
          ) : (
            <td style={{ width: "20%" }}>{prop.bill_type}</td>
          )}
          <td style={{ width: "20%" }}>{prop.city}</td>
          <td style={{ width: "20%" }}>{Utils.FormatDateTime(prop.in_date)}</td>
          <td style={{ width: "20%" }}>
            {Utils.FormatDateTime(prop.out_date)}
          </td>
        </tr>
        <tr>
          <td colSpan={5} style={{ width: "20%" }}>
            {prop.non_disputable ? (
              sessionStorage.getItem("ta_expense_type_id") !== "100" ||
              (sessionStorage.getItem("ta_expense_type_id") === "100" &&
                sessionStorage.getItem("ta_is_corporate_card") === "false") ? (
                <div className="text-danger">
                  All hotels are now Non-Disputable. Please code hotel purchase
                  orders to Vendor #328063.
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <div className="text-danger">
                (Disputable) All hotels are now Non-Disputable. Please code
                hotel purchase orders to Vendor #328063.
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>Traveler</td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            Purpose/Comments
          </td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>Rate</td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ backgroundColor: "#FFFF80", width: "50%" }}>
                  Tax
                </td>
                <td style={{ backgroundColor: "#FFFF80", width: "50%" }}>
                  Incidentals
                </td>
              </tr>
            </table>
          </td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            Sub Total
          </td>
        </tr>
      </tbody>
    );
  }
  //CodingSummary : Rental car
  loadRentalCarHeader(prop) {
    return (
      <tr>
        <td style={{ backgroundColor: "silver", width: "20%" }}>
          Rental Company/Car Type {!prop.BCD_generated ? " (Manual)" : <></>}
        </td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Billing</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>City</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Pick-Up</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Return</td>
      </tr>
    );
  }
  rentalCarBookingHtml(prop) {
    return (
      <tbody>
        {this.loadRentalCarHeader(prop)}
        <tr>
          <td style={{ width: "20%" }}>{prop.car_company}</td>
          {prop.bill_type === "Select Billing" ? (
            <td style={{ width: "20%", color: "red" }}>{prop.bill_type}</td>
          ) : (
            <td style={{ width: "20%" }}>{prop.bill_type}</td>
          )}
          <td style={{ width: "20%" }}>{prop.city}</td>
          <td style={{ width: "20%" }}>
            {Utils.FormatDate(prop.pick_up_date)}
          </td>
          <td style={{ width: "20%" }}>{Utils.FormatDate(prop.return_date)}</td>
        </tr>
        <tr>
          {prop.carTypeDesc === "Select Car Type" ? (
            <td style={{ width: "20%", color: "red" }}>{prop.carTypeDesc}</td>
          ) : (
            <td style={{ width: "20%" }}>{prop.carTypeDesc}</td>
          )}
          <td style={{ width: "20%" }}></td>
          <td style={{ width: "20%" }}></td>
          <td style={{ width: "20%" }}></td>
        </tr>
        <tr>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>Traveler</td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            Purpose/Comments
          </td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>Rate</td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ backgroundColor: "#FFFF80", width: "50%" }}>
                  Tax
                </td>
                <td style={{ backgroundColor: "#FFFF80", width: "50%" }}>
                  Other Costs
                </td>
              </tr>
            </table>
          </td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            Sub Total
          </td>
        </tr>
      </tbody>
    );
  }
  //CodingSummary : CarService
  loadCarServiceHeader(prop) {
    return (
      <tr>
        <td style={{ backgroundColor: "silver", width: "20%" }}>
          Service Company {!prop.BCD_generated ? " (Manual)" : <></>}
        </td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>
          Service Type
        </td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Car Type</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Pickup Date</td>
        <td style={{ backgroundColor: "silver", width: "20%" }}>Pickup Time</td>
      </tr>
    );
  }
  carServiceBookingHtml(prop) {
    return (
      <tbody>
        <tr>
          <td colSpan={5}>
            Account Number:{" "}
            {sessionStorage.getItem("ta_is_corporate_card") === "true"
              ? prop.car_service_cc_account_number
              : prop.car_service_account_number}
          </td>
        </tr>
        {this.loadCarServiceHeader(prop)}
        <tr>
          <td style={{ width: "20%" }}>{prop.company_name}</td>
          {prop.service_type === "Select Service Type" ? (
            <td style={{ width: "20%", color: "red" }}>{prop.service_type}</td>
          ) : (
            <td style={{ width: "20%" }}>{prop.service_type}</td>
          )}
          {prop.car_type === "Select Car Type" ? (
            <td style={{ width: "20%", color: "red" }}>{prop.car_type}</td>
          ) : (
            <td style={{ width: "20%" }}>{prop.car_type}</td>
          )}
          <td style={{ width: "20%" }}>
            {Utils.FormatDate(prop.pick_up_date)}
          </td>
          <td style={{ width: "20%" }}>
            {prop.pick_up_time}{" "}
            {sessionStorage.getItem("ta_car_service2") === "2"
              ? prop.am_pm
              : ""}
          </td>
        </tr>
        {this.state.carServiceBooking.map((cprop, ckey) => {                  
          if (sessionStorage.getItem("ta_car_service2") === "2") {            
            if (ckey < Number(cprop.location_count)) {
            {                
                return this.loadCarService2Location(prop, ckey);
             }
            }
          } else {
            if (ckey < Number(cprop.location_count)) {
              {
                return this.loadCarServiceLocation(prop, ckey);
              }
            }
          }
        })}
        <tr>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>Traveler</td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            Purpose/Comments
          </td>
          {/*<td style={{backgroundColor:"#FFFF80",width:"20%"}}>Phone Usage</td>*/}
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>Rate</td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}></td>
          <td style={{ backgroundColor: "#FFFF80", width: "20%" }}>
            Sub Total
          </td>
        </tr>
      </tbody>
    );
  }
  loadCarService2Location = (cprop, ckey) => {
    return (
      <>
        <tr>
          <td colSpan={5} style={{ backgroundColor: "cyan" }}>
            Location {ckey + 1}
          </td>
        </tr>
        <tr>
          <td>Pick Up:</td>
          <td colSpan={4}>{cprop.pick_up_location1}</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4}>{cprop.pick_up_location2}</td>
        </tr>
        <tr>
          <td>Drop Off:</td>
          <td colSpan={4}>{cprop.drop_off_location1}</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4}>{cprop.drop_off_location2}</td>
        </tr>
      </>
    );
  };
  loadCarServiceLocation = (cprop, ckey) => {
    return (
      <>
        <tr>
          <td colSpan={5} style={{ backgroundColor: "cyan" }}>
            From:
          </td>
        </tr>
        <tr>
          <td>Location Name:</td>
          <td colSpan={4}>{cprop.from_name}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td colSpan={4}>{cprop.from_addresslineone}</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4}>{cprop.from_addresslinetwo}</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4}>
            {cprop.from_city}
            {cprop.from_city !== "" ? ", " : ""}
            {cprop.from_state} {" " + cprop.from_zip}
          </td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td colSpan={4}>{cprop.from_phone}</td>
        </tr>
        <tr>
          <td>Alternate Phone:</td>
          <td colSpan={4}>{cprop.from_alt_phone}</td>
        </tr>
        <tr>
          <td colSpan={5} style={{ backgroundColor: "cyan" }}>
            To:
          </td>
        </tr>
        <tr>
          <td>Location Name:</td>
          <td colSpan={4}>{cprop.to_name}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td colSpan={4}>{cprop.to_addresslineone}</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4}>{cprop.to_addresslinetwo}</td>
        </tr>
        <tr>
          <td></td>
          <td colSpan={4}>
            {cprop.to_city}
            {cprop.to_city !== "" ? ", " : ""}
            {cprop.to_state} {" " + cprop.to_zip}
          </td>
        </tr>
        <tr>
          <td>Phone:</td>
          <td colSpan={4}>{cprop.to_phone}</td>
        </tr>
        <tr>
          <td>Alternate Phone:</td>
          <td colSpan={4}>{cprop.to_alt_phone}</td>
        </tr>
      </>
    );
  };
  //convert null to empty
  convertNullToEmpty(val) {
    return val === null ? "" : val;
  }
  convertNullToZero(val) {
    return val === null ? 0 : val;
  }
  //Load Travel details
  travellerDetails = (prop, sBooking, total) => {
    var travelPurpose = "";
    travelPurpose =
      prop.special_instructions !== ""
        ? this.convertNullToEmpty(prop.travel_purpose) +
          "<div>" +
          this.convertNullToEmpty(prop.special_instructions) +
          "</div>"
        : this.convertNullToEmpty(prop.travel_purpose);
    if (
      sBooking === "Car Service" &&
      sessionStorage.getItem("ta_car_service2") !== "2"
    ) {
      total = prop.sub_total;
    } else {
      total = total + prop.sub_total;
    }
    if (sBooking === "Air") {
      return (
        '<tr><td style="width:20%">' +
        this.convertNullToEmpty(prop.traveler) +
        '</td><td style="width:20%">' +
        travelPurpose +
        '</td><td style="width:20%">' +
        this.formatCurrency(this.convertNullToZero(prop.airfare_amount)) +
        '</td><td style="width:20%;visibility:hidden">' +
        this.formatCurrency(this.convertNullToZero(prop.fee_amount)) +
        '</td><td style="width:20%">' +
        this.formatCurrency(this.convertNullToZero(prop.sub_total)) +
        "</td></tr>"
      );
    } else if (sBooking === "Hotel") {
      return (
        '<tr><td style="width:20%">' +
        this.convertNullToEmpty(prop.traveler) +
        '</td><td style="width:20%">' +
        travelPurpose +
        '</td><td style="width:20%">' +
        this.formatCurrency(prop.rate) +
        '</td><td style="width:20%"><table style="width:100%"><tr><td style="width:50%">' +
        this.formatCurrency(this.convertNullToZero(prop.tax)) +
        '</td><td style="width: 50%">' +
        this.formatCurrency(this.convertNullToZero(prop.incidentals)) +
        '</td></tr></table></td><td style="width:20%">' +
        this.formatCurrency(this.convertNullToZero(prop.sub_total)) +
        "</td></tr>"
      );
    } else if (sBooking === "Rental Car") {
      var rateType =
        prop.RateType === "Days"
          ? this.formatCurrency(this.convertNullToZero(prop.rate)) +
            " (per day)"
          : this.formatCurrency(this.convertNullToZero(prop.rate)) +
            " (per week)";
      return (
        '<tr><td style="width:20%">' +
        this.convertNullToEmpty(prop.traveler) +
        '</td><td style="width:20%">' +
        travelPurpose +
        '</td><td style="width:20%">' +
        rateType +
        '</td><td style="width:20%"><table style="width:100%"><tr><td style="width:50%">' +
        this.formatCurrency(this.convertNullToZero(prop.tax)) +
        '</td><td style="width:50%">' +
        this.formatCurrency(this.convertNullToZero(prop.other_costs)) +
        '</td></tr></table></td><td style="width:20%">' +
        this.formatCurrency(this.convertNullToZero(prop.sub_total)) +
        "</td></tr>"
      );
    } else if (sBooking === "Car Service") {
      return (
        "<tr>" +
        '<td style={{width:"20%"}}>' +
        this.convertNullToEmpty(prop.traveler) +
        "</td>" +
        '<td style={{width:"20%"}}>' +
        travelPurpose +
        "</td>" +
        '<td style={{width:"20%"}}>' +
        this.formatCurrency(this.convertNullToZero(prop.rate)) +
        "</td>" +
        '<td style={{width:"20%"}}></td>' +
        '<td style={{width:"20%"}}>' +
        this.formatCurrency(this.convertNullToZero(prop.sub_total)) +
        "</td>" +
        "</tr>"
      );
    }
  };
  formatCurrency(amount) {
    var floatamount = parseFloat(amount);
    return floatamount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  //load sub total
  bookingSubTotalAndgetCoding(prop, total) {
    return (
      <>
        <tr>
          <td style={{ width: "60%" }} colSpan={3}>
            &nbsp;
          </td>
          <td
            style={{ width: "20%", color: "red", borderTop: "2px solid navy" }}
          >
            Total
          </td>
          <td
            style={{ width: "20%", color: "red", borderTop: "2px solid navy" }}
          >
            {this.formatCurrency(total)}
          </td>
        </tr>
        {prop.sc_amount > 0 ? (
          <>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "silver" }} colSpan={5}>
                Special Charges Explanation &amp; Amount
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "tan" }} colSpan={4}>
                {prop.sc_description}
              </td>
              <td style={{ backgroundColor: "tan" }} valign="top">
                {this.formatCurrency(this.convertNullToZero(prop.sc_amount))}
              </td>
            </tr>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  displayCodingSummary = () => {
    var prevBookingID = "";
    var prevTravelerID = "";
    var prevBookingIDVal = "";
    let bookingHtml;
    let bookingTravelerHtml;
    let bookingSubTotalHtml;
    let bookingPOSapHtmlAir;
    let bookingPOSapHtmlAirSplCharges;
    let bookingPOSapHtmlHotel;
    let bookingPOSapHtmlHotelSplCharges;
    let bookingPOSapHtmlRentalCar;
    let bookingPOSapHtmlRentalCarSplCharges;
    let bookingPOSapHtmlCarService;
    let bookingPOSapHtmlCarServiceSplCharges;

    var total = 0;
    let tot = "";

    return (
      <div>
        {this.taHeader()}
        <Row>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Button
              id="print"
              type="button"
              size="sm"
              onClick={() => window.print()}
            >
              Print
            </Button>
          </Col>
        </Row>
        {this.state.travellerDetails.length > 0 ? (
          <Row
            style={{ marginTop: "20px" }}
            className="text-dark font-size-xs small"
          >
            <Col sm={12}>
              <a href="https://www.viewtrip.com/" target="_blank">
                <b>ViewTrip.com</b>
              </a>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <table
          className="text-dark font-size-xs small"
          cellSpacing="0"
          cellPadding="3"
          style={{ width: "100%", marginTop: "10px" }}
        >
          {this.state.airBooking.length > 0 ? (
            <tr>
              <td
                colSpan={5}
                style={{
                  marginTop: "10px",
                  color: "white",
                  backgroundColor: "navy",
                }}
              >
                Air
              </td>
            </tr>
          ) : (
            <></>
          )}
          {(tot = "")}
          {this.state.uniquefields.uniqueAirBookingIds.map((uprop, ukeyVal) => {
            let getAirBooking = this.state.airBooking.filter(
              (f) => f.booking_id == uprop
            );
            let generateTravelerHtml;
            let generatebookingHtml;
            getAirBooking.map((mprop, keyVal) => {
              if (prevBookingID !== mprop.booking_id) {
                prevBookingID = mprop.booking_id;
                generatebookingHtml = this.airBookingHtml(mprop);
                prevTravelerID = "";
                tot = "";
                generateTravelerHtml = "";
                getAirBooking.map((prop, skeyVal) => {
                  if (prevTravelerID !== prop.traveller_id) {
                    prevTravelerID = prop.traveller_id;
                    tot = Number(tot) + prop.sub_total;
                    generateTravelerHtml =
                      generateTravelerHtml +
                      this.travellerDetails(prop, Constants.airBooking, total);
                  }
                });
                bookingHtml = parse(generatebookingHtml);
                bookingTravelerHtml = parse(generateTravelerHtml);
                bookingSubTotalHtml = this.bookingSubTotalAndgetCoding(
                  mprop,
                  tot
                );

                bookingPOSapHtmlAirSplCharges = "";
                //Coding
                if (this.state.jsonPoSAPAir.length === 0) {
                  bookingPOSapHtmlAir = "";
                }
                this.state.jsonPoSAPAir.map((prop) => {
                  if (prop.iBookingId === mprop.booking_id) {
                    bookingPOSapHtmlAir = prop.sHtml;
                    //console.log("AIR BOOKING JSON PO SAP: ", bookingPOSapHtmlAir)
                    //Special charges
                    if (this.state.jsonPoSAPAirSplCharges.length === 0) {
                      bookingPOSapHtmlAirSplCharges = "";
                    }
                    this.state.jsonPoSAPAirSplCharges.map((propSpl) => {
                      if (prop.iBookingId === propSpl.iBookingId) {
                        bookingPOSapHtmlAirSplCharges = propSpl.sHtml;
                        //console.log("AIR BOOKING JSON PO SAP: ", bookingPOSapHtmlAirSplCharges)
                      }
                    });
                  }
                });
              }
            });
            // console.log("Sub Total Air:", tot);
            return [
              bookingHtml,
              bookingTravelerHtml,
              bookingSubTotalHtml,
              bookingPOSapHtmlAir,
              bookingPOSapHtmlAirSplCharges,
            ];
          })}

          {(prevBookingIDVal = "")}
        </table>
        <table
          className="text-dark font-size-xs small"
          cellSpacing="0"
          cellPadding="3"
          style={{ width: "100%" }}
        >
          {this.state.hotelBooking.length > 0 ? (
            <tr>
              <td
                colSpan={5}
                style={{
                  marginTop: "10px",
                  color: "white",
                  backgroundColor: "navy",
                }}
              >
                Hotel
              </td>
            </tr>
          ) : (
            <></>
          )}
          {(prevBookingID = "")}
          {(prevTravelerID = "")}
          {(tot = "")}
          {this.state.uniquefields.uniqueHotelBookingIds.map(
            (uprop, ukeyVal) => {
              // console.log("PrevBookingID before look :",uprop)
              let getHotelBooking = this.state.hotelBooking.filter(
                (f) => f.booking_id == uprop
              );
              // console.log("GetHotelBooking :", getHotelBooking)
              let generateTravelerHtml;
              getHotelBooking.map((mprop, keyVal) => {
                if (prevBookingID !== mprop.booking_id) {
                  prevBookingID = mprop.booking_id;
                  bookingHtml = this.hotelBookingHtml(mprop);
                  prevTravelerID = "";
                  tot = "";
                  generateTravelerHtml = "";
                  getHotelBooking.map((prop, skeyVal) => {
                    // console.log("sub getHotelBooking :", prop)
                    prevTravelerID = prop.traveller_id;
                    tot = Number(tot) + prop.sub_total;
                    generateTravelerHtml =
                      generateTravelerHtml +
                      this.travellerDetails(
                        prop,
                        Constants.hotelBooking,
                        total
                      );
                  });
                  //evt = parse()
                  // console.log("Parse Hotel booking: ", generateTravelerHtml)
                  bookingTravelerHtml = parse(generateTravelerHtml);
                  bookingSubTotalHtml = this.bookingSubTotalAndgetCoding(
                    mprop,
                    tot
                  );

                  bookingPOSapHtmlHotelSplCharges = "";
                  //Coding
                  if (this.state.jsonPoSAPHotel.length === 0) {
                    bookingPOSapHtmlHotel = "";
                  }
                  this.state.jsonPoSAPHotel.map((prop) => {
                    if (prop.iBookingId === mprop.booking_id) {
                      bookingPOSapHtmlHotel = prop.sHtml;
                      //console.log("HOTEL BOOKING JSON PO SAP: ", bookingPOSapHtmlHotel)
                      //Special charges
                      if (this.state.jsonPoSAPHotelSplCharges.length === 0) {
                        bookingPOSapHtmlHotelSplCharges = "";
                      }
                      this.state.jsonPoSAPHotelSplCharges.map((propSpl) => {
                        if (prop.iBookingId === propSpl.iBookingId) {
                          bookingPOSapHtmlHotelSplCharges = propSpl.sHtml;
                          //console.log("HOTEL BOOKING JSON PO SAP: ", bookingPOSapHtmlHotelSplCharges)
                        }
                      });
                    }
                  });
                }
              });
              // console.log("Sub Total Hotel:", tot);
              return [
                bookingHtml,
                bookingTravelerHtml,
                bookingSubTotalHtml,
                bookingPOSapHtmlHotel,
                bookingPOSapHtmlHotelSplCharges,
              ];
            }
          )}
          {(prevBookingIDVal = "")}
        </table>
        <table
          className="text-dark font-size-xs small"
          cellSpacing="0"
          cellPadding="3"
          style={{ width: "100%" }}
        >
          {this.state.rentalCarBooking.length > 0 ? (
            <tr>
              <td
                colSpan={5}
                style={{
                  marginTop: "10px",
                  color: "white",
                  backgroundColor: "navy",
                }}
              >
                Rental Car
              </td>
            </tr>
          ) : (
            <></>
          )}
          {(prevBookingID = "")}
          {(prevTravelerID = "")}
          {(tot = "")}
          {this.state.uniquefields.uniqueRentalCarBookingIds.map(
            (uprop, ukeyVal) => {
              // console.log("PrevBookingID before look :",uprop)
              let getRentalCarBooking = this.state.rentalCarBooking.filter(
                (f) => f.booking_id == uprop
              );
              // console.log("GetRentalCarBooking :", getRentalCarBooking)
              let generateTravelerHtml;
              getRentalCarBooking.map((mprop, keyVal) => {
                if (prevBookingID !== mprop.booking_id) {
                  prevBookingID = mprop.booking_id;
                  bookingHtml = this.rentalCarBookingHtml(mprop);
                  prevTravelerID = "";
                  tot = "";
                  generateTravelerHtml = "";
                  getRentalCarBooking.map((prop, skeyVal) => {
                    // console.log("sub getRentalCarBooking :", prop)
                    prevTravelerID = prop.traveller_id;
                    tot = Number(tot) + prop.sub_total;
                    generateTravelerHtml =
                      generateTravelerHtml +
                      this.travellerDetails(
                        prop,
                        Constants.rentalCarBooking,
                        total
                      );
                  });
                  // console.log("Parse RentalCar booking: ", generateTravelerHtml)
                  bookingTravelerHtml = parse(generateTravelerHtml);
                  bookingSubTotalHtml = this.bookingSubTotalAndgetCoding(
                    mprop,
                    tot
                  );

                  bookingPOSapHtmlRentalCarSplCharges = "";
                  //Coding
                  if (this.state.jsonPoSAPRentalCar.length === 0) {
                    bookingPOSapHtmlRentalCar = "";
                  }
                  this.state.jsonPoSAPRentalCar.map((prop) => {
                    if (prop.iBookingId === mprop.booking_id) {
                      bookingPOSapHtmlRentalCar = prop.sHtml;
                      //console.log("RENTAL CAR BOOKING JSON PO SAP: ", bookingPOSapHtmlRentalCar)
                      //Special charges
                      if (
                        this.state.jsonPoSAPRentalCarSplCharges.length === 0
                      ) {
                        bookingPOSapHtmlRentalCarSplCharges = "";
                      }
                      this.state.jsonPoSAPRentalCarSplCharges.map((propSpl) => {
                        if (prop.iBookingId === propSpl.iBookingId) {
                          bookingPOSapHtmlRentalCarSplCharges = propSpl.sHtml;
                          //console.log("RENTAL CAR BOOKING JSON PO SAP: ", bookingPOSapHtmlRentalCarSplCharges)
                        }
                      });
                    }
                  });
                }
              });
              // console.log("Sub Total RentalCar:", tot);
              return [
                bookingHtml,
                bookingTravelerHtml,
                bookingSubTotalHtml,
                bookingPOSapHtmlRentalCar,
                bookingPOSapHtmlRentalCarSplCharges,
              ];
            }
          )}
          {(prevBookingIDVal = "")}
        </table>
        <table
          className="text-dark font-size-xs small"
          cellSpacing="0"
          cellPadding="3"
          style={{ width: "100%", marginTop: "10px" }}
        >
          {this.state.carServiceBooking.length > 0 ? (
            <tr>
              <td
                colSpan={5}
                style={{
                  marginTop: "10px",
                  color: "white",
                  backgroundColor: "navy",
                }}
              >
                Car Service
              </td>
            </tr>
          ) : (
            <></>
          )}
          {(tot = "")}
          {this.state.uniquefields.uniqueCarServiceBookingIds.map(
            (uprop, ukeyVal) => {
              let getCarServiceBooking = this.state.carServiceBooking.filter(
                (f) => f.booking_id == uprop
              );
              // console.log("getCarServiceBooking :", getCarServiceBooking)
              let generateTravelerHtml;
              let generatebookingHtml;
              getCarServiceBooking.map((mprop, keyVal) => {
                if (prevBookingID !== mprop.booking_id) {
                  prevBookingID = mprop.booking_id;  
                                
                  generatebookingHtml = this.carServiceBookingHtml(mprop);
                  prevTravelerID = "";
                  tot = "";
                  generateTravelerHtml = "";
                  getCarServiceBooking.map((prop, skeyVal) => {
                    // console.log("sub getCarServiceBooking :", prop)
                    if (prevTravelerID !== prop.traveller_id) {
                      prevTravelerID = prop.traveller_id;
                      tot = Number(tot) + prop.sub_total;
                      generateTravelerHtml =
                        generateTravelerHtml +
                        this.travellerDetails(
                          prop,
                          Constants.carServiceBooking,
                          total
                        );
                    }
                  });
                  bookingHtml = generatebookingHtml;
                  // console.log("generatebookingHtml: ", generatebookingHtml)
                  bookingTravelerHtml = parse(generateTravelerHtml);
                  // console.log("generateTravelerHtml: ", generateTravelerHtml)
                  bookingSubTotalHtml = this.bookingSubTotalAndgetCoding(
                    mprop,
                    tot
                  );

                  bookingPOSapHtmlCarServiceSplCharges = "";
                  //Coding
                  if (this.state.jsonPoSAPCarService.length === 0) {
                    bookingPOSapHtmlCarService = "";
                  }
                  this.state.jsonPoSAPCarService.map((prop) => {
                    if (prop.iBookingId === mprop.booking_id) {
                      bookingPOSapHtmlCarService = prop.sHtml;
                      //console.log("CAR SERVICE BOOKING JSON PO SAP: ", bookingPOSapHtmlRentalCar)
                      //Special charges
                      if (
                        this.state.jsonPoSAPCarServiceSplCharges.length === 0
                      ) {
                        bookingPOSapHtmlCarServiceSplCharges = "";
                      }
                      this.state.jsonPoSAPCarServiceSplCharges.map(
                        (propSpl) => {
                          if (prop.iBookingId === propSpl.iBookingId) {
                            bookingPOSapHtmlCarServiceSplCharges =
                              propSpl.sHtml;
                            //console.log("CAR SERVICE BOOKING JSON PO SAP: ", bookingPOSapHtmlCarServiceSplCharges)
                          }
                        }
                      );
                    }
                  });
                }
              });
              // console.log("Sub Total Hotel:", tot);
              return [
                bookingHtml,
                bookingTravelerHtml,
                bookingSubTotalHtml,
                bookingPOSapHtmlCarService,
                bookingPOSapHtmlCarServiceSplCharges,
              ];
            }
          )}
          {(prevBookingIDVal = "")}
        </table>
      </div>
    );
  };

  codingTab() {
    var status = true;
    if (
      sessionStorage.getItem("page") === "submitter" &&
      parseInt(sessionStorage.getItem("ta_status_id")!) === 1 &&
      (parseInt(sessionStorage.getItem("ta_tier_code")!) !== 0 ||
        sessionStorage.getItem("ta_is_corporate_card") === "true")
    ) {
      status = false;
      return (
        <Tab
          headerClass="my-tab-header"
          activeHeaderClass="my-active-tab-header"
          title="Click for summary page"
          label="Print"
        >
          {this.displayCodingSummary()}
        </Tab>
      );
    }
    if (
      sessionStorage.getItem("page") === "approver" ||
      sessionStorage.getItem("page") === "submitter"
    ) {
      status = false;
      if (
        parseInt(sessionStorage.getItem("ta_status_id")!) !== 1 ||
        (parseInt(sessionStorage.getItem("ta_status_id")!) === 1 &&
          parseInt(sessionStorage.getItem("ta_tier_code")!) === 0) ||
        sessionStorage.getItem("ta_is_corporate_card") === "false"
      ) {
        return (
          <Tab
            headerClass="my-tab-header"
            activeHeaderClass="my-active-tab-header"
            title="Click for coding page"
            label="Coding"
          >
            {this.displayCodingSummary()}
          </Tab>
        );
      }
    }

    if (status === true) {
      return (
        <Tab
          headerClass="my-tab-header"
          activeHeaderClass="my-active-tab-header"
          title="Click for coding page"
          label="Coding"
        >
          {this.displayCodingSummary()}
        </Tab>
      );
    }
  }

  decisionTabTAHistoryResubmit() {
    if (
      sessionStorage.getItem("ta_status_id") === Constants.ReSubmit.toString()
    ) {
      return (
        <Tab
          headerClass="my-tab-header"
          activeHeaderClass="my-active-tab-header"
          title="Click for decision page"
          label="Decision"
        ></Tab>
      );
    } else {
      return (
        <Tab
          headerClass="nav-link disabled"
          style={{ color: "white" }}
          disabled
        ></Tab>
      );
    }
  }
  redirectToPage(tabLabel) {
    switch (tabLabel) {
      case "Traveler":
        this.props.history.push("/admin/traveller");
        break;
      case "Air / Rail":
        this.props.history.push("/admin/airrail");
        break;
      case "Hotel":
        this.props.history.push("/admin/hotel");
        break;
      case "Rental Car":
        this.props.history.push("/admin/rentalcar");
        break;
      case "Car Service":
        this.props.history.push("/admin/carservice");
        break;
      case "Print":
        this.props.history.push({
          pathname: "/admin/codingsummary",
          state: { from: this.props.location.state.from },
        });
        break;
      case "Coding":
        this.props.history.push({
          pathname: "/admin/codingsummary",
          state: { from: this.props.location.state.from },
        });
        break;
      case "Decision":
        this.props.history.push({
          pathname: "/admin/decision",
          state: { from: this.props.location.state.from },
        });
        break;
      case "Confirmation":
        this.props.history.push({
          pathname: "/admin/confirmation",
          state: { from: this.props.location.state.from },
        });
        break;
    }
  }
  render() {
    // console.log("ta_status_desc: ",sessionStorage.getItem("ta_status_desc"))
    const { loading } = this.state;
    return (
      <div className="content">
        <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Card border="light" style={{ width: "100%" }}>
                <Card.Body>
                  <Form autoComplete="off">
                    <Alert
                      variant="danger"
                      style={{
                        display: this.state.errMsg ? "inherit" : "none",
                      }}
                    >
                      {this.state.errMsg}
                    </Alert>
                    <AuthStatus />
                    <section style={{ marginTop: "10px" }}>
                      <div style={{ textAlign: "right" }}>
                        <SubmitButton
                          loading={this.state.loading}
                          redirect={this.props.history}
                        />
                      </div>
                      {this.props.location.state.from === "tahistory" &&
                      (sessionStorage.getItem("ta_status_id") ===
                        Constants.Complete.toString() ||
                        sessionStorage.getItem("ta_status_id") ===
                          Constants.CarServiceComplete.toString()) ? (
                        <Tabs
                          onSelect={(index, label) =>
                            this.redirectToPage(`${label}`)
                          }
                          selected={0}
                          mountOnExit
                        >
                          {this.codingTab()}
                          <Tab
                            headerClass="my-tab-header"
                            activeHeaderClass="my-active-tab-header"
                            title="Click for confirmation page"
                            label="Confirmation"
                          >
                            {}
                          </Tab>
                          <Tab
                            headerClass="my-tab-header"
                            activeHeaderClass="my-active-tab-header"
                            title="Click for decision page"
                            label="Decision"
                          ></Tab>
                        </Tabs>
                      ) : this.props.location.state.from === "" ||
                        ((this.props.location.state.from === "tahistory" ||
                          this.props.location.state.from === "unsubmittedta") &&
                          (sessionStorage.getItem("ta_status_id") ===
                            Constants.ReSubmit.toString() ||
                            sessionStorage.getItem("ta_status_id") ===
                              Constants.Pending.toString())) ? (
                        sessionStorage.getItem("ta_car_service_only") ===
                        "true" ? (
                          <Tabs
                            onSelect={(index, label) =>
                              this.redirectToPage(`${label}`)
                            }
                            selected={2}
                            mountOnExit
                          >
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for traveler page"
                              label="Traveler"
                            ></Tab>
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for car service page"
                              label="Car Service"
                            ></Tab>
                            {this.codingTab()}
                            {this.decisionTabTAHistoryResubmit()}
                          </Tabs>
                        ) : (
                          <Tabs
                            onSelect={(index, label) =>
                              this.redirectToPage(`${label}`)
                            }
                            selected={5}
                            mountOnExit
                          >
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for traveler page"
                              label="Traveler"
                            ></Tab>
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for air/rail page"
                              label="Air / Rail"
                            ></Tab>
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for hotel page"
                              label="Hotel"
                            ></Tab>
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for rental car page"
                              label="Rental Car"
                            ></Tab>
                            <Tab
                              headerClass="my-tab-header"
                              activeHeaderClass="my-active-tab-header"
                              title="Click for car service page"
                              label="Car Service"
                            ></Tab>
                            {this.codingTab()}
                            {this.decisionTabTAHistoryResubmit()}
                          </Tabs>
                        )
                      ) : (
                        <Tabs
                          onSelect={(index, label) =>
                            this.redirectToPage(`${label}`)
                          }
                          selected={0}
                          mountOnExit
                        >
                          <Tab
                            headerClass="my-tab-header"
                            activeHeaderClass="my-active-tab-header"
                            title="Click for coding page"
                            label="Coding"
                          >
                            {this.displayCodingSummary()}
                          </Tab>
                          <Tab
                            headerClass="my-tab-header"
                            activeHeaderClass="my-active-tab-header"
                            title="Click for decision page"
                            label="Decision"
                          ></Tab>
                        </Tabs>
                      )}
                    </section>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
