import React, { Component } from "react";
import {FormLabel,FormGroup,Container,Row,Col,Form,Navbar, Nav, Alert,Button,Card,CardGroup,FormControl,Modal,Table} from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'

export class AuthStatus extends Component {
    constructor(props){
        super(props)
    }
    displayProject=()=>{    
        switch(sessionStorage.getItem("ta_expense_type_id")) {
            case '1':case '2':case '10':case '11':{
              return sessionStorage.getItem("ta_artist_project_number") + ' / ' + sessionStorage.getItem("ta_artist_project_name") + ' / ' + sessionStorage.getItem("ta_artist_name");
            }
            case '100':case '1000':{
                if (sessionStorage.getItem("ta_artist_project_name") !== ""){
                    return sessionStorage.getItem("ta_artist_project_number") + ' / ' + sessionStorage.getItem("ta_artist_project_name")
                }else{
                    return sessionStorage.getItem("ta_artist_project_number")
                }
            }
            default:{
              return '';
            }
        }
        return ''
    }
    render() {
        return (
            <div style={{backgroundColor:"#E4E6EF",border:"1px solid #222A35",padding:"5px"}}>
                <Row>
                    <Col sm={4} style={{textAlign:"left"}}>
                        <div className="text-dark font-size-xs small">Current{(sessionStorage.getItem("ta_car_service_only")) === "true" ?" Car Service ":" Travel "}Authorization:</div>
                        <div className="text-primary font-size-xs small">{(sessionStorage.getItem("ta_status_desc") === "Pending")?"NEW":sessionStorage.getItem("ta_status_desc")?.toLocaleUpperCase()}</div>
                    </Col>
                    <Col sm={4} style={{textAlign:"center"}}>
                        <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_created_by")}&nbsp;{sessionStorage.getItem("ta_business_phone")}</div>
                        <div className="text-dark font-size-xs small">{this.displayProject()}</div>
                    </Col>
                    <Col sm={4} style={{textAlign:"right"}}>
                        <div className="text-dark font-size-xs small">{(sessionStorage.getItem("ta_car_service_only")) === "true" ?"CA#":"TA#"}{sessionStorage.getItem("ta")}</div>
                        <div className="text-dark font-size-xs small">{sessionStorage.getItem("ta_expense_type_desc")}</div>
                    </Col>
                </Row>
            </div>
        )
    }
}