import React, { Component } from "react";
import {IOnlineDocumentHelpUploadParameter, Istate } from "../../../Model/IOnlineDocumentHelpUpload";
import {Container,Row,Col,Form,Alert,Button,Card,Table} from "../../../../node_modules/react-bootstrap";
import '../../../assets/css/Style.css'
import { FormInputs } from "../../CustomComponents/FormInput";
import { CommonController } from "../../../Controllers/CommonController";
import {alert} from 'react-bootstrap-confirmation';

export class OnlineDocumentHelpUpload extends Component<IOnlineDocumentHelpUploadParameter,Istate> {
    constructor(props:IOnlineDocumentHelpUploadParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: { file:'',fileName:'',fileExtn:''},
            selectfields: {typeId:'',formonFile:'',Restrictions:'',travelAgentName:'', expenseTypeId:'', expenseTypeName:'', selCountryId:'',selStateProvinceId:'', formLabel: ''},
            checkfields: {isCorporateCard:false,manualResv:false,chkDirectorHeadApproval:false},
            optionfields : {employeeTravel:true, conventionProject:false, rdoArtist:false,rdoContestWinner:false,rdoRadioPersonel:false,rdoOverhead:false, rdoFormonfileyes:false,rdoFormonfileno:false,rdoRestrictionsyes:false,rdoRestrictionsno:false, rdoRestrictionsawaitingform:false, rdoIssuer2:false},
            errors:{firstName:'', lastName:'',submitter:'',formLabel:'', reason:'',resv:'',SSNoFedID:'',address1:'',countryId:'',city:'',stateProvinceId:'',postalCode:'',callLetters:'', travelAgentId:'', expenseTypeId:'', SAPEmpNo:'', projectName:'', artistName:'', projectNumber:'',comments:'',rdotypeid:'',rdoformonfile:'',rdoRestrictions:''},
            errMsg:"",
            loading:false,
            modal: {modalSpinnerShow: false,modalBody:'',modalShow:false,modalTAShow:false,modalTitle:'',modalTATitle:''}
          
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
    }
    handleChange(e:any){
      let fields=this.state.fields;
      if(e.target.files[0].name !== undefined){
        fields["file"]=e.target.files[0];
        fields["fileName"]=e.target.files[0].name;
        fields["fileExtn"] = e.target.files[0].name.split(".")[1]
        this.setState({fields})
      }
}
handleSubmit(e:any){
  const formdata=new FormData();
  formdata.append('file',this.state.fields.file)
  formdata.append('fileName',this.state.fields.fileName)
 
  if (this.state.fields.fileExtn === 'pdf'){
      let commonController = new CommonController();

      return commonController.upload(formdata).then(response => {
        //debugger;
        alert(response.message);
        //alert(response);
        return response;
      
    }).catch(error => {
        console.error("There was an error!", error)
    });
  }
  else
  {
    alert("Please upload .pdf file format");
  }
}

    render(){
        return (
          <div className="content">
            <div className="content">
              <Container fluid>
                <Row>
                  <Col sm={12}>
                    <Card border="light" style={{ width: "100%" }}>
                      <Card.Body>
                        <Form autoComplete="off">
                          <Alert
                            variant="danger"
                            style={{
                              display: this.state.errMsg ? "inherit" : "none",
                            }}
                          >
                            {this.state.errMsg}
                          </Alert>

                          {/* {this.displayVoucherPlus()}    */}
                          <div style={{ width: "auto"}}>
                          <Table>
                          <tbody>
                          <tr>
                             <td  align="center">Upload Online Help Document (.pdf)</td>
                             </tr>
                             <tr>
                             <td><div className="custom-file">
                           <input type="file" className="custom-file-input" id="customFile" onChange={(e) => this.handleChange(e)}/>
                            <label className="custom-file-label">{this.state.fields.fileName}</label>
                           </div></td>
                               <td>
                               <Button style={{height: 'auto'}}  size="sm" id="Upload" name="Upload" onClick={(e) => this.handleSubmit(e)}>Upload</Button>
                               </td>
                             </tr>
                             </tbody>

                              </Table>

                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        );

    }
}