import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';

export class DepartmentMaintenanceController {
    response : any;
    serviceProxy: ServiceProxy;
    variables: Variables;
    constructor(){
        this.response = { msg : '', success : true }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
    }

    createUpdateDepartment(data)
    {;
        var url = this.variables.Url+ this.variables.Controller.departmentMaintenance + this.variables.departmentMaintenanceService.createUpdateDepartment;
        var data1 = {
            doBy: data.doBy,
            departmentId: data.departmentId,
            labelId: data.labelId,
            description: data.description,          
            isActive: data.isActive        
        }
        return this.serviceProxy.post(url,data1).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }

    getDepartmentsByLabel (data){
        var url = this.variables.Url+ this.variables.Controller.departmentMaintenance + this.variables.departmentMaintenanceService.getDepartmentByLabel;
       
        return this.serviceProxy.get(url,data).then(response=>{
            var jsonGetDepartment : Array<{"labelid": string, "description": string,"departmentId":string,"isActive":boolean}> = [];
                if(response.success){
                    response.recordset.forEach(departmentlst);
                    function departmentlst(item: { Label_ID: any; Description: any;Department_ID:any;Is_Active:any; }){
                        jsonGetDepartment.push({"labelid": item.Label_ID, "description": item.Description, "departmentId": item.Department_ID,
                     "isActive": item.Is_Active, })
                    }
                    return JSON.parse(JSON.stringify(jsonGetDepartment).replace(/"\s+|\s+"/g, '"'));
                }
                else {
                    return JSON.parse(JSON.stringify(jsonGetDepartment).replace(/"\s+|\s+"/g, '"'));
                }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

}