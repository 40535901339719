import React, { Component } from "react";
import { CommonController } from "../../../Controllers/CommonController";
import {History, LocationState} from "History";

export class StopShadow extends Component<IShawdowParameter,Istate> 
{
  constructor(props){ 
    super(props)
    const userLogin = sessionStorage.getItem("userLogin")
    let loggedIn = true;
    if (userLogin == null){
        loggedIn = false;
    }
  } 

componentWillMount(){
    let commonController = new CommonController();
    commonController.getUser(sessionStorage.getItem('shadow')!).then(response => {
        sessionStorage.setItem('shadow',"");
        sessionStorage.setItem('ShadowAdmin', "" )
        this.props.history.push('/admin/home');
    }).catch(error => {
        console.error("There was an error!", error);
    })
      /* UnLocking TA for the current users */
    let comController = new CommonController();
    var data = {
        authreqid: sessionStorage.getItem("ta"),
        userid: sessionStorage.getItem("uid"),
        shadowid: sessionStorage.getItem("shadow"),
        check: ""
    }
    // console.log(data,"data,check")
    comController.updateUnLockAuthreq(data).then(response => {
    }).catch(error => {
        console.error("There was an error!", error)
    })
}


    render() {
        return (<></>)
    }
}

interface IShawdowParameter {   
    history?: History<LocationState>;
    location?:any;
  }
  
interface Istate {    

  }