import React, { Component } from "react";
import { Container, Row, Col, Form, Alert, Card, Button, FormControl } from "../../../../node_modules/react-bootstrap";
import * as Utils from '../../../js/Utils.js';
import '../../../assets/css/Style.css'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { FormModalSpinner } from '../../CustomComponents/FormModalSpinner';
import { alert, confirm } from 'react-bootstrap-confirmation';
import { AuthStatus } from "../Common/AuthStatus";
import { IConfirmationParameter, Istate } from "../../../Model/IConfirmation";
import { BCDAgentController } from "../../../Controllers/BCDAgentController";
import { FormInputs } from "../../CustomComponents/FormInput";
import { CommonController } from "../../../Controllers/CommonController";
import { Console } from "console";
import { EmailController } from "../../../Controllers/EmailController";
import { BCDWSController } from "../../../Controllers/BCDWSController";
import { TAInclude } from "../../../Common/TAInclude";
import { CodingSummaryController } from "../../../Controllers/CodingSummaryController";
import { isElementAccessExpression } from "typescript";
import * as Constants from "../../../js/Constants";

export class Confirmation extends Component<IConfirmationParameter, Istate>{
    constructor(props:IConfirmationParameter){
        super(props)
        const userLogin = sessionStorage.getItem("userLogin")
        let loggedIn = true;
        if (userLogin == null){
            loggedIn = false;
        }
        this.state={
            fields: {sessionTA: sessionStorage.getItem('ta'), bConfirm:false, travellerArray: [], travellerRLN: [], taIndex: 0, bCodeAir:false, bCodeHotel:false, bCodeRentalCar:false, bCodeCarService:false, newTravellerRLN:[], sb2:'', sBooking:[], taAirCodingRow:[], taHotelCodingRow:[], taRentalCarCodingRow:[], taCarServiceCodingRow:[]},
            selectfields:{},
            checkfields: {},
            errors:{},
            loading:{saveChanges:false, applyConfirmation:false, addBCDRemarks:false},
            errMsg:"",
            userProcess:"",
            travellerDetails:[],
            isShowNewRLN: {air:[], hotel:[], rentalCar:[], carService:[]},
            airBooking:[],
            hotelBooking:[],
            rentalCarBooking:[],
            carServiceBooking:[],
            authorizer:[],
            airConfirmationNumber:{booking:[],bookingId:[],travellerId:[],confirmationNumber:[]},
            hotelConfirmationNumber:{booking:[],bookingId:[],travellerId:[],confirmationNumber:[]},
            rentalCarConfirmationNumber:{booking:[],bookingId:[],travellerId:[],confirmationNumber:[]},
            carServiceConfirmationNumber:{booking:[],bookingId:[],travellerId:[],confirmationNumber:[]},
            modal: {modalSpinnerShow: false},
            prevBookingID:'',
            prevTravelerID:'',
            uniquefields: {uniqueAirBookingIds:[], uniqueHotelBookingIds:[], uniqueRentalCarBookingIds:[], uniqueCarServiceBookingIds:[]},
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.addBCDRemarksSubmit = this.addBCDRemarksSubmit.bind(this);
    }

    componentWillMount(){
        //console.log("ComponentWillMount");
        this.pageLoad();
        this.taCodingRow();
        //console.log("airConfirmation:pageload:", this.state.airConfirmationNumber)
    }

    pageLoad(){
        let modal = this.state.modal;
        modal['modalSpinnerShow']=true;
        if(sessionStorage.getItem("ta_status_desc") === "Complete" || sessionStorage.getItem("ta_status_desc") === "Car Service Complete")
        {
        }else{
            sessionStorage.setItem("page","wtp");
        }

        if (sessionStorage.getItem("ta_car_service_only")==="true")
        {
            sessionStorage.setItem("page","car_service");
        }
        else
        {
            sessionStorage.setItem("page","wtp");
        }

        this.setState({modal});
        let fields = this.state.fields;
        if (sessionStorage.getItem("ta_sound_biz_2")){
                fields['sb2'] = "1"
        }else{
                fields['sb2'] = "0"
        }
        if(sessionStorage.getItem("car_service_company_id") !== "null"){
            fields["sBooking"] = ["Car Service"]
        }
        else{
            fields["sBooking"] = ["Air", "Hotel", "Rental Car", "Car Service"]
        }
        if (sessionStorage.getItem("ta_status_id") === "5" && sessionStorage.getItem("wtp_history") === "false"
        && ((sessionStorage.getItem("car_service_company_id") !== "" && sessionStorage.getItem("page") === "car_service") 
        || (sessionStorage.getItem("user_type_id") === "W" && sessionStorage.getItem("page") === "wtp" && sessionStorage.getItem("approver_tier_id") === "0") 
        || sessionStorage.getItem("user_type_id") === "CT"
        || sessionStorage.getItem("user_type_id") === "S")) {
            fields['bConfirm'] = true;
        }
        this.setState({fields});
        //Temp start
        sessionStorage.setItem("HFMEntityOrOperatingCompanyText","HFM Entity");
        sessionStorage.setItem("HFMCodeOrOpCoText","HFM Code");
        //Temp end
        let bcdAgentController = new BCDAgentController();
        var data = {
            booking: 'air',
            taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
            authReqId: this.state.fields.sessionTA,
            taCarService2: sessionStorage.getItem("ta_car_service2"),
            carServiceCompanyId: sessionStorage.getItem("car_service_company_id")
        }
        bcdAgentController.getConfirmationPage(data).then(response => {
            if (response !== undefined){
                this.setState({                           
                    airBooking : JSON.parse(JSON.stringify(response.recordset))
                });
                var prevBookingID = '';
                var prevTravelerID = ''
                //filter unique Air Booking_ids
                const filterAirBooking_ids =  this.state.airBooking.map(q => q.booking_id);
                //console.log("filterAirBooking_ids:", filterAirBooking_ids)
                this.state.uniquefields.uniqueAirBookingIds = filterAirBooking_ids.filter((q, idx) => filterAirBooking_ids.indexOf(q) === idx)
                //console.log("Unique air Booking", this.state.uniquefields.uniqueAirBookingIds)
                var index=0;
                this.state.uniquefields.uniqueAirBookingIds.map((uprop, ukeyVal) => {
                    let getAirBooking = this.state.airBooking.filter(f=>f.booking_id === uprop);
                    getAirBooking.map((mprop, keyVal) => {
                        if(prevBookingID !== mprop.booking_id){
                            prevBookingID = mprop.booking_id
                            prevTravelerID = "";
                            getAirBooking.map((prop, skeyVal) => {
                                //console.log("sub getAirBooking :", prop)
                                if(prevTravelerID !== prop.traveller_id){
                                    prevTravelerID = prop.traveller_id
                                    this.state.isShowNewRLN.air.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                                    let airConfirmationNumber = this.state.airConfirmationNumber;
                                    airConfirmationNumber["booking"][index] = "air";
                                    airConfirmationNumber["bookingId"][index] = prop.booking_id;
                                    airConfirmationNumber["travellerId"][index] = prop.traveller_id;
                                    airConfirmationNumber["confirmationNumber"][index] = prop.confirmation_number === null ? "": prop.confirmation_number;
                                    this.setState(airConfirmationNumber);
                                    index = index + 1;
                                }
                                
                            })                      
                        }
                    });
                })  

             /*this.state.airBooking.map((prop,key) => {
                    this.state.isShowNewRLN.air.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                    let airConfirmationNumber = this.state.airConfirmationNumber;
                    airConfirmationNumber["booking"][key] = "air";
                    airConfirmationNumber["bookingId"][key] = prop.booking_id;
                    airConfirmationNumber["travellerId"][key] = prop.traveller_id;
                    airConfirmationNumber["confirmationNumber"][key] = prop.confirmation_number === null ? "": prop.confirmation_number;
                    this.setState(airConfirmationNumber);
                })*/
                //console.log("airBooking:", this.state.airBooking);
                //console.log("airBooking-confirmation",this.state.airConfirmationNumber)
            }
            var data = {
                booking: 'hotel',
                taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
                authReqId: this.state.fields.sessionTA,
                taCarService2: sessionStorage.getItem("ta_car_service2"),
                carServiceCompanyId: sessionStorage.getItem("car_service_company_id")
            }
            bcdAgentController.getConfirmationPage(data).then(response => {
                if (response !== undefined){
                    this.setState({                           
                        hotelBooking : JSON.parse(JSON.stringify(response.recordset))
                    });

                    var prevBookingID = '';
                    var prevTravelerID = ''
                    //filter unique Hotel Booking_ids
                    const filterHotelBooking_ids =  this.state.hotelBooking.map(q => q.booking_id);
                    //console.log("filterHotelBooking_ids:", filterHotelBooking_ids)
                    this.state.uniquefields.uniqueHotelBookingIds = filterHotelBooking_ids.filter((q, idx) => filterHotelBooking_ids.indexOf(q) === idx)
                    //console.log("Unique Hotel Booking", this.state.uniquefields.uniqueHotelBookingIds)
                    var index=0;
                    this.state.uniquefields.uniqueHotelBookingIds.map((uprop, ukeyVal) => {
                        let getHotelBooking = this.state.hotelBooking.filter(f=>f.booking_id === uprop);
                        getHotelBooking.map((mprop, keyVal) => {
                            if(prevBookingID !== mprop.booking_id){
                                prevBookingID = mprop.booking_id
                                prevTravelerID = "";
                                getHotelBooking.map((prop, skeyVal) => {
                                    //console.log("sub getHotelBooking :", prop)
                                    if(prevTravelerID !== prop.traveller_id){
                                        prevTravelerID = prop.traveller_id
                                        this.state.isShowNewRLN.hotel.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                                        let hotelConfirmationNumber = this.state.hotelConfirmationNumber;
                                        hotelConfirmationNumber["booking"][index] = "hotel";
                                        hotelConfirmationNumber["bookingId"][index] = prop.booking_id;
                                        hotelConfirmationNumber["travellerId"][index] = prop.traveller_id;
                                        hotelConfirmationNumber["confirmationNumber"][index] = prop.confirmation_number === null ? "": prop.confirmation_number;
                                        this.setState(hotelConfirmationNumber);
                                        index = index + 1;
                                    }
                                    
                                })                      
                            }
                        });
                    })

                    /*this.state.hotelBooking.map((prop,key) => {
                        this.state.isShowNewRLN.hotel.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                        let hotelConfirmationNumber = this.state.hotelConfirmationNumber;
                        hotelConfirmationNumber["booking"][key] = "hotel";
                        hotelConfirmationNumber["bookingId"][key] = prop.booking_id;
                        hotelConfirmationNumber["travellerId"][key] = prop.traveller_id;
                        hotelConfirmationNumber["confirmationNumber"][key] = prop.confirmation_number === null ? "": prop.confirmation_number;
                        this.setState(hotelConfirmationNumber);
                    })*/
                }
                //console.log("hotelBooking:", this.state.hotelBooking);
                var data = {
                    booking: 'rentalCar',
                    taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
                    authReqId: this.state.fields.sessionTA,
                    taCarService2: sessionStorage.getItem("ta_car_service2"),
                    carServiceCompanyId: sessionStorage.getItem("car_service_company_id")
                }
                bcdAgentController.getConfirmationPage(data).then(response => {
                    if (response !== undefined){
                        this.setState({                           
                            rentalCarBooking : JSON.parse(JSON.stringify(response.recordset))
                        });

                        var prevBookingID = '';
                        var prevTravelerID = ''
                        //filter unique RenTal car Booking_ids
                        const filterRentalcarBooking_ids =  this.state.rentalCarBooking.map(q => q.booking_id);
                        //console.log("filterRentalcarBooking_ids:", filterRentalcarBooking_ids)
                        this.state.uniquefields.uniqueRentalCarBookingIds = filterRentalcarBooking_ids.filter((q, idx) => filterRentalcarBooking_ids.indexOf(q) === idx)
                        //console.log("Unique Rental Car Booking", this.state.uniquefields.uniqueRentalCarBookingIds)
                        var index=0;
                        this.state.uniquefields.uniqueRentalCarBookingIds.map((uprop, ukeyVal) => {
                            let getRentalcarBooking = this.state.rentalCarBooking.filter(f=>f.booking_id === uprop);
                            getRentalcarBooking.map((mprop, keyVal) => {
                                if(prevBookingID !== mprop.booking_id){
                                    prevBookingID = mprop.booking_id
                                    prevTravelerID = "";
                                    getRentalcarBooking.map((prop, skeyVal) => {
                                        //console.log("sub getRentalcarBooking :", prop)
                                        if(prevTravelerID !== prop.traveller_id){
                                            prevTravelerID = prop.traveller_id
                                            this.state.isShowNewRLN.rentalCar.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                                            let rentalCarConfirmationNumber = this.state.rentalCarConfirmationNumber;
                                            rentalCarConfirmationNumber["booking"][index] = "rentalCar";
                                            rentalCarConfirmationNumber["bookingId"][index] = prop.booking_id;
                                            rentalCarConfirmationNumber["travellerId"][index] = prop.traveller_id;
                                            rentalCarConfirmationNumber["confirmationNumber"][index] = prop.confirmation_number === null ? "": prop.confirmation_number;
                                            this.setState(rentalCarConfirmationNumber);
                                            index = index + 1;
                                        }
                                        
                                    })                      
                                }
                            });
                        })

                        /*this.state.rentalCarBooking.map((prop, key) => {
                            this.state.isShowNewRLN.rentalCar.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                            let rentalCarConfirmationNumber = this.state.rentalCarConfirmationNumber;
                            rentalCarConfirmationNumber["booking"][key] = "rentalCar";
                            rentalCarConfirmationNumber["bookingId"][key] = prop.booking_id;
                            rentalCarConfirmationNumber["travellerId"][key] = prop.traveller_id;
                            rentalCarConfirmationNumber["confirmationNumber"][key] = prop.confirmation_number === null ? "": prop.confirmation_number;
                            this.setState(rentalCarConfirmationNumber);
                        })*/
                    }
                    //console.log("rentalCarBooking:", this.state.rentalCarBooking);
                    var data = {
                        booking: 'carService',
                        taExpenseTypeId: sessionStorage.getItem("ta_expense_type_id"),
                        authReqId: this.state.fields.sessionTA,
                        taCarService2: sessionStorage.getItem("ta_car_service2"),
                        carServiceCompanyId: (sessionStorage.getItem("car_service_company_id") === 'null' || sessionStorage.getItem("car_service_company_id") === '')?'':sessionStorage.getItem("car_service_company_id")
                    }
                    bcdAgentController.getConfirmationPage(data).then(response => {
                        if (response !== undefined){
                            this.setState({                           
                                carServiceBooking : JSON.parse(JSON.stringify(response.recordset))
                            });

                            var prevBookingID = '';
                            var prevTravelerID = ''
                            //filter unique RenTal car Booking_ids
                            const filterCarserviceBooking_ids =  this.state.carServiceBooking.map(q => q.booking_id);
                            //console.log("filterCarserviceBooking_ids:", filterCarserviceBooking_ids)
                            this.state.uniquefields.uniqueCarServiceBookingIds = filterCarserviceBooking_ids.filter((q, idx) => filterCarserviceBooking_ids.indexOf(q) === idx)
                            //console.log("Unique Car Service Booking", this.state.uniquefields.uniqueCarServiceBookingIds)
                            var index=0;
                            this.state.uniquefields.uniqueCarServiceBookingIds.map((uprop, ukeyVal) => {
                                let getCarserviceBooking = this.state.carServiceBooking.filter(f=>f.booking_id === uprop);
                                getCarserviceBooking.map((mprop, keyVal) => {
                                    if(prevBookingID !== mprop.booking_id){
                                        prevBookingID = mprop.booking_id
                                        prevTravelerID = "";
                                        getCarserviceBooking.map((prop, skeyVal) => {
                                            //console.log("sub getCarserviceBooking :", prop)
                                            if(prevTravelerID !== prop.traveller_id){
                                                prevTravelerID = prop.traveller_id
                                                this.state.isShowNewRLN.carService.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                                                let carServiceConfirmationNumber = this.state.carServiceConfirmationNumber;
                                                carServiceConfirmationNumber["booking"][index] = "carService";
                                                carServiceConfirmationNumber["bookingId"][index] = prop.booking_id;
                                                carServiceConfirmationNumber["travellerId"][index] = prop.traveller_id;
                                                carServiceConfirmationNumber["confirmationNumber"][index] = prop.confirmation_number === null ? "": prop.confirmation_number;
                                                this.setState(carServiceConfirmationNumber);
                                                index = index + 1;
                                            }
                                            
                                        })                      
                                    }
                                });
                            })  

                            /*this.state.carServiceBooking.map((prop, key) => {
                                this.state.isShowNewRLN.carService.push(this.isShowNewRLN(prop.traveller_id, prop.RLN));
                                let carServiceConfirmationNumber = this.state.carServiceConfirmationNumber;
                                carServiceConfirmationNumber["booking"][key] = "carService";
                                carServiceConfirmationNumber["bookingId"][key] = prop.booking_id;
                                carServiceConfirmationNumber["travellerId"][key] = prop.traveller_id;
                                carServiceConfirmationNumber["confirmationNumber"][key] = prop.confirmation_number === null ? "": prop.confirmation_number;
                                this.setState(carServiceConfirmationNumber);
                            })*/
                        }
                        //console.log("carServiceBooking:", this.state.carServiceBooking);
                        bcdAgentController.getAuthorizer({authReqId: this.state.fields.sessionTA}).then(response => {
                            if (response !== undefined){
                                this.setState({                           
                                    authorizer : JSON.parse(JSON.stringify(response.recordset))
                                });
                            }
                            let modal = this.state.modal;
                            modal['modalSpinnerShow']=false;
                            this.setState({ modal});
                        }).catch(error => {
                            console.error("There was an error", error);
                        })  
                    }).catch(error => {
                        console.error("There was an error", error);
                    })
                }).catch(error => {
                    console.error("There was an error", error);
                })
            }).catch(error => {
                console.error("There was an error", error);
            })
        }).catch(error => {
            console.error("There was an error", error);
        })
    }

    taCodingRow(){
        let fields = this.state.fields;
        this.state.fields.sBooking.map(booking => {
            //console.log("sBooking",this.state.fields.sBooking)
            let codingSummaryContoller = new CodingSummaryController();
            var data={
                authreqId: sessionStorage.getItem("ta"),
                sBooking: booking,
                taCarService2: sessionStorage.getItem("ta_car_service2")!,
                travelId: "",
                travelType: ""
            };
            codingSummaryContoller.getTACoding(data).then(response => {
                if(booking === "Air"){
                    fields["taAirCodingRow"] = JSON.parse(JSON.stringify(response.recordset));
                }
                else if(booking === "Hotel"){
                    fields["taHotelCodingRow"] = JSON.parse(JSON.stringify(response.recordset));
                }
                else if(booking === "Rental Car"){
                    fields["taRentalCarCodingRow"] = JSON.parse(JSON.stringify(response.recordset));
                }
                else{
                    fields["taCarServiceCodingRow"] = JSON.parse(JSON.stringify(response.recordset));
                }
                this.setState({ fields });
            })
        }) 
    }

    handleCNChange(e:any,type,index){
        const { name, value } = e.target;
        if(type === "Air"){
            let airConfirmationNumber = this.state.airConfirmationNumber;
            airConfirmationNumber["confirmationNumber"][index] = value;
            this.setState({ airConfirmationNumber });
            //console.log("handleCNChange:", this.state.airConfirmationNumber)
        }
        else if(type === "Hotel"){
            let hotelConfirmationNumber = this.state.hotelConfirmationNumber;
            hotelConfirmationNumber["confirmationNumber"][index] = value;
            this.setState({ hotelConfirmationNumber });
        }
        else if(type === "RentalCar"){
            let rentalCarConfirmationNumber = this.state.rentalCarConfirmationNumber;
            rentalCarConfirmationNumber["confirmationNumber"][index] = value;
            this.setState({ rentalCarConfirmationNumber });
        }
        else if(type === "CarService"){
            let carServiceConfirmationNumber = this.state.carServiceConfirmationNumber;
            carServiceConfirmationNumber["confirmationNumber"][index] = value;
            this.setState({ carServiceConfirmationNumber });
        }
    }

    handleRLNChange = (e:any,tid)=>{
        let fields = this.state.fields;
        if (e.currentTarget.value.trim().length > 0){
            fields["newTravellerRLN"][fields["travellerArray"].indexOf(tid)] = e.currentTarget.value;
        }
        else{
            fields["newTravellerRLN"][fields["travellerArray"].indexOf(tid)] = e.currentTarget.value.trim();
        }
        //console.log("fields newTravellerRLN:", fields)
        //console.log("state:", this.state)
        this.setState({ fields });
    }

    validateExistingRLN = () =>{
        var result;
        var validateExistingRLN = false;
        for(let rln of this.state.fields.travellerRLN){
            result = this.isValidRLN(rln);
            if(result[0] === false){
                alert(result[1]);
                return validateExistingRLN;
            }
        }
        validateExistingRLN = true;
        return validateExistingRLN;
    }

    isValidRLNSaveChanges = (rln, action) =>{
        
        let isValidRLN = false;
        let errMsg = "";
        if (action === "savechanges"){
            var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/)
            if (!regexp.test(rln)){
                errMsg = "A valid Resv # is limited to A-Z, 0-9, must be 6 characters in length and no spaces or special characters."
                return [isValidRLN, errMsg];
            }
            
            if(rln.trim() === "999999"){
                errMsg = "Existing Resv # cannot be 999999 a temporary resv #, enter valid number...";
                return [isValidRLN, errMsg];
            }
            if(rln.trim().length !== 6){
                errMsg = "A valid Resv # is must be 6 characters in length."
                return [isValidRLN, errMsg];
            }
        }
        isValidRLN = true;
        return [isValidRLN, errMsg];
    }

    isValidRLN = (rln) => {
        let isValidRLN = false;
        let errMsg = "";
        var regexp = new RegExp(/^[a-zA-Z0-9 ]*$/)
        if (!regexp.test(rln)){
            errMsg = "A valid Resv # is limited to A-Z, 0-9, must be 6 characters in length and no spaces or special characters."
            return [isValidRLN, errMsg];
        }
        if(rln.trim() === "999999"){
            errMsg = "Existing Resv # cannot be 999999 a temporary resv #, enter valid number...";
            return [isValidRLN, errMsg];
        }
        if(rln.trim().length !== 6){
            errMsg = "A valid Resv # is must be 6 characters in length."
            return [isValidRLN, errMsg];
        }
        isValidRLN = true;
        return [isValidRLN, errMsg];
    }

    validateConfirmation()
    {
        let retVal=true;
        let modal = this.state.modal;
        //console.log("************************************************")
        //console.log("airConfirmation:", this.state.airConfirmationNumber)
        //console.log("hotalConfirmation:",this.state.hotelConfirmationNumber)
        //console.log("rentalCarConfirmatoin:",this.state.rentalCarConfirmationNumber)
        //console.log("carServiceConfirmation",this.state.carServiceConfirmationNumber)
        //console.log("************************************************")
        for (const objAir of this.state.airConfirmationNumber.confirmationNumber){
            //console.log("objAir:",objAir)
            if(objAir === "" || objAir === null)
            {
                retVal =false;
            }
        }
        //console.log("True")
        for (const objHotel of this.state.hotelConfirmationNumber.confirmationNumber){
            //console.log("objHotel:",objHotel)
            if(objHotel === "" || objHotel === null)
            {
                retVal =false;
            }
        }
        for (const objRentalCar of this.state.rentalCarConfirmationNumber.confirmationNumber){
            //console.log("objAir:",objRentalCar)
            if(objRentalCar === "" || objRentalCar === null)
            {
                retVal =false;
            }
        }
        for (const objCarService of this.state.carServiceConfirmationNumber.confirmationNumber){
            //console.log("objAir:",objCarService)
            if(objCarService === "" || objCarService === null)
            {
                retVal =false;
            }
        }
        
        if(!retVal)
        {
            alert("Please enter all confirmation #fields");     
            modal['modalSpinnerShow']=false;
            this.setState({modal})    
        }
        return retVal;
    }

    validateRLN(action){
        let retVal=true;
        var result;
        if(action === 'saveChanges'){
            //console.log("RLN:", this.state.fields.newTravellerRLN)
            for (const objRloc of this.state.fields.newTravellerRLN){
                //console.log("objRloc:",objRloc)
                if(objRloc.trim() === "" || objRloc.trim() === null)
                {
                    //retVal =false;
                }
                else
                {
                    result =  this.isValidRLN(objRloc);
                    //console.log("valid RLN", result);   
                    if(result[1]){
                        alert(result[1]);
                        retVal = false;
                    }
                }
            }
        }
        else
        {
            
            for(const obj of this.state.airBooking){
                if(obj.RLN === "999999"){
                    retVal=false;
                }
            }
            for(const obj of this.state.hotelBooking){
                
                if(obj.RLN === "999999")
                {
                    retVal=false;
                }
            }
            for(const obj of this.state.rentalCarBooking){
                if(obj.RLN === "999999")
                {
                    retVal=false;
                }
            }
            for(const obj of this.state.carServiceBooking){
                if(obj.RLN === "999999")
                {
                    retVal=false;
                }
            }
            if (!retVal){
                alert("Existing Resv # cannot be 999999 a temporary resv #, enter valid number...");
            }
        }
        return retVal;
    }
    onSubmit(e:any){
        e.preventDefault();
        var action = e.currentTarget.id;      
        
        if (this.validateConfirmation() && this.validateRLN(action)){
            if(action === "saveChanges"){
                //console.log(action);
                this.saveConfirmation(action);
                //alert("success saved");
            }
            else if(action === "applyConfirmation"){
                //console.log(action);
                //console.log("travellerArray:", this.state.fields.travellerArray)        
                this.applyConfirmation(action);
                //alert("success applyConfirmation");
            }
        }
    }

    async saveConfirmation(action){
        
        let loading = this.state.loading;
        loading["saveChanges"] = true;
        this.setState({loading});
        //console.log("Save Confiramation");
        //console.log("travellerArray:", this.state.fields.travellerArray)
        let bcdAgentController = new BCDAgentController();
        var result;
        for(let i in this.state.fields.travellerArray){
            result = await this.isValidRLNSaveChanges(this.state.fields.newTravellerRLN[i], action);
            //console.log("valid RLN", result);
            if(result[0]){
                if(this.state.fields.newTravellerRLN[i] !== ""){
                    var saveConfirmationData={
                        travellerId: this.state.fields.travellerArray[i],
                        rln: this.state.fields.newTravellerRLN[i]
                    }
                    //console.log("Save Confirmation Data:", saveConfirmationData);
                    await bcdAgentController.saveConfirmation(saveConfirmationData).then(response => {
                        //console.log("Save Confirmation Response:", response);
                    }).catch(error => {
                        //console.log("Save Confirmation Error:", error);
                    })
                }
            } 
            else{
                alert(result[1]);
                let loading = this.state.loading;
                loading["saveChanges"] = false;
                this.setState({loading});
                return;
            } 
        }
        this.applyConfirmation(action);
    }

    async applyConfirmation(action){
        
        if(action === "applyConfirmation"){
            let loading = this.state.loading;
            loading["applyConfirmation"] = true;
            this.setState({loading});
        }
        let bcdAgentController = new BCDAgentController();
        if(sessionStorage.getItem("ta_car_service_only") === 'false'){
            for(let i in this.state.airConfirmationNumber.booking){
                if(this.state.airConfirmationNumber.confirmationNumber[i] !== ''){
                    var data = {
                        booking: this.state.airConfirmationNumber.booking[i],
                        bookingId: this.state.airConfirmationNumber.bookingId[i],
                        travellerId: this.state.airConfirmationNumber.travellerId[i],
                        confirm: this.state.airConfirmationNumber.confirmationNumber[i]
                    }
                    //console.log("Apply Confirmation",data)
                    await bcdAgentController.applyConfirmation(data).then(response => {
                        //console.log(response)
                    })
                }
            }

            for(let i in this.state.hotelConfirmationNumber.booking){
                var data = {
                    booking: this.state.hotelConfirmationNumber.booking[i],
                    bookingId: this.state.hotelConfirmationNumber.bookingId[i],
                    travellerId: this.state.hotelConfirmationNumber.travellerId[i],
                    confirm: this.state.hotelConfirmationNumber.confirmationNumber[i]
                }
                await bcdAgentController.applyConfirmation(data).then(response => {
                    //console.log(response)
                })
            }

            for(let i in this.state.rentalCarConfirmationNumber.booking){
                var data = {
                    booking: this.state.rentalCarConfirmationNumber.booking[i],
                    bookingId: this.state.rentalCarConfirmationNumber.bookingId[i],
                    travellerId: this.state.rentalCarConfirmationNumber.travellerId[i],
                    confirm: this.state.rentalCarConfirmationNumber.confirmationNumber[i]
                }
                await bcdAgentController.applyConfirmation(data).then(response => {
                    //console.log(response)
                })
            }
        }
        for(let i in this.state.carServiceConfirmationNumber.booking){
            var data = {
                booking: this.state.carServiceConfirmationNumber.booking[i],
                bookingId: this.state.carServiceConfirmationNumber.bookingId[i],
                travellerId: this.state.carServiceConfirmationNumber.travellerId[i],
                confirm: this.state.carServiceConfirmationNumber.confirmationNumber[i]
            }
            await bcdAgentController.applyConfirmation(data).then(response => {
                //console.log(response)
            })
        }

        if(action === "applyConfirmation"){
            this.taHistory();
        }
        else{
            let loading = this.state.loading;
            loading["saveChanges"] = false;
            this.setState({loading});
            this.pageLoad();
        }
    }

    taHistory(){
        //console.log("ta History");
        var statusId = "";
        if(sessionStorage.getItem("page") === "car_service"){
            statusId = "9";
        }
        else if(sessionStorage.getItem("page") === "wtp"){
            statusId = "6";
        }
        let commonController = new CommonController();
        var taHistoryData = {
            authReqId: sessionStorage.getItem("ta"),
            userId: sessionStorage.getItem("uid"),
            statusId: statusId,
            taIsDH: sessionStorage.getItem("ta_is_dh"),
            taWeight: sessionStorage.getItem("ta_weight"),
            isDHA: sessionStorage.getItem("is_dha"),
            approverTierId: sessionStorage.getItem("approver_tier_id"),
            commentsToRequestor: "",
            commentsToApprover: "",
        }
        //console.log("update TA History data",taHistoryData)
        commonController.UpdateTAHistory(taHistoryData).then(response => {
            //console.log("update TA History", response);
            this.taMove(statusId);
        })
    }

    taMove(statusId){
        let commonController = new CommonController()
        var taMoveData={
            userName: sessionStorage.getItem("uid"),
            weight: "",
            statusId: statusId,
            authreqId: sessionStorage.getItem("ta")
        }
        //console.log("taMove", taMoveData);
        commonController.UpdateTaMove(taMoveData).then(response => {
            //console.log("UpdateTaMove", response)
            this.emailSubmitter(statusId);
            let loading = this.state.loading;
            loading["applyConfirmation"] = false;
            this.setState({loading});
        })
    }

    emailSubmitter(statusId){
        var to = sessionStorage.getItem("ta_email")!;
        var subject = "AuthReq #" + sessionStorage.getItem("ta") + " has been"; 
        switch(statusId.toString()){
            case "3":
                subject = subject + " returned for changes and resubmission"
                break;
            case "4":
                subject = subject + " rejected"
                break;
            case "6":
                subject = subject + " completed by BCD"
                break;
            case "7":
                subject = subject + " terminated per your request"
                break;
            case "9":
                subject = subject + " completed by " + sessionStorage.getItem("ta_car_service_company")
                break;
            default:
                subject = subject + " approved"
                break;
        }
        let commonController = new CommonController();
        var travellerData={
            id:'',
            view:'assigned',
            bInner:'false',
            sessionTA:sessionStorage.getItem("ta")
        }
        commonController.getTATraveler(travellerData).then(jsonTravellers => {
            var traveller = '';
            jsonTravellers.map(prop => {
                if(traveller === ''){
                    traveller = prop["name"];
                }
                else{
                    traveller = traveller + ' ,' + prop["name"];
                }
            })
            subject = subject + " for travelers: " + traveller
            var body = subject + ".<br>"
            switch(statusId.toString()){
                case "3": 
                case "4":
                    body = body + "<br> <br> Please check the Decision tab for details.<br> Explanation: " + this.state.fields.commentsToRequestor
            }
            body = body + "<br> <br> Logon to eTA at " + Utils.getSiteCollectionUrl() + " for more information. <br> <br>Thank you for using eTA!" 
            let emailController = new EmailController();
            emailController.sendEmail('',to,subject,body);
            this.props.history.push('/admin/wtpincomingta')
        }).catch(error => {
            console.error("There was an error loading Traveler..., " + error)
        });
    }

    async addBCDRemarksSubmit(e:any){
        
        e.preventDefault();
        var flag = await confirm("Are you sure you want to add Coding/Ticketing Remarks?  (Note that remarks should have been transmitted when the final UMG Approver authorized the TA.  Use this button only if those remarks are blank or need to be updated/appended.)  Click OK to continue or Cancel.",{title:"Confirm Delete", okText:"Ok", cancelText:"Cancel"})
        //console.log("delete flag", flag);
        let loading = this.state.loading;
        let modal = this.state.modal;
        let commonController = new CommonController();                
        await commonController.authTokenLocalStorage();
        modal['modalSpinnerShow']=true;
        loading["addBCDRemarks"] = true;
        this.setState({loading, modal});
        if(flag)
        {
            if(this.validateExistingRLN()){
                let bcdwsController = new BCDWSController;
                
                var data = {
                    authReqId: this.state.fields.sessionTA,
                    isAddBCDRemarks: true,
                    token: localStorage.getItem("accessToken")
                }
                
                //console.log(data);
                bcdwsController.addSortRemarkToPNR(data).then(response => {
                    //console.log("response addSorRemarToPNR:", response);
                    if(response.error === ""){
                        alert("CTM Remarks sent successfully");
                    }
                    else{
                        alert("CTM Remarks send terminated abnormally. Contact tech support...");
                    }
                    loading["addBCDRemarks"] = false;
                    modal['modalSpinnerShow']=false;
                    this.setState({loading, modal});
                }).catch(error => {
                    loading["addBCDRemarks"] = false;
                    modal['modalSpinnerShow']=false;
                    this.setState({loading, modal});
                    console.error("There was an error", error);
                })
            }
            else{
                loading["addBCDRemarks"] = false;
                modal['modalSpinnerShow']=false;
                this.setState({loading,modal}); 
            }
        }
        else{
            loading["addBCDRemarks"] = false;
            modal['modalSpinnerShow']=false;
            this.setState({loading,modal}); 
        }
    }

    taHeader = () => {
        return <div>
            {sessionStorage.getItem("bCoding") === "true" ?
                <Row style={{alignSelf:"center",display:"none"}}>
                    <Col sm={12} style={{color:"green"}}>All Reservations on the TA must be Reviewed before you can Submit.</Col>
                </Row>
            :
                <></>
            }   
            <table>
                {sessionStorage.getItem("page") === "direct_bill" ?
                    <tr>
                        <td>{sessionStorage.getItem("ta_label_desc")}</td>
                        <td>&nbsp;</td>  
                    </tr>
                : 
                    <></>
                }  
            </table>
        </div>
    }

    loadAirLeg = (prop, cprop) => {
        return <>
            <tr>
                <td style={{backgroundColor:"silver",width:"20%"}}>Airline {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Class</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Departure</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Departing</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Arriving</td>
            </tr>
            <tr>
                <td>{cprop.airline}</td>
                {(cprop.travel_class==="Select Class")?
                    <td style={{width:"20%",color: "red"}}>{cprop.travel_class}</td>
                :
                    <td style={{width:"20%"}}>{cprop.travel_class}</td>
                }
                <td>{Utils.FormatDate(cprop.travel_date)}</td>
                <td>{cprop.from_location}<br></br>{cprop.from_time}{cprop.from_am_pm}</td>
                <td>{cprop.to_location}<br></br>{cprop.to_time}{cprop.to_am_pm}</td>
            </tr>
        </>
    }

    airBookingHtml(prop){
        return <tbody>
            {this.state.airBooking.map((cprop, ckey) => {
                if(ckey < Number(cprop.leg_count)){
                    {return this.loadAirLeg(prop,cprop)} 
                }
            })}  
        </tbody>
    }

    hotelBookingHtml(prop){
        return <tbody>
            <tr>
                <td style={{backgroundColor:"silver",width:"20%"}}>Hotel {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Billing</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>City</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Check-In</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Check-Out</td>
            </tr>
            <tr>
                <td style={{width:"20%"}}>{prop.hotel}</td>
                {(prop.bill_type === "Select Billing Type")?
                    <td style={{width:"20%",color: "red"}}>{prop.bill_type}</td>
                :
                    <td style={{width:"20%"}}>{prop.bill_type}</td>
                }
                <td style={{width:"20%"}}>{prop.city}</td>
                <td style={{width:"20%"}}>{Utils.FormatDate(prop.in_date)}</td>
                <td style={{width:"20%"}}>{Utils.FormatDate(prop.out_date)}</td>
            </tr>  
            <tr>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Rate</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Tax</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Incidentals</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Total</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Confirmation # {prop.BCD_generated ? "BCD" : "Manual"}</td>
            </tr>
            <tr>
                <td colSpan={5} style={{width:"20%"}}>
                {(prop.non_disputable)? (sessionStorage.getItem("ta_expense_type_id") !== "100" || (sessionStorage.getItem("ta_expense_type_id") !== "100" && !sessionStorage.getItem("ta_is_corporate_card"))) ?
                    <div className="text-danger">All hotels are now Non-Disputable.  Please code hotel purchase orders to Vendor #328063.</div> : <div></div>
                    : 
                    <div className="text-danger">(Disputable) All hotels are now Non-Disputable.  Please code hotel purchase orders to Vendor #328063.</div>
                }
                </td>
            </tr>
        </tbody>
    }

    rentalCarBookingHtml(prop){
        return <tbody>
            <tr>
                <td style={{backgroundColor:"silver",width:"20%"}}>Rental Company/Car Type {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Billing</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>City</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Pick-Up</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Return</td>
            </tr>
            <tr>
                <td style={{width:"20%"}}>{prop.car_company}</td>
                {(prop.bill_type==="Select Billing")?
                    <td style={{width:"20%",color: "red"}}>{prop.bill_type}</td>
                :
                    <td style={{width:"20%"}}>{prop.bill_type}</td>
                }
                <td style={{width:"20%"}}>{prop.city}</td>
                <td style={{width:"20%"}}>{Utils.FormatDate(prop.pick_up_date)}</td>
                <td style={{width:"20%"}}>{Utils.FormatDate(prop.return_date)}</td>
            </tr>  
            <tr>
                {(prop.carTypeDesc==="Select Car Type")?
                    <td style={{width:"20%",color: "red"}}>{prop.carTypeDesc}</td>
                :
                    <td style={{width:"20%"}}>{prop.carTypeDesc}</td>
                }
                <td style={{width:"20%"}}></td>
                <td style={{width:"20%"}}></td>
                <td style={{width:"20%"}}></td>
            </tr>
            <tr>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Rate</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Tax</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Other Costs</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Total</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Confirmation # {prop.BCD_generated ? "BCD" : "Manual"}</td>
            </tr>
        </tbody> 
    }
    
    carServiceBookingHtml(prop){
        return <tbody>
            <td colSpan={5}>Traveler:&nbsp;<span style={{color:"red"}}>{prop.traveler}&nbsp;&nbsp;{prop.RLN}</span></td>
            <tr>
                <td colSpan={5}>General Comments:&nbsp;{prop.special_instructions}</td>
            </tr>
            <tr>
                <td colSpan={5}>Account Number: {(sessionStorage.getItem("ta_is_corporate_card"))? prop.car_service_cc_account_number : prop.car_service_account_number}</td>
            </tr>
            <tr>
                <td style={{backgroundColor:"silver",width:"20%"}}>Service Company {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Service Type</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Car Type</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Pickup Date</td>
                <td style={{backgroundColor:"silver",width:"20%"}}>Pickup Time</td>
            </tr>
            <tr>
                <td style={{width:"20%"}}>{prop.company_name}</td>
                {(prop.service_type==="Select Service Type")?
                    <td style={{width:"20%",color: "red"}}>{prop.service_type}</td>
                :
                    <td style={{width:"20%"}}>{prop.service_type}</td>
                }
                {(prop.car_type==="Select Car Type")?
                    <td style={{width:"20%",color: "red"}}>{prop.car_type}</td>
                :
                    <td style={{width:"20%"}}>{prop.car_type}</td>
                }
                <td style={{width:"20%"}}>{Utils.FormatDate(prop.pick_up_date)}</td>
                <td style={{width:"20%"}}>{prop.pick_up_time}{(sessionStorage.getItem("ta_car_service2")==="2")?prop.am_pm:""}</td>
            </tr>  
            {this.state.carServiceBooking.map((cprop, ckey) => {
                
                if(sessionStorage.getItem("ta_car_service2") === "2"){
                    if(ckey < Number(cprop.location_count)){
                        {return this.loadCarService2Location(cprop, ckey)} 
                    }
                }else{
                    if(ckey < Number(cprop.location_count)){
                        {return this.loadCarServiceLocation(cprop, ckey)} 
                    }
                }
            })} 
            <tr>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Phone Usage</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Rate</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Estimated Total</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>{sessionStorage.getItem("ta_car_service2") == "2" ? "Passengers" : ""}</td>
                <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Car Service Confirmation</td>
            </tr>
        </tbody> 
    }

    loadCarService2Location=(cprop,ckey)=>{
        return <>
            <tr>
                <td colSpan={5} style={{backgroundColor:"cyan"}}>Location {ckey+1}</td>
            </tr>
            <tr>
                <td>Pick Up:</td>
                <td colSpan={4}>{cprop.pick_up_location1}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={4}>{cprop.pick_up_location2}</td>
            </tr>
            <tr>
                <td>Drop Off:</td>
                <td colSpan={4}>{cprop.drop_off_location1}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={4}>{cprop.drop_off_location2}</td>
            </tr>
        </>
    }

    loadCarServiceLocation=(cprop,ckey)=>{
        return <>
            <tr>
                <td colSpan={5} style={{backgroundColor:"cyan"}}>From:</td>
            </tr>
            <tr>
                <td>Location Name:</td>
                <td colSpan={4}>{cprop.from_name}</td>
            </tr>
            <tr>
                <td>Address:</td>
                <td colSpan={4}>{cprop.from_addresslineone}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={4}>{cprop.from_addresslinetwo}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={4}>{cprop.from_city}
                {(cprop.from_city !== "") ? ", " : ""} 
                {cprop.from_state} {' ' + cprop.from_zip}</td>
            </tr>
            <tr>
                <td>Phone:</td>
                <td colSpan={4}>{cprop.from_phone}</td>
            </tr>
            <tr>
                <td>Alternate Phone:</td>
                <td colSpan={4}>{cprop.from_alt_phone}</td>
            </tr>
            <tr><td colSpan={5} style={{backgroundColor:"cyan"}}>To:</td></tr>
            <tr>
                <td>Location Name:</td>
                <td colSpan={4}>{cprop.to_name}</td>
            </tr>
            <tr>
                <td>Address:</td>
                <td colSpan={4}>{cprop.to_addresslineone}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={4}>{cprop.to_addresslinetwo}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={4}>{cprop.to_city}
                {(cprop.to_city !== "") ? ", " : ""} 
                {cprop.to_state} {' ' + cprop.to_zip}</td>
                </tr>
            <tr>
                <td>Phone:</td>
                <td colSpan={4}>{cprop.to_phone}</td>
            </tr>
            <tr>
                <td>Alternate Phone:</td>
                <td colSpan={4}>{cprop.to_alt_phone}</td>
            </tr>
        </>
    }
    
    travellerDetails=(prop, sBooking, total)=>{
        //var total=0;
        if (sBooking === "Car Service" && sessionStorage.getItem("ta_car_service2") !== "2"){
            total = prop.sub_total;
        }else{
            total = total + prop.sub_total;
        }
        if (sBooking === 'Air'){
            return <>
                <tr>
                    <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Airfare</td>
                    <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Transaction Fee</td>
                    <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Air Total</td>
                    <td style={{backgroundColor:"#FFFF80",width:"20%"}}></td>
                    <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Confirmation # {prop.BCD_generated? "BCD" : "Manual"}</td>
                </tr>
                <tr>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.airfare_amount)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.fee_amount)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.total_amount)}</td>
                    <td style={{width:"20%"}}></td>
                    <td style={{width:"20%"}}><FormControl value={prop.confirmation_number}/></td>
                </tr></>
        }else if(sBooking === "Hotel"){
            return <tr>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.rate)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.tax)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.incidentals)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.total)}</td>
                    <td style={{width:"20%"}}><FormControl value={prop.confirmation_number}/></td>
                </tr>
        }else if(sBooking === "Rental Car"){
            return <tr>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.rate)} {(prop.RateType === "Days")?"(per day)":"(per week)"}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.tax)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.other_costs)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.total)}</td>
                    <td style={{width:"20%"}}><FormControl value={prop.confirmation_number}/></td>
                </tr>
        }else if(sBooking === "Car Service"){
            return <tr>
                    <td style={{width:"20%"}}>{prop.phone_usage}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.rate)}</td>
                    <td style={{width:"20%"}}>{this.formatCurrency(prop.total)}</td>
                    <td style={{width:"20%"}}>{sessionStorage.getItem("ta_car_service2") == "2" ? prop.passenger_count : ""}</td>
                    <td style={{width:"20%"}}><FormControl value={prop.confirmation_number}/></td>
                </tr>
        }
    }

    formatCurrency = (amount) => {
        var floatamount = parseFloat(amount);
        return floatamount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    displayButton = (loading) => {
            if(sessionStorage.getItem("page") !== "car_service"){
                return <>
                    <Col sm={6} style={{textAlign:"center"}}>
                        <Button id="saveChanges" type="submit" style={{ marginRight: "5px"}} size="sm" disabled={loading.saveChanges} onClick={(e) => this.onSubmit(e)}>
                            {loading.saveChanges && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , color:"#487afa" }}
                            />
                            )}
                            {loading.saveChanges && <span style={{color:"#487afa"}} >wait...</span>}
                            {!loading.saveChanges && <span>Save Changes</span>}
                        </Button>
                        <Button id="addBCDRemarks" type="button" style={{ marginRight: "5px"}} size="sm" disabled={loading.addBCDRemarks} onClick={(e) => this.addBCDRemarksSubmit(e)}>
                            {loading.addBCDRemarks && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , color:"#487afa" }}
                            />
                            )}
                            {loading.addBCDRemarks && <span style={{color:"#487afa"}} >wait...</span>}
                            {!loading.addBCDRemarks && <span>Add CTM Remarks</span>}
                        </Button>
                        <Button id="applyConfirmation" type="submit" style={{ marginRight: "5px"}} size="sm" disabled={loading.applyConfirmation} onClick={(e) => this.onSubmit(e)}>
                            {loading.applyConfirmation && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , color:"#487afa" }}
                            />
                            )}
                            {loading.applyConfirmation && <span style={{color:"#487afa"}} >wait...</span>}
                            {!loading.applyConfirmation && <span>Apply Confirmation</span>}
                        </Button>
                    </Col>
                </>
            }
            else{
                return <>
                    <Col sm={6} style={{textAlign:"center"}}>
                        <Button id="applyConfirmation" type="button" size="sm" disabled={loading.applyConfirmation} onClick={(e) => this.onSubmit(e)}>
                            {loading.applyConfirmation && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , color:"#487afa" }}
                            />
                            )}
                            {loading.applyConfirmation && <span style={{color:"#487afa"}} >wait...</span>}
                            {!loading.applyConfirmation && <span>Apply Confirmation</span>}
                        </Button>
                    </Col>
                </>
            }
    }

    isShowNewRLN = (travellerId, rln) => {
        
        //console.log("travellerId", travellerId);
        var isShowNewRLN = true;
        if(!this.state.fields.bConfirm){
            isShowNewRLN = false;
            //console.log("isShowNewRLN", isShowNewRLN);
            return isShowNewRLN;
        }
    
        for(var tid of this.state.fields.travellerArray){
            if(tid === travellerId){
                isShowNewRLN = false;
                break;
            }
        }

        if(isShowNewRLN === true){
            let fields = this.state.fields;
            fields["travellerArray"][this.state.fields.taIndex] = travellerId;
            fields["newTravellerRLN"][this.state.fields.taIndex] = "";
            fields["travellerRLN"][this.state.fields.taIndex] = rln;
            fields["taIndex"] = this.state.fields.taIndex + 1;
            this.setState({fields});
        }
        //console.log("isShowNewRLN", isShowNewRLN);
        return isShowNewRLN;
    }

    confirmationNumber(type, bookingId, travellerId, confirmationNumber, key){
        if(this.state.fields.bConfirm){
            if(type === "Air"){
                return <>
                    <FormControl style={{border: "1px solid #bebebe"}} type="text" name={type+"_"+bookingId+"_"+travellerId} id={type+"_"+bookingId+"_"+travellerId} value={this.state.airConfirmationNumber.confirmationNumber[key]} maxLength={20} onChange={(e)=>this.handleCNChange(e,type,key)}/>
                </>
            }
            else if(type === "Hotel"){
                return <>
                    <FormControl style={{border: "1px solid #bebebe"}} type="text" name={type+"_"+bookingId+"_"+travellerId} id={type+"_"+bookingId+"_"+travellerId} value={this.state.hotelConfirmationNumber.confirmationNumber[key]}  maxLength={20} onChange={(e)=>this.handleCNChange(e,type,key)}/>
                </>
            }
            else if(type === "RentalCar"){
                return <>
                    <FormControl style={{border: "1px solid #bebebe"}} type="text" name={type+"_"+bookingId+"_"+travellerId} id={type+"_"+bookingId+"_"+travellerId} value={this.state.rentalCarConfirmationNumber.confirmationNumber[key]}  maxLength={20} onChange={(e)=>this.handleCNChange(e,type,key)}/>
                </>
            }
            else if(type === "CarService"){
                return <>
                    <FormControl style={{border: "1px solid #bebebe"}} type="text" name={type+"_"+bookingId+"_"+travellerId} id={type+"_"+bookingId+"_"+travellerId} value={this.state.carServiceConfirmationNumber.confirmationNumber[key]}  maxLength={20} onChange={(e)=>this.handleCNChange(e,type,key)}/>
                </>
            }
        }
        else{
            return <>{confirmationNumber}</>
        }
        
    }

    displayConfirmationPage = () => {
        const { loading } = this.state;
        var rlnColor = "";
        var prevLegCount = 1;
        var prevBookingID = "";
        var prevTravelerID = "";
        var prevBookingIDVal = "";
        let bookingHtml;
        let bookingTravelerHtml;
        let traveller;
        let generalComments;
        var total = 0;
        let tot = "";
        let airKey = 0;
        let hotelKey = 0;
        let carServiceKey = 0;
        let rentalCarKey = 0;
        return <div>
            {this.taHeader()}
            <Row>
                <Col sm={12} style={{textAlign:"right"}}>
                    <Button id="print" type="button" size="sm" onClick={() => window.print()} >
                        Print
                    </Button>
                </Col>
            </Row>
            {sessionStorage.getItem("ta_is_corporate_card") === "true" ?
                <div style={{textAlign:"center",width:"100%"}}>EMPLOYEE CORPORATE CARD</div>
            :
                <></>
            }    
            <table style={{marginTop:"10px"}}>       
                <tr>
                    <td>
                        {sessionStorage.getItem("ta_status_id") === "7" ?
                            <>Terminated by
                            {sessionStorage.getItem("ta_updated_by")}
                            per submitter request with the following comments:
                            {sessionStorage.getItem("ta_last_comments_to_requestor")}</>
                        :
                            <table>
                                <tr>
                                    <td>Last Update:&nbsp;</td>
                                    <td>{sessionStorage.getItem("ta_updated_by")}&nbsp;{Utils.FormatDateTime(sessionStorage.getItem("ta_updated_date"))}</td>
                                </tr>
                                <tr>
                                    <td>Authorized:</td>
                                    <td>{this.state.authorizer.map(prop => {
                                        return (
                                            prop.Name + " " + Utils.FormatDateTime(prop.Updated_Date) 
                                        )
                                    })}</td>
                                </tr>
                            </table>
                        }
                    </td>
                </tr>
            </table>
            <table className="text-dark font-size-xs small" cellSpacing="0" cellPadding="3" style={{width:"100%",marginTop:"10px"}}>
                {this.state.airBooking.length > 0 ? <tr><td colSpan={5} style={{marginTop:"10px",color:"white",backgroundColor:"navy"}}>Air</td></tr> : <></>}
                {this.state.uniquefields.uniqueAirBookingIds.map((uprop, ukeyVal) => {
                    ////console.log("Testing uniquehotelbooking:", uprop)
                    let getAirBooking = this.state.airBooking.filter(f=>f.booking_id === uprop);
                    return getAirBooking.map((mprop, keyVal) => {
                        if(prevBookingID !== mprop.booking_id){
                            prevBookingID = mprop.booking_id
                            ////console.log("inside uniquehotelbooking:", mprop.booking_id)
                            prevTravelerID = "";
                            return getAirBooking.map((prop, key) => {
                                traveller="";
                                generalComments="";
                                bookingHtml="";
                                bookingTravelerHtml="";
                                if(prevTravelerID !== prop.traveller_id){
                                    prevTravelerID = prop.traveller_id
                                    prevLegCount = prop.leg_count;
                                    traveller = <tr>
                                        <td>Traveler:&nbsp;<span style={{color:"red"}}>{prop.traveler}&nbsp;&nbsp;{prop.RLN}</span></td>
                                        {this.state.isShowNewRLN.air[airKey] ? 
                                            <>
                                                <Form.Group as={Row} style={{marginTop:"10px"}}>
                                                    <Form.Label column sm="7">
                                                        Change RLOC number to:
                                                    </Form.Label>
                                                    <Col sm="5">
                                                        <Form.Control type="text" name={"newTravelerRLN_"+ prop.traveller_id} id={"newTravelerRLN_"+ prop.traveller_id} value={this.state.fields.newTravellerRLN[this.state.fields.travellerArray.indexOf(prop.traveller_id)]} style={{backgroundColor:(prop.RLN === "999999") ? "yellow" : "#FFFFFF"}} maxLength={10} onChange={(e)=>this.handleRLNChange(e,prop.traveller_id)}/>
                                                    </Col>
                                                </Form.Group>
                                            </>
                                        :
                                            <></>
                                        }
                                    </tr>
                                    generalComments = <tr><td>General Comments:&nbsp;{prop.special_instructions}</td></tr>;
                                    
                                    if(prop.leg_count === 1){
                                        bookingHtml = <><tr>
                                            <td style={{backgroundColor:"silver",width:"20%"}}>Airline {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                                            <td style={{backgroundColor:"silver",width:"20%"}}>Class</td>
                                            <td style={{backgroundColor:"silver",width:"20%"}}>Departure</td>
                                            <td style={{backgroundColor:"silver",width:"20%"}}>Departing</td>
                                            <td style={{backgroundColor:"silver",width:"20%"}}>Arriving</td>
                                        </tr>
                                        <tr>
                                            <td>{prop.airline}</td>
                                            {(prop.travel_class==="Select Class")?
                                                <td style={{width:"20%",color: "red"}}>{prop.travel_class}</td>
                                            :
                                                <td style={{width:"20%"}}>{prop.travel_class}</td>
                                            }
                                            <td>{Utils.FormatDate(prop.travel_date)}</td>
                                            <td>{prop.from_location}<br></br>{prop.from_time}{prop.from_am_pm}</td>
                                            <td>{prop.to_location}<br></br>{prop.to_time}{prop.to_am_pm}</td>
                                        </tr></> 
                                    }
                                    else{
                                        bookingHtml = <>
                                            {this.state.airBooking.map((cprop, ckey) => {
                                                if(prop.booking_id === cprop.booking_id && prop.traveller_id === cprop.traveller_id && cprop.leg_count > 1){
                                                    return <><tr>
                                                        <td style={{backgroundColor:"silver",width:"20%"}}>Airline {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                                                        <td style={{backgroundColor:"silver",width:"20%"}}>Class</td>
                                                        <td style={{backgroundColor:"silver",width:"20%"}}>Departure</td>
                                                        <td style={{backgroundColor:"silver",width:"20%"}}>Departing</td>
                                                        <td style={{backgroundColor:"silver",width:"20%"}}>Arriving</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{cprop.airline}</td>
                                                        {(cprop.travel_class==="Select Class")?
                                                            <td style={{width:"20%",color: "red"}}>{cprop.travel_class}</td>
                                                        :
                                                            <td style={{width:"20%"}}>{cprop.travel_class}</td>
                                                        }
                                                        <td>{Utils.FormatDate(cprop.travel_date)}</td>
                                                        <td>{cprop.from_location}<br></br>{cprop.from_time}{cprop.from_am_pm}</td>
                                                        <td>{cprop.to_location}<br></br>{cprop.to_time}{cprop.to_am_pm}</td>
                                                    </tr></> 
                                                }
                                            })}  
                                        </>
                                    }
                                    tot = Number(tot) + prop.sub_total;
                                    bookingTravelerHtml =  <>
                                        <tr>
                                            <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Airfare</td>
                                            <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Transaction Fee</td>
                                            <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Air Total</td>
                                            <td style={{backgroundColor:"#FFFF80",width:"20%"}}></td>
                                            {/*<td style={{backgroundColor:"#FFFF80",width:"20%"}}>Confirmation # {prop.BCD_generated? "BCD" : "Manual"}</td>*/}
                                            <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Airline Confirmation #<span className="text-danger" style={{fontSize:'16px'}}>*</span></td>
                                        </tr>
                                        <tr>
                                            <td style={{width:"20%"}}>{this.formatCurrency(prop.airfare_amount)}</td>
                                            <td style={{width:"20%"}}>{this.formatCurrency(prop.fee_amount)}</td>
                                            <td style={{width:"20%"}}>{this.formatCurrency(prop.total_amount)}</td>
                                            <td style={{width:"20%"}}></td>
                                            <td style={{width:"20%"}}>{this.confirmationNumber('Air',prop.booking_id,prop.traveller_id,prop.confirmation_number,airKey)}</td>
                                        </tr>
                                    </>
                                    //console.log("Sub Total Air:", tot);
                                    //prevBookingID = prop.booking_id;
                                    airKey = airKey + 1;
                                } 
                                return ([traveller, generalComments, bookingHtml, bookingTravelerHtml,(getAirBooking.length -1 === key)?<tr style={{borderTop:"2px solid navy"}}><td></td></tr>:<></>]);
                            })
                        }
                    })
                })
            }
            </table>
            <table className="text-dark font-size-xs small" cellSpacing="0" cellPadding="3" style={{width:"100%"}}>
                {this.state.hotelBooking.length > 0 ? <tr><td colSpan={5} style={{marginTop:"10px",color:"white",backgroundColor:"navy"}}>Hotel</td></tr> : <></>}
                {prevBookingID = ""}
                {prevTravelerID = ""}
                {traveller = ""}
                {generalComments = ""}
                {bookingHtml = ""}
                {bookingTravelerHtml = ""}
                {
                this.state.uniquefields.uniqueHotelBookingIds.map((uprop, ukeyVal) => {
                    let getHotelBooking = this.state.hotelBooking.filter(f=>f.booking_id === uprop);
                    return getHotelBooking.map((mprop, keyVal) => {
                        if(prevBookingID !== mprop.booking_id){
                            prevBookingID = mprop.booking_id
                            prevTravelerID = "";
                            return getHotelBooking.map((prop, key) => {
                                traveller="";
                                generalComments="";
                                bookingHtml="";
                                bookingTravelerHtml="";
                                if(prevTravelerID !== prop.traveller_id){
                                    prevTravelerID = prop.traveller_id
                                    traveller = <tr>
                                        <td>Traveler:&nbsp;<span style={{color:"red"}}>{prop.traveler}&nbsp;&nbsp;{prop.RLN}</span></td>
                                        {this.state.isShowNewRLN.hotel[hotelKey] ? 
                                            <td>
                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="7">
                                                        Change RLOC number to:
                                                    </Form.Label>
                                                    <Col sm="5">
                                                    <Form.Control type="text" name={"newTravelerRLN_"+ prop.traveller_id} id={"newTravelerRLN_"+ prop.traveller_id} value={this.state.fields.newTravellerRLN[this.state.fields.travellerArray.indexOf(prop.traveller_id)]} style={{backgroundColor:(prop.RLN === "999999") ? "yellow" : "#FFFFFF"}} maxLength={10} onChange={(e)=>this.handleRLNChange(e,prop.traveller_id)}/>
                                                    </Col>
                                                </Form.Group>
                                            </td>
                                        :
                                            <td></td>
                                        }
                                    </tr>
                                    generalComments = <tr><td>General Comments:&nbsp;{prop.special_instructions}</td></tr>
                                    bookingHtml = <>
                                    <tr style={{height:"20%"}}>
                                        <td style={{width:"20%",backgroundColor:"silver"}}>Hotel</td>
                                        <td style={{width:"20%",backgroundColor:"silver"}}>Billing</td>
                                        <td style={{width:"20%",backgroundColor:"silver"}}>City</td>
                                        <td style={{width:"20%",backgroundColor:"silver"}}>Check-In</td>
                                        <td style={{width:"20%",backgroundColor:"silver"}}>Check-Out</td>
                                    </tr>
                                    <tr style={{height:"20"}}>
                                        <td style={{width:"20%"}}>
                                            {prop.hotel}
                                            {(prop.non_disputable) ?
                                                <span style={{color:"red"}}> (non-disputable)</span>
                                            :
                                                ""}
                                        </td>
                                        <td style={{width:"20%"}}>{prop.bill_type}</td>
                                        <td style={{width:"20%"}}>{prop.city}</td>
                                        <td style={{width:"20%"}}>{Utils.FormatDateTime(prop.in_date)}</td>
                                        <td style={{width:"20%"}}>{Utils.FormatDateTime(prop.out_date)}</td>
                                    </tr>
                                    </>
                                    bookingTravelerHtml =  <><tr style={{height:"20"}}>
                                        <td style={{width:"20%",backgroundColor:"#ffff80"}}>Rate</td>
                                        <td style={{width:"20%",backgroundColor:"#ffff80"}}>Tax</td>
                                        <td style={{width:"20%",backgroundColor:"#ffff80"}}>Incidentals</td>
                                        <td style={{width:"20%",backgroundColor:"#ffff80"}}>Total</td>
                                        {/*<td style={{width:"20%",backgroundColor:"#ffff80"}}>Confirmation # {prop.BCD_generated == "true" ? "BCD" : "Manual"}</td>*/}
                                        <td style={{width:"20%",backgroundColor:"#ffff80"}}>Hotel Confirmation #<span className="text-danger" style={{fontSize:'16px'}}>*</span></td>
                                    </tr>
                                    <tr style={{height:"20"}}>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.rate)}</td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.tax)}</td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.incidentals)}</td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.total)}</td>
                                        <td style={{width:"20%"}}>{this.confirmationNumber('Hotel',prop.booking_id,prop.traveller_id,prop.confirmation_number,hotelKey)}</td>
                                    </tr>
                                    </>
                                    hotelKey = hotelKey + 1;
                                } 
                                return ([traveller, generalComments, bookingHtml, bookingTravelerHtml,(getHotelBooking.length -1 === key)?<tr style={{borderTop:"2px solid navy"}}><td></td></tr>:<></>]);
                            })
                        }
                    })
                })
            }
			</table>
            <table className="text-dark font-size-xs small" cellSpacing="0" cellPadding="3" style={{width:"100%"}}>
            {this.state.rentalCarBooking.length > 0 ? <tr><td colSpan={5} style={{marginTop:"10px",color:"white",backgroundColor:"navy"}}>Rental Car</td></tr> : <></>}
            {prevBookingID = ""}
            {prevTravelerID = ""}
            {traveller = ""}
            {generalComments = ""}
            {bookingHtml = ""}
            {bookingTravelerHtml = ""}
            {this.state.uniquefields.uniqueRentalCarBookingIds.map((uprop, ukeyVal) => {
                    let getRentalCarBooking = this.state.rentalCarBooking.filter(f=>f.booking_id === uprop);
                    return getRentalCarBooking.map((mprop, keyVal) => {
                        if(prevBookingID !== mprop.booking_id){
                            prevBookingID = mprop.booking_id
                            prevTravelerID = "";
                            return getRentalCarBooking.map((prop, key) => {
                                traveller="";
                                generalComments="";
                                bookingHtml="";
                                bookingTravelerHtml="";
                                if(prevTravelerID !== prop.traveller_id){
                                    prevTravelerID = prop.traveller_id
                                    traveller = <tr>
                                        <td>Traveler:&nbsp;<span style={{color:"red"}}>{prop.traveler}&nbsp;&nbsp;{prop.RLN}</span></td>
                                        {this.state.isShowNewRLN.rentalCar[rentalCarKey] ? 
                                            <>
                                                <Form.Group as={Row} style={{marginTop:"10px"}}>
                                                    <Form.Label column sm="7">
                                                        Change RLOC number to:
                                                    </Form.Label>
                                                    <Col sm="5">
                                                    <Form.Control type="text" name={"newTravelerRLN_"+ prop.traveller_id} id={"newTravelerRLN_"+ prop.traveller_id} value={this.state.fields.newTravellerRLN[this.state.fields.travellerArray.indexOf(prop.traveller_id)]} style={{backgroundColor:(prop.RLN === "999999") ? "yellow" : "#FFFFFF"}} maxLength={10} onChange={(e)=>this.handleRLNChange(e,prop.traveller_id)}/>
                                                    </Col>
                                                </Form.Group>
                                            </>
                                        :
                                            <></>
                                        }
                                    </tr>
                                    generalComments = <tr><td>General Comments:&nbsp;{prop.special_instructions}</td></tr>
                                    bookingHtml = <>
                                    <tr style={{height:"20"}}>
                                        <td style={{width:"20%", backgroundColor:"silver"}}>Rental Company/Car Type</td>
                                        <td style={{width:"20%", backgroundColor:"silver"}}>Billing</td>
                                        <td style={{width:"20%", backgroundColor:"silver"}}>City</td>
                                        <td style={{width:"20%", backgroundColor:"silver"}}>Pick-Up</td>
                                        <td style={{width:"20%", backgroundColor:"silver"}}>Return</td>
                                    </tr>
                                    <tr style={{height:"20"}}>
                                        <td style={{width:"20%"}}>{prop.car_company}</td>
                                        <td style={{width:"20%"}}>{prop.bill_type}</td>
                                        <td style={{width:"20%"}}>{prop.city}</td>
                                        <td style={{width:"20%"}}>{Utils.FormatDateTime(prop.pick_up_date)}</td>
                                        <td style={{width:"20%"}}>{Utils.FormatDateTime(prop.return_date)}</td>
                                    </tr>
                                    <tr style={{height:"20"}}>
                                        <td colSpan={2} style={{width:"20%"}}>{prop.carTypeDesc}</td>
                                        <td style={{width:"20%"}}></td>
                                        <td style={{width:"20%"}}></td>
                                        <td style={{width:"20%"}}></td>
                                    </tr></>
                                    bookingTravelerHtml =  <><tr style={{height:"20"}}>
                                        <td style={{width:"20%", backgroundColor:"#ffff80"}}>Rate</td>
                                        <td style={{width:"20%", backgroundColor:"#ffff80"}}>Tax</td>
                                        <td style={{width:"20%", backgroundColor:"#ffff80"}}>Other Costs</td>
                                        <td style={{width:"20%", backgroundColor:"#ffff80"}}>Total</td>
                                        {/*<td style={{width:"20%", backgroundColor:"#ffff80"}}>Confirmation # {prop.BCD_generated == "true" ? "BCD" : "Manual"}</td>*/}
                                        <td style={{width:"20%", backgroundColor:"#ffff80"}}>Rental Car Confirmation #<span className="text-danger" style={{fontSize:'16px'}}>*</span></td>
                                    </tr>
                                    <tr style={{height:"20"}}>
                                        <td style={{width:"20%"}}>
                                            {this.formatCurrency(prop.rate)}
                                            {prop.RateType == "Days" ?
                                                " (per day)"
                                            : prop.RateType == "Weeks" ?
                                                " (per week)"
                                            : ""}
                                        </td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.tax)}</td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.other_costs)}</td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.total)}</td>
                                        <td style={{width:"20%"}}>{this.confirmationNumber('RentalCar',prop.booking_id,prop.traveller_id,prop.confirmation_number,rentalCarKey)}</td>
                                    </tr>						
                                    </>  
                                    rentalCarKey = rentalCarKey + 1;
                                }
                                return ([traveller, generalComments, bookingHtml, bookingTravelerHtml,(getRentalCarBooking.length -1 === key)?<tr style={{borderTop:"2px solid navy"}}><td></td></tr>:<></>]);
                            })
                        }
                    })
                })
            }
            </table>
            <table className="text-dark font-size-xs small" cellSpacing="0" cellPadding="3" style={{width:"100%"}}>
            {this.state.carServiceBooking.length > 0 ? <tr><td colSpan={5} style={{marginTop:"10px",color:"white",backgroundColor:"navy"}}>Car Service</td></tr> : <></>}
            {prevBookingID = ""}
            {prevTravelerID = ""}
            {traveller = ""}
            {generalComments = ""}
            {bookingHtml = ""}
            {bookingTravelerHtml = ""}
            {this.state.uniquefields.uniqueCarServiceBookingIds.map((uprop, ukeyVal) => {
                    let getCarServiceBooking = this.state.carServiceBooking.filter(f=>f.booking_id === uprop);
                    return getCarServiceBooking.map((mprop, keyVal) => {
                        if(prevBookingID !== mprop.booking_id){
                            prevBookingID = mprop.booking_id
                            prevTravelerID = "";
                            return getCarServiceBooking.map((prop, key) => {
                                traveller="";
                                generalComments="";
                                bookingHtml="";
                                bookingTravelerHtml="";
                                if(prevTravelerID !== prop.traveller_id){
                                    prevTravelerID = prop.traveller_id
                                    traveller = <tr>
                                        <td>Traveler:&nbsp;<span style={{color:"red"}}>{prop.traveler}&nbsp;&nbsp;{prop.RLN}</span></td>
                                        {this.state.isShowNewRLN.carService[carServiceKey] ? 
                                            <>
                                                <Form.Group as={Row} style={{marginTop:"10px"}}>
                                                    <Form.Label column sm="7">
                                                        Change RLOC number to:
                                                    </Form.Label>
                                                    <Col sm="5">
                                                    <Form.Control type="text" name={"newTravelerRLN_"+ prop.traveller_id} id={"newTravelerRLN_"+ prop.traveller_id} value={this.state.fields.newTravellerRLN[this.state.fields.travellerArray.indexOf(prop.traveller_id)]} style={{backgroundColor:(prop.RLN === "999999") ? "yellow" : "#FFFFFF"}} maxLength={10} onChange={(e)=>this.handleRLNChange(e,prop.traveller_id)}/>
                                                    </Col>
                                                </Form.Group>
                                            </>
                                        :
                                            <></>
                                        }
                                    </tr>
                                    generalComments = <tr><td>General Comments:&nbsp;{prop.special_instructions}</td></tr>
                                    bookingHtml = <><tr>
                                        <td colSpan={5}>Account Number: {(sessionStorage.getItem("ta_is_corporate_card"))? prop.car_service_cc_account_number : prop.car_service_account_number}</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor:"silver",width:"20%"}}>Service Company {(!prop.BCD_generated) ? " (Manual)" : <></>}</td>
                                        <td style={{backgroundColor:"silver",width:"20%"}}>Service Type</td>
                                        <td style={{backgroundColor:"silver",width:"20%"}}>Car Type</td>
                                        <td style={{backgroundColor:"silver",width:"20%"}}>Pickup Date</td>
                                        <td style={{backgroundColor:"silver",width:"20%"}}>Pickup Time</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"20%"}}>{prop.company_name}</td>
                                        {(prop.service_type==="Select Service Type")?
                                            <td style={{width:"20%",color: "red"}}>{prop.service_type}</td>
                                        :
                                            <td style={{width:"20%"}}>{prop.service_type}</td>
                                        }
                                        {(prop.car_type==="Select Car Type")?
                                            <td style={{width:"20%",color: "red"}}>{prop.car_type}</td>
                                        :
                                            <td style={{width:"20%"}}>{prop.car_type}</td>
                                        }
                                        <td style={{width:"20%"}}>{Utils.FormatDate(prop.pick_up_date)}</td>
                                        <td style={{width:"20%"}}>{prop.pick_up_time}{(sessionStorage.getItem("ta_car_service2")==="2")?prop.am_pm:""}</td>
                                    </tr>  
                                    {this.state.carServiceBooking.map((cprop, ckey) => {
                                        
                                        if(sessionStorage.getItem("ta_car_service2") === "2"){
                                            if(ckey < Number(cprop.location_count)){
                                                {return this.loadCarService2Location(prop, ckey)} 
                                            }
                                        }else{
                                            if(ckey < Number(cprop.location_count)){
                                                {return this.loadCarServiceLocation(prop, ckey)} 
                                            }
                                        }
                                    })} </>
                                    bookingTravelerHtml =  <><tr>
                                        {/*<td style={{backgroundColor:"#FFFF80",width:"20%"}}>Phone Usage</td>*/}
                                        <td style={{backgroundColor:"#FFFF80",width:"20%"}}></td>
                                        <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Rate</td>
                                        <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Estimated Total</td>
                                        <td style={{backgroundColor:"#FFFF80",width:"20%"}}>{sessionStorage.getItem("ta_car_service2") == "2" ? "Passengers" : ""}</td>
                                        <td style={{backgroundColor:"#FFFF80",width:"20%"}}>Car Service Confirmation #<span className="text-danger" style={{fontSize:'16px'}}>*</span></td>
                                    </tr>
                                    <tr>
                                        {/*<td style={{width:"20%"}}>{prop.phone_usage}</td>*/}
                                        <td style={{width:"20%"}}></td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.rate)}</td>
                                        <td style={{width:"20%"}}>{this.formatCurrency(prop.total)}</td>
                                        <td style={{width:"20%"}}>{sessionStorage.getItem("ta_car_service2") == "2" ? prop.passenger_count : ""}</td>
                                        <td style={{width:"20%"}}>{this.confirmationNumber('CarService',prop.booking_id,prop.traveller_id,prop.confirmation_number,carServiceKey)}</td>
                                    </tr>						
                                    </>  
                                    carServiceKey = carServiceKey + 1;
                                }
                                return ([traveller, generalComments, bookingHtml, bookingTravelerHtml,(getCarServiceBooking.length -1 === key)?<tr style={{borderTop:"2px solid navy"}}><td></td></tr>:<></>]);
                            })
                        }
                    })
                })
            }
            </table>
            <Row style={{marginTop:"5px"}}>
                <Col sm={3}></Col>
                {this.state.fields.bConfirm ?
                    this.displayButton(loading)
                :
                    <Col sm={6}></Col>
                }
                <Col sm={3}></Col>
            </Row>
            {sessionStorage.getItem("ta_is_corporate_card") === "false" ? 
                <><table style={{marginTop:"25px", width:"100%"}}>
                    <tbody>
                        <tr>
                            <td style={{backgroundColor:"red"}}>Coding</td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"red"}}>{sessionStorage.getItem("HFMEntityOrOperatingCompanyText")}:&nbsp;{sessionStorage.getItem("ta_label_desc")}</td>
                        </tr>
                        <tr>
                            <td style={{backgroundColor:"red"}}>{sessionStorage.getItem("HFMCodeOrOpCoText")}:&nbsp;{sessionStorage.getItem("ta_op_code")}</td>
                        </tr>
                    </tbody>
                </table>
                {this.state.fields.sBooking.map(booking => {
                    var recordset = [];
                    var col2, col3, col4, col5;
                    switch(sessionStorage.getItem("ta_expense_type_id")){
                        case '1' || '2' || '10' || '11' ||'1000': {col2 = " "; col3 = "GL Account #"; col4 = "WBS Code"; col5 = "Project #"}
                        default: {col2 = "Cost Center"; col3 = "GL Account #"; col4 = "WBS"; col5 = "Employee/Project #"}
                    }
                    booking === "Air" ? recordset = this.state.fields.taAirCodingRow : booking === "Hotel" ? recordset = this.state.fields.taHotelCodingRow : booking === "Rental Car" ? recordset = this.state.fields.taRentalCarCodingRow : recordset = this.state.fields.taCarServiceCodingRow ;
                    return <table style={{width:"100%"}}>
                        {recordset.length !== 0 ?
                            <>
                                <tbody>
                                    <tr style={{height:"20"}}>
                                        <td colSpan={8} style={{color:"white", backgroundColor:"navy"}}>{booking}</td>
                                    </tr>
                                    <tr style={{height:"20"}}>
                                        <td style={{width:"10%", backgroundColor:"silver"}}>Profit Center</td>
                                        <td style={{width:"10%", backgroundColor:"silver"}}>{col2}</td>
                                        <td style={{width:"10%", backgroundColor:"silver"}}>{col3}</td>
                                        <td style={{width:"15%", backgroundColor:"silver"}}>{col4}</td>
                                        <td style={{width:"15%", backgroundColor:"silver"}}>{col5}</td>
                                        <td style={{width:"10%", backgroundColor:"silver"}}>PO #</td>
                                        <td style={{width:"10%", backgroundColor:"silver"}}>PO Line</td>
                                        <td style={{width:"10%", backgroundColor:"silver"}}>Amount</td>
                                    </tr>
                                    {recordset.map(item => {
                                        return <tr style={{height:"20"}}>
                                            <td>{item["business_unit"]}</td>
                                            <td>{sessionStorage.getItem("ta_expense_type_id") === "100" ? item["cost_center"] : "" }</td>
                                            <td>{item["object_account"]}</td>
                                            <td>{item["sub_account"]}</td>
                                            <td>{item["project_emp_number"]}</td>
                                            <td>{item["cts_number"]}</td>
                                            <td>{item["po_line"]}</td>
                                            <td>{this.formatCurrency(item["amount"])}</td>
                                        </tr>
                                    })}
                                </tbody>  
                            </>
                            :
                            <></>
                        }
                    </table>
                })}
                </>
            :
                <></>
            }
        </div>

    }

    redirectToPage(tabLabel){
        
        switch (tabLabel) {
            case 'Confirmation':
                this.props.history.push({pathname:'/admin/confirmation',state:{from:this.props.location.state.from}});
                break;
            case 'Decision':
                this.props.history.push({pathname:'/admin/decision',state:{from:this.props.location.state.from}});
                break;
            case 'Print':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:this.props.location.state.from}});
                break;
            case 'Coding':
                this.props.history.push({pathname:'/admin/codingsummary',state:{from:this.props.location.state.from}});
                break;
        }
    }
    render() {
        const{ loading } = this.state;
        return (
        <div className="content">
            <FormModalSpinner show={this.state.modal.modalSpinnerShow} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card border="light" style={{ width: '100%' }}>
                            <Card.Body>
                            <Form onSubmit={this.onSubmit} autoComplete='off'>
                                <Alert variant="danger" style={{ display: this.state.errMsg ? "inherit" : "none"}}>{this.state.errMsg}</Alert>
                                <AuthStatus/>
                                <section style={{marginTop:"10px"}}>
                                    {(this.props.location.state.from === "history")? 
                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={0} mountOnExit>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation">{this.displayConfirmationPage()}</Tab>
                                        </Tabs>
                                    :
                                    (this.props.location.state.from === "incoming")? 
                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={0} mountOnExit>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation">{this.displayConfirmationPage()}</Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>
                                        </Tabs>
                                        :
                                        <Tabs onSelect={(index, label) => this.redirectToPage(`${label}`)} selected={1} mountOnExit>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for coding page" label="Coding"></Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for confirmation page" label="Confirmation">{this.displayConfirmationPage()}</Tab>
                                            <Tab headerClass="my-tab-header" activeHeaderClass="my-active-tab-header" title="Click for decision page" label="Decision"></Tab>
                                        </Tabs>
                                    }
                                </section>
                            </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}
