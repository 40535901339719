import { ServiceProxy } from '../Common/ServiceProxy';
import { Variables } from '../Variables/Variables';
import { DateTimeFormat } from '../Common/DateTimeFormat';

export class LoginController {
    username: string;
    password: string;
    loginFailed: number;
    response: { msg: string; success: boolean; loginFailed: number; changePassword: boolean};
    serviceProxy: ServiceProxy;
    variables: Variables;
    dateTimeFormat : DateTimeFormat;
    constructor(username : string, password : string, loginFailed : number){
        this.username = username;
        this.password = password;
        this.loginFailed = loginFailed;
        this.response = { msg : '', success : true, loginFailed : 0, changePassword : false }
        this.serviceProxy = new ServiceProxy();
        this.variables = new Variables();
        this.dateTimeFormat = new DateTimeFormat();
    }

    userExistsInApp(username:string){
        
        var data = {
            "username":username
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.userExistsInApp;
        return this.serviceProxy.get(url,data).then((response : any) => {
            if(response.success){
                return response;
            }
            else{
                this.response.msg = ""; 
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    afterSuccessfulLogin(username:string, sessionId : string, userAgent : string){
        var data = {
            "username":username,
            "sessionId":sessionId,
            "userAgent":userAgent
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.afterSuccessfulLogin;
        return this.serviceProxy.get(url,data).then((response : any) => {
            if(response.success){
               return response
            }
            else{
                this.response.msg = ""; 
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }  

    updateLogSuccessfulLogin (userName,ipAddress){
        
        var data = {
            "userName":userName,
            "ipAddress":ipAddress
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.updateLogSuccessfulLogin;
        return this.serviceProxy.put(url,data).then(response => {
            return response;
        }).catch(error =>{
            console.error('There was an error!', error);
        });
    }
    getActiveUserLogin(email:string){
        var data = {
            "email":email
        };
        var url = this.variables.Url + this.variables.Controller.login + this.variables.loginService.getActiveUserLogin;
        return this.serviceProxy.get(url,data).then((response : any) => {
            if(response.success){
               return response
            }
            else{
                this.response.msg = ""; 
                this.response.success = false;
                return this.response;
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });       
    }
}